import { useSelector } from 'react-redux';
import { createSelector, Selector } from '@reduxjs/toolkit';
import { CustomFieldUsage, EmailTemplate, EmailTemplateStatus, Tenant } from '@alucio/aws-beacon-amplify/src/models';
import { RootState } from '../store';
import { EmailTemplateORM, ORMTypes } from 'src/types/types';
import {
  AttachedFilesMap,
  DocumentsMap,
  DocumentVersionsMap,
  getAssociatedFilesORM,
  selAttachedFilesMap,
  selDocumentsMap,
  selDocumentVersionsMap,
} from './document';
import { loggedUser } from './user';
import { getMappedCustomValues } from './common';

export type EmailTemplateORMMap = { [emailTemplateId: string]: EmailTemplateORM }
export const selEmailTemplates = (state: RootState): EmailTemplate[] => state.emailTemplate.records;

const toEmailTemplateORM = (
  emailTemplate: EmailTemplate,
  attachedFilesMap: AttachedFilesMap,
  documentsMap: DocumentsMap,
  documentVersionsMap: DocumentVersionsMap,
  tenant?: Tenant,
): EmailTemplateORM => {
  const associatedFiles = getAssociatedFilesORM(
    emailTemplate.associatedFiles ?? [],
    attachedFilesMap,
    documentsMap,
    documentVersionsMap,
    tenant,
  );

  const emailTemplateORM : EmailTemplateORM = {
    model: emailTemplate,
    type: ORMTypes.EMAIL_TEMPLATE,
    relations: {
      associatedFiles: associatedFiles,
    },
    meta: {
      customFilterValues:
          getMappedCustomValues(
            { internalUsages: [CustomFieldUsage.EMAIL_TEMPLATE_FILTER, CustomFieldUsage.USER_FILTER] },
            emailTemplate.customFilterValues,
            tenant?.config.customFields),
    },
  }

  return emailTemplateORM
}

export const emailTemplateList: Selector<RootState, EmailTemplateORM[]> = createSelector(
  selEmailTemplates,
  selAttachedFilesMap,
  selDocumentsMap,
  selDocumentVersionsMap,
  loggedUser,
  (emailTemplates, attachedFilesMap, documentsMap, documentVersionsMap, user): EmailTemplateORM[] =>
    emailTemplates.map(emailTemplate => {
      return toEmailTemplateORM(
        emailTemplate, attachedFilesMap, documentsMap, documentVersionsMap, user.relations.tenant,
      )
    }),
);

export const allEmailTemplatesMap: Selector<RootState, EmailTemplateORMMap> = createSelector(
  emailTemplateList,
  (emailTemplates): EmailTemplateORMMap => {
    return emailTemplates.reduce<EmailTemplateORMMap>(
      (acc, emailTemplate) => {
        acc[emailTemplate.model.id] = emailTemplate;
        return acc;
      },
      {},
    )
  },
);

export const emailTemplateById: Selector<RootState, EmailTemplateORM | undefined> = createSelector(
  allEmailTemplatesMap,
  (_: RootState, id: string) => id,
  (templates, id): EmailTemplateORM | undefined => templates[id],
)

/** Returns the list of Email Templates */
export const useEmailTemplateList = (): ReturnType<typeof emailTemplateList> =>
  useSelector((state: RootState) => emailTemplateList(state));

/** Returns an Email Template by Id */
export const useTemplateById = (templateId?: string): EmailTemplateORM | undefined =>
  useSelector((state: RootState) => templateId
    ? emailTemplateById(state, templateId)
    : undefined);

/** Returns the list of active Email Templates for MSL */
export const useEmailTemplateListForMSL = (): ReturnType<typeof emailTemplateList> =>
  useSelector((state: RootState) => emailTemplateList(state)
    .filter(tpl => tpl.model.status === EmailTemplateStatus.ACTIVE));
