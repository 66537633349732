import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAText, DNAContextMenu, DNADivider } from '@alucio/lux-ui/src';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { HubSectionType } from '@alucio/aws-beacon-amplify/src/models';
// DND
import { DNARearranger } from 'src/components/DnD/DNARearranger';
import { arrayMove } from '@dnd-kit/sortable';
import { defaultWidgetsValue, useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import { useHubRHFromState } from './useHubRHFormStateProvider';

const styles = StyleSheet.create({
  widgetEditorContainer: {
    width: 240,
    backgroundColor: colors['color-text-white'],
    borderRightColor: colors['color-gray-80'],
    borderRightWidth: 1,
  },
  addSectionBtnContainer: {
    height: 72,
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
  },
  addSectionBtn: {
    width: 215,
    height: 40,
  },
  draggableWidgetCard: {
    paddingVertical: 15,
  },
  widgetCardOverlay: {
    borderTopColor: colors['color-gray-80'],
    borderBottomColor: colors['color-gray-80'],
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  contextMenuBtn: {
    marginRight: 12,
  },
  contextMenuItems: {
    minWidth: 160,
    right: 66,
  },
});

type WidgetContextMenuItem = {
  id: HubSectionType,
  label: string,
  icon: string,
  formName: string,
}

export const widgetContextMenu: Record<HubSectionType, WidgetContextMenuItem> = {
  TEXTAREA: {
    id: HubSectionType.TEXTAREA,
    label: 'Text Box',
    icon: 'format-text',
    formName: 'textareaWidget',
  },
  SHARED_FILES: {
    id: HubSectionType.SHARED_FILES,
    label: 'Shared Files',
    icon: 'file-account-outline',
    formName: 'sharedFilesWidget',
  },
  TODO_LIST: {
    id: HubSectionType.TODO_LIST,
    label: 'To-Do List',
    icon: 'format-list-bulleted',
    formName: 'todoListWidget',
  },
}

type WidgetContextMenuProp = {
  type?: HubSectionType,
}

const WidgetContextMenu: React.FC<WidgetContextMenuProp> = ({ type }) => {
  const { handleDeleteWidget } = useHubsState()
  const { rhform } = useHubRHFromState()
  return (
    <DNAContextMenu style={ styles.contextMenuItems }>
      <DNAContextMenu.Anchor>
        <DNAButton
          iconRight="dots-vertical"
          appearance="ghostLink"
          size="md"
          status="gray"
          padding="none"
          style={styles.contextMenuBtn}
        />
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        <DNAContextMenu.Item
          collapseOnPress
          delay={100} // Workaround because sometimes the item deletes too fast before the ContextMenu can hide itself
          title="Delete"
          status="danger"
          iconStatus="danger"
          icon="trash-can-outline"
          onPress={() => type && handleDeleteWidget(type, rhform)}
        />
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

const LeftSideBar: React.FC = () => {
  const { widgets, setWidgets, items, setItems, handleAddWidget } = useHubsState()
  const { rhform } = useHubRHFromState()

  const currentEnabledWidgetTypes: string[] = useMemo(() => widgets.map(widget => widget.type), [widgets])
  const availableWidgets = Object.values(widgetContextMenu)
    .filter(contextMenuItem => {
      return !currentEnabledWidgetTypes.includes(contextMenuItem.id)
    })

  const [activeItemId, setActiveItemId] = useState()
  const activeWidget = useMemo(() => widgets.find(widget => widget.id === activeItemId), [activeItemId])

  useEffect(() => {
    setWidgets(pre => pre.map(widget => ({ ...widget, order: items.indexOf(widget.id) })))
    // update all widgets order in rhform
    const formValues = rhform.getValues()
    items.forEach((id, index) => {
      const widgetsType = widgets.find(widget => widget.id === id)?.type
      if (widgetsType) {
        const formName = widgetContextMenu[widgetsType].formName
        const values = formValues[formName] || defaultWidgetsValue[widgetsType]
        // Only update the form if the order has changed
        if (values.order !== index) {
          const payload = { ...values, order: index }
          rhform.setValue(formName, payload, { shouldDirty: true })
        }
      }
    })
  }, [items])

  function handleDragStart(event) {
    const { active } = event
    if (active.id) {
      setActiveItemId(active.id)
    }
  }

  function handleDragEnd(event) {
    const { active, over } = event
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
    setActiveItemId(undefined)
  }

  return (
    <DNABox appearance="col" style={ styles.widgetEditorContainer }>
      {/* Add Widget Button */}
      <DNABox alignY="center" alignX="center" style={ styles.addSectionBtnContainer }>
        <DNAContextMenu>
          <DNAContextMenu.Anchor>
            <DNAButton
              iconLeft="plus"
              style={ styles.addSectionBtn }
              disabled={!availableWidgets.length}
            >
              Add section
            </DNAButton>
          </DNAContextMenu.Anchor>
          <DNAContextMenu.Items>
            {availableWidgets.map(widget => (
              <DNAContextMenu.Item
                key={widget.id}
                minWidth={215}
                collapseOnPress
                delay={100}
                icon={widget.icon}
                title={widget.label}
                onPress={() => handleAddWidget(widget.id, rhform)}
              />
            ))}
          </DNAContextMenu.Items>
        </DNAContextMenu>
      </DNABox>
      {/* Widget Drag and Drop Section */}
      <DNABox fill appearance="col">
        <DNARearranger
          collisionDetection={'closestCenter'}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          modifiers={['restrictToVerticalAxis']}
          items={items}
          strategy={'verticalListSortingStrategy'}
          overlay={ activeWidget && (
            <DNARearranger.SortableItem
              id={activeWidget.id}
              itemId={activeWidget.id}
              containerId="base"
              handle
              boxContainerStyle={styles.widgetCardOverlay}
            >
              <DNABox fill style={ styles.draggableWidgetCard } alignY="center" spacing="between">
                <DNAText>{widgetContextMenu[activeWidget.type].label}</DNAText>
                <WidgetContextMenu />
              </DNABox>
            </DNARearranger.SortableItem>
          )}
        >
          {items.map((id) => {
            const widge = widgets.find(widget => widget.id === id)
            if (!widge) return null
            return (
              <React.Fragment key={id}>
                <DNARearranger.SortableItem
                  id={id}
                  itemId={widge.type}
                  containerId="base"
                  handle
                >
                  <DNABox fill style={ styles.draggableWidgetCard } alignY="center" spacing="between">
                    <DNAText>{widgetContextMenu[widge.type].label}</DNAText>
                    <WidgetContextMenu type={widge.type as HubSectionType}/>
                  </DNABox>
                </DNARearranger.SortableItem>
                <DNADivider />
              </React.Fragment>
            )
          })}
        </DNARearranger>
      </DNABox>
    </DNABox>
  )
}

export default LeftSideBar
