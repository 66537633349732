import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDocumentVersionORM, isFolderItemORM, MeetingORM } from 'src/types/types';
import { useMeeting } from 'src/state/redux/selector/meeting';
import { meetingActions } from 'src/state/redux/slice/meeting';
import useScreenNav from 'src/components/DNA/hooks/useScreenNav';
import { Tab } from 'src/screens/Meetings/PresentationControls/PresentationControls';
import { Meeting, PresentedMeta, Sentiment } from '@alucio/aws-beacon-amplify/src/models';
import { drawerActions, DRAWER_ENTITIES } from 'src/state/redux/slice/drawer';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { UseFormMethods } from 'react-hook-form';
import { useBeforeunload } from 'react-beforeunload'
import useFullScreen from 'src/hooks/useFullScreen/useFullScreen';
import usePresentationControlsState from './Hooks/usePresentationControlsState';
import useMeetingsComponentVisibility from './Hooks/useMeetingsComponentVisibility';
import useSyncContentPresented from './Hooks/useSyncContentPresented';
import { useSyncState } from 'src/state/redux/selector/cache'
import workerChannel from 'src/worker/channels/workerChannel'
import { useInterval } from '@alucio/lux-ui';
import useMeetingsPopoutContentState from './Hooks/useMeetingsPopoutContentState';
import { RootState } from 'src/state/redux';
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
import { NOTES } from 'src/screens/Meetings/SharedComponents/Notes';
import useKeyPressedEventHandler, {
  KeyEventSettings,
} from 'src/hooks/useKeyPressedEventHandler/useKeyPressedEventHandler';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import ConfirmationModal
  from 'src/screens/Meetings/PresentationControls/TabContent/MeetingDetails/ConfirmationModal';
import { ActionBarState, useMenuActionBar } from 'src/screens/Meetings/SharedComponents/PresentationMenu';
import { FormValuesType } from 'src/components/CustomFields/ComposableForm';

export interface MeetingsStateType {
  meetingORM: MeetingORM | undefined,
  endMeeting: () => void,
  editMeeting: (meeting: Meeting) => void,
  meetingForm: React.MutableRefObject<UseFormMethods<FormValuesType>>,

  popoutContentHidden: boolean,

  presentationControlsVisible: boolean,
  setPresentationControlsVisible: Dispatch<SetStateAction<boolean>>,
  togglePresentationControlsVisibility: () => void,

  slideRollVisible: boolean,
  setSlideRollVisible: Dispatch<SetStateAction<boolean>>,
  toggleSlideRollVisibility: () => void,

  textSearchVisible: boolean,
  setTextSearchVisible: Dispatch<SetStateAction<boolean>>,
  toggleTextSearchVisibility: () => void,
  checkFormDiscard: (callback: () => void) => void,
  setIsSubmitting: (isSubmitting: boolean) => void,
  currentTab: Tab,
  setCurrentTab: Dispatch<SetStateAction<Tab>>,
  previousTab: React.MutableRefObject<Tab>,

  canEndMeeting: boolean,
  setCanEndMeeting: Dispatch<SetStateAction<boolean>>,

  openNotes: NOTES[],
  toggleMyNotes: () => void,
  toggleSpeakerNotes: () => void,
  addNotes: (notes: string) => void,
  updateReaction: (sentiment: Sentiment) => void,
  updateFollowUp: () => void,
  presentedMeta: PresentedMeta | undefined,
  setPresentationMenu: (state: ActionBarState) => void;
  actionBarState: ActionBarState;
}

export const MeetingsStateContext = createContext<MeetingsStateType>(null!);
MeetingsStateContext.displayName = 'MeetingsContext';

interface InitialValues {
  meetingId: string
}

interface UtilitiesProps {
  meetingForm: React.MutableRefObject<UseFormMethods<FormValuesType>>,
  currentTab: Tab,
}

const useFormUtilities = (props: UtilitiesProps) => {
  const { meetingForm, currentTab } = props;
  const isMeetingTab = useRef<boolean>(currentTab === 'MEETING_DETAILS');

  useEffect(() => {
    isMeetingTab.current = currentTab === 'MEETING_DETAILS';
  }, [currentTab]);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const latestSavedMeetingDetails = useRef(meetingForm.current?.getValues());
  const dispatch = useDispatch();

  useEffect(() => {
    // IF THE USER WANTS TO DISCARD THE FORM CHANGES, THE FORM'S STATE IT HAD WHEN
    // THE TAB WAS OPENED, IS THE ONE THAT WILL BE SET UPON DISCARDING CHANGES
    if ((currentTab === 'MEETING_DETAILS' && meetingForm.current) || isSubmitting) {
      latestSavedMeetingDetails.current = meetingForm.current.getValues();
    }
  }, [currentTab, isSubmitting]);

  // THIS FUNCTION WILL BE TRIGGERED FROM ANY PLACE THAT CAN CAUSE A DISCARD OF THE FORM CHANGES
  // SO THE USER CAN DECIDE WHETHER DISCARDING THEM OR SAVE THEM (CONTINUE IN THE CURRENT SCREEN)
  const checkFormDiscard = (callback: () => void) => {
    function onConfirm(): void {
      callback();
      meetingForm.current.reset({ ...latestSavedMeetingDetails.current }, { isDirty: false });
    }

    const isKeyDirty = Object.keys(meetingForm?.current?.formState?.dirtyFields || []).length;
    if (isMeetingTab.current && isKeyDirty) {
      dispatch(
        DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: true,
          component: (props) => (<ConfirmationModal
            {...props}
            onConfirmAction={onConfirm}
          />),
        }));
    } else {
      callback();
    }
  }

  return {
    checkFormDiscard,
    setIsSubmitting,
  };
};

const MeetingsStateProvider: React.FC<PropsWithChildren<InitialValues>> = (props) => {
  const dispatch = useDispatch();
  const meetingORM = useMeeting(props.meetingId);
  const syncState = useSyncState()
  const { goTo } = useScreenNav();
  const {
    presentations,
    nextStepPage,
    prevStepPage,
    setContentPresented,
    initPresentation,
    contentPresented,
  } = useContent();
  const {
    addNotes,
    updateReaction,
    updateFollowUp,
    presentedMeta,
  } = useSyncContentPresented(meetingORM);
  const { initialMeetingContent } = useSelector((state: RootState) => state.contentPreviewModal);
  const hasAttached = !!initialMeetingContent; // or has attached folder for BEAC-3271
  const isVirtual = meetingORM?.model.type === 'VIRTUAL';
  const showPanel = !hasAttached || isVirtual;
  const [canEndMeeting, setCanEndMeeting] = useState<boolean>(true);
  const [openNotes, setOpenNotes] = useState<NOTES[]>([]);
  const presentationControlState =
    usePresentationControlsState(hasAttached && isVirtual ? 'PRESENTING' : 'BROWSE_CONTENT');
  const meetingForm = useRef<UseFormMethods<FormValuesType>>(null!);
  const { setIsSubmitting, checkFormDiscard } =
    useFormUtilities({ meetingForm, currentTab: presentationControlState.currentTab });
  const { isFullScreen } = useFullScreen();
  const menuPresentationBar = useMenuActionBar();

  const keyEventSettings: KeyEventSettings[] = useMemo(() => [{
    keyEvent: 'keydown',
    keys: ['ArrowLeft', 'ArrowUp', 'Home', 'PageUp'],
    onAction: prevStepPage,
  }, {
    keyEvent: 'keydown',
    keys: ['ArrowRight', 'ArrowDown', 'Enter', 'Space', 'End', 'PageDown'],
    onAction: nextStepPage,
  }], []);

  useKeyPressedEventHandler(keyEventSettings);

  useEffect(() => {
    if (meetingORM && meetingORM.model.contentPresented.length) {
      setContentPresented(meetingORM.model.contentPresented);
    }
  }, []);

  const resumeOfflineSyncing = async() => {
    // check if service worker is registered
    const registration = await navigator.serviceWorker.getRegistration('/')
    if (registration && syncState?.matches('online.paused')) {
      // resume offline syncing
      workerChannel.postMessageExtended({ type: 'RESUME_SYNC' })
    }
  }

  const endMeeting = () => {
    if (meetingORM && contentPresented) {
      dispatch(meetingActions.endMeeting(meetingORM?.model,
        { contentPresented }))
    }
    popoutReference.current && (popoutReference.current.onunload = null)
    popoutReference.current?.window.close()
    goTo.MEETING_HISTORY();
    meetingORM && dispatch(drawerActions.toggle({
      entity: DRAWER_ENTITIES.MEETING,
      entityId: meetingORM.model.id,
    }));

    resumeOfflineSyncing()
  }

  const toggleMyNotes = (): void => {
    setOpenNotes((notes) =>
      notes.includes(NOTES.MY_NOTES)
        ? notes.filter((note) => note !== NOTES.MY_NOTES)
        : [...notes, NOTES.MY_NOTES])
  }

  const toggleSpeakerNotes = (): void => {
    setOpenNotes((notes) =>
      notes.includes(NOTES.SPEAKER_NOTES)
        ? notes.filter((note) => note !== NOTES.SPEAKER_NOTES)
        : [...notes, NOTES.SPEAKER_NOTES])
  }

  const {
    popoutReference,
    popoutContentHidden,
  } = useMeetingsPopoutContentState(meetingORM, endMeeting)

  const editMeeting = (meeting: Meeting) => {
    meetingORM && dispatch(meetingActions.updateMeeting(meetingORM.model, meeting));
  }

  const saveContentBeForeUnload = () => {
    if (meetingORM && contentPresented) {
      const payload = {
        ...meetingORM?.model,
        contentPresented,
      }
      editMeeting(payload);
    }
  }

  /** Periodic Save of presented content */
  useInterval(() => {
    saveContentBeForeUnload()
  }, 60000);

  // prevents the window/tab from closing
  useBeforeunload((e) => {
    saveContentBeForeUnload()
    if (!isFullScreen) {
      e.preventDefault();
    }
  })

  useEffect(() => {
    if (meetingORM?.model && presentations.length < meetingORM?.model.contentPresented.length) {
      const contendPresentedIds = presentations.map(({ presentable: { orm } }) => {
        return (isFolderItemORM(orm) && orm.relations?.itemORM?.model.id) ||
          (isDocumentVersionORM(orm) && orm.model.id)
      })
      const payload = {
        ...meetingORM?.model,
        contentPresented: meetingORM?.model.contentPresented
          .map(p => {
            if (contendPresentedIds.includes(p.contentId) || p.closedAt) {
              return p
            }
            return { ...p, closedAt: new Date().toISOString() }
          }),

      }
      editMeeting(payload);
    }

    if (!presentations.length && openNotes) {
      setOpenNotes([]);
    }
  }, [presentations])

  useEffect(() => {
    if (initialMeetingContent) {
      initPresentation(initialMeetingContent.presentableModelORM, initialMeetingContent.page);
      dispatch(contentPreviewModalActions.setInitialContent(undefined));
    }
  }, [])

  const contextValue: MeetingsStateType = {
    endMeeting,
    editMeeting,
    meetingORM,
    meetingForm,

    popoutContentHidden,

    ...presentationControlState,
    ...useMeetingsComponentVisibility(showPanel),
    ...menuPresentationBar,
    checkFormDiscard,
    setIsSubmitting,
    canEndMeeting,
    setCanEndMeeting,
    openNotes,
    toggleSpeakerNotes,
    toggleMyNotes,
    addNotes,
    updateReaction,
    updateFollowUp,
    presentedMeta,
  }

  return (
    <MeetingsStateContext.Provider value={contextValue}>
      {props.children}
    </MeetingsStateContext.Provider>
  )
}
MeetingsStateProvider.displayName = 'MeetingsStateProvider';

export const useMeetingsState = () => {
  const context = useContext(MeetingsStateContext)
  if (!context) {
    throw new Error('useMeetingsState must be used within the MeetingsStateProvider')
  }
  return context;
}

export default MeetingsStateProvider;
