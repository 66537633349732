import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { commonReducers, initialState, SliceStatus } from './common';
import { getDependenciesForSharedFolder, getSharedFolders } from '@alucio/aws-beacon-amplify/src/graphql/queries';
import { API, graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import { multiSliceActions } from './multiSlice';
import { useDispatch, batch } from 'react-redux';
import { folderPermissionActions } from './folderPermission';
import { GetSharedFoldersQuery } from '@alucio/aws-beacon-amplify/src/API';
import {
  CustomDeck,
  Folder,
  Document,
  DocumentVersion,
  AccessTokenData,
  GetSharedFoldersOutput,
} from '@alucio/aws-beacon-amplify/src/models';
import { store } from 'src/state/redux';
import customDeckSlice from './customDeck';
import documentVersionSlice from './documentVersion';
import documentSlice from './document';
import authTokenSlice, { AccessTokenDataExtended } from './authToken';

const sliceName = 'sharedFolder'
const { reducers, extraReducers } = commonReducers<Folder>(sliceName)

export async function getExtenalDependencies(folderId: string, parentFolderId: string) {
  const { data } = await API.graphql(
    graphqlOperation(getDependenciesForSharedFolder, {
      folderId: folderId,
      parentFolderId: parentFolderId,
    }),
  ) as GraphQLResult<{
    getDependenciesForSharedFolder: {
      customDecks: CustomDeck[];
      documents: Document[]; documentVersions: DocumentVersion[];
      accessTokens: AccessTokenData[];
    };
  }>;

  const {
    customDecks,
    documents,
    documentVersions,
    accessTokens,
  } = data?.getDependenciesForSharedFolder || {};

  return {
    customDecks,
    documents,
    documentVersions,
    accessTokens,
  }
}

export const useFetchSharedFolders = () => {
  const dispatch = useDispatch()

  const fetchSharedFolders = async () => {
    dispatch(sharedFolderActions.setStatus(SliceStatus.PENDING))
    dispatch(folderPermissionActions.setStatus(SliceStatus.PENDING))
    try {
      const { data } = await API.graphql(graphqlOperation(getSharedFolders)) as GraphQLResult<GetSharedFoldersQuery>;
      if (data?.getSharedFolders) {
        dispatch(multiSliceActions.replaceSharedFolders(data.getSharedFolders as GetSharedFoldersOutput));
      }
    } catch (err) {
      console.error(err);
      dispatch(sharedFolderSlice.actions.setStatus(SliceStatus.ERROR))
      dispatch(folderPermissionActions.setStatus(SliceStatus.ERROR))
    }
  }

  return {
    fetchSharedFolders,
  }
}
const getFolderExternalDependencies = createAsyncThunk(
  'folder/getFolderExternalDependencies',
  async ({ folderId, parentFolderId }: { folderId: string, parentFolderId: string }) => {
    const {
      customDecks, documents, documentVersions, accessTokens,
    } = await getExtenalDependencies(folderId, parentFolderId);

    // [NOTE] - These do not insert proper DataStore records!!
    //        - That means you may run into an issue trying to save a record that is not available to you by default!
    batch((): void => {
      customDecks && store.dispatch(customDeckSlice.actions.insert(customDecks));
      documents && store.dispatch(documentSlice.actions.insert(documents));
      documentVersions && store.dispatch(documentVersionSlice.actions.insert(documentVersions));
      accessTokens && store.dispatch(authTokenSlice.actions.upsert(
        accessTokens?.map(accesToken => new AccessTokenDataExtended(accesToken)),
      ));
    });
  },
)

const getSharedFoldersAction = createAsyncThunk(
  'sharedFolder/getSharedFolders',
  async () => {
    const { data } = await API.graphql(graphqlOperation(getSharedFolders)) as GraphQLResult<GetSharedFoldersQuery>;
    return data?.getSharedFolders || [];
  },
)

const sharedFolderSlice = createSlice({
  name: sliceName,
  initialState: initialState<Folder>(),
  reducers: {
    ...reducers,
    replace: (state, { payload }) => {
      state.status = SliceStatus.OK;
      state.hydrated = true;
      state.records = payload;
    },
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    updateFolderItemTitle: (state, action) => {
      state.records.forEach(folder => {
        folder.items.forEach(item => {
          if (item.id === action.payload.id) {
            item.customTitle = action.payload.title;
          }
        })
      })
    },
  },
  extraReducers: {
    [getSharedFoldersAction.fulfilled.toString()]: (state, { payload }) => {
      state.status = SliceStatus.OK;
      state.hydrated = true;
      state.records = payload;
    },
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    [getFolderExternalDependencies.pending.toString()]: (state, _) => {
      state.status = SliceStatus.PENDING;
    },
    [getFolderExternalDependencies.fulfilled.toString()]: (state, _) => {
      state.status = SliceStatus.OK;
      state.hydrated = true;
    },
    [getFolderExternalDependencies.rejected.toString()]: (state, _) => {
      state.status = SliceStatus.ERROR;
    },
    ...extraReducers,
  },
})

export default sharedFolderSlice

export const sharedFolderActions = {
  ...sharedFolderSlice.actions,
  getFolderExternalDependencies,
  getSharedFoldersAction,
}
