import { DNABox, DNACard, DNAText } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import React from 'react';
import { StyleSheet } from 'react-native';
import DNARouteTabs from 'src/components/DNA/Navigation/DNARouteTabs/DNARouteTabs';
import { useCurrentUserORM } from 'src/state/redux/selector/user';
import UsersList from '../Tenants/UsersList';

const style = StyleSheet.create({
  mainWrapper: {
    backgroundColor: colors['color-text-white'],
    flex: 1,
    flexDirection: 'column',
    minHeight: 'calc(100vh - 160px)',
    minWidth: 'auto',
    paddingHorizontal: 33,
    paddingVertical: 24,
  },
  header: {
    marginBottom: 20,
  },
  usersContainer: {
    borderRadius: 8,
    borderWidth: 1,
  },
})

const AdminSettings = () => {
  const currentUser = useCurrentUserORM();
  if (!currentUser) return null;

  const tenantId = currentUser.model.tenantId;

  return (
    <DNABox style={style.mainWrapper}>
      <DNAText h5 status="subtle" testID="page-title" style={style.header}>
        Admin
      </DNAText>
      <DNACard appearance="outline" style={style.usersContainer}>
        <DNARouteTabs.Router>
          <DNARouteTabs.Tabs withDivider>
            <DNARouteTabs.Tab title="Users" path="/publisher_admin_settings">
              <UsersList tenantId={tenantId} />
            </DNARouteTabs.Tab>
          </DNARouteTabs.Tabs>
        </DNARouteTabs.Router>
      </DNACard>
    </DNABox>
  )
}

export default AdminSettings;
