import React from 'react'
import { EmailTemplate, User, ShareType, CustomValues } from '@alucio/aws-beacon-amplify/src/models'
import { GenericToast, ToastOrientations } from '@alucio/lux-ui';
import { ToastActions } from '@alucio/lux-ui/lib/components/Toast/useToast';
import { getShareableLink, ShareableLinkResult } from './common'
import { isSafari, isWindows } from 'react-device-detect'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'

const JUMP_LINE = '%0D%0A'
const BODY_ARG_LENGTH = 6 // => '&body='
const MAX_LENGTH_ALLOWED_ON_WINDOWS = 2000

export interface ShareFileOption {
  isMainDoc: boolean,
  isDefault: boolean,
  beingShared: boolean,
  isDistributable: boolean,
  contentProps: ContentShareProps
}

export interface ContentShareProps {
  contentId: string,
  sharedContentId?: string,
  title?: string,
  type: ShareType,
}

export interface ExistingShareLink {
  title: string,
  expiresAt: string,
  link: string,
  id: string,
}

// TODO: create a tech-debt to separate the two entirely (without the optional param)
async function copyShareableLinkToClipboard(
  shares: ShareFileOption[],
  toast: ToastActions,
  customValues: CustomValues[],
  meetingId?: string,
  existingShareLinks?: ExistingShareLink[],
): Promise<ExistingShareLink[] | ShareableLinkResult[]> {
  const toastWidth = 275
  const generatingToastId = toast.add(<GenericToast
    title="Generating Link"
    status="loading"
    width={toastWidth}
  />, ToastOrientations.TOP_RIGHT)

  let clipboardText: string = ''
  let linkResult: ExistingShareLink[] | ShareableLinkResult[] = []

  if (existingShareLinks?.length) {
    clipboardText = existingShareLinks.reduce((acc, val) => {
      return `${acc}${val.title}${val.title ? '\n' : ''}${val.link}?expiresAt=${val.expiresAt} \n\n`
    }, '')
    linkResult = existingShareLinks
  } else {
    // movet this out into a separate func, call in here if needed, if existingLink exists then use that link instead of generating a new one
    const shareableLinksList: Promise<ShareableLinkResult>[] = shares.map((share: ShareFileOption) => {
      const { contentId, type, title } = share.contentProps
      return getShareableLink(contentId, type, title, customValues, meetingId)
    })
    const result: ShareableLinkResult[] = await Promise.all(shareableLinksList)

    clipboardText = result.reduce((acc, val) => {
      return `${acc}${val.title}${val.title ? '\n' : ''}${val.link}?expiresAt=${val.expiresAt} \n\n`
    }, '')
    linkResult = result
  }
  const linkCopiedToast = (<GenericToast
    title="Link copied, expires in 30 days."
    status="success"
    width={toastWidth}
  />)

  navigator.clipboard.writeText(clipboardText).then(
    () => {
      toast.remove(generatingToastId)
      toast.add(linkCopiedToast, ToastOrientations.TOP_RIGHT);
    },
    () => {
      toast.remove(generatingToastId);
      let copyToastId = '';
      const handleCopyLink = () => {
        navigator.clipboard.writeText(clipboardText).then(() => {
          toast.remove(copyToastId);
          toast.add(linkCopiedToast, ToastOrientations.TOP_RIGHT);
        }, () => {
          toast.remove(copyToastId);
          toast.add(
            <GenericToast title="Unable to access clipboard." width={toastWidth} status="error" />,
            ToastOrientations.TOP_RIGHT,
          )
        })
      }
      copyToastId = toast.add(
        <GenericToast
          title="Link generated."
          width={toastWidth}
          status="information"
          actionConfig={{ actionTitle: 'Copy link', callback: handleCopyLink }}
        />,
        ToastOrientations.TOP_RIGHT, false, 0);
    },
  )

  return linkResult;
}

async function shareEmail(
  shareFileOptions: ShareFileOption[],
  user: User,
  customValues: CustomValues[],
  toast: ToastActions,
  meetingId?: string,
  existingShareLinks?: ExistingShareLink[],
): Promise<ExistingShareLink[] | ShareableLinkResult[]> {
  let bodyText: string = ''
  let linkResult: ExistingShareLink[] | ShareableLinkResult[] = []
  if (existingShareLinks?.length) {
    bodyText = existingShareLinks.reduce((acc, val) => {
      /* eslint-disable max-len */
      return `${acc}${encodeURIComponent(val.title)}${val.title ? JUMP_LINE : ''}${encodeURIComponent(val.link)}%20(link expires ${val.expiresAt})${JUMP_LINE + JUMP_LINE}`
    }, '')
    linkResult = existingShareLinks
  } else {
    const shares: ContentShareProps[] =
      shareFileOptions.map((shareFileOption: ShareFileOption): ContentShareProps => shareFileOption.contentProps);
    const shareableLinksList = shares.map(share => {
      const { title, contentId, type } = share
      return getShareableLink(contentId, type, title, customValues, meetingId)
    })
    const result = await Promise.all(shareableLinksList)
    linkResult = result

    bodyText = result.reduce((acc, val) => {
      /* eslint-disable max-len */
      return `${acc}${encodeURIComponent(val.title)}${val.title ? JUMP_LINE : ''}${encodeURIComponent(val.link)}%20(link expires ${val.expiresAt})${JUMP_LINE + JUMP_LINE}`
    }, '')
  }

  const cc = user.shareCC && user.shareCC.length > 0 ? encodeURIComponent(user.shareCC.join(',')) : '';
  const bcc = user.shareBCC && user.shareBCC.length > 0 ? encodeURIComponent(user.shareBCC.join(',')) : '';

  await sendEmail(cc, bcc, JUMP_LINE + bodyText, toast)
  return linkResult
}

async function copyEmailTemplateToClipboard(
  toast: ToastActions,
  shareFileOptions: ShareFileOption[],
  attachmentsInfo: ContentShareProps[],
  emailTemplate: EmailTemplate,
  customValues: CustomValues[],
  meetingId?: string,
  existingShareLinks?: ExistingShareLink[],
): Promise<ShareableLinkResult[] | ExistingShareLink[]> {
  const shares: ContentShareProps[] = shareFileOptions.map((shareFileOption: ShareFileOption): ContentShareProps => shareFileOption.contentProps);
  const toastWidth = 275

  // @ts-ignore
  const processor = (links: ShareableLinkResult[] | ExistingShareLink[]) => links.reduce(
    (acc, val, idx) => {
      return (
        `${acc}${val.title}${val.title ? '\n' : ''}${val.link}?expiresAt=${val.expiresAt}` +
        `${idx !== links.length - 1 ? '\n\n' : ''}`
      )
    },
    '',
  )

  const bodyTextAndFileResults = await getEmailBodyAndFilesResultsWithTemplate(
    shares,
    attachmentsInfo,
    emailTemplate,
    processor,
    customValues,
    meetingId,
    existingShareLinks,
  )

  const handleCopyLink = () => {
    navigator.clipboard.writeText(bodyTextAndFileResults.bodyText).then(() => {
      toast.add(<GenericToast
        title="Email copied, link expires in 30 days."
        status="success"
        width={toastWidth}
      />, ToastOrientations.TOP_RIGHT, true, 3000)
    }, () => {
      toast.add(
        <GenericToast title="Unable to access clipboard." width={toastWidth} status="error" />,
        ToastOrientations.TOP_RIGHT,
      )
    })
  }

  navigator.clipboard.writeText(bodyTextAndFileResults.bodyText).then(
    () => {
      // we return undefined here because the UI does not show a toast but instead updates the text
      return undefined
    },
    () => {
      toast.add(
        <GenericToast
          title="Email generated"
          width={toastWidth}
          status="information"
          actionConfig={{ actionTitle: 'Copy email', callback: handleCopyLink }}
        />,
        ToastOrientations.TOP_RIGHT, false, 0);
      return undefined
    },
  )
  return bodyTextAndFileResults.filesResults
}

async function shareEmailTemplate(
  shareFileOptions: ShareFileOption[],
  attachmentsInfo: ContentShareProps[],
  emailTemplate: EmailTemplate,
  customValues: CustomValues[],
  toast: ToastActions,
  meetingId?: string,
  existingShareLinks?: ExistingShareLink[],
): Promise<ShareableLinkResult[] | ExistingShareLink[]> {
  const splitSeparator = isWindows ? ';' : ',';

  const shares: ContentShareProps[] = shareFileOptions.map((shareFileOption: ShareFileOption): ContentShareProps => shareFileOption.contentProps);

  // @ts-ignore
  const processor = (links: ExistingShareLink[] | ShareableLinkResult[]) => links.reduce((acc, val, idx) => {
    return `${acc}${encodeURIComponent(val.title)}${val.title ? JUMP_LINE : ''}` +
      `${encodeURIComponent(val.link)}%20(link expires ${val.expiresAt})` +
      `${idx !== links.length - 1 ? JUMP_LINE + JUMP_LINE : ''}`
  }, '')

  const emailBodyAndFilesResults = await getEmailBodyAndFilesResultsWithTemplate(
    shares,
    attachmentsInfo,
    emailTemplate,
    processor,
    customValues,
    meetingId,
    existingShareLinks,
  )
  const bodyTextToCopy = emailBodyAndFilesResults.bodyText.replace(/\n/g, JUMP_LINE);
  const cc = emailTemplate.cc && emailTemplate.cc.length > 0 ? encodeURIComponent(emailTemplate.cc.join(splitSeparator)) : '';
  const bcc = emailTemplate.bcc && emailTemplate.bcc.length > 0 ? encodeURIComponent(emailTemplate.bcc.join(splitSeparator)) : '';

  await sendEmail(cc, bcc, bodyTextToCopy, toast, emailTemplate.subject)
  return emailBodyAndFilesResults.filesResults
}

async function getEmailBodyAndFilesResultsWithTemplate(
  shares: ContentShareProps[],
  attachments: ContentShareProps[],
  emailTemplate: EmailTemplate,
  processor: (links: ShareableLinkResult[] | ExistingShareLink[]) => string,
  customValues: CustomValues[],
  meetingId?: string,
  existingShareLinks?: ExistingShareLink[],
): Promise<{bodyText: string, filesResults: ShareableLinkResult[] | ExistingShareLink[]}> {
  let filesResults: ExistingShareLink[] | ShareableLinkResult[] = []
  if (existingShareLinks) {
    filesResults = existingShareLinks
  } else {
    const shareableLinksList = shares.map(share => {
      const { title, contentId, type } = share
      return getShareableLink(contentId, type, title, customValues, meetingId)
    })
    filesResults = await Promise.all(shareableLinksList)
  }
  const attachmentsLinksList = attachments.map(share => {
    const { title, contentId, type } = share
    return getShareableLink(contentId, type, title, customValues, meetingId)
  })
  const attachmentsResults = await Promise.all(attachmentsLinksList)
  const fileLinks = processor(filesResults)
  const attachmentLinks = processor(attachmentsResults)

  let bodyText = emailTemplate.body!.replace('[Files]', checkIfShouldAddNewlines(emailTemplate.body!, '[Files]') ? `\n${fileLinks}\n` : fileLinks);
  bodyText = bodyText.replace('[Attachments]', checkIfShouldAddNewlines(emailTemplate.body!, '[Attachments]') ? `\n${attachmentLinks}\n` : attachmentLinks);
  return { bodyText, filesResults };
}

const checkIfShouldAddNewlines = (body: string, placeholder: string) => {
  const bodyLines = body.split('\n');
  const lineWithPlaceholder = bodyLines.find(l => l.indexOf(placeholder) > -1);
  let shouldAddNewLines = false;
  if (lineWithPlaceholder && !lineWithPlaceholder.trim().startsWith(placeholder)) {
    shouldAddNewLines = true;
  }

  return shouldAddNewLines;
}

async function emailTemplatePreview(
  toast: ToastActions,
  subject?: string,
  body?: string,
  cc?: string[],
  bcc?: string[],
  attachments?: ContentShareProps[]): Promise<void> {
  const splitSeparator = isWindows ? ';' : ',';
  const formattedBCC = (bcc?.length && encodeURIComponent(bcc.join(splitSeparator))) || '';
  const formattedCC = (cc?.length && encodeURIComponent(cc.join(splitSeparator))) || '';

  if (body && attachments) {
    const expiresAt = format(addDays(new Date(), 30), 'yyyy-MM-dd')
    const links = attachments.reduce((acc, val) => {
      /* eslint-disable max-len */
      return `${acc}${encodeURIComponent(val.title ?? '')}${val.title ? JUMP_LINE : ''}[Document link]%20(link expires ${expiresAt})${JUMP_LINE + JUMP_LINE}`
    }, '')

    body = body.replace('[Attachments]', checkIfShouldAddNewlines(body, '[Attachments]') ? `\n${links}\n` : links);
  }
  body = body ? body.replace(/\n/g, JUMP_LINE) : '';

  await sendEmail(formattedCC, formattedBCC, body, toast, subject);
}

const sendEmail = async (cc: string, bcc: string, body: string, toast: ToastActions, subject?: string) => {
  let hasMultipleArg = false
  let mailto = 'mailto:?'

  const buildArg = (field: string, value?: string) => {
    let arg = ''

    if (value) {
      if (hasMultipleArg) {
        arg += '&'
      } else {
        hasMultipleArg = !hasMultipleArg
      }
      arg += `${field}=${value}`
    }

    return arg
  }

  mailto += buildArg('cc', cc)
  mailto += buildArg('bcc', bcc)
  mailto += buildArg('subject', subject)

  mailto += buildArg('body', body)
  if (!isSafari) {
    if (isWindows && excedesWindowsLenghtLimit(mailto, body)) {
      await navigator.clipboard.writeText(decodeURIComponent(body))
      body = 'Paste the message from your clipboard here. (Ctrl-V)'
    }
    const mail = document.createElement('a');
    mail.href = mailto;
    mail.setAttribute('data-testid', 'emulated-email-client')
    mail.target = '_blank';
    mail.click();
  } else {
    // On safari due to privacy restrictions it does not allow us to auto-click a generated
    // mailto link from an async method
    await (new Promise<void>((resolve) => {
      let emailGeneratedToastId = '';
      const handleOpenEmailClick = () => {
        const mail = document.createElement('a');
        mail.href = mailto;
        mail.setAttribute('data-testid', 'emulated-email-client')
        mail.target = '_blank';
        mail.click();
        toast.remove(emailGeneratedToastId)
        resolve()
      }

      emailGeneratedToastId = toast.add(
        <GenericToast
          title="Email generated"
          width={275}
          status="information"
          actionConfig={{ actionTitle: 'Open email', callback: handleOpenEmailClick }}
        />,
        ToastOrientations.TOP_RIGHT, false, 0);
    }))
  }
}

const excedesWindowsLenghtLimit = (mailto: string, body: string) => mailto.length + body.length + BODY_ARG_LENGTH > MAX_LENGTH_ALLOWED_ON_WINDOWS;

export {
  copyShareableLinkToClipboard,
  copyEmailTemplateToClipboard,
  shareEmail,
  shareEmailTemplate,
  emailTemplatePreview,
}
