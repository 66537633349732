import React, { useCallback } from 'react';
import { DNABox, DNASelect } from '@alucio/lux-ui'
import { DocumentStatus } from '@alucio/aws-beacon-amplify/src/models';
import { DocumentVersionORM } from 'src/types/types';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { useContentPreviewModalState } from './State/ContentPreviewModalStateProvider';
import { useMeetingPresentedMeta } from './State/MeetingPresentedMetaProvider';

const DocumentVersionSelector: React.FC = () => {
  const {
    activeDocumentORM,
    activeDocumentVersionORM,
    isPublisher,
    setPageGroupToAllSlides,
  } = useContentPreviewModalState();
  const { meetingORM } = useMeetingPresentedMeta();
  const { addPresentation } = useContent()

  const documentVersions = isPublisher
    ? activeDocumentORM?.relations.documentVersions
    : activeDocumentORM?.relations.documentVersions
      .filter(document => document.model.status === DocumentStatus.PUBLISHED);
  const disableSelect = documentVersions?.length === 1 || !!meetingORM;

  const formatVersionLabel = (docVerORM: DocumentVersionORM): string => {
    const { latestPublishedDocumentVersionORM } = { ...activeDocumentORM?.relations.version }
    let label = `Version ${docVerORM.meta.version.semVerLabel}`;

    if (latestPublishedDocumentVersionORM?.model.id === docVerORM.model.id) {
      label += ' (Current)'
    }
    else if (docVerORM.model.status === DocumentStatus.NOT_PUBLISHED) {
      label += ' (Draft in progress)';
    }

    return label;
  }

  const onVersionChanged = (e) => {
    if (documentVersions) {
      const { row } = e;
      const version = documentVersions[row];
      setPageGroupToAllSlides()
      addPresentation(version)
    }
  }

  const selectedValue = useCallback(
    () => {
      const versionLabel = formatVersionLabel(activeDocumentVersionORM!);
      return versionLabel
    },
    [activeDocumentORM?.relations.version.latestDocumentVersionORM, activeDocumentVersionORM],
  )

  return (
    <DNABox alignY="center" fill testID="document-version-selector">
      <DNASelect
        style={{ minWidth: 190, maxWidth: 260 }}
        value={selectedValue()}
        onSelect={onVersionChanged}
        disabled={disableSelect}
        testID="document-version-selector-disabled"
      >
        {
          documentVersions?.map((docVerORM) => {
            const formattedLabel = formatVersionLabel(docVerORM)
            return (
              <DNASelect.Item
                testID="document-version-drop-down-list-item"
                key={formattedLabel}
                title={formattedLabel}
              />
            )
          })
        }
      </DNASelect>
    </DNABox>
  )
}

export default DocumentVersionSelector;
