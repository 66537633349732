import React, { useEffect, useState } from 'react';
import { DNABox, util, Iffy, DNAButton, DNADivider, DNAText } from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { ScrollView, StyleSheet } from 'react-native';
import VersionHistory from './Panels/VersionHistory';
import { useDNADocumentActions } from '../../DNA/Document/DNADocument.actions';
import Details from './Panels/Details';
import AssociatedFiles from './Panels/AssociatedFiles';
import { useContentPreviewModalState } from '../State/ContentPreviewModalStateProvider';
import { DocumentAccessLevel, DocumentStatus, FileType } from '@alucio/aws-beacon-amplify/src/models';
import { useDNADocumentVersionActions } from 'src/components/DNA/Document/DNADocumentVersion.actions';
import { useSelector } from 'react-redux';
import { RootState } from 'src/state/redux';
import SlideNotes from './Panels/SlideNotes';
import MeetingNotes from './Panels/MeetingNotes';
import { useMeetingPresentedMeta } from '../State/MeetingPresentedMetaProvider';
import { FeatureFlags } from 'src/types/featureFlags';
import useFeatureFlag from 'src/hooks/useFeatureFlag/useFeatureFlag';
import { useAppSettings } from 'src/state/context/AppSettings';

/** SECTION: Enums */
export enum SidebarOptions {
  versionHistorySideBar = 'versionHistorySideBar',
  meetingNotes = 'meetingNotes',
  detailsSideBar = 'detailsSideBar',
  speakerNotesSidebar = 'speakerNotesSidebar',
  associatedFilesSidebar = 'associatedFilesSidebar'
}
/** !SECTION: Enums */

/** SECTION: Interfaces */
interface OptionAvailabilityConfig {
  condition: boolean
}

interface TabConfig {
  key: string,
  id: SidebarOptions,
  label: string,
  icon: string,
  tooltip: string,
  testID?: string,
}
/** !SECTION: Interfaces */

/** SECTION: Types */
type SideBarOptionVariant = Record<SidebarOptions, React.ElementType>
type OptionAvailabilityConfigs = Record<SidebarOptions, OptionAvailabilityConfig>
/** !SECTION: Types */

/** SECTION: Globals */
const styles = StyleSheet.create({
  arrowIcon: {
    height: 35,
    width: 35,
    marginTop: 8,
    marginBottom: 4,
  },
  sideBarIcon: {
    height: 35,
    width: 35,
    marginTop: 10,
  },
  tooltipText: {
    color: colors['color-text-white'],
  },
  mainWrapper: {
    backgroundColor: colors['color-text-white'],
    borderColor: colors['color-gray-80'],
    borderRightWidth: 1,
    width: 56,
  },
});

export const tabConfigs: TabConfig[] = [
  {
    key: 'slides-button',
    id: SidebarOptions.speakerNotesSidebar,
    label: 'Speaker Notes',
    icon: 'text-box-outline',
    tooltip: 'Speaker notes',
    testID: 'content-preview-sidebar-speaker-note',
  },
  {
    key: 'meeting-notes-button',
    id: SidebarOptions.meetingNotes,
    label: 'My Notes',
    icon: 'playlist-edit',
    tooltip: 'My Notes',
    testID: 'content-preview-sidebar-my-notes',
  },
  {
    key: 'associated-files-button',
    id: SidebarOptions.associatedFilesSidebar,
    label: 'Associated Files',
    icon: 'file-multiple-outline',
    tooltip: 'Associated files',
  },
  {
    key: 'version-history',
    id: SidebarOptions.versionHistorySideBar,
    label: 'Version History',
    icon: 'layers-triple-outline',
    tooltip: 'Version history',
    testID: 'content-preview-sidebar-version-history',
  },
  {
    key: 'details-button',
    id: SidebarOptions.detailsSideBar,
    label: 'Details',
    icon: 'information-outline',
    tooltip: 'Details',
    testID: 'content-preview-sidebar-details',
  },
]

const tabVariant: SideBarOptionVariant = {
  detailsSideBar: Details,
  associatedFilesSidebar: AssociatedFiles,
  versionHistorySideBar: VersionHistory,
  speakerNotesSidebar: SlideNotes,
  meetingNotes: MeetingNotes,
}
/** !SECTION: Globals */

/** SECTION: Functional Components */
const Sidebar: React.FC = () => {
  const {
    isFolderItem, activeDocumentVersionORM, isPublisher, isModifiedDocumentVersion, isAssociatedFile,
  } = useContentPreviewModalState()
  const { onOpenMeetingNotes } = useMeetingPresentedMeta();
  const documentActions = useDNADocumentActions()
  const { shareEmail } = useDNADocumentVersionActions();
  const meetingId = useSelector((state: RootState) => state.contentPreviewModal.meetingId)
  const areMeetingNotesEnabled = useFeatureFlag(FeatureFlags.BEAC_4227_meeting_slide_notes);
  const areNotesOpenByDefault = meetingId && areMeetingNotesEnabled;
  const [selectedSideBarOption, setSelectedSideBarOption] = useState<SidebarOptions | null>(
    (areNotesOpenByDefault && SidebarOptions.meetingNotes) || null)
  const isUserDocument = activeDocumentVersionORM?.relations.documentORM.model.accessLevel === DocumentAccessLevel.USER;

  useEffect(() => {
    isAssociatedFile && setSelectedSideBarOption(null)
  }, [isAssociatedFile])

  useEffect(() => {
    if (selectedSideBarOption === SidebarOptions.meetingNotes) {
      onOpenMeetingNotes();
    }
  }, [selectedSideBarOption]);

  const isUserDocumentOrFolder = (isFolderItem && !activeDocumentVersionORM) || isUserDocument
  const hasAssociatedFiles = (activeDocumentVersionORM?.model.associatedFiles?.length || 0) >
  0 && !isAssociatedFile

  /** Mapping of visibility conditions for each option */
  const optionAvailabilityConfigs:OptionAvailabilityConfigs = {
    speakerNotesSidebar: {
      condition: true,
    },
    meetingNotes: {
      condition: areMeetingNotesEnabled && !!meetingId,
    },
    versionHistorySideBar: {
      condition: !isUserDocumentOrFolder,
    },
    detailsSideBar: {
      condition: !isUserDocumentOrFolder,
    },
    associatedFilesSidebar: {
      condition: !isUserDocumentOrFolder && hasAssociatedFiles,
    },
  }

  /** Ensure we are only allowing a single instance of each option */
  const sidebarOptionOrder = new Set<SidebarOptions>([
    SidebarOptions.speakerNotesSidebar,
    SidebarOptions.meetingNotes,
    SidebarOptions.versionHistorySideBar,
    SidebarOptions.associatedFilesSidebar,
    SidebarOptions.detailsSideBar,
  ])

  /** Iterator to generate available options array */
  const getAvailableSidebarOptions = () => {
    const options:SidebarOptions[] = []
    sidebarOptionOrder.forEach(option => options.push(
      ...(optionAvailabilityConfigs[option].condition ? [option] : []),
    ))
    return options
  }

  const { isOnline } = useAppSettings()
  const availableSidebarOptions = getAvailableSidebarOptions()

  const { latestPublishedDocumentVersionORM } = { ...activeDocumentVersionORM?.relations.documentORM.relations.version }
  const { MSLShare } = { ...latestPublishedDocumentVersionORM?.meta.permissions }

  const isLatestPublished = activeDocumentVersionORM?.meta.version.isLatestPublished
  const canDownload = activeDocumentVersionORM?.model.type !== FileType.WEB && (isPublisher ||
    (isLatestPublished && activeDocumentVersionORM?.meta.permissions.MSLDownload))

  // Only the lastest published version of the distributable document that is not revoked can be shared
  const isNotRevoked = activeDocumentVersionORM?.meta.sealedStatus !== DocumentStatus.REVOKED
  const canShare = isNotRevoked && isLatestPublished && MSLShare

  const toggleSidebar = () => {
    selectedSideBarOption ? setSelectedSideBarOption(null) : setSelectedSideBarOption(availableSidebarOptions[0])
  }

  const SelectedSidebar = selectedSideBarOption && tabVariant[selectedSideBarOption]

  return (
    <DNABox
      testID="content-preview-modal-side-bar"
      appearance="row"
      style={util.mergeStyles(
        undefined,
        styles.mainWrapper,
        [{ width: 377, borderLeftWidth: 1, borderColor: colors['color-gray-80'] }, !!selectedSideBarOption],
      )}
    >
      <DNABox appearance="row">
        <Iffy is={!!selectedSideBarOption}>
          <DNABox fill as={ScrollView} style={{ width: 320 }} spacing="md" appearance="col" childFill={1}>
            <DNABox testID="side-bar-header-text" fill appearance="col" spacing="md" style={{ padding: 18 }}>
              <DNAText testID="side-bar-text" h4>
                {tabConfigs.find(p => p.id === selectedSideBarOption)?.label || ''}
              </DNAText>
              <DNADivider />
            </DNABox>
            {SelectedSidebar &&
              <DNABox style={{ paddingHorizontal: 24, paddingBottom: 20 }} fill>
                <SelectedSidebar/>
              </DNABox>
              }
          </DNABox>
        </Iffy>
        <DNABox
          spacing="between"
          alignX="center"
          appearance="col"
          style={{ borderLeftWidth: 1, borderColor: colors['color-gray-80'], width: 56 }}
        >
          <DNABox spacing="xs" appearance="col">
            <DNAPopover placement="left" disablePopover={['tabletPWA']}>
              <DNAPopover.Anchor>
                <DNAButton
                  status="tertiary"
                  appearance="ghostLink"
                  style={styles.arrowIcon}
                  iconLeft={!selectedSideBarOption ? 'chevron-left' : 'chevron-right'}
                  onPress={toggleSidebar}
                  size="md"
                />
              </DNAPopover.Anchor>
              <DNAPopover.Content>
                <DNAText
                  style={styles.tooltipText}
                  numberOfLines={1}
                >
                  {selectedSideBarOption ? 'Collapse' : 'Expand'}
                </DNAText>
              </DNAPopover.Content>
            </DNAPopover>
            <DNADivider />
            {availableSidebarOptions.map(config => {
              const item = tabConfigs.find(p => p.id === config)
              return (item &&
                <DNAPopover placement="left" key={item.key} disablePopover={['tabletPWA']}>
                  <DNAPopover.Anchor>
                    <DNAButton
                      appearance={config === selectedSideBarOption ? 'filled' : 'ghost'}
                      status={config === selectedSideBarOption ? 'primary' : 'tertiary'}
                      style={styles.sideBarIcon}
                      iconLeft={item.icon}
                      onPress={() => setSelectedSideBarOption(item.id)}
                      size="md"
                    />
                  </DNAPopover.Anchor>
                  <DNAPopover.Content>
                    <DNAText style={styles.tooltipText} numberOfLines={1}>{item?.tooltip}</DNAText>
                  </DNAPopover.Content>
                </DNAPopover>)
            })
            }
          </DNABox>

          {isOnline && activeDocumentVersionORM && !isModifiedDocumentVersion &&
            <DNABox appearance="col" alignX="center" alignY="end">
              {canShare &&
                <DNAPopover placement="left" disablePopover={['tabletPWA']}>
                  <DNAPopover.Anchor>
                    <DNAButton
                      testID="share-icon"
                      status="tertiary"
                      appearance="ghostLink"
                      size="md"
                      style={styles.sideBarIcon}
                      iconLeft="share"
                      onPress={shareEmail(activeDocumentVersionORM, undefined, meetingId)}
                    />
                  </DNAPopover.Anchor>
                  <DNAPopover.Content>
                    <DNAText style={styles.tooltipText} numberOfLines={1}>Share</DNAText>
                  </DNAPopover.Content>
                </DNAPopover>
              }

              {canDownload &&
                <DNAPopover placement="left" disablePopover={['tabletPWA']}>
                  <DNAPopover.Anchor>
                    <DNAButton
                      status="tertiary"
                      appearance="ghostLink"
                      size="md"
                      style={styles.sideBarIcon}
                      iconLeft="download"
                      onPress={() => documentActions.download(activeDocumentVersionORM.relations.documentORM)()}
                    />
                  </DNAPopover.Anchor>
                  <DNAPopover.Content>
                    <DNAText style={styles.tooltipText} numberOfLines={1}>Download</DNAText>
                  </DNAPopover.Content>
                </DNAPopover>}
            </DNABox>}

        </DNABox>
      </DNABox>

    </DNABox>
  )
}
/** !SECTION: Functional Components */

export default Sidebar
