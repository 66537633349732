import React from 'react';
import { DNABox, DNAButton, DNADivider, DNAIcon, DNAChip, DNAText, Iffy } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { StyleSheet } from 'react-native';
import { useAddMeeting } from './AddMeetingProvider';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import { CRMConnectionStatus } from 'src/state/machines/CRM/crmMachineTypes';
import { MeetingStatus } from '@alucio/aws-beacon-amplify/src/models';
import { CRMOpenCallButton, CRMSubmitButton, useCanDeleteMetting } from './AddMeetingFooter';

const styles = StyleSheet.create({
  titleContainer: {
    height: 56,
    paddingLeft: 24,
    paddingRight: 24,
  },
  title: {
    color: colors['gray-600'],
  },
  contentContainer: {
    paddingLeft: 24,
    paddingRight: 24,
  },
})

const AddMeetingHeaderDesktop = () => {
  const { onCancel } = useAddMeeting()

  return (
    <DNABox appearance="col">
      <DNABox alignY="center" appearance="row" spacing="between" style={styles.titleContainer}>
        <DNAText bold h5 style={styles.title}>Meeting Event</DNAText>
        <DNABox>
          <DNAButton
            appearance="ghost"
            status="tertiary"
            size="md"
            padding="sm"
            iconLeft="close"
            onPress={onCancel}
          />
        </DNABox>
      </DNABox>
      <DNADivider />
    </DNABox>
  )
}

const AddMeetingHeaderTablet = () => {
  const {
    onCancel,
    meetingORM,
    onSave,
    onDelete,
    isSubmitting,
    isReadOnly,
  } = useAddMeeting()

  const dateLastSyncedAt = new Date(meetingORM?.model.crmRecord?.lastSyncedAt || '');
  const lastSyncedAt = 'Saved to CRM on ' + dateLastSyncedAt.toLocaleDateString() + ' at ' +
    dateLastSyncedAt.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  const { connectionStatus } = useCRMStatus();
  const isMeetingLocked = meetingORM?.model.status === MeetingStatus.LOCKED

  const { deleteDisabled } = useCanDeleteMetting(meetingORM);

  return (
    <DNABox appearance="col">
      <DNABox alignY="center" appearance="row" spacing="md" childFill={2} style={styles.titleContainer}>
        <DNAButton
          disabled={isSubmitting}
          status="tertiary"
          appearance="ghost"
          iconLeft="close"
          onPress={onCancel}
        />
        <DNAText bold h5 style={styles.title}>Meeting Event</DNAText>
        <DNABox alignX="end" spacing="sm" alignY="center">
          <Iffy is={isReadOnly}>
            <DNABox spacing="sm" alignY="center">
              {/* Open in CRM */}
              <Iffy is={meetingORM?.model.crmRecord?.crmCallId}>
                <CRMOpenCallButton meetingORM={meetingORM} />
              </Iffy>
              <DNAIcon name="lock" size="md" style={{ fontSize: 20, color: colors['color-gray-400'] }} />
              <DNAText status="flat">
                Submitted to CRM on {lastSyncedAt} and is now locked.
              </DNAText>
            </DNABox>
          </Iffy>
          <Iffy is={meetingORM?.model.crmRecord?.crmCallId && !isReadOnly}>
            <DNAChip status="basic" size="md">
              {lastSyncedAt}
            </DNAChip>
          </Iffy>
          <Iffy is={!isReadOnly}>
            {/* Delete Button */}
            <DNAButton
              disabled={isMeetingLocked || deleteDisabled}
              appearance="outline"
              size="sm"
              padding="sm"
              status="tertiary"
              style={{ paddingVertical: 5 }}
              iconRight="trash-can-outline"
              onPress={onDelete}
            />
          </Iffy>
          {/* Open in CRM */}
          <Iffy is={meetingORM?.model.crmRecord?.crmCallId}>
            <CRMOpenCallButton meetingORM={meetingORM} />
          </Iffy>
          {/* CRM Submit Button */}
          <Iffy is={connectionStatus === CRMConnectionStatus.CONNECTED && !isReadOnly}>
            <CRMSubmitButton />
          </Iffy>
          {/* Save Button */}
          <Iffy is={!isReadOnly}>
            <DNAButton
              style={{ maxHeight: 43 }}
              appearance="filled"
              status={isSubmitting ? 'success' : undefined}
              disabled={isSubmitting}
              onPress={() => onSave()}
            >
              {isSubmitting ? 'Saving...' : 'Save'}
            </DNAButton>
          </Iffy>
        </DNABox>
      </DNABox>
      <DNADivider />
    </DNABox>
  )
}

type DeviceModeVariant = Record<DeviceMode, React.ElementType>
const deviceModeVariant:DeviceModeVariant = {
  desktop: AddMeetingHeaderDesktop,
  tablet: AddMeetingHeaderTablet,
}

const AddMeetingHeader = () => {
  const { deviceMode } = useAppSettings()
  const CurrentDeviceModeVariant = deviceModeVariant[deviceMode]

  return <CurrentDeviceModeVariant/>
}

export default AddMeetingHeader
