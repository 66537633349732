import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react'
import { StyleSheet, ScrollView } from 'react-native';
import { compose } from 'redux'
import { useParams } from 'src/router';
import {
  Iffy,
  DNABox,
  DNAButton,
  DNAText,
  DNADivider,
  luxColors,
  DNACard,
  DNACheckbox,
} from '@alucio/lux-ui'

import DNADocumentGridList from 'src/components/DNA/GridList/DNADocumentGridList'
import DNAGrid from 'src/components/DNA/Grid/DNAGrid'
import DNAPagination from 'src/components/DNA/Pagination/DNAPagination'
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import DNADocumentFilters, {
  useDNADocumentFilters,
  withDNADocumentFilters,
} from 'src/components/DNA/Document/DNADocumentFilters/DNADocumentFilters'

import docQuery, { merge } from 'src/state/redux/document/query'
import useDisplayMode, { DisplayModes } from 'src/components/DNA/hooks/useDisplayMode'
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings'
import { useSort, withSort } from 'src/components/DNA/hooks/useSort'
import { useAllDocumentsInstance } from 'src/state/redux/selector/document'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import useFixedHeader from '@alucio/lux-ui/src/hooks/useFixedLayout'
import { DocumentORM, FeatureFlags } from 'src/types/types';
import { SearchInputContext } from 'src/components/Header/SearchInput/SearchInput';
import GridListTabletHeader from 'src/components/DNA/GridList/GridListTabletHeader'
import { useTenant } from 'src/state/redux/selector/tenant';
import ActiveUser from 'src/state/global/ActiveUser';
import QuickFiltersProvider, { useQuickFilters } from 'src/components/QuickFilters/QuickFiltersProvider';
import FiltersSidebar, { FilterButton, VariantOptions } from 'src/components/QuickFilters/FiltersSidebar';
import useFeatureFlag from 'src/hooks/useFeatureFlag/useFeatureFlag';
import DocumentSelectManager, { useDocumentSelectManager } from './DocumentSelectManager';
import SelectedCollapsibleCard from './SelectedCollapsibleCard';
import DocumentSearchProvider, { useDocumentSearchV2, useDocumentSearchV2Context } from 'src/hooks/useDocumentSearchV2';
import ListSkeleton from 'src/components/ListSkeleton/ListSkeleton';

const styles = StyleSheet.create({
  mobileGridViewContainer: {
    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  mobileGridViewHeader: {
    backgroundColor: colors['color-gray-10'],
    paddingHorizontal: 16,
    paddingVertical: 5,
  },
  selectedFilters: {
    backgroundColor: colors['color-brand2-500'],
    borderRadius: 3,
    left: 20,
    paddingHorizontal: 4,
    paddingVertical: 2,
    position: 'absolute',
    top: 0,
  },
});

export const useDNALibrary = (searchText?: string, fromPublisher?: boolean) => {
  const { displayMode, displayModeIconName, toggleDisplayMode } = useDisplayMode()
  const { sortSelectorOpts } = useSort()
  const { filterSelectorOpts, unpublishedToggle } = useDNADocumentFilters()

  const filteredDocsSelectorOpts = useMemo(
    () => docQuery.merge(
      docQuery.filters.published, filterSelectorOpts, sortSelectorOpts),
    [sortSelectorOpts, filterSelectorOpts],
  )

  const allDocsSelectorOpts = useMemo(
    () => fromPublisher
      ? unpublishedToggle
        ? docQuery.merge(docQuery.filters.hasUnpublishedVersion, sortSelectorOpts)
        : docQuery.merge(sortSelectorOpts)
      : docQuery.merge(
        docQuery.filters.published, sortSelectorOpts),
    [sortSelectorOpts, filterSelectorOpts],
  )

  const { query } = useDocumentSearchV2(searchText);

  const isSearch = searchText && searchText !== ''

  // [NOTE] - Could possibly refactor this to use two separate `useDocumentSearchV2` and let those handle memoization
  const { allDocsQuery, filteredDocsQuery }  = useMemo(
    () => {
      const allDocsQuery =  query
        ? merge(query, allDocsSelectorOpts)
        : allDocsSelectorOpts;

      const filteredDocsQuery = query
        ? merge(query, filteredDocsSelectorOpts)
        : filteredDocsSelectorOpts;

      return { allDocsQuery, filteredDocsQuery }
    },
    [query, allDocsSelectorOpts, filteredDocsSelectorOpts],
  )

  // [TODO] - Should attempt to not have so many instances
  const allTenantDocuments: DocumentORM[] = useAllDocumentsInstance(allDocsSelectorOpts)
  const allSearchedDocuments = useAllDocumentsInstance(allDocsQuery)
  const filteredSearchDocuments = useAllDocumentsInstance(filteredDocsQuery);
  const allDocuments: DocumentORM[] = isSearch ? allSearchedDocuments : allTenantDocuments;
  const filteredDocuments: DocumentORM[] = useAllDocumentsInstance(filteredDocsSelectorOpts);
  const documents = isSearch ? filteredSearchDocuments : filteredDocuments;

  return {
    displayMode: isSearch ? DisplayModes.list : displayMode,
    displayModeIconName,
    toggleDisplayMode,
    selectorOpts: query ? merge(query, filteredDocsSelectorOpts) : filteredDocsSelectorOpts, // used in dnapagination
    documents, // used by Tablet: quick filters, search header, filter modal, search results | Desktop: displaying number of search results
    allDocuments, // used by modal that is not visible anymore in desktop
    allTenantDocuments, // used for bulk select (important for persisting checked documents)
  }
}

interface DNALibraryProps {
  toggleSearchMessage?: boolean
  disableQuickFilters?: boolean
  initUserDefaultFilters?: boolean
}

export const DNALibraryDesktop: React.FC<DNALibraryProps> = () => {
  const { searchText } = useParams<{ searchText?: string }>();
  const {
    displayMode,
    displayModeIconName,
    toggleDisplayMode,
    selectorOpts,
    documents,
    allTenantDocuments,
  } = useDNALibrary(searchText)
  const {
    selectEnabled,
    setSelectEnabled,
    selectedDocuments,
    setSelectedDocuments,
    onSelectDocuments,
  } = useDocumentSelectManager()
  /** NOTE: This should probably be moved into the useDocumentSelectManager for reusability but the context does
   * not currently retain a full list of the documents only a subset of what was passed in via onSelectDocuments */
  const allDocumentsSelected = documents.every(({ model:{ id } }) => selectedDocuments[id])

  const { isLoadingSearch } = useDocumentSearchV2Context();

  const isSearch = !!searchText?.length;
  const showLoader = isLoadingSearch && isSearch;

  useEffect(
    () => { setSelectEnabled(false) },
    [searchText],
  )

  const { handleClear: handleClearQuickFilters } = useQuickFilters();

  const isBulkAddToFolderEnabled = useFeatureFlag(FeatureFlags.BEAC_1832_bulk_add_to_folder)
  const isBulkShareEmailEnabled = useFeatureFlag(FeatureFlags.BEAC_2131_bulk_share_email)
  const isBulkSelectEnabled = isBulkAddToFolderEnabled || isBulkShareEmailEnabled

  const tenantORM = useTenant(ActiveUser.user!.tenantId);
  const quickFilters = !!tenantORM?.tenant.config.quickFilters;
  const [filtersVisible, setFiltersVisible] = useState(quickFilters);

  const fixedHeaderWidth = useFixedHeader({ offSet: 200 });
  const selectedDocumentORMs = useMemo(() => allTenantDocuments.filter(doc => selectedDocuments[doc.model.id]),
    [allTenantDocuments, selectedDocuments])

  const paginatedContent: ReactElement = (
    <DNABox
      as={ScrollView}
      appearance="col"
      spacing="md"
    >
      <Iffy is={selectEnabled}>
        <DNABox
          spacing="md"
          appearance="col"
          style={[isSearch && { width: fixedHeaderWidth - (filtersVisible ? 400 : 0) }]}
        >
          <SelectedCollapsibleCard
            selectedDocuments={selectedDocumentORMs}
            onSelectDocuments={onSelectDocuments}
            setSelectedDocuments={setSelectedDocuments}
            displayMode={displayMode}
            isSearch={isSearch}
          />
          {displayMode === DisplayModes.grid &&
            <DNACard>
              <DNABox alignY="center" style={{ padding: 15 }}>
                <DNACheckbox
                  checked={allDocumentsSelected}
                  onChange={onSelectDocuments ? () => onSelectDocuments(documents) : undefined}
                >
                  Select All
                </DNACheckbox>
              </DNABox>
            </DNACard>
          }
        </DNABox>
      </Iffy>
      <Iffy is={displayMode === DisplayModes.list}>
        <DNADocumentGridList.Paginated
          emptyVariant={EmptyVariant.DocumentListEmptySearch}
          selectEnabled={selectEnabled}
          selectedDocuments={selectedDocuments}
          onSelectDocuments={onSelectDocuments}
          isSearch={isSearch}
          filtersVisible={filtersVisible}
        />
      </Iffy>
      <Iffy is={displayMode === DisplayModes.grid}>
        <DNABox
          /** TODO: copying the implementation above. However, manually setting this width based on the fixed header width
           * seems to be a high maintenance approach, Should refactor this to automagically fill available viewport space */
          style={[{ width: fixedHeaderWidth - (filtersVisible ? 400 : 0) }]}
        >
          <DNAGrid.Paginated
            emptyVariant={EmptyVariant.DocumentListEmptySearch}
            selectEnabled={selectEnabled}
            selectedDocuments={selectedDocuments}
            onSelectDocuments={onSelectDocuments}
          />
        </DNABox>
      </Iffy>
      <DNABox style={{ alignSelf: 'flex-start', marginTop: 8 }}>
        <DNAPagination.Nav />
      </DNABox>
    </DNABox>
  );

  return (
    <DNABox alignX="center">
      <DNABox
        fill
        style={{
          // This is a jank workaround to fill the space so the pinkish bg doesn't show
          // flex doesn't work well here, so we calculate the screen height - (header + footer)
          minHeight: 'calc(100vh - 160px)',
          minWidth: 'calc(100vw - 158px)',
          paddingHorizontal: 48,
          paddingVertical: 20,
          maxWidth: displayMode === DisplayModes.grid ? fixedHeaderWidth : undefined,
        }}
      >
        <DNABox fill spacing="md" appearance="col">
          {/* Header */}
          <DNABox alignY="center" style={{ maxWidth: fixedHeaderWidth }}>
            {
              searchText != null
                ? (
                  <DNAText testID="page-title" h5 status="secondary">
                    {/* eslint-disable-next-line max-len */}
                    {showLoader ? `Search results for "${searchText}"` : `Search results for "${searchText}" | ${documents.length} result${documents.length === 1 ? '' : 's'}`
                    }
                  </DNAText>
                )
                : <DNAText testID="page-title" h5 status="secondary">Library</DNAText>
            }
          </DNABox>
          <DNADivider />
          <DNABox alignY="center" spacing="between" style={{ maxWidth: fixedHeaderWidth }}>
            <DNABox testID="filters-button">
              <FilterButton
                setFiltersVisible={setFiltersVisible}
                filtersVisible={filtersVisible}
                variant={VariantOptions.library}
              />
            </DNABox>
            <DNABox spacing="sm">
              <Iffy is={isBulkSelectEnabled}>
                <DNAButton
                  testID="header-select-button"
                  size="md"
                  padding="sm"
                  status="secondary"
                  appearance={selectEnabled ? 'filled' : 'outline'}
                  rounded="md"
                  iconLeft={selectEnabled ? undefined : 'checkbox-marked-outline'}
                  onPress={() => setSelectEnabled(pre => !pre)}
                >
                  {selectEnabled ? 'Cancel' : 'Select'}
                </DNAButton>
              </Iffy>
              <Iffy is={!isSearch}>
                <DNAButton
                  size="md"
                  padding="sm"
                  appearance="outline"
                  status="secondary"
                  iconLeft={displayModeIconName}
                  onPress={toggleDisplayMode}
                />
              </Iffy>
            </DNABox>
          </DNABox>
          <DNADivider />
          <DNABox spacing="between" alignY="start" childFill={0} style={{ maxWidth: fixedHeaderWidth }}>
            <DNADocumentFilters.Chips
              documents={documents}
              onClearFilters={handleClearQuickFilters}
              isLoading={showLoader}
            />
          </DNABox>
          <DNABox
            fill
            spacing="md"
            childStyle={[1, { flex: 1 }]}
          >
            {<DNABox
              as={ScrollView}
              appearance="col"
              fill
              style={[!filtersVisible && { display: 'none' }, { maxHeight: 'calc(100vh - 300px)' }]}
            >
              <FiltersSidebar setVisible={setFiltersVisible} textValue={searchText} />
            </DNABox>}
            {/* PAGINATED CONTENT */}
            {showLoader
              ? <DNABox style={{ width: fixedHeaderWidth - (filtersVisible ? 400 : 0) }}>
                <ListSkeleton numberOfItems={5} />
              </DNABox>
              : (
                <DNAPagination
                  query={selectorOpts}
                >
                  {paginatedContent}
                </DNAPagination>
              )
            }
          </DNABox>
        </DNABox>
      </DNABox>
    </DNABox>
  );
}

export const DNALibraryTablet: React.FC<DNALibraryProps> = ({
  toggleSearchMessage,
  disableQuickFilters,
}) => {
  const { searchText } = useContext(SearchInputContext)
  const { displayMode, documents, allTenantDocuments } = useDNALibrary(searchText)
  const {
    selectEnabled,
    setSelectEnabled,
    selectedDocuments,
    setSelectedDocuments,
    onSelectDocuments,
  } = useDocumentSelectManager()
  const allDocumentsSelected = documents.every(({ model:{ id } }) => selectedDocuments[id])

  const [filtersVisible, setFiltersVisible] = useState(false);
  const selectedDocumentORMs = useMemo(() => allTenantDocuments.filter(doc => selectedDocuments[doc.model.id]),
    [allTenantDocuments, selectedDocuments])
  const { quickFilters } = useQuickFilters();

  const { isLoadingSearch } = useDocumentSearchV2Context();

  const isLoading = isLoadingSearch && !!searchText.length;

  useEffect(() => {
    quickFilters.length > 0 && setFiltersVisible(true)
  }, [quickFilters])

  const setFiltersVisibleHandler = (visible: boolean) => {
    analytics?.track('LIBRARY_TOGGLE_FILTER', {
      action: 'FILTER',
      category: searchText.trim().length ? 'VIEWER_SEARCH_RESULTS' : 'VIEWER_LIBRARY',
      context: 'VIEWER',
      state: visible ? 'VISIBLE' : 'HIDDEN',
    })
    setFiltersVisible(visible);
  };

  useEffect(() => {
    // When the searchText changed, turn off the select mode
    setSelectEnabled(false)
  }, [searchText])

  if (toggleSearchMessage) {
    return (
      <DNABox fill style={{ minWidth: 'fit-content' }}>
        <DNAEmpty emptyVariant={EmptyVariant.SearchInstructions} />
      </DNABox>
    )
  }
  const paginatedContent: ReactElement = (
    <DNABox
      appearance="col"
      fill
      style={{ minWidth: 'fit-content' }}
      spacing="md"
    >
      <Iffy is={selectEnabled}>
        <DNABox appearance="col" spacing="md">
          <SelectedCollapsibleCard
            selectedDocuments={selectedDocumentORMs}
            onSelectDocuments={onSelectDocuments}
            setSelectedDocuments={setSelectedDocuments}
            displayMode={displayMode}
            isSearch={!!searchText.length}
          />
        </DNABox>
        {displayMode === DisplayModes.grid &&

          <DNABox alignY="center" style={{ marginHorizontal: 15 }}>
            <DNACard>
              <DNABox alignY="center" style={{ padding: 20 }}>
                <DNACheckbox
                  checked={allDocumentsSelected}
                  onChange={onSelectDocuments ? () => onSelectDocuments(documents) : undefined}
                >Select All</DNACheckbox>
              </DNABox>
            </DNACard>
          </DNABox>

          }
      </Iffy>
      <Iffy is={displayMode === DisplayModes.list}>
        <DNADocumentGridList
          emptyVariant={EmptyVariant.DocumentListEmptySearch}
          documents={documents}
          selectEnabled={selectEnabled}
          selectedDocuments={selectedDocuments}
          onSelectDocuments={onSelectDocuments}
          isSearch={!!searchText?.length}
        />
      </Iffy>
      <Iffy is={displayMode === DisplayModes.grid}>
        <DNABox as={ScrollView} fill appearance="col" spacing="md">
          {/* CONTENT */}
          <DNABox style={styles.mobileGridViewContainer}>
            <DNAGrid
              emptyVariant={EmptyVariant.DocumentGridEmptySearch}
              items={documents}
              selectEnabled={selectEnabled}
              selectedDocuments={selectedDocuments}
              onSelectDocuments={onSelectDocuments}
            />
          </DNABox>
        </DNABox>
      </Iffy>
    </DNABox>
  );

  return (
    <DNABox fill>
      <DNABox style={!filtersVisible && { display: 'none' }}>
        <FiltersSidebar
          setVisible={setFiltersVisibleHandler}
          textValue={searchText}
          disableQuickFilters={disableQuickFilters}
        />
      </DNABox>

      <DNABox fill style={{ backgroundColor: luxColors.info.primary }}>
        <DNABox fill appearance="col">
          <GridListTabletHeader
            searchText={searchText}
            totalRecordCount={documents.length}
            setFiltersVisible={setFiltersVisibleHandler}
            isFiltersVisible={filtersVisible}
            isLoading={isLoading}
          />
          <DNADivider />
          {isLoading
            ? <ListSkeleton numberOfItems={5} />
            : (
              <DNABox fill style={{ overflow: 'scroll' }}>
                {paginatedContent}
              </DNABox>
            )
          }
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

type DeviceModeVariantComponent = Record<DeviceMode, React.FC<DNALibraryProps>>

const DNALibrarySwitcher: React.FC<DNALibraryProps> = (props) => {
  const { deviceMode } = useAppSettings()
  const { quickFilters } = { ...useTenant(ActiveUser.user!.tenantId)?.tenant.config }

  const deviceModeVariants: DeviceModeVariantComponent = {
    desktop: DNALibraryDesktop,
    tablet: DNALibraryTablet,
  }

  const CurrentDeviceModeVariant = deviceModeVariants[deviceMode]

  return (
    <QuickFiltersProvider quickFilters={quickFilters ?? []}>
      <DocumentSearchProvider mode="VIEWER_RESULTS">
        <DocumentSelectManager>
          <CurrentDeviceModeVariant {...props} />
        </DocumentSelectManager>
      </DocumentSearchProvider>
    </QuickFiltersProvider>
  )
}

export default compose<React.FC<DNALibraryProps>>(
  withDNADocumentFilters,
  withSort,
)(DNALibrarySwitcher)
