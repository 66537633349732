import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch } from 'src/state/redux';
import { drawerActions } from 'src/state/redux/slice/drawer';
import { useComposableForm } from '../CustomFields/ComposableForm';
import { isEmpty } from 'lodash';
import { formToModel } from '../CustomFields/ComposableFormUtilities';
import { API, graphqlOperation } from '@aws-amplify/api';
import { submitTenantForm } from '@alucio/aws-beacon-amplify/src/graphql/mutations';
import { SubmitTenantFormMutation } from '@alucio/aws-beacon-amplify/lib/API';
import { useAppSettings } from 'src/state/context/AppSettings';
import { GenericToast, ToastOrientations, useToast } from '@alucio/lux-ui'

export enum GLOBAL_TENANT_FORM_ERROR {
  api_error = 'Sorry, we were unable to save this record.',
  validation_error = 'Please correct the error(s).',
  offline = 'Please connect to the internet to edit form.'
}

interface TenantFormContext {
  onCancel: () => void
  isDirty: boolean,
  isValid: boolean,
  isSubmitting: boolean,
  isFormEdited: boolean,
  onSave: () => void,
  formId: string,
  toggleDrawer: () => void,
  error?: GLOBAL_TENANT_FORM_ERROR,
}

const TenantFormContext = React.createContext<TenantFormContext>(null!);

interface TenantFormProviderProps {
  formId: string
  toggleDrawer: (ignoreChanges?: boolean) => void
}

export const TenantFormProvider: React.FC<PropsWithChildren<TenantFormProviderProps>> = ({
  formId,
  toggleDrawer,
  children,
}) => {
  const dispatch = useDispatch();
  const { isOnline } = useAppSettings();
  const { rhForm } = useComposableForm()
  const { formState } = rhForm
  const { isDirty, isValid, isSubmitSuccessful, touched } = formState
  const toast = useToast()

  const [isSubmitting, setIsSubmitting] = useState(isSubmitSuccessful);
  const [error, setError] = useState<GLOBAL_TENANT_FORM_ERROR>();

  const onCancel = () => {
    toggleDrawer()
  }

  useEffect(() => {
    if (!isOnline) setError(GLOBAL_TENANT_FORM_ERROR.offline)
    else setError(undefined)
  }, [isOnline])

  const isFormEdited =
    (isDirty && isValid) ||
    (isEmpty(touched) && !isDirty)

  const onSave = async () => {
    const isValid = await rhForm.trigger()
    if (isValid) {
      setError(undefined)
      rhForm.handleSubmit(async (values) => {
        try {
          setIsSubmitting(true)
          const { data } = await API.graphql(
            graphqlOperation(submitTenantForm, {
              formId,
              customValues: formToModel(values),
            }),
          ) as {
            data: SubmitTenantFormMutation;
          };

          if (data.submitTenantForm) {
            toast.add(<GenericToast
              title="Form successfully submitted"
              status="success"
            />,
            ToastOrientations.TOP_RIGHT)
            analytics?.track('TENANT_FORM_SUBMIT', {
              category: 'TENANT_FORM',
              context: 'TENANT_FORM',
              formID: formId,
            })
            toggleDrawer(true)
          }
          else {
            setError(GLOBAL_TENANT_FORM_ERROR.api_error)
            analytics?.track('TENANT_FORM_ERROR', {
              category: 'TENANT_FORM',
              context: 'TENANT_FORM',
              formID: formId,
            })
          }
        } catch (err) {
          setError(GLOBAL_TENANT_FORM_ERROR.api_error)
          console.error(`error: ${JSON.stringify(err)}`);
          analytics?.track('TENANT_FORM_ERROR', {
            category: 'TENANT_FORM',
            context: 'TENANT_FORM',
            formID: formId,
          })
        }
        finally {
          setIsSubmitting(false)
        }
      }, (errors) => {
        console.warn(errors)
      })()
    }
    else {
      setError(GLOBAL_TENANT_FORM_ERROR.validation_error)
    }
  }

  useEffect(() => {
    dispatch(drawerActions.setPendingChanges(
      {
        hasPendingChanges: isDirty,
      },
    ),
    )
  }, [isDirty])

  const context: TenantFormContext = {
    onCancel,
    isDirty,
    isValid: rhForm.formState.isValid,
    isSubmitting,
    isFormEdited,
    onSave,
    formId,
    toggleDrawer,
    error,
  }

  return (
    <TenantFormContext.Provider value={context}>
      {children}
    </TenantFormContext.Provider>
  )
}

export const useTenantForm = () => React.useContext(TenantFormContext)
