import React from 'react';
import {
  DNABox,
  DNAButton,
  DNACard,
  DNAChip,
  DNAText,
  Icon,
  Iffy,
  Stack,
} from '@alucio/lux-ui/src';
import { AttachedFile } from '@alucio/aws-beacon-amplify/src/models';
import { downloadContentFromCloudfront } from 'src/utils/loadCloudfrontAsset/loadCloudfrontAsset';
import { styles, RowProps, AssociatedFileDetails } from 'src/screens/Hubs/EditHub/Widgets/SharedFiles';

interface AttachedFileRowProps extends RowProps {
  associatedContent: AssociatedFileDetails
}

const AttachedFileRow: React.FC<AttachedFileRowProps> = ({ associatedContent, isLastItem, handleDeleteItem }) => {
  const file = associatedContent.associatedFileORM.file as AttachedFile
  const isRequired = associatedContent.isRequired
  const handleDownload = () => downloadContentFromCloudfront(file.srcFile.key, file.srcFileName, file.type)
  return (
    <DNACard
      onPress={handleDownload}
      interactive="pressableAlt"
      appearance="flat"
      style={isLastItem ? undefined : styles.bottomBorder}
    >
      <DNABox alignY="center" style={styles.row}>
        <DNABox style={[styles.documentThumbnailBorder, styles.attachedFileThumbnailBox]}>
          <Stack>
            <Stack.Layer>
              <DNABox fill alignX="center" alignY="center" style={styles.attachedFileThumbnailBox}>
                <Icon name="file-document-outline" style={styles.attachedFileIcon} />
              </DNABox>
            </Stack.Layer>
            <Stack.Layer anchor="bottomLeft">
              <DNAChip appearance="tag">{file.type}</DNAChip>
            </Stack.Layer>
          </Stack>
        </DNABox>
        <DNABox fill appearance="col" alignY="center" spacing="sm">
          <DNAText>{file.srcFileName}</DNAText>
          <DNABox spacing="sm">
            <Iffy is={isRequired}>
              <DNAChip appearance="tag">REQUIRED</DNAChip>
            </Iffy>
          </DNABox>
        </DNABox>
        <DNABox alignY="center" spacing="sm">
          <DNAButton
            iconLeft="download"
            appearance="ghostLink"
            status="gray"
            size="md"
            padding="sm"
            onPress={handleDownload}
          />
          <Iffy is={!isRequired}>
            <DNAButton
              iconLeft="trash-can-outline"
              appearance="ghostLink"
              status="gray"
              size="md"
              padding="sm"
              onPress={() => handleDeleteItem(associatedContent.associatedFileId, associatedContent)}
            />
          </Iffy>
        </DNABox>
      </DNABox>
    </DNACard>
  )
}

export default AttachedFileRow