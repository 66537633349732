import {
  AttendeeType,
  CrmSyncStatus,
  CustomFieldDefinition,
  MeetingAttendeeStatus,
  ObjectRecordStatus,
} from '@alucio/aws-beacon-amplify/src/models';
import { v4 as uuid } from 'uuid';
import { getFirstSubmitRecordPayload, getUpsertRecords, SalesforceFormTranslator } from './SalesforceFormTranslator';
import {
  CRMAccount,
  CRMAddress,
  CRMSubmitMeetingPayload,
  FormSettings,
  LayoutItem,
  LayoutSection, RecordToDelete,
  SalesforceFirstSubmitPayload,
  SalesforceFirstSubmitRecord,
  SalesforceFormSettings,
  SalesforceRecordToUpsert,
  SalesforceUpdateSubmit,
  VeevaBasicPayloadFields,
} from '../CRMIndexedDBTypes';
import { FormTranslatorResponse } from '../CRMHandler';
import { handleZvod, SUPPORTED_ZVODS } from './VeevaMarkerHandler';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { Logger } from '@aws-amplify/core';
import format from 'date-fns/format';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { MEETING_SUBMIT_TYPE } from 'src/components/Meeting/AddMeetingProvider';
import { isIOS, isMobile, isTablet } from 'react-device-detect'
import { Singleton as IndexDbCrm } from '../CRMIndexedDB';
import { isSalesforceFirstSubmitPayload } from 'src/types/typeguards';
import { AttendeeForm } from 'src/state/context/Meetings/saveMeetingHelper';
import { isArrayOfObjects, ObjectWithId } from 'src/components/CustomFields/ComposableFormUtilities';
import { FormValuesType } from 'src/components/CustomFields/ComposableForm';
import { MeetingORM } from 'src/types/orms';

const logger = new Logger('VeevaTranslator', 'DEBUG');

export class VeevaFormTranslator extends SalesforceFormTranslator {
  protected formSettings: SalesforceFormSettings[];

  // THESE CRM FIELDS ARE BEING SKIPPED AS THEY WILL BE HANDLED UPON
  // SUBMITTING THE CALL AND WILL BE FILLED USING BEACON FIELDS
  private fieldsToSkip: string[] = [
    'Parent_Address_vod__c',
    'Allowed_Products_vod__c',
    'Call_Datetime_vod__c',
    'Address_vod__c',
    'Account_vod__c',
  ];

  constructor(formSettings: SalesforceFormSettings[], mainTable: string) {
    super(formSettings, mainTable);
    this.formSettings = formSettings;
  }

  //* * SALESFORCE CONFIG TO BEACON **//
  getFormCustomFieldConfig(): FormTranslatorResponse {
    return super.getFormCustomFieldConfig();
  }

  protected processSection(section: LayoutSection, formSettings: SalesforceFormSettings): CustomFieldDefinition[] {
    return super.processSection(section, formSettings);
  }

  protected processItem(item: LayoutItem, formSetting: SalesforceFormSettings): CustomFieldDefinition[] {
    const isZvod = item.label.startsWith('zvod_');
    const fieldId = get(item, 'layoutComponents[0].apiName', '')

    if (this.fieldsToSkip.includes(fieldId)) {
      return []
    }

    if (isZvod) {
      const { apiName } = item.layoutComponents[0];
      return handleZvod(apiName as SUPPORTED_ZVODS, this.formSettings, super.processChild.bind(this));
    }

    return super.processItem(item, formSetting);
  }
}

interface AttendeeMap {
  attendeeForm: AttendeeForm;
  account: CRMAccount;
}

// ** FIRST SUBMIT FUNCTIONS ** //
export async function getVeevaFirstSubmitPayload(
  crmSubmitPayload: CRMSubmitMeetingPayload): Promise<SalesforceFirstSubmitPayload> {
  logger.debug('Getting payload for first Veeva submit.', crmSubmitPayload);
  const { formSettings } = crmSubmitPayload;
  let attendeesPersonsCount = 0;
  const mainRecordSetting = formSettings.find((record) => record.isMainTable);
  // MEETING ATTENDEES WITH THEIR CRM ACCOUNTS
  const attendeesMapped = await getAttendeesMapped(crmSubmitPayload, crmSubmitPayload.formValuesAttendees);

  attendeesMapped.forEach((attendee) => {
    if (attendee.account.IsPersonAccount) {
      attendeesPersonsCount += 1;
    }
  })

  if (!mainRecordSetting) {
    throw Error('Settings for main table not found.');
  }

  // PAYLOAD FOR THE MAIN CALL RECORD
  const mainAccountCallRecord: SalesforceFirstSubmitRecord =
    await getFirstSubmitMainRecordPayload(mainRecordSetting, crmSubmitPayload, attendeesPersonsCount);

  const attendeeRecords = getAttendeesCallRecords(
    attendeesMapped,
    cloneDeep(mainAccountCallRecord),
    mainRecordSetting);

  logger.debug('Attendees records payload:', attendeeRecords);

  // ADDS, TO THE MAIN CALL, A RELATIONSHIP WITH CHILD CALLS
  if (attendeeRecords.length) {
    mainAccountCallRecord.Call2_vod__r = {
      records: attendeeRecords,
    };
  }

  return {
    records: [mainAccountCallRecord],
  };
}

// THIS FUNCTION RETURNS THE "ON THE GO" GENERATED VEEVA SPECIFIC VALUES FOR MAIN RECORD
async function getVeevaMainRecordFieldValues(
  crmSubmitPayload: CRMSubmitMeetingPayload, personAccountsCount: number): Promise<VeevaBasicPayloadFields> {
  const mainAttendee = crmSubmitPayload.formValuesAttendees.find((attendee) =>
    attendee.attendeeType === AttendeeType.PRIMARY);
  const accountId = mainAttendee?.crmAccountId as string | undefined;
  const addressId = mainAttendee?.crmAddressId as string | undefined;

  if (!mainAttendee) {
    throw new Error('Main CRM Attendee not found on FormValueRecord');
  }

  // GETS THE MAIN ACCOUNT RECORD ON INDEXDB
  const account = await IndexDbCrm.getById<CRMAccount>('ACCOUNT', accountId || '');
  const address = account?.addresses?.find((address) => address.id === addressId);

  if (!account || !accountId) {
    throw new Error('Main CRM Attendee not found on IndexDB');
  } else if (!address || !addressId) {
    throw new Error('Main Address not found on CRM Attendee record');
  }

  const accountFields = Object.entries(account).reduce<{[fieldKey: string]: string}>((acc, [fieldKey, value]) => {
    if (typeof value === 'string') {
      acc[fieldKey] = value;
    }
    return acc;
  }, {});

  const Call_Date_vod__c = format(new Date(crmSubmitPayload.startTime), 'Y-MM-dd');
  const Call_Datetime_vod__c = crmSubmitPayload.startTime;
  const veevaDevice = getVeevaDevice();
  const Status_vod__c = crmSubmitPayload.submitType === MEETING_SUBMIT_TYPE.SUBMIT_LOCK_TO_CRM
    ? 'Submitted_vod' : 'Saved_vod';

  return {
    Account_vod__c: accountId,
    Address_vod__c: getFormattedAddressName(address),
    Address_Line_1_vod__c: address.Name,
    Address_Line_2_vod__c: address.Address_Line_1_vod__c,
    Attendee_Type_vod__c: accountFields.IsPersonAccount ? 'Person_Account_vod' : 'Group_Account_vod',
    Attendees_vod__c: personAccountsCount + (accountFields.IsPersonAccount ? 1 : 0),
    Call_Date_vod__c,
    Call_Datetime_vod__c,
    Call_Channel_vod__c: 'Other_vod',
    City_vod__c: address.City_vod__c,
    Credentials_vod__c: accountFields.Credentials_vod__c,
    Duration_vod__c: differenceInMinutes(new Date(crmSubmitPayload.endTime), new Date(crmSubmitPayload.startTime)),
    Last_Device_vod__c: veevaDevice,
    Mobile_Created_Datetime_vod__c: veevaDevice === 'Online_vod' ? '' : Call_Datetime_vod__c,
    Parent_Address_vod__c: addressId,
    Status_vod__c,
    State_vod__c: address.State_vod__c,
    Zip_4_vod__c: address.Zip_4_vod__c,
    Zip_vod__c: address.Zip_vod__c,
  }
}

function getFormattedAddressName(address: CRMAddress): string {
  const fields = ['Address_Line_2_vod__c', 'City_vod__c', 'State_vod__c', 'Zip_vod__c', 'Country_vod__c'];
  return fields.reduce<string>((acc, fieldName) => {
    if (!address[fieldName]) {
      return acc;
    } else if (['Zip_vod__c', 'Country_vod__c'].includes(fieldName)) {
      return `${acc} ${address[fieldName]}`;
    }

    return `${acc}, ${address[fieldName]}`;
  }, address.Name || '');
}

// RETURNS THE BEACON ATTENDEE RECORDS WITH THEIR CRM ACCOUNTS
async function getAttendeesMapped(
  crmSubmitPayload: CRMSubmitMeetingPayload,
  attendees: AttendeeForm[]): Promise<AttendeeMap[]> {
  // FILTER TO ONLY CREATE CHILD CALLS OF THE ADDITIONAL ATTENDEES
  const additionalAttendeeIds = attendees.reduce<string[]>((acc, attendee) => {
    const accountId = attendee.crmAccountId;
    if (typeof accountId === 'string' && attendee.attendeeType === AttendeeType.SECONDARY) {
      acc.push(accountId);
    }
    return acc;
  }, []);
  const additionalAttendees = await IndexDbCrm.filterById('ACCOUNT', additionalAttendeeIds);
  const attendeesMapped = additionalAttendees.reduce<AttendeeMap[]>((acc, account) => {
    const attendee = crmSubmitPayload.formValuesAttendees.find(({ crmAccountId }) => crmAccountId === account.id);

    if (typeof attendee?.id === 'string') {
      acc.push({
        attendeeForm: attendee,
        account,
      });
    }
    return acc;
  }, []);

  return attendeesMapped;
}

// RECEIVES A LIST OF ATTENDEES AND THE MAIN ACCOUNT CALL RECORD
// TO CREATE THE ATTENDEES' CALL RECORDS BASED ON THE MAIN RECORD
// (SAME OBJECT BUT REPLACING SOME VALUES LIKE ACCOUNT_ID)
function getAttendeesCallRecords(
  attendees: AttendeeMap[],
  mainAccountRecord: SalesforceFirstSubmitRecord,
  mainRecordSetting: FormSettings,
  parentCallId?: string):
  SalesforceFirstSubmitRecord[] {
  return attendees.map((attendee) => {
    const attendeeBeaconId = typeof attendee.attendeeForm.id === 'string' ? attendee.attendeeForm.id : '';
    // TRANSFORMS THE ATTENDEE'S CRM VALUES INTO SUBMIT STRUCTURE
    const attendeeSubmitPayload = getFirstSubmitRecordPayload(
      mainRecordSetting.id,
      attendeeBeaconId,
      attendee.attendeeForm.crmValues,
      mainRecordSetting,
    );

    const attendeeCallRecord: SalesforceFirstSubmitRecord = {
      ...mainAccountRecord,
      ...attendeeSubmitPayload,
      Account_vod__c: attendee.account.id,
      Attendee_Type_vod__c: attendee.account.IsPersonAccount ? 'Person_Account_vod' : 'Group_Account_vod',
      Attendees_vod__c: 0,
      Credentials_vod__c: typeof attendee.account.Credentials_vod__c === 'string'
        ? attendee.account.Credentials_vod__c : '',
    };

    if (parentCallId) {
      attendeeCallRecord.Parent_Call_vod__c = parentCallId;
    }

    // ONCE THE ATTENDEE RECORD IS CREATED, IT'S REQUIRED TO ADD TO ITS CHILD RECORDS
    // THE SPECIFIC VEEVA FIELDS FOR THEM. ALSO, ITS CHILD RECORDS MUST HAVE THEIR OWN REFERENCE ID
    return addAdditionalVeevaFieldsToChildObjects(attendeeCallRecord, {
      Account_vod__c: attendee.account.id,
      Attendee_Type_vod__c: attendeeCallRecord.Attendee_Type_vod__c,
    });
  });
}

function getVeevaDevice(): VeevaBasicPayloadFields['Last_Device_vod__c'] {
  if (isTablet) {
    if (isIOS) {
      return 'iPad_vod';
    }
    return 'Tablet_vod';
  } else if (isMobile) {
    if (isIOS) {
      return 'iPhone_vod';
    }
    return 'Mobile_vod';
  }
  return 'Online_vod';
}

// THIS FUNCTION WILL CHECK CHILD RECORDS AND ADD TO THEM SPECIFIC FIELD/VALUES
// THAT ARE NOT SHOWN ON THE FORM FOR THE USER TO ENTER BUT MIGHT BE REQUIRED
function addAdditionalVeevaFieldsToChildObjects(
  firstSubmitRecord: SalesforceFirstSubmitRecord,
  veevaFieldValues: {[fieldKey: string]: any }): SalesforceFirstSubmitRecord {
  // ITERATE OVER THE OBJECT'S FIELDS TO CHECK THE OBJECT VALUES
  Object.entries(firstSubmitRecord).forEach(([crmFieldKey, value]) => {
    // IF TRUE, IT'S AN OBJECTS ARRAY FIELD
    if (isSalesforceFirstSubmitPayload(value)) {
      // ADDS SPECIFIC VEEVA FIELDS
      switch (crmFieldKey) {
        case 'Medical_Discussion_vod__r': {
          (<SalesforceFirstSubmitPayload>firstSubmitRecord[crmFieldKey]).records =
            value.records.map((medicalDiscussion) => ({
              ...medicalDiscussion,
              Account_vod__c: veevaFieldValues.Account_vod__c,
              Attendee_Type_vod__c: veevaFieldValues.Attendee_Type_vod__c,
            }))
          break;
        }
      }
    }
  });

  return firstSubmitRecord;
}

// GETS THE MAIN RECORD FIRST SUBMIT PAYLOAD
async function getFirstSubmitMainRecordPayload(
  mainRecordSetting: FormSettings,
  crmSubmitPayload: CRMSubmitMeetingPayload, personsCount: number): Promise<SalesforceFirstSubmitRecord> {
  // GETS THE FORM'S FIELD VALUES
  const mainRecordPayload = getFirstSubmitRecordPayload(
    mainRecordSetting.id,
    'main-record',
    crmSubmitPayload.mainCrmValues,
    mainRecordSetting,
    crmSubmitPayload,
  );
  logger.debug('Generated main record form fields:', mainRecordPayload);

  // GETS AN OBJECT WITH THE NON-FORM VEEVA FIELD VALUES
  const veevaFieldValues = await getVeevaMainRecordFieldValues(crmSubmitPayload, personsCount);
  logger.debug('Generated main record Veeva fields:', veevaFieldValues);

  // ADDS, TO THE CHILD RECORDS, ADDITIONAL SPECIFIC FIELD/VALUES
  const withAdditionalFieldValues = addAdditionalVeevaFieldsToChildObjects(mainRecordPayload, veevaFieldValues);

  const mainAccountCallRecord: SalesforceFirstSubmitRecord = {
    ...veevaFieldValues,
    ...withAdditionalFieldValues,
  };
  logger.debug('Main account call record payload:', mainAccountCallRecord);

  return mainAccountCallRecord;
}

// ** UPDATE SUBMIT FUNCTIONS ** //
// BASED ON THE CRM RECORDS, PREPARES THEM TO BE UPDATED/CREATED
export async function getVeevaPayloadToUpdateRecords(crmSubmitPayload: CRMSubmitMeetingPayload):
  Promise<SalesforceUpdateSubmit> {
  logger.debug('Getting payload for update Veeva submit.', crmSubmitPayload);
  const { formValuesAttendees, formSettings, meetingORM } = crmSubmitPayload;
  const mainRecordSetting = formSettings.find((record) => record.isMainTable);

  if (!mainRecordSetting) {
    throw Error('Settings for main table not found.');
  }

  // SEPARATES NEW/EXISTING ATTENDEES
  const { newAttendees, existingAttendees } =
    formValuesAttendees.reduce<{ newAttendees: AttendeeForm[], existingAttendees: AttendeeForm[] }>((acc, attendee) => {
      if (attendee.attendeeType === AttendeeType.PRIMARY) {
        return acc;
      }

      const isExistingAttendee = meetingORM.model.attendees.some((existingAttendee) =>
        existingAttendee.id === attendee.id);
      if (isExistingAttendee) {
        acc.existingAttendees.push(attendee);
      } else {
        acc.newAttendees.push(attendee);
      }

      return acc;
    }, { newAttendees: [], existingAttendees: [] });

  const attendeesToDelete = meetingORM.model.attendees.reduce<RecordToDelete[]>((acc, attendee) => {
    const isNewDelete = attendee.crmAccountId && attendee.status === MeetingAttendeeStatus.ACTIVE &&
      attendee.crmRecord?.crmCallId && !existingAttendees.find((a) => attendee.id === a.id) &&
      attendee.attendeeType !== AttendeeType.PRIMARY;

    if (isNewDelete) {
      acc.push({
        crmId: attendee.crmRecord.crmCallId,
        beaconId: attendee.id,
      });
    }
    return acc;
  }, []);

  const newAttendeesInserts = await getNewAttendeesInserts(
    newAttendees,
    crmSubmitPayload,
    crmSubmitPayload.mainCrmRecordId!);
  logger.debug('New attendees inserts', newAttendeesInserts);

  // BASED ON THE CRM VALUES (MAIN ACCOUNT),
  // RETURNS OBJECTS TO BE UPSERTED
  const upserts = getUpsertRecords({
    Call_Date_vod__c: format(new Date(crmSubmitPayload.startTime), 'Y-MM-dd'),
    Call_Datetime_vod__c: crmSubmitPayload.startTime,
    Last_Device_vod__c: getVeevaDevice(),
    ...crmSubmitPayload.mainCrmValues,
  },
  crmSubmitPayload.formSettings, crmSubmitPayload.mainCrmRecordId!,
  mainRecordSetting, crmSubmitPayload);

  // TO EACH ATTENDEE, GETS THEIR UPDATED/NEW OBJECTS
  const attendeesUpserts = await getAttendeesUpserts(
    upserts,
    existingAttendees,
    crmSubmitPayload,
    formSettings,
    mainRecordSetting,
  );
  logger.debug('Existing attendees upserts', attendeesUpserts);

  const payloads: SalesforceUpdateSubmit = {
    recordsToUpsert: [...upserts, ...attendeesUpserts],
    recordsToDelete: attendeesToDelete,
    recordsToInsert: {
      records: newAttendeesInserts.records,
    },
  };

  return payloads;
}

// ADDS VEEVA FIELDS TO RECORDS TO BE UPDATED
function addVeevaFieldsToUpsertRecords(
  records: SalesforceRecordToUpsert[],
  veevaFieldValues: {[fieldKey: string]: any }): SalesforceRecordToUpsert[] {
  return records.map((record) => {
    switch (record.apiName) {
      case 'Medical_Discussion_vod__r': {
        record.fields.Account_vod__c = veevaFieldValues.Account_vod__c;
        record.fields.Attendee_Type_vod__c = veevaFieldValues.Attendee_Type_vod__c;
        break;
      }
    }

    return record;
  });
}

// GIVEN THE MAIN RECORD NEW/UPDATED OBJECTS, ITERATE OVER THE EXISTING ATTENDEES TO UPDATE THEIRS
async function getAttendeesUpserts(
  mainUpdates: SalesforceRecordToUpsert[],
  existingAttendees: AttendeeForm[],
  crmSubmitPayload: CRMSubmitMeetingPayload,
  formSettings: FormSettings[],
  mainRecordSetting: FormSettings,
): Promise<SalesforceRecordToUpsert[]> {
  const upsertsPayloads: SalesforceRecordToUpsert[] = [];
  const { meetingORM } = crmSubmitPayload;
  const attendeesMapped = await getAttendeesMapped(crmSubmitPayload, existingAttendees);
  const mainRecordUpdate = mainUpdates.find(({ beaconId }) => beaconId === 'main-record');

  if (!mainRecordUpdate) {
    throw new Error('Main record update payload not found');
  }

  attendeesMapped.forEach(({ attendeeForm, account }) => {
    const attendee = meetingORM.model.attendees.find(({ id }) => id === attendeeForm.id);
    // GETS THE ATTENDEE'S MAIN RECORD TO BE UPDATED
    upsertsPayloads.push({
      ...mainRecordUpdate,
      beaconId: attendeeForm.id,
      salesforceId: attendee?.crmRecord?.crmCallId,
    });

    const upserts = getUpsertRecords(
      attendeeForm.crmValues || {},
      formSettings,
      attendee?.crmRecord?.crmCallId || '',
      mainRecordSetting,
    );

    const upsertsWithVeevaFields = addVeevaFieldsToUpsertRecords(upserts, {
      Account_vod__c: account.id,
      Attendee_Type_vod__c: 'Person_Account_vod',
    });

    upsertsPayloads.push(...upsertsWithVeevaFields.filter((update) =>
      update.beaconId !== 'main-record',
    ));
  });

  return upsertsPayloads;
}

// RETURNS THE NEW ATTENDEES INSERTS AND THE MAIN ACCOUNT RECORD
async function getNewAttendeesInserts(
  attendees: AttendeeForm[],
  crmSubmitPayload: CRMSubmitMeetingPayload,
  parentCallId: string): Promise<SalesforceFirstSubmitPayload> {
  const payload: SalesforceFirstSubmitPayload = {
    records: [],
  };

  const mainRecordSetting = crmSubmitPayload.formSettings.find((record) => record.isMainTable);

  if (!mainRecordSetting) {
    throw Error('Settings for main table not found.');
  }

  const [mainAccountCallRecord, attendeesMapped] = await Promise.all([
    getFirstSubmitMainRecordPayload(mainRecordSetting, crmSubmitPayload, 0),
    getAttendeesMapped(crmSubmitPayload, attendees),
  ]);

  // ATTENDEES INSERTS
  payload.records = getAttendeesCallRecords(
    attendeesMapped,
    cloneDeep(mainAccountCallRecord),
    mainRecordSetting,
    parentCallId);

  return payload;
}

// RECEIVES ATTENDEES, MAIN RECORD AND ADDS TO EACH ONE THEIR OWN SET OF CHILD OBJECTS
export function veevaPreProcessFormAttendees(
  crmValues: FormValuesType,
  meetingORM: MeetingORM,
  attendees: ObjectWithId[]) {
  // 1. GETS THE OBJECT RECORDS FROM THE MAIN CRM RECORD
  const crmObjects: {[fieldKey: string]: ObjectWithId[]} = Object.entries(crmValues).reduce((acc, [key, value]) => {
    if (Array.isArray(value) && value.length && isArrayOfObjects(value)) {
      acc[key] = value;
    }
    return acc;
  }, {});

  if (Object.keys(crmObjects).length) {
    // 2. TO EACH ATTENDEE, ADDS THEIR OWN OBJECT RECORDS WITH THEIR OWN IDS
    return attendees.map((attendee) => {
      if (!attendee.crmAccountId) {
        return attendee;
      }
      const attendeeInDB = meetingORM.model.attendees.find(({ id }) => id === attendee.id);

      const attendeeCrmValues: {[fieldKey: string]: ObjectWithId[]} = {};
      Object.entries(crmObjects).forEach(([key, objects]) => {
        const idsUsed: string[] = [];
        attendeeCrmValues[key] = objects.reduce<ObjectWithId[]>((acc, object) => {
          if (!object.externalId || !attendeeInDB) {
            // IF IT'S NEW A OBJECT OR NEW ATTENDEE, CREATE THE OBJECT WITH A NEW ID
            acc.push({
              ...object,
              externalId: undefined,
              id: uuid(),
            });
          } else {
            // OTHERWISE, USE THE ID OF AN EXISTING ONE
            attendeeInDB?.crmRecord?.crmCustomValues.forEach((customValue) => {
              if (customValue.fieldId === key) {
                customValue.objectRecords?.forEach((objectRecord) => {
                  if (objectRecord.syncStatus !== CrmSyncStatus.DELETED &&
                    objectRecord.status !== ObjectRecordStatus.REMOVED &&
                    !idsUsed.includes(objectRecord.id)) {
                    idsUsed.push(objectRecord.id);
                    acc.push({
                      ...object,
                      id: objectRecord.id,
                      externalId: objectRecord.externalId,
                    });
                  }
                });
              }
            });
          }
          return acc;
        }, [])
      });

      return {
        ...attendee,
        crmValues: attendeeCrmValues,
      };
    });
  }

  return attendees;
}
