import React from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import { DNASlider, DNABox, Iffy, Stack, util } from '@alucio/lux-ui';
import Header from './Header/Header';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';
import { MeetingsStateProvider } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import { ContentProvider } from 'src/state/context/ContentProvider/ContentProvider.proxy'
import OpenedPresentations from './TabContent/OpenedPresentations/OpenedPresentations';
import MeetingDetails from './TabContent/MeetingDetails/MeetingDetails';
import Presenting from './TabContent/Presenting';
import Footer from './Footer/Footer';
import BrowseContent from './TabContent/BrowseContent';
import { MeetingType } from '@alucio/aws-beacon-amplify/src/models';
import { Animation } from 'react-native-animatable'
import CurrentlyPresentingPanel from './SidePanels/CurrentlyPresenting';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { HighlighterProvider } from 'src/components/Highlighter/HighlighterProvider';
import { CanvasVariantEnum } from 'src/components/Highlighter/UseCanvas';

/** Define the structure of a variant */
interface VariantConfig {
  /** Used to define unique variant styles */
  styles?: ViewStyle,
  animations?: {
    in?: Animation,
    out?: Animation
  }
}
/** Define a mapping for each variant type */
type Variants = Record<MeetingType, VariantConfig>
/** Define the values for each variant type */
const variants: Variants = {
  IN_PERSON: {
    styles: {
      maxWidth: 775,
    },
    animations: {
      in: 'slideInLeft',
      out: 'slideOutLeft',
    },
  },
  VIRTUAL: {
    animations: {
      in: 'slideInUp',
      out: 'slideOutDown',
    },
  },
  MANUAL: {},
}

const styles = StyleSheet.create({
  /** Styles which are common across variants should be placed here */
  tabContainer: {
    backgroundColor: colors['color-gray-800'],
  },
  panelStackLayer: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: colors['color-gray-900'],
    display: 'flex',
  },
})

export enum Tabs {
  BROWSE_CONTENT,
  OPENED_PRESENTATIONS,
  MEETING_DETAILS,
  PRESENTING,
}
export type Tab = keyof typeof Tabs
interface TabConfig {
  testID: string,
  title: string,
  component: React.ElementType
}

type TabOptions = Record<Tab, TabConfig>

export const tabs: TabOptions = {
  BROWSE_CONTENT: {
    testID: 'browse-content',
    title: 'Browse Content',
    component: BrowseContent,
  },
  OPENED_PRESENTATIONS: {
    testID: 'opened-presentations',
    title: 'Opened Files',
    component: OpenedPresentations,
  },
  MEETING_DETAILS: {
    testID: 'meeting-details',
    title: 'Meeting Details',
    component: MeetingDetails,
  },
  PRESENTING: {
    testID: 'presenting',
    title: 'Presenting',
    component: Presenting,
  },
};

const PresentationControls = () => {
  const meetingsState = useMeetingsState()
  const {
    presentationControlsVisible,
    setPresentationControlsVisible,
    currentTab,
    meetingORM,
    checkFormDiscard,
    actionBarState,
  } = meetingsState
  const content = useContent()
  const { presentations, activePresentation } = content

  if (!meetingORM) {
    console.error('Meeting ORM not found');
    return null;
  }

  const { type } = meetingORM.model
  const variant = variants[type]
  const isVirtualMode = type === 'VIRTUAL'
  const isInPerson = type === 'IN_PERSON'
  const availableTabs = Object.entries(tabs).filter((tab) => isVirtualMode || tab[0] !== 'PRESENTING')
  const tabContentElements = availableTabs.map(([tabName, tabConfig]) => {
    const isHidden = currentTab !== tabName && tabName !== 'PRESENTING'
    const Component = tabConfig.component
    const containerStyle: ViewStyle | undefined = !isHidden
      ? undefined
      : {
        display: 'none',
      }

    return (
      <DNABox key={tabName} fill style={containerStyle}>
        <Component />
      </DNABox>
    )
  })
  const presentingTab = tabContentElements.find((elem) => elem.key === 'PRESENTING')
  const nonPresentingTab = tabContentElements.filter((elem) => elem.key !== 'PRESENTING')
  const isPresenting = currentTab === 'PRESENTING'

  const addAnalytics = (variant: CanvasVariantEnum) => {
    analytics?.track('MEETING', {
      action: variant === CanvasVariantEnum.arrow ? 'MEETING_HIGHLIGHTER_ARROW' : 'MEETING_HIGHLIGHTER_SQUARE',
      category: 'MEETING',
      meetingID: meetingORM?.model.id,
      documentVersionId: activePresentation?.currentPresentablePage.documentVersionORM.model.id,
      documentId: activePresentation?.currentPresentablePage.documentVersionORM.model.documentId,
      pageId: activePresentation?.currentPresentablePage.id,
      page: activePresentation?.currentPresentablePage.page.number,
    })
  }

  const handleBackDropPress = () => {
    // BEFORE CLOSING, CHECKS THE MEETING'S FORM DOESN'T HAVE UNSAVED DATA
    checkFormDiscard(() => setPresentationControlsVisible(false));
  }

  return (
    <DNASlider
      visible={presentationControlsVisible}
      setVisible={setPresentationControlsVisible}
      orientation="horizontal"
      onBackdropPress={handleBackDropPress}
      // [NOTE] - SlideSize is locked in for now and only supports numbers
      //        - If we need dynamic values (i.e. screen width)
      //          Consider using a layoutEffect and calculating the current screen width from there
      sliderSize={Number(variant.styles?.maxWidth) ?? 756}
      invertAnimation
    >
      <ContentProvider value={content}>
        <MeetingsStateProvider value={meetingsState}>
          <DNABox
            style={styles.tabContainer}
            appearance="col"
            fill
          >
            {/* HEADER */}
            <Header />
            {/* CONTENT */}
            <DNABox fill appearance="row">
              <Stack anchor="top" style={{ flex: 1 }}>
                <Stack.Layer style={{ flex: 1 }}>
                  <HighlighterProvider actionBarState={actionBarState} onModeChange={addAnalytics}>
                    {presentingTab}
                  </HighlighterProvider>
                </Stack.Layer>
                <Stack.Layer
                  style={util.mergeStyles(undefined, styles.panelStackLayer, [{ display: 'none' }, isPresenting])}
                >
                  <DNABox fill appearance="row">
                    {nonPresentingTab}
                    <Iffy is={isVirtualMode && !isPresenting}>
                      <CurrentlyPresentingPanel />
                    </Iffy>
                  </DNABox>
                </Stack.Layer>
              </Stack>
            </DNABox>
            {/* CURRENT STATUS FOOTER */}
            <Iffy is={presentations.length <= 0 || isInPerson}>
              <Footer />
            </Iffy>
          </DNABox>
          {/* CURRENT STATUS FOOTER */}
        </MeetingsStateProvider>
      </ContentProvider>
    </DNASlider>
  );
};

PresentationControls.displayName = 'PresentationControls';

export default PresentationControls;
