// create log out machine

import { createMachine, InterpreterFrom } from 'xstate';
import { assign } from '@xstate/immer'
import { Auth } from 'aws-amplify';
import authUtil from '../../../components/Authenticator/services/authUtil';
import { SOURCE } from '../../../components/Authenticator/LogOut';
import { setDisconnectedDue } from '../../../components/Authenticator/DisconnectedDue';
import { SessionStatus } from '../../../components/IdleComponent/IdleComponent';
import { WebLinking as Linking } from '@alucio/core'
import { CRMUtil } from '../CRM/util';

export enum StateTags {
  IS_PERFORMING_LOGOUT = 'IS_PERFORMING_LOGOUT',
}

type LogOutParams = {
    isOfflineEnabled?: boolean;
    isSSO: boolean;
    clearLocalData?: boolean,
    source?: SOURCE;
    disconnectionReason?: SessionStatus;
}

export type LogOutMachine = ReturnType<typeof logOutMachine>
export type LogOutService = InterpreterFrom<LogOutMachine>

type LOG_OUT_SIGNAL = {
  type: 'LOG_OUT_SIGNAL',
  payload: { source: SOURCE, disconnectionReason?: SessionStatus }
}

type SET_CLEAR_LOCAL_DATA = {
  type: 'SET_CLEAR_LOCAL_DATA',
  payload: { clearLocalData: boolean }
}

type CANCEL_LOG_OUT = {
  type: 'CANCEL_LOG_OUT',
}

type SET_SSO = {
  type: 'SET_SSO',
  payload: { isSSO: boolean }
}

type SET_OFFLINE_ENABLED = {
  type: 'SET_OFFLINE_ENABLED',
  payload: { isOfflineEnabled?: boolean }
}

type LogOutEvents = LOG_OUT_SIGNAL
| SET_CLEAR_LOCAL_DATA
| CANCEL_LOG_OUT
| SET_SSO
| SET_OFFLINE_ENABLED

type LogOutState = {
  value: { }
  context: LogOutParams
}

const crmUtil = new CRMUtil();

export const logOutMachine = (params: LogOutParams) => createMachine<LogOutParams, LogOutEvents, LogOutState>({
  id: 'logOut',
  initial: 'idle',
  context: {
    ...params,
  },
  states: {
    idle: {
      entry: 'cleanContext',
      on: {
        SET_SSO: [{ actions: ['setSSO'] }],
        SET_OFFLINE_ENABLED: [{ actions: ['setOfflineEnabled'] }],
        LOG_OUT_SIGNAL: [{
          target: 'determine',
          actions: ['setLogOutPayload'],
        }],
      },
    },
    determine: {
      tags: [StateTags.IS_PERFORMING_LOGOUT],
      always: [
        { target: '#logOut.user.isUserInitiated', cond: 'isUserInitiated' },
        { target: 'isFromIdleChannel', cond: 'isFromIdleChannel' },
        { target: 'leaderTab.determineOfflineEnabled', cond: 'isIdleTimer' },
      ],
    },
    user: {
      states: {
        isUserInitiated: {
          tags: [StateTags.IS_PERFORMING_LOGOUT],
          always: [
            { target: 'promptUserConfirmation.showPrompt', cond: 'shouldShowPrompt' },
            { target: '#logOut.isFromIdleChannel' },
          ],
        },
        promptUserConfirmation: {
          states: {
            showPrompt: {
              on: {
                SET_CLEAR_LOCAL_DATA:
                {
                  actions: ['setClearLocalData'],
                  target: '#logOut.isFromIdleChannel',
                },
                CANCEL_LOG_OUT: {
                  target: '#logOut.idle',
                },
                SET_OFFLINE_ENABLED: [{ actions: ['setOfflineEnabled'] }],
              },
            },
          },
        },
      },
    },
    isFromIdleChannel: {
      tags: [StateTags.IS_PERFORMING_LOGOUT],
      always: [
        { target: '#logOut.leaderTab.storedStateTasks', cond: 'isFromIdleChannel' },
        { target: 'leaderTab.determineOfflineEnabled' },
      ],
    },
    leaderTab: {
      tags: [StateTags.IS_PERFORMING_LOGOUT],
      states: {
        determineOfflineEnabled: {
          entry: 'sendOtherTabsLogOutSignal',
          always: [
            {
              target: '#logOut.leaderTab.offline.offlineCacheTasks',
              cond: 'isOfflineEnabled',
            },
            {
              target: '#logOut.leaderTab.storedStateTasks',
            },
          ],
        },
        offline: {
          states: {
            offlineCacheTasks: {
              tags: [StateTags.IS_PERFORMING_LOGOUT],
              always: [
                {
                  actions: ['sendSWorkerPauseSync'],
                  target: '#logOut.leaderTab.offline.purgeOfflineCache',
                  cond: 'clearOfflineCacheData',
                },
                {
                  actions: ['sendSWorkerPauseSync'],
                  target: '#logOut.leaderTab.clientTasks',
                },
              ],
            },
            purgeOfflineCache: {
              tags: [StateTags.IS_PERFORMING_LOGOUT],
              invoke: {
                id: 'purgeOfflineCache',
                src: 'purgeOfflineCache',
                onDone: {
                  target: '#logOut.leaderTab.clientTasks',
                },
                onError: {
                  target: '#logOut.error',
                },
              },
            },
          },
        },
        storedStateTasks: {
          tags: [StateTags.IS_PERFORMING_LOGOUT],
          invoke: {
            id: 'storedStateTasks',
            src: 'storedStateTasks',
            onDone: {
              target: '#logOut.allTabs.clientCleanup',
            },
            onError: {
              target: '#logOut.error',
            },
          },
        },
        clientTasks: {
          tags: [StateTags.IS_PERFORMING_LOGOUT],
          invoke: {
            id: 'clientTasks',
            src: 'clientTasks',
            onDone: {
              target: '#logOut.allTabs.clientCleanup',
            },
            onError: {
              target: '#logOut.error',
            },
          },
        },
        terminateAuthSession: {
          tags: [StateTags.IS_PERFORMING_LOGOUT],
          invoke: {
            id: 'terminateAuthSession',
            src: 'terminateAuthSession',
            onDone: {
              target: '#logOut.done',
            },
            onError: {
              target: '#logOut.error',
            },
          },
        },
      },
    },
    allTabs: {
      tags: [StateTags.IS_PERFORMING_LOGOUT],
      states: {
        clientCleanup: {
          always: [
            { target: '#logOut.leaderTab.terminateAuthSession', actions: ['clientCleanup'] },
          ],
        },
      },
    },
    done: {
      type: 'final',
    },
    error: {
      type: 'final',
    },
  },
}, {
  guards: {
    isOfflineEnabled: (context) => !!context.isOfflineEnabled,
    clearLocalData: (context) => !!context.clearLocalData,
    isSSO: (context) => context.isSSO,
    isUserInitiated: (context) => context.source === SOURCE.USER,
    isIdleTimer: (context) => context.source === SOURCE.IDLE_TIMER,
    isFromIdleChannel: (context) => context.source === SOURCE.IDLE_CHANNEL,
    shouldShowPrompt: (context) => context.source === SOURCE.USER && !!context.isOfflineEnabled,
    shouldClearOfflineData: (context) => context.source === SOURCE.USER && !!context.clearLocalData,
    clearOfflineCacheData: (context) => context.source === SOURCE.USER && !!context.clearLocalData,
  },
  services: {
    clientCleanup: () => {
      authUtil.clearUserDetailLocalStorage()
      authUtil.hubDispatch()
      authUtil.resetRedux()
      return Promise.resolve()
    },
    terminateAuthSession: async (ctx) => {
      const isSSO = ctx.isSSO
      const disconnectionReason = ctx.disconnectionReason

      await authUtil.setPrevAuthUser()
      authUtil.cleanGlobalState()

      const registration = await navigator.serviceWorker.getRegistration('/')

      // if the service worker is not registered, we need to clear all the CRM data related information
      if (!registration) {
        await crmUtil.setOfflineCRM()
      }

      // This call causes that the state machine does not go to the final state
      // i think this is as soon the auth session is terminated
      // the state machine is unmounted not a real issue due to the fact that
      // the state machine is not used anymore after this point
      await Auth.signOut();

      if (disconnectionReason) {
        setDisconnectedDue(disconnectionReason)
      }

      // after check with dev we decided to include this reload here
      // due we want to clean some side effects introduced by amplify on the localstorage, cookies, and indexDB
      if (!isSSO) {
        Linking.openURL('/', '_self');
      }
    },
    storedStateTasks: async () => {
      await Promise.all([
        authUtil.clearDataStore(),
        authUtil.clearOfflineAnalyticsDB(),
      ])
    },
    purgeOfflineCache: async () => {
      return Promise.all([
        authUtil.purgeCacheDB(),
        authUtil.cleanCRMData(),
      ])
    },
    clientTasks: async () => {
      authUtil.clearUserDetailLocalStorage()
      return Promise.all([
        authUtil.clearOfflineAnalyticsDB(),
        authUtil.clearDataStore(),
      ])
    },
  },
  actions: {
    clientCleanup: assign(() => {
      authUtil.clearUserDetailLocalStorage()
      authUtil.hubDispatch()
      authUtil.resetRedux()
    }),
    sendOtherTabsLogOutSignal: assign(() => {
      authUtil.sendLogOutMessageToChannel(SessionStatus.end);
    }),
    sendSWorkerPauseSync: assign(() => {
      authUtil.pauseSync();
    }),
    setLogOutPayload: assign((context, event) => {
      const evt = event as LOG_OUT_SIGNAL;
      context.source = evt.payload.source;
      context.disconnectionReason = evt.payload.disconnectionReason;
    }),
    setClearLocalData: assign((context, event) => {
      const evt = event as SET_CLEAR_LOCAL_DATA;
      context.clearLocalData = evt.payload.clearLocalData;
    }),
    cleanContext: assign((ctx) => {
      ctx.source = undefined;
      ctx.clearLocalData = undefined;
      ctx.disconnectionReason = undefined;
    }),
    setSSO: assign((context, event) => {
      const evt = event as SET_SSO;
      context.isSSO = evt.payload.isSSO;
    }),
    setOfflineEnabled: assign((context, event) => {
      const evt = event as SET_OFFLINE_ENABLED;
      context.isOfflineEnabled = evt.payload.isOfflineEnabled;
    }),
  },
});
