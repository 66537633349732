import React, { useCallback, useState } from 'react';
import { DNABox, DNAButton, DNAText, Iffy, useOrientationChange } from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import { StyleSheet } from 'react-native';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';
import useFullScreen from 'src/hooks/useFullScreen/useFullScreen';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { FileType, Sentiment } from '@alucio/aws-beacon-amplify/src/models';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import PresentationNav from '../SharedComponents/PresentationNav';
import OpenedPresentations from '../PresentationControls/TabContent/OpenedPresentations/OpenedPresentations';
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import { useHighlighter } from 'src/components/Highlighter/HighlighterProvider';
import { isDocumentVersionORM, isFolderItemORM } from 'src/types/typeguards';
import useFeatureFlag from 'src/hooks/useFeatureFlag/useFeatureFlag';
import { FeatureFlags } from 'src/types/featureFlags';
import { CanvasVariantEnum } from 'src/components/Highlighter/UseCanvas';
import { ActionBarState } from '../SharedComponents/PresentationMenu';

type InPersonActionBarVariants = Record<DeviceMode, React.ElementType>
type ButtonDefaultVariants = Record<DeviceMode, DNAButtonProps>
type InPersonActionBarStyleVariants = Record<DeviceMode, StyleSheet.NamedStyles<{ [keys: string]: StyleSheet }>>

const inPersonActionBarStyleVariants: InPersonActionBarStyleVariants = {
  desktop: StyleSheet.create({
    actionsContainer: {
      backgroundColor: colors['color-black'],
      padding: 8,
      borderTopLeftRadius: 4,
    },
    iconBtn: {
      paddingHorizontal: 0,
    },
    icon: {
      width: 16,
      height: 16,
    },
  }),
  tablet: StyleSheet.create({
    actionsContainer: {
      backgroundColor: colors['color-black'],
      padding: 8,
      borderTopLeftRadius: 4,
    },
  }),
}

const useConditionals = () => {
  const areMeetingNotesEnabled = useFeatureFlag(FeatureFlags.BEAC_4227_meeting_slide_notes);
  const areReactionsEnabled = useFeatureFlag(FeatureFlags.BEAC_4227_meeting_slide_reactions);
  const isFollowUpEnabled = useFeatureFlag(FeatureFlags.BEAC_4227_meeting_slide_follow_up);

  return {
    areMeetingNotesEnabled,
    areReactionsEnabled,
    isFollowUpEnabled,
  };
};

type AdditionalActionGroups = 'default' | 'tabletSentiments'

const useInPersonActionBarSharedComponents = () => {
  const {
    endMeeting,
    setPresentationControlsVisible,
    toggleSlideRollVisibility,
    setSlideRollVisible,
    toggleTextSearchVisibility,
    canEndMeeting,
    toggleMyNotes,
    updateReaction,
    updateFollowUp,
    presentedMeta,
    actionBarState,
    setPresentationMenu,
  } = useMeetingsState();
  const { presentations, activePresentation } = useContent();
  const { isFullScreen, toggleFullScreen } = useFullScreen();
  const { deviceMode } = useAppSettings()
  const [showPresentations, setShowPresentations] = useState<boolean>(false);
  const [currentActionsGroup, setCurrentActionGroups] = useState<AdditionalActionGroups>('default')

  const conditionals = useConditionals();
  const orientation = useOrientationChange()

  const buttonDefaultVariants: ButtonDefaultVariants = {
    desktop: {
      size: 'sm',
      status: 'dark',
      padding: 'sm',
    },
    tablet: {
      size: orientation === 'LANDSCAPE' ? 'lg' : 'sm',
      status: 'dark',
      /**
       * TODO: This should be square context, however, using
       * that value causes the size prop to become inoperable.
       * Should address this in BEAC-1887 */
      style: { borderRadius: 4 },
    },
  }

  const { orm } = { ...activePresentation?.presentable }

  const currentORM = isDocumentVersionORM(orm)
    ? orm
    : isFolderItemORM(orm)
      ? orm.relations.itemORM
      : undefined

  const isWebOrVideoDoc = isDocumentVersionORM(currentORM) && [
    FileType.WEB,
    FileType.MP4,
    FileType.HTML,
  ].includes(FileType[currentORM?.model.type])

  const {
    highlighterVisible,
    setHighlighterMode,
    selectedType,
    setHighlighterVisible,
  } = useHighlighter();

  const style = inPersonActionBarStyleVariants[deviceMode]

  const openSidePanel = () => {
    setSlideRollVisible(false);
    setPresentationControlsVisible(true);
  };

  const toggleShowPresentations = () => {
    setShowPresentations((showPresentations) => !showPresentations)
  }

  const buttonDefaults = buttonDefaultVariants[deviceMode]

  const presentationNav = !isWebOrVideoDoc && !!presentations.length && <PresentationNav />

  // My Content Button
  const myContentButton = (<DNAButton
    {...buttonDefaults}
    onPress={openSidePanel}
    testID="my-content-button"
  >
    My Content
  </DNAButton>)

  // Presentations Button
  const presentationsButton = !!presentations.length &&
    <DNAPopover
      lazyMount
      placement="top"
      interactive={true}
      visible={showPresentations}
      onBackdropPress={toggleShowPresentations}
      type="menu"
      offset={30}
    >
      <DNAPopover.Anchor>
        <DNAButton
          {...buttonDefaults}
          onPress={toggleShowPresentations}
          testID="files-button"
        >
          Files
        </DNAButton>
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNABox fill style={{ marginRight: 0, minWidth: 480, maxHeight: 500 }}>
          <OpenedPresentations variant="inPersonActionBar" />
        </DNABox>
      </DNAPopover.Content>
    </DNAPopover>

  // Slides Button
  const slidesButton = !isWebOrVideoDoc && !!presentations.length &&
    <DNAButton
      {...buttonDefaults}
      onPress={toggleSlideRollVisibility}
      testID="in-person-meeting-slides-btn"
    >
      Slides
    </DNAButton>

  // Text Search button
  const textSearchButton = !isWebOrVideoDoc && !!presentations.length &&
    <DNAPopover
      placement="top"
      type="tooltip"
      offset={30}
      disablePopover={['tabletPWA']}
    >
      <DNAPopover.Anchor>
        <DNAButton
          {...buttonDefaults}
          onPress={toggleTextSearchVisibility}
          testID="in-person-meeting-search-in-document-btn"
          style={style.iconBtn}
          iconLeft="magnify"
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">Search</DNAText>
      </DNAPopover.Content>
    </DNAPopover>

  const myNotesButton = !!presentations.length && conditionals.areMeetingNotesEnabled ? (
    <DNAPopover
      placement="top"
      type="tooltip"
      offset={30}
      disablePopover={['tabletPWA']}
    >
      <DNAPopover.Anchor>
        <DNAButton
          {...buttonDefaults}
          style={style.iconBtn}
          onPress={toggleMyNotes}
          key="My Notes"
          testID="toggle-my-notes-button"
          iconLeft="playlist-edit"
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">My Notes</DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  ) : null;

  const reactionButtons = activePresentation && conditionals.areReactionsEnabled ? (
    <DNABox spacing="sm">
      <DNAButton
        {...buttonDefaults}
        style={style.iconBtn}
        iconLeft="chevron-up"
        iconStyle={[style.icon, presentedMeta?.sentiment === Sentiment.POSITIVE &&
        { color: colors['color-success-500'] }]}
        onPress={() => updateReaction(Sentiment.POSITIVE)}
        testID="positive-reaction-btn"
      />
      <DNAButton
        {...buttonDefaults}
        style={style.iconBtn}
        iconLeft="chevron-down"
        iconStyle={[style.icon, presentedMeta?.sentiment === Sentiment.NEGATIVE &&
        { color: colors['color-warning-500'] }]}
        onPress={() => updateReaction(Sentiment.NEGATIVE)}
        testID="negative-reaction-btn"
      />
    </DNABox>
  ) : null;

  const highlighterActions = activePresentation ? (
    <DNABox spacing="sm">
      <DNAButton
        {...buttonDefaults}
        style={style.iconBtn}
        iconLeft="arrow-left-bold"
        onPress={() => {
          setPresentationMenu(ActionBarState.PresentationMenu)
          setHighlighterVisible(false)
        }}
        testID="arrow-btn"
      >
        Back
      </DNAButton>
      <DNAButton
        {...buttonDefaults}
        style={style.iconBtn}
        iconLeft="arrow-top-right"
        onPress={() => setHighlighterMode(CanvasVariantEnum.arrow)}
        status={highlighterVisible && selectedType === CanvasVariantEnum.arrow
          ? 'info'
          : buttonDefaults.status
        }
        testID="arrow-btn"
      >
        Arrow
      </DNAButton>
      <DNAButton
        {...buttonDefaults}
        style={style.iconBtn}
        iconLeft="checkbox-blank"
        onPress={() => setHighlighterMode(CanvasVariantEnum.square)}
        testID="box-btn"
        status={highlighterVisible && selectedType === CanvasVariantEnum.square
          ? 'info'
          : buttonDefaults.status
        }
      >
        Box highlighter
      </DNAButton>
    </DNABox>
  ) : null;

  const followUpButton = activePresentation && conditionals.isFollowUpEnabled ? (
    <DNAPopover
      placement="top"
      type="tooltip"
      offset={30}
      disablePopover={['tabletPWA']}
    >
      <DNAPopover.Anchor>
        <DNAButton
          {...buttonDefaults}
          style={style.iconBtn}
          iconLeft={presentedMeta?.followUp ? 'flag' : 'flag-outline'}
          onPress={() => updateFollowUp()}
          testID="follow-up-flag-btn"
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">Flag for follow-up</DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  ) : null;

  // Fullscreen button
  const fullScreenButton = (
    <DNAPopover
      placement="top"
      type="tooltip"
      offset={30}
      disablePopover={['tabletPWA']}
    >
      <DNAPopover.Anchor>
        <DNAButton
          {...buttonDefaults}
          onPress={toggleFullScreen}
          testID="in-person-meeting-full-screen-btn"
          iconRight={isFullScreen ? 'arrow-collapse' : 'arrow-expand'}
          iconStyle={{ margin: 0 }}
          style={style.iconBtn}
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">Full screen</DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  )

  // End meeting button
  const endMeetingButton =
    (<DNAButton
      {...buttonDefaults}
      status="danger"
      onPress={endMeeting}
      disabled={!canEndMeeting}
      testID="in-person-meeting-end-meeting-btn"
    >
      <DNAText status="basic">End</DNAText>
    </DNAButton>)

  // Collapse Button
  const collapseButton = (<DNAButton
    {...buttonDefaults}
    appearance="filled"
    iconRight={actionBarState === ActionBarState.PresentationMenu ? 'arrow-right-circle' : 'arrow-left-circle' }
    style={style.iconBtn}
    onPress={() => setPresentationMenu(
      actionBarState === ActionBarState.PresentationMenu ? ActionBarState.Collapsed : ActionBarState.PresentationMenu,
    )}
    testID="direct-share-mode-collapse-btn"
  />)

  const highlighterButton = activePresentation ? (
    <DNAPopover
      placement="top"
      type="tooltip"
      offset={30}
      disablePopover={['tabletPWA']}
    >
      <DNAPopover.Anchor>
        <DNAButton
          {...buttonDefaults}
          onPress={() => setPresentationMenu(ActionBarState.HighlighterMenu)}
          style={style.iconBtn}
          key="Marker"
          testID="toggle-marker-button"
          iconLeft="marker"
          status={buttonDefaults.status}
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">Highlighter</DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  ) : null

  const CurrentActionsToggle = useCallback<React.FC<{ group: AdditionalActionGroups } & DNAButtonProps>>(
    (props) => {
      const { group, ...buttonProps } = props
      
      return (
        <DNAButton
          {...buttonDefaults}
          {...buttonProps}
          onPress={() => setCurrentActionGroups(group)}
        />
      )
    },
    [setCurrentActionGroups]
  )

  return {
    presentationNav,
    myContentButton,
    presentationsButton,
    slidesButton,
    textSearchButton,
    fullScreenButton,
    endMeetingButton,
    collapseButton,
    actionBarState,
    style,
    highlighterButton,
    highlighterActions,
    followUpButton,
    reactionButtons,
    myNotesButton,
    CurrentActionsToggle,
    currentActionsGroup,
  }
}

/** TODO: we may be able to refactor this to a single component via the DNAActionBar
 * and conditionally style and set visibility of options based on the deviceMode.
 * Additionally, we should tighten up the styling of the component to ensure that icon
 * only items are the same height as text only items. */
/** SECTION: Variants */
const DesktopVariant: React.FC = () => {
  const {
    presentationNav,
    myContentButton,
    presentationsButton,
    slidesButton,
    actionBarState,
    collapseButton,
    textSearchButton,
    fullScreenButton,
    endMeetingButton,
    style,
    highlighterButton,
    highlighterActions,
    myNotesButton,
    reactionButtons,
    followUpButton,
  } = useInPersonActionBarSharedComponents()

  return (
    <DNABox spacing="sm" style={style.actionsContainer} alignY="center">

      <Iffy is={actionBarState === ActionBarState.PresentationMenu} >
        {presentationNav}
        {myContentButton}
        {presentationsButton}
        {slidesButton}
        {highlighterButton}
        {textSearchButton}
        {reactionButtons}
        {myNotesButton}
        {followUpButton}
        {fullScreenButton}
        {endMeetingButton}
        {collapseButton}
      </Iffy>

      <Iffy is={actionBarState === ActionBarState.HighlighterMenu} >
        {highlighterActions}
      </Iffy>

      <Iffy is={actionBarState === ActionBarState.Collapsed} >
        {presentationNav}
        {collapseButton}
      </Iffy>

    </DNABox>
  )
}

const TabletVariant: React.FC = () => {
  const {
    presentationNav,
    myContentButton,
    presentationsButton,
    slidesButton,
    textSearchButton,
    endMeetingButton,
    collapseButton,
    actionBarState,
    highlighterButton,
    style,
    myNotesButton,
    reactionButtons,
    highlighterActions,
    followUpButton,
    CurrentActionsToggle,
    currentActionsGroup
  } = useInPersonActionBarSharedComponents()

  return (
    <DNABox spacing="sm" style={style.actionsContainer} alignY="center">
      <Iffy is={actionBarState === ActionBarState.PresentationMenu}>
        <Iffy is={currentActionsGroup === 'tabletSentiments'}>
          <CurrentActionsToggle
            group='default'
            iconLeft='arrow-left-thick'
          >
            Back
          </CurrentActionsToggle>
          {reactionButtons}
          {followUpButton}
        </Iffy>
        <Iffy is={currentActionsGroup === 'default'}>
          {presentationNav}
          {myContentButton}
          {presentationsButton}
          {slidesButton}
          {highlighterButton}
          {textSearchButton}
          {myNotesButton}
          <Iffy is={(reactionButtons || followUpButton)}>
            <CurrentActionsToggle
              group="tabletSentiments"
              iconLeft="dots-vertical"
            />
          </Iffy>
          {endMeetingButton}
          {collapseButton}
        </Iffy>
      </Iffy>
      <Iffy is={actionBarState === ActionBarState.HighlighterMenu} >
        {highlighterActions}
      </Iffy>
      <Iffy is={actionBarState === ActionBarState.Collapsed} >
        {presentationNav}
        {collapseButton}
      </Iffy>
    </DNABox>
  )
}
/** !SECTION: Variants */

const inPersonActionBarVariants: InPersonActionBarVariants = {
  desktop: DesktopVariant,
  tablet: TabletVariant,
}

const InPersonActionBar: React.FC = () => {
  const {
    presentationControlsVisible,
  } = useMeetingsState()
  const { deviceMode } = useAppSettings()

  const CurrentVariant = inPersonActionBarVariants[deviceMode]

  return (
    <Iffy is={!presentationControlsVisible}>
      <CurrentVariant />
    </Iffy>
  );
};

export default InPersonActionBar
