/** MODULES */
import { createMachine, spawn, send } from 'xstate'
import { assign } from '@xstate/immer'
import { API, graphqlOperation, GraphQLResult, GraphQLQuery } from '@aws-amplify/api';
import { Storage } from '@aws-amplify/storage'
import pick from 'lodash/pick'

/** BEACON TYPES */
import {
  DocumentVersion,
  Document,
  DocumentVersionChangeType,
  AssociatedFileType,
  FileType,
} from '@alucio/aws-beacon-amplify/src/models'
import {
  CreateDocumentVersionFromS3UploadInput,
  CreateDocumentVersionFromS3UploadMutation,
  PublishDocumentMutation,
  UpdateDocumentThumbnailInput,
  UpdateDocumentThumbnailMutation,
} from '@alucio/aws-beacon-amplify/src/API'
import {
  createDocumentVersionFromExisting,
  createDocumentVersionFromS3Upload,
  publishDocument,
  updateDocumentThumbnail,
  updateDocumentPageData,
} from '@alucio/aws-beacon-amplify/src/graphql/mutations'
import { VIDEO_TYPES_ENUM } from 'src/types/types'

/** VERSIONING TYPES */
import * as Ver from 'src/state/machines/versioning/versioningTypes'
import { StateTags } from 'src/state/machines/versioning/versioningTypes'
import { INFO_MESSAGES } from 'src/state/machines/versioning/versioningConstants'
import { UPLOAD_STATUS } from 'src/components/DNA/FileUpload/FileUpload'

/** BEACON UTIL */
import { store } from 'src/state/redux'
import { observable } from 'src/state/machines/versioning/observableVersion'
import { documentActions } from 'src/state/redux/slice/document'
import { documentVersionActions, createAssociatedFile } from 'src/state/redux/slice/documentVersion'
import versioningUtil from 'src/state/machines/versioning/versioningUtil';
import { multiSliceActions } from 'src/state/redux/slice/multiSlice';
import slideSettingsMachine from './SlideSettings/slideSettings'
import { formatDocumentFileNameHeader } from 'src/utils/documentHelpers';
import { fetchJsonFromCloudfront } from 'src/utils/loadCloudfrontAsset/common';

/** RE-EXPORTS */
export * from 'src/state/machines/versioning/versioningTypes'
export * from 'src/state/machines/versioning/versioningConstants'

const updateVersion = (docVer: DocumentVersion) => createMachine<
  Ver.UpdateVersionContext,
  Ver.UpdateVersionEvents,
  Ver.UpdateVersionState
>(
  {
    predictableActionArguments: false,
    id: 'UpdateVersion',
    strict: true,
    context: {
      cancelUpload: false,
      documentVersionId: docVer.id,
      documentInfoIsDirty: false,
      documentSettingsIsDirty: false,
      documentSlidesDataIsDirty: false,
      documentPublishIsDirty: false,
      errors: {},
      getDocumentORM: versioningUtil.getDocumentORMFactory(docVer.documentId),
      hasOptimizedFinishedThisSession: false,
      selectedTabIndex: 0,
      versionActor: undefined,
      versionForm: versioningUtil.omitInternalFields(docVer),
      slideSettingsActor: undefined,
    },
    // Spawn an observer to watch for updates from AppSync
    entry: assign(ctx => {
      if (!ctx.versionActor) {
        ctx.versionActor = spawn(
          observable({ filter: { model: { id: docVer.documentId } } }),
          'VersionActor',
        )
      }

      if (!ctx.slideSettingsActor) {
        const docVersionORM = versioningUtil.getDocumentORMFactory(docVer.documentId)()
          .relations.documentVersions.find((version) => docVer.id === version.model.id)
        if (docVersionORM) {
          ctx.slideSettingsActor = spawn(
            slideSettingsMachine(docVer, docVersionORM.meta.allPages),
            'SlideSettingsActor',
          )
        } else {
          console.error(`Unable to locate DocumentVersionORM for document ${docVer.id}`)
        }
      }
    }),
    initial: 'determine',
    states: {
      determine: {
        description: 'Determines whether we should go to the Publish or Draft node branches',
        always: [
          { target: 'published', cond: 'isSealed' },
          { target: 'published', cond: 'isPublished' },
          { target: 'draft' },
        ],
      },
      published: {
        description: 'The published nodes intended for viewing and creating new versions',
        initial: 'documentInfo',
        states: {
          documentInfo: {
            tags: [StateTags.DOCUMENT_INFO_TAB],
          },
          documentSettings: {
            tags: [StateTags.DOCUMENT_SETTINGS_TAB],
          },
          documentAssociatedFiles: {
            tags: [StateTags.DOCUMENT_ASSOCIATED_FILES],
          },
          documentReleaseNotes: {
            tags: [StateTags.DOCUMENT_RELEASE_NOTES_TAB],
          },
        },
        on: {
          CREATE_FROM_EXISTING: {
            target: 'draft.documentInfo.processing.existing',
            cond: 'canCreateNewVersion',
          },
          CREATE_FROM_UPLOAD: {
            target: 'draft.documentInfo.processing.upload',
            cond: 'canCreateNewVersion',
          },
          SWITCH_TAB_INFO: { target: '.documentInfo', actions: 'switchTabIdx' },
          SWITCH_TAB_SETTINGS: { target: '.documentSettings', actions: 'switchTabIdx' },
          SWITCH_TAB_ASSOCIATED_FILES: { target: '.documentAssociatedFiles', actions: 'switchTabIdx' },
          SWITCH_TAB_PUBLISH: { target: '.documentReleaseNotes', actions: 'switchTabIdx' },
        },
      },
      draft: {
        initial: 'documentInfo',
        states: {
          documentInfo: {
            description: 'Document Info tab in draft mode where most file processing happens',
            tags: [StateTags.DOCUMENT_INFO_TAB],
            initial: 'determine',
            states: {
              determine: {
                description: 'Determines current draft state node if file is queued, processing or idle',
                always: [
                  { target: 'processing.upload.queued', cond: 'isQueued' },
                  { target: 'processing.upload.optimizing', cond: 'isOptimizing' },
                  { target: 'processing.upload.error', cond: 'hasProcessingError' },
                  { target: 'idle' },
                ],
              },
              idle: {},
              processing: {
                description: 'Processing node for existing or new uploaded files',
                entry: [
                  'switchToInfoTab',
                  'resetErrors',
                ],
                states: {
                  existing: {
                    initial: 'optimizing',
                    tags: [StateTags.DISABLE_DRAFT_DELETE],
                    states: {
                      optimizing: {
                        description: 'Creating an existing file via Lambda function',
                        tags: [StateTags.DISABLE_EXIT, StateTags.DISABLE_MODIFY, StateTags.DISABLE_NAV],
                        meta: { infoMessage: INFO_MESSAGES.QUEUED },
                        // BEAC-3244 Potential race condition where API call might not return
                        // until after API returns. In this case we still want to switch to the
                        // new version. This seems to occur for files with a certain size payload (~80KB)
                        on: {
                          VERSION_UPDATE: [
                            {
                              target: '#UpdateVersion.published',
                              cond: 'isDraftDeleted',
                              actions: ['switchToLatestPublishedVersion'],
                            },
                            {
                              target: '#UpdateVersion.draft.documentInfo.idle',
                              cond: 'isVersionUpdateForNewerVersion',
                              actions: [
                                'setDocumentVersionIdFromUpdate',
                                'setVersionFormFromUpdate',
                                'updateSlideSettingsDraft',
                              ],
                            },
                          ],
                        },
                        invoke: {
                          src: 'createFromExistingOptimizing',
                          onDone: { target: 'processing' },
                          onError: {
                            target: '#UpdateVersion.published',
                            actions: 'handleError',
                          },
                        },
                      },
                      processing: {
                        description: 'Waiting to receive an AppSync subscription to update the current form values',
                        tags: [StateTags.DISABLE_MODIFY, StateTags.DISABLE_NAV],
                        meta: { infoMessage: INFO_MESSAGES.OPTIMIZING },
                        on: {
                          VERSION_UPDATE: [
                            {
                              target: '#UpdateVersion.published',
                              cond: 'isDraftDeleted',
                              actions: ['switchToLatestPublishedVersion'],
                            },
                            {
                              target: '#UpdateVersion.draft.documentInfo.idle',
                              actions: [
                                'setDocumentVersionIdFromUpdate',
                                'setVersionFormFromUpdate',
                                'updateSlideSettingsDraft',
                              ],
                            },
                          ],
                        },
                      },
                    },
                  },
                  upload: {
                    description: 'A multistep process to upload a file and subsequently create records',
                    initial: 'uploading',
                    states: {
                      uploading: {
                        description: 'Step 1. Uploading the file to S3',
                        tags: [StateTags.DISABLE_MODIFY, StateTags.DISABLE_NAV, StateTags.DISABLE_DRAFT_DELETE],
                        meta: {
                          infoMessage: INFO_MESSAGES.UPLOADING,
                          cancelMessage: INFO_MESSAGES.UPLOAD_CANCELLING,
                        },
                        entry: ['resetFileUploadCancel', 'switchToInfoTab'],
                        invoke: {
                          src: 'uploadFile',
                          onDone: [
                            { target: '#UpdateVersion.published', cond: 'isFileUploadCancelled' },
                            { target: 'createRecord' },
                          ],
                          onError: {
                            actions: 'handleError',
                            target: '#UpdateVersion.published',
                          },
                        },
                        on: {
                          CANCEL_UPLOAD: {
                            actions: 'flagFileUploadCancel',
                            // [NOTE] - This meta property is available until the next event
                            //          (which is dispatched after upload/cancel is finished)
                            meta: { infoMessage: INFO_MESSAGES.UPLOAD_CANCELLING },
                          },
                          // BEAC-3244 Potential race condition where API call might not return
                          // until after API returns. In this case we still want to switch to the
                          // new version. This seems to occur for files with a certain size payload (~80KB)
                          VERSION_UPDATE: [
                            {
                              target: '#UpdateVersion.published',
                              cond: 'isDraftDeleted',
                              actions: ['switchToLatestPublishedVersion'],
                            },
                            {
                              target: '#UpdateVersion.draft.documentInfo.idle',
                              cond: 'isVersionUpdateForNewerVersion',
                              actions: [
                                'setDocumentVersionIdFromUpdate',
                                'setVersionFormFromUpdate',
                                'updateSlideSettingsDraft',
                              ],
                            },
                          ],
                        },
                      },
                      createRecord: {
                        description: 'Step 1.5 - Create the records via Lambda function or bail if upload cancelled',
                        tags: [
                          StateTags.DISABLE_MODIFY,
                          StateTags.DISABLE_EXIT,
                          StateTags.DISABLE_NAV,
                          StateTags.DISABLE_DRAFT_DELETE,
                        ],
                        meta: { infoMessage: INFO_MESSAGES.UPLOADING },
                        invoke: {
                          src: 'createRecordForUpload',
                          onDone: { target: 'processing' },
                          onError: {
                            actions: 'handleError',
                            target: '#UpdateVersion.published',
                          },
                        },
                        // [NOTE] - There is a race condition between the GQL call finishing
                        //          and the AppSync updates being sent out early
                        //        - If we receive an optimistic AppSync update before the GQL call finishes
                        //          Skip the next step and go straight to queued
                        on: {
                          VERSION_UPDATE: [
                            {
                              target: '#UpdateVersion.published',
                              cond: 'isDraftDeleted',
                              actions: ['switchToLatestPublishedVersion'],
                            },
                            {
                              target: 'queued',
                              // [TODO-2126] - Add guards to make sure we're not receiving outside updates
                              //          not intended for this session
                              actions: 'setDocumentVersionIdFromUpdate',
                            },
                          ],
                        },
                      },
                      processing: {
                        description: 'Step 1.75 - An interim step to wait for the official queued status',
                        tags: [StateTags.DISABLE_NAV, StateTags.DISABLE_MODIFY, StateTags.DISABLE_DRAFT_DELETE],
                        meta: { infoMessage: INFO_MESSAGES.UPLOADING },
                        on: {
                          VERSION_UPDATE: [
                            {
                              target: '#UpdateVersion.published',
                              cond: 'isDraftDeleted',
                              actions: ['switchToLatestPublishedVersion'],
                            },
                            {
                              target: 'queued',
                              actions: 'setDocumentVersionIdFromUpdate',
                            },
                          ],
                        },
                      },
                      queued: {
                        description: 'Step 2 - In the queued status waiting for the optimized status',
                        tags: [StateTags.DISABLE_NAV, StateTags.DISABLE_MODIFY, StateTags.DISABLE_DRAFT_DELETE], // [NOTE] - We only block nav because tab switching is buggy here
                        meta: { infoMessage: INFO_MESSAGES.QUEUED },
                      },
                      optimizing: {
                        description: 'Step 3 - In the optimizing status waiting for the final processed status',
                        tags: [StateTags.DISABLE_NAV, StateTags.DISABLE_MODIFY, StateTags.DISABLE_DRAFT_DELETE], // [NOTE] - We only block nav because tab switching is buggy here
                        meta: { infoMessage: INFO_MESSAGES.OPTIMIZING },
                      },
                      error: {
                        description: 'A critical error state, the user must take action before resuming',
                        tags: [StateTags.PROCESSING_ERROR],
                        meta: { infoMessage: INFO_MESSAGES.PROCESSING_ERROR },
                      },
                    },
                    on: {
                      // [NOTE] - When we are in an "idle processing" state waiting for next conversion updates
                      VERSION_UPDATE: [
                        { target: '#UpdateVersion.published', cond: 'isDraftDeleted' },
                        { target: '.queued', cond: 'isQueued' },
                        { target: '.optimizing', cond: 'isOptimizing' },
                        {
                          target: '#UpdateVersion.draft.documentInfo.idle',
                          cond: 'isConversionProcessedFromUpdate',
                          actions: [
                            'flagOptimizedFinishedThisSession',
                            'setVersionFormFromUpdate',
                            'updateSlideSettingsDraft',
                          ],
                        },
                        { target: '.error', cond: 'isConversionErrorFromUpdate' },
                      ],
                    },
                  },
                },
              },
              uploadThumbnail: {
                description: 'A multistep process to upload a file and subsequently create records',
                tags: [StateTags.DISABLE_MODIFY, StateTags.DISABLE_NAV],
                meta: { infoMessage: INFO_MESSAGES.UPLOADING },
                initial: 'uploading',
                states: {
                  uploading: {
                    description: 'Step 1. Uploading the file to S3',
                    tags: [StateTags.DISABLE_MODIFY, StateTags.DISABLE_NAV, StateTags.DISABLE_DRAFT_DELETE],
                    meta: {
                      infoMessage: INFO_MESSAGES.UPLOADING,
                      cancelMessage: INFO_MESSAGES.UPLOAD_CANCELLING,
                    },
                    entry: ['resetFileUploadCancel', 'switchToInfoTab'],
                    invoke: {
                      src: 'uploadThumbnailImage',
                      onDone: {
                        target: 'createRecord',
                      },
                      onError: {
                        actions: 'handleError',
                        target: '#UpdateVersion.published',
                      },
                    },
                    on: {
                      CANCEL_UPLOAD: {
                        actions: 'flagFileUploadCancel',
                        meta: { infoMessage: INFO_MESSAGES.UPLOAD_CANCELLING },
                      },
                    },
                  },
                  createRecord: {
                    // eslint-disable-next-line max-len
                    description: 'Step 1.5 - Create the records via Lambda function or bail if upload cancelled',
                    tags: [
                      StateTags.DISABLE_MODIFY,
                      StateTags.DISABLE_EXIT,
                      StateTags.DISABLE_NAV,
                      StateTags.DISABLE_DRAFT_DELETE,
                    ],
                    meta: { infoMessage: INFO_MESSAGES.UPLOADING },
                    invoke: {
                      src: 'updateThumbnail',
                      onDone: {
                        target: '#UpdateVersion.draft.documentInfo',
                        actions: 'setVersionFormFromUpdateThumbnail',
                      },
                      onError: {
                        actions: 'handleError',
                        target: '#UpdateVersion.draft.documentInfo',
                      },
                    },
                  },
                },
              },
            },
            on: {
              // [TODO-2126] - This is pretty similar to sync forms on next/tab navigation (used for semver calculations dependencies)
              //             - We sync form data either way, but note that there are two different ways it's being handled right now
              SYNC_DISTRIBUTABLE: {
                description: 'Trigger to disable Associated Files modifications if file is no longer distributable',
                actions: [
                  'setVersionFormFromComponentSync',
                ],
              },
              SWITCH_TAB_NEXT: [
                { target: undefined, cond: 'isNavBlocked' },
                { target: 'documentSettings', actions: 'switchTabIdx' },
              ],
              SYNC_VERSION_FORM: {
                actions: 'setVersionFormFromComponentSync',
              },
            },
          },
          documentSettings: {
            description: 'Document Settings tab where Slide settings are configured',
            tags: [StateTags.DOCUMENT_SETTINGS_TAB],
            on: {
              SWITCH_TAB_PREV: { target: 'documentInfo', actions: 'switchTabIdx' },
              SWITCH_TAB_NEXT: {
                target: 'documentAssociatedFiles',
                actions: [
                  'switchTabIdx',
                  'setVersionFormFromComponentSync',
                ],
              },
              SYNC_VERSION_FORM: {
                description: 'Sync slide settings from component to machine',
                actions: [
                  'setVersionFormFromComponentSync',
                  'checkIfDirty',
                ],
              },
              SLIDE_SETTINGS_SYNC: {
                actions: [
                  'syncSlideSettingsDraft',
                  'saveDraft',
                ],
              },
            },
          },
          documentAssociatedFiles: {
            description: 'Document Associated Files tab where Associated Files are configured',
            tags: [StateTags.DOCUMENT_ASSOCIATED_FILES],
            initial: 'idle',
            states: {
              idle: {},
              processing: {
                description: 'The state during attached file uploading',
                tags: [StateTags.DISABLE_NAV, StateTags.DISABLE_MODIFY],
                on: {
                  ATTACHED_FILE_UPLOADED: {
                    description: 'On attached file(s) uploaded, create temporary records who commit on save draft',
                    actions: ['createAttachedFileRecords', 'checkIfDirty'],
                  },
                },
              },
            },
            on: {
              ATTACHED_FILE_UPLOAD_STATUS_CHANGE: [
                { target: '.processing', cond: 'isAssociatedFileUploadProcessing' },
                { target: '.idle' },
              ],
              ASSOCIATED_DOCUMENT_LINK: {
                description: 'On linked documents, create temporary records who commit on save draft',
                actions: ['associateDocumentLink', 'checkIfDirty'],
              },
              ASSOCIATED_FILE_DELETE: { actions: ['deleteAssociatedFile', 'checkIfDirty'] },
              ASSOCIATED_FILE_UPDATED: { actions: ['updateAssociatedFile', 'checkIfDirty'] },
              SWITCH_TAB_PREV: [
                { target: 'documentSettings', actions: 'switchTabIdx' },
              ],
              SWITCH_TAB_NEXT: {
                target: 'documentPublish',
                actions: [
                  'switchTabIdx',
                  'setVersionFormFromComponentSync',
                ],
              },
              SYNC_VERSION_FORM: {
                description: 'Sync slide settings from component to machine',
                actions: [
                  'setVersionFormFromComponentSync',
                  'checkIfDirty',
                ],
              },
            },
          },
          documentPublish: {
            description: 'Document Publish tab where notifications, semVer, and release notes are set',
            tags: [StateTags.DOCUMENT_PUBLISH_TAB],
            initial: 'idle',
            states: {
              idle: {
                description: 'On entry, recalculate the SemVer based on latest form info',
                entry: 'setSemVerChanges',
                on: {
                  PUBLISH_VERSION: {
                    target: 'publishing',
                    cond: 'canPublishDraft',
                    actions: ['publishVersion'],
                  },
                },
              },
              publishing: {
                tags: [StateTags.DISABLE_NAV, StateTags.DISABLE_MODIFY, StateTags.DISABLE_EXIT],
                invoke: {
                  src: 'updateContentPageData',
                  onDone: {
                    target: '.',
                  },
                  onError: {
                    target: 'idle',
                    actions: 'handleError',
                  },
                },
                on: {
                  // [TODO-2126] - It's possible we could get stuck here if the subscription never comes back
                  //          or the update comes back early
                  //        - Consider a timeout?
                  VERSION_UPDATE: [
                    {
                      target: '#UpdateVersion.published',
                      cond: 'isDraftDeleted',
                      actions: ['switchToLatestPublishedVersion'],
                    },
                    {
                      description: 'After publishing, switch over to published view',
                      target: 'setPublishStatus',
                      actions: [
                        'setDocumentVersionIdFromUpdate',
                        'resetSemVerChanges',
                        'resetForm',
                      ],
                    },
                  ],
                },
              },
              setPublishStatus: {
                invoke: {
                  src: 'publishDocument',
                  onDone: {
                    target: '#UpdateVersion.published',
                  },
                  onError: {
                    target: 'idle',
                  },
                },
                exit: 'resetDirty',
              },
            },
            on: {
              SWITCH_TAB_PREV: { target: 'documentAssociatedFiles', actions: 'switchTabIdx' },
              SYNC_VERSION_FORM: {
                actions: [
                  'setVersionFormFromComponentSync',
                  'checkIfDirty',
                ],
              },
            },
          },
          // [TODO-2126] - This is probably better served as a parallel state
          //            This only works because we keep track of the last known tab via selectedTabIdx
          //            and we disable most other actions so the user cannot do anything
          //        - This might also work without switch if we configure the draft node as a history node
          draftSaveProcessing: {
            description: 'A "history" type node that handles saving draft (or publishing) from any tab',
            tags: [StateTags.DISABLE_NAV, StateTags.DISABLE_MODIFY],
            invoke: {
              src: 'updateContentPageData',
              onDone:
                [
                  {
                    target: '#UpdateVersion.draft.documentInfo',
                    cond: 'isDocumentInfoTab',
                    actions: ['resetDirty'],
                  },
                  {
                    target: '#UpdateVersion.draft.documentSettings',
                    cond: 'isDocumentSettingsTab',
                    actions: ['resetDirty'],
                  },
                  {
                    target: '#UpdateVersion.draft.documentAssociatedFiles',
                    cond: 'isDocumentAssociatedFilesTab',
                    actions: ['resetDirty'],
                  },
                  {
                    target: '#UpdateVersion.draft.documentPublish',
                    cond: 'isDocumentPublishTab',
                    actions: ['resetDirty'],
                  },
                ],
              onError: {
                target: '#UpdateVersion.draft',
                actions: 'handleError',
              },
            },
            on: {
              VERSION_UPDATE: [
                // [TODO-2126] - Experimental check to avoid version updates until the appsync version is actually bumped
                //               since we receive 3 subscription updates.
                //             - We should probably apply this to all VERSION_UPDATE actions, but test here for now
                { cond: 'updateVerNotBumped' },
                { target: '#UpdateVersion.published', cond: 'isDraftDeleted' },
                {
                  target: '#UpdateVersion.draft.documentInfo',
                  cond: 'isDocumentInfoTab',
                  actions: 'resetForm',
                },
                {
                  target: '#UpdateVersion.draft.documentSettings',
                  cond: 'isDocumentSettingsTab',
                  actions: 'resetForm',
                },
                {
                  target: '#UpdateVersion.draft.documentAssociatedFiles',
                  cond: 'isDocumentAssociatedFilesTab',
                  actions: 'resetForm',
                },
                {
                  target: '#UpdateVersion.draft.documentPublish',
                  cond: 'isDocumentPublishTab',
                  actions: 'resetForm',
                },
              ],
            },
          },
        },
        on: {
          UPLOAD_THUMBNAIL: {
            target: 'draft.documentInfo.uploadThumbnail',
          },
          REMOVE_THUMBNAIL: {
            target: 'draft.documentInfo.uploadThumbnail.createRecord',
          },
          SET_IS_DIRTY: {
            description: 'Mark the various tabs as dirty from the form components',
            actions: 'setDirtyTabs',
          },
          VERSION_UPDATE: [
            {
              // [TODO-2126] - This is also repeated in other VERSION_UPDATE events -- we should figure out a way where it's more centralized
              //               This is because nested VERSION_UPDATES will take precdence but each of those also need to handle their own usecase
              //             - Try to see if there's a better global catch all level pattern we could use
              //             - Also, this still doesn't 100% absolve crashing the app, we still have to put a workaround in component side
              //               because selector updates there will return a null currentDocumentVersionORM
              //               before the machine can properly transition
              //             - This probably also doesn't work because the observed delete event will never come in as the record by that time
              //               has already been removed in Redux 🤦‍♂️
              description: 'Handles the concurrent use-case of when another publisher deletes a draft',
              target: '#UpdateVersion.published',
              cond: 'isDraftDeleted',
              actions: ['switchToLatestPublishedVersion'],
            },
            {
              description: 'Handles the concurrent use-case of when another publisher publishes the same version',
              target: '#UpdateVersion.published',
              cond: 'isPublished',
              // [TODO-2126] - Use an action to set the current form values to the newly published version
              //        - Or do read states not source from State machine's context?
            },
          ],
          DELETE_DRAFT: {
            target: '#UpdateVersion.published',
            cond: 'isDraftDeleteEnabled',
            actions: [
              'switchToLatestPublishedVersion',
              'deleteDraft',
              'updateSlideSettingsDraft',
              'resetDirty',
            ],
          },
          SAVE_DRAFT: {
            target: '#UpdateVersion.draft.draftSaveProcessing',
            actions: ['saveDraft', 'resetDirty'],
          },
          SWITCH_TAB_INFO: {
            target: '.documentInfo',
            cond: 'isNavUnblocked',
            actions: 'switchTabIdx',
          },
          SWITCH_TAB_SETTINGS: {
            target: '.documentSettings',
            cond: 'isNavUnblocked',
            actions: ['switchTabIdx'],
          },
          SWITCH_TAB_ASSOCIATED_FILES: {
            target: '.documentAssociatedFiles',
            cond: 'isNavUnblocked',
            actions: 'switchTabIdx',
          },
          SWITCH_TAB_PUBLISH: {
            target: '.documentPublish',
            cond: 'isNavUnblocked',
            actions: [
              'switchTabIdx',
              'setVersionFormFromComponentSync',
            ],
          },
        },
      },
    },
    on: {
      SWITCH_DOCUMENT_VERSION: {
        target: 'determine',
        cond: 'isNavUnblocked',
        actions: [
          'switchToInfoTab',
          'setDocumentVersionIdFromVersionSelect',
          'resetForm',
          'resetErrors',
        ],
      },
    },
  },
  {
    actions: {
      /** NAVIGATION */
      switchToInfoTab: assign((ctx) => { ctx.selectedTabIndex = 0 }),
      switchTabIdx: assign((ctx, event) => {
        const evt = event as Ver.SwitchTabEvents
        // [NOTE] - We noop tab switches that would result in the same value to (possibly) avoid re-renders
        // [TODO] - Break these indexes into consts perhaps to make it clearer
        // [TODO] - Consider using Meta instead to determine the current state and choosing where to go next
        const tabs = ['SWITCH_TAB_INFO', 'SWITCH_TAB_SETTINGS', 'SWITCH_TAB_ASSOCIATED_FILES', 'SWITCH_TAB_PUBLISH']
        const actions = ['SWITCH_TAB_NEXT', 'SWITCH_TAB_PREV']

        if (evt.type === tabs[0] && ctx.selectedTabIndex !== 0) ctx.selectedTabIndex = 0

        else if (evt.type === tabs[1] && ctx.selectedTabIndex !== 1) ctx.selectedTabIndex = 1

        else if (evt.type === tabs[2] && ctx.selectedTabIndex !== 2) ctx.selectedTabIndex = 2

        else if (evt.type === tabs[3] && ctx.selectedTabIndex !== 3) ctx.selectedTabIndex = 3

        else if (evt.type === actions[0] && ctx.selectedTabIndex !== 3) ctx.selectedTabIndex++
        else if (evt.type === actions[1] && ctx.selectedTabIndex !== 0) ctx.selectedTabIndex--
      }),

      /** DOCUMENT/VERSION META */
      setDocumentVersionIdFromUpdate: assign((ctx, event) => {
        const evt = event as Ver.EVT_VERSION_UPDATE
        ctx.documentVersionId = evt.payload.current.id
      }),
      setDocumentVersionIdFromVersionSelect: assign((ctx, event) => {
        const evt = event as Ver.EVT_SWITCH_DOCUMENT_VERSION
        ctx.documentVersionId = evt.payload.documentVersionId
      }),

      /** DRAFT - DIRTY */
      setDirtyTabs: assign((ctx, event) => {
        const evt = event as Ver.EVT_SET_IS_DIRTY
        switch (evt.payload.type) {
          case 'info': {
            ctx.documentInfoIsDirty = evt.payload.isDirty
            return;
          }
          case 'settings': {
            ctx.documentSettingsIsDirty = evt.payload.isDirty
            return;
          }
          case 'slidesData': {
            ctx.documentSlidesDataIsDirty = evt.payload.isDirty
            return;
          }
          case 'publish': {
            ctx.documentPublishIsDirty = evt.payload.isDirty
          }
        }
      }),
      checkIfDirty: assign((ctx) => {
        // [NOTE] - Deep equality checking (lodash, fast-deep-equal) isn't working
        //          after initial form touching, not sure what values are the offenders,
        //          string comparison works though and should be good enough (hopefully)
        const currentDocVer = ctx.getDocumentORM().relations.version.latestDocumentVersionORM
        const one = JSON.stringify(versioningUtil.omitInternalFields(currentDocVer.model))
        const two = JSON.stringify(ctx.versionForm)

        ctx.documentSettingsIsDirty = one !== two
      }),
      resetDirty: assign((ctx) => {
        ctx.documentInfoIsDirty = false
        ctx.documentPublishIsDirty = false
        ctx.documentSettingsIsDirty = false
        ctx.documentSlidesDataIsDirty = false
      }),

      /** DRAFT - SETTERS */
      syncSlideSettingsDraft: assign((ctx, event) => {
        const evt = event as Ver.EVT_SLIDE_SETTINGS_SYNC
        ctx.versionForm = {
          ...ctx.versionForm,
          ...evt.payload,
        }
      }),
      setVersionFormFromUpdate: assign((ctx, event) => {
        const evt = event as Ver.EVT_VERSION_UPDATE
        // [NOTE] - We override the entire draft form, not merge
        ctx.versionForm = versioningUtil.omitInternalFields(evt.payload.current)
      }),
      setVersionFormFromComponentSync: assign((ctx, event) => {
        const evt = event as Ver.SyncFormEvents
        ctx.versionForm = { ...ctx.versionForm, ...(evt.payload?.versionForm ?? {}) }
      }),
      setVersionFormFromUpdateThumbnail: assign((ctx, event) => {
        const evt = event as Ver.EVT_UPDATED_THUMBNAIL
        ctx.versionForm.hasCustomThumbnail = evt.data.hasCustomThumbnail
        ctx.versionForm.selectedThumbnail = evt.data.selectedThumbnail
      }),
      associateDocumentLink: assign((ctx, evt) => {
        const event = evt as Ver.EVT_ASSOCIATED_DOCUMENT_LINK

        if (!ctx.versionForm.associatedFiles) { ctx.versionForm.associatedFiles = [] }

        const newAssociatedFile = createAssociatedFile({
          attachmentId: event.payload.documentId,
          type: AssociatedFileType.DOCUMENT,
          status: 'ACTIVE',
        })

        ctx.versionForm.associatedFiles.push(newAssociatedFile)
      }),
      deleteAssociatedFile: assign((ctx, evt) => {
        const event = evt as Ver.EVT_ASSOCIATED_FILE_DELETE

        ctx.versionForm.associatedFiles = ctx
          .versionForm
          .associatedFiles
          ?.filter(file => file.attachmentId !== event.payload.attachmentId)
      }),
      updateAssociatedFile: assign((ctx, evt) => {
        const event = evt as Ver.EVT_ASSOCIATED_FILE_UPDATED

        if (!ctx.versionForm.associatedFiles) { ctx.versionForm.associatedFiles = [] }

        const targetFileIdx = ctx
          .versionForm
          .associatedFiles
          .findIndex(file => file.attachmentId === event.payload.associatedFile.attachmentId)

        ctx.versionForm.associatedFiles[targetFileIdx] = event.payload.associatedFile
      }),
      setSemVerChanges: (ctx) => {
        const documentORM = ctx.getDocumentORM()
        const versionChanges = versioningUtil.determineSemVerChange(
          ctx.versionForm,
          documentORM.relations.version.latestPublishedDocumentVersionORM,
        )

        ctx.changesCheckResult = {
          disableMinor: versionChanges.isMajorVersionRequired,
          detectedChangeType: versionChanges.isMajorVersionProposed
            ? DocumentVersionChangeType.MAJOR
            : DocumentVersionChangeType.MINOR,
        }
      },
      createAttachedFileRecords: assign((ctx, evt) => {
        const event = evt as Ver.EVT_ATTACHED_FILE_UPLOADED
        const { attachedFile } = event.payload

        if (!ctx.versionForm.associatedFiles) { ctx.versionForm.associatedFiles = [] }

        // Create temporary attached file record
        const newAssociatedFile = createAssociatedFile({
          attachmentId: attachedFile.id,
          type: AssociatedFileType.ATTACHED_FILE,
          status: 'ACTIVE',
        });

        ctx.versionForm.associatedFiles.push(newAssociatedFile)
      }),
      resetForm: assign((ctx) => {
        // [TODO-2126] - Should probably just send an action before this (resetDirty) instead of doing it here
        ctx.documentInfoIsDirty = false
        ctx.documentSettingsIsDirty = false
        ctx.documentPublishIsDirty = false
        ctx.documentSlidesDataIsDirty = false

        const currentReduxVersion = ctx
          .getDocumentORM()
          .relations
          .documentVersions
          .find(ver => ver.model.id === ctx.documentVersionId)?.model ??
          {}

        ctx.versionForm = currentReduxVersion
      }),
      resetSemVerChanges: assign((ctx) => { ctx.changesCheckResult = undefined }),
      resetErrors: assign((ctx) => { ctx.errors = {} }),
      /** NEW VERSION PROCESSING */
      flagOptimizedFinishedThisSession: assign((ctx) => { ctx.hasOptimizedFinishedThisSession = true }),
      resetFileUploadCancel: assign((ctx) => { ctx.cancelUpload = false }),
      flagFileUploadCancel: assign((ctx) => { ctx.cancelUpload = true }),

      /** DRAFT - SAVE & PUBLISH */
      // [TODO-2126] - Figure out an elegant way to glue together multiple actions from different slices
      //          i.e. Document (first time) and Version publish
      publishVersion: (ctx, event) => {
        const evt = event as Ver.EVT_PUBLISH_VERSION
        const documentORM = ctx.getDocumentORM()

        const isFirstVersion = !documentORM.relations.version.latestPublishedDocumentVersionORM
        const { latestDocumentVersionORM } = documentORM.relations.version

        // Publish the document if this is the very first version
        if (isFirstVersion) {
          store.dispatch(documentActions.publish(documentORM));
        }
        // Otherwise bump the updatedAt timestamp and track analytics
        else {
          store.dispatch(documentActions.save({
            model: Document,
            entity: documentORM.model,
            updates: {},
          }));

          // We only track on new version for existing published doc
          analytics?.track('DOCUMENT_PUBLISH_VERSION', {
            action: 'PUBLISH_VERSION',
            category: 'DOCUMENT',
            documentId: latestDocumentVersionORM.relations.documentORM.model.id,
            documentVersionId: latestDocumentVersionORM.model.id,
          });
        }

        const updates = { ...ctx.versionForm, ...evt.payload.versionForm }
        const omittedValues = versioningUtil.omitInternalFields(updates)

        store.dispatch(documentVersionActions.prePublish(latestDocumentVersionORM, omittedValues));
      },
      saveDraft: (ctx, event) => {
        const evt = event.type === 'SAVE_DRAFT'
          ? event as Ver.EVT_SAVE_DRAFT
          : event as Ver.EVT_SLIDE_SETTINGS_SYNC

        // [TODO-2126] - Needs to be document version now
        analytics?.track('DOCUMENT_SAVE', {
          action: 'SAVE',
          category: 'DOCUMENT',
          documentId: ctx.documentVersionId,
        });

        // We need to grab the latest version
        // The model id is not being bumped?
        const documentVersion = ctx
          .getDocumentORM()
          .relations
          .documentVersions
          .find(docVer => docVer.model.id === ctx.documentVersionId)

        if (!documentVersion) {
          console.error('Something went wrong during saving')
          return;
        }

        // We need to update the version form after hitting Edit Properties
        let updates: Partial<DocumentVersion> = {}
        if (evt.type === 'SAVE_DRAFT') {
          const newVersionForm = evt.payload?.versionForm ?? {}
          updates = versioningUtil.omitInternalFields({ ...ctx.versionForm, ...newVersionForm })
        } else if (evt.type === 'SLIDE_SETTINGS_SYNC') {
          // If the event type is `SLIDE_SETTINGS_SYNC`, we are only updating values from the slide setting tab
          const slideSettingsValues = pick(ctx.versionForm, ['pageGroups', 'selectedThumbnail', 'pages'])
          updates = versioningUtil.omitInternalFields({ ...slideSettingsValues })
        }

        store.dispatch(documentVersionActions.save({
          model: DocumentVersion,
          entity: documentVersion.model,
          updates,
        }));
      },
      switchToLatestPublishedVersion: assign((ctx) => {
        const latestPublishedVersion = ctx
          .getDocumentORM()
          .relations
          .version
          .latestPublishedDocumentVersionORM

        if (latestPublishedVersion) { ctx.documentVersionId = latestPublishedVersion.model.id }
      }),
      deleteDraft: assign((ctx) => {
        const currentDocumentVersionORM = ctx
          .getDocumentORM()
          .relations
          .version
          .latestDocumentVersionORM
        // [TODO-2126] - Temp workaround to allow concurrent deletion, normally we don't need to check here
        //               but in this case, we want to make sure we don't execute the action
        if (currentDocumentVersionORM.model.status === 'NOT_PUBLISHED') {
          store.dispatch(multiSliceActions.deleteDocumentDraft(currentDocumentVersionORM))
        }
      }),
      /** ERROR HANDLERS */
      handleError: assign((ctx, event) => {
        console.error({ errEvt: event })
        // @ts-expect-error
        ctx.errors[event.type] = event?.data?.message ?? ''
      }),
      updateSlideSettingsDraft: send((ctx) => {
        const currentDocumentVersion = ctx
          .getDocumentORM()
          .relations
          .version
          .latestDocumentVersionORM

        return {
          type: 'SIGNAL_VERSION_UPDATE',
          payload: currentDocumentVersion,
        }
      }, {
        to: 'SlideSettingsActor',
      }),
    },
    guards: {
      /** DOCUMENT/VERSION META */
      isPublished: (ctx) => {
        const documentORM = ctx.getDocumentORM()
        const selectedDocVerORM = documentORM
          .relations
          .documentVersions
          .find(docVerORM => docVerORM.model.id === ctx.documentVersionId)

        return selectedDocVerORM?.model.status === 'PUBLISHED'
      },
      updateVerNotBumped: (_, event) => {
        const evt = event as Ver.EVT_VERSION_UPDATE
        // @ts-expect-error - these are untyped, meta fields from appsync
        const skipUpdate = evt.payload.prev._version === evt.payload.current._version
        return skipUpdate
      },
      isDraftDeleteEnabled: (_, __, meta) => {
        return !meta.state.hasTag(StateTags.DISABLE_DRAFT_DELETE)
      },
      isDraftDeleted: (_, event) => {
        const evt = event as Ver.EVT_VERSION_UPDATE
        const isDraftDeleted = evt.payload.current.status === 'DELETED'
        return isDraftDeleted
      },
      isSealed: (ctx) => {
        const documentORM = ctx.getDocumentORM()
        return ['ARCHIVED', 'REVOKED', 'DELETED'].includes(documentORM?.model.status)
      },
      /** NAVIGATION */
      isDocumentInfoTab: (ctx) => ctx.selectedTabIndex === 0,
      isDocumentSettingsTab: (ctx) => {
        return ctx.selectedTabIndex === 1
      },
      isDocumentAssociatedFilesTab: (ctx) => {
        return ctx.selectedTabIndex === 2
      },
      isDocumentPublishTab: (ctx) => {
        return ctx.selectedTabIndex === 3
      },
      isNavUnblocked: (_, __, meta) => !meta.state.hasTag(StateTags.DISABLE_NAV),
      isNavBlocked: (_, __, meta) => meta.state.hasTag(StateTags.DISABLE_NAV),
      isVersionUpdateForNewerVersion: (ctx, event) => {
        const evt = event as Ver.EVT_VERSION_UPDATE
        return ctx.documentVersionId < evt.payload.current.id
      },
      /** DOCUMENT SETTINGS */
      isAssociatedFileUploadProcessing: (_, event) => {
        const evt = event as Ver.EVT_ATTACHED_FILE_UPLOAD_STATUS_CHANGE
        const isProcessing = [
          UPLOAD_STATUS.IN_PROGRESS,
          UPLOAD_STATUS.CANCELLING,
        ].some(status => evt.payload.status === status)

        return isProcessing
      },

      /** NEW VERSION CREATION */
      canCreateNewVersion: (ctx) => {
        const documentORM = ctx.getDocumentORM()
        const draftInProgress = documentORM.meta.hasUnpublishedVersion
        const isPublished = documentORM.model.status === 'PUBLISHED'
        return !draftInProgress && isPublished
      },
      canPublishDraft: (ctx) => {
        const hasNoErrors = !Object.values(ctx.errors).length
        return hasNoErrors
      },
      /** NEW VERSION PROCESSING */
      hasProcessingError: (ctx) => {
        return ctx.getDocumentORM()
          .relations
          .version
          .latestDocumentVersionORM
          .model
          .conversionStatus === 'ERROR';
      },
      isOptimizing: (ctx) => {
        return ctx.getDocumentORM()
          .relations
          .version
          .latestDocumentVersionORM
          .model
          .conversionStatus === 'PROCESSING';
      },
      isQueued: (ctx) => {
        return ctx.getDocumentORM().relations.version.latestDocumentVersionORM.model.conversionStatus === 'PENDING';
      },
      isConversionProcessedFromUpdate: (_, event) => {
        const evt = event as Ver.EVT_VERSION_UPDATE
        return evt.payload.current.conversionStatus === 'PROCESSED'
      },
      isConversionErrorFromUpdate: (_, event) => {
        const evt = event as Ver.EVT_VERSION_UPDATE
        return evt.payload.current.conversionStatus === 'ERROR'
      },
      isFileUploadCancelled: (ctx) => ctx.cancelUpload,
    },
    services: {
      uploadFile: async (ctx, event) => {
        const documentORM = ctx.getDocumentORM()
        const evt = event as Ver.EVT_CREATE_FROM_UPLOAD
        const file = evt.payload.file
        try {
          const fileExtension = file.name.split('.').pop();
          const key = `publish_tmp/${Date.now()}.${fileExtension}`;
          const name = formatDocumentFileNameHeader(file.name)

          // UPLOAD THE FILE TO THE TEMPORARY PATH IN S3
          await Storage.put(key, file, {
            contentType: file!.type,
            contentDisposition: `attachment; filename="${name}"`,
            level: 'private',
          });

          const apiInputObj: CreateDocumentVersionFromS3UploadInput = {
            srcFilename: file.name,
            fileS3Key: key,
            documentId: documentORM.model.id,
            existingVersionId: documentORM.relations.version.latestPublishedDocumentVersionORM?.model.id!,
            version: documentORM.relations.documentVersions.length + 1,
          };

          if (ctx.cancelUpload) {
            // Since it was cancelled but can't stop the put, proceed to delete the item
            Storage.remove(key);
            return 'CANCEL';
          }

          const evtData: Ver.EVT_CREATE_FILE['data'] = {
            file,
            S3Key: key,
            apiInputObj,
          }

          return evtData
        } catch (e) {
          console.error(e);
          throw e
        }
      },
      createRecordForUpload: async (_, event) => {
        const evt = event as Ver.EVT_CREATE_FILE
        // [NOTE] - This data comes from the previous step/action above (uploadFile)
        const { apiInputObj } = evt.data
        const result = await API.graphql(
          graphqlOperation(
            createDocumentVersionFromS3Upload,
            { inputVersion: apiInputObj },
          ),
        ) as GraphQLResult<CreateDocumentVersionFromS3UploadMutation>;

        analytics?.track('DOCUMENT_UPLOAD_VERSION', {
          action: 'UPLOAD_VERSION',
          category: 'DOCUMENT',
          documentId: result.data?.createDocumentVersionFromS3Upload?.documentId,
          documentVersionId: result.data?.createDocumentVersionFromS3Upload?.id,
        });

        return result
      },
      uploadThumbnailImage: async (ctx, event) => {
        const documentORM = ctx.getDocumentORM()
        const evt = event as Ver.EVT_UPLOAD_THUMBNAIL
        const file = evt.payload.file
        const type = VIDEO_TYPES_ENUM[documentORM.model.type] ? 'VIDEO' : documentORM.model.type
        const isWebDoc = documentORM.model.type === FileType.WEB
        analytics?.track('UPLOAD_THUMBNAIL', {
          action: 'UPLOAD',
          category: 'DOCUMENT',
          type,
          documentId: documentORM.relations.version.latestDocumentVersionORM.model.documentId,
          documentVersionId: documentORM.relations.version.latestDocumentVersionORM.model.id,
          URL: isWebDoc ? documentORM.relations.version.latestDocumentVersionORM.model.contentURL : undefined,
        })

        try {
          const fileExtension = file.name.split('.').pop();
          const key = `publish_tmp/${Date.now()}.${fileExtension}`;
          await Storage.put(key, file, {
            contentType: file!.type,
            contentDisposition: `attachment; filename="${file.name}"`,
            level: 'private',
          });

          const apiInputObj: UpdateDocumentThumbnailInput = {
            srcFilename: file.name,
            fileS3Key: key,
            documentId: documentORM.model.id,
            documentVersionId: documentORM.relations.version.latestDocumentVersionORM?.model.id!,
          };

          if (ctx.cancelUpload) {
            // Since it was cancelled but can't stop the put, proceed to delete the item
            Storage.remove(key);
            return 'CANCEL';
          }

          const evtData: Ver.EVT_UPDATE_THUMBNAIL['data'] = {
            file,
            S3Key: key,
            apiInputObj,
          }

          return evtData
        } catch (e) {
          console.error(e);
          throw e
        }
      },
      updateThumbnail: async (ctx, event) => {
        const evt = event as Ver.EVT_UPDATE_THUMBNAIL
        const documentORM = ctx.getDocumentORM()
        if (evt.type !== 'REMOVE_THUMBNAIL' && !evt.data?.apiInputObj) return
        try {
          const apiInputObj = (evt && evt.data) ? evt.data.apiInputObj
            : {
              documentId: documentORM.model.id,
              documentVersionId: documentORM.relations.version.latestDocumentVersionORM?.model.id!,
            }
          const result = await API.graphql(
            graphqlOperation(
              updateDocumentThumbnail,
              { inputDocumentThumbnail: apiInputObj },
            ),
          ) as GraphQLResult<UpdateDocumentThumbnailMutation>;
          const evtData: Ver.EVT_UPDATED_THUMBNAIL['data'] = {
            selectedThumbnail: result.data?.updateDocumentThumbnail?.selectedThumbnail!,
            hasCustomThumbnail: result.data?.updateDocumentThumbnail?.hasCustomThumbnail!,
          }
          // track when user removed customThumbnail
          if (!evtData.hasCustomThumbnail) {
            const documentType = documentORM.model.type
            const type = VIDEO_TYPES_ENUM[documentType] ? 'VIDEO' : documentType
            const isWebDoc = documentORM.model.type === FileType.WEB
            analytics?.track('REMOVE_THUMBNAIL', {
              action: 'REMOVE_THUMBNAIL',
              category: 'DOCUMENT',
              type,
              documentId: documentORM.model.id,
              documentVersionId: documentORM.relations.version.latestDocumentVersionORM?.model.id!,
              URL: isWebDoc ? documentORM.relations.version.latestDocumentVersionORM.model.contentURL : undefined,
            })
          }
          return evtData
        } catch (e) {
          console.error(e);
          throw e
        }
      },
      createFromExistingOptimizing: async (ctx: Ver.UpdateVersionContext) => {
        const documentORM = ctx.getDocumentORM()
        const { latestDocumentVersionORM } = documentORM.relations.version

        try {
          const newDocVer = await API.graphql(
            graphqlOperation(createDocumentVersionFromExisting, {
              documentId: latestDocumentVersionORM.model.documentId,
              newVersionNumber: latestDocumentVersionORM.model.versionNumber + 1,
              existingVersionId: latestDocumentVersionORM.model.id,
            }),
          );

          return newDocVer
        } catch (e) {
          console.warn('Error when creating existing version from existing', e)
          throw e
        }
      },
      updateContentPageData: async (ctx: Ver.UpdateVersionContext, event) => {
        const evt = event.type === 'SAVE_DRAFT' ? event as Ver.EVT_SAVE_DRAFT
          : event as Ver.EVT_PUBLISH_VERSION
        try {
          if (!evt.payload.contentPageData) return
          const documentORM = ctx.getDocumentORM()
          const { latestDocumentVersionORM } = documentORM.relations.version
          const pagesData = evt.payload.contentPageData?.map((pageData) => {
            return {
              number: pageData.presentationPageNumber,
              title: pageData.title,
              speakerNotes: pageData.speakerNotes,
            }
          })
          await API.graphql(
            graphqlOperation(updateDocumentPageData, {
              input:{
                documentId: latestDocumentVersionORM.model.documentId,
                documentVersionId: latestDocumentVersionORM.model.id,
                pagesData : pagesData,
              },
            }),
          );
          // ovveride disk cache with new data from request pages.json
          const s3Key = `${latestDocumentVersionORM.model.convertedFolderKey}json/Pages.json`;
          await fetchJsonFromCloudfront(s3Key, true);
        }
        catch (e) {
          console.warn('Error when updating page data', e)
          throw e
        }
      },
      publishDocument: async (ctx: Ver.UpdateVersionContext) => {
        const { latestDocumentVersionORM } = ctx.getDocumentORM().relations.version

        const { data } = await API.graphql<GraphQLQuery<PublishDocumentMutation>>(
          graphqlOperation(publishDocument, {
            documentId: latestDocumentVersionORM.model.id,
          }));

        if (!data?.publishDocument)
        // TODO: add validation depending code backend status
        { throw new Error('Error when publishing document') }
      },
    },
  },
)

export default updateVersion
