import React, {
  useState,
  createContext,
  PropsWithChildren,
  useContext,
} from 'react';
import { HubSectionType } from '@alucio/aws-beacon-amplify/src/models';
import useHubFormState, { EhitHubRhform } from 'src/screens/Hubs/EditHub/useHubForm';

export interface HubRHFormStateType {
  rhform: EhitHubRhform
  isSaving: boolean
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>
}

export enum HubFormWidgetKeys {
  textarea = 'textarea',
  sharedFiles = 'sharedFiles',
  todoList = 'todoList',
}

interface InitialValues {
  hubId: string
}

export const rhformFieldName: Record<HubSectionType, string> = {
  [HubSectionType.TEXTAREA]: 'textareaWidget',
  [HubSectionType.SHARED_FILES]: 'sharedFilesWidget',
  [HubSectionType.TODO_LIST]: 'todoListWidget',
}

export const HubRHFormStateContext = createContext<HubRHFormStateType | null>(null!)
HubRHFormStateContext.displayName = 'HubRHFormContext'

const HubRHFormStateProvider: React.FC<PropsWithChildren<InitialValues>> = (props) => {
  const { hubId, children } = props
  const [isSaving, setIsSaving] = useState(false)
  const rhform = useHubFormState({ hubId })

  const contextValue: HubRHFormStateType = {
    rhform,
    isSaving,
    setIsSaving
  }

  return (
    <HubRHFormStateContext.Provider value={contextValue}>
      {children}
    </HubRHFormStateContext.Provider>
  )
}

HubRHFormStateProvider.displayName = 'HubRHFormStateProvider'
export const useHubRHFromState = () => {
  const context = useContext(HubRHFormStateContext)
  if (!context) {
    throw new Error('useHubRHFromState must be used within the HubRHFormStateProvider')
  }
  return context;
}

export default HubRHFormStateProvider


