import React from 'react';
import { ImageStyle, Pressable, StyleSheet } from 'react-native';
import { DNABox, DNAText, Stack, Iffy, DNAChip } from '@alucio/lux-ui';
import { PresentablePage } from 'src/types/types';
import { PresentedMeta } from '@alucio/aws-beacon-amplify/src/models';
import { detectArchivedFileKeyPath } from '../../SlideSelector/useThumbnailSelector';
import DNAThumbnail from '../Thumbnail/DNAThumbnail';
import {
  SLIDE_ROLL_VARIANT,
  ThumbnailNumberPosition,
  thumbnailNumberPositionProps,
  thumbnailVariantSettings,
  thumbnailVariantStyles,
} from './StyleSetting';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

const S = StyleSheet.create({
  full: { width: '100%', height: '100%' },
  tintOverlay: {
    backgroundColor: colors['color-text-white'],
    opacity: 0.5,
  },
})

interface DNAPresentableThumbnailProps {
  page: PresentablePage,
  isContentUnavailable?: boolean,
  isSelected?: boolean,
  variant?: SLIDE_ROLL_VARIANT,
  onSelect?: () => void,
  numberPosition?: ThumbnailNumberPosition,
  presentedMeta?: PresentedMeta,
  height?: number,
  width?: number,
  tintOverlay?: boolean,
  fileTypeVisible?: boolean,
  title?: string
}

const DNASlideRollThumbnail: React.FC<DNAPresentableThumbnailProps> = ({
  height,
  isContentUnavailable,
  isSelected,
  numberPosition,
  onSelect,
  page,
  variant = SLIDE_ROLL_VARIANT.DEFAULT,
  presentedMeta,
  width,
  tintOverlay,
  fileTypeVisible,
  title,
}) => {
  const s3URL = detectArchivedFileKeyPath(page.documentVersionORM.model, page.page)
  const variantStyles = thumbnailVariantStyles[variant];
  const variantSettings = thumbnailVariantSettings[variant];
  const thumbnailPlacementProps = thumbnailNumberPositionProps[numberPosition ?? variantSettings.numberPosition];
  const titleWidth = (width ?? variantSettings.thumbnailWidth);

  if (isContentUnavailable) {
    return (
      <DNABox style={[variantStyles.container, { margin: 3 }]} />
    );
  }

  return (
    <Pressable
      onPress={onSelect}
      style={variantStyles.pressableContainer}
    >
      {/* CONTAINER  */}
      <DNABox testID="slide-thumbnail-container" {...thumbnailPlacementProps} spacing="xs">
        <Stack anchor="center">
          <Stack.Layer>
            {/* OUTER THUMBNAIL CONTAINER */}
            <DNABox
              key={page.id}
              style={[variantStyles.container, isSelected && variantStyles.selected]}
              alignX="center"
              alignY="center"
            >
              {/* INNER THUMBNAIL CONTAINER */}
              <DNABox
                alignX="center"
                alignY="center"
                style={[
                  variantStyles.innerContainer,
                  !isSelected
                    ? variantStyles.unselected
                    : variantStyles.selected,
                ]}
              >
                {/* THUMBNAIL */}
                <DNAThumbnail
                  style={variantStyles.thumbnail as ImageStyle}
                  s3URL={s3URL}
                  presentedMeta={presentedMeta}
                  height={height ?? variantSettings.thumbnailHeight}
                  width={width ?? variantSettings.thumbnailWidth}
                />
              </DNABox>
            </DNABox>
          </Stack.Layer>
          <Stack.Layer style={S.full}>
            {/* TINT OVERLAY */}
            <Iffy is={tintOverlay && !presentedMeta?.presented}>
              <DNABox fill style={[S.tintOverlay]}/>
            </Iffy>
          </Stack.Layer>
        </Stack>
        <DNABox spacing="between">

          {/* PAGE NUMBER */}
          <DNAText
            numberOfLines={1}
            style={[
              { ...variantStyles.pageText, maxWidth: titleWidth },
              isSelected && variantStyles.pageTextSelected,
            ]}
          >
            {`${page.presentationPageNumber}. ${title}` }
          </DNAText>

          {/* Badge */}
          {fileTypeVisible &&
            <DNAChip
              appearance="tag"
              status="basic"
              size="sm"
            >{page.documentVersionORM.model.type}
            </DNAChip>
            }

        </DNABox>
      </DNABox>
    </Pressable>
  );
};

DNASlideRollThumbnail.displayName = 'DNASlideRollThumbnail';

export default DNASlideRollThumbnail;
