import React from 'react';
import Modal from 'react-native-modal'
import { StyleSheet } from 'react-native'

import {
  DNABox,
  DNAButton,
  DNAText,
  DNADivider,
} from '@alucio/lux-ui';
import DNAThumbnail from '../DNA/Thumbnail/DNAThumbnail';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

const styles = StyleSheet.create({
  container: {
    width: 1000,
    height: 687,
    borderRadius: 4,
    backgroundColor: colors['color-text-white'],
  },
  header: {
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  footer: {
    paddingVertical: 12,
    paddingHorizontal: 24,

  },
})

export interface SlidePreviewModalProps {
  closeModal?: () => void;
  presentPage: () => void;
  archivedKey: string;
  isVisible: boolean;
  title?: string;
}

const SlidePreviewModal = (props: SlidePreviewModalProps) => {
  const { closeModal, presentPage, archivedKey } = props;

  const onPresent = () => {
    presentPage();
    closeModal?.();
  }

  return (
    <Modal
      isVisible={props.isVisible}
      hasBackdrop={false}
    >
      <DNABox fill alignX="center" alignY="center">
        <DNABox spacing="between" appearance="col" style={styles.container}>
          <DNABox appearance="col">
            <DNABox fill appearance="row" spacing="between" style={styles.header} childFill={2}>
              <DNAText h5>Preview</DNAText>
              <DNAButton
                appearance="ghost"
                status="tertiary"
                iconLeft="close"
                onPress={closeModal}
              />
            </DNABox>
            <DNADivider />
          </DNABox>
          <DNABox appearance="col" spacing="sm">
            <DNABox alignX="start">
              <DNAText style={{ marginLeft: 30 }}> {props.title || ''}</DNAText>
            </DNABox>
            <DNABox alignX="center" fill>
              <DNABox style={{ borderWidth: 1, borderColor: colors['color-gray-80'] }}>
                <DNAThumbnail
                  s3URL={archivedKey}
                  useLoadingIndicator={true}
                  height={527}
                  width={936}
                />
              </DNABox>
            </DNABox>
          </DNABox>
          <DNABox appearance="col">
            <DNADivider />
            <DNABox appearance="row" spacing="sm" alignX="end" style={styles.footer}>
              <DNAButton
                iconLeft="play"
                onPress={onPresent}
                status="primary"
                size="sm"
              >
                Present
              </DNAButton>
            </DNABox>
          </DNABox>
        </DNABox>
      </DNABox>
    </Modal>
  )
};

export default SlidePreviewModal
