import React, { useEffect } from 'react'
import { Pressable, StyleSheet } from 'react-native'
import {
  Iffy,
  DNABox,
  DNAButton,
  DNAText,
  DNAIcon,
  useOrientationChange,
} from '@alucio/lux-ui'
import { Switch, Route } from 'src/router'

import DNAFlatList from 'src/components/DNA/FlatList/DNAFlatList'
import { FolderORM, FolderItemORM, isDocumentVersionORM, DocumentORM } from 'src/types/types'
import useDisplayMode, { DisplayModes } from 'src/components/DNA/hooks/useDisplayMode'
import useFolderNavURL, { useFolderNavURLInit } from 'src/components/DNA/Folder/Nav/useFolderNavURL'
import { useDNAFolderActions } from 'src/components/DNA/Folder/DNAFolder.actions'
import { useDNADocumentVersionActions } from 'src/components/DNA/Document/DNADocumentVersion.actions'
import { withDNAFolderNav, useDNAFolderNav } from 'src/components/DNA/Folder/Nav/DNAFolderNav'
import DNADefaultHeader from 'src/components/DNA/FlatList/Headers/DNADefaultHeaders'
import DNAMyFoldersTablet from './DNAMyFoldersTablet';
import { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import DNAGrid from 'src/components/DNA/Grid/DNAGrid'
import { useAppSettings } from 'src/state/context/AppSettings'
import { ROUTES } from 'src/router/routes'
import useScreenNav from 'src/components/DNA/hooks/useScreenNav'
import truncate from 'lodash/truncate'
import DNARouteTabs from 'src/components/DNA/Navigation/DNARouteTabs/DNARouteTabs'
import DocumentsUpdates from './DocumentsUpdates'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage'
import { useDispatch } from 'src/state/redux'
import { infoPanelActions } from 'src/state/redux/slice/infoPanel'
import { sharedFolderActions, useFetchSharedFolders } from 'src/state/redux/slice/sharedFolder'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import MyUploads from './MyUploads'
import ArchivedFolders from './ArchivedFolders'
import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions'
import { getRootFolderORM } from 'src/state/redux/selector/folder'
import { isCustomDeckORM, isDocumentORM, isFolderORM } from 'src/types/typeguards'
import FolderActions from './FolderActions'

export const S = StyleSheet.create({
  chevronIcon: { marginTop: 2 },
  tab: {
    paddingHorizontal: 12,
    paddingVertical: 20,
  },
  breadcrumb: {
    fontSize: 14,
    fontWeight: '700',
  },
  gridViewContainer: {
    paddingTop: 16,
    paddingHorizontal: 8,
    borderWidth: 1,
    // TODO: This color is neither in the new theme system and in the legacy one, but it isused in the mockups
    borderColor: '#EBEBEB',
    paddingBottom: 16,
    marginBottom: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    backgroundColor: 'white',
  },
  tabContainer: {
    backgroundColor: 'white',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: colors['color-gray-100'],
  },
  header: {
    backgroundColor: 'white',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 0,
    borderColor: colors['color-gray-100'],
    padding: 16,
  },
})

export const useDNAMyFoldersSharedResources = () => {
  const dispatch = useDispatch()
  const { go } = useScreenNav()
  const { displayMode, displayModeIconName, toggleDisplayMode } = useDisplayMode()
  const folderActions = useDNAFolderActions()
  const documentVersionActions = useDNADocumentVersionActions()
  const orientation = useOrientationChange()
  const route = useCurrentPage({ exact: false })
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const isArchivedFoldersRoute = !!route?.PATH.includes('archived_folders')
  const folderNav = useDNAFolderNav()
  const documentActions = useDNADocumentActions();

  let folderNavURLPath = ROUTES.FOLDERS.PATH

  if (isPublisherRoute && folderNav.displaySharedFolders) {
    folderNavURLPath = ROUTES.PUBLISHER_SHARED_FOLDERS.PATH
  } else if (isPublisherRoute && isArchivedFoldersRoute) {
    folderNavURLPath = ROUTES.PUBLISHER_ARCHIVED_FOLDERS.PATH
  } else if (isPublisherRoute) {
    folderNavURLPath = ROUTES.PUBLISHER_FOLDERS.PATH
  } else if (isArchivedFoldersRoute) {
    folderNavURLPath = ROUTES.ARCHIVED_FOLDERS.PATH
  } else if (folderNav.displaySharedFolders) {
    folderNavURLPath = ROUTES.SHARED_FOLDERS.PATH
  }

  const folderNavURL = useFolderNavURL(folderNavURLPath)

  const activeItems = isPublisherRoute
    ? folderNav.currentItems
    : folderNav.currentItems.filter(
      item => (
        isDocumentVersionORM(item.relations.itemORM)
          ? item.relations.itemORM.model.status !== 'NOT_PUBLISHED'
          : true
      ),
    )

  const { deviceMode } = useAppSettings()
  const isTablet = deviceMode === 'tablet'

  const onPress = (selectedItemORM: FolderItemORM | FolderORM | DocumentORM) => {
    /** Folders */
    if (isFolderORM(selectedItemORM)) {
      const {
        model:{ id:selectedFolderID },
        relations:{ parentFolderORM },
        meta:{ hasExternalDependencies },
      } = selectedItemORM
      const { id:rootFolderID } = {
        ...(parentFolderORM
          ? getRootFolderORM(parentFolderORM)
          : selectedItemORM)?.model,
      }

      rootFolderID && hasExternalDependencies &&
        dispatch(sharedFolderActions.getFolderExternalDependencies({
          folderId: selectedFolderID,
          parentFolderId: rootFolderID,
        }))

      folderNavURL.pushFolder(selectedItemORM)

    /** Documents */
    } else if (isDocumentORM(selectedItemORM)) {
      const { conversionStatus } = selectedItemORM.relations.version.latestUsableDocumentVersionORM.model
      conversionStatus === 'PROCESSED' && documentActions.preview(selectedItemORM)();

    /** FolderItems (DocumentVersionORM | FolderORM | CustomDeckORM) */
    } else {
      const { itemORM:relatedItemORM } = selectedItemORM.relations
      if (isDocumentVersionORM(relatedItemORM)) {
        documentVersionActions.preview(relatedItemORM, selectedItemORM)()
      } else if (isCustomDeckORM(relatedItemORM)) {
        const { customDeckGroups } = relatedItemORM.meta
        for (const { model:{ visible }, pages } of customDeckGroups) {
          if (visible && pages[0]) {
            const { documentVersionORM } = pages[0]
            documentVersionActions.preview(documentVersionORM, selectedItemORM)()
            break
          }
        }
      }
    }
  }
  const createOnPress = () => {
    folderNav.isCurrentlyNested
      ? folderActions.createSubFolder(folderNav.folderStack.slice(-1)[0])()
      : folderActions.createFolder((undefined as any) as FolderORM)()
  }

  const returnToRoot = () => {
    go(ROUTES.FOLDERS)
  }

  /** TODO: This should be updated to a reusable component that generates breadcrumbs which can be utilized in both web and PWA.
   * Currently, it only serves the "tablet" variant and is using some outdated truncation techniques
   */
  const getBreadCrumb = (idx, truncateText = false, hideText = false) => {
    const notCurrentFolder = folderNav.folderStack.length - 1 !== idx
    const folderORM = folderNav.folderStack[idx]
    if (!folderORM) return null
    const { name } = { ...folderORM.model }
    return (
      <DNABox appearance="row" alignY="center">
        {notCurrentFolder &&
          <DNAButton
            appearance="ghostLink"
            status="tertiary"
            size="lg"
            iconLeft={'chevron-left' }
            iconStyle={S.chevronIcon}
            onPress={() => folderNavURL.popToFolder(folderORM)}
          />
          }
        <Iffy is={!hideText}>
          <DNABox>
            {truncateText
              ? isTablet
                ? <DNAText>
                  {truncate(name, { length: orientation === 'LANDSCAPE' ? 50 : 30 })}
                </DNAText>
                : null
              : <DNAText>
                {name}
              </DNAText>
            }
          </DNABox>
        </Iffy>
      </DNABox>)
  }

  const launchSlideBuilder = () => {
    /** This is taken from the createOnPress pattern above but is a
     * bit of a hack. Need to revisit this and properly implement */
    const currentFolder = folderNav.folderStack.slice(-1)[0]
    folderActions.createCustomPresentation(currentFolder)()
  }
  const { isOnline } = useAppSettings()
  let emptyVariantType;
  if (folderNav.isFolderListLoaded) {
    if (isArchivedFoldersRoute) {
      emptyVariantType = folderNav.isCurrentlyNested ? EmptyVariant.FolderGridEmpty : EmptyVariant.ArchivedFolderEmpty
    } else {
      emptyVariantType = (folderNav.isCurrentlyNested ? EmptyVariant.FolderGridEmpty : EmptyVariant.FolderListCreate)
    }
  } else {
    emptyVariantType = (isOnline ? EmptyVariant.FolderListLoading : EmptyVariant.FolderListSharedOffline)
  }

  /** Inform the global info panel that we've navigated into a folder */
  useEffect(() => {
    const currentItem = folderNav.folderStack.slice(-1)[0];
    dispatch(infoPanelActions.setActiveItem(currentItem))
  }, [folderNav])

  return {
    folderActions,
    displayMode,
    displayModeIconName,
    toggleDisplayMode,
    folderNav,
    folderNavURL,
    activeItems,
    onPress,
    createOnPress,
    returnToRoot,
    getBreadCrumb,
    emptyVariantType,
    launchSlideBuilder,
  }
}

export const DNAMyFoldersTabsDesktop: React.FC = () => {
  const route = useCurrentPage({ exact: false })
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const LDClient = useLDClient();
  const enableSharedFolders = LDClient?.variation('enable-shared-folders', true);
  const enableMyUploads = LDClient?.variation('enable-viewer-file-upload', false);

  return (
    <DNABox alignX="center">
      <DNABox fill alignX={isPublisherRoute ? 'stretch' : 'center'}>
        <DNABox
          fill
          style={{
            maxWidth: isPublisherRoute ? undefined : 1440,
            paddingHorizontal: isPublisherRoute ? 32 : 48,
            paddingVertical: 20,
            backgroundColor: isPublisherRoute ? colors['color-text-white'] : undefined,
          }}
        >
          <DNABox appearance="col" fill spacing="md">
            <DNABox alignY="center" style={{ margin: 0, marginHorizontal: 12 }}>
              <DNAText
                testID="page-title"
                status={isPublisherRoute ? 'subtle' : 'secondary'}
                h5
              >
                My Folders
              </DNAText>
            </DNABox>
            <DNARouteTabs.Router>
              <Iffy is={isPublisherRoute}>
                <DNARouteTabs.Tabs withDivider style={S.tabContainer}>
                  <DNARouteTabs.Tab title="My Folders" path="/publisher_folders" style={S.tab} hasNavigationPaths>
                    <DNAMyFoldersDesktop />
                  </DNARouteTabs.Tab>
                  <DNARouteTabs.Tab
                    title="Shared With Me"
                    path="/publisher_shared_folders"
                    style={[S.tab, !enableSharedFolders && { display: 'none' }]}
                    hasNavigationPaths
                  >
                    <DNAMyFoldersDesktop />
                  </DNARouteTabs.Tab>
                  <DNARouteTabs.Tab title="My Updates" path="/publisher_folders/updates" style={S.tab}>
                    <DocumentsUpdates />
                  </DNARouteTabs.Tab>
                  <DNARouteTabs.Tab
                    title="Archived Folders"
                    path="/publisher_archived_folders"
                    style={S.tab}
                    hasNavigationPaths
                  >
                    <ArchivedFolders />
                  </DNARouteTabs.Tab>
                  <DNARouteTabs.Unmatched rewrite="/publisher_folders" />
                </DNARouteTabs.Tabs>
              </Iffy>
              <Iffy is={!isPublisherRoute}>
                <DNARouteTabs.Tabs withDivider style={S.tabContainer}>
                  <DNARouteTabs.Tab title="My Folders" path="/folders" style={S.tab} hasNavigationPaths>
                    <DNAMyFoldersDesktop />
                  </DNARouteTabs.Tab>
                  <DNARouteTabs.Tab
                    title="Shared With Me"
                    path="/shared_folders"
                    style={[S.tab, !enableSharedFolders && { display: 'none' }]}
                    hasNavigationPaths
                  >
                    <DNAMyFoldersDesktop />
                  </DNARouteTabs.Tab>
                  <DNARouteTabs.Tab
                    title="My Uploads"
                    path="/folders/uploads"
                    style={[S.tab, !enableMyUploads && { display: 'none' }]}
                  >
                    <MyUploads />
                  </DNARouteTabs.Tab>
                  <DNARouteTabs.Tab title="My Updates" path="/folders/updates" style={S.tab}>
                    <DocumentsUpdates />
                  </DNARouteTabs.Tab>
                  <DNARouteTabs.Tab title="Archived Folders" path="/archived_folders" style={S.tab} hasNavigationPaths>
                    <DNAMyFoldersDesktop />
                  </DNARouteTabs.Tab>
                  <DNARouteTabs.Unmatched rewrite="/folders" />
                </DNARouteTabs.Tabs>
              </Iffy>
            </DNARouteTabs.Router>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

// [TODO] This should probably contain a switch and DNAFolderContent should declared at the root route level
// (So we can reuse the header bar)

export const DNAMyFoldersDesktop: React.FC = () => {
  const {
    folderNav,
    folderNavURL,
    displayModeIconName,
    toggleDisplayMode,
    displayMode,
    activeItems,
    onPress,
    emptyVariantType,
  } = useDNAMyFoldersSharedResources()
  const { setDisplaySharedFolders } = folderNav
  const route = useCurrentPage({ exact: false })
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const sharedFoldersTab = !!route?.PATH.includes('shared_folders')
  const archivedFoldersTab = !!route?.PATH.includes('archived_folders')

  useEffect(() => {
    if (sharedFoldersTab) setDisplaySharedFolders(sharedFoldersTab)
    else setDisplaySharedFolders(false);
  }, [sharedFoldersTab, archivedFoldersTab]);

  if (archivedFoldersTab) return <ArchivedFolders />

  return (
    <DNABox
      fill
      appearance="col"
    >
      {/* Breadcrumb Nav */}
      <DNABox
        alignY="center"
        spacing="between"
        style={S.header}
        childFill={0}
        childStyle={[1, { marginLeft: 3 }]}
      >
        <DNABox alignY="center" fill>
          <DNAButton appearance="ghostLink" status="gray" padding="none" onPress={() => folderNavURL.popToFolder()}>
            <DNAText testID="page-title" status="secondary" style={S.breadcrumb}>
              {sharedFoldersTab ? 'Shared With Me' : 'My Folders'}
            </DNAText>
          </DNAButton>
          {folderNav.folderStack.map(folderORM => (
            <DNABox shrink key={folderORM.model.id}>
              <DNAIcon.Styled
                name="chevron-right"
                appearance="ghostLink"
                status="gray"
                style={S.chevronIcon}
              />
              <Pressable onPress={() => folderNavURL.popToFolder(folderORM)} style={{ flexShrink: 1 }}>
                <DNAText status="secondary" style={S.breadcrumb} ellipsizeMode="tail" numberOfLines={1}>
                  {folderORM.model.name}
                </DNAText>
              </Pressable>
            </DNABox>
          ))}
        </DNABox>

        {/* Right Controls */}

        <DNABox spacing="md">
          <FolderActions />
          {/* list/grid button */}
          <DNAButton
            size="md"
            appearance="outline"
            padding="sm"
            status="tertiary"
            iconLeft={displayModeIconName}
            onPress={toggleDisplayMode}
          />
        </DNABox>
      </DNABox>

      {/* Content */}
      <Iffy is={displayMode === DisplayModes.grid}>
        <DNABox style={S.gridViewContainer}>
          <DNAGrid
            items={activeItems}
            onPress={onPress}
            emptyVariant={emptyVariantType}
          />
        </DNABox>
      </Iffy>
      <Iffy is={displayMode === DisplayModes.list}>
        <DNAFlatList
          items={activeItems}
          variant="default"
          onPress={onPress}
          emptyVariant={emptyVariantType}
        >
          <DNADefaultHeader variant={isPublisherRoute ? 'publisher' : 'default'} />
        </DNAFlatList>
      </Iffy>
    </DNABox>
  )
}

const DNAMyFoldersSwitcher: React.FC = () => {
  const { deviceMode } = useAppSettings()

  return deviceMode === 'desktop' ? <DNAMyFoldersTabsDesktop /> : <DNAMyFoldersTablet />
}

// [TODO]: We can potentially just render <Routes> within the above component
// - Try using useRouteMatch and then do a conditional route render if we don't
//   exceed the max depth
const NestedRoutes = () => {
  useFolderNavURLInit()

  const ldlClient = useLDClient();
  const { isOnline } = useAppSettings()
  const enableSharedFolders = ldlClient?.variation('enable-shared-folders', false);
  const { fetchSharedFolders } = useFetchSharedFolders();

  useEffect(() => {
    if (enableSharedFolders && isOnline) {
      fetchSharedFolders();
    }
  }, [isOnline])

  return (
    <Switch>
      {/* Viewer */}
      <Route exact path="/folders" component={DNAMyFoldersSwitcher} />
      <Route exact path="/folders/:id" component={DNAMyFoldersSwitcher} />
      <Route exact path="/folders/:id/:idd" component={DNAMyFoldersSwitcher} />
      <Route exact path="/shared_folders" component={DNAMyFoldersSwitcher} />
      <Route exact path="/shared_folders/:id" component={DNAMyFoldersSwitcher} />
      <Route exact path="/shared_folders/:id/:idd" component={DNAMyFoldersSwitcher} />
      <Route exact path="/archived_folders" component={DNAMyFoldersSwitcher} />
      <Route exact path="/archived_folders/:id" component={DNAMyFoldersSwitcher} />
      <Route exact path="/archived_folders/:id/:idd" component={DNAMyFoldersSwitcher} />
      {/* publisher */}
      <Route exact path="/publisher_folders" component={DNAMyFoldersSwitcher} />
      <Route exact path="/publisher_folders/:id" component={DNAMyFoldersSwitcher} />
      <Route exact path="/publisher_folders/:id/:idd" component={DNAMyFoldersSwitcher} />
      <Route exact path="/publisher_shared_folders" component={DNAMyFoldersSwitcher} />
      <Route exact path="/publisher_shared_folders/:id" component={DNAMyFoldersSwitcher} />
      <Route exact path="/publisher_shared_folders/:id/:idd" component={DNAMyFoldersSwitcher} />
      <Route exact path="/publisher_archived_folders" component={DNAMyFoldersSwitcher} />
      <Route exact path="/publisher_archived_folders/:id" component={DNAMyFoldersSwitcher} />
      <Route exact path="/publisher_archived_folders/:id/:idd" component={DNAMyFoldersSwitcher} />
    </Switch>
  )
}

export default withDNAFolderNav(
  NestedRoutes,
  // TODO: Suggest updating this to filter shared dynamically based on selected tab
)
