import React from 'react'

import { DocumentORM } from 'src/types/types'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'
import {
  DNABox, DNACard, DNAText,
  useTheme, Iffy, DNAChip, luxColors,
} from '@alucio/lux-ui'
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import { DocumentAccessLevel, FileType } from '@alucio/aws-beacon-amplify/src/models'
import { format } from 'date-fns';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { StyleSheet } from 'react-native';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList'
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import { useDocumentSearchV2Context } from 'src/hooks/useDocumentSearchV2'
import { renderTextWithHighlight } from 'src/utils/searchHelpers'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import { DNATextProps } from '@alucio/lux-ui/src/components/type/DNAText/DNAText'

export type DNADocumentMeetingRowProps = {
  onPin?: () => void,
  isDesktop?: boolean;
  isOnline?: boolean;
}

const styles = StyleSheet.create({
  containerStyle: {
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: 4,
    marginVertical: 4,
  },
})

const DNADocumentMeetingRow: DNARowsComponent<
  DocumentORM,
  DNADocumentMeetingRowProps
> = ({
  ORM: documentORM,
  onPress,
  style,
  isOnline,
  isSearch,
}) => {
  const theme = useTheme()
  const isPersonalDocument = documentORM.model.accessLevel === DocumentAccessLevel.USER;
  const { latestUsableDocumentVersionORM } = documentORM.relations.version
  const { meetingORM } = useMeetingsState();
  const isVirtualMeeting = meetingORM?.model.type === 'VIRTUAL'
  const isWebDoc = documentORM.model.type === FileType.WEB
  const isHTMLDoc = documentORM.model.type === FileType.HTML
  const unavailableContent =
    (!isOnline && !latestUsableDocumentVersionORM.meta.assets.isContentCached) ||
    ((isWebDoc) && (isVirtualMeeting || !isOnline)) ||
    (isHTMLDoc && isVirtualMeeting)

  const isDisabledHTMLorWebDocument =
    (isWebDoc && isVirtualMeeting) ||
    (isHTMLDoc && isVirtualMeeting)

  const isInteractive = latestUsableDocumentVersionORM.meta.permissions.MSLPresent && !unavailableContent
  const title = documentORM.relations.version.latestPublishedDocumentVersionORM?.model.title

  const { documentIdSnippetMap, submitFeedback } = useDocumentSearchV2Context();

  const snippet = documentIdSnippetMap.get(documentORM.model.id);

  const showSnippet = snippet?.documentExcerpt && isSearch;

  const titleProps: DNATextProps = {
    b1: true,
    testID: 'meeting-document-gridlist-name',
    numberOfLines: 1,
    style: { color: colors['color-text-basic'] },
  }

  const snippetProps: DNATextProps = {
    status: 'flatAlt',
    numberOfLines: 3,
    testID: 'drop-down-results-snippet',
  }

  return (
    <DNACard
      appearance="meetings"
      interactive={isInteractive ? 'pressable' : undefined}
      onPress={() => {
        if (isSearch) {
          submitFeedback(documentORM.model.id, documentORM.relations.tenant.kendraIndexId)
        }
        onPress?.(documentORM)
      }}
      testID="meeting-document-card-container"
      style={[
        {
          paddingHorizontal: theme['row-padding-horizontal'],
          paddingVertical: theme['row-padding-vertical'],
        },
        style,
        styles.containerStyle,
        !isInteractive && { opacity: 0.4 },
      ]}
    >
      <DNAPopover disablePopover={!isDisabledHTMLorWebDocument} placement="top-start">
        <DNAPopover.Anchor>
          <DNABox alignY="center" spacing="between" childFill={0}>
            <DNABox alignY="center" spacing="lg" childFill={1}>
              <DNABox appearance="row" style={{ borderWidth: 1, borderColor: theme['color-gray-80'] }}>
                <DNADocumentThumbnail
                  documentVersionORM={
                documentORM.relations.version.latestPublishedDocumentVersionORM ||
                documentORM.relations.version.latestDocumentVersionORM
              }
                  unavailableContent={unavailableContent}
                  width={isSearch ? 160 : 76}
                  height={isSearch ? 87 : 44}
                />
              </DNABox>
              {/* Title */}
              <DNABox
                spacing="sm"
                appearance="col"
                childFill
                style={{ paddingRight: 16 }}
              >
                {snippet?.title
                  ? renderTextWithHighlight(snippet?.title, titleProps)
                  : <DNAText {...titleProps}>
                    {documentORM.relations.version.latestUsableDocumentVersionORM.model.title}</DNAText>}
                {showSnippet && renderTextWithHighlight(snippet.documentExcerpt, snippetProps)}
                <DNABox alignX="start" alignY="start">
                  {!isPersonalDocument &&
                    <DNABox alignY="center" spacing="sm" fill>
                      <CustomFieldBadgeList documentVersionORM={latestUsableDocumentVersionORM} />
                      <DNAChip appearance="tag">
                        { documentORM.model.type }
                      </DNAChip>
                      <Iffy is={unavailableContent}>
                        <DNAChip
                          appearance="tag"
                          style={{ backgroundColor: luxColors.basicBlack.primary }}
                          testID={`content-not-available-${title}`}
                        >
                          CONTENT NOT AVAILABLE
                        </DNAChip>
                      </Iffy>
                    </DNABox>}
                </DNABox>
              </DNABox>
            </DNABox>
            {/* Actions */}
            <DNABox spacing="sm" alignY="center">
              <Iffy is={isPersonalDocument}>
                <DNAText style={{ color: colors['color-text-secondary'], marginRight: 57 }}>
                  {format(new Date(documentORM.model.createdAt), 'MMM dd, yyyy')}
                </DNAText>
              </Iffy>
            </DNABox>
          </DNABox>
        </DNAPopover.Anchor>
        <DNAPopover.Content>
          <DNAText
            testID="web-html-popup"
            status="basic"
          >WEB and HTML file types are not presentable in virtual meetings.</DNAText>
        </DNAPopover.Content>
      </DNAPopover>
    </DNACard>
  )
}

export default DNADocumentMeetingRow
