import React from 'react'

import { DocumentORM } from 'src/types/types'
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types'
import { CustomLabelDisplay } from '../default/CustomLabelDisplay'
import { DNABox, DNACard, DNAText, useTheme } from '@alucio/lux-ui'
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import { DocumentAccessLevel } from '@alucio/aws-beacon-amplify/src/models';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList'
import { useDocumentSearchV2Context } from 'src/hooks/useDocumentSearchV2'
import { renderTextWithHighlight } from 'src/utils/searchHelpers'
import { DNATextProps } from '@alucio/lux-ui/src/components/type/DNAText/DNAText'

const DNADocumentCustomDeckRow: DNARowsComponent<
  DocumentORM
> = (props) => {
  const {
    ORM: documentORM,
    onPress,
    style,
    isOnline,
    isSearch,
  } = props

  const theme = useTheme()
  const isPersonalFile = documentORM.model.accessLevel === DocumentAccessLevel.USER;

  const unavailableContent = (
    !isOnline &&
    !documentORM.relations.version
      .latestPublishedDocumentVersionORM
      ?.meta.assets.isContentCached
  )

  const processed = documentORM
    .relations.version.latestPublishedDocumentVersionORM?.model.conversionStatus === 'PROCESSED';

  const { documentIdSnippetMap, submitFeedback } = useDocumentSearchV2Context();
  const snippet = documentIdSnippetMap.get(documentORM.model.id);

  const titleProps: DNATextProps = {
    b1: true,
    testID: 'document-gridlist-name',
    numberOfLines: 1,
  }

  const snippetProps: DNATextProps = {
    status: 'flatAlt',
    numberOfLines: 3,
    testID: 'drop-down-results-snippet',
  }

  const showSnippet = snippet?.documentExcerpt && isSearch;
  return (
    <DNACard
      appearance="flat"
      interactive="pressable"
      onPress={() => {
        if (isSearch) {
          submitFeedback(documentORM.model.id, documentORM.relations.tenant.kendraIndexId)
        }
        processed && onPress?.(documentORM) }
      }
      style={[
        {
          paddingHorizontal: theme['row-padding-horizontal'],
          paddingVertical: theme['row-padding-vertical'],
        },
        style,
      ]}
    >
      <DNABox alignY="center" spacing="between" childFill={0}>
        <DNABox alignY="center" spacing="lg" childFill={1}>
          <DNADocumentThumbnail
            unavailableContent={unavailableContent}
            documentVersionORM={
              documentORM.relations.version.latestPublishedDocumentVersionORM ||
              documentORM.relations.version.latestDocumentVersionORM
            }
            width={isSearch ? 160 : 76}
            height={isSearch ? 87 : 44}
            showProcessing
            // Assessing implementation in #BEAC-2155
            // onPress={() => { console.log('pressed custom deck row') }}
          />
          {/* Title */}
          <DNABox
            spacing="sm"
            appearance="col"
            childFill
            style={{ paddingRight: 16 }}
          >
            {snippet?.title
              ? renderTextWithHighlight(snippet?.title, titleProps)
              : <DNAText {...titleProps} >
                {documentORM.relations.version.latestUsableDocumentVersionORM.model.title}</DNAText>}
            {showSnippet && renderTextWithHighlight(snippet.documentExcerpt, snippetProps)}
            {
              !isPersonalFile &&
                <DNABox alignY="center" spacing="sm" fill childFill={1}>
                  <CustomFieldBadgeList
                    documentVersionORM={documentORM.relations.version.latestPublishedDocumentVersionORM}
                  />
                  <CustomLabelDisplay documentORM={documentORM} />
                </DNABox>
            }
          </DNABox>
        </DNABox>
      </DNABox>
    </DNACard>
  )
}

export default DNADocumentCustomDeckRow
