import { FormValuesType } from 'src/components/CustomFields/ComposableForm';
import { ObjectWithId } from 'src/components/CustomFields/ComposableFormUtilities';
import { SubmitMeetingProps } from './saveMeetingHelper';
import { CrmIntegrationType } from '@alucio/aws-beacon-amplify/src/models';
import { MeetingORM } from 'src/types/orms';
import { veevaPreProcessFormAttendees } from 'src/classes/CRM/Translators/VeevaFormTranslator';
import { isArrayOfObjectsWithIds } from 'src/types/typeguards';
import { Logger } from '@aws-amplify/core';
const logger = new Logger('Save meeting utilities', 'DEBUG')

interface MeetingPreProcessorResponse {
  separatedCustomFields: SeparatedFields,
  formValuesAttendees: FormValuesType[],
  startTime: string,
  endTime: string,
}

const ATTENDEES_PREPROCESSOR = {
  [CrmIntegrationType.VEEVA]: veevaPreProcessFormAttendees,
};

interface MeetingPreProcessorProps extends SubmitMeetingProps {
  crmIntegrationType: CrmIntegrationType | 'VEEVA' | 'SALESFORCE' | undefined
  hasCRMConfig: boolean,
}

export interface SeparatedFields {
  beaconInternalValues: FormValuesType,
  customFieldValues: FormValuesType,
  crmValues: FormValuesType,
}

// THIS FUNCTION RECEIVES FORM VALUES AND THE MEETING AND PERFORMS
// PRESAVE ACTIONS TO VARIABLES FOR LATER USE
export function getPreprocessedMeetingForm(
  submitMeetingPayload: MeetingPreProcessorProps): MeetingPreProcessorResponse {
  const { formValues, hasCRMConfig, crmIntegrationType, meetingORM } = submitMeetingPayload;
  const separatedCustomFields = separateCustomFields(
    formValues,
    crmIntegrationType,
    hasCRMConfig,
    meetingORM);

  const formValuesAttendees: FormValuesType[] = [
    ...separatedCustomFields.beaconInternalValues.primaryAttendee,
    ...separatedCustomFields.beaconInternalValues.additionalAttendees] as FormValuesType[];

  const startTime = separatedCustomFields.beaconInternalValues.startTime as string;
  const endTime = separatedCustomFields.beaconInternalValues.endTime as string;
  const response = {
    separatedCustomFields,
    formValuesAttendees,
    endTime,
    startTime,
  };

  logger.debug('Preprocessor response.', response);
  return response;
}

// ** RECEIVES THE FIELD/VALUES FROM THE FORM AND IDENTIFIES THE BEACONS, CUSTOM FIELDS AND CRM FIELDS ** //
function separateCustomFields(
  formValues: FormValuesType,
  crmIntegrationType: CrmIntegrationType | 'VEEVA' | 'SALESFORCE' | undefined,
  hasCRMConfig: boolean,
  meetingORM: MeetingORM): SeparatedFields {
  const { startTime, endTime, title, additionalAttendees, primaryAttendee, ...rest } = formValues;
  // IF THERE'S A CRM CONFIG, THE NON-BEACON FIELDS WILL ALWAYS BE CRM FIELDS. OTHERWISE, CUSTOM FIELDS
  const crmValues: FormValuesType = hasCRMConfig ? rest : {};
  const customFieldValues: FormValuesType = hasCRMConfig ? {} : rest;
  const beaconInternalValues: FormValuesType = {
    startTime,
    endTime,
    title,
    additionalAttendees,
    primaryAttendee,
  };

  if (isArrayOfObjectsWithIds(additionalAttendees)) {
    beaconInternalValues.additionalAttendees = preProcessAttendees(
      meetingORM,
      additionalAttendees,
      crmValues,
      crmIntegrationType);
  }

  return {
    beaconInternalValues,
    customFieldValues,
    crmValues,
  }
}

// ADDS, TO THE FORM ATTENDEES, ADDITIONAL REQUIRED FIELD/VALUES FOR LATER USE
function preProcessAttendees(
  meetingORM: MeetingORM,
  attendees: ObjectWithId[],
  crmFormValues: FormValuesType,
  crmIntegrationType: CrmIntegrationType | 'VEEVA' | 'SALESFORCE' | undefined): ObjectWithId[] {
  const processor = ATTENDEES_PREPROCESSOR[crmIntegrationType || ''];

  if (processor) {
    return processor(crmFormValues, meetingORM, attendees);
  }

  return attendees;
}
