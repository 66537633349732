import React, { createContext, useState, useEffect, useReducer, useContext, useMemo } from 'react'
import { DNABox, DNASelect, DNAButton, DNAText } from '@alucio/lux-ui'
import {
  useAllDocumentsPaginated,
} from 'src/state/redux/selector/documentSearch/documentSearch'
import { DocumentORM } from 'src/types/types'
import { FilterAndSortOptions } from 'src/state/redux/selector/common'
import { isEqual } from 'lodash'

type PageState = {
  currentPage: number,
  totalPages: number,
}

type ChainPageAction = {
  type: 'increment' | 'decrement' | 'goToFirstPage' | 'goToLastPage' | 'jumpToPage',
  value: {
    nextPage?: number,
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  }
}

type PageAction = ChainPageAction | {
  type: 'setTotalPages',
  value?: number
}

type PaginationContextValue = PageState & {
  pageSize: number,
  setPageSize: React.Dispatch<React.SetStateAction<number>>,
  pageDispatch: React.Dispatch<PageAction>,
  pagedRecords: any[], // [TODO-73] - Proper ORM types here
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  totalRecords: number,
}

const MAX_PAGE_DISPLAY = 10
const PAGE_SIZE_OPTIONS = [25, 50, 100, 150]
export const DEFAULT_PAGE_SIZE = 25;

const PaginationContext = createContext<PaginationContextValue>({
  currentPage: 1,
  totalPages: 0,
  totalRecords: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  setPageSize: () => { },
  pageDispatch: () => { },
  pagedRecords: [],
  setCurrentPage: () => { },
})
PaginationContext.displayName = 'PaginationContext'

export const usePagination = () => useContext(PaginationContext)

// [TODO-73] - Try using Immer to preserve ref equality (instead of returning original state via if statements)
const pageReducer = (
  state: PageState,
  action: PageAction,
) => {
  switch (action.type) {
    case 'setTotalPages': {
      const newTotalPages = action?.value
      return (newTotalPages !== undefined) && (state.totalPages !== newTotalPages)
        ? { ...state, totalPages: newTotalPages }
        : state }
    case 'increment': {
      const newPage = (state.currentPage + 1)
      const inBound = newPage <= state.totalPages

      if (inBound) {
        action.value.setCurrentPage(newPage)
        return { ...state, currentPage: newPage }
      } else
      { return state; }
    }
    case 'decrement': {
      const newPage = state.currentPage - 1
      const inBound = state.currentPage > 1
      if (inBound) {
        action.value.setCurrentPage(newPage)
        return { ...state, currentPage: newPage }
      } else
      { return state }
    }
    case 'goToFirstPage': {
      const newPage = 1
      const inBound = state.currentPage !== 1
      if (inBound) {
        action.value.setCurrentPage(newPage)
        return { ...state, currentPage: newPage }
      } else
      { return state }
    }
    case 'goToLastPage': {
      const targetPage = state.totalPages
      const inBound = state.currentPage !== state.totalPages
      if (inBound) {
        action.value.setCurrentPage(targetPage)
        return { ...state, currentPage: targetPage }
      } else
      { return state }
    }
    case 'jumpToPage': {
      const targetPage = action.value?.nextPage
      const inBound = (
        targetPage !== undefined &&
        targetPage >= 1 && targetPage <= state.totalPages
      )
      if (inBound) {
        action.value.setCurrentPage(targetPage!)
        return { ...state, currentPage: targetPage! }
      } else
      { return state }
    }
    default: return state
  }
}

// [TODO-73]: Get enums working here
function Pagination<T extends DocumentORM>(props: {
  query?: FilterAndSortOptions<T>,
  children
}) {
  const { query, children } = props

  // [NOTE]
  //  - This is a performance workaround, we maintain a copy of current page in two places
  //    In `page` (which uses a reducer) and in a standalone `currentPage`
  //
  //  - This is because `useAllDocumentsPaginated` and `page`'s init value would normally
  //    depend on each other thus creating a circular reference. We could just break out the
  //    two values from state but we lose the clean reducer, and slight chance
  //    for needing to memoize individual State change functions.
  //
  //  - tl;dr - it's a slightly confusing performance workaround
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)
  const [prevQuery, setPrevQuery] = useState(query);

  const paginatedQuery = useMemo(() => {
    return {
      ...(query as FilterAndSortOptions<DocumentORM> ?? {}),
      paginate: {
        currentPage: currentPage,
        pageSize,
      },
    }
  }, [query, currentPage, pageSize])

  const { pagedRecords, totalRecords } = useAllDocumentsPaginated(undefined,
    // [TODO-73] - The generic above should work, check up how to this properly
    // It should also support other ORM types as well
    paginatedQuery,
  )

  const totalPages = Math.ceil(totalRecords / pageSize)
  const [page, pageDispatch] = useReducer(pageReducer, { currentPage, totalPages })

  useEffect(() => {
    if (page.totalPages !== totalPages)
    {
      pageDispatch({ type: 'setTotalPages', value: totalPages })
    }
  }, [totalRecords, pageSize])

  useEffect(() => {
    if (!isEqual(prevQuery, query)) {
      pageDispatch({ type: 'goToFirstPage', value: { setCurrentPage } })
    }
    setPrevQuery(query);
  }, [query])

  return (
    <PaginationContext.Provider
      value={{
        currentPage: page.currentPage,
        totalPages: page.totalPages,
        totalRecords: totalRecords,
        pageDispatch,
        pageSize,
        setPageSize,
        pagedRecords,
        setCurrentPage,
      }}
    >
      { children}
    </PaginationContext.Provider>
  )
}

const PaginationNav: React.FC = () => {
  const {
    totalPages,
    currentPage,
    pageDispatch,
    pageSize,
    setPageSize,
    setCurrentPage,
  } = usePagination()

  // [TODO] - These values (<= 5 and >= 4 are hardcoded to use MAX_PAGE_DISPLAY = 10)
  // We should calculate these instead
  const isCenterOfLeft = (currentPage <= 5)
  const isCenterOfRight = (currentPage >= (totalPages - 4))
  const pagesToRender = (totalPages < MAX_PAGE_DISPLAY)
    ? totalPages
    : MAX_PAGE_DISPLAY

  let startingIdx

  if (isCenterOfLeft)
  { startingIdx = 1 }
  else if (isCenterOfRight)
  { startingIdx = pagesToRender >= MAX_PAGE_DISPLAY
    ? (totalPages - MAX_PAGE_DISPLAY) + 1
    : 1 }
  else
  { startingIdx = (currentPage - 4) }

  return (
    <DNABox alignY="center" spacing="md">
      {/* SELECT */}
      <DNABox alignY="center" spacing="sm">
        <DNAText>Items per page</DNAText>
        <DNASelect
          size="small"
          selectedIndex={new DNASelect.Index(PAGE_SIZE_OPTIONS.indexOf(pageSize))}
          value={pageSize}
          onSelect={(v) => {
            if (Array.isArray(v)) return;
            setPageSize(PAGE_SIZE_OPTIONS[v.row])
            pageDispatch({ type: 'goToFirstPage', value: { setCurrentPage } })
          }}
        >
          {
            PAGE_SIZE_OPTIONS.map((x) => <DNASelect.Item key={x} title={x} />)
          }
        </DNASelect>
      </DNABox>
      {/* NAV */}
      <DNABox alignY="center" spacing="sm">
        {/* PREV BUTTONS */}
        <DNAButton
          status="tertiary"
          size="xs"
          iconLeft="page-first"
          onPress={() => pageDispatch({ type: 'goToFirstPage', value: { setCurrentPage } })}
          disabled={currentPage === 1}
        />
        <DNAButton
          status="tertiary"
          size="xs"
          iconLeft="chevron-left"
          onPress={() => pageDispatch({ type: 'decrement', value: { setCurrentPage } })}
          disabled={currentPage === 1}
        />
        {/* PAGE BUTTONS */}
        {
          Array.from(
            { length: pagesToRender },
            (_, i) => {
              const pageIdx = startingIdx + i
              const buttonStatus = pageIdx === currentPage
                ? 'primary'
                : 'tertiary'

              return (
                <DNAButton
                  key={pageIdx}
                  status={buttonStatus}
                  size="xs"
                  onPress={() => pageDispatch({
                    type: 'jumpToPage',
                    value: { nextPage: pageIdx, setCurrentPage },
                  })}
                >
                  {pageIdx}
                </DNAButton>
              )
            },
          )
        }
        {/* NEXT BUTTONS */}
        <DNAButton
          status="tertiary"
          size="xs"
          iconLeft="chevron-right"
          onPress={() => pageDispatch({ type: 'increment', value: { setCurrentPage } })}
          disabled={currentPage === totalPages}
        />
        <DNAButton
          status="tertiary"
          size="xs"
          iconLeft="page-last"
          onPress={() => pageDispatch({ type: 'goToLastPage', value: { setCurrentPage } })}
          disabled={currentPage === totalPages}
        />
      </DNABox>
    </DNABox>
  )
}

Pagination.Nav = PaginationNav

export default Pagination
