import {
  CustomFieldDefinition,
  CustomFieldUsage,
  LabelValues,
  PermissionType,
  ShareTargetType,
} from '@alucio/aws-beacon-amplify/src/models'
import { DNABox, DNAButton, DNAContextMenu, DNADivider, DNAModal, DNAText, Iffy, luxColors } from '@alucio/lux-ui'
import React, { useMemo } from 'react'
import { FlatList, StyleSheet } from 'react-native'
import { FolderORM } from 'src/types/orms'
import Folder from '../../Folder/DNAFolder'
import { DNAModalVariant } from '../DNAConnectedModal'
import { isEveryoneType, isFilterType, isUserType, ShareFilter, ShareFolderMode } from './DNAShareFolderModal'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import isEqual from 'lodash/isEqual'
import { useFeatureFlag } from 'src/hooks/useFeatureFlag/useFeatureFlag'
import { FeatureFlags } from 'src/types/featureFlags'
import { useIndexedTenantCustomFields } from 'src/state/redux/selector/tenant';

const styles = StyleSheet.create({
  icon: {
    height: 25,
    width: 25,
  },
  hoveredBackgroundColor: {
    backgroundColor: luxColors.hoveredBackground.primary,
  },
  menu: {
    maxHeight: 300,
  },
  titleText: {
    color: colors['color-gray-600'],
    fontSize: 14,
    lineHeight: 16,
  },
  searchBarStyle: {
    backgroundColor: colors['color-text-white'],
    // TODO: Color not available on the theming yet
    borderColor: luxColors.disabled.secondary,
    borderRadius: 4,
    width: 316,
    overflow: 'hidden',
  },
  searchBarWrapper: {
    marginTop: 20,
    flex: 1,
  },
  recordRow: {
    flex: 1,
    flexDirection: 'row',
    height: 52,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
  },
  noResults: {
    marginLeft: 4,
    marginBottom: 6,
    marginTop: 6,
  },
  resultsWrapperStyle: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  customFieldsText: {
    color: colors['color-gray-300'],
    fontSize: 12,
    lineHeight: 20,
  },
  folderMargins: {
    marginLeft: 24,
    marginRight: 12,
  },
})

interface PermissionsListProps {
  currentItems: ShareFilter[]
  onRemoveItem: (id: string) => void
  togglePermission: (id: string) => void
}

const PermissionsList: React.FC<PermissionsListProps> = React.memo((props) => {
  const { currentItems, onRemoveItem, togglePermission } = props
  const indexedCustomFields = useIndexedTenantCustomFields({
    usages: { internalUsages: [CustomFieldUsage.USER_FILTER] },
  });

  const items = useMemo(() => {
    const shareFilters: ShareFilter[] = []
    if (currentItems) {
      currentItems.forEach((item) => {
        if (isUserType(item.filter)) {
          shareFilters.push(item)
        }
      })
    }
    shareFilters.sort((a, b) => {
      if (isUserType(a.filter) && isUserType(b.filter)) {
        const aName  = a.filter.givenName + ' ' + a.filter.familyName;
        const bName  = b.filter.givenName + ' ' + b.filter.familyName;
        return aName.toLocaleLowerCase().localeCompare(bName.toLocaleLowerCase());
      }
      return 0;
    })
    if (currentItems) {
      currentItems.forEach((item) => {
        if (isFilterType(item.filter)) {
          shareFilters.unshift(item)
        }
      })
      currentItems.forEach((item) => {
        if (isEveryoneType(item.filter)) {
          shareFilters.unshift(item);
        }
      })
    }
    return shareFilters
  }, [currentItems])

  const ContextMenu: React.FC<{ item: ShareFilter }> = ({ item }) => {
    const enableCollaborationEdit = useFeatureFlag(FeatureFlags.BEAC_2818_custom_deck_collaboration);
    return (
      <>
        <Iffy is={enableCollaborationEdit}>
          <DNAContextMenu>
            <DNAContextMenu.Anchor>
              <DNAButton
                appearance="outline"
                status="tertiary"
                size="xs"
                padding="xs"
                iconRight="chevron-down"
              >
                {`Can ${item.type.toLowerCase()}`}
              </DNAButton>
            </DNAContextMenu.Anchor>
            <DNAContextMenu.Items>
              <DNAContextMenu.Item
                key="edit"
                status="neutral"
                onPress={() => togglePermission(item.id)}
                title={`Can ${(item.type === PermissionType.VIEW
                  ? PermissionType.EDIT : PermissionType.VIEW).toLowerCase()}`}
              />
              <DNAContextMenu.Item
                key="remove"
                status="danger"
                onPress={() => onRemoveItem(item.id)}
                title="Remove"
              />
            </DNAContextMenu.Items>
          </DNAContextMenu>
        </Iffy>
        <Iffy is={!enableCollaborationEdit}>
          <DNAButton
            appearance="outline"
            status="tertiary"
            size="xs"
            padding="xs"
            disabled={true}
          >
            Can view
          </DNAButton>
          <DNAButton
            iconLeft="close"
            onPress={() => onRemoveItem(item.id)}
            appearance="ghost"
            status="gray"
            size="xs"
            padding="xs"
          />
        </Iffy>
      </>
    )
  }

  return (
    <FlatList
      data={items.filter((item) => !item.isDeleted)}
      keyExtractor={({ id }) => id}
      ListEmptyComponent={(
        <DNABox>
          <DNAText status="subtle">Only you have access</DNAText>
        </DNABox>
      )}
      ItemSeparatorComponent={() => <DNADivider style={{ marginVertical: 12 }} />}
      scrollEnabled
      renderItem={({ item }) => {
        return (
          <DNABox appearance="row" spacing="between" fill alignY="center">
            {isEveryoneType(item.filter) &&
              <DNABox>
                <DNAText bold status="basic" numberOfLines={1} style={styles.titleText}>
                  Everyone
                </DNAText>
              </DNABox>
            }
            {isUserType(item.filter) &&
              <DNABox testID="sharee-users-container" shrink appearance="col" alignY="center" fill>
                <DNAText bold status="basic" numberOfLines={1} style={styles.titleText}>
                  {`${item.filter.givenName} ${item.filter.familyName}`}
                </DNAText>
                <DNAText style={styles.customFieldsText} numberOfLines={1}>
                  {item.filter.email}
                </DNAText>
              </DNABox>
            }
            {isFilterType(item.filter) &&
              <DNABox appearance={'col'} fill alignY="center">
                {Object.keys(item.filter).map(key => {
                  const fieldDefinition = indexedCustomFields[key];
                  const filters = item.filter[key] as LabelValues
                  const joinedValues = getValuesLabels(fieldDefinition, filters.values).join(', ')
                  return (
                    <DNABox appearance="row" key={key}>
                      <DNAText bold status="basic" numberOfLines={3} style={styles.titleText}>
                        {`${fieldDefinition?.fieldLabel}:`}
                      </DNAText>
                      <DNAText style={[styles.customFieldsText, { marginLeft: 4, width: 300 }]}>{joinedValues}</DNAText>
                    </DNABox>
                  )
                })}
              </DNABox>
            }
            <DNABox appearance="row" alignY="center">
              <ContextMenu item={item} />
            </DNABox>
          </DNABox>
        )
      }}
    />
  )
})

function getValuesLabels(fieldDefinition: CustomFieldDefinition, values: string[]): string[] {
  const labels: string[] = [];
  fieldDefinition?.fieldValueDefinitions.forEach(({ id, value }) => {
    if (values.includes(id)) {
      labels.push(value);
    }
  });

  return labels;
}

interface ShareFolderProps {
  folderORM: FolderORM
  setMode: (mode: ShareFolderMode) => void
  currentItems: ShareFilter[]
  onRemoveItem: (id: string) => void
  onConfirm: () => void
  initialValues: ShareFilter[]
  display: boolean
  isDesktop: boolean
  onShareWithEveryone: () => void
  togglePermission: (id: string) => void
}

const ShareFolder: DNAModalVariant<ShareFolderProps> = React.memo((props) => {
  const {
    folderORM,
    toggleModal,
    setMode,
    currentItems,
    onRemoveItem,
    onConfirm,
    onShareWithEveryone,
    initialValues,
    display,
    isDesktop,
    togglePermission,
  } = props

  const canUnshare = initialValues.length > 0 && initialValues.some((item) => !item.isDeleted)
  const unshareOnly = initialValues?.some((item) => !item.isDeleted) && currentItems?.every((item) => item.isDeleted)

  const isSharedWithEveryone = currentItems?.some(({ targetType, isDeleted }) => {
    return targetType === ShareTargetType.ALL && !isDeleted;
  });

  return (
    <DNABox style={{ display: display ? undefined : 'none' }}>
      <DNAModal>
        <DNAModal.Header>
          {/* DESKTOP HEADER */}
          <Iffy is={isDesktop}>
            <DNABox
              testID="share-folder-modal-header"
              appearance="row"
              spacing="between"
              style={{ minWidth: 600 }}
              alignY="center"
            >
              <DNABox appearance="row" alignY="center">
                <DNABox appearance="col" style={styles.folderMargins}>
                  <Folder folder={folderORM} mode={'SHARING'} />
                </DNABox>
                <DNABox appearance="col" alignY="center">
                  <DNAText h4>Share Folder</DNAText>
                  <DNAText
                    status="subtle"
                    numberOfLines={2}
                    style={{ maxWidth: 450 }}
                  >
                    {folderORM.model.name}
                  </DNAText>
                </DNABox>
              </DNABox>
              <DNAButton
                testID="cancel-icon-button"
                iconLeft="close"
                onPress={toggleModal}
                appearance="ghost"
                status="gray"
                size="md"
                padding="sm"
              />
            </DNABox>
          </Iffy>
          {/* TABLET HEADER */}
          <Iffy is={!isDesktop}>
            <DNABox
              testID="share-folder-modal-header"
              appearance="row"
              spacing="between"
              style={{ minWidth: 600 }}
              alignY="center"
            >
              <DNABox alignY="center">
                <DNAButton
                  iconLeft="close"
                  onPress={toggleModal}
                  appearance="ghost"
                  status="gray"
                  size="md"
                  padding="sm"
                  style={{ marginRight: 10 }}
                />
                <DNAText
                  status="dark"
                  h4
                  numberOfLines={2}
                  style={{ maxWidth: 450 }}
                >
                  {folderORM.model.name}
                </DNAText>
              </DNABox>
              <DNABox alignY="center">
                <Iffy is={canUnshare && !unshareOnly}>
                  <DNAButton
                    size="lg"
                    appearance="ghost"
                    status="dark"
                    onPress={() => setMode(ShareFolderMode.UNSHARE)}
                  >
                    Unshare
                  </DNAButton>
                </Iffy>
                <DNAButton
                  size="md"
                  onPress={onConfirm}
                  status="primary"
                  appearance="ghost"
                  disabled={isEqual(initialValues, currentItems)}
                >
                  {unshareOnly ? 'Unshare' : 'Share'}
                </DNAButton>
              </DNABox>
            </DNABox>
          </Iffy>
        </DNAModal.Header>
        <DNAModal.Body>
          <DNABox appearance="col" fill>
            <DNABox appearance="col" style={{ margin: 24 }}>
              <DNABox appearance="row" spacing="sm">
                <DNAButton
                  appearance="outline"
                  status="tertiary"
                  padding="sm"
                  testID="add-people-button"
                  onPress={() => setMode(ShareFolderMode.ADD_PEOPLE)}
                >
                  Add people
                </DNAButton>
                <DNAButton
                  appearance="outline"
                  status="tertiary"
                  padding="sm"
                  testID="share-with-everyone-button"
                  onPress={onShareWithEveryone}
                  disabled={isSharedWithEveryone}
                >
                  Share with everyone
                </DNAButton>
                <DNAButton
                  appearance="outline"
                  status="tertiary"
                  padding="sm"
                  testID="create-access-filter-button"
                  onPress={() => setMode(ShareFolderMode.CREATE_ACCESS_FILTER)}
                >
                  Create access filter
                </DNAButton>
              </DNABox>
              <DNADivider style={{ marginTop: 24 }} />
              <DNAText label style={{ marginTop: 24 }}>Who Has Access</DNAText>
              <DNABox style={{ marginTop: 16, height: 308 }}>
                <PermissionsList
                  currentItems={currentItems}
                  onRemoveItem={onRemoveItem}
                  togglePermission={togglePermission}
                />
              </DNABox>
            </DNABox>
          </DNABox>
        </DNAModal.Body>
        {/* FOOTER */}
        <Iffy is={isDesktop}>
          <DNAModal.Footer>
            <DNABox appearance="row" spacing="between" style={{ width: '100%' }}>
              <DNABox>
                <Iffy is={canUnshare && !unshareOnly}>
                  <DNAButton
                    appearance="outline"
                    status="info"
                    onPress={() => setMode(ShareFolderMode.UNSHARE)}
                  >
                    Unshare
                  </DNAButton>
                </Iffy>
              </DNABox>
              <DNABox appearance="row" spacing="sm">
                <DNAButton
                  testID="cancel-button"
                  appearance="outline"
                  padding="sm"
                  status="tertiary"
                  onPress={toggleModal}
                >
                  Cancel
                </DNAButton>
                <DNAButton
                  testID="share-folder-modal-confirmation-button"
                  onPress={onConfirm}
                  status="primary"
                  disabled={isEqual(initialValues, currentItems)}
                >
                  {unshareOnly ? 'Unshare' : 'Share Folder'}
                </DNAButton>
              </DNABox>
            </DNABox>
          </DNAModal.Footer>
        </Iffy>
      </DNAModal>
    </DNABox>
  )
})

export default ShareFolder
