import React, {
  useState,
  useReducer,
  useContext,
  useEffect,
  useRef,
  useCallback,
  createContext,
  PropsWithChildren,
} from 'react'
import { Storage } from '@aws-amplify/storage'
import im from 'immer'
import { v4 as uuidv4 } from 'uuid'

import { ProgressBar, useSafePromise } from '@alucio/lux-ui'
import { formatDocumentFileNameHeader } from 'src/utils/documentHelpers'

const DEFAULT_MAX_FILE_SIZE = 1E8

/** UTIL FUNCTIONS */
const s3Upload = async (
  key: string,
  file: File,
  progressCallback: ProgressCallback,
) => {
  const name = formatDocumentFileNameHeader(file.name)
  await Storage.put(
    key,
    file,
    {
      contentType: file.type,
      contentDisposition: `attachment; filename="${name}"`,
      level: 'private',
      progressCallback,
    },
  )
}

const s3Cancel = (file: ManagedFile) => {
  return Storage.remove(file.key, { level: 'private' })
}

// [TODO] - Check if this accepts upper case as well
const parseFileExt = (fileExt: string[]) => fileExt
  .map(ext => {
    const prefix = ext.slice(0) === '.' ? '' : '.'
    return prefix + ext.toLocaleLowerCase()
  })
  .join(',')

const parseProgressPercent = (
  progress: number,
  managedFile: ManagedFile,
  offset?: boolean) => {
  return Number(((progress * (offset ? 95 : 100)) / managedFile.file.size).toFixed(0))
}

const mapPendingFiles = (
  files: FileList,
  dispatch: React.Dispatch<FilesReducerAction>,
  opt?: { s3PathPrefix?: string, maxFileSize?: number },
) => Array
  .from(files)
  .map<ManagedFile>((file, idx) => {
    const fileExt = file.name.split('.').pop() ?? 'unknown'
    // [TODO] - Consider using the user's id to make this more unique
    const fileKey = `${opt?.s3PathPrefix}${Date.now()}-${idx}.${fileExt}`

    const maxFileSizeLimit = opt?.maxFileSize ?? DEFAULT_MAX_FILE_SIZE
    const isUnderFileSizeLimit = maxFileSizeLimit >= file.size

    return {
      file,
      fileExt,
      key: fileKey,
      type: 'MANAGED_FILE',
      status: isUnderFileSizeLimit
        ? UPLOAD_STATUS.PENDING
        : UPLOAD_STATUS.ERROR,
      error: isUnderFileSizeLimit
        ? undefined
        : new Error(ERR_CODES.MAX_FILE_SIZE_EXCEEDED),
      cancel: () => { },
      remove: () => dispatch({ type: 'removeFile', payload: { key: fileKey } }),
      upload: (ProgressCallback: ProgressCallback) => {
        return s3Upload(fileKey, file, ProgressCallback)
      },
    }
  })

/** TYPES */
export enum UPLOAD_STATUS {
  NEW = 'NEW',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  CANCELLING = 'CANCELLING', // [TODO] - Ability to cancel all files at once
  CANCELLED = 'CANCELLED', // [TODO] - Cancel/Abort on Unmount?
  UPLOADED = 'UPLOADED',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
}

enum ERR_CODES {
  MAX_FILE_SIZE_EXCEEDED = 'Max file size exceeded'
}

export type UploadStatus = keyof typeof UPLOAD_STATUS | UPLOAD_STATUS

export type ManagedFile = {
  file: File,
  key: string,
  fileExt: string,
  type: 'MANAGED_FILE',
  upload: (progressCallback: ProgressCallback) => Promise<void>,
  cancel: () => void,
  remove: () => void,
  status: UploadStatus,
  refId?: string,
  error?: any // [TODO] - any for now, but should typecheck to instanceof error, string, number, etc
}

type ProgressCallback = (progress: { loaded: number }) => void

/** CONTEXT/REDUCER */
type FileUploadContextValues = {
  files?: ManagedFile[],
  status: UploadStatus,
  dispatch: React.Dispatch<FilesReducerAction>
}
const FileUploadContext = createContext<FileUploadContextValues>({
  files: undefined,
  status: UPLOAD_STATUS.NEW,
  dispatch: () => { },
})
export const useFileUpload = () => useContext(FileUploadContext)

type FilesReducerAction = (
  {
    type: 'setFilesToUpload',
    payload: {
      files: (FileList | null),
      dispatch: React.Dispatch<FilesReducerAction>,
      opt: { s3PathPrefix?: string },
    },
  } |
  { type: 'uploadFiles', payload?: { prefix: string, name: string } } |
  { type: 'setStatus', payload: { status: UploadStatus } } |
  { type: 'setFileStatus', payload: { status: UploadStatus, key: ManagedFile['key'] } } |
  { type: 'setFileError', payload: { error: any, key: ManagedFile['key'] } } |
  { type: 'setFileRefId', payload: { refId: ManagedFile['refId'], key: ManagedFile['key'] } } |
  { type: 'removeFile', payload: { key: ManagedFile['key'] } }
)

const filesReducer = (
  state: {
    files: FileUploadContextValues['files'],
    status: UploadStatus,
  },
  action: FilesReducerAction,
) => {
  switch (action.type) {
    case 'setFilesToUpload': {
      if (!action.payload.files?.length) return state;

      const files = mapPendingFiles(
        action.payload.files,
        action.payload.dispatch,
        action.payload.opt,
      )

      return {
        status: UPLOAD_STATUS.PENDING,
        files: state.files
          ? [...state.files, ...files]
          : files,
      }
    }
    case 'uploadFiles': {
      if (state.status !== UPLOAD_STATUS.PENDING) { console.warn('Files not ready to be uploaded') }

      return state.status === UPLOAD_STATUS.PENDING
        ? im(state, draft => { draft.status = UPLOAD_STATUS.IN_PROGRESS })
        : state
    }
    case 'setStatus': {
      return im(state, draft => { draft.status = action.payload.status })
    }
    // [TODO] - Can probably create a generic function to modify any partial state of a file
    case 'setFileStatus': {
      return im(state, draft => {
        if (!draft.files) return state;
        const idx = draft.files.findIndex(file => file.key === action.payload.key)
        if (idx === -1) return;
        draft.files[idx].status = action.payload.status
      })
    }
    case 'setFileError': {
      return im(state, draft => {
        if (!draft.files) return state;
        const idx = draft.files.findIndex(file => file.key === action.payload.key)
        if (idx === -1) return;
        draft.files[idx].status = UPLOAD_STATUS.ERROR
        draft.files[idx].error = action.payload.error
      })
    }
    case 'setFileRefId': {
      return im(state, draft => {
        if (!draft.files) return state;
        const idx = draft.files.findIndex(file => file.key === action.payload.key)
        if (idx === -1) return;
        draft.files[idx].refId = action.payload.refId
      })
    }
    case 'removeFile': {
      if (!state.files) return state

      return im(state, draft => {
        draft.files = state.files?.filter(file => file.key !== action.payload.key)
      })
    }
  }
}

/** COMPONENTS */
type FileUploadComponent = React.FC<{
  children?: ((values: FileUploadContextValues) => React.ReactNode) |
  React.ReactElement |
  React.ReactElement[]
}> & {
  Input: typeof FileUploadInput,
  Status: typeof UPLOAD_STATUS,
  ProgressBar: typeof ProgressBar,
  RowProgressTemplate: typeof FileUploadRowProgressTemplate,
  RowProgress: typeof FileUploadRowProgress,
  ERR_CODES: typeof ERR_CODES,
}

const FileUpload: FileUploadComponent = (props) => {
  const { children } = props
  const [{ files, status }, dispatch] = useReducer(
    filesReducer,
    { status: UPLOAD_STATUS.NEW, files: undefined },
  )

  useEffect(() => {
    // Reset the state to new if there are no files (files can be removed)
    if (!files?.length) {
      dispatch({
        type: 'setStatus',
        payload: { status: UPLOAD_STATUS.NEW },
      })

      return;
    }

    const fileStatuses = files.map(file => file.status)
    // Similar to Array.every -- If any single file has one of these statuses
    // then we're not done processing
    const unprocessedStatuses:UploadStatus[] = ['NEW', 'PENDING', 'IN_PROGRESS', 'UPLOADED', 'CANCELLING']
    if (fileStatuses.some(status => unprocessedStatuses.includes(status))) return

    dispatch({
      type: 'setStatus',
      payload: {
        status: fileStatuses.includes('ERROR')
          ? UPLOAD_STATUS.ERROR
          : UPLOAD_STATUS.FINISHED,
      },
    })
  }, [files])

  const value = { files, status, dispatch }

  return (
    <FileUploadContext.Provider value={value}>
      {
        typeof children === 'function'
          ? children(value)
          : children
      }
    </FileUploadContext.Provider>
  )
}

type ProgressProps = {
  onFinished?: (file: ManagedFile) => (void | Promise<ManagedFile['refId']>),
  onError?: (file: ManagedFile, err: any) => (void | Promise<any>),
  onCancelled?: (file: ManagedFile) => (void | Promise<any>),
  children: (file: (ManagedFile & { progress: number, progressPercent: number })) => React.ReactElement
}

const FileUploadRowProgressTemplate: React.FC<ProgressProps> = (props) => {
  const { children, onFinished, onError, onCancelled } = props
  const { files } = useFileUpload()

  if (!files?.length) return null

  const templated = files.map((file, idx) => (
    <FileUploadRowProgress
      key={file.key + idx}
      file={file}
      onFinished={onFinished}
      onError={onError}
      onCancelled={onCancelled}
    >
      {children}
    </FileUploadRowProgress>
  ))

  return <>{templated}</>
}

const FileUploadRowProgress: React.FC<ProgressProps & { file: ManagedFile }> = (props) => {
  const { children, file, onFinished, onError, onCancelled } = props
  const [progress, setProgress] = useState<number>(0)
  const attempted = useRef<boolean>(false)
  const cancelled = useRef<boolean>(false)
  const { status, dispatch } = useFileUpload()
  const safePromise = useSafePromise(dispatch)

  // [TODO] - We should make this happen on the `mapPendingFiles` level
  //  but we need to maintain the cancelled ref
  const cancel = useCallback(() => {
    // NOTE: Can only cancel during upload NOT after (including callbacks)
    if (file.status !== UPLOAD_STATUS.IN_PROGRESS) { return; }

    cancelled.current = true
    dispatch({
      type: 'setFileStatus',
      payload: { key: file.key, status: UPLOAD_STATUS.CANCELLING },
    })
  }, [file.status])

  /** Trigger upload based on container Status flag */
  useEffect(() => {
    if (file.status !== UPLOAD_STATUS.PENDING) return;

    if (status === UPLOAD_STATUS.IN_PROGRESS) {
      safePromise.safeDispatch({
        type: 'setFileStatus',
        payload: { status: UPLOAD_STATUS.IN_PROGRESS, key: file.key },
      })

      file
        .upload((progress) => {
          // [TODO] - Make a generic function wrapper like safeDispatch
          if (safePromise.isMounted.current) {
            setProgress(progress.loaded)
          }
        })
        .then(() => safePromise.safeDispatch({
          type: 'setFileStatus',
          payload: {
            status: cancelled.current
              ? UPLOAD_STATUS.CANCELLED
              : UPLOAD_STATUS.UPLOADED,
            key: file.key,
          },
        }))
        .catch((err) => safePromise.safeDispatch({
          type: 'setFileError',
          payload: {
            error: err,
            key: file.key,
          },
        }))
    }
  }, [status, file.status])

  /** File Status Changes (Callback handlers) */
  useEffect(() => {
    const statusUpdate = async () => {
      try {
        switch (file.status as UPLOAD_STATUS) {
          case UPLOAD_STATUS.UPLOADED: {
            if (!attempted.current) {
              attempted.current = true

              const finishedCB = onFinished?.(file)

              if (finishedCB) {
                const res = await safePromise.makeSafe(finishedCB)

                if (res) {
                  dispatch({
                    type: 'setFileRefId',
                    payload: {
                      refId: res,
                      key: file.key,
                    },
                  })
                }
              }

              dispatch({
                type: 'setFileStatus',
                payload: {
                  status: UPLOAD_STATUS.FINISHED,
                  key: file.key,
                },
              })
            }

            break;
          }
          case UPLOAD_STATUS.CANCELLING: {
            break;
          }
          case UPLOAD_STATUS.CANCELLED: {
            const cancelCB = onCancelled?.(file)
            if (cancelCB) {
              await safePromise.makeSafe(cancelCB)
            }

            await safePromise.makeSafe(s3Cancel(file))

            safePromise.safeDispatch({
              type: 'setFileStatus',
              payload: {
                status: UPLOAD_STATUS.CANCELLED,
                key: file.key,
              },
            })
            break;
          }
          case UPLOAD_STATUS.ERROR: {
            if (!attempted.current) {
              attempted.current = true
              const cb = onError?.(file, file.error)
              if (cb) await safePromise.makeSafe(cb)
            }
            break;
          }
          default: {
            break;
          }
        }
      } catch (err: any) {
        // [TODO]: Currently Storage.remove does not work and results in a 403
        // We skip this specific error for now
        const isS3Remove403Error = err?.request?.status === 403 &&
          file.status === UPLOAD_STATUS.CANCELLED

        if (isS3Remove403Error) return;

        safePromise.safeDispatch({
          type: 'setFileError',
          payload: {
            error: err,
            key: file.key,
          },
        })
      }
    }

    statusUpdate()
  }, [file.status, onFinished, onError, onCancelled])

  const progressPercent = file.status === UPLOAD_STATUS.FINISHED
    ? 100
    : parseProgressPercent(progress, file, !!onFinished)

  return children({
    ...file,
    cancel,
    progress,
    progressPercent,
  })
}

type FileUploadInputProps = {
  fileExt: string[],
  s3PathPrefix?: string,
  multiFile?: boolean,
  disabled?: boolean,
  processOnSelect?: boolean,
  maxFileSize?: number, // [TODO]
  onSelect?: (files: FileList | null) => void
}

const FileUploadInput: React.FC<PropsWithChildren<FileUploadInputProps>> = (props) => {
  const { fileExt, multiFile, disabled, processOnSelect, s3PathPrefix, children, onSelect } = props
  const [trigger, setTrigger] = useState<string>('')
  const { dispatch } = useFileUpload()
  // Each FileUpload.Input needs a unique ID to support multiple instances
  const uuid = useRef(`fileUploadInput-${uuidv4()}`).current

  const onFilesSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();

    onSelect?.(event.target.files)

    dispatch({
      type: 'setFilesToUpload',
      payload: {
        files: event.target.files,
        dispatch,
        opt: { s3PathPrefix },
      },
    })

    if (processOnSelect) dispatch({ type: 'uploadFiles' })
    setTrigger('')
  }

  return (
    <>
      <input
        accept={parseFileExt(fileExt)}
        id={uuid}
        type="file"
        style={{ display: 'none' }}
        multiple={!!multiFile}
        value={trigger}
        onChange={onFilesSelected}
        disabled={disabled}
      />
      <label htmlFor={uuid}>
        {children}
      </label>
    </>
  )
}

FileUpload.Input = FileUploadInput
FileUpload.ProgressBar = ProgressBar
FileUpload.Status = UPLOAD_STATUS
FileUpload.RowProgress = FileUploadRowProgress
FileUpload.RowProgressTemplate = FileUploadRowProgressTemplate
FileUpload.ERR_CODES = ERR_CODES

export default FileUpload
