
import React from 'react';
import { PlayerMode } from '@alucio/core';
import HighlighterPlayer from './HighlighterPlayer';
import HighlighterSource from './HighlighterSource';

type HighlighterProps = {
    iFrameRef: React.MutableRefObject<HTMLIFrameElement | null>;
    mode: PlayerMode;
  }

type ModeVariants = Record<PlayerMode, React.ElementType>

const modeVariants:ModeVariants = {
  NON_INTERACTIVE: HighlighterPlayer,
  INTERACTIVE: HighlighterSource,
}

const Highlighter:React.FC<HighlighterProps> = (props) => {
  const { iFrameRef, mode } = props;

  const CurrentModeVariant = modeVariants[mode]

  return <CurrentModeVariant iFrameRef={iFrameRef} />
}

export default Highlighter
