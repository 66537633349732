import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { RootState, useDispatch } from 'src/state/redux';
import { DocumentAccessLevel } from '@alucio/aws-beacon-amplify/src/models';
import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions';
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage';
import { LoadedPresentation, useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { useAllDocumentsInstance } from 'src/state/redux/selector/document';
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
import { CustomDeckORM, DocumentORM, DocumentVersionORM, FolderItemORM } from 'src/types/orms';
import { isCustomDeckORM, isDocumentVersionORM, isFolderItemORM, isPageGroupORM } from 'src/types/typeguards';
import { IndexPath } from '@alucio/lux-ui';
import { useSelector } from 'react-redux';
import { folderActions } from 'src/state/redux/slice/folder';
import { customDeckActions } from 'src/state/redux/slice/customDeck';

interface ContentPreviewModalStateType {
  canContentBePresented: boolean,
  slideRollVisible: boolean,
  setSlideRollVisible: Dispatch<SetStateAction<boolean>>,
  textSearchVisible: boolean,
  setTextSearchVisible: Dispatch<SetStateAction<boolean>>,
  activeDocumentVersionORM: DocumentVersionORM | undefined
  activeDocumentORM: DocumentORM | undefined
  isModifiedDocumentVersion: boolean
  isFolderItem: boolean
  isPublisher: boolean
  isAssociatedFile: boolean
  setIsAssociatedFile: (isAssociatedFile: boolean) => void,
  bookmarkDocument: () => void,
  addDocumentToFolder: () => void,
  acknowledgeUpdate: () => void,
  isAcknowledgeClicked: boolean,
  onClose: () => void
  setPageGroupToAllSlides: () => void,
  setSelectedIndex: Dispatch<SetStateAction<IndexPath>>,
  selectedIndex: IndexPath,

}

const ContentPreviewModalStateContext = createContext<ContentPreviewModalStateType | null>(null!)
ContentPreviewModalStateContext.displayName = 'ContentPreviewModalContext'

interface InitialValues {
}

const ContentPreviewModalStateProvider: React.FC<PropsWithChildren<InitialValues>> = ({
  children,
}) => {
  const dispatch = useDispatch()
  const { activePresentation } = useContent()
  const documentActions = useDNADocumentActions()
  const modalState = useSelector((state: RootState) => state.contentPreviewModal)
  const [slideRollVisible, setSlideRollVisible] = useState<boolean>(!!modalState.meetingId)
  const [textSearchVisible, setTextSearchVisible] = useState<boolean>(false)
  const [isAssociatedFile, setIsAssociatedFile] = useState<boolean>(false)
  const [isAcknowledgeClicked, setIsAcknowledgeClicked] = useState<boolean>(false)
  const [selectedIndex, setSelectedIndex] = useState<IndexPath>(new IndexPath(0))
  const route = useCurrentPage({ exact: true })
  const isPublisher = route?.configOptions?.modules?.includes('publisher') || false
  /** NOTE: We can't rely on this model to contain data synchronized with the database, thus, we have to do a lookup here.
   * See: https://alucioinc.atlassian.net/browse/BEAC-3541
   */
  const activeDocumentId = activePresentation?.currentPresentablePage.documentVersionORM.relations.documentORM.model.id
  const [activeDocumentORM]:(DocumentORM)[] = useAllDocumentsInstance({
    filter: {
      model: { id: activeDocumentId },
    },
  })

  const onClose = () => {
    dispatch(contentPreviewModalActions.setModalVisibility({ isOpen: false, content: undefined }))
  }

  const setPageGroupToAllSlides = () => {
    setSelectedIndex(new IndexPath(0))
  }

  useEffect(() => {
    if (activePresentation) {
      let documentVersionId: string = '';
      let documentId: string = '';
      let customDeckId: string = '';
      const { orm } = activePresentation.presentable

      if (isFolderItemORM(orm)) {
        documentVersionId = activePresentation.currentPresentablePage.documentVersionORM.model.id
        documentId = activePresentation.currentPresentablePage.documentVersionORM.relations.documentORM.model.id
      }
      else if (isDocumentVersionORM(orm)) {
        documentVersionId = orm.model.id
        documentId = orm.relations.documentORM.model.id;
      }
      if (isFolderItemORM(orm) && !isDocumentVersionORM(orm) && !isDocumentVersionORM(orm.relations.itemORM)) {
        customDeckId = orm.relations.itemORM.model.id
      }
      if (documentVersionId || customDeckId) {
        analytics?.track('DOCUMENT_CONTENT_PREVIEW', {
          action: 'PREVIEW',
          category: 'DOCUMENT',
          documentId,
          documentVersionId,
          customDeckId: customDeckId,
        })
      }
    }
  }, [activePresentation?.presentable])

  let activeDocumentVersionORM: DocumentVersionORM | undefined

  if (isDocumentVersionORM(activePresentation?.presentable.orm)) {
    activeDocumentVersionORM = activePresentation?.presentable.orm
  } else if (
    isFolderItemORM(activePresentation?.presentable.orm) &&
    isDocumentVersionORM(activePresentation?.presentable.orm.relations.itemORM)
  ) {
    activeDocumentVersionORM = activePresentation?.presentable.orm.relations.itemORM
  } else if (isPageGroupORM(activePresentation?.presentable.orm)) {
    activeDocumentVersionORM = activePresentation?.presentable.orm.relations.documentVersionORM
  } else {
    activeDocumentVersionORM = undefined
  }

  const canContentBePresented = useMemo(() =>
    !isPublisher && canPresentContent(activeDocumentORM, activePresentation),
  [activePresentation?.presentable]);

  const isModifiedDocumentVersion =
    isFolderItemORM(activePresentation?.presentable.orm) &&
    !!activePresentation?.presentable.orm.model.visiblePages?.length;

  const bookmarkDocument = () => activeDocumentORM && documentActions.bookmark(activeDocumentORM)()
  const addDocumentToFolder = () => activeDocumentORM && documentActions.addToFolder(activeDocumentORM)()
  const acknowledgeUpdate = () => {
    // Acknowledge document is auto updated
    if (activePresentation?.presentable.orm && isFolderItemORM(activePresentation?.presentable.orm)) {
      const folderItemORMs: FolderItemORM[] = []
      const customDeckORMs: CustomDeckORM[] = []

      if (isCustomDeckORM(activePresentation?.presentable.orm.relations?.itemORM)) {
        customDeckORMs.push(activePresentation?.presentable.orm.relations.itemORM)
      }
      else {
        folderItemORMs.push(activePresentation?.presentable.orm)
      }

      dispatch(folderActions.acknowledgeAutoUpdate(folderItemORMs))
      dispatch(customDeckActions.acknowledgeAutoUpdate(customDeckORMs))

      setIsAcknowledgeClicked(true)
    }
  }

  const contextValue:ContentPreviewModalStateType = {
    slideRollVisible,
    canContentBePresented,
    setSlideRollVisible,
    textSearchVisible,
    setTextSearchVisible,
    isModifiedDocumentVersion,
    isFolderItem: isFolderItemORM(activePresentation?.presentable.orm),
    activeDocumentORM,
    activeDocumentVersionORM,
    isPublisher,
    isAssociatedFile,
    setIsAssociatedFile,
    bookmarkDocument,
    addDocumentToFolder,
    acknowledgeUpdate,
    isAcknowledgeClicked,
    onClose,
    setPageGroupToAllSlides,
    setSelectedIndex,
    selectedIndex,
  }

  return (
    <ContentPreviewModalStateContext.Provider value={contextValue}>
      {children}
    </ContentPreviewModalStateContext.Provider>
  )
}
ContentPreviewModalStateProvider.displayName = 'ContentPreviewModalStateProvider'
export const useContentPreviewModalState = () => {
  const context = useContext(ContentPreviewModalStateContext)
  if (!context) {
    throw new Error('useContentPreviewModalState must be used within the ContentPreviewModalStateProvider')
  }
  return context;
}

function canPresentContent(documentORM: DocumentORM, activePresentation?: LoadedPresentation): boolean {
  if (!activePresentation) {
    return false;
  }

  const { orm } = activePresentation?.presentable;

  let documentVersionORM: DocumentVersionORM | undefined = isDocumentVersionORM(orm) ? orm : undefined;

  // IS FOLDER ITEM
  if (isFolderItemORM(orm)) {
    // IS CUSTOM DECK
    if (isCustomDeckORM(orm.relations.itemORM)) {
      return orm.relations.itemORM.meta.permissions.MSLPresent;
    }

    // IS DOCUMENT VERSION WITHIN FOLDER
    if (isDocumentVersionORM(orm.relations.itemORM)) {
      documentVersionORM = orm.relations.itemORM;
    }
  }

  // IS PAGE GROUP
  if (isPageGroupORM(orm)) {
    documentVersionORM = orm.relations.documentVersionORM;
  }

  // IS DOCUMENT VERSION
  if (documentVersionORM) {
    // IF IT'S A DOCUMENT VERSION, IT HAS TO BE THE LATEST PUBLISHED
    const isLatestPublishedVersion = documentORM?.relations.version.latestPublishedDocumentVersionORM?.model.id ===
        documentVersionORM.model.id;
    const isUserDocument = documentVersionORM.relations.documentORM.model.accessLevel === DocumentAccessLevel.USER;

    return documentVersionORM.meta.permissions.MSLPresent && isLatestPublishedVersion && !isUserDocument;
  }

  return false;
}

export default ContentPreviewModalStateProvider
