import { DataStore, Predicates } from '@aws-amplify/datastore'
import { batch } from 'react-redux';
import { Auth } from '@aws-amplify/auth'
import { Logger } from '@aws-amplify/core'
import {
  AttachedFile,
  CustomDeck,
  Document,
  DocumentVersion,
  EmailTemplate,
  Folder,
  Tenant,
  Meeting,
  User,
  UserRole,
  EmailTemplateStatus,
  Hub,
} from '@alucio/aws-beacon-amplify/src/models'

import { store } from 'src/state/redux';
import { attachedFileActions } from '../redux/slice/attachedFile';
import { customDeckActions } from '../redux/slice/customDeck';
import { documentActions } from '../redux/slice/document'
import { documentVersionActions } from '../redux/slice/documentVersion'
import { folderActions } from '../redux/slice/folder';
import { tenantActions } from '../redux/slice/tenant'
import { userActions } from '../redux/slice/user'
import { meetingActions } from '../redux/slice/meeting';
import { emailTemplateActions } from '../redux/slice/emailTemplate';
import {
  filterDocumentsForDeletedAndLockedFilters,
  getIndexedValidCustomFields,
  matchesLockedFilters,
} from 'src/state/datastore/query'
import { hubActions } from '../redux/slice/hub';
import activeUser from 'src/state/global/ActiveUser'
import PWALoaded from 'src/state/global/PWALoaded'
import { syncCacheManifest } from 'src/state/cache/syncManifests'
import { allCustomDecks } from '../redux/selector/folder';
const logger = new Logger('hydrate', 'INFO');

const hydrate = async (isOfflineEnabled: boolean, ldClient) => {
  const modelRecords = await Promise.all([
    DataStore.query(Document, Predicates.ALL),
    DataStore.query(DocumentVersion, Predicates.ALL),
    DataStore.query(Tenant, Predicates.ALL),
    DataStore.query(User, Predicates.ALL),
    DataStore.query(Folder, Predicates.ALL),
    DataStore.query(AttachedFile, Predicates.ALL),
    DataStore.query(EmailTemplate, Predicates.ALL),
    DataStore.query(CustomDeck, Predicates.ALL),
    DataStore.query(Meeting, Predicates.ALL),
    DataStore.query(Hub, Predicates.ALL),
  ])

  const [
    documents,
    documentVersions,
    tenants,
    users,
    folders,
    attachedFiles,
    emailTemplates,
    customDeck,
    meetings,
    hubs,
  ] = modelRecords;
  let cognitoUser;
  try {
    cognitoUser = await Auth.currentAuthenticatedUser();
  } catch (e) {
    cognitoUser = JSON.parse(localStorage.getItem('amplify-latest-user-attributes') ?? '');
  }

  /**
   * first cognitoUser.signInUserSession.idToken.payload.email is the one that we are overriding on the cognitoTriggerHandler that one is in lowercase
   * second cognitoUser.attributes.email is the one that we are getting from the cognitoUserPool that one can be in uppercase or lowercase
   */
  const cognitoEmail = cognitoUser?.signInUserSession?.idToken?.payload?.email || cognitoUser?.attributes?.email;
  const currentUser = users.find(({ email }) => email === cognitoEmail);
  activeUser.set(currentUser)
  const isPWALoaded = window.matchMedia('(display-mode: standalone)').matches
  PWALoaded.set(isPWALoaded)

  // [TODO]: Revisit this when Alucio Admin may receive more than 1 Tenant
  if (tenants.length > 1 && currentUser?.role !== UserRole.ALUCIO_ADMIN) {
    throw new Error('More than one tenant returned by Datastore');
  } else if (!currentUser) {
    throw new Error('User not found in DynamoDB');
  }

  const usersTenant = tenants.find(({ id }) => id === currentUser?.tenantId);
  const validIndexedCustomFields = getIndexedValidCustomFields(usersTenant?.config?.customFields || []);

  const { documents: modifiedDocuments, versions: modifiedDocumentVersions } =
    filterDocumentsForDeletedAndLockedFilters(
      currentUser,
      documents,
      documentVersions,
      validIndexedCustomFields,
    )

  const modifiedEmailTemplates = emailTemplates
    .filter(({ customFilterValues, status }) => matchesLockedFilters(
      currentUser?.lockedFiltersCustomValues || [],
      validIndexedCustomFields)(status === EmailTemplateStatus.ACTIVE, customFilterValues));

  if (currentUser) {
    const userInfo = {
      userId: currentUser.id,
      email: currentUser.email,
      givenName: currentUser.givenName,
      lastName: currentUser.familyName,
      role: [currentUser.role],
      tenant: usersTenant?.name,
      tenantId: currentUser.tenantId,
    }
    logger.debug('About to initialize analytics', userInfo)
    analytics?.identify(currentUser.email, userInfo);

    analytics?.group(currentUser.tenantId, {
      name: usersTenant?.name,
    });

    const newUser = {
      key: currentUser.id,
      custom: {
        tenantId: currentUser.tenantId,
        tenantName: usersTenant?.name,
      },
    }

    // [NOTE] - We skip LD Client errors for now (this should normally error in offline mode)
    await ldClient
      ?.identify(newUser)
      .catch(() => {})
  }

  // removing deleted hubs before adding to store
  const filteredHubs = hubs.filter(hub => hub.status !== 'DELETED')

  batch(() => {
    // DataStore
    store.dispatch(documentActions.add(modifiedDocuments))
    store.dispatch(documentVersionActions.add(modifiedDocumentVersions))
    store.dispatch(folderActions.add(folders))
    store.dispatch(tenantActions.add(tenants))
    store.dispatch(attachedFileActions.add(attachedFiles))
    store.dispatch(userActions.add(users))
    store.dispatch(emailTemplateActions.add(modifiedEmailTemplates))
    store.dispatch(customDeckActions.add(customDeck))
    store.dispatch(meetingActions.add(meetings))
    store.dispatch(hubActions.add(filteredHubs))

    // Mark as hydrated
    // [TODO] - Alternatively, manage an overall app hydrated state in a separate slice
    store.dispatch(documentActions.setHydrated())
    store.dispatch(documentVersionActions.setHydrated())
    store.dispatch(folderActions.setHydrated())
    store.dispatch(tenantActions.setHydrated())
    store.dispatch(attachedFileActions.setHydrated())
    store.dispatch(userActions.setHydrated())
    store.dispatch(emailTemplateActions.setHydrated())
    store.dispatch(customDeckActions.setHydrated())
    store.dispatch(meetingActions.setHydrated())
    store.dispatch(hubActions.setHydrated())

    // Now that everything's hydrated we need to check if we need to auto-update anything
    // NOTE: Including validation for null because that whats we get instead of undefined if the
    // field does not exist in DynamoDB Tenant record
    if (usersTenant?.folderUpdateGracePeriodDays !== undefined &&
      usersTenant?.folderUpdateGracePeriodDays !== null) {
      const customDecks = allCustomDecks(store.getState())
      store.dispatch(folderActions.applyAutoUpdate(
        folders,
        documentVersions,
        usersTenant?.folderUpdateGracePeriodDays ?? 0,
      ))
      store.dispatch(customDeckActions.applyAutoUpdate(customDecks, usersTenant?.folderUpdateGracePeriodDays ?? 0))
    }
  })

  // Cache Tings
  if (isOfflineEnabled) {
    await syncCacheManifest()
  }
}

export default hydrate
