import React from 'react';
import {
  DNABox,
  DNAButton,
  DNACard,
  DNAChip,
  DNAText,
  Iffy,
} from '@alucio/lux-ui/src';
import { DocumentVersionORM } from 'src/types/types'
import { useDocumentVersionORM } from 'src/state/redux/selector/document';
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList';
import { styles, RowProps, AssociatedFileDetails } from 'src/screens/Hubs/EditHub/Widgets/SharedFiles';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import { useHubRHFromState } from 'src/screens/Hubs/EditHub/useHubRHFormStateProvider';

interface DocumentRowWrapperProps extends RowProps {
  associatedContent: AssociatedFileDetails
}

interface DocumentRowProps extends RowProps {
  documentVersionORM: DocumentVersionORM
  associatedContent?: AssociatedFileDetails
}

export const DocumentRow: React.FC<DocumentRowProps> = ({ documentVersionORM, associatedContent, isLastItem, handleDeleteItem }) => {
  const { hubORM, handleSaveNPreview, openPreviewWindow } = useHubsState()
  const { rhform, setIsSaving } = useHubRHFromState()
  const isRequired = associatedContent?.isRequired
  const { handleSubmit } = rhform

  if (!hubORM) return null

  const onSaveNPreview = (data: any) => {
    setIsSaving(true)
    setTimeout(() => {
      handleSaveNPreview(data, documentVersionORM.model.id)
      setIsSaving(false)
    }, 1000)
  }

  const onPreview = () => openPreviewWindow(hubORM.model.id, documentVersionORM.model.id)

  return (
    <DNACard
      onPress={handleSubmit(onSaveNPreview, onPreview)}
      interactive="pressableAlt"
      appearance="flat"
      style={isLastItem ? undefined : styles.bottomBorder}
    >
      <DNABox alignY="center" style={styles.row}>
        <DNABox style={styles.documentThumbnailBorder}>
          <DNADocumentThumbnail
            width={120}
            height={65}
            documentVersionORM={documentVersionORM}
          />
        </DNABox>
        <DNABox fill appearance="col" alignY="center" spacing="sm">
          <DNAText>{documentVersionORM.model.title}</DNAText>
          <DNABox spacing="sm">
            <CustomFieldBadgeList documentVersionORM={documentVersionORM} />
            <Iffy is={isRequired}>
              <DNAChip appearance="tag">REQUIRED</DNAChip>
            </Iffy>
          </DNABox>
        </DNABox>
        <DNABox alignY="center">
          <Iffy is={!isRequired}>
            <DNAButton
              iconLeft="trash-can-outline"
              appearance="ghostLink"
              status="gray"
              size="md"
              padding="sm"
              onPress={() => handleDeleteItem(documentVersionORM.model.id, associatedContent)}
            />
          </Iffy>
        </DNABox>
      </DNABox>
    </DNACard>
  )
}

export const DocumentRowWrapper: React.FC<DocumentRowWrapperProps> = ({ associatedContent, isLastItem, handleDeleteItem }) => {
  const documentVersionORM = useDocumentVersionORM(associatedContent.versionId || '')
  if (!documentVersionORM) return null
  return (
    <DocumentRow
      documentVersionORM={documentVersionORM}
      associatedContent={associatedContent}
      isLastItem={isLastItem}
      handleDeleteItem={handleDeleteItem}
    />
  )
}

export default DocumentRow