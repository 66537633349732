import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useReducer, useRef } from 'react';
import {
  DocumentVersionORM,
  FolderItemORM,
  isCustomDeckORM,
  isDocumentVersionORM,
  isPageGroupORM,
  PageGroupORM,
  Presentable,
  PresentablePage,
} from 'src/types/types';
import { PPZTransform } from '@alucio/core';
import { getPresentable } from './helper';
import throttle from 'lodash/throttle';
import useContentInfoBroadcastChannel from './useContentInfoBroadcastChannel';
import { Logger } from '@aws-amplify/core';
import { ContentPresented } from '@alucio/aws-beacon-amplify/src/models';
import { AccessTokenDataExtended } from 'src/state/redux/slice/authToken';
import { useAuthTokens } from 'src/state/redux/selector/authToken';
import useContentPageData, { ContentPageData } from 'src/hooks/useContentPageData/useContentPageData';
import { getContentPresentedIndexByContent } from '../Meetings/helper';
const logger = new Logger('ContentProvider', 'INFO');
interface InitialValues {
  item?: PresentableModelORM
  meetingId?: string
}

export interface LoadedPresentation {
  presentable: Presentable,
  currentPresentablePage: PresentablePage
  currentPPZCoords?: PPZTransform,
  currentStep?: number,
  totalSteps?: number,
}

export type PresentableModelORM = DocumentVersionORM | FolderItemORM | PageGroupORM;

export interface ContentType {
  /** NOTE: DO NOT RELY ON THIS FOR UPDATES THAT COULD OCCUR IN THE BACKEND (e.g bookmarks, etc...)
   * This nomenclature is a bit misleading. This property will not reflect any updates to the
   * relative models that have been recently updated on the backend. We shoud align on a nomenclature
   * that indicates a model which is syncronized with the backend vs asynchronous
   * See: https://alucioinc.atlassian.net/browse/BEAC-3541 */
  activePresentation?: LoadedPresentation,
  addPresentation: (item: PresentableModelORM, pageNumber?: number) => void,
  changeActivePresentation: (presentableId: string) => void,
  initPresentation: (item: PresentableModelORM, pageNumber?: number, visibleOverwrittenPageIds?: string[]) => void,
  nextStepPage: () => void,
  presentations: LoadedPresentation[],
  contentPresented?: ContentPresented[],
  contentPageData: ContentPageData[],
  addContentPresented: (contentPresented: ContentPresented) => void,
  updateContentPresented: (contentPresented: ContentPresented) => void,
  setContentPresented: (contentPresented: ContentPresented[]) => void,
  prevStepPage: () => void,
  removePresentation: (presentableId: string) => void,
  setActiveSlideByPresentationPageNumber: (presentationPageNumber: number, step?: number, totalSteps?: number) => void,
  setCurrentPPZCoords: (coords?: PPZTransform) => void,
}

type ContentReducerAction = (
  {
    type: 'addPresentation', payload: {
      item: PresentableModelORM, pageNumber?: number, authTokens?: AccessTokenDataExtended[]
    }
  } |
  { type: 'removePresentation', payload: { presentationId: string } } |
  { type: 'changeActivePresentation', payload: { presentationId: string } } |
  { type: 'initPresentation', payload: {
    item: PresentableModelORM, pageNumber?: number, visibleOverwrittenPageIds?: string[] } } |
  { type: 'setActiveSlideByPresentationPageNumber', payload: {
    presentationPageNumber: number, step?: number, totalSteps?: number } } |
  { type: 'setCurrentPPZCoords', payload: { coords: PPZTransform | undefined } } |
  { type: 'addContentPresented', payload: { contentPresented: ContentPresented } } |
  { type: 'setContentPresented', payload: { contentPresented: ContentPresented[] } } |
  { type: 'updateContentPresented', payload: { contentPresented: ContentPresented } }
);

interface ContentProviderState {
  activePresentation?: LoadedPresentation,
  presentations: LoadedPresentation[],
  contentPresented: ContentPresented[],
}

function getNewLoadedPresentation(presentable: Presentable, initialPage?: number): LoadedPresentation {
  let activePage: PresentablePage | undefined;

  const allPages = presentable.presentableGroups.reduce<PresentablePage[]>((acc, { pages }) =>
    [...acc, ...pages], []);

  if (initialPage) {
    if (!isPageGroupORM(presentable.orm) &&
      (isDocumentVersionORM(presentable.orm) ||
        isDocumentVersionORM(presentable.orm.relations.itemORM))) {
      activePage = allPages.find((page) => page.page.number === initialPage);
    } else if (!isPageGroupORM(presentable.orm) && isCustomDeckORM(presentable.orm.relations.itemORM)) {
      activePage = allPages.find((page) => page.presentationPageNumber === initialPage);
    } else if (isPageGroupORM(presentable.orm)) {
      activePage = allPages.find(page => page.presentationPageNumber === initialPage)
    }
  }
  if (!activePage) activePage = presentable.presentableGroups[0]?.pages[0];

  if (!activePage) {
    throw new Error('Presentable Page not found');
  }

  return {
    presentable,
    currentPresentablePage: activePage,
  }
}

function getPresentablePage(presentable: Presentable, presentationPageNumber: number): PresentablePage | undefined {
  for (const group of presentable.presentableGroups) {
    for (const page of group.pages) {
      if (page.presentationPageNumber === presentationPageNumber) {
        return page;
      }
    }
  }
}

const contentReducer = (state: ContentProviderState, action: ContentReducerAction): ContentProviderState => {
  switch (action.type) {
    case 'addPresentation': {
      // IF THE NEW PRESENTATION IS ALREADY IN OUR LOADED PRESENTATION'S ARRAY
      // THAT ONE WILL BE SELECTED INSTEAD OF ADDING A DUPLICATED PRESENTATION
      let loadedPresentation
      if (isPageGroupORM(action.payload.item)) {
        // REMOVE after BEAC-3611 is resolved and pageGroups ids are unique across versions
        const pageGroup = action.payload.item
        loadedPresentation = state.presentations.find(({ presentable }) =>
          isPageGroupORM(presentable.orm) && presentable.orm?.model.id === pageGroup.model.id &&
          presentable.orm.relations.documentVersionORM.model.id === pageGroup.relations.documentVersionORM.model.id,
        );
      } else {
        loadedPresentation = state.presentations.find(({ presentable }) =>
          presentable.orm?.model.id === action.payload.item.model.id,
        );
      }

      if (loadedPresentation) {
        // check if the presentation is the same and the page is the same
        if (state.activePresentation?.presentable.orm?.model.id === action.payload.item.model.id &&
          state.activePresentation?.currentPresentablePage.presentationPageNumber === action.payload.pageNumber) {
          return state;
        }

        if (action.payload.pageNumber) {
          const newPage = getPresentablePage(loadedPresentation.presentable, action.payload.pageNumber);
          loadedPresentation.currentPresentablePage = newPage || loadedPresentation.currentPresentablePage;
        }

        return {
          activePresentation: loadedPresentation,
          presentations: state.presentations,
          contentPresented: state.contentPresented,
        }
      }

      const presentable = getPresentable(action.payload.item, action.payload.authTokens);
      logger.debug('New Presentable', presentable)
      const activePresentation = getNewLoadedPresentation(presentable!, action.payload.pageNumber);
      return {
        activePresentation,
        presentations: [...state.presentations, activePresentation],
        contentPresented: state.contentPresented,
      }
    }
    case 'removePresentation': {
      const presentations = state.presentations.filter(({ presentable }) =>
        presentable.id !== action.payload.presentationId);
      const activePresentation = state.activePresentation?.presentable.id === action.payload.presentationId
        ? presentations[0] : state.activePresentation;

      if (!activePresentation) {
        console.warn('The current presentation was closed.');
      } else if (presentations.length === state.presentations.length) {
        console.warn('The presentation to be removed was not found.');
      }

      return {
        presentations,
        activePresentation,
        contentPresented: state.contentPresented,
      }
    }
    case 'changeActivePresentation': {
      const activePresentation = state.presentations.find(({ presentable }) =>
        presentable.id === action.payload.presentationId);

      if (!activePresentation) {
        console.warn('The requested presentation was not found. \' Keeping the current one.');
      }

      if (activePresentation?.presentable.id === state.activePresentation?.presentable.id) {
        return state;
      }

      return {
        ...state,
        activePresentation: activePresentation || state.activePresentation,
      };
    }
    case 'setActiveSlideByPresentationPageNumber': {
      if (!state.activePresentation) {
        return {
          presentations: [],
          activePresentation: undefined,
          contentPresented: state.contentPresented,
        }
      }

      const activePresentationId = state.activePresentation?.presentable.id;
      const newPage = getPresentablePage(state.activePresentation.presentable, action.payload.presentationPageNumber);

      if (!newPage) {
        throw new Error('Page not found');
      }

      return {
        ...state,
        presentations: state.presentations
          .map(({ presentable, currentPresentablePage, currentPPZCoords, currentStep, totalSteps }) =>
            (
              {
                presentable,
                currentPresentablePage: presentable.id === activePresentationId ? newPage : currentPresentablePage,
                currentPPZCoords,
                currentStep: presentable.id === activePresentationId ? action.payload.step : currentStep,
                totalSteps: presentable.id === activePresentationId ? action.payload.totalSteps : totalSteps,
              })),
        activePresentation: {
          presentable: state.activePresentation.presentable,
          currentPPZCoords: undefined,
          currentPresentablePage: newPage,
          currentStep: action.payload.step || 0,
          totalSteps: action.payload.totalSteps,
        },
      }
    }
    case 'initPresentation': {
      const presentable = getPresentable(action.payload.item, undefined, action.payload.visibleOverwrittenPageIds);
      const activePresentation = getNewLoadedPresentation(presentable!, action.payload.pageNumber);
      return {
        activePresentation,
        presentations: [activePresentation],
        contentPresented: state.contentPresented,
      }
    }
    case 'setCurrentPPZCoords': {
      if (!state.activePresentation || !action.payload.coords) {
        return state;
      }
      state.activePresentation.currentPPZCoords = action.payload.coords;
      return {
        ...state,
        activePresentation: {
          ...state.activePresentation,
          currentPPZCoords: {
            ...action.payload.coords,
          },
        },
      };
    }
    case 'addContentPresented': {
      if (!action.payload.contentPresented) {
        return state;
      }
      return {
        ...state,
        contentPresented: [...state.contentPresented, action.payload.contentPresented],
      };
    }
    case 'updateContentPresented': {
      if (!action.payload.contentPresented) {
        return state;
      }

      const contentToUpdateIndex =
        getContentPresentedIndexByContent(state.contentPresented, action.payload.contentPresented);

      return {
        ...state,
        contentPresented: state.contentPresented.map((contentPresented, idx) =>
          idx === contentToUpdateIndex ? action.payload.contentPresented : contentPresented),
      }
    }
    case 'setContentPresented': {
      return {
        ...state,
        contentPresented: action.payload.contentPresented,
      }
    }
  }
};

const ContentProvider: React.FC<PropsWithChildren<InitialValues>> = ({
  meetingId,
  item,
  children,
}) => {
  const authTokens = useAuthTokens()
  const [{ activePresentation, presentations, contentPresented }, dispatch] = useReducer(contentReducer, {
    presentations: [],
    activePresentation: undefined,
    contentPresented: [],
  });
  const { contentPageData } = useContentPageData(activePresentation?.presentable)
  const activePresentationRef = useRef<LoadedPresentation | undefined>(activePresentation);
  const nextStepPage = useCallback(throttle(goToNextStepPage, 300), []);
  const prevStepPage = useCallback(throttle(goToPrevStepPage, 300), []);
  useContentInfoBroadcastChannel(
    setActiveSlideByPresentationPageNumber,
    setCurrentPPZCoords,
    activePresentation,
    meetingId,
  )

  useEffect(() => {
    if (item) {
      dispatch({ type: 'initPresentation', payload: { item: item } });
    }
  }, []);

  useEffect(() => {
    activePresentationRef.current = activePresentation;
  }, [activePresentation]);

  function addPresentation(item: PresentableModelORM, pageNumber?: number): void {
    dispatch({ type: 'addPresentation', payload: { item, pageNumber, authTokens } });
  }

  function removePresentation(presentationId: string): void {
    dispatch({ type: 'removePresentation', payload: { presentationId } });
  }

  function changeActivePresentation(presentationId: string): void {
    dispatch({ type: 'changeActivePresentation', payload: { presentationId } });
  }

  function setActiveSlideByPresentationPageNumber(
    presentationPageNumber: number,
    step?: number,
    totalSteps?: number,
  ): void {
    dispatch({ type: 'setActiveSlideByPresentationPageNumber', payload: { presentationPageNumber, step, totalSteps } });
  }

  function initPresentation(
    item: PresentableModelORM,
    pageNumber?: number,
    visibleOverwrittenPageIds?: string[]): void {
    dispatch({ type: 'initPresentation', payload: { item, pageNumber, visibleOverwrittenPageIds } });
  }

  function setCurrentPPZCoords(coords?: PPZTransform): void {
    dispatch({ type: 'setCurrentPPZCoords', payload: { coords } });
  }

  function addContentPresented(contentPresented: ContentPresented): void {
    dispatch({ type: 'addContentPresented', payload: { contentPresented } });
  }

  function setContentPresented(contentPresented: ContentPresented[]): void {
    dispatch({ type: 'setContentPresented', payload: { contentPresented } });
  }

  function updateContentPresented(contentPresented: ContentPresented): void {
    dispatch({ type: 'updateContentPresented', payload: { contentPresented } });
  }

  function goToNextPage(): void {
    if (activePresentationRef.current?.currentPresentablePage &&
      activePresentationRef.current?.currentPresentablePage.presentationPageNumber <
      activePresentationRef.current.presentable.numberOfPages) {
      dispatch({
        type: 'setActiveSlideByPresentationPageNumber',
        payload: {
          presentationPageNumber: activePresentationRef.current?.currentPresentablePage.presentationPageNumber + 1,
        },
      });
    }
  }

  function goToPrevPage(): void {
    if (activePresentationRef.current?.currentPresentablePage &&
      activePresentationRef.current?.currentPresentablePage.presentationPageNumber > 1) {
      dispatch({
        type: 'setActiveSlideByPresentationPageNumber',
        payload: {
          presentationPageNumber: activePresentationRef.current?.currentPresentablePage.presentationPageNumber - 1,
        },
      });
    }
  }

  function goToNextStepPage(): void {
    logger.debug('Entered goToNextStepPage: ', activePresentationRef.current);
    if (activePresentationRef.current?.currentPresentablePage) {
      // IF WE'RE ON THE LAST STEP, GO TO THE NEXT SLIDE
      const currentStep = activePresentationRef.current?.currentStep;
      if (currentStep === undefined || !activePresentationRef.current?.totalSteps ||
          currentStep >= activePresentationRef.current?.totalSteps) {
        goToNextPage();
      } else {
        // OTHERWISE, GO TO NEXT STEP
        dispatch({
          type: 'setActiveSlideByPresentationPageNumber',
          payload: {
            presentationPageNumber: activePresentationRef.current?.currentPresentablePage.presentationPageNumber,
            step: currentStep + 1,
          },
        });
      }
    }
  }

  function goToPrevStepPage(): void {
    logger.debug('Entered goToPreviousStepPage: ', activePresentationRef.current);
    if (activePresentationRef.current?.currentPresentablePage) {
      // IF WE'RE ON THE FIRST (0) STEP, GO TO THE PREVIOUS SLIDE
      const currentStep = activePresentationRef.current?.currentStep;
      if (!currentStep) {
        goToPrevPage();
      } else {
        // OTHERWISE, GO TO THE PREVIOUS STEP
        dispatch({
          type: 'setActiveSlideByPresentationPageNumber',
          payload: {
            presentationPageNumber: activePresentationRef.current?.currentPresentablePage.presentationPageNumber,
            step: currentStep - 1,
          },
        });
      }
    }
  }

  const contextValue: ContentType = {
    activePresentation,
    changeActivePresentation,
    addPresentation,
    presentations,
    contentPresented,
    contentPageData,
    addContentPresented,
    updateContentPresented,
    removePresentation,
    setActiveSlideByPresentationPageNumber,
    setCurrentPPZCoords,
    initPresentation,
    nextStepPage,
    setContentPresented,
    prevStepPage,
  };

  return (
    <ContentContext.Provider value={contextValue}>
      {children}
    </ContentContext.Provider>
  )
}

export const ContentContext = createContext<ContentType>(null!);
ContentContext.displayName = 'ContentContext';
ContentProvider.displayName = 'ContentProvider';

export function useContent() {
  const context = useContext(ContentContext)
  if (!context) {
    throw new Error('useContent must be used within the ContentProvider')
  }
  return context;
}

export default ContentProvider;
