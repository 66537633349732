import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { API, graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import { DNABox, DNAButton, DNAText, DNADivider, Iffy, util, useToast, DNAChip, DNAContextMenu } from '@alucio/lux-ui/src';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import { useDispatch } from 'src/state/redux';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import DNAFileShareModal, { ShareVariantOptions } from 'src/components/DNA/Modal/DNAFileShareModal/DNAFileShareModal';
import { useCurrentUser } from 'src/state/redux/selector/user';
import { SHARE_METHOD } from 'src/components/DNA/Modal/DNAFileShareModal/state/context/DNAFileShareModalStateProvider';
import { useEmailTemplateListForMSL } from 'src/state/redux/selector/emailTemplate';
import { ContentShareORM, HubORM } from 'src/types/orms';
import { contentShareByContentID } from '@alucio/aws-beacon-amplify/src/graphql/queries';
import { ContentShare } from '@alucio/aws-beacon-amplify/src/models';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import { store } from 'src/state/redux';
import { contentShareActions } from 'src/state/redux/slice/contentShare';
import { useContentShareListByContentId } from 'src/state/redux/selector/contentShare';
import config from '../../../config/app.json'
import DNACommonConfirmation from 'src/components/DNA/Modal/DNACommonConfirmation';


export enum SidebarOptions {
  sharedWithSideBar = 'sharedWithSideBar'
}

interface TabConfig {
  key: string,
  id: SidebarOptions,
  label: string,
  icon: string,
  tooltip: string,
  testID?: string,
}

type RightSideBarProps = {
  hubORM: HubORM,
}

const styles = StyleSheet.create({
  sideBarMenu: {
    width: 56,
    backgroundColor: colors['color-text-white'],
    borderLeftColor: colors['color-gray-80'],
    borderLeftWidth: 1,
  },
  SideBarContent: {
    width: 320,
    backgroundColor: colors['color-text-white'],
    borderLeftColor: colors['color-gray-80'],
    borderLeftWidth: 1,
  },
  chevronIcon: {
    height: 35,
    width: 35,
    marginTop: 8,
    marginBottom: 4,
  },
  sideBarIcon: {
    height: 35,
    width: 35,
    marginTop: 10,
  },
  tooltipText: {
    color: colors['color-text-white'],
  },
  sideBarHeaderText: {
    padding: 18,
  },
  sideBarContentBody: {
    paddingBottom: 20,
  },
  addRecipientBtn: {
    width: 272,
    height: 40,
  },
  shareListContainer: {
    marginHorizontal: 24,
    borderColor: colors['color-gray-100'],
    borderWidth: 1,
    borderRadius: 6,
    padding: 12,
    marginBottom: 16,
  },
  emptyContainer: {
    paddingHorizontal: 24,
  },
  emptyContainerSubText: {
    textAlign: 'center',
    lineHeight: 24,
  },
});

export const tabConfigs: Record<SidebarOptions, TabConfig> = {
  [SidebarOptions.sharedWithSideBar]: {
    key: 'shared-with-button',
    id: SidebarOptions.sharedWithSideBar,
    label: 'Shared With',
    icon: 'account-multiple',
    tooltip: 'Shared With',
    testID: 'edit-hub-sidebar-shared-with',
  },
}

const RightSideBar: React.FC<RightSideBarProps> = (props) => {
  const { hubORM } = props
  
  // returns a list of contentShare for the current hub by hub id
  const contentShareORMs = useContentShareListByContentId(hubORM.model.id)
  const hubSideBarOptions = [SidebarOptions.sharedWithSideBar]
  const [selectedSideBarOption, setSelectedSideBarOption] = useState<SidebarOptions | null>(hubSideBarOptions[0])
  const dispatch = useDispatch()
  const toast = useToast()
  const user = useCurrentUser()
  const emailTemplates = useEmailTemplateListForMSL()
  
  const getExistingContentShareORM = (contentShareId: string) => {
    return contentShareORMs.find(contentShareORM => contentShareORM.model.id === contentShareId)
  }

  const handleOpenShareModalToCopy = (existingContentShareORM: ContentShareORM, hubTitle: string) => {
    const existingLink = {
      expiresAt: format(new Date(existingContentShareORM.model.expiresAt), 'yyyy-MM-dd'),
      id: existingContentShareORM.model.id,
      link: `${config.contentURL}/${existingContentShareORM.model.type}/${existingContentShareORM.model.token}`,
      title: hubTitle,
    }
    if (hubORM) {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: false,
        backdropVisible: true,
        component: (modalProps) => (
          <DNAFileShareModal
            toast={toast}
            shareContent={hubORM}
            currentUser={user}
            emailTemplates={emailTemplates}
            shareMethod={SHARE_METHOD.EMAIL}
            {...modalProps}
            variant={ShareVariantOptions.HUB_SHARE}
            existingShareLinks={existingLink}
          />
        ),
      }))
    }
  }
  
  useEffect(() => {
    const getData = async () => {
      const { data } = await API.graphql(
        graphqlOperation(contentShareByContentID, {
          contentId: hubORM.model.id
        })
      ) as GraphQLResult<{
        contentShareByContentID: {
          items: ContentShare[]
        }
      }>
      if (data?.contentShareByContentID?.items) {
        store.dispatch(contentShareActions.upsert(data.contentShareByContentID.items))
      }
    }
    // TODO better error handling
    getData().catch(err => console.log('error getting data: ', err))
  }, [])

  const toggleSidebar = () => {
    selectedSideBarOption ? setSelectedSideBarOption(null) : setSelectedSideBarOption(hubSideBarOptions[0])
  }
  const disablePopover: 'tabletPWA'[] = ['tabletPWA']

  const handleAddRecipient = () => {
    if (hubORM) {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: false,
        backdropVisible: true,
        component: (modalProps) => (
          <DNAFileShareModal
            toast={toast}
            shareContent={hubORM}
            currentUser={user}
            emailTemplates={emailTemplates}
            shareMethod={SHARE_METHOD.EMAIL}
            {...modalProps}
            variant={ShareVariantOptions.HUB_SHARE}
          />
        ),
      }))
    } else {
      throw Error('Unable to find Hub')
    }
  }

  const getExpirationOrStatusText = (expirationDate: string, revoked?: boolean) => {
    const hasExpired = isAfter(Date.now(), Date.parse(expirationDate))
    if (revoked) return <DNAChip status="danger">REVOKED</DNAChip>
    else if (hasExpired) {
      return (
        <DNAChip status="danger" appearance="subtle">
          {`EXPIRED ON ${format(Date.parse(expirationDate), 'L/d/yy')}`}
        </DNAChip>
      )
    } else {
      return (
        <DNAText status="primary">
          {`Expires on ${format(Date.parse(expirationDate), 'L/d/yy')}`}
        </DNAText>
      )
    }
  }

  const handleRevoke = async (contentShare: ContentShare) => {
    dispatch(
      DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: () => (<DNACommonConfirmation
          status="danger"
          cancelText="Cancel"
          confirmActionText="Revoke"
          onConfirmAction={() => dispatch(contentShareActions.revokeContentShare({ contentShare }))}
          title="Revoke access to hub?"
          descriptionText={`This will revoke access to this hub for this generated link. \n\n This action cannot be undone.`}
        />),
    }));
  }

  return (
    <DNABox
      testID="edit-hub-side-bar"
      appearance="row"
      style={util.mergeStyles(
        undefined,
        [{ width: 376, borderWidth: 0 }, !!selectedSideBarOption],
      )}
    >
      {/* Side Bar Content */}
      <DNABox appearance="row">
        <Iffy is={!!selectedSideBarOption}>
          <DNABox as={ScrollView} style={ styles.SideBarContent } fill childFill={2} appearance="col">
            <DNABox testID="side-bar-header-text" appearance="col" spacing="md" style={ styles.sideBarHeaderText}>
              <DNAText testID="side-bar-text" h4>
                {selectedSideBarOption ? tabConfigs[selectedSideBarOption]?.label : ''}
              </DNAText>
              <DNADivider />
            </DNABox>
            <DNABox alignY="center" alignX="center" style={ styles.sideBarContentBody }>
              <DNAButton
                iconLeft="plus"
                onPress={() => handleAddRecipient()}
                appearance="outline"
                status="tertiary"
                style={styles.addRecipientBtn}
                >
                Add recipient
              </DNAButton>
            </DNABox>
            <DNABox fill>
              <Iffy is={!contentShareORMs?.length}>
                <DNABox appearance="col" alignX="center" alignY="center" spacing="md" fill style={styles.emptyContainer}>
                  <DNAText status="subtle" bold>
                    Share hub by adding recipients
                  </DNAText>
                  <DNAText status="subtle" numberOfLines={3} style={styles.emptyContainerSubText}>
                    Each recipient will have a unique share link that will allow you to track their activity.
                  </DNAText>
                </DNABox>
                </Iffy>
              <Iffy is={contentShareORMs?.length}>
                <DNABox fill appearance="col">
                {contentShareORMs?.map(contentShareORM => {
                  return (
                    <DNABox
                      key={contentShareORM.model.id}
                      appearance="col"
                      style={styles.shareListContainer}
                      spacing="sm" keepSpacingLastItem
                    >
                      <DNABox>
                        {contentShareORM.model.customValues.map(customValue => {
                          return (
                            <DNAText
                              key={customValue.fieldId}
                              status="dark"
                              b1
                              bold
                              numberOfLines={1}
                            >
                              {customValue.values}
                            </DNAText>
                          )
                        })}
                      </DNABox>
                      <DNABox appearance="row" spacing="md">
                        <DNABox appearance="col" spacing="sm">
                          <DNAText status="flat">Link generated on {format(Date.parse(contentShareORM.model.createdAt), 'L/d/yy')}</DNAText>
                          <DNABox childFill>
                            {getExpirationOrStatusText(contentShareORM.model.expiresAt, contentShareORM.model.revoked)}
                          </DNABox>
                        </DNABox>
                        <Iffy is={!contentShareORM.model.revoked && !isAfter(Date.now(), Date.parse(contentShareORM.model.expiresAt))}>
                          <DNABox alignY="center">
                            <DNAButton
                              iconLeft="share"
                              testID="share-icon"
                              status="gray"
                              appearance="ghostLink"
                              padding="none"
                              size="md"
                              style={styles.sideBarIcon}
                              onPress={() => {
                                const existingContentShareORM = getExistingContentShareORM(contentShareORM.model.id)
                                if (existingContentShareORM) {
                                  handleOpenShareModalToCopy(existingContentShareORM, hubORM.model.name || '')
                                }
                              }}
                            />
                            <DNAContextMenu>
                              <DNAContextMenu.Anchor>
                                <DNAButton
                                  iconRight="dots-vertical"
                                  status="gray"
                                  appearance="ghostLink"
                                  padding="none"
                                  size="md"
                                  style={styles.sideBarIcon}
                                />
                              </DNAContextMenu.Anchor>
                              <DNAContextMenu.Items>
                                <DNAContextMenu.Item
                                  title="Revoke"
                                  icon='undo-variant'
                                  onPress={() => handleRevoke(contentShareORM.model)}
                                />
                              </DNAContextMenu.Items>
                            </DNAContextMenu>
                          </DNABox>
                        </Iffy>
                      </DNABox>
                    </DNABox>
                  )
                })}
                </DNABox>
              </Iffy>
            </DNABox>
          </DNABox>
        </Iffy>
      </DNABox>
      {/* Side Bar Menu */}
      <DNABox
        appearance="col"
        spacing="between"
        alignX="center"
        style={ styles.sideBarMenu }
      >
        <DNABox spacing="xs" appearance="col">
          {/* Chevron Icon */}
          <DNAPopover placement="left" disablePopover={disablePopover}>
            <DNAPopover.Anchor>
              <DNAButton
                status="tertiary"
                appearance="ghostLink"
                style={styles.chevronIcon}
                iconLeft={!selectedSideBarOption ? 'chevron-left' : 'chevron-right'}
                onPress={toggleSidebar}
                size="md"
              />
            </DNAPopover.Anchor>
            <DNAPopover.Content>
              <DNAText
                style={styles.tooltipText}
                numberOfLines={1}
              >
                {selectedSideBarOption ? 'Collapse' : 'Expand'}
              </DNAText>
            </DNAPopover.Content>
          </DNAPopover>
          <DNADivider />
          {hubSideBarOptions.map(config => {
            const item = tabConfigs[config]
            return (item &&
              <DNAPopover placement="left" key={item.key} disablePopover={disablePopover}>
                <DNAPopover.Anchor>
                  <DNAButton
                    appearance={config === selectedSideBarOption ? 'filled' : 'ghost'}
                    status={config === selectedSideBarOption ? 'primary' : 'tertiary'}
                    style={styles.sideBarIcon}
                    iconLeft={item.icon}
                    onPress={() => setSelectedSideBarOption(item.id)}
                    size="md"
                  />
                </DNAPopover.Anchor>
                <DNAPopover.Content>
                  <DNAText style={styles.tooltipText} numberOfLines={1}>{item?.tooltip}</DNAText>
                </DNAPopover.Content>
              </DNAPopover>)
          })}
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

export default RightSideBar
