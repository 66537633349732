import { UseFormMethods } from 'react-hook-form';
import { FormValuesType } from '../CustomFields/ComposableForm';
import { CrmIntegrationType } from '@alucio/aws-beacon-amplify/src/models';
import { GLOBAL_FORM_ERROR } from './AddMeetingProvider';
import { Singleton as IndexDbCrm } from 'src/classes/CRM/CRMIndexedDB'

type canSubmitToCRMType = (
    values: FormValuesType,
    rhForm: UseFormMethods<FormValuesType>,
    setErrorMessage: (value: React.SetStateAction<string>) => void,
    crmIntegrationType: CrmIntegrationType | 'VEEVA' | 'SALESFORCE' | undefined,
) => Promise<boolean>;

const integrationTypeValidation : Record<CrmIntegrationType, canSubmitToCRMType> = {
  [CrmIntegrationType.VEEVA]: async (values, rhForm, setErrorMessage) => {
    if (!values.primaryAttendee?.length) {
      rhForm.setError('primaryAttendee', { message: 'This field is required' });
      setErrorMessage(GLOBAL_FORM_ERROR.too_small);
      return false;
    }
    if (
      Array.isArray(values.primaryAttendee) &&
        Array.isArray(values.additionalAttendees)
    ) {
      const primaryAttendeeIds = values.primaryAttendee.map((attendee) => attendee.crmAccountId)
      const additionalAttendeeIds = values.additionalAttendees.map((attendee) => attendee.crmAccountId)

      const attendedeesIds = [...primaryAttendeeIds, ...additionalAttendeeIds]

      const additionalAttendeesAccounts = await IndexDbCrm.filterById('ACCOUNT', attendedeesIds)

      const isAtleastOnePersonalAccount = additionalAttendeesAccounts
        ?.some((account) => account['IsPersonAccount']);

      if (!isAtleastOnePersonalAccount) {
        rhForm.setError('additionalAttendees',
          { message: 'This field is required' },
        );
        setErrorMessage(GLOBAL_FORM_ERROR.too_small);
        return false;
      }
    }

    return true;
  },
  [CrmIntegrationType.SALESFORCE]: async (_values, _rhForm, _setErrorMessage, _crmIntegrationType) => {
    return true;
  },
};

export const canSubmitToCRM: canSubmitToCRMType = async (
  values,
  rhForm,
  setErrorMessage,
  crmIntegrationType,
): Promise<boolean> => {
  if (!crmIntegrationType) {
    throw new Error('CRM Integration Type is not defined');
  }

  return integrationTypeValidation[crmIntegrationType](values, rhForm, setErrorMessage, crmIntegrationType);
};
