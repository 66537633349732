import React, { useCallback } from 'react'
import { StyleSheet, ScrollView } from 'react-native'
import {
  DNABox,
  DNAButton,
  DNAText,
  DNASlider,
  Iffy,
  DNAChip,
} from '@alucio/lux-ui'
import { Active } from '@dnd-kit/core'

import CloneBuilder, { POOL_CONTAINER_ID } from 'src/components/DnD/Clone/CloneBuilder'
import SlidesGroupPool from 'src/components/DnD/Clone/PublisherGroups/SlidesGroupPool'
import SlidesGroupTarget from 'src/components/DnD/Clone/PublisherGroups/SlidesGroupTarget'
import SlidesGroupOverlay from 'src/components/DnD/Clone/PublisherGroups/SlidesGroupOverlay'
import { ActionBar, GroupHeader } from './GroupHeader'

import { useSlideSettings } from 'src/screens/Publishers/Versioning/SlideSettings/SlideSettings'
import { SlideSettingsStateProvider } from 'src/screens/Publishers/Versioning/SlideSettings/SlideSettings.proxy'
import useGroups, { GroupsProvider } from './GroupsProviders'
import { GroupStateProvider } from './GroupProvider.proxy'

import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'

const S = StyleSheet.create({
  poolContainer: {
    flex: 1,
    paddingHorizontal: 64,
    paddingVertical: 36,
  },
  targetContainer: {
    backgroundColor: colors['color-gray-80'],
  },
  targetSlidesHeader: {
    borderColor: colors['color-gray-80'],
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    height: 50,
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
})

const GroupModal: React.FC = () => {
  const { send, cond } = useSlideSettings()

  const {
    isExpanded,
    addGroupHandler,
    showEmptyMessage,
    groups,
    toggleExpanded,
    collapsedGroups,
    toggleCollapsedGroups,
    poolItemIds,
    groupItemIds,
    selectedPageIds,
  } = useGroups()

  const onDragEndChanged = useCallback(
    (groupItems) => send({ type: 'SYNC_GROUPS', payload: { groupItems } }),
    [send],
  )

  const onDragStart = useCallback(
    (active: Active) => {
      if (active.data.current?.containerId === POOL_CONTAINER_ID)
      { send({
        type: 'UPDATE_GROUP_SLIDES',
        payload: {
          scope: active.data.current?.itemId,
          forceActive: true,
        },
      }) }
    },
    [],
  )

  return (
    <DNASlider
      visible={cond.mode.groupSlides}
      setVisible={() => send({ type: 'BACK_TO_IDLE' })}
      orientation="horizontal"
    >
      <GroupStateProvider value={useGroups()}>
        <SlideSettingsStateProvider value={useSlideSettings()}>
          <CloneBuilder
            poolItems={poolItemIds}
            targetItems={groupItemIds}
            selectedItemIds={selectedPageIds}
            onDragEndChanged={onDragEndChanged}
            onDragStart={onDragStart}
          >
            <DNABox
              fill
              appearance="col"
              style={{ backgroundColor: colors['color-text-white'] }}
            >
              {/* HEADER */}
              <GroupHeader />

              {/* CONTENT */}
              <DNABox fill appearance="row">
                {/* LEFT SIDE */}
                <DNABox
                  fill
                  appearance="col"
                  style={{ display: isExpanded ? 'none' : 'flex' }}
                >
                  <ActionBar />
                  <ScrollView contentContainerStyle={S.poolContainer}>
                    <SlidesGroupPool />
                  </ScrollView>

                </DNABox>

                {/* RIGHT SIDE */}
                <DNABox
                  appearance="col"
                  fill
                  style={{ maxWidth: !isExpanded ? 600 : undefined }}
                >
                  <DNABox style={S.targetSlidesHeader}>
                    {/* GROUP ACTION BAR */}
                    <DNABox spacing="between" fill>
                      {/* GROUP COUNT */}
                      <DNABox alignY="center" spacing="xs">
                        <DNAText status="flatAlt" bold>
                          Groups
                        </DNAText>
                        <DNAChip
                          appearance="subtle"
                          status="basic"
                          size="md"
                        >
                          {`${groups.length}`}
                        </DNAChip>
                      </DNABox>

                      {/* ACTION ITEMS */}
                      <DNABox appearance="row" spacing="sm">
                        {/* CREATE */}
                        <DNAButton
                          appearance="outline"
                          status="tertiary"
                          size="xs"
                          onPress={() => addGroupHandler()}
                          testID="create-group-btn"
                        >
                          Create group
                        </DNAButton>
                        {/* COLLAPSE */}
                        <DNAButton
                          appearance="outline"
                          status="tertiary"
                          size="xs"
                          onPress={() => toggleCollapsedGroups()}
                        >
                          {
                            Object
                              .values(collapsedGroups)
                              .some(val => !val)
                              ? 'Collapse groups'
                              : 'Expand groups'
                          }
                        </DNAButton>
                        {/* EXPAND */}
                        <DNAButton
                          appearance="outline"
                          status="tertiary"
                          size="xs"
                          onPress={toggleExpanded}
                        >
                          { !isExpanded ? 'Expand canvas' : 'Collapse canvas'}
                        </DNAButton>
                      </DNABox>
                    </DNABox>
                  </DNABox>

                  <Iffy is={showEmptyMessage}>
                    <DNABox style={S.targetContainer} fill alignX="center" alignY="center">
                      <DNAText>Create a group to get started</DNAText>
                    </DNABox>
                  </Iffy>

                  <Iffy is={!showEmptyMessage}>
                    <DNABox fill style={S.targetContainer}>
                      <SlidesGroupTarget />
                    </DNABox>
                  </Iffy>
                </DNABox>
              </DNABox>
            </DNABox>

            {/* DRAG OVERLAY */}
            <SlidesGroupOverlay />
          </CloneBuilder>
        </SlideSettingsStateProvider>
      </GroupStateProvider>
    </DNASlider>
  )
}

const Groups: React.FC = () => {
  return (
    <GroupsProvider>
      <GroupModal />
    </GroupsProvider>
  )
}

export default Groups
