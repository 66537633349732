import { InputConfig } from '@alucio/lux-ui/lib/hooks/useInputConfig/useInputConfig'

const getInputConfig = (
  onSubmit: () => void,
) => {
  const inputConfig: InputConfig[] = [
    {
      keyCodes: ['Enter'],
      callback: onSubmit,
    },
  ]
  return inputConfig
}

export default getInputConfig
