import { useMemo } from 'react';
import {
  CustomFieldDefinition, FieldDataType,
  FieldPosition,
} from '@alucio/aws-beacon-amplify/src/models';
import { multiTypeSort } from 'src/state/redux/selector/common';

// since customfields now have a fieldPosition, we need to sort them
// into left and right fields so that they can be displayed correctly
// the default is left, so if the fieldPosition is not set, it will be
// displayed on the left
export const useFormatCustomFields = (customFields : CustomFieldDefinition[] ) => {
  const fields = useMemo(() => {
    const [leftFields, rightFields] = customFields.reduce<[CustomFieldDefinition[], CustomFieldDefinition[]]>(
      (acc, customField) => {
        const [leftFields, rightFields] = acc
        customField.settings?.fieldPosition === FieldPosition.LEFT || customField.fieldType === FieldDataType.OBJECT ||
          !customField.settings || !customField.settings.fieldPosition
          ? leftFields.push(customField)
          : rightFields.push(customField)

        return acc
      },
      [[], []],
    )

    const leftCustomFields = leftFields.sort((a, b) => multiTypeSort(a.order, b.order, { dir: 'asc' }))
    const rightCustomFields = rightFields.sort((a, b) => multiTypeSort(a.order, b.order, { dir: 'asc' }))

    return { leftCustomFields, rightCustomFields }
  }, [customFields])

  return fields
}

export const useFieldPositions = (customFields : CustomFieldDefinition[]) : string[] =>
{
  const fieldPositions = customFields.filter(p => !p.isChildField).reduce((acc : string[], field) => {
    const position = field.settings?.fieldPosition || FieldPosition.LEFT
    if (!acc.includes(position)) {
      acc.push(position)
    }
    return acc
  }, [] )

  return fieldPositions
}
