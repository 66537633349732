import React, { Dispatch, Fragment, useEffect, useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'
import {
  DNABox,
  DNAButton,
  DNACard,
  DNAChip,
  DNAIcon,
  DNAText,
  Iffy,
} from '@alucio/lux-ui'
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import { CustomDeckORM, DocumentVersionORM, FolderItemORM } from 'src/types/types'
import format from 'date-fns/format'
import { useDispatch } from 'src/state/redux'
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import folderQuery from 'src/state/redux/folder/query'
import {
  DocumentVersionUpdateInfo,
  DocumentVersionUpdateInfoPath,
  useAllFoldersUpdatedDocsMap,
} from 'src/state/redux/selector/folder'
import { folderActions } from 'src/state/redux/slice/folder'
import {
  BindCustomDeckContextActions,
  useDNACustomDeckActions,
} from 'src/components/DNA/Document/DNACustomDeck.actions'
import { isCustomDeckORM } from 'src/types/typeguards'
import truncate from 'lodash/truncate'
import { customDeckActions } from 'src/state/redux/slice/customDeck'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { useAppSettings } from 'src/state/context/AppSettings'
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList'

const S = StyleSheet.create({
  documentListHeader: { backgroundColor: colors['color-gray-10'] },
  documentListRow: { height: 48, padding: 14 },
  documentListRowBorder: { borderTopColor: '#EBEBEB', borderTopWidth: 1 },
  documentContainerCard: { padding: 24 },
  lastRow: {
    borderRadius: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  pathDivider: {
    marginHorizontal: 8,
  },
  thumbnailContainer: {
    borderWidth: 1,
    borderColor: colors['color-gray-80'],
  },
  updatesContainer: {
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: colors['color-gray-100'],
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: colors['color-text-white'],
  },
})

const MAX_FOLDER_LENGTH = 40
const MAX_TITLE_LENGTH = 65

interface DocumentInfoProps {
  documentVersionORM: DocumentVersionORM
}
const DocumentInfo = (props: DocumentInfoProps) => {
  const { documentVersionORM } = props

  return (
    <DNABox appearance="col" fill>
      <DNABox appearance="row" childFill={1} spacing="md">
        <DNABox style={S.thumbnailContainer} alignY="center">
          <DNADocumentThumbnail
            documentVersionORM={documentVersionORM}
            height={58}
            width={104}
          />
        </DNABox>
        <DNABox appearance="col" spacing="xs" childFill={0} alignY="center">
          <DNAText
            numberOfLines={2}
            status="flatAlt"
          >{documentVersionORM.model.title}
          </DNAText>
          <DNABox spacing="sm">
            <CustomFieldBadgeList documentVersionORM={documentVersionORM} />
          </DNABox>
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

type ButtonsActionsType = {
  [key in keyof typeof FolderItemStatus]: {
    label: string,
    action?: (
      dispatch: Dispatch<any>,
      itemsInfo: DocumentVersionUpdateInfo[],
      customDeckEditorActions?: BindCustomDeckContextActions) => void,
  }
}
const ItemsActions: ButtonsActionsType = {
  AutoUpdated: {
    label: 'Acknowledge',
    action: (dispatch, itemsInfo) => {
      const folderItemORMs: FolderItemORM[] = []
      const customDeckORMs: CustomDeckORM[] = []
      itemsInfo.map(obj => {
        if (isCustomDeckORM(obj.folderItem.relations.itemORM)) {
          customDeckORMs.push(obj.folderItem.relations.itemORM)
        }
        else {
          folderItemORMs.push(obj.folderItem)
        }
      })
      dispatch(folderActions.acknowledgeAutoUpdate(folderItemORMs))
      dispatch(customDeckActions.acknowledgeAutoUpdate(customDeckORMs))

      // Update aux objects status
      itemsInfo.forEach(item => {
        item.status = FolderItemStatus.Acknowledged
      })
    },
  },
  Acknowledged: { label: 'Acknowledged' },
  Outdated: {
    label: 'Update',
    action: (dispatch, itemsInfo) => {
      const folderItemORMs: FolderItemORM[] = []
      const customDeckORMs: CustomDeckORM[] = []
      itemsInfo.map(obj => {
        if (isCustomDeckORM(obj.folderItem.relations.itemORM)) {
          customDeckORMs.push(obj.folderItem.relations.itemORM)
        }
        else {
          folderItemORMs.push(obj.folderItem)
        }
      })

      dispatch(folderActions.updateDocumentVersion(folderItemORMs, false))
      dispatch(customDeckActions.updateDocumentVersion(customDeckORMs))

      // Update aux objects status
      itemsInfo.forEach(item => {
        item.status = FolderItemStatus.Updated
      })
    },
  },
  Updated: { label: 'Updated' },
  Review: {
    label: 'Review',
    action: (_, itemsInfo, customDeckEditorActions) => {
      const item = itemsInfo[0]
      if (isCustomDeckORM(item.folderItem.relations.itemORM)) {
        const customDeckORM = item.folderItem.relations.itemORM
        customDeckEditorActions?.edit(customDeckORM, item.folderItem, 'REVIEW')()
      }
    },
  },
}

interface UpdatedDocumentsListRowProps {
  updateInfo: DocumentVersionUpdateInfo,
  setFolderItems: React.Dispatch<React.SetStateAction<DocumentVersionUpdateInfo[]>>,
}

const UpdatedDocumentsListRow: React.FC<UpdatedDocumentsListRowProps> = (props) => {
  const { updateInfo, setFolderItems } = props
  const { deviceMode } = useAppSettings()

  const dispatch = useDispatch()
  const customDeckActions = useDNACustomDeckActions()

  const handleAction = () => {
    ItemsActions[updateInfo.status].action?.(dispatch, [updateInfo], customDeckActions)

    // Item status was updated in action, now we update the array reference
    setFolderItems(items => [...items])
  }

  const showSuccessText = [FolderItemStatus.Acknowledged, FolderItemStatus.Updated]
    .includes(updateInfo.status)

  return (
    <DNABox style={[S.documentListRow, S.documentListRowBorder]} alignY="center">
      <DNABox fill>
        {updateInfo.path.map((path, idx) => (<Fragment key={`${path}_${idx}`}>
          <Iffy is={idx > 0}>
            <DNAText style={S.pathDivider}>/</DNAText>
          </Iffy>
          <Iffy is={path.isModified}>
            <DNAChip appearance="tag" style={{ marginRight: 8 }}>MODIFIED</DNAChip>
          </Iffy>
          <DNAText numberOfLines={1}>
            {truncate(path.title, { length: idx < updateInfo.path.length - 1 ? MAX_FOLDER_LENGTH : MAX_TITLE_LENGTH })}
          </DNAText>
        </Fragment>))
        }
      </DNABox>
      <Iffy is={showSuccessText}>
        <DNABox alignY="center">
          <DNAIcon.Styled
            name="check-bold"
            appearance="ghost"
            status="success"
          />
          <DNAText status="success" style={{ fontSize: 14 }}>
            {ItemsActions[updateInfo.status].label}
          </DNAText>
        </DNABox>
      </Iffy>
      <Iffy is={!showSuccessText}>
        <DNAButton
          size={ deviceMode === 'desktop' ? 'xs' : 'md' }
          status={updateInfo.status === 'Review' ? 'warning' : 'tertiary'}
          iconLeft={updateInfo.status === 'Review' ? 'alert' : ''}
          appearance="outline"
          onPress={handleAction}
        >
          {ItemsActions[updateInfo.status].label}
        </DNAButton>
      </Iffy>
    </DNABox>
  )
}

interface UpdatedDocumentsListProps {
  updates: DocumentVersionUpdateInfo[],
}
const UpdatedFolderItemsList = (props: UpdatedDocumentsListProps) => {
  const [folderItems, setFolderItems] = useState(props.updates)
  const { deviceMode } = useAppSettings()
  const hasManyInStatus = (items: DocumentVersionUpdateInfo[], status: FolderItemStatus) =>
    items.filter(upd => upd.status === status).length > 1

  const [showAcknowledgeAll, setShowAcknowledgeAll] = useState(
    hasManyInStatus(props.updates, FolderItemStatus.AutoUpdated))
  const [showUpdateAll, setShowUpdateAll] = useState(
    hasManyInStatus(props.updates, FolderItemStatus.Outdated))
  const dispatch = useDispatch()

  useEffect(() => {
    // Check current status in list and update batch buttons
    setShowAcknowledgeAll(hasManyInStatus(folderItems, FolderItemStatus.AutoUpdated))
    setShowUpdateAll(hasManyInStatus(folderItems, FolderItemStatus.Outdated))
  }, [folderItems])

  const handleBatchAction = (action: 'acknowledge' | 'update') => {
    const itemsToUpdate: DocumentVersionUpdateInfo[] = []

    // Get array with affected items
    folderItems.forEach(item => {
      const isAcknowledge = action === 'acknowledge' && item.status === FolderItemStatus.AutoUpdated
      const isUpdate = action === 'update' && item.status === FolderItemStatus.Outdated
      if ((isAcknowledge || isUpdate)) {
        itemsToUpdate.push(item)
      }
    })

    // We only support batch actions for Acknowledge and Update
    const targetStatus = action === 'acknowledge'
      ? FolderItemStatus.AutoUpdated
      : FolderItemStatus.Outdated

    // Execute selected action on affected items
    ItemsActions[targetStatus].action?.(dispatch, itemsToUpdate)

    // Hide the batch action button
    if (action === 'acknowledge') {
      setShowAcknowledgeAll(false)
    }
    else {
      setShowUpdateAll(false)
    }

    // Finally update array reference
    setFolderItems(folderItems)
  }

  return (
    <DNACard appearance="outline">
      <DNABox style={[S.documentListHeader, S.documentListRow]} alignY="center" spacing="between">
        <DNAText status="flatAlt">
          File Location
        </DNAText>
        <Iffy is={showAcknowledgeAll || showUpdateAll}>
          <DNAButton
            size={deviceMode === 'desktop' ? 'xs' : 'md' }
            status="secondary"
            appearance="outline"
            onPress={() => handleBatchAction(showAcknowledgeAll ? 'acknowledge' : 'update')}
          >
            {showAcknowledgeAll ? 'Acknowledge all' : 'Update all'}
          </DNAButton>
        </Iffy>
      </DNABox>
      {
        folderItems.map((item, idx) => (
          <UpdatedDocumentsListRow
            key={`${item.folderItem.model.id}_${idx}`}
            updateInfo={item}
            setFolderItems={setFolderItems}
          />
        ))
      }
    </DNACard>
  )
}

interface DocumentUpdatedCardProps {
  documentVersionORM?: DocumentVersionORM,
  isLast?: boolean,
  updates: DocumentVersionUpdateInfo[],
}
const DocumentUpdatedCard: React.FC<DocumentUpdatedCardProps> = (props) => {
  const { documentVersionORM, isLast, updates } = props

  // Formatting date example: August 11, 2021, 1:13 PM
  const modifiedDate = format(documentVersionORM?.model.updatedAt
    ? new Date(documentVersionORM?.model.updatedAt) : new Date(), 'MMMM dd, yyyy, h:mm a')
  const changeType = documentVersionORM?.model.changeType?.toLowerCase()

  const docStatusMessage: string = documentVersionORM
    ? updates[0].status === FolderItemStatus.AutoUpdated
      ? `This file was auto-updated to a new version on ${modifiedDate} with ${changeType} changes.`
      : `${changeType === 'major' ? 'Major' : 'Minor'} ` +
        `changes were made to this new version on ${modifiedDate}.`
    : 'The source files of the following personalized files are no longer available.'

  return (
    <DNACard
      appearance="flat"
      style={[S.documentContainerCard, isLast && S.lastRow]}
    >
      <DNABox spacing="md" appearance="col">
        <DNAText status="flatAlt" bold>
          {docStatusMessage}
        </DNAText>
        <Iffy is={documentVersionORM}>
          <DNABox>
            <DocumentInfo documentVersionORM={documentVersionORM!} />
          </DNABox>
        </Iffy>
        <DNABox>
          <UpdatedFolderItemsList updates={updates} />
        </DNABox>
      </DNABox>
    </DNACard>
  )
}

enum FolderItemStatus {
  AutoUpdated = 'AutoUpdated',
  Acknowledged = 'Acknowledged',
  Outdated = 'Outdated',
  Updated = 'Updated',
  Review = 'Review'
}

const getPathString = (items: DocumentVersionUpdateInfoPath[]) =>
  items.map(x => x.title).join(' / ')

const DocumentsUpdates = (props) => {
  const currentActiveFilter = useMemo(() => folderQuery.merge(folderQuery.filters.active), [])
  const updatedDocumentsMap = useAllFoldersUpdatedDocsMap(currentActiveFilter)
  const [documents, setDocuments] = useState<[DocumentVersionORM, DocumentVersionUpdateInfo[]][]>([])

  useEffect(() => {
    // Load data only once so we don't lose current working state after dispatching an action

    // Order from most recent to older
    const array = Array.from(updatedDocumentsMap)
    array.sort((a, b) => a[0] && b[0]
      ? (a[0].model.updatedAt < b[0].model.updatedAt ? 1 : -1)
      : (a[0] ? 1 : (b[0] ? -1 : 0)))

    // Order folders
    array.forEach(entry => {
      entry[1].sort((a, b) => (getPathString(a.path) > getPathString(b.path)) ? 1 : -1)
    })

    setDocuments(array)
  }, [])

  return (
    <DNABox appearance="col" spacing="md" style={!props.isTablet && S.updatesContainer}>
      <Iffy is={documents.length === 0}>
        <DNACard appearance="flat" style={{ borderRadius: 4 }}>
          <DNAEmpty emptyVariant={EmptyVariant.FolderUpdatesEmpty} />
        </DNACard>
      </Iffy>
      <Iffy is={documents.length > 0}>
        {documents.map((item, idx) =>
          (<DocumentUpdatedCard
            isLast={idx === documents.length - 1}
            key={item[0]?.model?.id ?? '-1'}
            documentVersionORM={item[0]}
            updates={item[1]}
          />),
        )}
      </Iffy>
    </DNABox>
  )
}

export default DocumentsUpdates
