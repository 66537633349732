import React, { forwardRef } from 'react';
import { GroupDraftStatus, ReplaceGroupDraft } from './FindAndReplace';
import { DNABox, DNAButton, DNAChip, DNAIcon, DNAText, Iffy, Stack, util, useWindowSize } from '@alucio/lux-ui';
import { Pressable, StyleSheet } from 'react-native';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { DocumentAccessLevel, Page } from '@alucio/aws-beacon-amplify/src/models';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector';
import { DocumentVersionORM } from 'src/types/orms';

const styles = StyleSheet.create({
  groupChildWrapper: {
    alignSelf: 'flex-start',
    height: '100%',
  },
  groupWrapper: {
    paddingHorizontal: 17,
    paddingVertical: 17,
  },
  thumbnail: {
    backgroundColor: 'lightgrey',
    borderColor: colors['color-text-white'],
    borderRadius: 3,
    borderWidth: 3,
    height: 124,
    width: 220,
  },
  thumbnailWrapper: {
    borderColor: colors['color-gray-100'],
    borderRadius: 6,
    borderWidth: 3,
  },
  thumbIndividualOverlay: {
    borderColor: colors['color-warning-500'],
    borderRadius: 6,
    borderWidth: 3,
    maxHeight: 130,
  },
  thumbOverlay: {
    borderRadius: 3,
    backgroundColor: colors['color-gray-transparent-40'],
    flex: 1,
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  trashCanStack: {
    alignItems: 'flex-end',
    padding: 9,
    width: '100%',
  },
  selectedReplaceThumbnail: {
    borderColor: colors['color-warning-500'],
    borderStyle: 'dashed',
  },
  replaceThumbnail: {
    backgroundColor: colors['color-text-white'],
    height: 130,
    minWidth: 226,
  },
  removeIcon: {
    color: colors['color-text-white'],
    height: 18,
    width: 16,
  },
  removeIconWrapper: {
    height: 32,
    width: 32,
    backgroundColor: colors['color-gray-transparent-40'],
    borderRadius: 3,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

interface GroupRowProps {
  isActiveGroup: boolean;
  currentIndexCount: number;
  group: ReplaceGroupDraft;
  onRemoveGroup: (groupId: string) => void;
  onAcceptDeletedGroup: (groupId: string) => void;
  onSelectGroup: (groupId: string) => void;
  slidesThumbnails: { [key: string]: string };
  onUndoRemoval: (groupId: string) => void;
}

interface ReplacementGroupProps {
  isActiveGroup: boolean;
  group: ReplaceGroupDraft;
  onActionPressed: () => void;
  slidesThumbnails: { [key: string]: string };
}

interface PageComponentProps {
  selectedWrapper?: boolean;
  page: Page;
  documentVersionORM?: DocumentVersionORM;
  isFromPersonalDocument?: boolean;
  hideThumbnail?: boolean;
  number?: number;
  thumbnail?: string;
  warningWrapper?: boolean;
  visible: boolean;
}

enum DOC_STATUS {
  DELETED = 'unavailable',
  REVOKED = 'revoked',
  ARCHIVED = 'archived',
}

const GroupRow = forwardRef<typeof DNABox, GroupRowProps>(({
  group,
  onSelectGroup,
  onUndoRemoval,
  onAcceptDeletedGroup,
  currentIndexCount,
  slidesThumbnails,
  isActiveGroup,
  onRemoveGroup,
}, ref) => {
  const {
    status,
    documentVersionORM,
    groupId,
    isGroup,
    pages,
    visible,
  } = group
  const { width } = useWindowSize()
  const isUnavailableDocument =
    [GroupDraftStatus.DELETED, GroupDraftStatus.REVOKED, GroupDraftStatus.ARCHIVED].includes(status);
  const useThumbnailOverlay = isUnavailableDocument || status === GroupDraftStatus.GROUP_REMOVED;
  const warningWrapper = useThumbnailOverlay || status === GroupDraftStatus.MAJOR_UPDATE;
  const displayTrashCan = !useThumbnailOverlay && status !== GroupDraftStatus.ACTIVE;
  const isFromPersonalDocument =
    documentVersionORM?.relations.documentORM.model.accessLevel === DocumentAccessLevel.USER;

  const onAction = (): void => {
    if ([GroupDraftStatus.MAJOR_UPDATE, GroupDraftStatus.REPLACED].includes(status)) {
      onSelectGroup(groupId);
    } else if (status === GroupDraftStatus.GROUP_REMOVED) {
      onUndoRemoval(groupId);
    } else if (isUnavailableDocument) {
      onAcceptDeletedGroup(groupId);
    }
  };

  const isActiveOrRemovedGroup = [GroupDraftStatus.ACTIVE, GroupDraftStatus.GROUP_REMOVED].includes(status)

  return (
    <DNABox
      spacing={width && width < 1050 ? 'xs' : 'md'}
      alignY="center"
      alignX="center"
      childStyle={[1, styles.groupChildWrapper]}
      ref={ref}
    >
      <Pressable
        onPress={onAction}
        disabled={isActiveOrRemovedGroup}
      >
        <DNABox
          spacing="md"
          appearance="col"
          style={util.mergeStyles(undefined, [styles.thumbnailWrapper, isGroup],
            [{ borderColor: colors['color-warning-500'] }, warningWrapper && isGroup],
            [{ opacity: 0.5 }, status === GroupDraftStatus.ACTIVE])}
        >
          <DNABox>
            <Stack>
              {/* ARRAY OF PAGES WITHIN THE GROUP */}
              <Stack.Layer
                style={[isGroup && styles.groupWrapper, displayTrashCan && isGroup && { paddingTop: 50 }]}
              >
                <DNABox appearance="col" spacing="sm">
                  {
                    pages.map((page, index) =>
                      (<PageComponent
                        page={page}
                        visible={visible}
                        documentVersionORM={documentVersionORM}
                        key={`${groupId}-${page?.pageId}`}
                        hideThumbnail={isUnavailableDocument}
                        warningWrapper={!isGroup && warningWrapper}
                        isFromPersonalDocument={isFromPersonalDocument}
                        number={currentIndexCount + index}
                        thumbnail={slidesThumbnails[page?.pageId] || ''}
                      />))
                  }
                </DNABox>
              </Stack.Layer>
              {
                // NOT VISIBLE ICON
                !visible &&
                  <Stack.Layer
                    style={util.mergeStyles(undefined, styles.thumbOverlay,
                      [styles.thumbIndividualOverlay, !isGroup])}
                    fill
                  >
                    <DNABox fill alignX="center" alignY="center">
                      <DNAIcon.Styled name="eye-off-outline" size="lg"/>
                    </DNABox>
                  </Stack.Layer>
              }
              {
                // BLACK OVERLAY WITH MESSAGE/ACTION BUTTON
                useThumbnailOverlay &&
                  <Stack.Layer
                    style={util.mergeStyles(undefined, styles.thumbOverlay,
                      [styles.thumbIndividualOverlay, !isGroup],
                      [{ justifyContent: 'center', paddingBottom: 10 }, !isGroup && isUnavailableDocument])}
                  >
                    <DNABox alignY={'center'} alignX="center" appearance="col" spacing="sm">
                      <DNAText status="basic" bold>
                        {status === GroupDraftStatus.GROUP_REMOVED
                          ? 'Deleted'
                          : status === GroupDraftStatus.DELETED
                            ? `Source file is ${DOC_STATUS[status]}`
                            : `Source file was ${DOC_STATUS[status]}`
                        }
                      </DNAText>
                      <Iffy is={isUnavailableDocument}>
                        <DNAButton status="warning" onPress={onAction} size="sm">
                          <DNABox spacing="md">
                            <DNAIcon
                              appearance="ghostAlt"
                              status="basic"
                              style={{ height: 18, width: 16 }}
                              name="trash-can-outline"
                            />
                            <DNAText status="basic" bold>Remove</DNAText>
                          </DNABox>
                        </DNAButton>
                      </Iffy>
                    </DNABox>
                  </Stack.Layer>
              }
              {
                // TASH CAN ICON TO REMOVE THE THUMBNAIL
                displayTrashCan &&
                  <Stack.Layer style={styles.trashCanStack}>
                    <Pressable
                      onPress={() => onRemoveGroup(groupId)}
                      style={styles.removeIconWrapper}
                    >
                      <DNAIcon
                        status="neutral"
                        color="white"
                        appearance="ghostAlt"
                        style={styles.removeIcon}
                        name="trash-can-outline"
                      />
                    </Pressable>
                  </Stack.Layer>
              }
            </Stack>
          </DNABox>
        </DNABox>
      </Pressable>
      { /* CENTER ARROW */}
      <Iffy is={status !== GroupDraftStatus.ACTIVE && !isUnavailableDocument}>
        <DNAIcon.Styled
          appearance="ghost"
          status="tertiary"
          size="lg"
          name="arrow-right"
          style={{ marginBottom: 30 }}
        />
        { /* LEFT SIDE OF THE ROW (REPLACEMENT GROUP) */}
        <Pressable
          onPress={!isActiveGroup && status !== GroupDraftStatus.REPLACED ? null : onAction}
          style={{ height: '100%' }}
        >
          <ReplacementGroup
            slidesThumbnails={slidesThumbnails}
            isActiveGroup={isActiveGroup}
            group={group}
            onActionPressed={onAction}
          />
        </Pressable>
      </Iffy>
    </DNABox>
  );
})

// RIGHT SIDE OF THE GROUP ROW (THE GROUP THAT WILL REPLACE THE ONE WITH ERRORS)
const ReplacementGroup:React.FC<ReplacementGroupProps> = ({
  group,
  isActiveGroup,
  slidesThumbnails,
  onActionPressed,
}) => {
  const showButtonOption =
    !isActiveGroup &&
    [GroupDraftStatus.MAJOR_UPDATE, GroupDraftStatus.GROUP_REMOVED].includes(group.status);
  const showReplacementThumbnail = group.groupReplacement && group.status === GroupDraftStatus.REPLACED;
  const moreThanOneSlide = group.isGroup || ((group.groupReplacement?.pages.length || 0) > 1);

  const documentORM = group.documentVersionORM?.relations.documentORM;
  const { latestPublishedDocumentVersionORM } = { ...documentORM?.relations.version }

  return (
    <DNABox
      alignY="center"
      alignX="center"
      style={util.mergeStyles(undefined, styles.thumbnailWrapper, styles.replaceThumbnail,
        [styles.selectedReplaceThumbnail, isActiveGroup],
        [{ height: '100%' }, moreThanOneSlide],
        [{ backgroundColor: 'transparent' }, showReplacementThumbnail],
        [{ height: 'unset' }, showReplacementThumbnail && !group.isGroup],
        [{ borderWidth: 0 }, !moreThanOneSlide && showReplacementThumbnail],
      )}
    >
      <Iffy is={showReplacementThumbnail}>
        { /* NEW SLIDES */}
        <DNABox
          style={moreThanOneSlide && { paddingHorizontal: 10, paddingVertical: 10 }}
          appearance="col"
          spacing="sm"
        >
          {
            group.groupReplacement?.pages.map((page) =>
              (<PageComponent
                page={page}
                visible={group.visible}
                documentVersionORM={latestPublishedDocumentVersionORM}
                key={`${group.groupId}-${page.pageId}`}
                selectedWrapper={!moreThanOneSlide && showReplacementThumbnail && isActiveGroup}
                thumbnail={slidesThumbnails[page.pageId]}
              />))
          }
        </DNABox>
      </Iffy>
      <Iffy is={showButtonOption}>
        <DNAButton testID="replacement-button" onPress={onActionPressed} status="tertiary">
          <DNAText>
            {group.status === GroupDraftStatus.MAJOR_UPDATE ? 'Add replacement' : 'Restore'}
          </DNAText>
        </DNAButton>
      </Iffy>
      <Iffy is={isActiveGroup && group.status !== GroupDraftStatus.REPLACED}>
        <DNAText
          testID="replacement-slide-thumbnail"
          bold
          status="warning"
          style={{ textAlign: 'center' }}
        >
          {'Select a slide from the \nleft area'}
        </DNAText>
      </Iffy>
    </DNABox>
  );
}

// THUMBNAIL
const PageComponent:React.FC<PageComponentProps> = ({
  documentVersionORM,
  page,
  warningWrapper,
  selectedWrapper,
  hideThumbnail,
  number,
  isFromPersonalDocument,
  visible,
}) => {
  const s3URL = detectArchivedFileKeyPath(documentVersionORM?.model, page)
  return (
    <DNABox appearance="col">
      <DNABox
        style={[styles.thumbnailWrapper,
          warningWrapper && { borderColor: colors['color-warning-500'] },
          selectedWrapper && styles.selectedReplaceThumbnail]}
      >
        {hideThumbnail ? <DNABox style={styles.thumbnail} />
          : <DNAThumbnail
              overlayText={documentVersionORM?.model.title}
              style={styles.thumbnail}
              height={124}
              width={220}
              s3URL={s3URL}
          />
        }
      </DNABox>
      <Iffy is={number || page.isRequired || isFromPersonalDocument}>
        <DNABox spacing="between" style={{ marginTop: 4 }}>
          <DNAText>{visible ? number : ''}</DNAText>
          <Iffy is={page.isRequired}>
            <DNAChip
              status="danger"
              appearance="tag"
            >REQUIRED</DNAChip>
          </Iffy>
          <Iffy is={isFromPersonalDocument}>
            <DNAChip appearance="tag">
              MY UPLOADS
            </DNAChip>
          </Iffy>
        </DNABox>
      </Iffy>
    </DNABox>
  );
}

export default GroupRow
