// Most of drawing code was inspired by this example
// https://codepen.io/bearnithi/pen/GRdZaQd
import { DNABox } from '@alucio/lux-ui';
import React, { useEffect, useMemo, useReducer } from 'react';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import { canvasId, useHighlighter } from './HighlighterProvider';
import useKeyPressedEventHandler, { KeyEventSettings }
  from 'src/hooks/useKeyPressedEventHandler/useKeyPressedEventHandler';

type HighlightSourceProps = {
  iFrameRef: React.MutableRefObject<HTMLIFrameElement | null>;
}

export const HighlightSource = (props: HighlightSourceProps) => {
  const {
    canvasRef,
    prepareCanvas,
    mouseDownListener,
    mouseMoveListener,
    mouseUpListener,
    highlighterVisible,
    clearCanvas,
  } = useHighlighter();

  const {
    activePresentation,
    nextStepPage,
    prevStepPage,
  } = useContent()

  const keyEventSettings: KeyEventSettings[] = useMemo(() => [{
    keyEvent: 'keydown',
    keys: ['ArrowLeft', 'ArrowUp', 'Home', 'PageUp'],
    onAction: () => { prevStepPage(); clearCanvas?.(); },
  }, {
    keyEvent: 'keydown',
    keys: ['ArrowRight', 'ArrowDown', 'Enter', 'Space', 'End', 'PageDown'],
    onAction: () => { nextStepPage(); clearCanvas?.(); },
  }], []);

  useKeyPressedEventHandler(keyEventSettings);

  const { iFrameRef } = props;

  type ReducerAction = (
    {
      type: 'APPEND_STYLE',
      styles: any
    } |
    {
      type: 'SET_SOURCE_DIMENSIONS',
      width: number,
      height: number,
    }
  )

  type ReducerState = {
    style: React.CSSProperties,
    width: number | string,
    height: number | string,
  }

  const [stateSource, dispatchSource] = useReducer((state: ReducerState, action: ReducerAction): ReducerState => {
    switch (action.type) {
      case 'SET_SOURCE_DIMENSIONS':
        return { ...state, width: action.width, height: action.height };
      case 'APPEND_STYLE':
        return { ...state, style: { ...state.style, ...action.styles } };
      default:
        return state;
    }
  }, {
    width: '100%',
    height: '100%',
    style: { pointerEvents: 'none' },
  })

  useEffect(() => {
    dispatchSource({
      type: 'APPEND_STYLE',
      styles: highlighterVisible
        ? { opacity: 0.8, pointerEvents: '', cursor: 'crosshair' }
        : { pointerEvents: 'none', cursor: 'default' },
    })

    if (!highlighterVisible) return

    prepareCanvas?.();
  }, [highlighterVisible]);

  useEffect(() => {
    clearCanvas?.();
  }, [activePresentation])

  useEffect(() => {
    const iframe = iFrameRef.current;
    if (!iframe) return;

    const observer = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      dispatchSource({ type: 'SET_SOURCE_DIMENSIONS', width, height });
    });

    observer.observe(iframe);
    return () => observer.disconnect();
  }, [iFrameRef]);

  return (
    <DNABox
      fill
    >
      <canvas
        style={stateSource.style}
        id={canvasId}
        object-fit="cover"
        onMouseDown={mouseDownListener}
        onMouseMove={mouseMoveListener}
        onMouseUp={mouseUpListener}
        onMouseOut={(e) => {
          mouseUpListener?.(e)
        }}
        onTouchStart={mouseDownListener}
        onTouchMove={mouseMoveListener}
        onTouchEnd={(e) => {
          mouseUpListener?.(e)
        }}
        ref={canvasRef}
        width={stateSource.width}
        height={stateSource.height}
      />
    </DNABox>
  );
}

export default HighlightSource
