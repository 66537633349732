import { DNABox, DNAButton, DNACheckbox, DNAChip, DNAText, Iffy } from '@alucio/lux-ui';
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';
import { DNATextProps } from '@alucio/lux-ui/src/components/type/DNAText/DNAText';
import { DNAChipProps } from '@alucio/lux-ui/src/components/visual/DNAChip/DNAChip';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { ScrollView, StyleSheet, StyleProp, TextStyle } from 'react-native';
import { useDNALibrary } from 'src/screens/MyLibrary/DNALibrary';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import { useCurrentUserORM, useUserTenant } from 'src/state/redux/selector/user';
import { VIDEO_TYPES_ENUM } from 'src/types/enums';
import CollapsibleCard, { CollapsibleCardProps } from '../CollapsibleCard/CollapsibleCard';
import {
  countDocumentCustomValues,
  FilterMap,
  useDNADocumentFilters,
} from '../DNA/Document/DNADocumentFilters/DNADocumentFilters';
import useCurrentPage from '../DNA/hooks/useCurrentPage';
import { useQuickFilters } from './QuickFiltersProvider';

const styles = StyleSheet.create({
  wrapper: {
    width: 400,
    borderRightWidth: 1,
    borderRightColor: colors['color-gray-100'],
  },
  actionBar: {
    backgroundColor: 'white',
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  activePublisherText: {
    color: colors['color-text-basic'],
  },
  activeLibraryText: {
    color: colors['color-text-basic'],
  },
  disabledPublisherText: {
    color: colors['color-black'],
  },
  disabledLibraryText: {
    color: colors['color-brand1-500'],
  },
})

interface CustomHeaderProps {
  count: number
  title: string
  allFilters?: boolean
}

const CustomHeader: React.FC<CustomHeaderProps> = (
  { count, title, allFilters },
) => {
  return (
    <DNABox spacing="sm" alignY="center">
      <DNAText testID={`header-${title?.toLocaleLowerCase().replaceAll(' ', '-')}`} bold>{title}</DNAText>
      {count > 0 &&
        <DNAChip
          style={{ borderRadius: 2 }}
          appearance={allFilters ? 'tag' : 'subtle'}
          status="primary"
          size="sm"
        >
          {`${count}`}
        </DNAChip>
      }
    </DNABox>
  )
}

const FilterCollapsibleCard: React.FC<PropsWithChildren<
CollapsibleCardProps
& { disabled?: boolean }
>> = (props) => {
  const { disabled, children } = props

  if (disabled) return <>{children}</>

  return (<CollapsibleCard {...props}>{children}</CollapsibleCard>)
}

export const useFilterMap = () => {
  const { activeFilters, filterEntries } = useDNADocumentFilters();
  const currentUserORM = useCurrentUserORM();
  const [filterMap, setFilterMap] = useState<FilterMap>({});

  const [customFields, staticFields] = [
    filterEntries.filter((entry) => entry.isCustomLabel).sort((a, b) => a.fieldName.localeCompare(b.fieldName)),
    filterEntries.filter((entry) => !entry.isCustomLabel).sort((a, b) => a.fieldName.localeCompare(b.fieldName)),
  ]

  useEffect(() => {
    const filterMap = [...customFields, ...staticFields]
      .reduce<FilterMap>((acc, entry) => {
        const { fieldId, valueId } = entry;
        if (!acc[fieldId]) {
          acc[fieldId] = {
            fieldName: entry.fieldName,
            values: {},
          };
        }

        acc[fieldId].values[valueId] = {
          fieldId,
          valueId,
          fieldName: entry.fieldName,
          fieldValue: entry.fieldValue,
          active: activeFilters.find((f) => f.fieldId === fieldId && f.valueId === valueId)?.active ?? false,
        };

        return acc;
      }, {})

    currentUserORM?.model.lockedFiltersCustomValues.forEach((lockedFilter) => {
      if (filterMap[lockedFilter.fieldId]) {
        Object.keys(filterMap[lockedFilter.fieldId].values).forEach((valueId) => {
          if (!lockedFilter.values.includes(valueId)) {
            delete filterMap[lockedFilter.fieldId].values[valueId];
          }
        });
      }
    });

    setFilterMap(filterMap);
  }, [activeFilters])
  // OBJECT THAT WILL BE USED TO RENDER THE CUSTOM VALUES

  let totalSelected = 0;

  const categories = Object.entries(filterMap);
  const filterSelectedCount = categories.reduce<Record<string, number>>((prev, curr) => {
    const [, field] = curr;
    Object.entries(field.values).forEach(([_, fieldEntry]) => {
      prev[fieldEntry.fieldName] = prev[fieldEntry.fieldName] ?? 0;
      if (fieldEntry.active) {
        prev[fieldEntry.fieldName] += 1
        totalSelected += 1;
      }
    })
    return prev;
  }, {})

  return {
    filterMap,
    filterSelectedCount,
    totalSelected,
    categories,
  };
}

export enum VariantOptions {
  publisher = 'publisher',
  library = 'library',
}

type VariantOption = keyof typeof VariantOptions

enum InteractivityStates {
  active,
  disabled
}
type InteractivityState = keyof typeof InteractivityStates

interface FilterComponentProps {
  filter: DNAButtonProps,
}
interface TextComponentStyle {
  text: StyleProp<TextStyle>
}
interface ChipComponentProps {
  chip: Pick<DNAChipProps, 'appearance'|'status'>
}
type ComponentConfigs = FilterComponentProps & TextComponentStyle & ChipComponentProps

type VariantConfigs = Record<VariantOption, ComponentConfigs>

type InteractivityConfigs = Record<InteractivityState, VariantConfigs>

const interactivityConfigs:InteractivityConfigs = {
  active:{
    publisher:{
      filter:{
        status:'primary',
        appearance: 'filled',
      },
      text:styles.activePublisherText,
      chip:{
        status: 'primary',
        appearance: 'subtle',
      },
    },
    library:{
      filter:{
        status:'secondary',
        appearance: 'filled',
      },
      text:styles.activeLibraryText,
      chip:{
        status: 'primary',
        appearance: 'tag',
      },
    },
  },
  disabled:{
    publisher:{
      filter:{
        status:'tertiary',
        appearance: 'outline',
      },
      text:styles.disabledPublisherText,
      chip:{
        status: 'primary',
        appearance: 'tag',
      },
    },
    library:{
      filter:{
        status:'secondary',
        appearance: 'outline',
      },
      text:styles.disabledLibraryText,
      chip:{
        status: 'primary',
        appearance: 'tag',
      },
    },
  },
}

interface FilterButtonProps {
  setFiltersVisible: (visible: boolean) => void
  filtersVisible: boolean,
  variant: VariantOption;
}
export const FilterButton : React.FC<FilterButtonProps> = ({ filtersVisible, setFiltersVisible, variant }) => {
  const { totalSelected } = useFilterMap()
  const route = useCurrentPage({ exact: false });
  const isPublisher = route?.configOptions?.modules?.includes('publisher');
  const isSearchView = route?.UID === 'search' || route?.UID === 'publisher-search';

  const handleFilterVisibilityToggle = () => {
    analytics?.track('LIBRARY_TOGGLE_FILTER', {
      action: 'FILTER',
      category: isPublisher
        ? isSearchView ? 'PUBLISHER_SEARCH_RESULTS' : 'PUBLISHER'
        : isSearchView ? 'VIEWER_SEARCH_RESULTS' : 'VIEWER_LIBRARY',
      context: isPublisher ? 'PUBLISHER' : 'VIEWER',
      state: filtersVisible ? 'VISIBLE' : 'HIDDEN',
    })
  }
  useEffect(handleFilterVisibilityToggle, [filtersVisible])

  const currentInteractivity:InteractivityState = filtersVisible
    ? 'active'
    : 'disabled'

  const { filter, text, chip } = interactivityConfigs[currentInteractivity][variant]

  const toggleFilterVisibility = () => {
    setFiltersVisible(!filtersVisible)
  }

  return (
    <DNAButton
      status={filter.status}
      appearance={filter.appearance}
      iconLeft="filter-variant"
      size="sm"
      onPress={toggleFilterVisibility}
    >
      <DNABox testID="filters-button">
        <DNAText style={text}>Filters</DNAText>
        <DNAChip
          style={{ marginLeft: 8, borderRadius: 2 }}
          appearance={chip.appearance}
          status={chip.status}
          size="xs"
        >
          {`${totalSelected}`}
        </DNAChip>
      </DNABox>
    </DNAButton>
  )
}

interface FiltersSidebarProps {
  textValue?: string
  setVisible: (visible: boolean) => void
  disableQuickFilters?: boolean
}
const FiltersSidebar: React.FC<FiltersSidebarProps> = ({
  textValue,
  setVisible,
  disableQuickFilters,
}) => {
  const { deviceMode } = useAppSettings()
  const {
    quickFilters,
    selectedPath,
    visibleRows,
    handleToggleFilter,
    handleToggleFilterValue,
    handleClear: handleClearQuickFilters,
  } = useQuickFilters();
  const {
    activeFilters,
    toggleFilter,
    clearFilters,
    resetToDefaultFilters,
  } = useDNADocumentFilters();
  const userTenant = useUserTenant()
  const route = useCurrentPage({ exact: false });
  const isPublisher = route?.configOptions?.modules?.includes('publisher');
  const { allDocuments } = useDNALibrary(textValue, isPublisher);
  const { filterSelectedCount, totalSelected, categories } = useFilterMap();

  const { disableQuickFilters: disableQuickFiltersOnRoute } = { ...route?.configOptions?.[deviceMode] }
  const shouldDisableQuickFilters = disableQuickFilters || disableQuickFiltersOnRoute

  const documentsCount = useMemo(() =>
    countDocumentCustomValues(allDocuments, isPublisher, userTenant),
  [allDocuments, userTenant, isPublisher]);

  const textProps: DNATextProps = {
    numberOfLines: 3,
    selectable: false,
    style: { width: 300 },
  }

  const pathLabels = (
    <DNABox>
      <CollapsibleCard headerTitle="Find Content By" collapseRight>
        <DNABox appearance="col" spacing="sm" style={{ width: 300 }} fill>
          {quickFilters.map((filter, index) => {
            const isSelected = selectedPath === index;
            return (
              <DNACheckbox.PWA
                key={filter.id}
                checked={isSelected}
                onChange={() => {
                  handleToggleFilter(filter.id, isSelected, index)
                }}
                testID="quickfilter-path"
              >
                <DNAText
                  {...textProps}
                >
                  {filter.label}
                </DNAText>
              </DNACheckbox.PWA>
            )
          })}
        </DNABox>
      </CollapsibleCard>
    </DNABox>
  )

  const filterRows = (
    // for some reason, the component gets wonky without the spacing property, childStyle is added to override the margin added by the property
    <DNABox appearance="col" spacing="sm" childStyle={{ marginBottom: 0 }}>
      {visibleRows.map((field) => {
        return (
          <CollapsibleCard headerTitle={field.fieldLabel} key={field.id} collapseRight>
            <DNABox appearance="col" spacing="sm">
              {field.fieldValueDefinitions.map((value) => {
                const isSelected = !!activeFilters.find((v) => v.valueId === value.id);
                return (
                  <DNACheckbox.PWA
                    key={value.id}
                    testID="quickfilter-value"
                    checked={isSelected}
                    onChange={() => handleToggleFilterValue(field, value, isSelected)}
                  >
                    <DNAText
                      {...textProps}
                    >
                      {value.label ?? value.value} ({documentsCount[field.id]?.[value.id] || 0})
                    </DNAText>
                  </DNACheckbox.PWA>
                )
              })}
            </DNABox>
          </CollapsibleCard>
        )
      })}
    </DNABox>
  )

  const handleClose = () => {
    setVisible(false);
  }

  const handleReset = () => {
    resetToDefaultFilters();
  }

  const handleClear = () => {
    // clear regular filters
    clearFilters();
    // clear quick filters
    handleClearQuickFilters();
  }

  return (
    <DNABox style={styles.wrapper} appearance="col">
      <Iffy is={deviceMode === DeviceMode.tablet}>
        <DNABox spacing="sm" style={styles.actionBar}>
          <DNAButton
            onPress={handleClose}
          >
            Close
          </DNAButton>
          <DNAButton
            onPress={handleReset}
            appearance="outline"
          >
            Reset to default</DNAButton>
          <DNAButton
            onPress={handleClear}
            appearance="outline"
            testID="quickfilter-clear-button"
          >
            Clear
          </DNAButton>
        </DNABox>
      </Iffy>
      <DNABox spacing="md" appearance="col" fill as={ScrollView}>
        <Iffy is={quickFilters.length && !isPublisher && !shouldDisableQuickFilters}>
          <CollapsibleCard
            headerTitle="QUICK FILTERS"
            variant="filters"
            collapseRight
            contentPadding="none"
          >
            <DNABox appearance="col">
              {pathLabels}
              {filterRows}
            </DNABox>
          </CollapsibleCard>
        </Iffy>
        <DNABox>
          <FilterCollapsibleCard
            headerTitle={<CustomHeader title="ALL FILTERS" count={totalSelected} allFilters/>}
            collapsedDefault={!!quickFilters.length && !shouldDisableQuickFilters}
            variant="filters"
            collapseRight
            contentPadding="none"
            disabled={!!isPublisher}
          >
            <DNABox appearance="col" spacing="sm" childStyle={{ marginBottom: 0 }}>
              {categories.map(([_, { fieldName, values }]) =>
                (<FilterCollapsibleCard
                  headerTitle={<CustomHeader title={fieldName} count={filterSelectedCount[fieldName]} />}
                  numberOfTitleLines={3}
                  collapsedDefault
                  collapseRight
                  key={fieldName}
                >
                  <DNABox spacing="sm" appearance="col">
                    {
                      Object.entries(values).map(([_, { fieldId, valueId, active, fieldValue }]) => {
                        let videoDocumentCount = 0
                        if (valueId === 'VIDEO') {
                          for (const type in documentsCount[fieldId]) {
                            if (VIDEO_TYPES_ENUM[type]) videoDocumentCount += documentsCount[fieldId][type]
                          }
                        }
                        return (
                          <DNACheckbox.PWA
                            key={valueId}
                            testID={active ? 'checkbox-checked' : 'checkbox-unchecked'}
                            checked={active}
                            onChange={() => {
                              toggleFilter(fieldId, valueId);
                            }}
                          >
                            <DNAText
                              testID="filter-name-text"
                              {...textProps}
                            >
                              {fieldValue} ({videoDocumentCount ||
                              documentsCount[fieldId]?.[valueId] || 0})
                            </DNAText>
                          </DNACheckbox.PWA>
                        )
                      })
                    }
                  </DNABox>
                </FilterCollapsibleCard>),
              )}
            </DNABox>
          </FilterCollapsibleCard>
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

export default FiltersSidebar;
