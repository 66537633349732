import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { StyleSheet, ScrollView } from 'react-native'
import { DNABox, DNAText, DNAChip, DNACheckbox, Iffy, DNAButton } from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import { v4 as uuid } from 'uuid';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { ThumbnailPage } from './SlideSelector'
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail'
import AssociatedSlidesThumbnails from 'src/components/DNA/Thumbnail/AssociatedSlidesThumbnails'
import { Page, DocumentAccessLevel } from '@alucio/aws-beacon-amplify/src/models'
import { ThumbnailSize } from 'src/hooks/useThumbnailSize/useThumbnailSize'
import { DocumentVersionORM } from 'src/types/types'
import { PayloadGroup } from '../PresentationBuilder/state/PresentationBuilderStateProvider'
import { useCurrentUser } from 'src/state/redux/selector/user'
import CollapsibleCard, { CollapsibleActionMenu } from '../CollapsibleCard/CollapsibleCard'
import { getPresentable } from 'src/state/context/ContentProvider/helper';
import useContentPageData,
{ ContentPageData, getSlideContentPageDataTitle } from 'src/hooks/useContentPageData/useContentPageData';
import { SortableItem, SortableItemProps } from '../DnD/DnDWrapper';

interface SectionConfig {
  /** NOTE: Disabling the section will also disable the header since
   * there is no real value in having a sectionless header visible */
  disableSection?: boolean
  disableSectionHeader?: boolean
}

export interface DisplayConfig {
  groups?: SectionConfig
  individualSlides?: SectionConfig
}

interface PageGroupListProps {
  handleGroupSelect: (group: GroupDraft) => void
  selectorThumbnailSize: ThumbnailSize
  allGroups: GroupDraft[]
  disableCheckboxes?: boolean
  documentVersionORM?: DocumentVersionORM
  selectedGroups?: PayloadGroup[],
  hideGroups?: boolean,
  hideAssociatedSlides?: boolean,
}

export interface GroupDraft {
  id: string;
  pages: ThumbnailPage[];
  name?: string,
  locked?: boolean,
  checked?: boolean,
}

const styles = StyleSheet.create({
  allSlides: {
    backgroundColor: colors['color-gray-50'],
    height: 48,
    borderRadius: 4,
    marginBottom: 24,
  },
});

const RightGroupMenu: React.FC<{
  group: GroupDraft,
  onCheck: () => void,
  checked: boolean,
}> = ({ onCheck, group, checked }) => {
  return (
    <DNABox spacing="between" alignY="center">
      <Iffy is={group}>
        <DNABox alignX="center" alignY="center" fill childFill>
          <DNAChip
            appearance="subtle"
            status="basic"
            size="lg"
            style={{ borderRadius: 2 }}
          >
            {`${group?.pages.length}`}
          </DNAChip>
        </DNABox>
        <DNAPopover>
          <DNAPopover.Anchor>
            <DNAButton
              appearance="ghostLink"
              disabled
              size="md"
              iconStyle={{ color: colors['color-gray-200'] }}
              padding="sm"
              rounded="md"
              status="gray"
              iconLeft={group.locked ? 'lock' : 'lock-open-variant-outline'}
            />
          </DNAPopover.Anchor>
          <DNAPopover.Content>
            <DNAText style={{ color: colors['color-text-white'] }}>
              {group.locked ? 'Ungrouping restricted' : 'Ungrouping allowed'}
            </DNAText>
          </DNAPopover.Content>
        </DNAPopover>
      </Iffy>
      <DNACheckbox
        checked={checked}
        status="gray"
        onChange={onCheck}
      />
    </DNABox>
  )
}

export const s3Size : Record<ThumbnailSize, string> = {
  'xxs': 'small',
  'xs': 'small',
  'sm': 'small',
  'md': 'small',
  'lg': 'small',
  'xl': 'medium',
  'xxl': 'medium',
}

export const SlideThumb: React.FC<{
  page: ThumbnailPage,
  documentVersionORM?: DocumentVersionORM,
  onCheck?: () => void,
  showAssociation: boolean
  selectorThumbnailSize: ThumbnailSize,
  title?: string,
}> = (props) => {
  const { page, documentVersionORM, onCheck, showAssociation, selectorThumbnailSize } = props

  const thumbURL = documentVersionORM?.meta
    .assets
    .thumbnailKey
    ?.split('/')
    .slice(0, -1)
    .join('/') + `/${page.number}_${s3Size[selectorThumbnailSize]}_thumb.png` ?? ''

  const associatedPages: Page[] = []
  page.linkedSlides?.forEach(slideId => {
    const page = documentVersionORM?.meta.allPages.find(page => page.pageId === slideId)
    if (page) associatedPages.push(page)
  })

  return (
    <DNABox
      alignY="start"
      alignX="center"
      appearance="col"
      style={{ marginBottom: 18 }}
    >
      <DNABox style={{ marginRight: 12 }}>
        <DNAThumbnail.Lazy
          s3URL={thumbURL}
          variant={DNAThumbnail.Variants.INFO}
          mode={onCheck ? DNAThumbnail.Modes.SELECTABLE : undefined}
          size={selectorThumbnailSize}
          pageNumber={page.number}
          useLoadingIndicator
          isRequired={page?.isRequired}
          onCheck={onCheck}
          checked={onCheck ? page.checked : undefined}
          thumbnailTitle={props.title}
        />
      </DNABox>
      {documentVersionORM && showAssociation
        ? <AssociatedSlidesThumbnails
            docVerORM={documentVersionORM}
            pages={associatedPages}
            size="lg"
            getSlideTitle={getSlideContentPageDataTitle}
        />
        : null}
    </DNABox>
  )
}

interface IdedGroupPage extends ThumbnailPage {
  uuid: string
}

export const CollapsibleGroupItem : React.FC<{
  page: IdedGroupPage,
  contentPageData?: ContentPageData[],
  group: GroupDraft,
  sortableHeader?: SortableItemProps
  documentVersionORM?: DocumentVersionORM,
  selectorThumbnailSize: ThumbnailSize,
  onSlideCheck: (page: IdedGroupPage) => void,
}> = (props) => {
  const {
    contentPageData,
    documentVersionORM,
    group,
    onSlideCheck,
    page,
    selectorThumbnailSize,
    sortableHeader,
  } = props
  const title = contentPageData ? contentPageData[page.number - 1]?.title : `Slide ${page.number}`

  const SlideThumbContainer = useMemo(() => ({ children }) => (
    group.locked || group.checked
      ? <>
        {children}
      </>
      : <SortableItem
          id={uuid()}
          itemId={page.pageId}
          containerId={sortableHeader?.containerId || ''}
      >
        {children}
      </SortableItem>
  ), [group.locked, page.pageId, sortableHeader?.containerId])

  const onCheck = useCallback(() => {
    onSlideCheck(page)
  }, [onSlideCheck, page])

  const id = React.useMemo(() => uuid(), [])

  return (
    <SlideThumbContainer>
      <DNABox>
        <SlideThumb
          key={id}
          page={page}
          documentVersionORM={documentVersionORM}
          showAssociation={!!group.checked}
          selectorThumbnailSize={selectorThumbnailSize}
          title={title}
          onCheck={group.checked || group.locked ? undefined : onCheck}
        />
      </DNABox>
    </SlideThumbContainer>
  )
}

export const CollapsibleGroupCard: React.FC<{
  group: GroupDraft,
  documentVersionORM?: DocumentVersionORM,
  onCheck: () => void,
  selectorThumbnailSize: ThumbnailSize,
  contentPageData?: ContentPageData[],
  sortableHeader?: SortableItemProps
  handleSelectSlide?: (page: Page) => void
}> = (props) => {
  const { group, documentVersionORM, onCheck, selectorThumbnailSize, sortableHeader, contentPageData } = props

  // an array of uuids that does not change when the group changes and provokes a re-render
  const uuidArray = useRef<string[] | undefined>(undefined)

  useEffect(() => {
    if (uuidArray.current) return
    uuidArray.current = group.pages.map( () => uuid())
  }, [group])

  // TODO: consider making this an interface that is used on hydration to generate unique IDs
  // since we now have the possibility of having duplicate slides in groups.
  const memoIdGroupPages = useMemo(() => {
    const idedGroupPages: IdedGroupPage[] = group.pages.map((page, idx) => {
      const updatedIdedPage: IdedGroupPage = { ...page, uuid: uuidArray.current?.[idx] || '' }
      return updatedIdedPage
    })
    return idedGroupPages
  }, [group])

  const onSlideCheck = useCallback((page: IdedGroupPage) => {
    if (group.locked) return

    props?.handleSelectSlide?.(page)
  }, [group])

  const groupItems = useMemo(() =>
    memoIdGroupPages.map((page) => {
      return (
        <CollapsibleGroupItem
          key={page.uuid}
          page={page}
          contentPageData={contentPageData}
          group={group}
          documentVersionORM={documentVersionORM}
          selectorThumbnailSize={selectorThumbnailSize}
          onSlideCheck={onSlideCheck}
          sortableHeader={sortableHeader}
        />
      )
    }), [group, documentVersionORM, selectorThumbnailSize, contentPageData, sortableHeader])

  return (
    <CollapsibleCard
      headerTitle={group.name || ''}
      collapsedDefault
      numberOfTitleLines={1}
      selected={!!group.checked}
      sortableHeader={sortableHeader}
    >
      <CollapsibleActionMenu>
        <RightGroupMenu
          group={group}
          onCheck={onCheck}
          checked={!!group.checked}
        />
      </CollapsibleActionMenu>
      <DNABox wrap={'start'} spacing="md">
        {groupItems}
      </DNABox>
    </CollapsibleCard>
  )
}

const PageGroupList: React.FC<PageGroupListProps> = ({
  handleGroupSelect,
  documentVersionORM,
  allGroups,
  selectedGroups,
  selectorThumbnailSize,
  hideGroups,
  hideAssociatedSlides,
}) => {
  const currentUser = useCurrentUser()
  const modifiable = documentVersionORM?.meta.permissions.MSLSelectSlides
  const isUserUploads = documentVersionORM?.relations.documentORM.model.accessLevel === DocumentAccessLevel.USER &&
    documentVersionORM?.relations.documentORM.model.createdBy === currentUser.authProfile?.attributes.email

  const ContainerRef = useRef<ScrollView>(null)
  const presentable = getPresentable(documentVersionORM);
  const { contentPageData } = useContentPageData(presentable)
  const collapsibleGroups: GroupDraft[] = []
  const allIndividualSlideGroups: GroupDraft[] = []
  allGroups.forEach(groupDraft => {
    const { pages: { length } } = groupDraft
    length > 1
      ? collapsibleGroups.push(groupDraft)
      : allIndividualSlideGroups.push(groupDraft)
  })

  const { groups, individualSlides }:DisplayConfig = {
    groups: {
      disableSection: hideGroups,
    },
    individualSlides: {
      disableSectionHeader: hideGroups,
    },
  }

  const handleForcedSelectedCheck = (group: GroupDraft) => {
    let isForcedSelected: boolean = group.pages.some(page => page.isRequired)
    // Check if the selectedGroups already has the required slide(s)
    if (isForcedSelected) {
      selectedGroups?.forEach(group => {
        group.pages.forEach(slide => {
          const requiredPage = group.pages.find(page => page.isRequired)
          // If the selectedGroups has the required slide already, then no need to force select
          if (slide.pageId === requiredPage?.pageId) isForcedSelected = false
        })
      })
    }
    // Check if the current selected/checked group has the required slides
    if (isForcedSelected) {
      collapsibleGroups.forEach(collapsibleGroup => {
        if (collapsibleGroup.checked) {
          collapsibleGroup.pages.forEach(slide => {
            const requiredPage = group.pages.find(page => page.isRequired)
            if (slide.pageId === requiredPage?.pageId) {
              isForcedSelected = false
              requiredPage.checked = false
            }
          })
        }
      })
    }
    // If the selectGroups does not hve required slide, then modified the slide to be checked
    if (isForcedSelected) {
      group.pages.forEach(page => {
        page.checked = true
      })
    }
  }

  const handleSelectionSlide = useCallback((page: Page): void => {
    const group = allIndividualSlideGroups
      .find(group => group.pages.find(pageWithGroup => pageWithGroup.pageId === page.pageId));

    if (!group) return;

    handleGroupSelect(group);
  }, [allIndividualSlideGroups, handleGroupSelect]);

  return (
    <DNABox as={ScrollView} appearance="col" ref={ContainerRef}>
      {/* All the groups */}
      {!groups?.disableSection && !!collapsibleGroups.length &&
        <DNABox appearance="col">

          {/* Header */}
          {!groups?.disableSectionHeader &&
            <DNABox alignX="center" alignY="center" style={styles.allSlides}>
              <DNAText h5>Groups</DNAText>
            </DNABox>
          }

          {/* Groups */}
          {collapsibleGroups
            .sort((a, b) => {
              if (!a.name) return -1
              else if (!b.name) return 1
              else return a.name.localeCompare(b.name)
            })
            ?.map(group => {
              return (
                <DNABox style={{ marginBottom: 24 }} key={group.id}>
                  <CollapsibleGroupCard
                    key={group.id}
                    group={group}
                    documentVersionORM={documentVersionORM}
                    onCheck={() => handleGroupSelect(group)}
                    selectorThumbnailSize={selectorThumbnailSize}
                    handleSelectSlide={handleSelectionSlide}
                    contentPageData={contentPageData}
                  />
                </DNABox>
              )
            })}
        </DNABox>}

      {/* All individual slides */}
      {!individualSlides?.disableSection && (modifiable || isUserUploads) &&
        <DNABox appearance="col">

          {/* Header */}
          {!individualSlides?.disableSectionHeader &&
            <DNABox alignX="center" alignY="center" style={styles.allSlides}>
              <DNAText h5>All Slides</DNAText>
            </DNABox>}

          {/* Slides */}
          <DNABox wrap="start" alignX="center">
            {allIndividualSlideGroups.map(group => {
              handleForcedSelectedCheck(group)
              return group.pages.map(page => {
                const title = contentPageData.length > 0
                  ? getSlideContentPageDataTitle(page.number - 1, contentPageData) : ''
                return (
                  <DNABox key={`single-slide-${page.pageId}`} style={{ marginRight: 12 }}>
                    <SlideThumb
                      page={page}
                      documentVersionORM={documentVersionORM}
                      title={title}
                      onCheck={!page.isPlaceholder
                        ? () => { handleGroupSelect(group) }
                        : undefined}
                      showAssociation={!hideAssociatedSlides && page.checked}
                      selectorThumbnailSize={selectorThumbnailSize}
                    />
                  </DNABox>
                )
              })
            })}
          </DNABox>
        </DNABox>
      }

    </DNABox>
  )
}

export default PageGroupList
