import { FolderORM } from '../types/orms';
import { isCustomDeckORM, isFolderORM } from 'src/types/typeguards'

export function getRootFolder(folderORM: FolderORM): FolderORM {
  if (folderORM.relations.parentFolderORM) {
    return getRootFolder(folderORM.relations.parentFolderORM);
  } else {
    return folderORM;
  }
}

export function doesTreeContainsCustomDeck(folderORM: FolderORM): boolean {
  const rootFolder = getRootFolder(folderORM);

  function hasCustomDeck(folderORM: FolderORM): boolean {
    let containsCustomDeck = false;

    for (const item of folderORM.relations.items) {
      if (isCustomDeckORM(item.relations.itemORM)) {
        containsCustomDeck = true;
        break;
      } else if (isFolderORM((item.relations.itemORM))) {
        containsCustomDeck = hasCustomDeck(item.relations.itemORM);
      }
    }

    return containsCustomDeck;
  }
  return hasCustomDeck(rootFolder);
}
