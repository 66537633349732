import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch } from 'src/state/redux';
import { meetingActions } from 'src/state/redux/slice/meeting';
import {
  ContentPresented,
  CustomValues,
  Meeting,
  MeetingStatus,
  MeetingType,
} from '@alucio/aws-beacon-amplify/src/models';
import DNAMeetingDeleteModal from '../DNA/Modal/DNAMeetingDeleteModal';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { DRAWER_ENTITIES, drawerActions } from 'src/state/redux/slice/drawer';
import { useMeeting } from 'src/state/redux/selector/meeting';
import DNAMeetingContentDeleteModal from '../DNA/Modal/DNAMeetingContentDeleteModal';
import { MeetingORM } from 'src/types/orms';
import { useComposableForm } from '../CustomFields/ComposableForm';
import { isEmpty } from 'lodash';
import { DeepMap } from 'react-hook-form';
import { CONTENT_PRESENTED_STATUS } from '@alucio/aws-beacon-amplify/src/API';
import { Logger } from '@aws-amplify/core'
import { replaceContentPresented } from 'src/state/context/Meetings/helper';
import { GenericToast, ToastOrientations, useToast } from '@alucio/lux-ui'
import isEqual from 'lodash/isEqual';
import { formToModel } from '../CustomFields/ComposableFormUtilities';
import { useSaveMeeting } from 'src/state/context/Meetings/saveMeetingHelper';
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import { canSubmitToCRM } from './MeetingsCRMValidations';

const logger = new Logger('Add Meeting Panel', 'INFO')

export const GLOBAL_FORM_ERROR = {
  invalid_type: 'Missing required fields.',
  too_small: 'Missing required fields.',
  invalid_date: 'End time must be after start time.',
}

interface MeetingContext {
  onCancel: () => void
  onDelete: () => void
  formToModel: (values: { [x: string]: string | string[]}) => CustomValues[]
  onDeleteContent: (contentPresentedId: string, folderItemId?: string) => void
  meetingORM: MeetingORM | undefined

  isDirty: boolean,
  isValid: boolean,
  touched: DeepMap<{
    [x: string]: string | string[];
}, true>,
  isSubmitting: boolean,
  crmSubmitState: CRM_SUBMIT_STATE,
  isFormEdited: boolean,
  contentPresented: ContentPresented[],
  onSave: (submitType?: MEETING_SUBMIT_TYPE) => void
  addContentPresented: (item: ContentPresented) => void;
  errorMessage: string,
  isReadOnly?: boolean,
}

const AddMeetingContext = React.createContext<MeetingContext>(null!);

export enum MEETING_SUBMIT_TYPE {
  DEFAULT,
  SILENT_SAVE,
  SAVE_TO_CRM,
  SUBMIT_LOCK_TO_CRM
}

export enum CRM_SUBMIT_STATE {
  IDLE,
  SUBMITTING,
  SUCCESS,
  ERROR
}

interface AddMeetingProviderProps {
  meetingId?: string
  toggleDrawer: (ignoreChanges?: boolean) => void
}

export const AddMeetingProvider:React.FC<PropsWithChildren<AddMeetingProviderProps>> = ({
  meetingId,
  toggleDrawer,
  children,
}) => {
  const meetingORM = useMeeting(meetingId!)
  const submitMeeting = useSaveMeeting();
  const dispatch = useDispatch();
  const toast = useToast()
  const { rhForm, resetWithLatestDefaultValues, isReadOnly } = useComposableForm()
  const { formState, watch } = rhForm
  const { isDirty, isValid, isSubmitSuccessful, touched } = formState
  const initialContentPresented =  meetingORM?.model.contentPresented || []
  logger.debug({ cp:meetingORM?.model.contentPresented })
  const [contentPresented, setContentPresented] = useState<ContentPresented[]>(initialContentPresented);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [crmSubmitState, setCrmSubmitState] = useState<CRM_SUBMIT_STATE>(CRM_SUBMIT_STATE.IDLE);
  const { crmIntegrationType } = useCRMStatus();
  const startTime = watch('startTime');
  const endTime = watch('endTime');

  useEffect(() => {
    if (meetingId) {
      rhForm.trigger('endTime')
    }
  }, [startTime, endTime, meetingId]);

  // CREATES THE MEETING IN CASE IT DOESN'T EXIST
  useEffect(() => {
    const onNewMeetingCreated = (meeting: Meeting): void => {
      dispatch(drawerActions.toggle({
        entity: DRAWER_ENTITIES.MEETING,
        entityId: meeting.id,
        keepOpen: true,
      }));
    };

    if (!meetingId) {
      const { title, startTime, endTime } = rhForm.control.defaultValuesRef.current;
      dispatch(meetingActions.createMeeting({
        startTime: startTime!.toString(),
        endTime: endTime!.toString(),
        status: MeetingStatus.ENDED_EXIT,
        type: MeetingType.MANUAL,
        contentPresented,
        customValues: [],
        callback: onNewMeetingCreated,
        attendees: [],
        title: title!.toString(),
      }))
    }

    logger.debug('Inside init useEffect', contentPresented)
  }, []);

  // IF THERE WAS A CRM SUBMIT, THE FORM NEEDS TO BE RESET WITH THE LATEST DEFAULT VALUES
  // AS THERE MIGHT BE NEW EXTERNAL IDS TO BE SET THAT WERE NOT FORM VALUES
  useEffect(() => {
    if ([CRM_SUBMIT_STATE.SUCCESS, CRM_SUBMIT_STATE.SUBMITTING].includes(crmSubmitState)) {
      resetWithLatestDefaultValues();
    }
  }, [meetingORM]);

  useEffect(() => {
    // IF THERE'S A CHANGE OF A CONTENT FROM THE MEETING CPM (SENTIMENTS), THE
    // CONTENTPRESENTED OF THE STATE NEEDS TO BE UPDATED TO REFLECT THIS CHANGE
    const updatedContentPresented = replaceContentPresented(meetingORM?.model.contentPresented || [], contentPresented);
    setContentPresented(updatedContentPresented);
  }, [meetingORM?.model.contentPresented]);

  /** NOTE: The formState declared above has an 'isSubmitting' property, however,
   * the submission of the form appears to block UI updates. This useState is a
   * workaround to allow for UI updates just prior to form submission */
  const [isSubmitting, setIsSubmitting] = useState(isSubmitSuccessful);

  /** NOTE: This is a workaround for the blocking nature of the form
   * submission to allow for UI updates just prior to the submission */
  const onSave = (submitType?: MEETING_SUBMIT_TYPE) => {
    handleSubmit(submitType).finally(() => {
      setIsSubmitting(false);
    });
  }

  const onCancel = () => {
    toggleDrawer()
  }

  const onDelete = () => {
    if (meetingORM?.model.status === MeetingStatus.LOCKED) {
      console.warn('Cannot delete a locked meeting');
      return;
    }

    const toggleDrawerAndIgnoreChanges = async () => {
      return toggleDrawer(true)
    }
    if (meetingORM) {
      const payload = {
        isVisible: true,
        allowBackdropCancel: false,
        component: () =>
          (<DNAMeetingDeleteModal
            meeting={meetingORM}
            onDelete={toggleDrawerAndIgnoreChanges}
          />),
      };
      dispatch(DNAModalActions.setModal(payload));
    }
  }

  const onDeleteContent = (contentPresentedId: string, folderItemId?: string) => {
    const handleUpdateContent = () => {
      // just filter it from the array if it didn't exist before
      if (initialContentPresented.find((content) => content.contentId)) {
        setContentPresented((prev) => prev.filter((content) => content.contentId !== contentPresentedId));
      }
      // otherwise set status to deleted
      else {
        setContentPresented((prev) => prev.map((item) => (item.contentId === contentPresentedId &&
          !folderItemId && !item.folderItemId) ||
          (item.folderItemId === folderItemId && item.contentId === contentPresentedId)
          ? { ...item, status: CONTENT_PRESENTED_STATUS.DELETED, presentedMeta: [] } : item),
        );
      }
    }
    const payload = {
      isVisible: true,
      allowBackdropCancel: false,
      component: () =>
        (<DNAMeetingContentDeleteModal
          onDelete={handleUpdateContent}
        />),
    };
    dispatch(DNAModalActions.setModal(payload));
  }

  const isFormEdited =
    (isDirty && isValid) ||
    (isEmpty(touched) && !isDirty)

  const handleSubmit = (submitType: MEETING_SUBMIT_TYPE = MEETING_SUBMIT_TYPE.DEFAULT): Promise<void> => {
    return rhForm.handleSubmit(async (values) => {
      const isSubmitCRMSave =
        submitType && [MEETING_SUBMIT_TYPE.SUBMIT_LOCK_TO_CRM, MEETING_SUBMIT_TYPE.SAVE_TO_CRM].includes(submitType);
      if (isSubmitCRMSave) {
        if (!await canSubmitToCRM(values, rhForm, setErrorMessage, crmIntegrationType)) {
          return;
        }
        setCrmSubmitState(CRM_SUBMIT_STATE.SUBMITTING);
      }
      setIsSubmitting(true);
      logger.debug('Inside handleSubmit: form valid, saving...', { contentPresented });

      if (meetingORM) {
        try {
          await submitMeeting({
            meetingORM,
            contentPresented,
            formValues: values,
            submitType,
          });
        } catch (e) {
          setCrmSubmitState(CRM_SUBMIT_STATE.ERROR);
          return;
        }

        analytics?.track('MEETING_UPDATED', {
          action: 'UPDATED',
          category: 'MEETING',
          meetingId: meetingORM.model.id,
        });

        if (MEETING_SUBMIT_TYPE.DEFAULT === submitType) {
          toast.add(<GenericToast
            title="Changes saved"
            status="success"
          />,
          ToastOrientations.TOP_RIGHT)
          toggleDrawer(true)
        }
      }

      if (isSubmitCRMSave) {
        setTimeout(() => {
          setCrmSubmitState(CRM_SUBMIT_STATE.SUCCESS);
          setErrorMessage('');
        }, 800);
      }
    }, (errors) => {
      logger.debug('Inside handleSubmit: form invalid, show error message', { contentPresented });
      const consolidatedFormErrors = [...new Set(
        Object
          .values(errors)
          .map(err => {
            // Non-nested errors
            if (err?.message === GLOBAL_FORM_ERROR.invalid_date) {
              return GLOBAL_FORM_ERROR.invalid_date;
            } else if (typeof err?.type === 'string') {
              return GLOBAL_FORM_ERROR[err.type] }
            else {
              return GLOBAL_FORM_ERROR.invalid_type;
            }
          })
          .flat(),
      )]
      const formattedFormErrors = consolidatedFormErrors.join('\n')
      setErrorMessage(formattedFormErrors)
    })();
  }

  const addContentPresented = (item: ContentPresented) => {
    setContentPresented((prev) => [...prev, item])
  }

  useEffect(() => {
    dispatch(drawerActions.setPendingChanges(
      {
        hasPendingChanges: isDirty || !isEqual(initialContentPresented, contentPresented),
      },
    ),
    )
  }, [isDirty, contentPresented])

  const context:MeetingContext = {
    onCancel,
    onDelete,
    formToModel,
    onDeleteContent,
    meetingORM,
    addContentPresented,
    contentPresented,
    isDirty,
    isValid,
    touched,
    isSubmitting,
    crmSubmitState,
    isFormEdited,
    onSave,
    errorMessage,
    isReadOnly,
  }

  return (
    <AddMeetingContext.Provider value={context}>
      {children}
    </AddMeetingContext.Provider>
  )
}

export const useAddMeeting = () => React.useContext(AddMeetingContext)
