import _merge from 'lodash/merge'
import { FolderORM } from 'src/types/types'
import { FilterAndSortOptions } from 'src/state/redux/selector/common'

export const merge: (
  ...args: FilterAndSortOptions<FolderORM>[]
) => FilterAndSortOptions<FolderORM> = (...args) => _merge({}, ...args)

enum FilterOption {
  active = 'active',
  archived = 'archived',
  rootFolder = 'rootFolder',
  shared = 'shared',
}

export type Filters = Record<FilterOption, FilterAndSortOptions<FolderORM>>

export const filters: Filters = {
  active: {
    filter: { model: { status: 'ACTIVE' } },
  },
  archived: {
    filter: { model: { status: 'ARCHIVED' } },
  },
  rootFolder: {
    filter: { relations: { parentFolderORM: null } },
  },
  shared: {
    filter: { meta: { isSharedWithTheUser: true } },
  },
}

enum SortOption {
  folderTypeAsc = 'folderTypeAsc',
  pinnedAsc = 'pinnedAsc'
}

type Sorts = Record<SortOption, FilterAndSortOptions<FolderORM>>

export const sorts: Sorts = {
  folderTypeAsc: { sort: [{ type: 'desc' }] },
  pinnedAsc: {
    sort: [
      { model: { isPinned: 'asc' } },
      { model: { name: 'asc' } },
    ],
  },
}

export default {
  filters,
  sorts,
  merge,
}
