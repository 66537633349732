import { ROUTES, DNARoute } from 'src/router/routes'
import reduce from 'lodash/reduce'
import { useLocation } from 'react-router'

type UseCurrentPage = (opts?: { exact?: boolean }) => DNARoute | undefined

/** TODO: refactor this into a hook named useActiveRoute */

const useCurrentPage: UseCurrentPage = () => {
  const { pathname } = useLocation()

  const routeSearch: { bestMatch?: DNARoute, count?: number } = {}

  /** Home Route (Root) */
  if (pathname === '/') {
    return ROUTES.HOME
  }

  /** Child Routes */
  const substrings = new Set(pathname.split('/').filter(segment => segment !== ''));
  const countMatches = str => str.split('/').reduce((a, substr) => a + (substrings.has(substr)), 0);
  const { bestMatch } = reduce(ROUTES, ({ bestMatch, count = 0 }, route) => {
    const thisCount = countMatches(route.PATH);
    return thisCount > count
      ? { count: thisCount, bestMatch: route }
      : { count, bestMatch };
  }, routeSearch)

  return bestMatch
}

export default useCurrentPage
