import { isIOS } from 'react-device-detect';
import { CanvasEvent, getClientOffset, getClientPosition } from './shared';

type Pos = {
  x: number,
  y: number,
}
export function arrow(
  canvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
  isDrawStart: boolean,
) {
  let xPos = 0;
  let yPos = 0;
  let timeOutRef : NodeJS.Timeout | undefined;

  let canvasMapElements : Record<string, any> = {}

  const getContext = () : CanvasRenderingContext2D | undefined | null => {
    if (!canvasRef.current)
    { return; }

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    return context;
  };
  //
  const prepareCanvas = () => {
    const canvas = canvasRef.current;

    if (!canvas)
    { return; }

    const context = getContext();

    if (!context)
    { return; }
    context.scale(2, 2);
  };

  const onCanvasMounted = () => {
    if (!Object.keys(canvasMapElements).length)
    { return; }

    cleanCanvas();
  };

  const cleanCanvas = () => {
    const canvas = canvasRef.current;
    const context = getContext();

    if (timeOutRef) {
      clearTimeout(timeOutRef)
    }

    if (!context)
    { return; }
    if (!canvas)
    { return; }
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  const clearCanvas = () => {
    canvasMapElements = {};
    cleanCanvas();
  };

  const mouseDownListener = (event: CanvasEvent) => {
    if (isIOS) {
      touchStart(event)
      return
    }

    if (!canvasRef.current)
    { return; }

    const offset = getClientOffset(canvasRef.current);

    if (!offset)
    { return; }

    const { x, y } = getClientPosition(event);

    xPos = x - offset?.x;
    yPos = y - offset?.y;

    if (xPos === 0 && yPos === 0)
    { return; }

    cleanCanvas();
    isDrawStart = true;
  };

  const touchStart = (event: CanvasEvent) => {
    event.preventDefault();

    if (!canvasRef.current)
    { return; }

    const offset = getClientOffset(canvasRef.current)
    const touches = event.changedTouches;

    if (!offset)
    { return; }

    if (isDrawStart) {
      return;
    }

    if (!offset)
    { return; }

    if (!touches || !touches.length)
    { return; }

    const { x, y } = getClientPosition(event, true, 0);

    xPos = x - offset?.x;
    yPos = y - offset?.y;

    if (xPos === 0 && yPos === 0)
    { return; }

    cleanCanvas();
    isDrawStart = true;
  }

  const mouseMoveListener = (event: CanvasEvent) => {
    if (isIOS) {
      touchmove(event)
      return
    }

    if (!isDrawStart)
    { return; }

    if (!canvasRef.current)
    { return; }

    const offset = getClientOffset(canvasRef.current);

    if (!offset)
    { return; }

    const context = getContext();

    if (!context)
    { return; }

    const { x, y } = getClientPosition(event);

    cleanCanvas();
    drawLineWithArrowhead(
      context,
      { x: xPos, y: yPos },
      { x: x - offset.x, y: y - offset.y },
    );

    return offset;
  };

  const touchmove = (event: CanvasEvent) => {
    event.preventDefault();
    const touches = event.changedTouches;

    if (!canvasRef.current)
    { return; }

    const offset = getClientOffset(canvasRef.current);

    if (!offset)
    { return; }

    const context = getContext();

    if (!context)
    { return; }

    if (!touches || !touches.length)
    { return; }

    const { x, y } = getClientPosition(event, true, touches.length - 1);

    cleanCanvas();
    drawLineWithArrowhead(
      context,
      { x: xPos, y: yPos },
      { x: x - offset.x, y: y - offset.y },
    );
  };

  const mouseUpListener = (event: CanvasEvent) => {
    if (isIOS) {
      touchend(event)
      return
    }

    const context = getContext();

    if (!context)
    { return; }

    if (!canvasRef.current)
    { return; }

    const offset = getClientOffset(canvasRef.current);

    if (!offset)
    { return; }

    if (isDrawStart === true) {
      xPos = 0;
      yPos = 0;
      isDrawStart = false;

      timeOutRef = setTimeout(() => {
        cleanCanvas();
      }, 3000);
    }
  };

  const touchend = (event: CanvasEvent) => {
    event.preventDefault();

    const context = getContext();

    if (!context)
    { return; }

    if (!canvasRef.current)
    { return; }

    const offset = getClientOffset(canvasRef.current);

    if (!offset)
    { return; }

    if (isDrawStart === true) {
      xPos = 0;
      yPos = 0;
      isDrawStart = false;

      timeOutRef = setTimeout(() => {
        cleanCanvas();
      }, 3000);
    }
  };

  const drawArrowhead = (ctx, x: number, y: number, radians: number) => {
    ctx.save();

    // setup the style
    const lineStyle = getlineStyle();
    ctx.strokeStyle = lineStyle.strokeStyle;
    ctx.fillStyle = lineStyle.fillStyle;
    ctx.lineWidth = lineStyle.lineWidth;

    ctx.beginPath();
    ctx.translate(x, y);
    ctx.rotate(radians);

    ctx.moveTo(0, 0);
    ctx.lineTo(20, 45);
    ctx.lineTo(-20, 45);
    ctx.closePath();
    ctx.restore();
    ctx.fill();
  }

  const drawLineWithArrowhead = (ctx: CanvasRenderingContext2D, p1 : Pos, p2: Pos) => {
    // check if the line is too small
    if (Math.abs(p2.x - p1.x) < 5 && Math.abs(p2.y - p1.y) < 5) {
      return;
    }

    // setup the style
    const lineStyle = getlineStyle();
    ctx.strokeStyle = lineStyle.strokeStyle;
    ctx.fillStyle = lineStyle.fillStyle;
    ctx.lineWidth = lineStyle.lineWidth;

    // rem 10px to both ends so the arrowhead doesn't get cut off
    const length = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2)) - 10;

    // get the angle of the line in radians
    const angle = Math.atan2(p2.y - p1.y, p2.x - p1.x);

    // draw the line from p1 to p2
    ctx.beginPath();
    ctx.moveTo(p1.x, p1.y);
    ctx.lineTo(p1.x + length * Math.cos(angle), p1.y + length * Math.sin(angle));
    ctx.stroke();

    // draw the ending arrowhead
    let endRadians = Math.atan((p2.y - p1.y) / (p2.x - p1.x));
    endRadians += ((p2.x > p1.x) ? 90 : -90) * Math.PI / 180;

    drawArrowhead(ctx, p2.x, p2.y, endRadians);
  }

  const getlineStyle = () => {
    return {
      strokeStyle: '#FFFF00',
      fillStyle: '#FFFF00',
      lineWidth: 8,
    }
  }

  const initCanvas = () => {
    if (!canvasRef.current)
    { return; }

    onCanvasMounted();
  };

  return {
    clearCanvas,
    initCanvas,
    isDrawStart,
    mouseDownListener,
    mouseMoveListener,
    mouseUpListener,
    onCanvasMounted,
    prepareCanvas,
  };
}
