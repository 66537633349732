import React, { useState } from 'react'
import { StyleSheet } from 'react-native'
import { DNABox, DNACheckbox, DNAText } from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import { usePresentationBuilderState } from '../../state/PresentationBuilderStateProvider'
import { Action, usePresentationBuilderSharedResources } from '../PresentationEditor'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { useAppSettings } from 'src/state/context/AppSettings'

const style = StyleSheet.create({
  settings: {
    backgroundColor: colors['color-text-white'],
    borderColor: colors['color-gray-100'],
    borderRadius: 4,
    borderWidth: 1,
    minWidth: 210,
    paddingVertical: 16,
    paddingHorizontal: 20,
    shadowColor: colors['color-gray-900'],
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.16,
    shadowRadius: 11.22,
  },
});

const SecondaryActionButtons: React.FC<
{
  disablePreview?: boolean
}
> = ({ disablePreview }) => {
  const { getActionEls } = usePresentationBuilderSharedResources()
  const { platform } = useAppSettings()

  const {
    editorThumbnailSize,
    cycleEditorThumbnailSize,
    hiddenSlidesVisible,
    setHiddenSlidesVisible,
    displayPageSourceFile,
    setDisplayPageSourceFile,
    onPreview,
  } = usePresentationBuilderState()

  const [displaySettings, setDisplaySettings] = useState<boolean>(false);

  function toggleVisibilitySettings(): void {
    setDisplaySettings((val) => !val);
  }

  const actions: Action[] = [
    {
      disabled: disablePreview,
      iconLeft:'play',
      appearance:'ghost',
      status: 'tertiary',
      padding: 'sm',
      tooltip:'Preview',
      onPress: onPreview,
    },
    {
      hidden: platform === 'tabletPWA',
      iconLeft: editorThumbnailSize === 'lg' ? 'view-comfy' : 'view-grid',
      appearance:'ghost',
      status:'tertiary',
      padding: 'sm',
      tooltip:'Toggle thumbnail size',
      onPress: cycleEditorThumbnailSize,
    },
    {
      iconLeft: 'cog',
      appearance:'ghost',
      status:'tertiary',
      padding: 'sm',
      tooltip: 'Settings',
      onPress : toggleVisibilitySettings,
    },
  ]

  const actionEls = getActionEls(actions);
  /** TODO: refactor this once the composable action bar refactor
   * PR has has been merged in (which adds support for popovers)
   * see https://github.com/alucioinc/eeb/pull/1933 */
  const settingsActionEl = actionEls.pop()

  return (
    <DNABox spacing="sm">
      {actionEls}
      <DNAPopover
        lazyMount
        placement="bottom"
        interactive={true}
        visible={displaySettings}
        onBackdropPress={toggleVisibilitySettings}
        type="menu"
      >
        <DNAPopover.Anchor>
          {settingsActionEl}
        </DNAPopover.Anchor>
        <DNAPopover.Content>
          <DNABox spacing="sm" appearance="col" style={style.settings}>
            <DNABox spacing="sm">
              <DNACheckbox checked={displayPageSourceFile} onChange={setDisplayPageSourceFile}/>
              <DNAText>Show source file name</DNAText>
            </DNABox>
            <DNABox spacing="sm">
              <DNACheckbox
                testID="hidden-slide-checkbox"
                checked={hiddenSlidesVisible}
                onChange={setHiddenSlidesVisible}
              />
              <DNAText>Show hidden slides</DNAText>
            </DNABox>
          </DNABox>
        </DNAPopover.Content>
      </DNAPopover>
    </DNABox>
  )
};

export default React.memo(SecondaryActionButtons);
