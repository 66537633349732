import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Hub, Logger } from '@aws-amplify/core';
import subDays from 'date-fns/subDays';
import LoadingScreen from '../../screens/Loading'
import { Auth } from '@aws-amplify/auth'
import { DataStore, syncExpression } from '@aws-amplify/datastore';
import {
  useDataStoreSubscription,
  useSyncMachineSubscription,
  useOfflineManifestSubscription,
} from 'src/state/datastore/subscriptions';
import hydrate from '../../state/datastore/hydrate';
import { useAppSettings } from 'src/state/context/AppSettings';
import { useSelector } from 'react-redux';
import { RootState } from 'src/state/redux';
import { validate as uuidValidate } from 'uuid';
import {
  AttachedFile,
  CustomDeck,
  Document,
  DocumentVersion,
  EmailTemplate,
  Folder,
  Tenant,
  User,
  Meeting,
  FolderStatus,
  Hub as BeaconHub,
  HubStatus,
} from '@alucio/aws-beacon-amplify/src/models'
import { useLDClient } from 'launchdarkly-react-client-sdk';
import AuthUtil from '../Authenticator/services/authUtil';
import useLogOut from '../Authenticator/LogOut';
import { useValidateUser } from '../Authenticator/useValidateUser';

const logger = new Logger('UserInit', 'INFO');
// Having tenantId declared at this level let us update the value when needed
// and ensure that the syncExpressions has the correct value
// when they are executed at DataStore.start() call
let tenantId = ''
let createdBy = ''
const UserInit: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const isHydrated = useSelector((state: RootState) => state.document.hydrated && state.emailTemplate.hydrated);
  const [dataStoreReady, setDataStoreReady] = useState(isHydrated);
  const dataStoreSubscribed = useDataStoreSubscription(dataStoreReady);
  const { isOfflineEnabled } = useAppSettings();
  const { isPerformingLogOut } = useLogOut();
  const syncMachineSubscribed = useSyncMachineSubscription(dataStoreReady, isOfflineEnabled);
  useOfflineManifestSubscription()
  const ldClient = useLDClient()

  useEffect(() => {
    let removeListener: () => void | undefined
    const init = async () => {
      if (isPerformingLogOut) {
        return;
      }

      if (isOfflineEnabled !== undefined && ldClient && !isHydrated) {
        removeListener = Hub.listen('datastore', async (capsule) => {
          const { payload: { event } } = capsule;
          if (event === 'ready') {
            // Datastore is done syncing now populate redux
            logger.debug('Got ready event from datastore')
            await hydrate(isOfflineEnabled, ldClient);
            setDataStoreReady(true);
            logger.debug('Done hydrating redux')
            removeListener();
          }
        });

        // Configure datastore to use the tenantId GSI when querying dynamoDB rather than a scan
        let cognitoUser;
        const thirtyDaysAgo = subDays(new Date(), 30).toISOString();
        try {
          cognitoUser = await Auth.currentAuthenticatedUser();
        } catch (e) {
          if (localStorage.getItem('amplify-latest-user-attributes') !== null) {
            cognitoUser = JSON.parse(localStorage.getItem('amplify-latest-user-attributes') ?? '');
          } else {
            logger.error('Error getting current authenticated user', e);
          }
        }
        tenantId = cognitoUser?.attributes['custom:org_id']
        createdBy = cognitoUser?.attributes['custom:user_id']
        const syncExpressions = uuidValidate(tenantId) ? [
          syncExpression(AttachedFile, () => {
            return obj => obj.tenantId.eq(tenantId)
          }),
          syncExpression(CustomDeck, () => {
            return obj => obj.tenantId.eq(tenantId)
          }),
          syncExpression(Document, () => {
            return obj => obj.tenantId.eq(tenantId)
          }),
          syncExpression(DocumentVersion, () => {
            return obj => obj.tenantId.eq(tenantId)
          }),
          syncExpression(EmailTemplate, () => {
            return obj => obj.tenantId.eq(tenantId)
          }),
          syncExpression(Folder, () => {
            return obj => obj.and(p => [p.tenantId.eq(tenantId), p.status.ne(FolderStatus.REMOVED)])
          }),
          syncExpression(Tenant, () => {
            return obj => obj.id.eq(tenantId)
          }),
          syncExpression(User, () => {
            return obj => obj.tenantId.eq(tenantId)
          }),
          syncExpression(Meeting, () => {
            return obj => obj.and(p => [p.createdBy.eq(createdBy), p.startTime.gt(thirtyDaysAgo)]);
          }),
          syncExpression(BeaconHub, () => {
            return obj => obj.and(p => [p.createdBy.eq(createdBy), p.status.ne(HubStatus.DELETED)]);
          }),
        ] : [];

        DataStore.configure({
          syncPageSize: 250,
          errorHandler: (error) => logger.warn('Datastore Sync Error', error),
          syncExpressions: syncExpressions,
        })

        // Start the DataStore, this kicks-off the sync process.
        logger.debug('Calling DataStore.start')
        AuthUtil.dataStoreInit()
      }
    }
    init()
    return () => {
      logger.debug('Hub removeListener called')
      removeListener?.();
    }
  }, [isOfflineEnabled, ldClient, isHydrated, isPerformingLogOut]);

  const isReady = (
    dataStoreReady &&
    dataStoreSubscribed &&
    (!isOfflineEnabled || syncMachineSubscribed) &&
    isHydrated
  )

  return isReady ? <>{children}</> : <LoadingScreen />
};

/*
  * This component is used to prevent the compenet will be rendered when the user is logging out or logging in
*/
const UserAuthWrapper: React.FC<PropsWithChildren> = (props) => {
  const { isPerformingLogOut } = useLogOut();
  const { isValidatingUser } = useValidateUser()
  const { isOnline } = useAppSettings();

  const { children } = props;

  if (isValidatingUser && isOnline) return <LoadingScreen />
  if (isPerformingLogOut) return <LoadingScreen />

  return (
    <UserInit>
      {children}
    </UserInit>
  )
}

export default UserAuthWrapper;
