import React, { useState } from 'react'
import { useDispatch } from 'src/state/redux'

import { DocumentORM, FolderItemORM, FolderORM } from 'src/types/types'
import { FolderItemStatus } from '@alucio/aws-beacon-amplify/src/models';

import { DNAModal, DNABox, DNAButton, DNAText, GenericToast, ToastOrientations, useDisableSwipe } from '@alucio/lux-ui'
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal'
import DNAFolderNestedList, { FolderSelectedMap } from 'src/components/DNA/Folder/NestedList/DNAFolderNestedList'
import DNAFolderUpsertModal from 'src/components/DNA/Modal/DNAFolderUpsert'
import { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast'
import { folderActions } from 'src/state/redux/slice/folder'
import * as guards from 'src/types/typeguards'
import DNAConditionalButton from '../ConditionalButton/DNAConditionalButton';

export type DNAFolderArchiveAddItem = {
  itemORM: DocumentORM | FolderItemORM | DocumentORM[]
  toast?: ToastActions
}

export const getDocumentORMFromItem = (itemORM: DocumentORM | FolderItemORM | undefined) : DocumentORM | undefined => (
  guards.isDocumentORM(itemORM)
    ? itemORM
    : guards.isDocumentVersionORM(itemORM?.relations.itemORM)
      ? itemORM?.relations.itemORM.relations.documentORM
      : undefined
)

const trackDocument = (folder: FolderORM, itemORM: DocumentORM | FolderItemORM, isBulkAdd?: boolean) => {
  if (guards.isFolderItemORM(itemORM) && guards.isCustomDeckORM(itemORM.relations.itemORM)) {
    const customDeck = itemORM.relations.itemORM;
    analytics?.track('FOLDER_ADD_CUSTOM_DECK', {
      action: 'ADD_CUSTOM_DECK',
      category: 'FOLDER',
      folderId: folder.model.id,
      customDecktId: customDeck.model.id,
    });
  }
  else {
    const document = getDocumentORMFromItem(itemORM)
    analytics?.track('FOLDER_ADD_DOCUMENT_VERSION', {
      action: 'ADD_DOCUMENT_VERSION',
      category: 'FOLDER',
      context: isBulkAdd ? 'BULK_ADD' : 'ADD_DOCUMENT',
      folderId: folder.model.id,
      documentId: document!.model.id,
      documentVersionId: document?.relations?.version?.latestPublishedDocumentVersionORM?.model.id,
    });
  }
}

export const DNAFolderArchiveModal: DNAModalVariant<DNAFolderArchiveAddItem> = (props) => {
  const { itemORM, toggleModal, pushModal, closeModal, toast } = props
  const [selected, setSelected] = useState<FolderSelectedMap>({})

  const dispatch = useDispatch()
  useDisableSwipe()

  const targetFolders = Object
    .values(selected)
    .filter(selection => selection && !selection.locked)
    .map(selection => selection?.folder) as FolderORM[]

  const onAction = () => {
    const bulkItemORM = Array.isArray(itemORM) && itemORM.length > 1 ? itemORM : undefined
    const singleItemORM = Array.isArray(itemORM) ? !bulkItemORM ? itemORM[0] : undefined : itemORM

    if (targetFolders.length) {
      targetFolders.forEach(folder => {
        if (bulkItemORM) {
          // Filter out documents that is already in the folder
          const existingDoc = folder.model.items
            .filter(doc => doc.status === FolderItemStatus.ACTIVE)
            .map(doc => doc.itemId)
          const filteredBulkItemORM = bulkItemORM
            .filter(doc => !existingDoc.includes(doc.relations.version.latestUsableDocumentVersionORM.model.id))
          filteredBulkItemORM.forEach(itemORM => trackDocument(folder, itemORM, true))
          dispatch(folderActions.addDocument(filteredBulkItemORM, folder))
        }
        else if (singleItemORM) {
          trackDocument(folder, singleItemORM);
          if (guards.isDocumentORM(singleItemORM)) dispatch(folderActions.addDocument([singleItemORM], folder))
          else if (guards.isFolderItemORM(singleItemORM)) {
            if (guards.isCustomDeckORM(singleItemORM.relations.itemORM)) {
              dispatch(folderActions.createCustomDeck(
                folder, singleItemORM.relations.itemORM.model.groups, singleItemORM.model.customTitle || ''));
            } else {
              dispatch(folderActions.addFolderItem(singleItemORM, folder));
            }
          }
        }
      })
      toast?.add(
        <GenericToast
          title={ (bulkItemORM ? 'Files' : 'File') + ' added to My Folders.'}
          status="success"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    }
    toggleModal()
  }

  const onCreateFolder = () => {
    pushModal((props) => (<DNAFolderUpsertModal {...props} toast={toast} />))
  }

  return (
    <DNAModal>
      <DNAModal.Header onClose={closeModal}>
        <DNABox spacing="sm">
          <DNAText h5>Add file(s) to my Folders</DNAText>
          <DNAText status="subtle">{targetFolders.length} selected</DNAText>
        </DNABox>
      </DNAModal.Header>
      <DNAModal.Body>
        <DNAFolderNestedList
          itemORM={itemORM}
          onFolderSelect={setSelected}
        />
      </DNAModal.Body>
      <DNAModal.Confirmation>
        <DNAButton
          testID="create-new-folder-Button"
          onPress={onCreateFolder}
          appearance="outline"
        >Create new folder</DNAButton>
        <DNAConditionalButton
          onPress={onAction}
          testID="add-to-folder-confirm-button"
        >
          <DNAConditionalButton.Disable condition={!targetFolders.length}>
            <DNAText status="basic">Select destination folder(s)</DNAText>
          </DNAConditionalButton.Disable>
          Done
        </DNAConditionalButton>
      </DNAModal.Confirmation>
    </DNAModal>
  )
}

export default DNAFolderArchiveModal
