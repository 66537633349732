import React, { useMemo, useState, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import {
  DNABox,
  DNAButton,
  DNAText,
  Iffy,
  TextField,
} from '@alucio/lux-ui/src';
import { HubSectionType } from '@alucio/aws-beacon-amplify/src/models';
import { WidgetContentCardProps, widgetContentCardStyles, WidgetContextMenu } from 'src/screens/Hubs/EditHub/Widgets/WidgetContentCard';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import { HubFormWidgetKeys } from '../useHubRHFormStateProvider';

const styles = StyleSheet.create({
  editHubTitleIcon: {
    marginLeft: 6,
  },
  editHeaderInput: {
    maxWidth: '100%',
  },
  cardBody: {
    height: 144,
  },
  textarea: {
    width: '100%',
  },
  textareaTextStyle: {
    height: 128,
  },
});

const Textarea: React.FC<WidgetContentCardProps> = ({ widget, rhform }) => {
  const { hubORM } = useHubsState()
  const [header, setHeader] = useState<string>(widget.textarea?.header || 'Header Text')
  const [previousHeader, setPreviousHeader] = useState<string>(widget.textarea?.header || 'Header Text')
  const [editHeaderMode, setEditHeaderMode] = useState<boolean>(false)
  const [content, setContent] = useState<string | undefined>(widget.textarea?.content)

  useEffect(() => {
    /* 
    upon widgets list getting updated (added or deleted new widgets),
    we need to set the form value to the current unsave values within the widget.
    or else the value in widgets will reset to the previous saved value when user add or delete other widgets.
    we also compairs the current value with the previous value to determine if the form is dirty.
    */
    if (!hubORM) return
    const lastSavedTextareaWidget = hubORM.model.hubSections?.find(section => section.type === HubSectionType.TEXTAREA)
    let shouldDirty
    const basicFieldKeys = ['order', 'type', 'visible']
    if (lastSavedTextareaWidget) {
      Object.keys(lastSavedTextareaWidget).forEach(key => {
        const formDirtyCond = 
          ((key === HubFormWidgetKeys.textarea) && (
            (lastSavedTextareaWidget.textarea?.header !== widget.textarea?.header) ||
            (lastSavedTextareaWidget.textarea?.content !== widget.textarea?.content)
          )) ||
          (basicFieldKeys.includes(key) && lastSavedTextareaWidget[key] !== widget[key])
        
        if (formDirtyCond) shouldDirty = true
      })
    } else shouldDirty = true
    rhform.setValue('textareaWidget', widget, { shouldDirty })
  }, [hubORM, widget])

  const cancelHeaderChange = useMemo(() => () => {
    setEditHeaderMode(false)
    setHeader(previousHeader)
  }, [previousHeader])

  const updateHeader = useMemo(() => () => {
    const textareaWidget = rhform.getValues().textareaWidget
    rhform.setValue('textareaWidget',
      { ...textareaWidget, textarea: { ...textareaWidget?.textarea, header } }, { shouldDirty: true })
  }, [header, rhform, widget])

  const onSubmitHeader = useMemo(() => () => {
    setEditHeaderMode(false)
    setPreviousHeader(header)
    updateHeader()
  }, [header, updateHeader, rhform])

  const updateContent = useMemo(() => (text: string) => {
    setContent(text)
    const textareaWidget = rhform.getValues().textareaWidget
    rhform.setValue('textareaWidget',
      { ...textareaWidget, textarea: { ...textareaWidget?.textarea, content: text } },
      { shouldDirty: true })
  }, [content, setContent, rhform, widget])

  if (widget.type !== HubSectionType.TEXTAREA) return null

  return (
    <>
      {/* Card Header */}
      <DNABox fill style={ widgetContentCardStyles.cardHeader }>
        <DNABox
          fill
          alignY="center"
          childFill={0}
        >
          {/* Hub Title Read Only Mode - Text & Edit Button */}
          <Iffy is={!editHeaderMode}>
            <DNABox fill childFill={0} alignY="center">
              <DNAText h4 bold numberOfLines={1} style={ styles.editHeaderInput }>{ header }</DNAText>
              <DNAButton
                appearance="ghostLink"
                status="gray"
                iconLeft="pencil"
                padding="sm"
                size="md"
                style={ styles.editHubTitleIcon }
                onPress={() => setEditHeaderMode(true)}
              />
            </DNABox>
            <WidgetContextMenu widgetType={HubSectionType.TEXTAREA} />
          </Iffy>
          {/* Edit Hub Title Mode - Input & Buttons */}
          <DNABox
            fill
            alignY="center"
            spacing="sm"
            childFill={0}
            style={!editHeaderMode && { display: 'none' }}
          >
            <DNABox fill alignY="center">
              <TextField.Kitten
                style={ styles.textarea }
                value={header}
                maxLength={150}
                onChangeText={setHeader}
                onKeyPress={e => {
                  if (e.nativeEvent.key === 'Enter') onSubmitHeader()
                }}
              />
            </DNABox>
            <DNAButton
              appearance="outline"
              status="tertiary"
              padding="sm"
              onPress={() => cancelHeaderChange()}
              children="Cancel"
            />
            <DNAButton
              padding="sm"
              onPress={() => onSubmitHeader()}
              children="Done"
            />
          </DNABox>
        </DNABox>
      </DNABox>
      {/* Card Content */}
      <DNABox fill alignY="center" style={ widgetContentCardStyles.cardBody }>
        <TextField.Kitten
          placeholder="Enter text here..."
          multiline={true}
          style={ styles.textarea }
          textStyle={ styles.textareaTextStyle }
          value={content}
          onChangeText={updateContent}
        />
      </DNABox>
    </>
  )
}

export default Textarea
