import { EventObject, GuardMeta, StateMeta } from 'xstate'
import { ToastActions } from '@alucio/lux-ui/lib/components/Toast/useToast'
import { API, graphqlOperation, GraphQLResult } from '@aws-amplify/api';

import {
  AttachedFile,
  DocumentStatus,
  Document,
  ShareType,
  Tenant,
  CustomFieldDefinition,
  CustomValues,
  User,
  EmailTemplate,
  ContentShare,
} from '@alucio/aws-beacon-amplify/src/models'
import { AssociatedFileORM, DocumentVersionORM, EmailTemplateORM, HubORM } from 'src/types/orms'
import { DOCUMENT_ACTIONS_ENUM } from 'src/types/types'
import { isNextStep, isPrevStep, ShareContext, ShareEvents, STEP_STATE, ShareFileOptionMapping } from './shareTypes'
import {
  shareEmail,
  shareEmailTemplate as shareEmailTemplateOG,
  copyEmailTemplateToClipboard,
  copyShareableLinkToClipboard,
  ContentShareProps,
  ShareFileOption,
  ExistingShareLink,
} from 'src/utils/shareLink/shareLink.web'
import { ShareableLinkResult } from 'src/utils/shareLink/common'
import { canPerformAction } from 'src/state/redux/selector/document';
import { SHARE_METHOD } from 'src/components/DNA/Modal/DNAFileShareModal/state/context/DNAFileShareModalStateProvider'
import { getContentShare as getContentShareByID } from '@alucio/aws-beacon-amplify/src/graphql/queries';
import { contentShareActions } from 'src/state/redux/slice/contentShare';
import { store } from 'src/state/redux';

export function getCurrentState<T extends EventObject>(state: StateMeta<ShareContext, T>['state']) {
  const states = [STEP_STATE.CUSTOM_FIELDS, STEP_STATE.ASSOCIATED_FILES, STEP_STATE.EMAIL_TEMPLATES]
  return states.find(targetState => state.matches(`form.${targetState}`))
}

export const initialStep = (step: STEP_STATE) => (
  ctx: ShareContext,
  _: ShareEvents,
  __: GuardMeta<ShareContext, ShareEvents>,
) => {
  return ctx.availableStates[0] === step
}

export function stepToGuard<T extends EventObject>(step: STEP_STATE) {
  return (
    ctx: ShareContext,
    event: ShareEvents,
    meta: GuardMeta<ShareContext, T>,
  ) => {
    const currentState = getCurrentState<T>(meta.state)

    const targetStep = ctx.availableStates.indexOf(step)

    if (!currentState) {
      throw new Error('Could not determine correct current step')
    }

    if (targetStep === -1) return false

    const currentStep = ctx.availableStates.indexOf(currentState)

    return isPrevStep(event)
      ? currentStep === targetStep + 1
      : isNextStep(event)
        ? currentStep === targetStep - 1
        : false
  }
}

export const getPublishedAndDistributableAssociatedFiles = (associatedFiles: AssociatedFileORM[]) => {
  return associatedFiles.filter(({ file, meta: { canBeSharedByMSL } }) => {
    if (file instanceof AttachedFile) {
      return canBeSharedByMSL
    } else if (file instanceof Document) {
      /** Otherwise, check if document can be shared and is published */
      return (file as Document).status === DocumentStatus.PUBLISHED && canBeSharedByMSL
    } else {
      return false
    }
  })
}

const getAllShareFileOption = (shareFileOptionMapping: ShareFileOptionMapping) => {
  return Object.values(shareFileOptionMapping).flat(1)
}

const removeDuplicateShareFileOption = (allShareFileOption: ShareFileOption[]): ShareFileOption[] => {
  const uniqueShareFileOption: ShareFileOption[] = []
  const uniqueSet = new Set()
  allShareFileOption.forEach(shareFileOption => {
    if (!uniqueSet.has(shareFileOption.contentProps.contentId)) {
      uniqueSet.add(shareFileOption.contentProps.contentId)
      uniqueShareFileOption.push(shareFileOption)
    } else if (shareFileOption.isMainDoc) {
      // Swap the non-main doc to the main doc
      const itemToRemove = uniqueShareFileOption
        .find(sfo => sfo.contentProps.contentId === shareFileOption.contentProps.contentId)
      if (itemToRemove) {
        const removeIndex = uniqueShareFileOption.indexOf(itemToRemove)
        uniqueShareFileOption.splice(removeIndex, 1)
        uniqueShareFileOption.push(shareFileOption)
      }
    }
  })
  return uniqueShareFileOption
}

export const shareHubsLink = async (
  hubORM: HubORM,
  user: User,
  customValues: CustomValues[],
  toast: ToastActions,
  existingLinks?: ExistingShareLink[],
) => {
  if (!existingLinks) {
    analytics?.track('HUB_SHARE', {
      action: 'SHARE',
      category: 'HUB',
      hubId: hubORM.model.id,
    });
  }

  const hubShareProps = {
    contentId: hubORM.model.id,
    title: hubORM.model.name,
    type: ShareType.HUB,
  }
  const shareOptions = {
    isMainDoc: false,
    isDefault: false,
    beingShared: false,
    isDistributable: false,
    contentProps: hubShareProps,
  }
  const linkResults = shareEmail([shareOptions], user, customValues, toast, undefined, existingLinks)
  return linkResults
}

export const shareEmailLinks = async (
  shareFileOptionMapping: ShareFileOptionMapping,
  user: User,
  customValues: CustomValues[],
  toast: ToastActions,
  meetingId?: string,
) => {
  const allShareFileOption = getAllShareFileOption(shareFileOptionMapping)
  const uniqueShareFileOption = removeDuplicateShareFileOption(allShareFileOption)
  shareEmail(uniqueShareFileOption, user, customValues, toast, meetingId)

  // [TODO] - Analytics
}

export const shareHubsEmailTemplate = async (
  hubORM: HubORM,
  attachmentsInfo: ContentShareProps[],
  emailTemplate: EmailTemplate,
  customValues: CustomValues[],
  toast: ToastActions,
  existingShareLinks?: ExistingShareLink[],
) => {
  if (!existingShareLinks) {
    analytics?.track('HUB_SHARE', {
      action: 'SHARE',
      category: 'HUB',
      hubId: hubORM.model.id,
    });
  }

  const hubShareProps = {
    contentId: hubORM.model.id,
    title: hubORM.model.name,
    type: ShareType.HUB,
  }
  const shareOptions = {
    isMainDoc: false,
    isDefault: false,
    beingShared: false,
    isDistributable: false,
    contentProps: hubShareProps,
  }
  const linkResults = await shareEmailTemplateOG(
    [shareOptions],
    attachmentsInfo,
    emailTemplate,
    customValues,
    toast,
    undefined,
    existingShareLinks,
  )
  return linkResults
}

export const shareEmailTemplate = async (
  emailTemplateORM: EmailTemplateORM,
  documentVersionORMs: DocumentVersionORM[],
  shareFileOptionMapping: ShareFileOptionMapping,
  attachmentsInfo: ContentShareProps[],
  emailTemplate: EmailTemplate,
  customValues: CustomValues[],
  toast: ToastActions,
  meetingId?: string,
) => {
  const allShareFileOption = getAllShareFileOption(shareFileOptionMapping)
  const uniqueShareFileOption = removeDuplicateShareFileOption(allShareFileOption)

  await shareEmailTemplateOG(uniqueShareFileOption, attachmentsInfo, emailTemplate, customValues, toast, meetingId)

  // Below code is for analytics tracking
  const contentIdMapping: {[contentId: string]: string} = {}
  for (const documentVersionId in shareFileOptionMapping) {
    shareFileOptionMapping[documentVersionId].forEach(shareFileOption => {
      if (!contentIdMapping[shareFileOption.contentProps.contentId]) {
        contentIdMapping[shareFileOption.contentProps.contentId] = documentVersionId

        const isMainDocBeingShared = shareFileOption.isMainDoc && shareFileOption.beingShared
        const documentVersionORM = documentVersionORMs.find(docVer => docVer.model.id === documentVersionId)
        if (isMainDocBeingShared && documentVersionORM) {
          analytics?.track('DOCUMENT_SHARE', {
            action: 'SHARE',
            channel: 'EMAIL',
            category: 'DOCUMENT',
            documentId: documentVersionORM.model.documentId,
            documentVersionId: documentVersionORM.model.id,
            emailTemplateId: emailTemplateORM.model.id,
            context: documentVersionORMs.length > 1 ? 'BULK_SHARE' : 'SHARE',
          })
        } else if (documentVersionORM) {
          analytics?.track('DOCUMENT_AF_SHARE', {
            action: 'AF_SHARE',
            attachedContentId: shareFileOption.contentProps.contentId,
            category: 'DOCUMENT',
            channel: 'EMAIL',
            documentId: documentVersionORM.model.documentId,
            documentVersionId: documentVersionORM.model.id,
            sharedContentId: shareFileOption.contentProps.sharedContentId,
            type: shareFileOption.contentProps.type === 'ATTACHED_FILE' ? 'UPLOAD' : 'LINK',
          })
        }
      }
    })
  }
}

export const copyHubsEmailTemplate = async (
  hubORM: HubORM,
  toast: ToastActions,
  attachmentsInfo: ContentShareProps[],
  emailTemplate: EmailTemplate,
  customValues: CustomValues[],
  existingShareLinks?: ExistingShareLink[],
) => {
  if (!existingShareLinks) {
    analytics?.track('HUB_SHARE', {
      action: 'SHARE',
      category: 'HUB',
      hubId: hubORM.model.id,
    });
  }

  const hubShareProps = {
    contentId: hubORM.model.id,
    title: hubORM.model.name,
    type: ShareType.HUB,
  }
  const shareOptions = {
    isMainDoc: false,
    isDefault: false,
    beingShared: false,
    isDistributable: false,
    contentProps: hubShareProps,
  }
  const linkResults = await copyEmailTemplateToClipboard(
    toast,
    [shareOptions],
    attachmentsInfo,
    emailTemplate,
    customValues,
    undefined,
    existingShareLinks,
  )
  return linkResults
}

export const copyShareEmailTemplate = async (
  emailTemplateORM: EmailTemplateORM,
  documentVersionORMs: DocumentVersionORM[],
  toast: ToastActions,
  shareFileOptionMapping: ShareFileOptionMapping,
  attachmentsInfo: ContentShareProps[],
  emailTemplate: EmailTemplate,
  customValues: CustomValues[],
  meetingId?: string,
) => {
  const allShareFileOption = getAllShareFileOption(shareFileOptionMapping)
  const uniqueShareFileOption = removeDuplicateShareFileOption(allShareFileOption)

  await copyEmailTemplateToClipboard(
    toast,
    uniqueShareFileOption,
    attachmentsInfo,
    emailTemplate,
    customValues,
    meetingId,
  )

  // Below code is for analytics tracking
  const contentIdMapping: {[contentId: string]: string} = {}
  for (const documentVersionId in shareFileOptionMapping) {
    shareFileOptionMapping[documentVersionId].forEach(shareFileOption => {
      if (!contentIdMapping[shareFileOption.contentProps.contentId]) {
        contentIdMapping[shareFileOption.contentProps.contentId] = documentVersionId

        const isMainDocBeingShared = shareFileOption.isMainDoc && shareFileOption.beingShared
        const documentVersionORM = documentVersionORMs.find(docVer => docVer.model.id === documentVersionId)
        if (isMainDocBeingShared && documentVersionORM) {
          analytics?.track('DOCUMENT_SHARE', {
            action: 'SHARE',
            channel: 'EMAIL',
            category: 'DOCUMENT',
            documentId: documentVersionORM.model.documentId,
            documentVersionId: documentVersionORM.model.id,
            emailTemplateId: emailTemplateORM.model.id,
            context: documentVersionORMs.length > 1 ? 'BULK_SHARE' : 'SHARE',
          })
        } else if (documentVersionORM) {
          analytics?.track('DOCUMENT_AF_SHARE', {
            action: 'AF_SHARE',
            attachedContentId: shareFileOption.contentProps.contentId,
            category: 'DOCUMENT',
            channel: 'EMAIL',
            documentId: documentVersionORM.model.documentId,
            documentVersionId: documentVersionORM.model.id,
            sharedContentId: shareFileOption.contentProps.sharedContentId ?? null,
            type: shareFileOption.contentProps.type === 'ATTACHED_FILE' ? 'UPLOAD' : 'LINK',
          })
        }
      }
    })
  }
}

export const copyHubsLink = async (
  hubORM: HubORM,
  toast: ToastActions,
  customValues: CustomValues[],
  existingLinks?: ExistingShareLink[],
) => {
  if (!existingLinks) {
    analytics?.track('HUB_SHARE', {
      action: 'SHARE',
      category: 'HUB',
      hubId: hubORM.model.id,
    });
  }

  const hubShareProps = {
    contentId: hubORM.model.id,
    title: hubORM.model.name,
    type: ShareType.HUB,
  }
  const shareOptions = {
    isMainDoc: false,
    isDefault: false,
    beingShared: false,
    isDistributable: false,
    contentProps: hubShareProps,
  }
  const linkResults = await copyShareableLinkToClipboard([shareOptions], toast, customValues, undefined, existingLinks)
  return linkResults
}

export const copyLink = async (
  documentVersionORMs: DocumentVersionORM[],
  shareFileOptionMapping: ShareFileOptionMapping,
  toast: ToastActions,
  customValues: CustomValues[],
  meetingId?: string,
) => {
  const allShareFileOption = getAllShareFileOption(shareFileOptionMapping)
  const uniqueShareFileOption = removeDuplicateShareFileOption(allShareFileOption)
  const linkResults =
    await copyShareableLinkToClipboard(uniqueShareFileOption, toast, customValues, meetingId) as ShareableLinkResult[]

  // Below code is for analytics tracking
  const contentIdMapping: {[contentId: string]: string} = {}
  for (const documentVersionId in shareFileOptionMapping) {
    shareFileOptionMapping[documentVersionId].forEach(shareFileOption => {
      if (!contentIdMapping[shareFileOption.contentProps.contentId]) {
        contentIdMapping[shareFileOption.contentProps.contentId] = documentVersionId
      }
    })
  }

  linkResults.forEach((shareableLinkResult: ShareableLinkResult) => {
    const documentVersionORM = documentVersionORMs.find(docVer => docVer.model.id === shareableLinkResult.contentId)
    if (documentVersionORM) {
      analytics?.track('DOCUMENT_SHARE', {
        action: 'SHARE',
        channel: 'LINK',
        category: 'DOCUMENT',
        documentId: documentVersionORM.model.documentId,
        documentVersionId: documentVersionORM.model.id,
        shareId: shareableLinkResult.id,
        context: documentVersionORMs.length > 1 ? 'BULK_SHARE' : 'SHARE',
      });
      return
    }

    const documentVersionId = contentIdMapping[shareableLinkResult.contentId]
    const documentVersionORM_AF = documentVersionORMs.find(docVer => docVer.model.id === documentVersionId)
    if (documentVersionORM_AF) {
      analytics?.track('DOCUMENT_AF_SHARE', {
        action: 'AF_SHARE',
        attachedContentId: shareableLinkResult.contentId,
        category: 'DOCUMENT',
        channel: 'LINK',
        documentId: documentVersionORM_AF.model.documentId,
        documentVersionId: documentVersionORM_AF.model.id,
        shareId: shareableLinkResult.id,
        type: shareableLinkResult.type === 'ATTACHED_FILE' ? 'UPLOAD' : 'LINK',
      });
    }
  });
}

export const getMainFileShareOptions = (
  documentVersionORM: DocumentVersionORM,
  tenant: Tenant,
  beingShared: boolean = true,
): ShareFileOption => ({
  isMainDoc: true,
  isDefault: false,
  beingShared,
  isDistributable: canPerformAction(
    DOCUMENT_ACTIONS_ENUM.share,
    documentVersionORM.meta.customValues.configsMap,
    tenant,
  ),
  contentProps: {
    contentId: documentVersionORM.model.id,
    title: documentVersionORM?.model.title,
    type: ShareType.DOCUMENT_VERSION,
  },
})

export const isHeadlessMode = (
  customFields: CustomFieldDefinition[],
  associatedFiles: AssociatedFileORM[],
  emailTemplates: EmailTemplateORM[],
  shareMethod: SHARE_METHOD,
) => {
  const validAssociatedFiles = getPublishedAndDistributableAssociatedFiles(associatedFiles)

  return (
    !customFields.length &&
    !validAssociatedFiles.length &&
    (shareMethod === SHARE_METHOD.EMAIL && !emailTemplates.length)
  )
}

export const getAndUpdateReduxContentShare = async (
  contentShareId: string,
): Promise<void> => {
  const { data } = await API.graphql(
    graphqlOperation(getContentShareByID, {
      id: contentShareId,
    }),
  ) as GraphQLResult<{
    getContentShare: ContentShare
  }>
  if (data?.getContentShare) {
    store.dispatch(contentShareActions.upsert(data?.getContentShare))
  }
  // TODO error handling?
}
