import React from 'react'
import { DNABox, DNAButton, DNAText } from '@alucio/lux-ui'
import { useAppSettings } from 'src/state/context/AppSettings';
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import { useUserTenant } from 'src/state/redux/selector/user';
import { MEETING_SUBMIT_TYPE, useAddMeeting } from './AddMeetingProvider';
import { useActor } from '@xstate/react';
import { CRMAvailabilityStatus, CRMConnectionStatus } from 'src/state/machines/CRM/crmMachineTypes';
import { ERR_TOKEN_EXPIRED } from 'src/state/machines/CRM/crmMachine';

const MeetingCRMAuth = () => {
  const currentTenant = useUserTenant();
  const { crmMachine } = useAppSettings();
  const { connectionStatus, availabilityStatus } = useCRMStatus();
  const { isValid } = useAddMeeting()

  const [state, send] = useActor(crmMachine);
  const { context } = state;

  const { onSave } = useAddMeeting()

  const msg = context.error === ERR_TOKEN_EXPIRED 
    ? ERR_TOKEN_EXPIRED
    : 'Your organization has enabled CRM integration. Please log into your CRM to sync the latest data.';

  const btnMsg = context.error === ERR_TOKEN_EXPIRED
    ? 'Reconnect to CRM'
    : 'Connect to CRM';

  if (availabilityStatus === CRMAvailabilityStatus.DISABLED) return null;
  if (connectionStatus === CRMConnectionStatus.CONNECTED) return null;

  const handleConnect = async () => {
    if (!isValid) return;

    onSave(MEETING_SUBMIT_TYPE.SILENT_SAVE);
    send({
      type: 'LOGIN_CRM',
      payload: currentTenant?.config.crmIntegration,
    })
  }

  return (
    <DNABox
      appearance="col"
      spacing="md"
      alignX="center"
      alignY="center"
      fill
      style={{ backgroundColor: 'white', minHeight: 400 }}
    >
      <DNAText status="flat">
        {msg}
      </DNAText>
      <DNAButton onPress={handleConnect} disabled={!isValid}>
        {btnMsg}
      </DNAButton>
    </DNABox>
  )
}

export default MeetingCRMAuth
