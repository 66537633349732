import React, { useEffect, useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import {
  DNABox,
  DNAButton,
  DNAChip,
  DNADivider,
  DNAIcon,
  DNAText,
  Iffy,
  LuxStatus,
  LuxStatusEnum,
  Popover,
} from '@alucio/lux-ui';
import { CrmIntegrationType, MeetingStatus } from '@alucio/aws-beacon-amplify/src/models';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { MEETING_SUBMIT_TYPE, useAddMeeting } from './AddMeetingProvider';
import useFeatureFlag from 'src/hooks/useFeatureFlag/useFeatureFlag';
import { FeatureFlags } from 'src/types/featureFlags';
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import { CRMConnectionStatus } from 'src/state/machines/CRM/crmMachineTypes';
import DNAPopover from '../DNA/Popover/DNAPopover';
import { S } from 'src/screens/Publishers/Versioning/VersioningPanel';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { useDispatch } from 'react-redux';
import DNACommonConfirmation from '../DNA/Modal/DNACommonConfirmation';
import { useAppSettings } from 'src/state/context/AppSettings';
import { MeetingORM } from 'src/types/orms';
import { useUserTenant } from 'src/state/redux/selector/user';

const styles = StyleSheet.create({
  content: {
    backgroundColor: colors['color-text-white'],
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
})

enum DEMO_SYNCING_STATUS {
  IDLE = 'Sync to CRM',
  SYNCING = 'Syncing...',
  SYNCED = 'Synced'
}

interface SyncMetaType {
  [x: string]: {
    color: LuxStatus,
    icon?: string,
  }
}

const SYNCING_BUTTON_META: SyncMetaType = {
  [DEMO_SYNCING_STATUS.IDLE]: {
    color: LuxStatusEnum.tertiary,
    icon: undefined,
  },
  [DEMO_SYNCING_STATUS.SYNCING]: {
    color: LuxStatusEnum.tertiary,
    icon: 'sync',
  },
  [DEMO_SYNCING_STATUS.SYNCED]: {
    color: LuxStatusEnum.success,
    icon: 'check',
  },
};

export const useCanDeleteMetting = (meetingORM: MeetingORM | undefined) => {
  const { isOnline } = useAppSettings();
  const { connectionStatus } = useCRMStatus();
  // The user is offline (as the request to the SF api to delete it can’t be sent)
  const deleteDisabledDueOffline = !isOnline && !!meetingORM?.model.crmRecord?.crmCallId
  // The user is not connected to CRM.
  const deleteDisabledDueCRMConnection = connectionStatus !== CRMConnectionStatus.CONNECTED &&
     !!meetingORM?.model.crmRecord?.crmCallId

  const [isDeletedDisabled, setDeleteDisabled] = useState<{
    deleteDisabled: boolean;
    deleteDisabledMessage: string;
  }>({
    deleteDisabled: false,
    deleteDisabledMessage: '',
  });

  useEffect(() => {
    if (deleteDisabledDueOffline) {
      setDeleteDisabled({
        deleteDisabled: true,
        deleteDisabledMessage: 'Please go online to delete the meeting.',
      });
    } else if (deleteDisabledDueCRMConnection) {
      setDeleteDisabled({
        deleteDisabled: true,
        deleteDisabledMessage: 'Please connect to CRM to delete the meeting.',
      });
    } else {
      setDeleteDisabled({
        deleteDisabled: false,
        deleteDisabledMessage: '',
      });
    }
  }, [deleteDisabledDueOffline, deleteDisabledDueCRMConnection]);

  return isDeletedDisabled;
}

const AddMeetingFooter = () => {
  const {
    onCancel,
    onDelete,
    isSubmitting,
    onSave,
    meetingORM,
    isReadOnly,
  } = useAddMeeting()
  const dateLastSyncedAt = new Date(meetingORM?.model.crmRecord?.lastSyncedAt || '');
  const lastSyncedAt = 'Saved to CRM on ' + dateLastSyncedAt.toLocaleDateString() + ' at ' +
    dateLastSyncedAt.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const [demoSyncingStatus, setDemoSyncingStatus] = useState<DEMO_SYNCING_STATUS>(DEMO_SYNCING_STATUS.IDLE)
  const { connectionStatus } = useCRMStatus();
  const [uiDisabled, setUIDisabled] = useState<boolean>(false)
  const isDemoModeEnabled = useFeatureFlag(FeatureFlags.BEAC_3900_analytics_demo_mode)
  const isMeetingLocked = meetingORM?.model.status === MeetingStatus.LOCKED
  const { deleteDisabled, deleteDisabledMessage } = useCanDeleteMetting(meetingORM);

  /** NOTE: Simulate sync delay */
  const onSyncDemo = (): void => {
    const syncStepDuration = 1500
    setDemoSyncingStatus(DEMO_SYNCING_STATUS.SYNCING)
    setTimeout(() => {
      setDemoSyncingStatus(DEMO_SYNCING_STATUS.SYNCED)
      setTimeout(() => {
        setDemoSyncingStatus(DEMO_SYNCING_STATUS.IDLE)
      }, syncStepDuration)
    }, syncStepDuration)
  }

  const handleIsSubmitting = () => {
    setUIDisabled(isSubmitting || demoSyncingStatus !== DEMO_SYNCING_STATUS.IDLE)
  }
  useEffect(handleIsSubmitting, [isSubmitting, demoSyncingStatus])

  if (isReadOnly) { return (
    <DNABox fill appearance="col" alignY="end">
      <DNADivider />

      <DNABox spacing="sm" alignY="center" style={styles.content} >
        {/* Open in CRM */}
        <Iffy is={meetingORM?.model.crmRecord?.crmCallId}>
          <CRMOpenCallButton meetingORM={meetingORM} />
        </Iffy>
        <DNAIcon name="lock" size="md" style={{ fontSize: 20, color: colors['color-gray-400'] }} />
        <DNAText status="flatAlt">
          Submitted to CRM on {lastSyncedAt} and is now locked.
        </DNAText>
      </DNABox>

    </DNABox>
  ) }

  return (
    <DNABox fill appearance="col" alignY="end" >
      <DNADivider />
      <DNABox spacing="between" alignY="center" style={styles.content} >
        <DNABox spacing="sm" alignY="center">
          {/* Delete Button */}
          <Popover disablePopover={!deleteDisabledMessage}>
            <Popover.Anchor>
              <DNAButton
                disabled={uiDisabled || isMeetingLocked || deleteDisabled}
                appearance="outline"
                size="sm"
                padding="sm"
                status="tertiary"
                style={{ paddingVertical: 5 }}
                iconRight="trash-can-outline"
                onPress={onDelete}
              />
            </Popover.Anchor>
            <Popover.Content>
              <DNAText status="basic">{deleteDisabledMessage}</DNAText>
            </Popover.Content>
          </Popover>
          {/* CRM Submit Button */}
          <Iffy is={connectionStatus === CRMConnectionStatus.CONNECTED}>
            <CRMSubmitButton />
          </Iffy>
          <Iffy is={meetingORM?.model.crmRecord?.crmCallId}>
            <DNABox spacing="sm" alignY="center">
              <CRMOpenCallButton meetingORM={meetingORM} />
              <DNAChip status="basic" size="md">
                {lastSyncedAt}
              </DNAChip>
            </DNABox>
          </Iffy>
        </DNABox>
        <DNABox spacing="sm">

          {/* Cancel Button */}
          <DNAButton
            disabled={uiDisabled}
            appearance="filled"
            status="tertiary"
            onPress={onCancel}
          >
            Cancel
          </DNAButton>

          {/* Demo mode button */}
          <Iffy is={isDemoModeEnabled}>
            <DNAButton
              appearance="filled"
              disabled={uiDisabled}
              status={SYNCING_BUTTON_META[demoSyncingStatus].color}
              onPress={onSyncDemo}
              iconLeft={SYNCING_BUTTON_META[demoSyncingStatus].icon}
            >
              {demoSyncingStatus}
            </DNAButton>
          </Iffy>

          {/* Save Button */}
          <DNAButton
            style={{ maxHeight: 43 }}
            appearance="filled"
            disabled={uiDisabled}
            onPress={() => onSave()}
          >
            Save
          </DNAButton>
        </DNABox>
      </DNABox>
    </DNABox>
  );
};

export const CRMSubmitButton = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { isOnline } = useAppSettings();
  const { crmIntegrationType } = useCRMStatus();
  const { meetingORM, onSave } = useAddMeeting();
  const dispatch = useDispatch();
  const canSubmitToCRM = meetingORM?.model.status !== MeetingStatus.LOCKED && isOnline;

  const onPressForCRM = (): void => {
    if (crmIntegrationType === CrmIntegrationType.VEEVA) {
      toggleVisibility();
    }
    else {
      onSave(MEETING_SUBMIT_TYPE.SAVE_TO_CRM);
    }
  }

  const toggleVisibility = (): void => {
    setIsVisible((visible) => !visible);
  }

  const onSaveToCRM = (): void => {
    toggleVisibility();
    onSave(MEETING_SUBMIT_TYPE.SAVE_TO_CRM);
  }

  const onFinalSubmitToCRM = (): void => {
    toggleVisibility();

    const onConfirm = (): void => {
      onSave(MEETING_SUBMIT_TYPE.SUBMIT_LOCK_TO_CRM);
    };

    dispatch(
      DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: () => (
          <DNACommonConfirmation
            descriptionText="Once submitted, this meeting will no longer be editable."
            confirmActionText="Submit"
            title="Submit final meeting to CRM?"
            onConfirmAction={onConfirm}
          />
        ),
      }));
  }

  return (
    <DNAPopover visible={canSubmitToCRM ? false : undefined}>
      <DNAPopover.Anchor>
        <DNAPopover
          lazyMount
          placement="top-end"
          interactive={true}
          visible={isVisible}
          onBackdropPress={toggleVisibility}
          type="menu"
        >
          <DNAPopover.Anchor>
            <DNAButton
              testID="submit-to-crm-button"
              onPress={onPressForCRM}
              appearance="outline"
              disabled={!canSubmitToCRM}
              status="tertiary"
              size="sm"
            >
              <DNABox spacing="sm" alignY="center">
                <DNAText status={canSubmitToCRM ? undefined : 'flat'}>
                  Save draft to CRM
                </DNAText>
                <Iffy is={crmIntegrationType === CrmIntegrationType.VEEVA}>
                  <DNAIcon.Styled
                    status={canSubmitToCRM ? 'tertiary' : 'gray'}
                    appearance="ghost"
                    name="chevron-down"
                    size="md"
                  />
                </Iffy>
              </DNABox>
            </DNAButton>
          </DNAPopover.Anchor>
          {/* CONTENT */}
          <DNAPopover.Content offset={5}>
            <DNABox style={S.headerActionsWrapper} appearance="col" spacing="md">
              {/* SAVE IN CRM */}
              <Pressable onPress={onSaveToCRM}>
                <DNAText>
                  Save to CRM
                </DNAText>
              </Pressable>
              {/* SAVE IN CRM AND LOCK */}
              <Pressable onPress={onFinalSubmitToCRM}>
                <DNAText>
                  Submit final to CRM
                </DNAText>
              </Pressable>
            </DNABox>
          </DNAPopover.Content>
        </DNAPopover>
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">
          { isOnline
            ? 'This meeting has been submitted and locked in your CRM.'
            : 'This feature is not available in offline mode.' }
        </DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  );
};

export const CRMOpenCallButton = (props: {meetingORM?: MeetingORM}) => {
  const tenant = useUserTenant();
  const openInCRM = (): void => {
    const crmCallId = props.meetingORM?.model.crmRecord?.crmCallId;
    const instanceUrl = tenant?.config.crmIntegration?.instanceUrl;
    if (instanceUrl && crmCallId) {
      analytics?.track('MEETING_CRM_RECORD_OPEN', {
        category: 'MEETING',
        meetingId: props.meetingORM?.model.id,
        crmCallId,
      });
      window.open(`${instanceUrl}/${crmCallId}`, '_blank');
    } else {
      console.error('Instance URL not defined');
    }
  };

  return (
    <DNAPopover>
      <DNAPopover.Anchor>
        <DNAButton
          testID="open-call-in-crm-button"
          onPress={openInCRM}
          appearance="outline"
          status="tertiary"
          size="sm"
        >
          Open in CRM
        </DNAButton>
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAPopover.Content>
          <DNAText status="basic">
            Open meeting details in CRM in a new window.
          </DNAText>
        </DNAPopover.Content>
      </DNAPopover.Content>
    </DNAPopover>
  );
};

AddMeetingFooter.displayName = 'AddMeetingFooter';

export default AddMeetingFooter;
