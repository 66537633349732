import { IDBPDatabase, openDB } from 'idb';
import lunr from 'lunr';
import {
  Config,
  CRMAccount,
  CrmDBSchema,
  TableName,
  FullTextSearch,
  TableEnum,
  CRMAddress,
  FormSettings,
} from './CRMIndexedDBTypes';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import { CRMAccountSettings } from '@alucio/aws-beacon-amplify/src/models';
import { SyncEntry, SYNC_ENTRY_STATUS } from './ISyncManifest';
import isEqual from 'lodash/isEqual';

type dbItem = Config | CRMAccount | FullTextSearch | SyncEntry | FormSettings;
type dbItemArray = Array<dbItem>;
type dbItemStore = dbItem | dbItemArray;

export class IndexDbCrm {
  readonly DB_NAME = 'CRMIndexDb';
  readonly VERSION = 1;
  readonly DEBUG = false;
  // if we can cache the full text search index we can persist it to the DB
  readonly DISABLE_FULL_TEXT_SEARCH_CACHE = false;
  public index: lunr.Index | null = null;
  public config: CRMAccountSettings | null = null;
  private previousFormRecordsResponse: FormSettings[] | undefined;

  private canHydrateSearch = () => {
    return (!this.DISABLE_FULL_TEXT_SEARCH_CACHE)
  }

  constructor() {
    this.getConfig().then((config) => {
      this.config = config;
    });
  }

  private getProperties = (item: any) => {
    return Object.entries(item).reduce<Record<string, any>>((acc, [key, value]) => {
      if (!(
        (isArray(value) ||
          isObject(value)) &&
        !isEmpty(value)
      )) { acc[key] = value; }
      return acc;
    }, {});
  }

  private async calculateSearchIndex() {
    const data = await this.getAll<CRMAccount>(TableEnum.ACCOUNT);
    const getProperties = this.getProperties;

    if (!data.length) { return null; }

    const keys = Object.keys(getProperties(data[0]))

    return lunr(function () {
      this.ref('id');
      keys.forEach((key) => {
        this.field(key);
      });

      const items = data.reduce((acum, account) => {
        const addresses = account.addresses as CRMAddress[];

        if (addresses.length) {
          const addIndex = addresses.map((address) => ({
            ...getProperties(account),
            addresses: Object.values(getProperties(address)).join(' '),
          }),
          )
          addIndex.length && acum.push(...addIndex)
        }

        if (!addresses.length) {
          acum.push(getProperties(account))
        }

        return acum;
      }, [] as any);

      items.forEach((item) => {
        this.add(item);
      })
    });
  }

  private async hydrateSearchIndex() {
    const cachedIndex = await this.getById<FullTextSearch>(TableEnum.FULL_TEXT_SEARCH, '1')

    if (cachedIndex) {
      const { value } = cachedIndex;
      const searchIndex = lunr.Index.load(JSON.parse(value));
      await this.cacheSearchIndex(searchIndex)
      return searchIndex
    }

    return null
  }

  private cacheSearchIndex = async (searchIndex: lunr.Index | null) => {
    if (!searchIndex) { return }

    const serialized = JSON.stringify(searchIndex.toJSON())
    await this.upsertItem(
      TableEnum.FULL_TEXT_SEARCH,
      { id: '1', value: serialized },
    )
  }

  private setSearchIndex = (searchIndex: lunr.Index | null) => {
    this.index = searchIndex
  }

  initializeSearchIndex = async () => {
    let searchIndex: lunr.Index | null = null
    if (this.canHydrateSearch()) {
      searchIndex = await this.hydrateSearchIndex()
    }

    if (!searchIndex) {
      searchIndex = await this.calculateSearchIndex()
    }

    this.setSearchIndex(searchIndex)
  }

  private refreshSearchIndex = async () => {
    const updatedIndex = await this.calculateSearchIndex()

    if (this.canHydrateSearch() && updatedIndex) {
      await this.cacheSearchIndex(updatedIndex)
    }

    this.setSearchIndex(updatedIndex)
  }

  // get a singleton of the openIDB function
  private openIDB = (() => {
    let openIDB: Promise<IDBPDatabase<CrmDBSchema>> | null = null;
    return () => {
      if (!openIDB) {
        openIDB = openDB<CrmDBSchema>(this.DB_NAME, this.VERSION, {
          upgrade(db) {
            db.createObjectStore(TableEnum.ACCOUNT, { keyPath: 'id' });
            db.createObjectStore(TableEnum.CONFIG_OBJECT, { keyPath: 'id' });
            db.createObjectStore(TableEnum.FULL_TEXT_SEARCH, { keyPath: 'id' });
            db.createObjectStore(TableEnum.FORM_SETTINGS, { keyPath: 'id' });
            db.createObjectStore(TableEnum.FORM_MANIFEST, { keyPath: 'id' });
          },
        });
      }
      return openIDB;
    };
  })();

  private getDB = async () => {
    const db = await this.openIDB();

    if (!db) {
      throw new Error('DB not initialized');
    }

    return db;
  };

  private getTx = async (tableName: TableName) => {
    const db = await this.getDB();
    const tx = db.transaction(tableName, 'readwrite');
    if (this.DEBUG) {
      tx.oncomplete = () => {
        console.warn('Transaction completed');
      };
      tx.onerror = (event) => {
        console.error('Transaction error: ', event);
      };
      tx.onabort = (event) => {
        console.warn('Transaction aborted: ', event);
      };
    }
    return tx;
  };

  private upsertItem = async (
    tableName: TableName,
    item: dbItem,
  ) => {
    const dbItem = await this.getById(tableName, item.id);
    const tx = await this.getTx(tableName);

    const result = dbItem === undefined ? await tx.store.add(item) : await tx.store.put(item);

    if (result) {
      return this.getById(tableName, item.id);
    }

    return dbItem;
  };

  /*
    * Upsert data into the database
    * @param tableName - the name of the table to upsert into
    * @param data - the data to upsert
    * @param syncFullTextSearch - whether to sync the full text search index
  */
  upsert = async <T>(tableName: TableName, data: dbItemStore) => {
    if (!Array.isArray(data)) {
      return await this.upsertItem(tableName, data) as Promise<T>;
    }

    if (Array.isArray(data)) {
      return Promise.all(data.map(
        (item: dbItem) =>
          this.upsertItem(tableName, item),
      ),
      ) as Promise<T[]>;
    }

    const shouldRefreshSearchIndex = [
      TableEnum.ACCOUNT.toString(),
      TableEnum.CONFIG_OBJECT.toString(),
      TableEnum.FORM_SETTINGS.toString(),
      TableEnum.FORM_MANIFEST.toString(),
    ].includes(tableName)

    if (shouldRefreshSearchIndex) { this.refreshSearchIndex() }
  };

  getAll = async <T>(tableName: TableName): Promise<T[]> => {
    const tx = await this.getTx(tableName);
    const items = tx.store.getAll();
    return (items as unknown) as Promise<T[]>;
  };

  getFormSettingsRaw = async (): Promise<FormSettings[]> => {
    const response = await this.getAll<FormSettings>(TableEnum.FORM_SETTINGS)

    if (!isEqual(response, this.previousFormRecordsResponse)) {
      // THIS RESPONSE IS STORED WITHIN THE CLASS TO AVOID THE SELECTOR
      // TO CONSIDER IT AS A NEW OBJECT AND TRANSLATE IT AGAIN
      this.previousFormRecordsResponse = response;
    }
    return this.previousFormRecordsResponse || response;
  };

  getById = async <T>(tableName: TableName, id: string): Promise<T> => {
    const tx = await this.getTx(tableName);
    const item = tx.store.get(id);
    return (item as unknown) as T;
  };

  // purge all data from the database
  clearDB = async () => {
    const db = await this.getDB();
    await Promise.all([
      db.clear(TableEnum.ACCOUNT),
      db.clear(TableEnum.CONFIG_OBJECT),
      db.clear(TableEnum.FULL_TEXT_SEARCH),
      db.clear(TableEnum.FORM_SETTINGS),
      db.clear(TableEnum.FORM_MANIFEST),

    ]);
  };

  getConfig = async () => {
    const config = await this.getAll<Config>(TableEnum.CONFIG_OBJECT);

    if (config.length) {
      const item = config[0];
      // base 64 decode the config
      const decoded = atob(item.configBase64);
      const parsed = JSON.parse(decoded);
      return parsed.accountsSettings;
    }
  };

  // filter index db by multiple indexes
  filterById = async (tableName: TableName, keys: string[]) => {
    if (!this.config) {
      this.config = await this.getConfig();
    }

    const tx = await this.getTx(tableName);
    const items = keys.map((key) => tx.store.get(key));

    if (!items.length) {
      return [];
    }

    return Promise.all(items) as Promise<CRMAccount[]>;
  }

  addSyncEntry = async (entry: SyncEntry) => {
    // check if entry already exists
    const entryExist = await this.getById<SyncEntry>('FORM_MANIFEST', entry.id)

    if (!entryExist) {
      entry.firstSynced = new Date().getTime()
    }
    else {
      entry.firstSynced = entryExist.firstSynced || new Date().getTime()
    }

    return await this.upsert<SyncEntry>('FORM_MANIFEST', entry) as SyncEntry
  }

  getEntryByStatus = async (status: SYNC_ENTRY_STATUS) => {
    const SyncEntries = await this.getAll<SyncEntry>('FORM_MANIFEST')
    return SyncEntries.filter((entry) => entry.status === status)
  }
}

// proxy to guarantee that the search index is initialized
export const Singleton = new Proxy(new IndexDbCrm(), {
  get: (target, prop) => {
    try {
      if (target.index === null) {
        target.initializeSearchIndex()
      }

      if (target[prop] !== undefined) {
        return target[prop]
      }

      // check if prop is an string
      if (typeof prop === 'string') {
        console.error(`IndexDbCrm: ${prop} is not a valid method`)
      }
    }
    catch (e) {
      console.error(e)
    }
  },
})
