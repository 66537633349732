import React from 'react'
import { SvgProps } from 'react-native-svg'

import { UserRole } from '@alucio/aws-beacon-amplify/src/models'

import HomeIcon from 'src/assets/icons/Home'
import LibraryIcon from 'src/assets/icons/Molecule'
import MyFoldersIcon from 'src/assets/icons/Folder'
import BookmarksIcon from 'src/assets/icons/Bookmarks'
import MeetingHistoryIcon from 'src/assets/icons/Calendar'
import LetterIcon from 'src/assets/icons/Letter'
import AnalyticsIcon from 'src/assets/icons/Analytics'
import AdminSettingsIcon from 'src/assets/icons/AdminSettings'
import { HubsIcon } from 'src/assets/icons/Hubs'

export enum PAGES {
  CRM_INTEGRATION = 'CRM_INTEGRATION',
  HOME = 'HOME',
  PLAYGROUND = 'PLAYGROUND',
  EMAIL_TEMPLATES = 'EMAIL_TEMPLATES',
  PUBLISHER_DASHBOARD = 'PUBLISHER_DASHBOARD',
  TENANTS = 'TENANTS',
  LIBRARY = 'LIBRARY',
  BOOKMARKS = 'BOOKMARKS',
  MEETING_HISTORY = 'MEETING_HISTORY',
  MEETING_PRESENTATION = 'MEETING_PRESENTATION',
  MEETING_CONTENT = 'MEETING_CONTENT',
  FOLDERS = 'FOLDERS',
  FOLDERS_UPDATES = 'FOLDERS_UPDATES',
  SHARED_FOLDERS = 'SHARED_FOLDERS',
  ARCHIVED_FOLDERS = 'ARCHIVED_FOLDERS',
  PUBLISHER_FOLDERS = 'PUBLISHER_FOLDERS',
  PUBLISHER_SHARED_FOLDERS = 'PUBLISHER_SHARED_FOLDERS',
  PUBLISHER_ARCHIVED_FOLDERS = 'PUBLISHER_ARCHIVED_FOLDERS',
  PUBLISHER_FOLDERS_UPDATES = 'PUBLISHER_FOLDERS_UPDATES',
  SEARCH_RESULTS = 'SEARCH_RESULTS',
  INTEGRATIONS = 'INTEGRATIONS',
  SEARCH_RESULTS_PUBLISHER = 'SEARCH_RESULTS_PUBLISHER',
  FAQ = 'FAQ',
  NOTIFICATIONS = 'NOTIFICATIONS',
  PROFILE = 'PROFILE',
  PROFILE_PASSWORD = 'PROFILE_PASSWORD',
  PROFILE_OFFLINE = 'PROFILE_OFFLINE',
  REPORTS = 'REPORTS',
  VIEWER_REPORTS = 'VIEWER_REPORTS',
  NOT_FOUND = 'NOT_FOUND',
  SYNC_STATUS_DEBUG = 'SYNC_STATUS_DEBUG',
  FOLDERS_UPLOADS = 'FOLDERS_UPLOADS',
  TENANT_CONFIGURATION = 'TENANT_CONFIGURATION',
  ADMIN_SETTINGS = 'ADMIN_SETTINGS',
  HUBS = 'HUBS',
  EDIT_HUBS = 'EDIT_HUBS',
}

enum AppModules {
  publisher,
  viewer
}
type AppModule = keyof typeof AppModules

export interface DeviceRouteConfigOptions {
  disableNotificaions?: boolean
  disableSearch?: boolean
  activateInfoPanel?: boolean
  disableGlobalHeader?: boolean
  disableGlobalNavigationMenu?: boolean
  disableGlobalFooter?: boolean
  disableMeetings?: boolean
  enableHorizontalScroll?: boolean
  disableNetworkStatusBanner?: boolean
  disableGlobalScroll?: boolean
  disableQuickFilters?: boolean
}

export interface BaseRouteConfigOptions {
  desktop?: DeviceRouteConfigOptions
  tablet?: DeviceRouteConfigOptions
  modules?: AppModule[]
}

export type DNARoute = {
  /** This id must be unique across all routes */
  UID: string,
  PATH: string,
  SEARCH?: string,
  LABEL: string,
  ICON?: string,
  testID?: string,
  PARENT_PATH?: string,
  ROLES?: UserRole[],
  CUSTOM_ICON?: React.FC<SvgProps>,
  ALLOWED_ROLES?: UserRole[],
  configOptions?: BaseRouteConfigOptions,
}

type Routes = Record<PAGES, DNARoute>

export const ROUTES: Routes = {
  CRM_INTEGRATION: {
    UID: 'crm-integration',
    PATH: '/profile/crm-integration',
    LABEL: 'CRM Integration',
    testID: 'crm-integration',
  },
  HOME: {
    UID: 'home',
    PATH: '/',
    LABEL: 'Home',
    CUSTOM_ICON: HomeIcon,
    testID: 'home',
  },
  PLAYGROUND: {
    UID: 'playground',
    PATH: '/playground',
    LABEL: 'App Playground',
    testID: 'app-playground',
  },
  EMAIL_TEMPLATES: {
    UID: 'email-templates',
    ALLOWED_ROLES: [UserRole.TENANT_PUBLISHER],
    CUSTOM_ICON: LetterIcon,
    PATH: '/email-templates',
    LABEL: 'Email Templates',
    testID: 'email-templates',
    configOptions: {
      modules: ['publisher'],
      desktop: {
        disableMeetings: true,
      },
    },
  },
  PUBLISHER_DASHBOARD: {
    UID: 'dashboard',
    ALLOWED_ROLES: [UserRole.TENANT_PUBLISHER],
    CUSTOM_ICON: LibraryIcon,
    LABEL: 'Library',
    PATH: '/publisher',
    testID: 'dashboard',
    configOptions: {
      modules: ['publisher'],
      desktop: {
        disableMeetings: true,
        enableHorizontalScroll: true,
      },
    },
  },
  TENANTS: {
    UID: 'tenants',
    ALLOWED_ROLES: [UserRole.ALUCIO_ADMIN],
    PATH: '/tenants',
    LABEL: 'Tenant Management',
    testID: 'tenant-management',
    configOptions: {
      desktop: {
        disableNotificaions: true,
        disableSearch: true,
        disableGlobalNavigationMenu: true,
        disableMeetings: true,
      },
      tablet: {
        disableGlobalNavigationMenu: true,
      },
    },
  },
  INTEGRATIONS: {
    UID: 'integrations',
    ALLOWED_ROLES: [UserRole.ALUCIO_ADMIN],
    PATH: '/tenants/integrations',
    LABEL: 'Integrations Management',
    testID: 'integrations-management',
    configOptions: {
      desktop: {
        disableNotificaions: true,
        disableSearch: true,
        disableMeetings: true,
        disableGlobalNavigationMenu: true,
      },
      tablet: {
        disableGlobalNavigationMenu: true,
      },
    },
  },
  TENANT_CONFIGURATION: {
    UID: 'tenant_configuration',
    ALLOWED_ROLES: [UserRole.ALUCIO_ADMIN],
    PATH: '/tenants/configuration',
    LABEL: 'Tenant Configuration',
    testID: 'tenant-configuration',
    configOptions: {
      desktop: {
        disableNotificaions: true,
        disableSearch: true,
        disableMeetings: true,
        disableGlobalNavigationMenu: true,
      },
    },
  },
  LIBRARY: {
    UID: 'library',
    PATH: '/library',
    SEARCH: '?display=list',
    LABEL: 'Library',
    CUSTOM_ICON: LibraryIcon,
    testID: 'library',
    configOptions: {
      desktop: {
        enableHorizontalScroll: true,
      },
    },
  },
  BOOKMARKS: {
    UID: 'bookmarks',
    PATH: '/bookmarks',
    SEARCH: '?display=list',
    LABEL: 'Bookmarks',
    CUSTOM_ICON: BookmarksIcon,
    testID: 'bookmarks',
    configOptions: {
      desktop: {
        enableHorizontalScroll: true,
      },
    },
  },
  MEETING_PRESENTATION: {
    UID: 'meeting-presentation',
    PATH: '/meeting-presentation/:meetingId',
    LABEL: 'Meetings',
    testID: 'meeting-presentation',
    configOptions: {
      desktop: {
        disableGlobalHeader: true,
        disableGlobalNavigationMenu: true,
        disableGlobalFooter: true,
        disableGlobalScroll: true,
      },
      tablet: {
        disableGlobalHeader: true,
        disableGlobalNavigationMenu: true,
      },
    },
  },
  MEETING_CONTENT: {
    UID: 'meeting-content',
    PATH: '/meeting-content',
    LABEL: 'Meeting Content',
    testID: 'meeting-content',
    configOptions: {
      desktop: {
        disableGlobalHeader: true,
        disableGlobalNavigationMenu: true,
        disableGlobalFooter: true,
        disableNetworkStatusBanner: true,
        disableGlobalScroll: true,
      },
      tablet: {
        disableGlobalHeader: true,
        disableGlobalNavigationMenu: true,
        disableNetworkStatusBanner: true,
        disableGlobalScroll: true,
      },
    },
  },
  MEETING_HISTORY: {
    UID: 'meetings',
    PATH: '/meetings',
    LABEL: 'Meeting History',
    CUSTOM_ICON: MeetingHistoryIcon,
    testID: 'meeting-history',
    configOptions: {
      desktop: {
        enableHorizontalScroll: true,
      },
      tablet: {
        enableHorizontalScroll: true,
      },
    },
  },
  FOLDERS: {
    UID: 'folders',
    PATH: '/folders',
    SEARCH: '?display=list',
    LABEL: 'My Folders',
    CUSTOM_ICON: MyFoldersIcon,
    testID: 'my-folders',
    configOptions: {
      desktop: {
        activateInfoPanel: true,
      },
      tablet: {
        activateInfoPanel: true,
      },
    },
  },
  FOLDERS_UPDATES: {
    UID: 'folders-updates',
    PATH: '/folders/updates',
    LABEL: 'My Updates',
    CUSTOM_ICON: MyFoldersIcon,
    testID: 'my-folders-updates',
    PARENT_PATH: '/folders',
  },
  FOLDERS_UPLOADS: {
    UID: 'folders-uploads',
    PATH: '/folders/uploads',
    LABEL: 'My Uploads',
    CUSTOM_ICON: MyFoldersIcon,
    testID: 'my-folders-uploads',
    PARENT_PATH: '/folders',
  },
  SHARED_FOLDERS: {
    ALLOWED_ROLES: [UserRole.TENANT_PUBLISHER, UserRole.TENANT_VIEWER],
    UID: 'shared_folders',
    PATH: '/shared_folders',
    SEARCH: '?display=list',
    LABEL: 'Shared Folders',
    CUSTOM_ICON: MyFoldersIcon,
    testID: 'shared-folders',
    PARENT_PATH: '/folders',
    configOptions: {
      desktop: {
        activateInfoPanel: true,
      },
      tablet: {
        activateInfoPanel: true,
      },
    },
  },
  ARCHIVED_FOLDERS: {
    UID: 'archived_folders',
    PATH: '/archived_folders',
    SEARCH: '?display=list',
    LABEL: 'Archived Folders',
    CUSTOM_ICON: MyFoldersIcon,
    testID: 'archived-folders',
    PARENT_PATH: '/folders',
    configOptions: {
      desktop: {
        activateInfoPanel: true,
      },
    },
  },
  PUBLISHER_FOLDERS: {
    ALLOWED_ROLES: [UserRole.TENANT_PUBLISHER],
    UID: 'publisher_folders',
    PATH: '/publisher_folders',
    SEARCH: '?display=list',
    LABEL: 'My Folders',
    CUSTOM_ICON: MyFoldersIcon,
    testID: 'publisher-my-folders',
    configOptions: {
      modules: ['publisher'],
      desktop: {
        disableMeetings: true,
        activateInfoPanel: true,
      },
    },
  },
  PUBLISHER_SHARED_FOLDERS: {
    ALLOWED_ROLES: [UserRole.TENANT_PUBLISHER],
    UID: 'publisher_shared_folders',
    PATH: '/publisher_shared_folders',
    SEARCH: '?display=list',
    PARENT_PATH: '/publisher_folders',
    LABEL: 'Publisher Shared Folders',
    CUSTOM_ICON: MyFoldersIcon,
    testID: 'publisher-shared-folders',
    configOptions: {
      modules: ['publisher'],
      desktop: {
        disableMeetings: true,
        activateInfoPanel: true,
      },
    },
  },
  PUBLISHER_ARCHIVED_FOLDERS: {
    ALLOWED_ROLES: [UserRole.TENANT_PUBLISHER],
    UID: 'publisher_archived_folders',
    PATH: '/publisher_archived_folders',
    SEARCH: '?display=list',
    PARENT_PATH: '/publisher_folders',
    LABEL: 'Publisher Archived Folders',
    CUSTOM_ICON: MyFoldersIcon,
    testID: 'publisher-archived-folders',
    configOptions: {
      modules: ['publisher'],
      desktop: {
        disableMeetings: true,
        activateInfoPanel: true,
      },
    },
  },
  PUBLISHER_FOLDERS_UPDATES: {
    ALLOWED_ROLES: [UserRole.TENANT_PUBLISHER],
    UID: 'publisher_folders_updates',
    PATH: '/publisher_folders/updates',
    LABEL: 'My Updates',
    CUSTOM_ICON: MyFoldersIcon,
    testID: 'publisher-my-folders-updates',
    PARENT_PATH: '/publisher_folders',
    configOptions: {
      modules: ['publisher'],
      desktop: {
        disableMeetings: true,
        activateInfoPanel: true,
      },
    },
  },
  SEARCH_RESULTS: {
    UID: 'search',
    PATH: '/search/:searchText',
    LABEL: 'Search Results for',
    testID: 'search-results',
    configOptions: {
      desktop: {
        enableHorizontalScroll: true,
        disableQuickFilters: true,
      },
      tablet: {
        disableQuickFilters: true,
      },
    },
  },
  SEARCH_RESULTS_PUBLISHER: {
    ALLOWED_ROLES: [UserRole.TENANT_PUBLISHER],
    UID: 'publisher-search',
    PATH: '/publisher-search',
    LABEL: 'Search Results for',
    testID: 'publisher-search-results',
    configOptions: {
      modules: ['publisher'],
      desktop: {
        disableMeetings: true,
        enableHorizontalScroll: true,
      },
    },
  },
  FAQ: {
    UID: 'faq',
    PATH: '/faq',
    LABEL: 'User Guide / FAQ',
    testID: 'user-guide',
    configOptions: {
      desktop: {
        disableGlobalHeader: true,
        disableGlobalNavigationMenu: true,
      },
      tablet: {
        disableGlobalNavigationMenu: false,
      },
    },
  },
  NOTIFICATIONS: {
    UID: 'notifications',
    PATH: '/notifications',
    LABEL: 'Notifications',
    testID: 'notifications',
  },
  PROFILE: {
    UID: 'profile',
    PATH: '/profile',
    LABEL: 'Account Profile',
    testID: 'account-profile',
  },
  PROFILE_PASSWORD: {
    UID: 'profile-password',
    PATH: '/profile/password',
    LABEL: 'Change Password',
    testID: 'profile-password',
  },
  PROFILE_OFFLINE: {
    UID: 'profile-offline',
    PATH: '/profile/offline',
    LABEL: 'Offline Settings',
    testID: 'profile-offline',
  },
  NOT_FOUND: {
    UID: '404',
    PATH: '/404',
    LABEL: 'We could not find what you were looking for',
    testID: '404',
  },
  SYNC_STATUS_DEBUG: {
    UID: 'sync-status-debug',
    PATH: '/debug/sync-status',
    LABEL: 'Sync Status Debug',
    testID: 'sync-status-debug',
  },
  REPORTS: {
    UID: 'reports',
    ALLOWED_ROLES: [UserRole.TENANT_PUBLISHER],
    CUSTOM_ICON: AnalyticsIcon,
    PATH: '/reports',
    LABEL: 'Analytics',
    testID: 'reports',
    configOptions: {
      modules: ['publisher'],
      desktop: {
        disableMeetings: true,
        enableHorizontalScroll: true,
      },
    },
  },
  VIEWER_REPORTS: {
    UID: 'viewer_reports',
    PATH: '/analytics-viewer',
    LABEL: 'Analytics',
    CUSTOM_ICON: AnalyticsIcon,
    testID: 'viewer_reports',
    configOptions: {
      desktop: {
        enableHorizontalScroll: true,
      },
    },
  },
  ADMIN_SETTINGS: {
    UID: 'ADMIN_SETTINGS',
    PATH: '/publisher_admin_settings',
    LABEL: 'Admin Settings',
    testID: 'admin-settings',
    configOptions: {
      modules: ['publisher'],
      desktop: {
        disableMeetings: true,
        disableGlobalScroll: true,
      },
    },
    ALLOWED_ROLES: [UserRole.TENANT_ADMIN],
    CUSTOM_ICON: AdminSettingsIcon,
  },
  HUBS: {
    UID: 'hubs',
    PATH: '/hubs',
    LABEL: 'Hubs',
    testID: 'hubs',
    CUSTOM_ICON: HubsIcon,
    configOptions: {
      desktop: {
        enableHorizontalScroll: true,
      },
      tablet: {
        enableHorizontalScroll: true,
      },
    },
  },
  EDIT_HUBS: {
    UID: 'edit-hub',
    PATH: '/hubs/:hubId',
    LABEL: 'Edit Hub',
    testID: 'edit-hub',
    configOptions: {
      desktop: {
        disableGlobalHeader: true,
        disableGlobalNavigationMenu: true,
        disableGlobalFooter: true,
      },
      tablet: {
        disableGlobalHeader: true,
        disableGlobalNavigationMenu: true,
      },
    },
  },
}

export const HTMLTroubleshootingPage = '/hc/en-us/articles/10172766653463--HTML-Website-Publishing-Troubleshooting-Tips'

export default ROUTES
