/* eslint-disable */

import React, { useState, useRef, useMemo } from 'react';
import { ScrollView } from 'react-native'
import {
  DNABox,
  DNAButton,
  DNAText,
} from '@alucio/lux-ui'
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
// import LazyDNAThumbnail from 'src/components/DNA/Thumbnail/LazyDNAThumbnail'

const thumbnails = Array.from(
  { length: 500 },
  (_, idx) => `3111d12b-0a11-4116-aaa3-39b3b46ff989/ddc419d7-eb71-4b16-96d1-28a5dd676d00/ddc419d7-eb71-4b16-96d1-28a5dd676d00_2/v2/thumbnails/${idx + 1}_small_thumb.png`
) 

const PlaygroundDesktop = () => {
  const scrollerRef = useRef<ScrollView>()  
  const [size, setSize] = useState<'small' | 'medium'>('small')

  const thumbnailsURL = useMemo(
    () => {
      return Array.from(
        { length: 500 },
        (_, idx) => `3111d12b-0a11-4116-aaa3-39b3b46ff989/ddc419d7-eb71-4b16-96d1-28a5dd676d00/ddc419d7-eb71-4b16-96d1-28a5dd676d00_2/v2/thumbnails/${idx + 1}_${size}_thumb.png`
      ) 
    },
    [size]
  )

  return (
    <DNABox
      appearance="row"
      fill
    >
      <ScrollView
        ref={scrollerRef as React.LegacyRef<ScrollView>}
        contentContainerStyle={{ flex: 1 }}
      >
        {
          thumbnails.map((s3Key, idx) => (
            // <LazyDNAThumbnail
            <DNAThumbnail.Lazy
              size='md'
              key={s3Key}
              s3URL={thumbnailsURL[idx]}
              parentRef={scrollerRef as any}
              pageNumber={idx + 1}
            />
          ))
        }
      </ScrollView>
      <DNABox appearance='col'>
        <DNAText>Desktop Playground</DNAText>
        <DNAButton onPress={() => setSize(p => p === 'small' ? 'medium' : 'small')}>
          Current { size }
        </DNAButton>
      </DNABox>
    </DNABox>
  )
}

const PlaygroundTablet = () => {
  return (
    <DNABox>
      <DNAText>Tablet Playground</DNAText>
    </DNABox>
  )
}

type DeviceModeVariants = Record<DeviceMode, React.ElementType>

const deviceModeVariants:DeviceModeVariants = {
  desktop: PlaygroundDesktop,
  tablet: PlaygroundTablet,
}

export default () => {
  const { deviceMode } = useAppSettings()

  const CurrentDeviceModeVariant = deviceModeVariants[deviceMode]

  return <CurrentDeviceModeVariant />
}
