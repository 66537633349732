import React, { createContext, useContext, useEffect, useRef, useMemo, PropsWithChildren } from 'react';
import { StateFrom, InterpreterFrom } from 'xstate'
import { useMachine } from '@xstate/react';

import { CustomFieldUsage } from '@alucio/aws-beacon-amplify/src/models';
import shareMachineConfig from 'src/state/machines/sharing/share'
import { useEmailTemplateListForMSL } from 'src/state/redux/selector/emailTemplate';
import { useTenantCustomFields } from 'src/state/redux/selector/tenant';
import { CurrentUser, useUserTenant } from 'src/state/redux/selector/user';
import { DocumentVersionORM, EmailTemplateORM, HubORM } from 'src/types/orms';
import { STEP_STATE } from 'src/state/machines/sharing/shareTypes';
import { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast';
import { DNAModalVariant } from '../../../DNAConnectedModal';
import { useComposableForm } from 'src/components/CustomFields/ComposableForm';
import { getCurrentState } from 'src/state/machines/sharing/shareUtils';
import { UseFormMethods } from 'react-hook-form';
import { formToModel } from 'src/components/CustomFields/ComposableFormUtilities';
import { ShareVariantType, ShareVariantOptions } from '../../DNAFileShareModal';
import { ExistingShareLink } from 'src/utils/shareLink/shareLink.web';

export enum SHARE_METHOD {
  COPY = 'COPY',
  EMAIL = 'EMAIL'
}

type ShareMachine = ReturnType<typeof shareMachineConfig>

/** This interface defines what can be consumed from the context */
 interface DNAFileShareModalStateType {
    shareMachineState: StateFrom<ShareMachine>,
    shareMachineSend: InterpreterFrom<ShareMachine>['send'],
    shareContent: DocumentVersionORM[] | HubORM,
    currentUser: CurrentUser
    toast: ToastActions,
    emailTemplates: EmailTemplateORM[],
    shareMethod: SHARE_METHOD,
    meetingId?: string,
    closeModal: () => void,
    associatedFilesRef: React.MutableRefObject<any>,
    cond: {
      isGeneratingLink: boolean,
      generatedLink: boolean,
    },
    currentState: STEP_STATE | undefined,
    rhForm: UseFormMethods,
    onNextStep: () => void,
    validateAndExecute: (callback: () => void) => () => void,
    variant: ShareVariantType,
 }

const DNAFileShareModalStateContext = createContext<DNAFileShareModalStateType>(null!)

/** This interface defines what is initially passed into the context */
type DNAFileShareModalStateProviderProps = {
    shareContent: DocumentVersionORM[] | HubORM
    currentUser: CurrentUser
    toast: ToastActions,
    emailTemplates: EmailTemplateORM[],
    shareMethod: SHARE_METHOD,
    meetingId?: string,
    variant: ShareVariantType,
    existingShareLinks?: ExistingShareLink
}

/** TODO: this typing is weird. Probably a better way to get the modal props */
const DNAFileShareModalStateProvider: DNAModalVariant<PropsWithChildren<DNAFileShareModalStateProviderProps>> = (
  {
    closeModal,
    shareContent,
    toast,
    currentUser,
    emailTemplates,
    shareMethod,
    meetingId,
    variant,
    children,
    existingShareLinks,
  },
) => {
  const variantConfig = {
    [ShareVariantOptions.DOC_VER_SHARE]: {
      customFieldUsage: CustomFieldUsage.CONTENT_SHARE,
    },
    [ShareVariantOptions.HUB_SHARE]: {
      customFieldUsage: CustomFieldUsage.HUB,
    },
  }
  const filter = useMemo(
    () => ({ usages: { internalUsages: [variantConfig[variant].customFieldUsage] } }),
    [],
  )
  const customFields = useTenantCustomFields(filter)
  const tenant = useUserTenant()

  /** SECTION: STATE MACHINE */
  const shareMachine = useRef(shareMachineConfig(
    shareContent,
    /** NOTE: This will be an empty array if there are no email templates (I think) */
    useEmailTemplateListForMSL(),
    customFields,
    shareMethod,
    tenant!,
    toast,
    currentUser.userProfile!,
    meetingId,
    existingShareLinks,
  )).current
  const [shareMachineState, shareMachineSend] = useMachine(shareMachine)
  const associatedFilesRef = useRef()
  const { rhForm } = useComposableForm()

  const currentState = getCurrentState(shareMachineState)
  const isCustomFields = currentState === STEP_STATE.CUSTOM_FIELDS

  const onNextStep = () => {
    const customFieldsValues = isCustomFields
      ? formToModel(rhForm.getValues())
      : undefined

    shareMachineSend({
      type: 'NEXT_STEP',
      payload: {
        customFieldsValues,
      },
    })
  }

  const validateAndExecute = (callback) => () => {
    rhForm.trigger().then(() => {
      rhForm.formState.isValid && callback()
    })
  }

  const cond = {
    isGeneratingLink: shareMachineState.matches('generateLinks.generating'),
    generatedLink: shareMachineState.matches('generateLinks.finished'),
  }
  /** !SECTION: STATES */

  /** SECTION - UTILITY FUNCTIONS */
  /** !SECTION - UTILITY FUNCTIONS */

  /** SECTION - HANDLERS */
  const handleContextInit = () => {}
  useEffect(handleContextInit, [])

  /** !SECTION - HANDLERS */

  const contextValue: DNAFileShareModalStateType = {
    shareMachineState,
    shareMachineSend,
    closeModal,
    shareContent,
    toast,
    currentUser,
    emailTemplates,
    shareMethod,
    meetingId,
    associatedFilesRef,
    cond,
    currentState,
    rhForm,
    onNextStep,
    validateAndExecute,
    variant,
  }

  useEffect(
    () => {
      if (shareMachineState.done) {
        closeModal()
      }
    },
    [shareMachineState],
  )

  return (
    <DNAFileShareModalStateContext.Provider value={contextValue}>
      {children}
    </DNAFileShareModalStateContext.Provider>
  )
}

export const useDNAFileShareModalState = () => {
  const context = useContext(DNAFileShareModalStateContext)
  if (!context) {
    throw new Error('useDNAFileShareModalState must be used within the DNAFileShareModalStateProvider')
  }
  return context;
}

export default DNAFileShareModalStateProvider
