import React, { useEffect, useRef, useState } from 'react';
import { DNABox, DNAButton, DNADivider, DNAText, Hoverable, Iffy, TextField, util } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { Pressable, ScrollView, StyleSheet } from 'react-native';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import { MeetingType } from '@alucio/aws-beacon-amplify/src/models';
import { ANALYTICS_EVENT_NAME } from 'src/components/ContentPreviewModalV2/State/MeetingPresentedMetaProvider';
import { isCustomDeckORM, isFolderItemORM } from 'src/types/typeguards';

const styles = StyleSheet.create({
  hoveredNotes: {
    backgroundColor: colors['color-gray-700'],
    borderRadius: 4,
  },
  notesContainer: {
    backgroundColor: colors['color-gray-900'],
    borderColor: colors['color-gray-600'],
    borderLeftWidth: 1,
    width: 320,
  },
  notesHeader: {
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  notesInput: {
    backgroundColor: colors['color-text-white'],
    borderColor: colors['color-gray-100'],
    borderRadius: 4,
    color: colors['color-gray-800'],
    height: '100%',
    outlineWidth: 0,
    paddingHorizontal: 8,
    paddingVertical: 7,
    width: '100%',
  },
  notesPadding: {
    borderRadius: 4,
    padding: 8,
  },
  notesContent: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    width: '100%',
  },
});

export enum NOTES {
  SPEAKER_NOTES,
  MY_NOTES
}

interface Props {
  openNotes: NOTES[],
  toggleMyNotes: () => void,
  toggleSpeakerNotes: () => void,
}

const Notes = (props: Props) => {
  const { activePresentation, contentPageData } = useContent();
  const { meetingORM, addNotes, presentedMeta } = useMeetingsState();
  const isInPerson = meetingORM?.model.type === MeetingType.IN_PERSON;
  const [isEditingNotes, setIsEditingNotes] = useState<boolean>(props.openNotes.includes(NOTES.MY_NOTES));
  const [areMyNotesHovered, setAreMyNotesHovered] = useState<boolean>(false);
  const speakerNotes = activePresentation?.currentPresentablePage && contentPageData
    ? contentPageData[activePresentation?.currentPresentablePage.presentationPageNumber - 1]?.speakerNotes : undefined
  const [myNotes, setMyNotes] = useState<string>('');
  const latestMeetingNotes = useRef<NOTES[]>(props.openNotes);

  // WILL UPDATE THE SLIDE NOTES UPON CHANGING PAGES
  useEffect(() => {
    if (presentedMeta?.note) setMyNotes(presentedMeta.note)
    else setMyNotes('')
  }, [presentedMeta]);

  useEffect(() => {
    // THE USER OPENED THEIR OWN NOTES
    if (props.openNotes.includes(NOTES.MY_NOTES) &&
      (!latestMeetingNotes.current.includes(NOTES.MY_NOTES) || props.openNotes.length === 1)) {
      const customDeckId = isFolderItemORM(activePresentation?.presentable.orm)
        ? (isCustomDeckORM(activePresentation?.presentable.orm.relations.itemORM)
          ? activePresentation?.presentable.orm.relations.itemORM.model.id : undefined) : undefined;

      analytics?.track(ANALYTICS_EVENT_NAME.SLIDE_NOTES_OPENED, {
        category: 'MEETING',
        context: 'MEETING',
        documentId: activePresentation?.currentPresentablePage?.documentVersionORM.model.documentId,
        documentVersionId: activePresentation?.currentPresentablePage?.documentVersionORM.model.id,
        customDeckId,
        folderItemId: isFolderItemORM(activePresentation?.presentable.orm)
          ? activePresentation?.presentable.orm.model.id : undefined,
        meetingId: meetingORM?.model.id,
        pageNumber: activePresentation?.currentPresentablePage?.page.number,
      })
    }
    latestMeetingNotes.current = props.openNotes;
  }, [props.openNotes]);

  const onHoverIn = (): void => {
    setAreMyNotesHovered(true);
  };

  const onHoverOut = (): void => {
    setAreMyNotesHovered(false);
  };

  const handleAllowEditNotes = (): void => {
    setIsEditingNotes(true);
  };

  const handleOnNotesChange = (notes: string): void => {
    setMyNotes(notes);
  }

  const handleSaveNotes = (): void => {
    addNotes(myNotes);
    setIsEditingNotes(false);
  };

  return (
    <DNABox
      style={
      util.mergeStyles(undefined,
        styles.notesContainer,
        [{ paddingBottom: 40 }, isInPerson])}
      appearance="col"
    >
      {
        // SPEAKER NOTES SECTION //
        props.openNotes.includes(NOTES.SPEAKER_NOTES) &&
          <DNABox appearance="col" fill>
            <DNABox spacing="between" alignY="center" style={styles.notesHeader}>
              <DNAText status="basic">Speaker Notes</DNAText>
              <DNAButton
                status="dark"
                appearance="filled"
                iconLeft="close"
                onPress={props.toggleSpeakerNotes}
                testID="close-speaker-notes-button"
              />
            </DNABox>
            <DNADivider variant="virtual" />
            <ScrollView>
              <DNAText status="basic" style={styles.notesContent}>{speakerNotes ?? 'No slide notes'}</DNAText>
            </ScrollView>
          </DNABox>
      }
      <Iffy is={props.openNotes.length === 2}>
        <DNADivider variant="virtual" />
      </Iffy>
      {
        // MY NOTES SECTION //
        props.openNotes.includes(NOTES.MY_NOTES) &&
          <DNABox appearance="col" fill>
            <DNABox spacing="between" alignY="center" style={styles.notesHeader}>
              <DNAText status="basic">My Notes</DNAText>
              <DNAButton
                status="dark"
                appearance="filled"
                iconLeft="close"
                onPress={props.toggleMyNotes}
                testID="close-speaker-notes-button"
              />
            </DNABox>
            <DNADivider variant="virtual" />
            <Iffy is={isEditingNotes}>
              <DNABox fill style={styles.notesContent} childFill appearance="col">
                <DNAButton
                  size="sm"
                  padding="sm"
                  onPress={handleSaveNotes}
                  style={{ marginBottom: 20 }}
                >
                  Done
                </DNAButton>
                <TextField.Native
                  autoFocus
                  maxLength={1000}
                  onBlur={handleSaveNotes}
                  onChangeText={handleOnNotesChange}
                  multiline
                  value={myNotes}
                  style={styles.notesInput}
                />
              </DNABox>
            </Iffy>
            <Iffy is={!isEditingNotes}>
              <DNABox fill style={styles.notesContent} childFill>
                <Hoverable onHoverIn={onHoverIn} onHoverOut={onHoverOut}>
                  <Pressable onPress={handleAllowEditNotes} style={{ width: '100%' }}>
                    <ScrollView style={areMyNotesHovered && styles.hoveredNotes}>
                      <DNAText
                        status={myNotes !== '' ? 'basic' : 'flat'}
                        style={styles.notesPadding}
                      >
                        {myNotes === '' ? 'Enter notes...' : myNotes}
                      </DNAText>
                    </ScrollView>
                  </Pressable>
                </Hoverable>
              </DNABox>
            </Iffy>
          </DNABox>
      }
    </DNABox>
  );
}

export default Notes;
