import React from 'react';
import {
  Iffy,
  DNABox,
} from '@alucio/lux-ui';
import PresentationControls from './PresentationControls/PresentationControls';
import InPersonContent from './InPersonContent/InPersonContent';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';
import {
  MeetingsStateProvider as MeetingsStateProviderProxy,
} from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import { MeetingType } from '@alucio/aws-beacon-amplify/src/models';
import { ContentProvider } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { HighlighterProvider } from 'src/components/Highlighter/HighlighterProvider';
import { CanvasVariantEnum } from 'src/components/Highlighter/UseCanvas';

const MeetingsContent = () => {
  const meetingState = useMeetingsState()
  const contentState = useContent()

  const { meetingORM, actionBarState } = meetingState

  const { activePresentation } = useContent()

  const addAnalytics = (variant: CanvasVariantEnum) => {
    analytics?.track('MEETING_HIGHLIGHTER', {
      type: variant === CanvasVariantEnum.arrow ? 'Arrow' : 'Box',
      category: 'MEETING',
      meetingID: meetingORM?.model.id,
      documentVersionId: activePresentation?.currentPresentablePage.documentVersionORM.model.id,
      documentId: activePresentation?.currentPresentablePage.documentVersionORM.model.documentId,
      pageId: activePresentation?.currentPresentablePage.id,
      page: activePresentation?.currentPresentablePage.page.number,
    })
  }

  return (
    <DNABox fill childFill={0}>
      <PresentationControls />
      <Iffy is={meetingORM?.model?.type === MeetingType.IN_PERSON}>
        <ContentProvider value={contentState}>
          <MeetingsStateProviderProxy value={meetingState}>
            <HighlighterProvider actionBarState={actionBarState} onModeChange={addAnalytics}>
              <InPersonContent />
            </HighlighterProvider>
          </MeetingsStateProviderProxy>
        </ContentProvider>
      </Iffy>
    </DNABox>
  );
};

MeetingsContent.displayName = 'MeetingsContent';

export default MeetingsContent;
