import { DocumentVersion, Folder } from '@alucio/aws-beacon-amplify/src/models'
import { createListenerMiddleware, isAnyOf, TypedStartListening } from '@reduxjs/toolkit'

import { RootState, AppDispatch } from 'src/state/redux'
import { documentVersionActions } from 'src/state/redux/slice/documentVersion'
import { folderActions } from 'src/state/redux/slice/folder'

import { syncCacheManifestDebounced } from 'src/state/cache/syncManifests'
import PWALoaded from 'src/state/global/PWALoaded'

import PWALogger from 'src/worker/util/logger'
import { isIOS } from 'react-device-detect'

export type AppStartListening = TypedStartListening<RootState, AppDispatch>

const listenerMiddleware = createListenerMiddleware()
const appStartListening = listenerMiddleware.startListening as AppStartListening

/** DOCUMENT CACHE SYNC */
appStartListening({
  matcher: isAnyOf(
    documentVersionActions.update,
    documentVersionActions.upsert,
  ),
  effect: (action, listenerAPI) => {
    if (!PWALoaded.isPWA) return;

    const state = listenerAPI.getState()
    const versionSlice = state.documentVersion
    PWALogger.debug('Received notification of update of docVersion')
    if (versionSlice.hydrated) {
      const nextVersion = action.payload as DocumentVersion
      const prevVersion = versionSlice.records.find(version => version.id === nextVersion.id)

      if (prevVersion) {
        const isNewlyPublished = prevVersion.status === 'NOT_PUBLISHED' && nextVersion.status === 'PUBLISHED'
        if (isNewlyPublished) {
          PWALogger.debug('Starting sync due to updated version')
          // We don't auto-sync on iOS non-PWA
          syncCacheManifestDebounced(PWALoaded.isPWA || !isIOS)
        }
      }
    }
  },
})

/** FOLDER CACHE SYNC */
appStartListening({
  matcher: isAnyOf(
    folderActions.add,
    folderActions.update,
    folderActions.upsert,
  ),
  effect: (action, listenerAPI) => {
    if (!PWALoaded.isPWA) return;

    const state = listenerAPI.getState()
    const folderSlice = state.folder

    if (folderSlice.hydrated) {
      const nextVersion = action.payload as Folder

      if (nextVersion.items.length) {
        PWALogger.debug('Starting sync due to new/updated folder items')
        // We don't auto-sync on iOS non-PWA
        syncCacheManifestDebounced(PWALoaded.isPWA || !isIOS)
      }
    }
  },
})

export default listenerMiddleware
