import { SYNC_ENTRY_FETCH_TYPE, SYNC_ENTRY_REQUEST_TYPE, SYNC_ENTRY_STATUS, SyncEntry } from '../ISyncManifest';
import { CRMIntegration, CrmIntegrationType, Tenant } from '@alucio/aws-beacon-amplify/src/models';
import {
  CRMSubmitMeetingPayload,
  CRMSubmitResponseReferences,
  DeleteRecordsResponse,
  FormSettings,
  RecordToDelete,
  SalesforceFirstSubmitPayload,
  SalesforceFirstSubmitResponse,
  SalesforceFormSettings,
  SalesforceRecordToUpsert,
  SYNC_STATUS,
  UpsertSalesforceRecordResponse,
} from '../CRMIndexedDBTypes';
import { ICRMSyncer } from '../CRMHandler';
import {
  getRecordIdsToDelete,
  getSalesforceFirstSubmitPayload,
  getSalesforceUpdateSubmitPayload,
} from '../Translators/SalesforceFormTranslator';
import { Logger } from '@aws-amplify/core';
import { MeetingORM } from 'src/types/orms';
import chunk from 'lodash/chunk';
const logger = new Logger('SalesforceSyncer', 'DEBUG');

class SalesforceSyncer implements ICRMSyncer {
  baseUrl: string = ''
  config : CRMIntegration = {} as CRMIntegration
  readonly API_VERSION = 'v56.0';
  // Used as a key for the CRM integration session in local storage
  CRM_AUTH_INFORMATION = 'CRM_AUTH_INFORMATION'

  constructor(config: CRMIntegration) {
    if (!config) throw new Error('CRM Integration is not configured')
    if (!config.instanceUrl) throw new Error('CRM Instance URL is not configured')

    this.config = config
    this.baseUrl = `${config.instanceUrl}/services/data/${this.API_VERSION}`

    this.handleDeleteMeeting = this.handleDeleteMeeting.bind(this)
  }

  // Add to the sync manifest the parent tables
  public initialize() : SyncEntry[] {
    const config = this.config
    const meetingSettings = config.meetingSetting;
    const entries = [] as SyncEntry[]

    if (!meetingSettings) return entries

    const baseUrl = this.baseUrl
    entries.push({
      id: meetingSettings.apiName,
      url: `${baseUrl}/ui-api/record-defaults/create/${meetingSettings.apiName}`,
      parameters: { apiName: meetingSettings.apiName, id: meetingSettings.apiName },
      type: CrmIntegrationType.SALESFORCE,
      subType: SYNC_ENTRY_REQUEST_TYPE.TABLE,
      fetchType: SYNC_ENTRY_FETCH_TYPE.REGULAR,
      lastSynced: new Date().getTime(),
      status: SYNC_ENTRY_STATUS.PENDING,
    })

    meetingSettings.children.map((child) =>
      entries.push({
        url: `${baseUrl}/ui-api/record-defaults/create/${child.apiName}`,
        parameters: { apiName: child.apiName, id: child.apiName },
        type: CrmIntegrationType.SALESFORCE,
        subType: SYNC_ENTRY_REQUEST_TYPE.TABLE,
        fetchType: SYNC_ENTRY_FETCH_TYPE.REGULAR,
        id: child.apiName,
        lastSynced: new Date().getTime(),
        status: SYNC_ENTRY_STATUS.PENDING,
      }),
    )

    return entries
  }

  public async processManifestEntry(entry: SyncEntry): Promise<SyncEntry[]> {
    const meetingSetting = this.config.meetingSetting;
    const baseUrl = this.baseUrl;
    const fetch = this.fetchType[entry.fetchType]
    const entries = [] as SyncEntry[]

    if (!meetingSetting) return entries

    if (entry.subType === SYNC_ENTRY_REQUEST_TYPE.TABLE) {
      const tableInfo = await fetch(entry)

      entries.push(tableInfo)

      const { data: table, parameters } = tableInfo
      const { apiName } = table.record
      const { recordTypeId, sections } = table.layout
      const apiObjectInfo = table.objectInfos[apiName].fields

      const lookupApiNames =  sections
        ?.map((section) => section?.layoutRows.map((row) => row?.layoutItems.map((item) => item)))
        ?.flat(2)
        ?.filter((item) => item.lookupIdApiName === 'Id')
        ?.map((item) => item.layoutComponents)
        ?.flat()
        ?.filter((item) => apiObjectInfo[item.apiName]?.dataType === 'Reference')
        ?.map((item) => item.apiName)

      lookupApiNames.map((lookupApiName) =>
        entries.push({
          url: `${baseUrl}/query/?q=SELECT Id, Name FROM ${apiObjectInfo[lookupApiName].referenceToInfos[0].apiName}`,
          type: CrmIntegrationType.SALESFORCE,
          subType: SYNC_ENTRY_REQUEST_TYPE.LOOKUP,
          parameters: { apiName, lookupApiName, parentId: parameters.id },
          fetchType: SYNC_ENTRY_FETCH_TYPE.SOQL,
          id: `${apiName}_${lookupApiName}`,
          lastSynced: new Date().getTime(),
          status: SYNC_ENTRY_STATUS.PENDING,
        }),
      )

      entries.push({
        url: `${baseUrl}/ui-api/object-info/${apiName}/picklist-values/${recordTypeId}`,
        type: CrmIntegrationType.SALESFORCE,
        subType: SYNC_ENTRY_REQUEST_TYPE.PICKLIST,
        parameters: { apiName, recordTypeId, parentId: parameters.id },
        fetchType: SYNC_ENTRY_FETCH_TYPE.REGULAR,
        id: `${apiName}_${recordTypeId}`,
        lastSynced: new Date().getTime(),
        status: SYNC_ENTRY_STATUS.PENDING,
      })
    }
    else if (entry.subType === SYNC_ENTRY_REQUEST_TYPE.LOOKUP) {
      const result = await fetch(entry)
      entries.push(result)
    }
    else if (entry.subType === SYNC_ENTRY_REQUEST_TYPE.PICKLIST) {
      const result = await fetch(entry)
      entries.push(result)
    }
    else {
      throw new Error('Invalid entry type')
    }

    return entries
  }

  public async configSyncComplete(entries: SyncEntry[]) : Promise<FormSettings[]> {
    const results : FormSettings[] = []
    const meetingSetting = this.config.meetingSetting;

    if (!meetingSetting) return results

    const tableData: SyncEntry[] = [];
    const pickLists: SyncEntry[] = [];
    const lookups: SyncEntry[] = [];

    entries.forEach((entry) => {
      switch (entry.subType) {
        case SYNC_ENTRY_REQUEST_TYPE.LOOKUP:
          lookups.push(entry);
          break;
        case SYNC_ENTRY_REQUEST_TYPE.PICKLIST:
          pickLists.push(entry);
          break;
        case SYNC_ENTRY_REQUEST_TYPE.TABLE:
          tableData.push(entry);
      }
    });

    tableData.forEach(({ data: table, parameters }) => {
      const { apiName } = table.record
      const { id: parentId } = parameters
      const tableSetting = meetingSetting.children.find((childSetting) => apiName === childSetting.apiName);
      const picklistData = pickLists.filter((picklist) => picklist.parameters.parentId === parentId)
      const lookupData = lookups.filter((lookup) => lookup.parameters.parentId === parentId)
      const isMainTable = meetingSetting.apiName === apiName;
      const lookupsResults = lookupData.reduce((acc, { data, parameters }) => ({
        ...acc,
        [parameters.lookupApiName]: {
          records: data.records.map((record) => ({ fields: record })),
        },
      }), {});

      const fromConfig: SalesforceFormSettings = {
        id: apiName,
        isMainTable,
        presentationsFieldName: isMainTable ? meetingSetting.presentationsFieldName : undefined,
        relationshipWithParentApiName: tableSetting?.relationshipName,
        picklists: picklistData.map(({ data }) => data.picklistFieldValues)[0] || {},
        lookups: lookupsResults,
        layout: table.layout,
        objectInfos: table.objectInfos[apiName],
        defaultRecord: table.record,
      }
      results.push(fromConfig)
    })

    return results
  }

  async submitToCRM(crmSubmitPayload: CRMSubmitMeetingPayload): Promise<CRMSubmitResponseReferences> {
    logger.debug('Submit to CRM is called.', crmSubmitPayload);
    // IF IT'S THE FIRST TIME A MEETING IS BEING SUBMITTED, WE CAN USE THE COMPOSITE TREE SF'S
    // ENDPOINT TO CREATE ALL THE RECORDS WITH ONE CALL
    if (!crmSubmitPayload.mainCrmRecordId) {
      return this.handleFirstSubmit(crmSubmitPayload);
    }

    return this.handleUpdates(crmSubmitPayload);
  }

  private async handleUpdates(crmSubmitPayload: CRMSubmitMeetingPayload): Promise<CRMSubmitResponseReferences> {
    logger.debug('Handling update submit to CRM', crmSubmitPayload);
    // GETS AN ARRAY OF RECORDS TO BE UPDATED/CREATED/DELETED
    const separatedRecords = getSalesforceUpdateSubmitPayload(crmSubmitPayload);
    logger.debug('Salesforce upserts payload', separatedRecords);

    const responses = await Promise.all([
      this.deleteRecords(separatedRecords.recordsToDelete),
      this.upsertRecords(
        crmSubmitPayload.tenant,
        separatedRecords.recordsToUpsert,
        separatedRecords.recordsToInsert),
    ]);

    return responses.reduce((acc, resp) => ({ ...acc, ...resp }), {});
  }

  public async handleDeleteMeeting(meeting: MeetingORM): Promise<CRMSubmitResponseReferences> {
    const { crmRecord } = meeting.model;

    if (!crmRecord?.crmCallId) {
      return {};
    }

    const recordToDelete = getRecordIdsToDelete(meeting)

    analytics.track('MEETING_DELETE', {
      category: 'MEETING',
      type: 'DELETE',
      integration: 'SALESFORCE',
      crmRecordId: crmRecord.crmCallId,
    });

    return this.deleteRecords(recordToDelete.concat({
      crmId: crmRecord.crmCallId,
      beaconId: 'main-record',
    }));
  }

  protected async upsertRecords(
    tenant: Tenant,
    recordsToUpsert: SalesforceRecordToUpsert[],
    insertPayload: SalesforceFirstSubmitPayload): Promise<CRMSubmitResponseReferences> {
    logger.debug('Performing upserts...');
    const baseUrl = `${this.baseUrl}/ui-api/records`;

    // NEW INSERTS
    const response = await this.handleSubmit(insertPayload, tenant);

    // UPSERTS
    const responses = await Promise.all<Promise<Response>[]>(recordsToUpsert.map((record) => {
      const isCreate = !record.salesforceId;
      return fetch(`${baseUrl}${isCreate ? '' : `/${record.salesforceId}`}`,
        this.getRequestHeaderBody(isCreate ? 'POST' : 'PATCH', {
          ...(isCreate && { apiName: record.apiName }),
          fields: record.fields,
        }));
    }));

    const jsons: UpsertSalesforceRecordResponse[] = await Promise.all(responses
      .map((response) => response?.json()));
    logger.debug('Upserts responses', jsons);

    return jsons.reduce<CRMSubmitResponseReferences>((acc, response, idx) => {
      const errors: string[] = [];

      if (Array.isArray(response)) {
        response.forEach((res) => {
          if (res.errorCode && res.message) {
            errors.push(res.message);
          }
        });
      } else if (response.enhancedErrorType && response.message) {
        errors.push(response.message);
      }

      if (errors.length) {
        logger.debug('Request with ERRORS', {
          response,
          record: recordsToUpsert[idx],
        });
      }

      return {
        ...acc,
        [recordsToUpsert[idx].beaconId]: {
          syncStatus: responses[idx]?.ok ? SYNC_STATUS.SYNCED : SYNC_STATUS.ERROR,
          externalId: response.id,
          errorMessages: errors,
        },
      }
    }, response);
  }

  protected async deleteRecords(records: RecordToDelete[]): Promise<CRMSubmitResponseReferences> {
    if (!records.length) {
      return {};
    }
    logger.debug('Deleting the following records: ', records);
    const authHeaders = this.getAuthHeader();
    const headers = {
      method: 'DELETE',
      headers: authHeaders,
    }

    const chunks = chunk<RecordToDelete>(records, 25);
    const responses = await Promise.all<Promise<Response>[]>(chunks.map((chunk) => {
      const ids = chunk.map(({ crmId }) => crmId);
      const url = `${this.baseUrl}/composite/sobjects?ids=${ids.join(',')}`;
      return fetch(url, headers);
    }));
    const jsons: DeleteRecordsResponse[] = await Promise.all(responses
      .map((response) => response?.json()));
    logger.debug('Got delete records responses', jsons.flat());

    return jsons.flat().reduce<CRMSubmitResponseReferences>((acc, response) => {
      const beaconId = records.find(({ crmId }) => crmId === response.id)?.beaconId || '';
      const errors = response.errors?.reduce<string[]>((acc, { message }) => {
        if (message === 'entity is deleted') {
          return acc;
        }
        return acc.concat(message);
      }, []);

      return {
        ...acc,
        [beaconId]: {
          syncStatus: errors?.length ? SYNC_STATUS.ERROR : SYNC_STATUS.DELETED,
          externalId: response.id,
          errorMessages: errors,
        },
      };
    }, {});
  }

  protected async handleFirstSubmit(crmSubmitPayload: CRMSubmitMeetingPayload): Promise<CRMSubmitResponseReferences> {
    logger.debug('Handling first submit to CRM');
    const payload = getSalesforceFirstSubmitPayload(crmSubmitPayload);
    return this.handleSubmit(payload, crmSubmitPayload.tenant);
  }

  private async handleSubmit(
    payload: SalesforceFirstSubmitPayload,
    tenant: Tenant): Promise<CRMSubmitResponseReferences> {
    if (!payload.records.length) {
      return {};
    }

    const response = await this.postFirstSubmit(payload, tenant);

    if (response.hasError) {
      logger.debug('An error occurred ', response.results);
    }

    return response.results.reduce<CRMSubmitResponseReferences>((acc, result) => {
      const errors = result.errors?.map((error) => error.message) || [];

      return {
        ...acc,
        [result.referenceId]: {
          externalId: result.id,
          syncStatus: errors.length ? SYNC_STATUS.ERROR : SYNC_STATUS.SYNCED,
          errorMessages: errors,
        },
      }
    }, {});
  }

  protected async postFirstSubmit(payload: SalesforceFirstSubmitPayload, tenant: Tenant)
    : Promise<SalesforceFirstSubmitResponse> {
    logger.debug('Calling endpoint', payload);

    if (!tenant.config.crmIntegration?.meetingSetting?.apiName) {
      throw new Error('API name is not defined')
    }

    const url = `${this.baseUrl}/composite/tree/${tenant.config.crmIntegration.meetingSetting.apiName}`;
    const response = await fetch(url, this.getRequestHeaderBody('POST', payload));
    const json: SalesforceFirstSubmitResponse = await response.json();
    logger.debug('Got response from API', json);
    return json;
  }

  /// ///////////////////////
  // Utility functions
  /// ///////////////////////

  private getAuthInformation = () => {
    const authInformation = localStorage.getItem(this.CRM_AUTH_INFORMATION)
      ? JSON.parse(localStorage.getItem(this.CRM_AUTH_INFORMATION) as string)
      : undefined;

    if (!authInformation) {
      throw new Error('No CRM auth information found');
    }

    return authInformation;
  }

  private getAuthHeader = () => {
    const authInformation = this.getAuthInformation();
    const accessToken = authInformation.access_token || authInformation.accessToken;
    return {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };
  }

  private getRequestHeaderBody = (method: 'POST' | 'PATCH', payload) => {
    const authHeader = this.getAuthHeader();
    return {
      method,
      headers: authHeader,
      body: JSON.stringify(payload),
    }
  };

  private fetch = async (entry: SyncEntry, options?: RequestInit) : Promise<SyncEntry> => {
    try {
      const authHeader = this.getAuthHeader();
      const headers = {
        method: options?.method || 'GET',
        headers: authHeader,
      }
      const response = await fetch(entry.url, headers);
      if (response.status >= 400) {
        throw new Error(`Error fetching ${entry.url}`);
      }
      const json = await response.json()
      return { ...entry, data: json, status: SYNC_ENTRY_STATUS.SYNCED };
    } catch (error) {
      return { ...entry, data: undefined, status: SYNC_ENTRY_STATUS.ERROR };
    }
  }

  private fetchSOQL = async (entry: SyncEntry, options?: RequestInit) : Promise<SyncEntry> => {
    try {
      const maxRecords = 150;
      const limit = 150;
      let offset = 0;
      const authHeader = this.getAuthHeader();
      const headers = {
        method: options?.method || 'GET',
        headers: authHeader,
      }

      const data : {Id: string, Name: string}[] = [];

      while (true) {
        const url = `${entry.url} LIMIT ${limit} OFFSET ${offset}`;
        const response = await fetch(url, headers);
        if (response.status >= 400) {
          throw new Error(`Error fetching ${url}`);
        }
        const json = await response.json()

        if (!json.records.length) {
          break;
        }

        data.push(...json.records);

        // means we have reached the end of the records
        if (json.records.length < limit) {
          break;
        }

        if (data.length >= maxRecords) {
          break;
        }

        offset += maxRecords;
      }

      return {
        ...entry,
        data: {
          done: true,
          totalSize: data.length,
          records: data,
        },
        status: SYNC_ENTRY_STATUS.SYNCED,
      };
    } catch (error) {
      return { ...entry, data: undefined, status: SYNC_ENTRY_STATUS.ERROR };
    }
  }

  fetchType = {
    [SYNC_ENTRY_FETCH_TYPE.REGULAR]: this.fetch,
    [SYNC_ENTRY_FETCH_TYPE.SOQL]: this.fetchSOQL,
  }
}

export { SalesforceSyncer }
