import React, { useEffect, useState, useMemo } from 'react'
import {
  DNABox,
  DNACheckbox,
  DNAIcon,
  DNAText,
  DNAChip,
  Iffy,
} from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import { ScrollView, StyleSheet } from 'react-native'
import {
  DocumentVersionORM,
  AssociatedFileORM,
  DOCUMENT_ACTIONS_ENUM,
  getAssociatedFileTitle,
} from 'src/types/types'
import {
  AssociatedFileType,
  Document,
  DocumentStatus,
  ShareType,
} from '@alucio/aws-beacon-amplify/src/models'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import DNAConditionalWrapper from 'src/components/DNA/ConditionalWrapper/DNAConditionalWrapper'
import CollapsibleCard from 'src/components/CollapsibleCard/CollapsibleCard'
import { useUserTenant } from 'src/state/redux/selector/user'
import { canPerformAction, useAllDocumentsInstance } from 'src/state/redux/selector/document'
import { ShareFileOption } from 'src/utils/shareLink/shareLink.web'
import { useDNAFileShareModalState } from '../state/context/DNAFileShareModalStateProvider'
import { getMainFileShareOptions } from 'src/state/machines/sharing/shareUtils'
import { STEP_STATE } from 'src/state/machines/sharing/shareTypes'

const styles = StyleSheet.create({
  documentList: {
    borderColor: colors['color-gray-80'],
    borderRadius: 4,
    borderWidth: 2,
    paddingVertical: 12,
    paddingHorizontal: 20,
    marginTop: 4,
    marginBottom: 24,
  },
  sectionTitle: {
    textTransform: 'uppercase',
  },
  tooltipIcon: {
    color: '#B6B6B6',
  },
  documentChip: {
    minWidth: 45,
    marginTop: 8,
  },
});

type AssociatedFilesSelectionForSingleDocProps = {
  documentVersionORM: DocumentVersionORM,
  multipleDocShare?: boolean,
}

const sortTitle = (
  a: AssociatedFileORM, b: AssociatedFileORM,
) => getAssociatedFileTitle(a)?.localeCompare(getAssociatedFileTitle(b) ?? '') ?? 0;

export const ShareAssociatedFileRow: React.FC<{
  associatedFile: AssociatedFileORM
  reducedDocs: Record<string, ShareFileOption>,
  onToggleCheckbox: (docId: string) => void
}> = props => {
  const {
    associatedFile,
    reducedDocs,
    onToggleCheckbox,
  } = props;
  return (
    <DNABox key={associatedFile.model.id}>
      <DNABox appearance="row" spacing="md" alignY="center" fill childStyle={[2, { flex: 1 }]}>
        <DNACheckbox
          checked={reducedDocs[associatedFile.file?.id!].beingShared}
          disabled={associatedFile.model.isDefault}
          status="primary"
          appearance="default"
          onChange={() => onToggleCheckbox(associatedFile.file?.id!)}
        />
        <DNABox appearance="col" alignY="center">
          <DNAText numberOfLines={1} style={{ width: 600 }}>{getAssociatedFileTitle(associatedFile)?.trim()}</DNAText>
          <DNABox style={styles.documentChip}>
            <DNAChip appearance="tag">
              {associatedFile.file?.type}
            </DNAChip>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

const AssociatedFilesSelectionForSingleDoc: React.FC<AssociatedFilesSelectionForSingleDocProps> = (props) => {
  const { documentVersionORM, multipleDocShare } = props
  const {
    shareMachineSend,
  } = useDNAFileShareModalState()

  const [selectAll, setSelectAll] = useState<boolean>(false)
  const tenant = useUserTenant();
  const allDocumentORM = useAllDocumentsInstance()

  const canMainFileBeShared = tenant
    ? canPerformAction(
      DOCUMENT_ACTIONS_ENUM.share,
      documentVersionORM.meta.customValues.configsMap,
      tenant,
    )
    : false

  const associatedFiles = documentVersionORM
    .relations
    .associatedFiles
    .filter((associatedFile) => {
      if (associatedFile.model.type !== AssociatedFileType.DOCUMENT) {
        return true;
      }

      // TODO is it possible to refactor to not use `as`?
      const isDistributable = associatedFile.meta.canBeSharedByMSL;
      return (associatedFile.file as Document).status === DocumentStatus.PUBLISHED && isDistributable
    });

  const requiredDoc: AssociatedFileORM[] = associatedFiles
    .sort(sortTitle).reduce((acc: AssociatedFileORM[], associatedFile: AssociatedFileORM) => {
      if (associatedFile.model.isDefault) return [...acc, associatedFile]
      else return acc
    }, [])
  const optionalDoc: AssociatedFileORM[] = associatedFiles
    .sort(sortTitle).reduce((acc: AssociatedFileORM[], associatedFile: AssociatedFileORM) => {
      const isDistributable = associatedFile.meta.canBeSharedByMSL;
      if (!associatedFile.model.isDefault && isDistributable) return [...acc, associatedFile]
      else return acc
    }, [])

  const mainFileFormatted: Record<string, ShareFileOption> = {
    [documentVersionORM.relations.documentORM.model.id]: getMainFileShareOptions(
      documentVersionORM,
      tenant!,
      canMainFileBeShared && requiredDoc.length === 0 && optionalDoc.length === 0,
    ),
  }

  const allDocsFormatted = useMemo(() => {
    return associatedFiles?.reduce<Record<string, ShareFileOption>>((acc, associatedFile) => {
      if (associatedFile.file && acc[associatedFile.file.id]) {
        return acc
      } else {
        const fileTitle = getAssociatedFileTitle(associatedFile)
        acc[associatedFile.file?.id!] = {
          isMainDoc: false,
          isDefault: associatedFile.model.isDefault ?? false,
          beingShared: associatedFile.model.isDefault ?? false,
          isDistributable: associatedFile.meta.canBeSharedByMSL,
          contentProps: associatedFile.model.type === 'ATTACHED_FILE'
            ? {
              contentId: associatedFile.file?.id!,
              sharedContentId: associatedFile.model.id,
              title: fileTitle,
              type: ShareType.ATTACHED_FILE,
            }
            : {
              contentId: associatedFile.file?.id!,
              sharedContentId: associatedFile.model.id,
              title: fileTitle,
              type: ShareType.DOCUMENT_VERSION,
            },
        }
        return acc
      }
    }, mainFileFormatted) ?? {}
  }, [associatedFiles])

  const [reducedDocs, setReducedDocs] = useState<Record<string, ShareFileOption>>(allDocsFormatted)
  const [numOfSelected, setNumOfSelected] = useState<number>()

  useEffect(() => {
    const sharedDocs: ShareFileOption[] = [];

    for (const doc in reducedDocs) {
      if (reducedDocs[doc].beingShared) {
        sharedDocs.push(reducedDocs[doc])
      }
    }

    sharedDocs.sort((a, b) => {
      if (a.isMainDoc) {
        return -1
      } else if (b.isMainDoc) {
        return 1
      } else {
        return (a.contentProps.title ?? '').localeCompare(b.contentProps.title ?? '')
      }
    })

    const docsToShare = sharedDocs.map((file: ShareFileOption): ShareFileOption => {
      let newContentId: string | undefined

      if (file.contentProps.type === ShareType.DOCUMENT_VERSION) {
        const [currentAssociatedDoc] = allDocumentORM
          .filter(currentDoc => file.contentProps.contentId === currentDoc.model.id)

        newContentId = currentAssociatedDoc != null
          ? currentAssociatedDoc.relations.version.latestPublishedDocumentVersionORM?.model.id!
          : file.contentProps.contentId
      }

      return {
        ...file,
        contentProps: {
          ...file.contentProps,
          ...(newContentId ? { contentId: newContentId } : { }),
        },
      }
    })
    setNumOfSelected(docsToShare.length)
    shareMachineSend({
      type: 'SET_DOCS_TO_SHARE',
      payload: { contentToShare: { [documentVersionORM.model.id]: docsToShare } },
    })
  }, [allDocumentORM, reducedDocs]);

  useEffect(() => {
    const docsBeingSharedCount = Object
      .values(reducedDocs)
      .filter(doc => !doc.isDefault && doc.beingShared)
      .length;
    if (docsBeingSharedCount === 1) {
      setSelectAll(true);
    }
  }, [])

  const handleToggleCheckbox = (docId: string) => {
    const selectableDocsCount = optionalDoc.length + (canMainFileBeShared ? 1 : 0)
    const docsBeingSharedCount = Object
      .values(reducedDocs)
      .filter(doc => !doc.isDefault && doc.beingShared)
      .length;

    if (!reducedDocs[docId].beingShared && docsBeingSharedCount === selectableDocsCount - 1) {
      setSelectAll(true)
    }
    else if (reducedDocs[docId].beingShared && docsBeingSharedCount === selectableDocsCount) {
      setSelectAll(false)
    }

    setReducedDocs(p => ({
      ...p,
      [docId]: {
        ...p[docId],
        ...{ beingShared: !p[docId].beingShared },
      },
    }))
  }

  const handleSelectAll = () => {
    setSelectAll(p => !p)
    for (const doc in reducedDocs) {
      // if it is default dont toggle
      if (!reducedDocs[doc].isDefault && reducedDocs[doc].isDistributable) {
        setReducedDocs(p => ({ ...p, [doc]: { ...p[doc], ...{ beingShared: !selectAll } } }))
      }
    }
  }

  const requiredDocLength = requiredDoc.length
  const optionalDocLength = optionalDoc.length
  const showSelectAll = ((canMainFileBeShared ? 1 : 0) + optionalDocLength) > 1
  const isMainDocBeingShared = reducedDocs[documentVersionORM.relations.documentORM.model.id].beingShared

  return (
    <DNAConditionalWrapper
      condition={multipleDocShare}
      wrapper={children => (
        <CollapsibleCard
          headerTitle={
            <DNABox fill spacing="between" alignY="center" style={{ marginLeft: 5 }}>
              <DNAText bold numberOfLines={2} style={{ width: 580 }}>{documentVersionORM.model.title?.trim()}</DNAText>
              <DNAText status="subtle">{`${numOfSelected} selected`}</DNAText>
            </DNABox>
          }
          onToggleChanged={(collapsed) => !collapsed}
          isCollapsed={false}
        >{children}</CollapsibleCard>
      )}
    >
      <DNABox appearance="col" spacing="xs" testID="associated-files-share-modal">
        {/* SELECT ALL CHECKBOX */}
        <Iffy is={showSelectAll}>
          <DNABox appearance="row" spacing="sm" fill style={styles.documentList}>
            <DNACheckbox
              checked={selectAll}
              status="primary"
              appearance="default"
              testID="select-all"
              onChange={handleSelectAll}
            />
            <DNAText>Select all</DNAText>
          </DNABox>
        </Iffy>

        {/* MAIN FILE */}
        <DNABox appearance="col" spacing="xs" alignY="center" fill>
          <DNABox alignY="center" spacing="sm">
            <DNAText bold c1 style={styles.sectionTitle}>Main file</DNAText>
            <DNAPopover placement="top" >
              <DNAPopover.Anchor>
                <DNAIcon name="help-circle-outline" status="tertiary" size="lg" style={styles.tooltipIcon} />
              </DNAPopover.Anchor>
              <DNAPopover.Content offset={2}>
                <DNAText status="basic">
                  This is the active file that you selected before opening this modal.
                </DNAText>
              </DNAPopover.Content>
            </DNAPopover>
          </DNABox>
          <DNABox
            appearance="row"
            spacing="md"
            alignY="center"
            fill
            style={styles.documentList}
            childStyle={[2, { flex: 1 }]}
          >
            <DNACheckbox
              disabled={!canMainFileBeShared}
              checked={isMainDocBeingShared}
              status="primary"
              appearance="default"
              onChange={() => handleToggleCheckbox(documentVersionORM.relations.documentORM.model.id)}
            />
            <DNABox appearance="col" alignY="center">
              <DNAText style={{ flex: 1, fontSize: 14, lineHeight: 16, width: 600 }} numberOfLines={5} >
                {documentVersionORM.model.title?.trim()}
              </DNAText>
              <DNABox style={styles.documentChip} spacing="sm">
                <DNADocumentChip
                  item={documentVersionORM.relations.documentORM}
                  variant="docType"
                />
                <Iffy is={!canMainFileBeShared}>
                  <DNAChip status="primary">
                    {'Non-distributable'}
                  </DNAChip>
                </Iffy>
              </DNABox>
            </DNABox>
          </DNABox>
        </DNABox>

        {/* REQUIRED FILES */}
        <Iffy is={requiredDocLength}>
          <DNABox appearance="col" spacing="xs" alignY="center" fill>
            <DNABox alignY="center" spacing="sm">
              <DNAText bold c1 style={styles.sectionTitle}>Required</DNAText>
              <DNAPopover placement="top" >
                <DNAPopover.Anchor>
                  <DNAIcon name="help-circle-outline" status="tertiary" size="lg" style={styles.tooltipIcon} />
                </DNAPopover.Anchor>
                <DNAPopover.Content offset={2}>
                  <DNAText status="basic">
                    You are required to share these files when distributing externally.
                  </DNAText>
                </DNAPopover.Content>
              </DNAPopover>
            </DNABox>
            <DNABox appearance="col" spacing="md" style={styles.documentList}>
              {requiredDoc.map(associatedFile =>
                (
                  <ShareAssociatedFileRow
                    key={associatedFile.model.id}
                    associatedFile={associatedFile}
                    reducedDocs={reducedDocs}
                    onToggleCheckbox={handleToggleCheckbox}
                  />
                ),
              )}
            </DNABox>
          </DNABox>
        </Iffy>

        {/* OPTIONAL FILES */}
        <Iffy is={optionalDocLength}>
          <DNABox appearance="col" spacing="xs" alignY="center" fill>
            <DNABox alignY="center" spacing="sm">
              <DNAText bold c1 style={styles.sectionTitle}>Optional</DNAText>
              <DNAPopover placement="top" >
                <DNAPopover.Anchor>
                  <DNAIcon name="help-circle-outline" status="tertiary" size="lg" style={styles.tooltipIcon} />
                </DNAPopover.Anchor>
                <DNAPopover.Content offset={2}>
                  <DNAText status="basic">
                    These files are optional for you to share when distributing externally.
                  </DNAText>
                </DNAPopover.Content>
              </DNAPopover>
            </DNABox>
            <DNABox appearance="col" spacing="md" style={styles.documentList}>
              {optionalDoc.map(associatedFile =>
                (
                  <React.Fragment key={associatedFile.model.id}>
                    <ShareAssociatedFileRow
                      associatedFile={associatedFile}
                      reducedDocs={reducedDocs}
                      onToggleCheckbox={handleToggleCheckbox}
                    />
                  </React.Fragment>),
              )}
            </DNABox>
          </DNABox>
        </Iffy>
      </DNABox>
    </DNAConditionalWrapper>
  )
}

export const SelectAssociatedFilesBody: React.FC = () => {
  const {
    shareContent,
    currentState,
    // in associated files the shareContent can only be DocumentVersionORM
  } = useDNAFileShareModalState() as { shareContent: DocumentVersionORM[], currentState: STEP_STATE | undefined}

  const currentlyVisible: boolean = currentState === STEP_STATE.ASSOCIATED_FILES
  return (
    <ScrollView contentContainerStyle={{ flex: 1, margin: 16, display: currentlyVisible ? 'flex' : 'none' }}>
      {shareContent.length === 1
        ? <AssociatedFilesSelectionForSingleDoc
            key={shareContent[0].model.id}
            documentVersionORM={shareContent[0]}
        />
        : <DNABox appearance="col" fill spacing="md">
          {
            shareContent.map(documentVersionORM => {
              return (
                <AssociatedFilesSelectionForSingleDoc
                  key={documentVersionORM.model.id}
                  documentVersionORM={documentVersionORM}
                  multipleDocShare={true}
                />
              )
            })
          }
        </DNABox>
      }
    </ScrollView>
  )
}
