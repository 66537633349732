import React, { useState } from 'react'
import { DNAButton, DNABox, DNASlider, DNAHeader } from '@alucio/lux-ui'
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings'
import FAQScreen from 'src/screens/FAQ/FAQ';
import { generateZendeskURL } from 'src/utils/zendeskHelpers';
import { ZENDESK_FEATURE } from '@alucio/aws-beacon-amplify/src/API';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag/useFeatureFlag';
import { FeatureFlags } from 'src/types/types';

type FAQButtonProps = { }

export const openKnowledgeBase = async (deviceMode: DeviceMode, returnTo?: string) => {
  const url = await generateZendeskURL(ZENDESK_FEATURE.SSO, returnTo);
  if (deviceMode === DeviceMode.tablet) {
    window.location.assign(url);
  }
  else {
    window.open(url, '_blank');
  }
}

export const FAQButtonDesktop: React.FC<FAQButtonProps> = () => {
  const { isOnline }  = useAppSettings()
  const isKnowledgebaseEnabled = useFeatureFlag(FeatureFlags.BEAC_2519_zendesk_knowledgebase);

  const handlePress = async () => {
    if (isOnline && isKnowledgebaseEnabled) {
      openKnowledgeBase(DeviceMode.desktop);
    }
    else {
      window.open('/faq', '_blank');
    }
  }

  return (
    <DNAButton
      appearance="outline"
      status="tertiary"
      size="lg"
      padding="sm"
      iconLeft="help-circle-outline"
      testID="user-guide-button"
      onPress={handlePress}
    />
  )
}

export const FAQButtonTablet: React.FC<FAQButtonProps> = () => {
  const [visible, setVisible] = useState<boolean>(false)
  const { isOnline } = useAppSettings();
  const isKnowledgebaseEnabled = useFeatureFlag(FeatureFlags.BEAC_2519_zendesk_knowledgebase);

  const handlePress = async () => {
    if (isOnline && isKnowledgebaseEnabled) {
      openKnowledgeBase(DeviceMode.tablet);
    }
    else {
      setVisible(p => !p);
    }
  }

  return (
    <>
      <DNAButton
        appearance="ghostLink"
        padding="none"
        rounded="full"
        status="dark"
        size="xl"
        iconLeft="help-circle-outline"
        onPress={handlePress}
        testID="icon-help-circle-outline"
      />
      <DNASlider
        visible={visible}
        setVisible={setVisible}
      >
        <DNABox
          fill
          appearance="col"
          style={{ backgroundColor: 'white' }}
        >
          <DNAHeader status="brand" title="User Guide / FAQ">
            <DNAHeader.Left>
              <DNAButton
                status="secondary"
                onPress={() => setVisible(false)}
                testID="tablet-header-close"
              >
                Close
              </DNAButton>
            </DNAHeader.Left>
          </DNAHeader>
          <FAQScreen />
        </DNABox>
      </DNASlider>
    </>
  )
}

type DeviceModeVariants = Record<DeviceMode, React.FC<FAQButtonProps>>
const deviceModeVariants:DeviceModeVariants = {
  desktop: FAQButtonDesktop,
  tablet: FAQButtonTablet,
}

export const FAQButton: React.FC<FAQButtonProps> = (props) => {
  const { deviceMode } = useAppSettings()
  const CurrentDeviceModeVariant = deviceModeVariants[deviceMode]
  return <CurrentDeviceModeVariant {...props}/>
}

export default FAQButton
