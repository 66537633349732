import React, { forwardRef, PropsWithChildren, ReactNode, useState } from 'react';
import { StyleSheet, ViewProps } from 'react-native';
import { createPortal, DNABox, Icon, Iffy } from '@alucio/lux-ui/src';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
  UniqueIdentifier,
  DragOverlay,
  // CollisionDetections
  rectIntersection,
  closestCenter,
  closestCorners,
} from '@dnd-kit/core';
import {
  useSortable,
  SortableContext,
  sortableKeyboardCoordinates,
  // Strategies
  rectSortingStrategy,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
  rectSwappingStrategy,
} from '@dnd-kit/sortable';
// ModifiersList
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';

const styles = StyleSheet.create({
  dndIconContainer: {
    padding: 12,
  },
  dndIcon: {
    color: colors['color-gray-200'],
    height: 21,
    width: 20,
  },
});

enum StrategiesEnum {
  verticalListSortingStrategy = 'verticalListSortingStrategy',
  rectSortingStrategy = 'rectSortingStrategy',
  horizontalListSortingStrategy = 'horizontalListSortingStrategy',
  rectSwappingStrategy = 'rectSwappingStrategy',
}

type Strategies = keyof typeof StrategiesEnum

const strategies = {
  verticalListSortingStrategy,
  rectSortingStrategy,
  horizontalListSortingStrategy,
  rectSwappingStrategy,
}

enum ModifiersListEnum {
  restrictToVerticalAxis = 'restrictToVerticalAxis',
  restrictToWindowEdges = 'restrictToWindowEdges',
}

type ModifiersList = keyof typeof ModifiersListEnum

const modifiersList = {
  restrictToVerticalAxis,
  restrictToWindowEdges,
}

enum CollisionDetectionsEnum {
  rectIntersection = 'rectIntersection',
  closestCenter = 'closestCenter',
  closestCorners = 'closestCorners',
}

type CollisionDetections = keyof typeof CollisionDetectionsEnum

const collisionDetections = {
  rectIntersection,
  closestCenter,
  closestCorners,
}

type DNARearrangerWrapperProps = React.FC<PropsWithChildren<DNARearrangerProps>> & {
  SortableItem: React.FC<PropsWithChildren<SortableItemProps>>,
}

interface DNARearrangerProps {
  items: (UniqueIdentifier | { id: UniqueIdentifier })[]
  onDragStart?: (event: DragStartEvent) => void,
  onDragEnd: (event: DragEndEvent) => void,
  collisionDetection?: CollisionDetections,
  modifiers?: ModifiersList[],
  strategy?: Strategies,
  overlay?: ReactNode,
  leftIcon? : string,
}

type ItemProps = {
  style?: React.CSSProperties,
  children: React.ReactNode,
}

type InteractiveItemProps = {
  id: UniqueIdentifier,
  itemId: UniqueIdentifier,
  containerId: UniqueIdentifier,
  disabled?: boolean,
}

interface SortableItemProps extends InteractiveItemProps {
  style?: React.CSSProperties,
  visible?: boolean,
  handle?: boolean,
  boxContainerStyle?: ViewProps['style'],
  leftIcon?: string,
}

export const Item = React.memo(
  forwardRef<HTMLImageElement, ItemProps>((props, ref) => {
    const { style, children, ...restProps } = props

    return (
      <div ref={ref} style={style} {...restProps}>
        <DNABox fill>
          {children}
        </DNABox>
      </div>
    );
  }),
);

export const SortableItem: React.FC<PropsWithChildren<SortableItemProps>> = ({
  id,
  itemId,
  containerId,
  visible = true,
  disabled,
  style,
  children,
  handle,
  boxContainerStyle,
  leftIcon = 'drag-horizontal',
}) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    disabled,
    data: {
      containerId,
      itemId,
    },
  });

  if (!visible) return null

  const sortableStyle: React.CSSProperties = {
    display: 'flex',
    boxSizing: 'border-box',
    transformOrigin: '0 0',
    // See https://docs.dndkit.com/api-documentation/sensors/touch#recommendations
    touchAction: 'manipulation',
    opacity: isDragging ? 0.3 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  }

  const finalStyle = StyleSheet.flatten([
    sortableStyle,
    style,
  ]) as React.CSSProperties

  const handleBtnStyle: React.CSSProperties = {
    backgroundColor: 'transparent',
    border: 'none',
  }

  return (
    <Item
      ref={disabled ? undefined : setNodeRef}
      style={finalStyle}
      {...((disabled || handle) ? {} : attributes)}
      {...((disabled || handle) ? {} : listeners)}
    >
      <DNABox fill style={boxContainerStyle}>
        <Iffy is={handle && listeners && attributes}>
          <DNABox alignY={leftIcon === 'drag-horizontal' ? 'center' : 'start'} style={styles.dndIconContainer}>
            <button {...listeners} {...attributes} style={handleBtnStyle}>
              <Icon
                name={leftIcon}
                style={[styles.dndIcon, isDragging ? { cursor: 'grabbing' } : { cursor: 'grab' }]}
              />
            </button>
          </DNABox>
        </Iffy>
        {children}
      </DNABox>
    </Item>
  );
}

/**
* The DNARearranger is a drag and drop wrapper that takes the following props:
* * items: an array of objects with a unique identifier id, or just an
*   array of unique identifiers, representing the items to be sorted.
* * onDragEnd: a function that is called when a drag and drop action ends,
*   providing information about the action that just occurred.
* * [collisionDetection](https://docs.dndkit.com/api-documentation/context-provider/collision-detection-algorithms)?: an optional parameter specifying the collision
*   detection strategy to be used for the drag and drop interactions.
* * [modifiers](https://docs.dndkit.com/api-documentation/modifiers)?: an optional array of modifier functions to customize the
*   behavior of the drag and drop interactions.
* * [strategy](https://docs.dndkit.com/presets/sortable/sortable-context#strategy)?: an optional parameter specifying the strategy to be used
*   for the drag and drop interactions.
*
* The DNARearranger.SortableItem is the wrapper for the individual draggable/sortable item
*   that is wrapped inside DNARearranger, this component takes the following props:
* * id: a unique identifier for the item being sorted.
* * itemId: a unique identifier for the item being dragged.
* * containerId: a unique identifier for the container that holds the items being sorted.
* * visible?: an optional boolean value indicating whether the item should be visible.
* * disabled?: an optional boolean value indicating whether the item is disabled.
* * style?: an optional object of CSS styles to be applied to the item.
* * handle?: an optional boolean value indicating whether the item should have a handle for dragging.
*
* @see Checkout [dndkit Storybook](https://master--5fc05e08a4a65d0021ae0bf2.chromatic.com/?path=/story/core-draggable-hooks-usedraggable--basic-setup) for more options.
*/
export const DNARearranger: DNARearrangerWrapperProps = ({
  children,
  items,
  onDragStart,
  onDragEnd,
  collisionDetection,
  modifiers,
  strategy,
  overlay,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const chosenModifiers = modifiers?.map(modifierName => modifiersList[modifierName])

  const handleDragStart = (event: DragStartEvent) => {
    setIsDragging(true);
    onDragStart && onDragStart(event)
  }

  const handleDragEnd = (event: DragEndEvent) => {
    setIsDragging(false);
    onDragEnd(event)
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetection ? collisionDetections[collisionDetection] : undefined}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      modifiers={chosenModifiers}
    >
      <SortableContext
        items={items}
        strategy={strategy ? strategies[strategy] : undefined}
      >
        {children}
      </SortableContext>
      <Iffy is={overlay}>
        {createPortal(
          <DragOverlay>
            {isDragging ? (
              overlay
            ) : null}
          </DragOverlay>, window.document.body)}
      </Iffy>
    </DndContext>
  )
}

DNARearranger.displayName = 'DNARearranger'
SortableItem.displayName = 'DNARearrangerItem'
DNARearranger.SortableItem = SortableItem

export default DNARearranger
