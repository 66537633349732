import React, { useMemo, useCallback, useRef } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { DNABox, DNAIcon, Tabs, luxColors, util } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useAppSettings } from 'src/state/context/AppSettings';
import { usePresentationSelector } from './PresentationSelectorStateProvider';
import InputComponent from 'src/components/Publishers/InputComponent';
import debounce from 'lodash/debounce';
import { Input } from '@ui-kitten/components';
import { useCanPerformSearch } from 'src/state/redux/selector/documentSearch/documentSearch';
import { useLDClient } from 'launchdarkly-react-client-sdk';

interface TabProps {
  isSelected: boolean;
  option: {
    title: string,
  }
  onTabSelected: (tab: string) => void,
}

const styles = StyleSheet.create({
  searchBarContainerStyle: {
    minWidth: 80,
    marginHorizontal: 16,
    flex: 1,
  },
  searchBarStyle: {
    backgroundColor: luxColors.info.primary,
    borderColor: luxColors.disabled.secondary,
    maxWidth: 450,
    color: colors['gray-300'],
    borderRadius: 4,
    flexDirection: 'row',
    overflow: 'hidden',
  },
  headerTabsWrapper: {
    marginTop: '10px',
  },
  headerWrapper: {
    height: 56,
    paddingLeft: 64,
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
  },
  headerWrapperTablet: {
    paddingHorizontal: 0,
  },
});

const tabs: TabProps['option'][] = [
  { title: 'Bookmarks' },
  { title: 'Library' },
  { title: 'My Uploads' },
];

const MagnifyingIcon:React.FC = () => {
  return (
    <TouchableOpacity disabled>
      <DNAIcon.Styled name="magnify" appearance="ghost" status="tertiary" />
    </TouchableOpacity>
  );
}

const Header: React.FC = () => {
  const {
    localSearchText,
    selectedTab,
    setLocalSearchText,
    setSelectedTab,
    setSearchText,
  } = usePresentationSelector()

  const LDClient = useLDClient();
  const enableMyUploads = LDClient?.variation('enable-viewer-file-upload', false);
  const filteredTabs = enableMyUploads ? tabs : tabs.filter((item) => item.title !== 'My Uploads');

  const canPerformSearch = useCanPerformSearch();
  const { deviceMode } = useAppSettings()
  // Used to return to that tab if user clears the search input
  const tabSelectedBeforeSearch = useRef(tabs[0].title);

  const ClearButtonIcon:React.FC = () => {
    const handleClear = () => {
      onSearchTextChange('')
      inputRef.current?.focus()
    }
    return (
      <TouchableOpacity onPress={handleClear}>
        <DNAIcon.Styled name="close" appearance="ghost" status="tertiary" />
      </TouchableOpacity>
    );
  }

  const handleTabSelect = (tab:string) => {
    setLocalSearchText('');
    setSearchText('');
    setSelectedTab(tab);
  }

  // Memoized Components
  const MemoizedClearButtonIcon = useMemo(() => ClearButtonIcon, [selectedTab]);
  const MemoizedMagnifyingIcon = useMemo(() => MagnifyingIcon, []);
  const MemoizedTabs = useMemo(() => (<Tabs
    selectedTab={selectedTab}
    onSelectTab={handleTabSelect}
    tabs={filteredTabs}
    selectedTabVariant="primary"
  />), [selectedTab]);
  const inputRef = useRef<Input>(null)

  const CloseIcon:React.FC = () => {
    return !localSearchText ? null : <MemoizedClearButtonIcon/>
  }

  const debouncedSearchText = useCallback(
    debounce(
      (text: string) => { setSearchText(text) },
      1000,
    ),
    [setSearchText],
  )

  const onSearchTextChange = (text: string) => {
    const canTextBeSearchable = canPerformSearch(text);
    setLocalSearchText(text);

    if (text) {
      debouncedSearchText(text)
    }

    if (canTextBeSearchable && selectedTab && selectedTab !== 'Search results') {
      tabSelectedBeforeSearch.current = selectedTab;
      setSelectedTab('Search results');
    } else if (!canTextBeSearchable && selectedTab === 'Search results') {
      setSelectedTab(tabSelectedBeforeSearch.current);
    }
  }

  const isTablet = deviceMode === 'tablet'

  return (
    <DNABox
      alignY="center"
      style={util.mergeStyles(
        undefined,
        styles.headerWrapper,
        [styles.headerWrapperTablet, isTablet],
      )}
    >
      <DNABox testID="tab-header" style={styles.headerTabsWrapper}>
        {MemoizedTabs}
      </DNABox>
      <DNABox fill style={styles.searchBarContainerStyle}>
        <InputComponent
          testID="search-build-presentation"
          ref={inputRef}
          getRightIconFunction={() => <CloseIcon/>}
          getLeftIconFunction={() => <MemoizedMagnifyingIcon/>}
          hideLabel={true}
          inputStyle={styles.searchBarStyle}
          onChangeText={onSearchTextChange}
          placeholderTextColor={luxColors.contentPanelBackground.quinary}
          removeMarginPadding={true}
          title="Search files..."
          value={localSearchText}
        />
      </DNABox>
    </DNABox>
  );
}

export default Header
