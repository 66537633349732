import React, { useEffect } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { DNACard, Iffy, util } from '@alucio/lux-ui';
import { usePresentationSelector, Options } from './PresentationSelectorStateProvider';
import { ContentORMs } from 'src/components/DNA/types';
import { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';
import { DocumentORM, DocumentVersionORM, FolderORM, isDocumentORM } from 'src/types/types';
import DNAFlatList from 'src/components/DNA/FlatList/DNAFlatList';
import SlidesGroupPool from 'src/components/DnD/Clone/PresentationBuilder/SlidesGroupPool';
import { useDocumentSearchV2Context } from 'src/hooks/useDocumentSearchV2';
import ListSkeleton from 'src/components/ListSkeleton/ListSkeleton';

export const styles = StyleSheet.create({
  documentList: {
    marginBottom: 32,
    marginHorizontal: 64,
  },
});

const emptyVariants = {
  [Options.BOOKMARKS]: EmptyVariant.FolderListBookmark,
  [Options.SEARCH_RESULTS]: EmptyVariant.DocumentResultsListEmpty,
  [Options.LIBRARY]: EmptyVariant.DocumentListEmpty,
  [Options.MY_UPLOADS]: EmptyVariant.DocumentListEmpty,
}

const Content: React.FC = () => {
  const {
    selectedTab,
    selectedDocumentVersionORM,
    currentTab,
    setSelectedDocumentVersionORM,
  } = usePresentationSelector()

  const onItemPress = (item: ContentORMs) => {
    const updatedItem = isDocumentORM(item) ? item.relations.version.latestPublishedDocumentVersionORM : undefined
    setSelectedDocumentVersionORM(updatedItem)
  }

  const emptyVariant: EmptyVariant = emptyVariants[selectedTab] ?? EmptyVariant.FolderListEmpty

  function clearNavStack() {
    setSelectedDocumentVersionORM(undefined);
  }

  // On tab switch, clear the Nav Stack
  useEffect(() => { clearNavStack() }, [selectedTab]);

  const { isLoadingSearch } = useDocumentSearchV2Context();

  const isLoading = isLoadingSearch && selectedTab === Options.SEARCH_RESULTS

  return (
    <ScrollView style={{ flex: 1 }}>
      {/* NO DOC SELECTED */}
      <Iffy is={!selectedDocumentVersionORM}>
        {/* LOADING */}
        <Iffy is={isLoading}>
          <DNACard appearance="outline" style={styles.documentList}>
            <ListSkeleton numberOfItems={5} />
          </DNACard>
        </Iffy>
        {/* LIST OF DOCUMENTS */}
        <Iffy is={!isLoading}>
          <DNACard
            appearance="outline"
            style={util.mergeStyles(
              undefined,
              styles.documentList,
            )}
          >
            <DNAFlatList<(DocumentORM | DocumentVersionORM | FolderORM) >
              items={currentTab[selectedTab].items}
              variant="customDeck"
              emptyVariant={emptyVariant}
              onPress={onItemPress}
              useEmptyContainer={false}
              isSearch={selectedTab === Options.SEARCH_RESULTS}
            />
          </DNACard>
        </Iffy>
      </Iffy>
      {/* DND SOURCE POOL ITEMS */}
      <SlidesGroupPool />
    </ScrollView>
  )
}

export default Content
