import React, { PropsWithChildren } from 'react'
import { StyleSheet, ViewStyle } from 'react-native'

import {
  DNABox,
  DNAButton,
  DNAText,
  DNAContextMenu,
  Stack,
  DNAIcon, util, Iffy,
} from '@alucio/lux-ui'

import {
  PayloadGroup,
  usePresentationBuilderState,
  GroupStatus,
  ModifiedPayloadGroup,
  AssociatedParentsMap,
} from 'src/components/PresentationBuilder/state/PresentationBuilderStateProvider'
import {
  EDITOR_TYPE,
} from 'src/state/redux/slice/PresentationBuilder/PresentationBuilder'
import { CustomDeckORM } from 'src/types/orms'
import { GroupHeaderActionItems } from './GroupedSlides'

const S = StyleSheet.create({
  opaqueBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  containerMargin: {
    margin: 8,
  },
  flexButton: {
    flex: 1,
    margin: 'auto',
    maxWidth: 384,
  },
})

export const notPublishedStatus = {
  [GroupStatus.REVOKED]: true,
  [GroupStatus.DELETED]: true,
  [GroupStatus.ARCHIVED]: true,
}

const WarningButton: React.FC<{
  onPress: () => void,
  label: string,
  icon: string,
}> = (props) => {
  const { onPress, icon, label } = props
  const { displayPageSourceFile } = usePresentationBuilderState();

  return (
    <DNAButton
      testID="find-and-replace-button"
      status="warning"
      iconLeft={icon}
      iconStyle={{ marginRight: 4 }}
      onPress={onPress}
      style={util.mergeStyles(
        undefined,
        S.flexButton,
        [{ marginBottom: 40 }, displayPageSourceFile],
      )}
    >
      { label }
    </DNAButton>
  )
}

// [TODO-928]
//  - This would be useful in the context level
//    -or-
// - Put it in the useSlide hook, but then it requires prop drilling which I think we should avoid for now
// - Current performance impact is pretty negligble, so not a big deal right now
const canRemoveGroup = (group: PayloadGroup, selectedGroups: PayloadGroup[]): boolean => {
  const pageIdRequiredCountMap = group.documentVersionORM?.meta.allPages.reduce<Record<string, number>>(
    (acc, page) => {
      if (page.isRequired) {
        const selectedRequiredCount = selectedGroups.reduce<number>(
          (acc, grp) => {
            if (grp.documentVersionORM === group.documentVersionORM) {
              grp.pages.forEach(pg => {
                if (pg.isRequired && pg.pageId === page.pageId)
                { acc++ }
              })
            }

            return acc
          },
          0,
        )

        acc[page.pageId] = selectedRequiredCount
      }
      return acc
    },
    { },
  ) ?? { }

  const canRemove = group.pages.every(page => {
    const requiredCount = pageIdRequiredCountMap[page.pageId]

    return requiredCount !== undefined
      ? requiredCount > 1
      : true
  })

  return canRemove
}

// This function check if this group has association to determin if the remove button should show or not
// If page within group has associated parent(s) and there is no duplicate of page, then user cannot remove the group
const isSingleAndHasAssociation = (
  group: ModifiedPayloadGroup,
  selectedGroups: PayloadGroup[],
  associatedParentsMap: AssociatedParentsMap,
):boolean => {
  let canRemove = true
  group.pages.forEach(page => {
    if (associatedParentsMap.get(page.pageId)) {
      let count = 0
      selectedGroups.forEach(group => {
        group.pages.forEach(p => {
          if (p.pageId === page.pageId) count++
        })
      })
      if (count < 2) canRemove = false
    }
  })
  return !canRemove
}

const onRemove = (
  group: PayloadGroup,
  setSelectedGroups: React.Dispatch<React.SetStateAction<PayloadGroup[]>>,
  editorType?: EDITOR_TYPE,
  customDeck?: CustomDeckORM,
  setNumOfRequiredSlides?: React.Dispatch<React.SetStateAction<number>>,
) => () => {
  group.pages.forEach((page) => {
    analytics?.track('CUSTOM_SLIDE_REMOVED', {
      action: 'SLIDE_REMOVED',
      category: 'CUSTOM',
      customDeckId: customDeck?.model.id,
      groupId: group.id,
      pageId: page.pageId,
      editorType: editorType,
    });
  })
  if (setNumOfRequiredSlides) setNumOfRequiredSlides(0)
  setSelectedGroups((groups) => groups.filter((grp) => grp.id !== group.id))
}

export const GroupItemContextMenu: React.FC<{ group: ModifiedPayloadGroup }> = (props) => {
  const { group } = props
  const {
    selectedGroups,
    setSelectedGroups,
    customDeck,
    editorType,
    setNumOfRequiredSlides,
    associatedParentsMap,
  } = usePresentationBuilderState()

  const onHide = () => {
    setSelectedGroups(
      groups => {
        const rv = groups.reduce<PayloadGroup[]>(
          (acc, grp) => {
            if (grp.id === group.id) {
              const action = grp.visible ? 'HIDE' : 'UNHIDE';

              grp.pages.forEach((page) => {
                analytics?.track(`CUSTOM_SLIDE_${action}`, {
                  action: `SLIDE_${action}`,
                  category: 'CUSTOM',
                  customDeckId: customDeck?.model.id,
                  groupId: group.id,
                  pageId: page.pageId,
                  editorType: editorType,
                });
              });

              return [...acc, { ...grp, visible: !grp.visible }]
            }

            return [...acc, grp]
          },
          []
        )

        return rv
      }
    )
  }

  const canRemove = canRemoveGroup(group, selectedGroups)
  const hasAssociatedSlide = isSingleAndHasAssociation(group, selectedGroups, associatedParentsMap)

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        <DNAButton
          iconLeft="dots-vertical"
          size="sm"
          padding="sm"
          style={S.opaqueBackground}
        />
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        <DNAContextMenu.Item
          title={ group.visible ? 'Hide' : 'Unhide'}
          icon={ group.visible ? 'eye-off-outline' : 'eye-outline'}
          onPress={onHide}
        />
        {
          canRemove && !hasAssociatedSlide &&
            <DNAContextMenu.Item
              title="Remove"
              icon="trash-can-outline"
              onPress={onRemove(group, setSelectedGroups, editorType, customDeck, setNumOfRequiredSlides)}
            />
        }
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

export const FindAndReplaceOverlay: React.FC<{ group: PayloadGroup }> = (props) => {
  const { group } = props
  const {
    setSelectedGroups,
    setActiveReplacementGroup,
    handleModeChange,
    isLocked,
    editorType,
  } = usePresentationBuilderState()

  const onFindAndReplace = () => {
    setActiveReplacementGroup(group)
    handleModeChange('replace')
  }

  return (
    <DNABox
      fill
      appearance="col"
      spacing="between"
      style={S.containerMargin}
    >
      <DNABox alignX="end">
        <Iffy is={!isLocked}>
          <DNAButton
            iconLeft="trash-can-outline"
            onPress={onRemove(group, setSelectedGroups, editorType)}
            size="sm"
            padding="sm"
            style={S.opaqueBackground}
          />
        </Iffy>
      </DNABox>
      <Iffy is={isLocked}>
        <DNABox fill alignX="center" alignY="end" style={{ marginBottom: 40 }}>
          <DNAText bold b1 status="basic">
            Source file has been recently updated
          </DNAText>
        </DNABox>
      </Iffy>
      <Iffy is={!isLocked}>
        <WarningButton
          icon="alert"
          label="Find & replace"
          onPress={onFindAndReplace}
        />
      </Iffy>
    </DNABox>
  )
}

export const StatusChangedOverlay: React.FC<{ group: PayloadGroup }> = (props) => {
  const { group } = props
  const { setSelectedGroups, isLocked, editorType } = usePresentationBuilderState()

  return (
    <DNABox
      fill
      appearance="col"
      alignY="end"
      spacing="md"
      style={S.containerMargin}
    >
      <DNABox fill alignX="center" alignY="end">
        <DNAText bold b1 status="basic">
          {group.groupStatus === GroupStatus.DELETED
            ? 'Source file is unavailable'
            : `Source file was ${group.groupStatus.toLowerCase()}`
          }
        </DNAText>
      </DNABox>
      <Iffy is={!isLocked}>
        <WarningButton
          label="Remove"
          icon="trash-can-outline"
          onPress={onRemove(group, setSelectedGroups, editorType)}
        />
      </Iffy>
    </DNABox>
  )
}

interface GroupItemOverlayProps {
  style?: ViewStyle,
  group: ModifiedPayloadGroup,
  enabled?: boolean,
  isLocked?: boolean,
  onCheck?: () => void,
  isChecked?: boolean,
}

export const GroupItemOverlay: React.FC<PropsWithChildren<GroupItemOverlayProps>> = (
  { group, style, enabled, children, isLocked, onCheck, isChecked },
) => {
  const isNotPublished = !!notPublishedStatus[group.groupStatus]
  const isFindAndReplace = group.groupStatus === GroupStatus.MAJOR_UPDATE
  const showItemOverlay = isFindAndReplace || isNotPublished
  const isSingleSlide = group.pages.length === 1

  const finalStyle = StyleSheet.flatten([
    { padding: 4 },
    style,
  ])

  if (!enabled)
  { return children as React.ReactElement }

  const isOpaqueScenario = (!group.visible || isNotPublished || isFindAndReplace)

  return (
    <DNABox fill style={finalStyle}>
      <Stack>
        {/* Children - Base Layer */}
        <Stack.Layer>
          { children }
        </Stack.Layer>

        {/* Opaque background for multiple scenarios */}
        {
          isOpaqueScenario &&
            <Stack.Layer fill style={{ pointerEvents: 'none', margin: 6 }}>
              <DNABox
                fill
                alignX="center"
                alignY="center"
                style={S.opaqueBackground}
              />
            </Stack.Layer>
        }

        {/* Hidden Group Overlay */}
        {
          !group.visible &&
            <Stack.Layer fill style={{ pointerEvents: 'none' }}>
              <DNABox
                fill
                alignX="center"
                alignY="center"
              >
                <DNAIcon.Styled
                  name="eye-off-outline"
                  size="xl"
                />
              </DNABox>
            </Stack.Layer>
        }

        {/* Status Changed Overlay */}
        {
          isNotPublished &&
            <Stack.Layer fill>
              <StatusChangedOverlay group={group} />
            </Stack.Layer>
        }

        {/* Find & Replace Overlay */}
        {
          isFindAndReplace &&
            <Stack.Layer fill>
              <FindAndReplaceOverlay group={group}/>
            </Stack.Layer>
        }

        {/* Context Menu */}
        {
          !showItemOverlay && !isLocked && isSingleSlide &&
            <Stack.Layer anchor="topRight">
              <DNABox style={S.containerMargin}>
                <GroupItemContextMenu group={group}/>
              </DNABox>
            </Stack.Layer>
        }
        {
          !group.visible && !showItemOverlay && !isLocked && !isSingleSlide &&
            <Stack.Layer anchor="topRight">
              <DNABox style={{ margin: 18 }}>
                <GroupHeaderActionItems
                  group={group}
                  isChecked={isChecked}
                  onCheck={onCheck}
                />
              </DNABox>
            </Stack.Layer>
        }
      </Stack>
    </DNABox>
  )
}
