import { Hub } from '@alucio/aws-beacon-amplify/src/models';
import { DNAButton, DNAContextMenu } from '@alucio/lux-ui';
import React from 'react';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider';

interface HubsContextMenuProps {
  hub: Hub,
}

const HubsContextMenu: React.FC<HubsContextMenuProps> = (props) => {
  const { handleEditHub, handlePressShare, handleSaveNPreview, handleDeletHub } = useHubsState()
  const { hub } = props
  const { id } = hub

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        <DNAButton
          iconRight="dots-vertical"
          appearance="outline"
          status="tertiary"
          padding="sm"
        />
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        <DNAContextMenu.Item
          icon="pencil"
          title="Edit"
          onPress={() => handleEditHub(id)}
        />
        <DNAContextMenu.Item
          icon="eye-outline"
          title="Preview"
          onPress={() => handleSaveNPreview(id)}
        />
        <DNAContextMenu.Item
          icon="email-send-outline"
          title="Share"
          onPress={() => handlePressShare(id)}
        />
        <DNAContextMenu.Item
          icon="trash-can-outline"
          title="Delete"
          iconStatus="danger"
          status="danger"
          onPress={() => handleDeletHub(hub)}
        />
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

export default HubsContextMenu
