import React from 'react'
import { DNABox, DNAButton, DNADivider, DNAModal, DNAText } from '@alucio/lux-ui'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { DocumentVersionORM } from 'src/types/orms'
import { DNAModalVariant } from './DNAConnectedModal'

interface Props {
  documentVersionORM: DocumentVersionORM
}

const ReleaseNotesModal: DNAModalVariant<Props> = (props) => {
  const { closeModal, documentVersionORM } = props;
  return (
    <DNAModal style={{ width: 560 }}>
      {/* DNABox is used instead of DNAModal.Header because of long title issues */}
      <DNABox style={{ paddingHorizontal: 24, paddingVertical: 16 }}>
        <DNAText numberOfLines={1} bold style={{ color: colors['color-gray-800'] }}>
          {documentVersionORM.model.title}
        </DNAText>
      </DNABox>
      <DNADivider />
      <DNAModal.Body style={{ padding : 24 }}>
        <DNAText style={{ color: colors['color-gray-400'], lineHeight: 24 }}>
          {documentVersionORM.model.releaseNotes}
        </DNAText>
      </DNAModal.Body>
      <DNAModal.Confirmation style={{ paddingVertical: 12, paddingHorizontal: 24 }}>
        <DNAButton appearance="outline" status="info" onPress={closeModal}>
          Close
        </DNAButton>
      </DNAModal.Confirmation>
    </DNAModal>
  )
}

export default ReleaseNotesModal;
