import { DNABox, DNAButton, DNADivider, DNAText, Iffy, InformationMessage } from '@alucio/lux-ui';
import React from 'react';
import { useTenantForms } from 'src/state/redux/selector/tenant';
import { ComposableForm } from '../CustomFields/ComposableForm';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { ScrollView, StyleSheet } from 'react-native';
import { getMappedCustomValues } from 'src/state/redux/selector/common';
import { CustomFieldDefinition, CustomFieldUsage } from '@alucio/aws-beacon-amplify/src/models';
import { GLOBAL_TENANT_FORM_ERROR, TenantFormProvider, useTenantForm } from './TenantFormProvider';
import { ColumnFieldsWrapper } from '../CustomFields/ColumnCustomFieldsWrapper';
import { useAppSettings } from 'src/state/context/AppSettings';

const styles = StyleSheet.create({
  titleContainer: {
    height: 56,
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: 'white',
  },
  title: {
    color: colors['gray-600'],
  },
  contentContainer: {
    padding: 24,
  },
  footer: {
    backgroundColor: colors['color-text-white'],
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  informationMessageContainer: {
    paddingHorizontal: 24,
    paddingTop: 24,
  },
  informationMessage: {
    marginBottom: 0,
  },
  required: {
    color: colors['color-danger-500'],
    paddingLeft: 5,
    paddingRight: 5,
  },
})

interface WrappedFormProps {
  formId: string,
  toggleDrawer: () => void,
}

interface TenantFormProps {
  title: string,
  customFields: CustomFieldDefinition[],
}

interface TenantFormHeaderProps {
  title: string,
  onCancel: () => void,
}

interface TenantFormFooterProps {
  onCancel: () => void,
}

const TenantFormHeader = (props: TenantFormHeaderProps) => {
  const { onCancel, title } = props;
  return (
    <DNABox appearance="col">
      <DNABox alignY="center" appearance="row" spacing="between" style={styles.titleContainer}>
        <DNAText bold h5 style={styles.title}>{title}</DNAText>
        <DNABox>
          <DNAButton
            appearance="ghost"
            status="tertiary"
            size="md"
            padding="sm"
            iconLeft="close"
            onPress={onCancel}
          />
        </DNABox>
      </DNABox>
      <DNADivider />
    </DNABox>
  )
}

const TenantFormFooter = (props: TenantFormFooterProps) => {
  const { onCancel } = props;
  const { onSave, isSubmitting } = useTenantForm()
  const { isOnline } = useAppSettings();

  return (
    <DNABox fill appearance="col" alignY="end">
      <DNADivider />
      <DNABox spacing="between" alignY="center" style={styles.footer} >
        <DNABox />
        <DNABox spacing="sm">

          {/* Cancel Button */}
          <DNAButton
            appearance="filled"
            status="tertiary"
            onPress={onCancel}
          >
            Cancel
          </DNAButton>
          <DNAButton
            style={{ maxHeight: 43 }}
            appearance="filled"
            onPress={onSave}
            disabled={isSubmitting || !isOnline}
          >
            Submit
          </DNAButton>
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

const TenantForm: React.FC<TenantFormProps> = (props) => {
  const { customFields, title } = props;
  const { toggleDrawer, error } = useTenantForm()

  return (
    <DNABox appearance="col" fill style={{ backgroundColor: colors['color-gray-50'] }}>

      <TenantFormHeader title={title} onCancel={() => toggleDrawer()} />
      <Iffy is={error}>

        <DNABox appearance="col" spacing="lg" style={styles.informationMessageContainer}>
          <InformationMessage
            text={error}
            variance={error === GLOBAL_TENANT_FORM_ERROR.validation_error ? 'danger' : 'warning'}
            style={styles.informationMessage}
          />
        </DNABox>
      </Iffy>
      <ScrollView contentContainerStyle={{ paddingBottom: 80 }}>
        <DNABox appearance="col" style={styles.contentContainer}>
          <DNABox appearance="col" spacing="sm" fill style={{ backgroundColor: 'white', padding: 24 }}>
            <ColumnFieldsWrapper customFields={customFields} />
          </DNABox>
        </DNABox>
      </ScrollView>
      <TenantFormFooter onCancel={() => toggleDrawer()} />
    </DNABox>

  );
};

const WrappedForm = (props: WrappedFormProps) => {
  const { formId, toggleDrawer } = props;
  const tenantForms = useTenantForms();
  const form = tenantForms.find((form) => form.id === formId);
  const customFields = form?.fields || [];
  const usage = { internalUsages: [CustomFieldUsage.TENANT_FORM] }
  const defaultValues = getMappedCustomValues(usage, [], customFields)
  const { isOnline } = useAppSettings();
  return (
    <ComposableForm disabled={!isOnline} customFields={customFields} values={defaultValues}>
      <TenantFormProvider formId={formId} toggleDrawer={toggleDrawer}>
        <TenantForm title={form?.label || ''} customFields={customFields} />
      </TenantFormProvider>
    </ComposableForm>
  )
}

export default WrappedForm;
