import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { FlatList, ScrollView, StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNASelect, DNAText, Iffy, IndexPath, useMouseEvent } from '@alucio/lux-ui';
import { useAppSettings } from 'src/state/context/AppSettings';
import {
  isPageGroupORM,
  Presentable,
  PresentablePage,
  ORMTypes,
  isFolderItemORM,
  DocumentVersionORM,
} from 'src/types/types';
import DNASlideRollThumbnail from './DNASlideRollThumbnail';
import { SLIDE_ROLL_VARIANT } from './StyleSetting';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { FileType, PresentedMeta } from '@alucio/aws-beacon-amplify/src/models';
import { PresentableModelORM } from 'src/state/context/ContentProvider/ContentProvider';
import { useIsSlideRollVisible } from './useIsSlideRollVisible';
import useContentPageData, { getSlideContentPageDataTitle } from 'src/hooks/useContentPageData/useContentPageData'

const styles = StyleSheet.create({
  closeButtonContainer: {
    marginTop: 12,
    marginBottom: 12,
    borderRadius: 4,
    backgroundColor: colors['color-gray-600'],
  },
});

interface SlideRollProps {
  activeSlide: PresentablePage,
  itemHeight?: number,
  itemWidth?: number,
  horizontal?: boolean,
  isContentUnavailable?: boolean,
  scrollToSelected?: boolean,
  isGridView?: boolean,
  presentable: Presentable,
  onSelect?: (presentationNumber: number) => void,
  variant?: SLIDE_ROLL_VARIANT,
  closeButtonOnPress?: () => void,
  presentedMeta?: PresentedMeta[],
  addPresentation?: (item: PresentableModelORM, pageNumber?: number) => void,
}

const DNASlideRoll: React.FC<SlideRollProps> = ({
  activeSlide,
  isContentUnavailable,
  scrollToSelected = true,
  isGridView,
  itemHeight,
  itemWidth,
  onSelect,
  presentedMeta = [],
  presentable,
  horizontal = true,
  variant = SLIDE_ROLL_VARIANT.DEFAULT,
  closeButtonOnPress,
  addPresentation,
}) => {
  const { deviceMode } = useAppSettings();
  const isTablet = deviceMode === 'tablet';
  const flatListRef = useRef<FlatList<PresentablePage> | null>(null);
  const hasPageGroup = !!activeSlide.documentVersionORM.relations.pageGroups.length
  const isCustomDeck = isFolderItemORM(presentable.orm) && presentable.orm.model.type === ORMTypes.CUSTOM_DECK
  const groups = [...activeSlide.documentVersionORM.relations.pageGroups]
    .sort((a, b) => a.model.name.localeCompare(b.model.name))
  const selectedIndex: IndexPath = useMemo(() => {
    if (isPageGroupORM(presentable.orm)) {
      const group = presentable.orm.model
      const index = groups.findIndex(g => g.model.id === group?.id)
      return new IndexPath(index + 1)
    }
    return new IndexPath(0)
  }, [presentable.orm])
  const { isIntersecting, setObserverRef } = useIsSlideRollVisible();
  const { contentPageData } = useContentPageData(presentable)
  const onGroupSelect = e => {
    if (groups) {
      const { row } = e
      if (row === 0) {
        if (addPresentation) {
          addPresentation(activeSlide.documentVersionORM)
        }
      } else {
        if (addPresentation) {
          const group = groups[row - 1]
          addPresentation(group)
        }
      }
    }
  }

  const selectedValue = () => {
    if (selectedIndex.row === 0) {
      return 'All slides'
    } else if (groups && groups.length && isPageGroupORM(presentable.orm)) {
      return groups[selectedIndex.row - 1].model.name
    }
  }
  const groupOptions = groups?.map(group => {
    return (
      <DNASelect.Item
        key={group.model.id}
        title={group.model.name}
      />
    )
  })

  const scrollToItem = (): void => {
    if (scrollToSelected) {
      flatListRef?.current?.scrollToIndex({
        index: activeSlide.presentationPageNumber - 1,
        animated: true,
      });
    }
  }
  useEffect(scrollToItem, [activeSlide, presentable.presentablePages]);

  useEffect(() => {
    isIntersecting && setTimeout(scrollToItem, 500);
  }, [isIntersecting, activeSlide, presentable.presentablePages])

  const handleMouseEvent = useCallback(
    (e: MouseEvent | Event | TouchEvent) => {
      if (e instanceof WheelEvent) {
        const scrollEl = flatListRef.current?.getScrollableNode() as HTMLElement
        if (scrollEl) { scrollEl.scrollLeft += e.deltaY }
      }
    },
    [],
  )
  useMouseEvent(handleMouseEvent);

  const renderThumbnail = ({ item: page }: { item: PresentablePage }) => {
    const title = contentPageData && contentPageData.length > 0
      ? getSlideContentPageDataTitle(page.presentationPageNumber - 1, contentPageData) : ''
    const isSelected = activeSlide.presentationPageNumber === page.presentationPageNumber;
    const presentedSlide = presentedMeta?.find(({ pageId }) => pageId === page.page.pageId);
    function onSelectPage(): void {
      onSelect?.(page.presentationPageNumber);
    }
    const tintOverlay = presentedMeta && presentedMeta.length > 0
    const visibleTypeBadges:DocumentVersionORM['model']['type'][] = [FileType.WEB, FileType.HTML, FileType.MP4]
    const isVisivleTypeBadge =
      visibleTypeBadges.includes(page.documentVersionORM.model.type) &&
      isCustomDeck

    return (
      <DNASlideRollThumbnail
        isContentUnavailable={isContentUnavailable}
        page={page}
        variant={variant}
        presentedMeta={presentedSlide}
        isSelected={isSelected}
        height={itemHeight}
        width={itemWidth}
        onSelect={onSelectPage}
        tintOverlay={tintOverlay}
        title={title}
        fileTypeVisible={isVisivleTypeBadge}
      />
    );
  };

  const firstOption = <DNASelect.Item key="allSlides" title="All slides" />

  groupOptions?.unshift(firstOption)

  if (isGridView) {
    return (
      <ScrollView>
        <DNABox wrap="start" spacing="md">
          {presentable.presentablePages.map((page) => renderThumbnail({
            item: page,
          }))}
        </DNABox>
      </ScrollView>
    );
  }
  const isInpreviewModal =
    [SLIDE_ROLL_VARIANT.PREVIEW_MODAL, SLIDE_ROLL_VARIANT.MEETING_PRESENTED_EMPTY].includes(variant)
  return (
    <DNABox fill={isTablet} appearance="col" >
      <DNABox alignX="end">
        <DNABox
          testID="slide-roll-exit-button"
          appearance="row"
          alignX="center"
          alignY="center"
          style={[styles.closeButtonContainer, isInpreviewModal && { backgroundColor: colors['color-text-white'] }]}
        >
          <DNAButton
            status={isInpreviewModal ? 'tertiary' : 'gray'}
            size="lg"
            appearance={isInpreviewModal ? 'outline' : 'ghostLink'}
            padding={isInpreviewModal ? 'xs' : 'none'}
            iconLeft="close"
            onPress={closeButtonOnPress}
          />
        </DNABox>
      </DNABox>
      <Iffy is={variant === SLIDE_ROLL_VARIANT.MEETING_PRESENTED_EMPTY}>
        <DNABox style={{ width: 248 }}>
          <DNAText status="flatAlt">No presented slides</DNAText>
        </DNABox>
      </Iffy>
      <Iffy is={!isCustomDeck && hasPageGroup && variant === SLIDE_ROLL_VARIANT.MEETINGS}>
        <DNABox alignY="center" childFill>
          <DNASelect
            style={{ marginBottom: 10, width: 340 }}
            selectedIndex={selectedIndex}
            value={selectedValue()}
            onSelect={onGroupSelect}
          >
            {groupOptions}
          </DNASelect>
        </DNABox>
      </Iffy>
      <Iffy is={variant !== SLIDE_ROLL_VARIANT.MEETING_PRESENTED_EMPTY}>
        <FlatList
          scrollEnabled={true}
          scrollEventThrottle={200}
          keyExtractor={(item) => `${item.id}-${item.presentationPageNumber}`}
          ref={(e) => {
            flatListRef.current = e;
            setObserverRef(e);
          }}
          ItemSeparatorComponent={() => <DNABox style={{ marginVertical: 6 }} />}
          horizontal={horizontal}
          data={presentable.presentablePages}
          renderItem={renderThumbnail}
          onScrollToIndexFailed={info => {
            setTimeout(() => {
              isIntersecting && flatListRef.current?.scrollToIndex({ index: info.index, animated: true });
            }, 500);
          }}
        />
      </Iffy>
    </DNABox>
  );
}

export default DNASlideRoll;
