import { DocumentStatus, PageGroup, PageGroupSource } from '@alucio/aws-beacon-amplify/src/models'
import { DNABox, DNAChip, DNACollapsibleCard, DNAText, Iffy, InformationMessage } from '@alucio/lux-ui'
import React, { useMemo } from 'react'
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail'
import { useVersioningPanel } from '../../VersioningPanelContext'
import { getThumbURL, useSlideSettings } from '../SlideSettings'
import { DNACollapsibleActionMenu } from '@alucio/lux-ui/src/components/layout/DNACollapsibleCard/DNACollapsibleCard'
import cloneDeep from 'lodash/cloneDeep'
import { RightGroupMenu } from './RightMenu'
import { DraftPageGroup } from './GroupsProviders'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import { useDispatch } from 'src/state/redux'
import DeleteGroupModal from './RemoveGroupModal'

export const GroupHeader : React.FC<{group: PageGroup | DraftPageGroup}> = ({ group }) => {
  const importedText = group.source === PageGroupSource.DOCUMENT ? 'Imported' : ''
  return (
    <DNABox>
      <DNAText>{group.name}</DNAText>
      <Iffy is={importedText}>
        <DNAChip style={{ marginLeft:8 }}>{`${importedText}`}</DNAChip>
      </Iffy>
    </DNABox>
  )
}

export const RemoveImportedGroups : React.FC<{
  groups: PageGroup[],
}> = ({ groups }) => {
  const hasImportedGroups = groups.some(group => group.source === PageGroupSource.DOCUMENT)

  const { send } = useSlideSettings()

  if (!hasImportedGroups) {
    return null
  }

  const removeImportedGroups = () => {
    send({
      type: 'REMOVE_IMPORTED_GROUPS',
    })
  }

  return (
    <DNABox>
      <InformationMessage
        text="Groups were automatically imported"
        actionButtonFunction={removeImportedGroups}
        actionButtonText="Delete all imported groups"
        variance="warning"
      />
    </DNABox>
  )
}

const ReadOnlyGroups: React.FC = () => {
  const { currentDocumentVersionORM } = useVersioningPanel()

  const {
    state,
    thumbnailSizes,
    send,
    getSlideTitle,
  } = useSlideSettings()

  const dispatch = useDispatch();

  const isDocSealed = ['REVOKED', 'ARCHIVED', 'DELETED'].includes(
    currentDocumentVersionORM
      .relations
      .documentORM
      .model
      .status,
  )

  const isVersionPublished = currentDocumentVersionORM
    .model
    .status === DocumentStatus.PUBLISHED

  const isVersionPublishedOrSealed = (isVersionPublished || isDocSealed)

  const groups = useMemo(() => cloneDeep(isVersionPublishedOrSealed
    ? currentDocumentVersionORM.model.pageGroups
    : state.context.versionDraft.pageGroups)
    ?.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true })) || []
  , [isVersionPublishedOrSealed, currentDocumentVersionORM, state.context.versionDraft.pageGroups])

  const pages = useMemo(() => isVersionPublishedOrSealed
    ? currentDocumentVersionORM.meta.allPages
    : state.context.versionDraft.pages
  , [isVersionPublishedOrSealed, currentDocumentVersionORM])

  const removeGroup = (groupName: string) => {
    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: (props) => (
        <DeleteGroupModal
          groupName={groupName}
          onSuccess={() => send({ type: 'REMOVE_IMPORTED_GROUPS', payload: { groupName: groupName } })}
          {...props}
        />
      ),
    }))
  }

  if (!groups) {
    return (null)
  }

  return (
    <DNABox appearance="col">
      <Iffy is={currentDocumentVersionORM.model.status === DocumentStatus.NOT_PUBLISHED}>
        <RemoveImportedGroups groups={groups} />
      </Iffy>
      {
        groups.map((group) => {
          return (
            <DNABox
              key={group.id}
              appearance="col"
              style={{ marginVertical: 8 }}
            >
              <DNACollapsibleCard
                isCollapsed
                variant={'publisherReadonly'}
                headerTitle={<GroupHeader group={group} />}
              >
                <DNACollapsibleActionMenu>
                  <RightGroupMenu
                    pages={group.pageIds?.length || 0}
                    locked={group.locked}
                    onDelete={
                      currentDocumentVersionORM.model.status === DocumentStatus.NOT_PUBLISHED
                        ? () => { removeGroup(group.name) }
                        : undefined
                    }
                  />
                </DNACollapsibleActionMenu>
                <DNABox
                  fill
                  appearance="row"
                  wrap="start"
                  spacing="lg"
                  childStyle={{ marginBottom: 32 }}
                >
                  {group.pageIds?.map((pageId, idx) => {
                    const page = pages.find((page) => page.pageId === pageId)
                    if (!page || !group || !group.pageIds) {
                      return null
                    }
                    const thumbURL = getThumbURL(currentDocumentVersionORM, page.number)

                    /**
                     * this is more of a hack for some reason all the elements have a margin of
                     * 32 on the right all except the last one, with this hack that margin is being injected
                     */
                    const styleLatest = idx === group.pageIds.length - 1 ? { marginRight: 32 } : {}
                    return (
                      <DNABox key={`${group.id}-${pageId}-${idx}`} appearance="col" style={styleLatest}>
                        <DNAThumbnail
                          key={thumbURL}
                          s3URL={thumbURL}
                          useLoadingIndicator
                          size={thumbnailSizes.thumbnailSize}
                          mode={DNAThumbnail.Modes.READ_ONLY}
                          variant={DNAThumbnail.Variants.INFO}
                          pageNumber={page.number}
                          thumbnailTitle={getSlideTitle(page.number)}
                        />
                      </DNABox>
                    )
                  })
                }
                </DNABox>
              </DNACollapsibleCard>
            </DNABox>
          )
        },
        )
      }
    </DNABox>
  )
}

export default ReadOnlyGroups
