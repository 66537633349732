import { useState } from 'react'

const useMeetingsComponentVisibility = (controlsInitialVisibility?: boolean) => {
  const [presentationControlsVisible, setPresentationControlsVisible] = useState<boolean>(!!controlsInitialVisibility)
  const [slideRollVisible, setSlideRollVisible] = useState<boolean>(false)
  const [textSearchVisible, setTextSearchVisible] = useState<boolean>(false)

  const togglePresentationControlsVisibility = () => {
    setPresentationControlsVisible(p => !p)
  }

  const toggleSlideRollVisibility = () => {
    setTextSearchVisible(false)
    setSlideRollVisible(p => !p)
  }

  const toggleTextSearchVisibility = () => {
    setSlideRollVisible(false)
    setTextSearchVisible(p => !p)
  }

  return {
    presentationControlsVisible,
    setPresentationControlsVisible,
    togglePresentationControlsVisibility,
    slideRollVisible,
    setSlideRollVisible,
    toggleSlideRollVisibility,
    textSearchVisible,
    setTextSearchVisible,
    toggleTextSearchVisibility,
  }
}

export default useMeetingsComponentVisibility
