import React, { useState, useEffect } from 'react'
import { Image, Pressable } from 'react-native'
import { WebLinking as Linking } from '@alucio/core'
import { DNABox } from '@alucio/lux-ui'

import useScreenNav from 'src/components/DNA/hooks/useScreenNav';
import { useUserTenant } from 'src/state/redux/selector/user';
import { getImageFromCloudfront } from 'src/utils/loadCloudfrontAsset/loadCloudfrontAsset';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';

type DNATenantLogoProps = {
  size?: 'small' | 'medium' | 'large'
  // will override the logo from the tenant
  imageUrl?: string
  disableNav?: boolean
}

const sizeMap: { [K in Exclude<DNATenantLogoProps['size'], undefined>]: number } = {
  small: 100,
  medium: 200,
  large: 300,
}

type useLogoProps = {
  overrideLogo?: string
}

const useLogo = (props: useLogoProps): {logoImageData: string, readOnly: Boolean} => {
  const tenant = useUserTenant();
  const [logoImageData, setLogoImageData] = useState('');

  useEffect(() => {
    if (props.overrideLogo) {
      setLogoImageData(props.overrideLogo);
    } else {
      if (tenant) {
      // The logo is always located in the tenant's content folder under /logo.png
        const logoUrl = `${tenant.id}/logo.png`;
        getImageFromCloudfront(logoUrl).then(image => {
          setLogoImageData(image)
        }, () => {
        // Logo file doesn't exist just show alucio logo instead
          const alucioLogo = require('../../../../assets/images/alucio_logo.png');
          setLogoImageData(alucioLogo);
        });
      } else {
      // IF THERE IS NO TENANT, THE USER IS AN ADMIN
        const alucioLogo = require('../../../../assets/images/alucio_logo.png');
        setLogoImageData(alucioLogo);
      }
    }
  }, [props.overrideLogo]);

  return { logoImageData, readOnly: !!props.overrideLogo }
}

const DNATenantLogo: React.FC<DNATenantLogoProps> = (props) => {
  const { size = 'small', disableNav } = props
  const { deviceMode } = useAppSettings()
  const { logoImageData, readOnly } = useLogo({ overrideLogo: props.imageUrl })
  const { goTo } = useScreenNav()

  const navProps = (readOnly || disableNav)
    ? {}
    : deviceMode === DeviceMode.desktop
      ? { as: Pressable, activeOpacity: 1, onPress: () => goTo.HOME() }
      : { as: Pressable, activeOpacity: 1, onLongPress: () => Linking.openURL(`/?refresh=${Date.now()}`, '_self') }

  return (
    <DNABox
      testID="tenantLogo"
      style={{ width: sizeMap[size] }}
      {...navProps}
    >
      <div style={{ userSelect: 'none', height: '100%', width: '100%', pointerEvents: 'none' }}>
        <Image
          style={{ height: '100%', width: '100%' }}
          resizeMode="contain"
          source={{ uri: logoImageData }}
        />
      </div>
    </DNABox>
  )
}

export default DNATenantLogo
