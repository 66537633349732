import {
  AlucioAuthHeaders,
  CacheManifestEntry,
  CachePayload,
} from '@alucio/core'
import { unzipSync, Unzipped } from 'fflate'
import CacheDB from 'src/worker/db/cacheDB'
import PWALogger from 'src/worker/util/logger'
class CacheHandler {
  public static contentTypesByExtension = {
    'css': 'text/css',
    'js': 'application/javascript',
    'png': 'image/png',
    'jpg': 'image/jpeg',
    'jpeg': 'image/jpeg',
    'html': 'text/html',
    'htm': 'text/html',
    'woff': 'font/woff',
    'mp4': 'video/mp4',
  };

  public static async downloadFile(
    cacheManifestEntry: CacheManifestEntry,
    authHeaders: AlucioAuthHeaders,
    abortController: AbortController,
  ): Promise<ArrayBuffer> {
    const signal = abortController.signal
    const fileURL = `/content/${cacheManifestEntry.fileKey}`

    let abort = false

    const response = await fetch(fileURL, { signal, headers: authHeaders })
      .catch(err => {
        // [TODO-PWA] - Check that other browsers have the same message
        if (err.message !== 'FETCH ERROR DOMException: The user aborted a request.') { throw err }

        abort = true
      })

    // [TODO-PWA]
    //  - We could always let the machine determine the error
    //    and do the appropriate action (not bubble the error up)
    if (abort) { return new ArrayBuffer(0) }

    if (!response || response.status !== 200) {
      throw new Error('No response from download')
    }

    const buffer = await response.arrayBuffer()
    return buffer
  }

  public static async extractArchive(blob: ArrayBuffer): Promise<Unzipped> {
    const unzipped = unzipSync(new Uint8Array(blob))
    return unzipped
  }

  public static async storeCachePayload(
    contents: Unzipped | ArrayBuffer,
    cacheManifestEntry: CacheManifestEntry,
  ): Promise<void> {
    const cacheDB = new CacheDB()
    await cacheDB.open()

    if (cacheManifestEntry.cacheType === 'THUMBNAIL') {
      const thumbnailContents = contents as ArrayBuffer
      const filePayload: CachePayload = {
        id: cacheManifestEntry.id,
        path: `/content/${cacheManifestEntry.fileKey}`,
        data: thumbnailContents as ArrayBuffer,
        cacheType: 'THUMBNAIL',
        size: thumbnailContents.byteLength,
        mimeType: this.contentTypesByExtension.png,
      }

      await cacheDB.putCachePayload(filePayload)
    }

    else if (cacheManifestEntry.cacheType === 'CONTENT') {
      //  [TODO-PWA]
      //    - cacheManifestEntry now has document, documentVersionId and version number
      //      We can probably replace this
      const docVersionId = cacheManifestEntry.fileKey.split('/')[2]
      const [documentId, versionNumber] = docVersionId.split('_')

      for (const filePath in contents) {
        const contentBlob = contents[filePath]

        // [TODO-PWA] - Skip directories for now
        if (Array.isArray(contentBlob)) { return; }

        const filePathParts = filePath.split('/')
        const fileName = filePathParts.slice(-1).toString()
        const fileExtension = fileName
          .split('.')
          .slice(-1)
          .toString()

        //  Files within the archive may need to be renamed due to "create from existing version"
        //    which just copies the archive without renaming contents (i.e. not bumping the version number)
        //    we re-adjust to the correct file name for the given version if that's the case
        const hasSameDocumentId = fileName.startsWith(documentId)
        const doesNotMatchVersion = !fileName.startsWith(`${documentId}_${versionNumber}`)

        if (hasSameDocumentId && doesNotMatchVersion) {
          PWALogger.verbose(`Found non matching file ${documentId}, ${versionNumber} ${fileName}`)

          const fileNameParts = fileName.split('_')
          // Need to update the version number only PDFs and thumbnails have version #
          if (fileNameParts[1].indexOf('.') > 0) {
            // PDF file with format of: {docid}_{version#}.pdf
            const parts = fileNameParts[1].split('.')
            parts[0] = versionNumber
            fileNameParts[1] = parts.join('.')
          } else {
            // Thumbnails with format of: {docid}_{version#}_{page#}_thumb.png
            fileNameParts[1] = versionNumber
          }
          filePathParts[filePathParts.length - 1] = fileNameParts.join('_')
          PWALogger.verbose(`Updated Name: ${filePathParts[filePathParts.length - 1]}`)
        }

        //  For PDFs, the location of the PDF file in the zip archive is different directory (/pages/ directory)
        //    which is different than what the app requests, so we simply remove the unneeded /page/ directory
        const basePath = `/content/${cacheManifestEntry.folderKey}${filePathParts.join('/')}`
        const path = fileExtension === 'pdf'
          ? basePath.replace('/pages', '')
          : basePath

        // [TODO-PWA] - TS Does not handle unknown file types
        const mimeType = this.contentTypesByExtension[fileExtension]
        const filePayload: CachePayload = {
          id: cacheManifestEntry.id,
          path: path,
          data: contentBlob,
          cacheType: 'CONTENT',
          size: contentBlob.byteLength,
          mimeType: mimeType,
        }

        await cacheDB.putCachePayload(filePayload)
      }
    }

    await cacheDB.close()
  }

  public static async updateCacheManifestEntry(
    id: string,
    updates: Partial<CacheManifestEntry>,
  ): Promise<void> {
    const cacheDB = new CacheDB()
    await cacheDB.open()
    await cacheDB.updateCacheManifestEntry(id, updates)
    await cacheDB.close()
  }
}

export default CacheHandler
