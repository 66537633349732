import { DocumentVersion, Page, PageGroup } from '@alucio/aws-beacon-amplify/src/models'
import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'src/state/redux'
import useThumbnailSize, { ThumbnailSize } from 'src/hooks/useThumbnailSize/useThumbnailSize'
import { slideSelectorModalActions } from 'src/state/redux/slice/SlideSelectorModal/SlideSelectorModal'
import { getImageObjectURLFromCloudfront } from 'src/utils/loadCloudfrontAsset/common'
import { SlideMode, ThumbnailPage } from './SlideSelector'
import { GroupDraft, s3Size } from './PageGroupList';
import { DocumentVersionORM } from 'src/types/orms'

type ThumbnailSelectorType = {
  mode?: SlideMode
  preSelectedThumbnails?: number[]
  disableRequiredToggle?: boolean
}

interface SelectorPageGroup extends PageGroup {
  checked?: boolean,
}

// [TODO-2126] - This should be calculated at the ORM level
//             - The name doesn't match what it's doing? Should be a thumbnail not archived file key?
export const detectArchivedFileKeyPath = (
  document: DocumentVersion | undefined,
  page: Pick<Page, 'number' | 'pageId'>,
  size: ThumbnailSize = 'xl',
) => {
  const thumbSize = s3Size[size]
  return `${document?.convertedFolderKey}thumbnails/${page.number}_${thumbSize}_thumb.png`
}

const getPlaceholderPages = (numberOfPages: number): ThumbnailPage[] => {
  return Array.from({ length: numberOfPages })
    .map((_, idx) => ({
      pageId: `placeholder_${idx + 1}`,
      number: idx + 1,
      srcHash: '',
      thumbnailObjectURL: '',
      checked: false,
      disabled: true,
      disableCheckbox: true,
      isPlaceholder: true,
      isCover: false,
    }))
}

const useThumbnailSelector = (
  props: ThumbnailSelectorType,
  disableImageFetch: boolean = false,
) => {
  const {
    mode,
    preSelectedThumbnails,
    disableRequiredToggle,
  } = props;
  const dispatch = useDispatch();
  const [pages, setPages] = useState<ThumbnailPage[]>([]);
  const [pageGroups, setPageGroups] = useState<SelectorPageGroup[]>([])
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { thumbnailSize, cycleThumbnailSize } = useThumbnailSize(['xxl', 'xl', 'lg', 'md'], 'lg')
  const willUnmount = useRef<boolean>(false)

  useEffect(
    () => () => { willUnmount.current = true },
    [],
  )

  useEffect(
    () => () => {
      if (willUnmount.current) {
        pages.forEach((page) => {
          if (page.thumbnailObjectURL) {
            URL.revokeObjectURL(page.thumbnailObjectURL)
          }
        })
      }
    },
    [pages],
  )

  /** Logic is: not an array of pages where an unchecked item is found */
  const allPagesSelected = !pages.find(p => !p.checked) && pages.length > 0;

  // Get a list of all currently selected page objects
  const selectedPageObjects = pages.filter(p => p.checked)

  /** BEGIN GROUPING VARS */

  // Get a list of IDs from all currently slected pages
  const selectedPagesIds = selectedPageObjects.map(page => page.pageId)

  const selectionCount = pages.filter(p => p.checked).length;
  const selectedPages = pages
    .filter(p => p.checked)
    .map(p => p.number);

  // Get a list of all current pagegroups which contain any of the selected slides
  const groupsWithSomeSlidesSelected = pageGroups.filter(pageGroup => {
    return pageGroup?.pageIds?.some(pID => selectedPagesIds.includes(pID))
  })

  /** END GROUPING VARS */

  const isFolder = (mode === undefined || mode === SlideMode.FolderItem);

  const handleSelectGroup = (groupDraft: GroupDraft, forceSelect?: boolean) => {
    const isGroupedPages = groupDraft.pages.length > 1
    // If selected a named group
    if (isGroupedPages) {
      setPageGroups(p => {
        const selectedGroup = p.map(group => group.id === groupDraft.id
          ? { ...group, checked: forceSelect ? true : !groupDraft.checked }
          : group,
        )
        return selectedGroup
      })

      // deselect all the single page groups if users selects a group
      if (forceSelect || !groupDraft.checked) {
        setPages(p => p.map(page => ({ ...page, checked: false })))
      }

      return
    }

    // If selected individual slides
    const groupDraftPageIDs = groupDraft.pages.map(page => page.pageId)
    const pagesInGroup = pages.filter(page => groupDraftPageIDs.includes(page.pageId))
    const pagesInGroupIDs = pagesInGroup.map(page => page.pageId)

    // if the slide is required the user cannot hide the slide
    const skipRequired = disableRequiredToggle && pagesInGroup.some(page => page.isRequired)
    if (skipRequired) return
    setPages(p => {
      const selectedPages = p.map(page => pagesInGroupIDs.includes(page.pageId)
        ? { ...page, checked: forceSelect ? true : !page.checked }
        : page,
      )

      return selectedPages
    })
  }

  const handleThumbSelect = (thumbPage: ThumbnailPage) => {
    // if the slide is required the user cannot hide the slide
    const skipRequired = disableRequiredToggle && thumbPage.isRequired
    if (skipRequired) return undefined;
    else {
      setPages(p => {
        // deep copy triggers rerender, is there a better way to do this or is this ok?
        const updatedPages = [...p]
        const page = updatedPages[thumbPage.number - 1]
        page.checked = !thumbPage.checked
        return updatedPages
      })
    }
  };

  const handleSelectAllToggle = (checked?: boolean) => {
    setPages(p => {
      const updatedPages = p.map(page => {
        /** Multi-use toggle allows for checked value to be passed in, if not passed in fall back to existing logic  */
        const updatedCheckedState = typeof checked === 'boolean'
          ? checked
          : (isFolder && page.isRequired
            ? true
            : !allPagesSelected)

        const skipRequired = disableRequiredToggle && page.isRequired
        const currentPage = {
          ...page,
          checked: skipRequired || updatedCheckedState,
        }

        return currentPage;
      })
      return updatedPages
    })
  };

  /**
   * THIS IS FOR NON-CONSECUTIVE
   * - all selected pages are ungrouped and no group is selected ( show grouping )
   * - all selected pages are ungrouped and at least one group is selected ( show grouping )
   * - all selected pages are grouped and only one group is selected ( show ungrouping )
   * - all selected pages are grouped and multiple groups are selected ( show grouping )
   * */

  // Get images from cloudfont, using the auth token
  const fetchImages = (
    activeItem: DocumentVersionORM,
    pageSettings: (
      page: Page,
      url?: string,
    ) => ThumbnailPage,
  ) => {
    return async function initFetch() {
      const images = await Promise.all(
        activeItem.meta.allPages.map(async (page: Page) => {
          const thumbnailObjectURL = !disableImageFetch
            ? await getImageObjectURLFromCloudfront(
              detectArchivedFileKeyPath(activeItem.model, page),
            )
            : undefined

          const fetchedPage = pageSettings(page, thumbnailObjectURL)
          // mark the page as checked if it's visible in the modified file OR if everything is visible (Should only happen in 'Select Slides')
          if (!mode) {
            fetchedPage.checked = preSelectedThumbnails?.includes(page.number) || preSelectedThumbnails === null;
          }
          return (fetchedPage)
        }) ?? [],
      )
      setPages(images)
    }
  }

  /**
     * Utility function to handle fetching thumbnail images for each docVer page which augments the
     * data model and adds a thumbnail object URL, a checked property, & a disabled property
     */
  const populateThumbnails = (activeItem: DocumentVersionORM, selectRequiredSlides: boolean) => {
    // set the ThumbnailPage properties when the image is get from cloud front
    const thumbnailPage = (page: Page, thumbnailObjectURL?: string) => {
      return {
        ...page,
        thumbnailObjectURL: thumbnailObjectURL,
        checked: selectRequiredSlides && !!page.isRequired,
        isRequired: page.isRequired,
        isCover: (activeItem.model.selectedThumbnail ?? 1) === page.number,
        disabled: !!disableRequiredToggle && !!page.isRequired,
      };
    }

    (fetchImages(activeItem, thumbnailPage))()
  }

  /**
    Get all individual slides and convert them into groups
  **/
  const getAllGroupedIndividualSlides = () => {
    const flatPages = [...new Set(pages.map(page => page.pageId))]
      .map(page => pages.find(pg => pg.pageId === page)!)
    const convertedPages: GroupDraft[] = flatPages
      .map(page => ({
        id: page.pageId,
        pages: [page],
      }));
    return convertedPages
  }

  /** Get all pagegroups for the document */
  const getAllGroups = (): GroupDraft[] => {
    const groupDrafts = pageGroups.map((group) => {
      return {
        ...group,
        pages: group.pageIds?.reduce((acc, id) => {
          const page = pages.find(page => page.pageId === id);
          if (page) {
            acc.push(page);
          }
          return acc;
        }, [] as ThumbnailPage[]) || [],
      }
    });
    return groupDrafts
  }

  /**
   * Combine ungrouped pages and existing pagegroups into array of page groups
   * (convert ungrouped to pg with single element)
   **/
  const getGroupDrafts = () => {
    const groupDrafts = getAllGroups()

    /** Convert ungrouped pages to array of pagegroups */
    // [TEMP] - Might have had a pages array duplicated due to conflict resolution here
    const convertedPages: GroupDraft[] = pages
      .map(page => ({
        id: page.pageId,
        pages: [page],
      }));

    return [...groupDrafts, ...convertedPages]
  }

  /** Event Handlers */
  const closeModal = () => {
    // THE IDEA OF THIS IS TO DO THE DISPATCH AFTER THE ANIMATION TO HIDE THE MODAL IS DONE
    /**
     * TODO: Hmmm, there's gotta be a more graceful way of dealing with this. Will allow for now but I've always had
     * issues with some edge case of people being on hardware that's slow enough to make things like this an issue
     * */
    setIsOpen(false);
    setTimeout(() => {
      dispatch(slideSelectorModalActions.setActiveDocumentVersion({}))
    }, 1000);
  };

  const initializeThumbnails = (activeDocumentVersionORM: DocumentVersionORM, selectRequiredSlides: boolean = true) => {
    // Show placeholder slides while the thumbnail images are being fetched
    setPages(getPlaceholderPages(activeDocumentVersionORM.meta.allPages.length))
    populateThumbnails(activeDocumentVersionORM, selectRequiredSlides)
    activeDocumentVersionORM.model.pageGroups && setPageGroups(activeDocumentVersionORM.model.pageGroups)
  }

  return {
    allPagesSelected,
    isOpen,
    pages,
    pageGroups,
    selectionCount,
    selectedPages,
    groupsWithSomeSlidesSelected,
    thumbnailSize,

    closeModal,
    getAllGroups,
    getAllGroupedIndividualSlides,
    getGroupDrafts,
    handleThumbSelect,
    handleSelectAllToggle,
    handleSelectGroup,
    initializeThumbnails,
    setPageGroups,
    cycleThumbnailSize,
  }
}

export default useThumbnailSelector
