import React, { forwardRef, PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native'
import {
  SortableContext,
  useSortable,
  SortingStrategy,
  verticalListSortingStrategy,
  AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
} from '@dnd-kit/sortable';

import { isIOS } from 'react-device-detect'

import { CSS } from '@dnd-kit/utilities';
import { useDroppable, UniqueIdentifier } from '@dnd-kit/core';

export type TargetItem<T extends {} = {}> = { id: string, itemId: string } & T
export type GroupedTargetItems<T extends {} = {}> = Record<string, TargetItem<T>[]>

type ItemProps = {
  style?: React.CSSProperties,
  children: React.ReactNode,
}

type InteractiveItemProps = {
  id: UniqueIdentifier,
  itemId: UniqueIdentifier,
  containerId: UniqueIdentifier,
  disabled?: boolean,
}

export const Item = React.memo(
  forwardRef<HTMLImageElement, ItemProps>((props, ref) => {
    const { style, children, ...restProps } = props

    // [TODO-928] - Try to support <DNABox> instead of <div>
    return (
      <div ref={ref} style={style} {...restProps}>
        { children }
      </div>
    );
  }),
);

export interface SortableItemProps extends InteractiveItemProps {
  style?: React.CSSProperties,
  visible?: boolean,
}

export const SortableItem: React.FC<PropsWithChildren<SortableItemProps>> = ({
  id,
  itemId,
  containerId,
  visible = true,
  disabled,
  style,
  children,
}) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    disabled,
    data: {
      containerId,
      itemId,
    },
  });

  const touchStartTimeout = React.useRef<number | null>(null)

  if (!visible) return null

  const sortableStyle:React.CSSProperties = {
    display: 'flex',
    boxSizing: 'border-box',
    transformOrigin: '0 0',
    // See https://docs.dndkit.com/api-documentation/sensors/touch#recommendations
    touchAction: 'manipulation',
    opacity: isDragging ? 0.3 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  }

  const onTouchStartIOS = (e: React.TouchEvent<HTMLDivElement>) => {
    touchStartTimeout.current = window.setTimeout(() => {
      listeners?.onTouchStart(e)
    }, 500)
  }

  const onTouchEndIOS = () => {
    if (touchStartTimeout.current) {
      window.clearTimeout(touchStartTimeout.current)
      touchStartTimeout.current = null
    }
  }

  const listenersByPlatform = isIOS
    ? {
      // on iOS, we need to delay the touch start event to avoid the drag and drop conflict with the selection
      onTouchStart: onTouchStartIOS,
      onTouchEnd: onTouchEndIOS,
    }
    : {
      ...listeners,
    }

  const finalStyle = StyleSheet.flatten([
    sortableStyle,
    style,
  ]) as React.CSSProperties

  return (
    <Item
      ref={disabled ? undefined : setNodeRef}
      style={finalStyle}
      {...( disabled ? {} : attributes)}
      {...( disabled ? {} : listenersByPlatform)}
    >
      { children }
    </Item>
  );
}

const animateLayoutChanges: AnimateLayoutChanges = (args) => defaultAnimateLayoutChanges({
  ...args,
  wasDragging: true,
})

interface DroppableContainerProps {
  id: string,
  items: TargetItem[]
  strategy?: SortingStrategy
  style?: React.CSSProperties,
  disabled?: boolean,
}
export const DroppableContainer: React.FC<PropsWithChildren<DroppableContainerProps>> = ({
  id,
  items,
  strategy = verticalListSortingStrategy,
  children,
  style,
  disabled,
}) => {
  const { setNodeRef } = useDroppable({
    id,
    disabled,
    data: {
      type: 'container',
      containerId: id,
      children: items,
      animateLayoutChanges,
    },
  });

  return (
    <div ref={disabled ? undefined : setNodeRef} style={style}>
      <SortableContext
        id={id.toString()}
        items={items}
        strategy={strategy}
        disabled={disabled}
      >
        { children }
      </SortableContext>
    </div>

  );
}
