import React from 'react'
import { DNAButton, DNAContextMenu } from '@alucio/lux-ui'
import {
  FolderContextMenuAction,
  folderContextMenuActions,
  useDNAFolderActions,
} from 'src/components/DNA/Folder/DNAFolder.actions'
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage'
import { useLocation } from 'react-router'
import { useDNAFolderNav } from 'src/components/DNA/Folder/Nav/DNAFolderNav'
import useFeatureFlag from 'src/hooks/useFeatureFlag/useFeatureFlag'
import { FeatureFlags } from 'src/types/featureFlags'

const FolderActions: React.FC = () => {
  const DNAFolderActions = useDNAFolderActions()
  const route = useCurrentPage({ exact: false })
  const { pathname } = useLocation();
  const folderNav = useDNAFolderNav()
  const folderRegex = /^\/folders\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b.*/gm;
  // eslint-disable-next-line max-len
  const publisherFolderRegex = /^\/publisher_folders\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b.*/gm;

  const lowerCasedPathName = pathname.toLowerCase();
  const isInsideFolder = !!lowerCasedPathName.match(folderRegex) || !!lowerCasedPathName.match(publisherFolderRegex);
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const sharedFoldersTab = !!route?.PATH.includes('shared_folders');
  const currentFolder = folderNav.currentFolders[folderNav.currentFolders.length - 1]
  const enableCollaboration = useFeatureFlag(FeatureFlags.BEAC_2712_custom_decks_shared_folders)
  const enableAllowAddFileToFolder = useFeatureFlag(FeatureFlags.BEAC_1954_allow_add_file_to_folder)
  const rootIsShared = enableCollaboration ? false : folderNav.folderStack.length &&
  folderNav.folderStack[0].model?.sharePermissions?.some((item) => !item.isDeleted)

  const actionsToUse: FolderContextMenuAction[] = []

  if (sharedFoldersTab) {
    return null;
  }

  const canAddFilesToFolder = enableAllowAddFileToFolder &&
    !rootIsShared && isInsideFolder
  const canCreateFolder = !folderNav.isMaxDepth && !folderNav.isCurrentlyNested
  const canCreateSubFolder = !folderNav.isMaxDepth && folderNav.isCurrentlyNested
  const canBuildPresentation = !isPublisherRoute && isInsideFolder

  canAddFilesToFolder && actionsToUse.push('openFilesDrawer')
  canCreateFolder && actionsToUse.push('createFolder')
  canCreateSubFolder && actionsToUse.push('createSubFolder')
  canBuildPresentation && actionsToUse.push('createCustomPresentation')

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        <DNAButton
          testID="plus-new-button"
          appearance="outline"
          status="tertiary"
          size="md"
          padding="sm"
          iconLeft="plus"
        >
          New
        </DNAButton>
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        {
          actionsToUse.map(action => {
            const { onPress, ...rest } = folderContextMenuActions[action]
            return (
              <DNAContextMenu.Item
                key={action}
                status="dark"
                onPress={DNAFolderActions[action](currentFolder)}
                {...rest}
              />
            )
          })
        }
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

export default FolderActions;
