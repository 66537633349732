import React, { useEffect, useMemo, useState, PropsWithChildren } from 'react';
import { CognitoUser } from '@alucio/beacon/src/models/User';
import { userActions } from '../../state/redux/slice/user';
import store, { storeReset } from '../../state/redux/store';
import ActiveUser from 'src/state/global/ActiveUser'
// AMPLIFY
import awsConfig from '@alucio/aws-beacon-amplify/src/aws-exports';
import { DataStore } from '@aws-amplify/datastore';
import { Auth } from '@aws-amplify/auth'
import { Amplify, Hub, Logger } from '@aws-amplify/core'
import { Authenticator as AmplifyAuthenticator, withAuthenticator } from './Authenticator';
import config from 'src/config/app.json';

// Custom Authentication Screens
import SignIn from 'src/screens/Authentication/SignIn/SignIn';
import RequireNewPassword from 'src/screens/Authentication/Password/RequireNewPassword/RequireNewPassword';
import ForgotPassword from 'src/screens/Authentication/Password/ForgotPassword/ForgotPassword';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { useAppSettings } from 'src/state/context/AppSettings';
import { generateZendeskURL } from 'src/utils/zendeskHelpers';
import { ZENDESK_FEATURE } from '@alucio/aws-beacon-amplify/src/API';
import Loading from 'src/screens/Loading';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag/useFeatureFlag';
import { FeatureFlags } from 'src/types/types';

const logger = new Logger('Authenticator', 'INFO');
Amplify.configure({
  ...awsConfig,
  Analytics: { disabled: true },
  // Set Amplify to send ID Token when it's available (user logged in). This allows us to use the tenantID
  // within amplify @auth attributes for filtering records to the user's tenant
  API: {
    graphql_headers: async () => {
      return new Promise((resolve) => {
        Auth.currentSession().then((session) => {
          resolve({ Authorization: session.getIdToken().getJwtToken() })
        }).catch(() => {
          resolve({})
        })
      })
    },
  },
  oauth: {
    domain: `${config.authUrl}.auth.us-west-2.amazoncognito.com`,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `${window.location.origin}/`,
    redirectSignOut: `${window.location.origin}/`,
    responseType: 'code',
  },
});

interface AuthProps {
  authState?: string;
  authData?: CognitoUser;
  onStateChange: (status: string) => void;
}

// Alternatively, don't use the HOC and use the components instead
// https://aws-amplify.github.io/docs/js/authentication#using-the-authenticator-component
const Authenticator: React.FC<PropsWithChildren<AuthProps>> = props => {
  const { children, onStateChange } = props;
  const { isOfflineEnabled, initialUrlSearchParams } = useAppSettings();
  const returnTo = initialUrlSearchParams.get('return_to') || initialUrlSearchParams.get('state');
  const isKnowledgebaseEnabled = useFeatureFlag(FeatureFlags.BEAC_2519_zendesk_knowledgebase);

  logger.debug('Authenticator Rendered')
  useEffect(() => {
    // Listen for Auth SignIn event and clear out datastore to handle case if session expires
    // and user signs in again
    // We use a separate AuthListener rather than adding it to the useEffect code below
    // to handle switch from Browser -> PWA (BEAC-2453)
    const authListener = async (data) => {
      switch (data.payload.event) {
        case 'signIn':
          store.dispatch(storeReset())
          await DataStore.clear()
          ActiveUser.clear()
          break
      }
    }
    const removeListener = Hub.listen('auth', authListener);

    return () => removeListener();
  }, [isOfflineEnabled]);

  useEffect(() => {
    logger.debug('useEffect onAuthState/authData Called', props)
    const init = async (props: AuthProps) => {
      if (props.authState === 'signedIn' && props.authData) {
        const userAttributtes = new CognitoIdToken({
          IdToken: props
            .authData
            // @ts-ignore with the sso we didnt detect a way to get the attributes from the user so we are using the id token for tha
            .signInUserSession
            .idToken
            .jwtToken,
        })
          .decodePayload()

        const attributes = (props.authData.attributes || userAttributtes) as any;
        store.dispatch(
          userActions.setCognitoUser({ username: props.authData.username, attributes: attributes }),
        );
        localStorage.setItem('amplify-latest-user-attributes', JSON.stringify({
          username: props.authData.username, attributes,
        }));

        if (returnTo && isKnowledgebaseEnabled) {
          const url = await generateZendeskURL(ZENDESK_FEATURE.SSO, returnTo);
          window.location.href = url;
        }
      }
    }
    init(props)
  }, [props.authState, props.authData, isKnowledgebaseEnabled]);

  useEffect(() => {
    const authListener = async (data) => {
      const { payload } = data
      const { event } = payload

      if (event === 'signOut') {
        onStateChange('signedOut');
      }
    }

    Hub.listen('auth', authListener);

    return () => Hub.remove('auth', authListener);
  }, [onStateChange]);

  if (returnTo && isKnowledgebaseEnabled) {
    return <Loading />
  }

  return (<>{children}</>)
};

// ON FIRST LOAD, AMPLIFY CHECKS THE ACCESS TOKEN. IF IT'S EXPIRED, AMPLIFY TRIES TO REFRESH IT.
// IF IT FAILS REFRESHING IT (WHICH WILL HAPPEN IF OFFLINE), IT LOGS OUT THE USER.
// WE WANT TO HANDLE THAT SCENARIO SO IF THE LAST AMPLIFY STATUS IS VALID, WE DON'T LOG THEM OUT.
const PWAAuthenticator: React.FC<PropsWithChildren> = (props) => {
  const { isOnline } = useAppSettings();
  const onLoadAmplifyAuthState = useMemo(
    () => localStorage.getItem('amplify-authenticator-authState') || undefined, [],
  )
  const [cognitoState, setCognitoState] = useState({
    authState: !isOnline ? onLoadAmplifyAuthState : undefined,
    authData: undefined,
  });

  useEffect(() => {
    async function handleOffLineLoad(): Promise<void> {
      let cognitoUser;
      try {
        cognitoUser = await Auth.currentAuthenticatedUser();
      } catch (e) {
        logger.debug('Authenticated user call failed, ', e);
        logger.debug('Getting CognitoUser from LocalStorage');
        cognitoUser = JSON.parse(localStorage.getItem('amplify-latest-user-attributes') ?? '');
      }

      if (!cognitoUser) {
        logger.debug('CognitoUser from LocalStorage not found');
        logger.debug('Signing Out');
        await Auth.signOut();
        setCognitoState({ authState: undefined, authData: undefined });
        return;
      }

      store.dispatch(
        userActions.setCognitoUser({ username: cognitoUser.username, attributes: cognitoUser.attributes }),
      );
    }

    if (!isOnline && onLoadAmplifyAuthState === 'signedIn') {
      handleOffLineLoad();
    }
  }, []);

  function handleAuthStateChange(state, data): void {
    setCognitoState({ authState: state, authData: data });
  }

  function onStateChange(state): void {
    setCognitoState((currentState) => ({ ...currentState, authState: state }));
  }

  const MemoizedAuthenticator = useMemo(() => (
    <Authenticator
      authState={cognitoState.authState}
      authData={cognitoState.authData}
      onStateChange={onStateChange}
    >
      {props.children}
    </Authenticator>
  ), [cognitoState]);

  if (cognitoState.authState !== 'signedIn') {
    return (
      <AmplifyAuthenticator
        hideDefault={true}
        onStateChange={handleAuthStateChange}
        children={[
          <SignIn key="custom-sign-in" />,
          <RequireNewPassword key="require-new-password" />,
          <ForgotPassword key="forgot-password" />,
        ]}
      />
    );
  }

  return MemoizedAuthenticator;
};

const AuthenticatorWrapper: React.FC<PropsWithChildren> = (props) => {
  const { isPWAStandalone } = useAppSettings();

  const DesktopAuthenticator = useMemo(() => withAuthenticator(Authenticator, false, [
    <SignIn key="custom-sign-in" />,
    <RequireNewPassword key="require-new-password" />,
    <ForgotPassword key="forgot-password" />,
  ]), []);

  return isPWAStandalone ? <PWAAuthenticator {...props} /> : <DesktopAuthenticator {...props} />;
};

export default AuthenticatorWrapper;
