import React, { useMemo } from 'react'
import { useDispatch } from 'src/state/redux'
import im from 'immer'

import { Dispatch } from 'redux'
import { Folder as FolderModel } from '@alucio/aws-beacon-amplify/src/models'
import { GenericToast, ToastOrientations } from '@alucio/lux-ui'

import { FeatureFlags, FolderORM } from 'src/types/types'

import { folderActions } from 'src/state/redux/slice/folder'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'

import DNAFolderUpsertModal from 'src/components/DNA/Modal/DNAFolderUpsert'
import DNAFolderDeleteModal from 'src/components/DNA/Modal/DNAFolderDelete'
import useToast, { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast'
import { EDITOR_TYPE, presentationBuilderActions } from 'src/state/redux/slice/PresentationBuilder/PresentationBuilder'
import DNAShareFolderWarning from '../Modal/DNAShareFolderWarning'
import DNAShareFolderModal from '../Modal/DNAShareFolderModal/DNAShareFolderModal'
import { doesTreeContainsCustomDeck } from 'src/utils/foldersHelpers'
import { useFeatureFlag } from 'src/hooks/useFeatureFlag/useFeatureFlag'
import { drawerActions, DRAWER_ENTITIES } from 'src/state/redux/slice/drawer'
import { Dimensions } from 'react-native'
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings'

export type FolderContextOptions = {
  [key in keyof typeof FolderContextMenuActions]: {
    icon: string,
    title: string,
    onPress: (d: Dispatch, f: FolderORM, toast: ToastActions, enableCollaboration: boolean,
      deviceMode: string ) => () => void
  }
}

export type BindFolderContextMenuActions = {
  [key in keyof typeof FolderContextMenuActions]: (f: FolderORM) => () => void
}

export enum FolderContextMenuActions {
  rename = 'Rename',
  duplicate = 'Duplicate',
  share = 'Share',
  duplicateNested = 'Duplicate',
  pin = 'Pin',
  unpin = 'Unpin',
  archive = 'Archive',
  unarchive = 'Unarchive',
  delete = 'Delete',
  createFolder = 'Create new folder',
  createSubFolder = 'Create sub folder',
  createCustomPresentation = 'Create custom presentation',
  copyShared = 'Copy into My Folders',
  openFilesDrawer = 'Add files to folder'
}

export type FolderContextMenuAction = keyof typeof FolderContextMenuActions

/** [TODO] WE CAN PROBABLY CONSLIDATE BOTH */
/** This is a bit more convenient than using the raw contextActions below */
export const useDNAFolderActions = (): BindFolderContextMenuActions => {
  const dispatch = useDispatch()
  const toast = useToast()
  // [TODO BEAC 2718] This should be removed at the end of the epic
  const enableCollaboration = useFeatureFlag(FeatureFlags.BEAC_2818_custom_deck_collaboration)
  const { deviceMode } = useAppSettings()

  const bound = useMemo(() => im(folderContextMenuActions, draft => {
    for (const action in folderContextMenuActions) {
      const actionTyped = action as keyof typeof FolderContextMenuActions
      draft[action] = (
        folderORM: FolderORM,
      ) => folderContextMenuActions[actionTyped]
        .onPress(dispatch, folderORM, toast, enableCollaboration, deviceMode)
    }
  }) as unknown as BindFolderContextMenuActions,
  [dispatch, toast],
  )

  return bound
}

/** This is specific to the Context Menus */
export const folderContextMenuActions: FolderContextOptions = {
  rename: {
    icon: 'pencil',
    title: FolderContextMenuActions.rename,
    onPress: (dispatch, folderORM, toast) => () => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: false,
        component: (props) => <DNAFolderUpsertModal {...props} folderORM={folderORM} toast={toast} />,
      }))
    },
  },
  duplicate: {
    icon: 'content-copy',
    title: FolderContextMenuActions.duplicate,
    onPress: (dispatch, folderORM) => () => {
      analytics?.track('FOLDER_DUPLICATE', {
        action: 'DUPLICATE',
        category: 'FOLDER',
        folderId: folderORM.model.id,
      })

      // @ts-ignore Fix this later
      dispatch(folderActions.cloneFolder({ folder: folderORM.model }))
    },
  },
  copyShared: {
    icon: 'content-copy',
    title: FolderContextMenuActions.copyShared,
    onPress: (dispatch, folderORM, toast) => () => {
      // WE NEED TO REMOVE, FROM THE ITEMS LIST, THE
      // ITEMS THAT THE USER DOESN'T HAVE ACCESS TO
      const folderModelToCopy = {
        ...folderORM.model,
        items: folderORM.model.items.filter((item) =>
          folderORM.relations.items.some((folderItemORM) =>
            folderItemORM.model.id === item.id),
        ),
      }
      // @ts-ignore
      dispatch(folderActions.cloneFolder({
        folder: {
          ...folderModelToCopy,
          sharePermissions: [],
        },
        isCopyingShared: true,
      }))
      toast.add(
        <GenericToast
          title="Copy created in My Folders"
          status="information"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    },
  },
  duplicateNested: {
    icon: 'content-copy',
    title: FolderContextMenuActions.duplicate,
    onPress: (dispatch, folderORM) => () => {
      analytics?.track('FOLDER_DUPLICATE_NESTED', {
        action: 'DUPLICATE_NESTED',
        category: 'FOLDER',
        folderId: folderORM.model.id,
      })

      dispatch(folderActions.duplicate(folderORM))
    },
  },
  pin: {
    icon: 'pin',
    title: FolderContextMenuActions.pin,
    onPress: (dispatch, folderORM) => () => {
      analytics?.track(`FOLDER_${!folderORM.model.isPinned ? 'PIN' : 'UNPIN'}`, {
        action: !folderORM.model.isPinned ? 'PIN' : 'UNPIN',
        category: 'FOLDER',
        folderId: folderORM.model.id,
      })

      dispatch(folderActions.save(
        {
          model: FolderModel,
          entity: folderORM.model,
          updates: { isPinned: !folderORM.model.isPinned },
        },
      ))
    },
  },
  unpin: {
    icon: 'pin',
    title: FolderContextMenuActions.unpin,
    onPress: (dispatch, folderORM) => () => {
      analytics?.track('FOLDER_UNPIN', {
        action: 'UNPIN',
        category: 'FOLDER',
        folderId: folderORM.model.id,
      })

      dispatch(folderActions.save(
        {
          model: FolderModel,
          entity: folderORM.model,
          updates: { isPinned: false },
        },
      ))
    },
  },
  archive: {
    icon: 'archive',
    title: FolderContextMenuActions.archive,
    onPress: (dispatch, folderORM, toast) => () => {
      analytics?.track('FOLDER_ARCHIVE', {
        action: 'ARCHIVE',
        category: 'FOLDER',
        folderId: folderORM.model.id,
      })

      dispatch(folderActions.archive(folderORM))
      toast.add(
        <GenericToast
          title="Folder archived."
          status="information"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    },
  },
  unarchive: {
    icon: 'archive',
    title: FolderContextMenuActions.unarchive,
    onPress: (dispatch, folderORM, toast) => () => {
      analytics?.track('FOLDER_UNARCHIVE', {
        action: 'UNARCHIVE',
        category: 'FOLDER',
        folderId: folderORM.model.id,
      })

      dispatch(folderActions.unarchive(folderORM))
      toast.add(
        <GenericToast
          title="Folder unarchived."
          status="information"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    },
  },
  delete: {
    icon: 'trash-can-outline',
    title: FolderContextMenuActions.delete,
    onPress: (dispatch, folderORM, toast) => () => {
      analytics?.track('FOLDER_DELETE', {
        action: 'DELETE',
        category: 'FOLDER',
        folderId: folderORM.model.id,
      })

      dispatch(DNAModalActions.setModal(
        {
          isVisible: true,
          allowBackdropCancel: false,
          component: (props) => (
            <DNAFolderDeleteModal
              {...props}
              folderORM={folderORM}
              toast={toast}
            />
          ),
        },
      ))
    },
  },
  createFolder: {
    icon: 'folder-plus-outline',
    title: FolderContextMenuActions.createFolder,
    onPress: (dispatch, _, toast) => () => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: false,
        component: (props) => <DNAFolderUpsertModal {...props} toast={toast} />,
      }))
    },
  },
  createSubFolder: {
    icon: 'folder-plus-outline',
    title: FolderContextMenuActions.createSubFolder,
    onPress: (dispatch, FolderORM, toast) => () => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: false,
        component: (props) => (
          <DNAFolderUpsertModal
            {...props}
            folderORM={FolderORM}
            mode="CREATE_SUBFOLDER"
            toast={toast}
          />
        ),
      }))
    },
  },
  createCustomPresentation: {
    icon: 'card-plus-outline',
    title: FolderContextMenuActions.createCustomPresentation,
    onPress: (dispatch, FolderORM, _) => () => {
      dispatch(presentationBuilderActions.openPresentationBuilderNew({
        targetFolder: FolderORM,
        editorType: EDITOR_TYPE.OWNER,
      }))
    },
  },
  share: {
    icon: 'account-multiple',
    title: FolderContextMenuActions.share,
    onPress: (dispatch, FolderORM, _, enableCollaboration) => () => {
      // [TODO BEAC 2718] This should be removed at the end of the epic
      const showWarning = (doesTreeContainsCustomDeck(FolderORM) && !enableCollaboration)

      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        // TODO: Onclose not implemented yet
        component: (props) => showWarning
          ? <DNAShareFolderWarning {...props} />
          : (<DNAShareFolderModal folderORM={FolderORM} {...props} />),
      }))
    },
  },
  openFilesDrawer: {
    icon: 'file-plus-outline',
    title: FolderContextMenuActions.openFilesDrawer,
    onPress: (dispatch, folderORM, _, __, deviceMode) => () => {
      analytics?.track('ADD_DOCUMENT_PANEL', {
        action: 'OPEN_ADD_DOCUMENT_PANEL',
        category: 'FOLDER',
        folderId: folderORM.model.id,
      })
      const width = deviceMode === DeviceMode.tablet ? Dimensions.get('window').width * 0.8 : undefined
      dispatch(drawerActions.toggle({
        entity: DRAWER_ENTITIES.FILES,
        entityId: folderORM.model.id,
        width: width,
      }))
    },
  },
}
