import {
  CustomDeck,
  CustomDeckGroup,
  CustomDeckPage,
  DocumentAccessLevel,
  DocumentStatus,
  EditMutex,
  FolderItemType,
  Page,
} from '@alucio/aws-beacon-amplify/src/models';
import isEqual from 'lodash/isEqual'
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { batch } from 'react-redux';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import ClearSlides from 'src/components/DNA/Modal/DNAPresentationBuilder/ClearSlides'
import useEditorState from './useEditorState';
import RequiredSlidesHiddenConfirmation
  from 'src/components/DNA/Modal/DNAPresentationBuilder/RequiredSlidesHiddenConfirmation';
import CloseConfirmation from 'src/components/DNA/Modal/DNAPresentationBuilder/CloseConfirmation';
import {
  PRESENTATION_BUILDER_VARIANT,
  presentationBuilderActions,
  EDITOR_TYPE,
} from 'src/state/redux/slice/PresentationBuilder/PresentationBuilder';
import { folderActions } from 'src/state/redux/slice/folder';
import useSelectorState from './useSelectorState';
import { ThumbnailDimensions, ThumbnailSize } from 'src/hooks/useThumbnailSize/useThumbnailSize';
import { RootState, useDispatch, useSelector } from 'src/state/redux';
import {
  CustomDeckGroupORM,
  CustomDeckORM,
  DocumentVersionORM,
  FolderItemORM,
  ORMTypes,
  VERSION_UPDATE_STATUS,
} from 'src/types/types';
import { useCustomDeckORMById } from 'src/state/redux/selector/folder';
import { v4 as uuid } from 'uuid';
import { contentPreviewModalActions } from '../../../state/redux/slice/contentPreviewModal';
import { customDeckActions } from 'src/state/redux/slice/customDeck'
import { useCurrentUser } from 'src/state/redux/selector/user';
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage';
import { useAppSettings } from 'src/state/context/AppSettings';
import { ThumbnailPage } from 'src/components/SlideSelector/SlideSelector';

/** These options represent the different 'modes' this component can be in. The default is 'selection' mode */
enum BuilderModes {
  selection,
  customization,
  replace,
}

const TIME_LAST_SEEN_MINUTES = 15
const MUTEX_INTERVAL = 60 * 1000

export type BuilderMode = keyof typeof BuilderModes

/** State properties which can be used by various sub-components to manipulate the current in-progress presentation */
export interface PresentationBuilderStateType {
  clearSlides: () => void
  closePresentationBuilder: (save?: boolean) => void
  savePresentation: () => void
  builderMode: BuilderMode
  setBuilderMode: React.Dispatch<React.SetStateAction<BuilderMode>>
  title: string | undefined
  setTitle: React.Dispatch<React.SetStateAction<string | undefined>>
  saveAttemptedWithoutTitle: boolean
  setSaveAttemptedWithoutTitle: React.Dispatch<React.SetStateAction<boolean>>
  numOfRequiredSlides: number,
  setNumOfRequiredSlides: React.Dispatch<React.SetStateAction<number>>
  selectedGroups: PayloadGroup[]
  setSelectedGroups: React.Dispatch<React.SetStateAction<PayloadGroup[]>>
  associatedParentsMap: AssociatedParentsMap,
  setAssociatedParentsMap: React.Dispatch<React.SetStateAction<AssociatedParentsMap>>,
  hiddenSlidesVisible: boolean
  setHiddenSlidesVisible: React.Dispatch<React.SetStateAction<boolean>>
  displayPageSourceFile: boolean
  setDisplayPageSourceFile: React.Dispatch<React.SetStateAction<boolean>>
  handleModeChange: (nextMode?: BuilderMode) => void
  editorThumbnailSize: ThumbnailSize
  setEditorThumbnailSize: React.Dispatch<React.SetStateAction<ThumbnailSize>>
  cycleEditorThumbnailSize: () => void
  editorThumbnailDimensions: ThumbnailDimensions
  selectorThumbnailSize: ThumbnailSize
  setSelectorThumbnailSize: React.Dispatch<React.SetStateAction<ThumbnailSize>>
  cycleSelectorThumbnailSize: () => void,
  selectorThumbnailDimensions: ThumbnailDimensions,
  onPreview: () => void,
  cancelPresentation: () => void,
  activeReplacementGroup?: PayloadGroup,
  setActiveReplacementGroup: React.Dispatch<React.SetStateAction<PayloadGroup | undefined>>,
  onApplyFindAndReplace: (payloadGroups: PayloadGroup[], newTitle: string) => void,
  customDeck?: CustomDeckORM,
  editorType?: EDITOR_TYPE,

  /** TODO: These three properties seem closely interrelated, should investigate the posibility of consolidation */
  variant?: PRESENTATION_BUILDER_VARIANT,
  isCustomDeckLockedByOthers?: boolean,
  isLocked?: boolean,

  selectedTargetItems: string[],
  setSelectedTargetItems: React.Dispatch<React.SetStateAction<string[]>>,
}

export interface PayloadGroup {
  id: string;
  groupSrcId?: string;
  documentVersionORM?: DocumentVersionORM;
  pages: Page[];
  visible: boolean;
  groupStatus: GroupStatus,
  docAccessLevel: keyof typeof DocumentAccessLevel,
  name?: string;
  locked?: boolean;
}

export type AssociatedParentsMap = Map<string, boolean>

/** NOTE: normally we'd have to use an Omit<PayloadGroup, 'pages'> here but since TS doesn't
 * care about switching from `Page` to the extended type `ThumbnailPage` it's fine */
export interface ModifiedPayloadGroup extends PayloadGroup {
  pages: ThumbnailPage[]
}

interface ExtendedPage extends Page, Pick<ThumbnailPage, 'parentIds'> { }
interface ExtendedPagePayloadGroup extends PayloadGroup {
  pages: ExtendedPage[]
}

export enum GroupStatus {
  MAJOR_UPDATE = 'MAJOR_UPDATE',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED',
  REVOKED = 'REVOKED',
  ACTIVE = 'ACTIVE',
}

/** Type defining config props for the <PresentationBuilderStateProvider> element */
interface PresentationBuilderStateProviderProps {
}

/** Context instantiation/config */
export const PresentationBuilderStateContext = createContext<PresentationBuilderStateType>(null!)
PresentationBuilderStateContext.displayName = 'PresentationBuilderContext'

/**
 * Identify the current status of the document, as an example if the documente should be removed or be replaced by another one
 * @param documentVersion
 * @returns MAJOR_UPDATE, DELETED, ARCHIVED, ACTIVE
 */
export const getPayloadGroupStatus = (documentVersion?: DocumentVersionORM): GroupStatus => {
  if (!documentVersion) return GroupStatus.DELETED;

  const isMajor = documentVersion?.meta.version.updateStatus === VERSION_UPDATE_STATUS.PENDING_MAJOR
  const isNotPublished = documentVersion?.meta.version.updateStatus === VERSION_UPDATE_STATUS.NOT_PUBLISHED

  if (isMajor) {
    return GroupStatus.MAJOR_UPDATE
  }
  else if (isNotPublished) {
    const status = documentVersion?.relations.documentORM.model.status as DocumentStatus
    if ([DocumentStatus.ARCHIVED].includes(status!)) {
      return GroupStatus.ARCHIVED
    }
    else if ([DocumentStatus.REVOKED].includes(status!)) {
      return GroupStatus.REVOKED
    }
    else if ([DocumentStatus.DELETED].includes(status!)) {
      return GroupStatus.DELETED
    }
    // Not sure if is a real posibility
    else {
      return GroupStatus.ACTIVE;
    }
  }
  else {
    return GroupStatus.ACTIVE
  }
}

/* When saving, convert our draft object into a CustomDeckGroup[] */
const payloadGroupsToCustomDeckGroup = (
  draftGroups: PayloadGroup[],
  existingCustomDeck?: CustomDeckORM,
) => {
  return draftGroups.map((draftGroup) => {
    let pages: CustomDeckPage[] = [];

    if (draftGroup.groupStatus === GroupStatus.DELETED) {
      // IF THE DOCUMENT WAS DELETED (MEANING THAT THE PAGES ARE EMPTY)
      // WE WANT TO KEEP THE REFERENCES AS THEY'RE IN THE CUSTOMDECK SO THEY'RE STILL
      // SHOWN AS REQUIRED TO BE MANUALLY REMOVED
      const savedGroup = existingCustomDeck?.model.groups.find(({ id }) => id === draftGroup.id);
      pages = savedGroup?.pages || [];
    } else {
      pages = draftGroup.pages.map((page) => ({
        pageId: page.pageId,
        pageNumber: page.number,
        documentVersionId: draftGroup.documentVersionORM?.model.id || '',
      }))
    }

    const newCustomDeckGroup:CustomDeckGroup = {
      id: draftGroup.id,
      srcId: draftGroup.groupSrcId ?? draftGroup.documentVersionORM?.model.id,
      pages,
      visible: draftGroup.visible,
      docAccessLevel: draftGroup.docAccessLevel,
      name:draftGroup.name,
      locked: draftGroup.locked,
    }
    return newCustomDeckGroup
  });
}

/* FUNCTIONS TO CREATE A DRAFT FOLDERITEMORM TO USE THE CUSTOM DECK IN THE PLAYER */
const customDeckORMToPreview = (groups: PayloadGroup[], customDeck?: CustomDeckORM) => {
  const groupORMs: CustomDeckGroupORM[] = [];
  const validGroups = groups.filter(({ groupStatus }) => groupStatus === GroupStatus.ACTIVE);
  const customDeckGroups: CustomDeckGroup[] = payloadGroupsToCustomDeckGroup(validGroups, customDeck);
  const now = new Date().toISOString();

  validGroups.forEach((group) => {
    /** NOTE: This might be better structured as a map to reduce the overhead from iteration */
    const customDeckGroup = customDeckGroups.find(({ id }) => id === group.id)

    if (!customDeckGroup) throw (new Error('No custom deck group found, this may indicate a corruped DB entry'))

    groupORMs.push({
      isGroup: group.pages.length > 1,
      model: customDeckGroup,
      pages: group.pages.map((page) => ({
        documentVersionORM: group.documentVersionORM!,
        page,
        model: {
          documentVersionId: group.documentVersionORM?.model.id!,
          pageNumber: page.number,
          pageId: page.pageId,
        },
      })),
      meta: {
        version: {
          updateStatus: VERSION_UPDATE_STATUS.CURRENT,
        },
      },
    });
  });

  const newCustomDeckORM:CustomDeckORM = {
    model: {
      id: uuid(),
      title: customDeck?.model.title ?? '',
      createdAt: now,
      updatedAt: now,
      createdBy: '',
      autoUpdateAcknowledgedAt: now,
      updatedBy: '',
      tenantId: '',
      groups: customDeckGroups,
    },
    type: ORMTypes.CUSTOM_DECK,
    meta: {
      customDeckGroups: groupORMs,
      assets: {
        isContentCached: false,
      },
      version: {
        updateStatus: VERSION_UPDATE_STATUS.CURRENT,
        requiresReview: false,
        autoUpdateUnacknowledged: false,
      },
      permissions: {
        isCollaborator: false,
        MSLPresent: false,
      },
      containsWebDocs: false,
      containsVideoDoc: false,
      containsHTMLDocs: false,
      hasExternalDependency: false,
    },
  }

  return newCustomDeckORM
}

/* CREATES A DRAFT FOLDER ITEM SO WE CAN SEND IT TO THE CONTENT PREVIEW MODAL AS A PREVIEW OF THE NEW CUSTOM DECK */
function toFolderItemORM(
  selectedGroups: PayloadGroup[],
  customTitle?: string, customDeckORM?: CustomDeckORM,
  folderItemORM?: FolderItemORM): FolderItemORM {
  const draftCustomDeckORM = customDeckORMToPreview(selectedGroups, customDeckORM);

  if (folderItemORM) {
    return {
      ...folderItemORM,
      meta: {
        title: folderItemORM.meta.title,
        assets: {},
        hasAutoUpdatedItem: false,
        hasOutdatedItem: false,
      },
      relations: {
        itemORM: draftCustomDeckORM,
      },
    }
  }

  const now = new Date().toISOString();

  return {
    model: {
      id: uuid(),
      customTitle: customTitle || 'Presentation Preview',
      type: FolderItemType.CUSTOM_DECK,
      itemId: uuid(),
      itemLastUpdatedAt: now,
      addedAt: now,
    },
    meta: {
      title: draftCustomDeckORM.model.title,
      assets: {},
      hasAutoUpdatedItem: false,
      hasOutdatedItem: false,
    },
    type: ORMTypes.FOLDER_ITEM,
    relations: {
      itemORM: draftCustomDeckORM,
    },
  };
}

export const timeSinceLastSeen = (editMutex: EditMutex) => {
  const now = new Date()
  const lastUpdatedAt = new Date(editMutex.lastSeenAt)

  const diff = now.getTime() - lastUpdatedAt.getTime()
  const diffMinutes = Math.floor(diff / (1000 * 60))

  return diffMinutes
}

/** Provider instantiation/config */
const PresentationBuilderStateProvider: React.FC<PropsWithChildren<
  PresentationBuilderStateProviderProps
>> = (props) => {
  const globalBuilderState = useSelector((state: RootState) => state.PresentationBuilder);
  const { targetFolder, folderItemORM, customDeckId, variant, editorType } = globalBuilderState;
  const { isOnline } = useAppSettings()
  const route = useCurrentPage({ exact: false })
  const isSharedFolders = route?.PATH.includes('shared_folders');
  const existingCustomDeck = useCustomDeckORMById(customDeckId || '');
  // We set to read only by default to allow the fetching of the lock
  const [isLocked, setIsLocked] = useState(!!existingCustomDeck && isOnline)
  const [builderMode, setBuilderMode] = useState<BuilderMode>(existingCustomDeck ? 'customization' : 'selection');
  const [title, setTitle] = useState<string | undefined>(folderItemORM?.meta.title ?? '')
  const [saveAttemptedWithoutTitle, setSaveAttemptedWithoutTitle] = useState<boolean>(false)
  const [selectedTargetItems, setSelectedTargetItems] = useState<string[]>([])
  const [numOfRequiredSlides, setNumOfRequiredSlides] = useState<number>(0)
  const initialTitleRef = useRef(title)

  /** If we're editing, we'll convert the CUSTOMDECKORM into our draft object **/
  const toCustomDeckPayloadGroups = (customDeckORM: CustomDeckORM) => {
    return customDeckORM.meta.customDeckGroups.map(
      (groupORM) => {
        const documentVersionORM = groupORM.pages[0].documentVersionORM;
        const groupStatus = getPayloadGroupStatus(documentVersionORM);

        // Calculate 'parentIds' and attach it in page, calculate 'associatedParents' and send to context
        const allAssociatedParents: {[key: string]: string[]} = {}
        documentVersionORM?.meta.allPages.forEach(page => {
          page.linkedSlides?.forEach(slideId => {
            if (allAssociatedParents[slideId]) allAssociatedParents[slideId].push(page.pageId)
            else allAssociatedParents[slideId] = [page.pageId]
          })
        })

        const pages = groupORM.pages.map(({ page }) => {
          const parentIds: string[] = []
          if (allAssociatedParents[page.pageId]) parentIds.push(...allAssociatedParents[page.pageId])
          return {
            ...page,
            parentIds,
          }
        })

        const mappedPayloadGroup: PayloadGroup = {
          id: groupORM.model.id,
          groupSrcId: groupORM.model.srcId,
          documentVersionORM,
          pages,
          visible: groupORM.model.visible,
          groupStatus,
          docAccessLevel: groupORM.model.docAccessLevel,
          name: groupORM.model.name,
          locked: groupORM.model.locked,
        }

        return mappedPayloadGroup
      },
    );
  }

  const [selectedGroups, setSelectedGroups] = useState<PayloadGroup[]>(
    existingCustomDeck
      ? toCustomDeckPayloadGroups(existingCustomDeck)
      : [],
  );
  const [associatedParentsMap, setAssociatedParentsMap] = useState<AssociatedParentsMap>(new Map<string, boolean>())
  const initialSelectedGroupRef = useRef(selectedGroups)
  const [hiddenSlidesVisible, setHiddenSlidesVisible] = useState<boolean>(true)
  const [displayPageSourceFile, setDisplayPageSourceFile] = useState<boolean>(true);
  const [activeReplacementGroup, setActiveReplacementGroup] = useState<PayloadGroup | undefined>();
  const dispatch = useDispatch()
  const pendingGroupsRevision = useRef<boolean>(false);

  const {
    editorThumbnailSize,
    setEditorThumbnailSize,
    cycleEditorThumbnailSize,
    editorThumbnailDimensions,
  } = useEditorState(builderMode)

  const {
    selectorThumbnailSize,
    setSelectorThumbnailSize,
    cycleSelectorThumbnailSize,
    selectorThumbnailDimensions,
  } = useSelectorState()
  const currentUser = useCurrentUser()
  const currentUserId = currentUser.userProfile?.id

  const isCustomDeckLockedByOthers = useRef<boolean | undefined>(existingCustomDeck &&
    existingCustomDeck?.model.editMutex &&
    timeSinceLastSeen(existingCustomDeck.model.editMutex) < TIME_LAST_SEEN_MINUTES &&
    currentUserId !== existingCustomDeck.model.editMutex.userId).current
  /** Locking Mutex for Collaborative Editing */
  const intervalRef = useRef<NodeJS.Timeout>()

  const sendMutex = useCallback(() => {
    const timeStarted = new Date()
    existingCustomDeck && dispatch(customDeckActions.lockCustomDeck({
      customDeckId: existingCustomDeck.model.id!,
      timestarted: timeStarted.toISOString(),
      lock: true,
      currentUser: currentUser.userProfile!,
    }))
  }, [currentUser.userProfile?.id, existingCustomDeck])

  useEffect(() => {
    // We set readOnly to false when we see the deck is locked by the current user
    if (existingCustomDeck && existingCustomDeck.model.editMutex &&
      existingCustomDeck.model.editMutex.userId === currentUserId &&
      timeSinceLastSeen(existingCustomDeck.model.editMutex) < TIME_LAST_SEEN_MINUTES) {
      setIsLocked(false)
    }
  }, [existingCustomDeck])

  useEffect(() => {
    if (existingCustomDeck && !isCustomDeckLockedByOthers) {
      sendMutex();
      intervalRef.current = setInterval(sendMutex, MUTEX_INTERVAL)
      return () => {
        intervalRef.current && clearInterval(intervalRef.current)
      }
    }
  }, [])

  /**
   * when the selected groups collection is mutated, we need to update the associated selected target items
   */
  useEffect(() => {
    if (selectedTargetItems.length) {
      const validSelectedTargetItems = selectedTargetItems.filter((id) => {
        const page = selectedGroups.find((group) => group.id === id)
        return !!page
      },
      )
      setSelectedTargetItems(validSelectedTargetItems)
    }

    if (pendingGroupsRevision.current) {
      const allGroupsAreValid = selectedGroups.every((group) => group.groupStatus === GroupStatus.ACTIVE);
      if (allGroupsAreValid) {
        onApplyFindAndReplace(selectedGroups, title || '');
      }
    }
  }, [selectedGroups])

  /** CONTEXT UTILITIES */
  const handleModeChange = (nextMode?: BuilderMode) => {
    // from selection mode or replace mode user can only go to customization mode
    // from customization mode user can either go to selection or replace depending on the button clicked
    setBuilderMode((p) => {
      if (nextMode) {
        return nextMode
      } else if (p === 'selection' || p === 'replace') {
        return 'customization'
      } else {
        // this is a catch all where the user is sent to the default mode
        return 'selection'
      }
    })
  }

  const clearSlides = () => {
    selectedGroups.length &&
      dispatch(
        DNAModalActions.setModal(
          {
            isVisible: true,
            allowBackdropCancel: true,
            component: (props) => (
              <ClearSlides
                {...props}
                onClear={() => setNumOfRequiredSlides(0)}
                setSelectedGroups={setSelectedGroups}
              />
            ),
          },
        ),
      )
  }

  const onPreview = (): void => {
    const draftFolderItemORM = toFolderItemORM(selectedGroups, title, existingCustomDeck, folderItemORM);

    // TODO: add type guard and remove as assertion here once typings cleanup from #1708 has been merged
    dispatch(
      contentPreviewModalActions.setModalVisibility({
        documentVersionId: (draftFolderItemORM.relations.itemORM.model as CustomDeck)
          .groups[0]?.pages[0].documentVersionId,
        folderItemORM: draftFolderItemORM,
        isOpen: true,
        content: draftFolderItemORM,
      },
      ))
  };

  const savePresentation = () => {
    if (selectedGroups.length) {
      if (!title) {
        setSaveAttemptedWithoutTitle(true);
        return;
      }
      const requiredHiddenSlides = selectedGroups.some((slide) => {
        return !slide.visible && slide.pages.some((page) => page.isRequired || associatedParentsMap.get(page.pageId))
      })

      if (requiredHiddenSlides) {
        openRequiredHiddenSlidesConfirmation()
        return
      }

      createOrUpdatePresentation()
      setSaveAttemptedWithoutTitle(false);
    }
  }

  const createOrUpdatePresentation = () => {
    if (existingCustomDeck) {
      folderItemORM &&
      targetFolder &&
      title &&
      !isSharedFolders &&
      dispatch(folderActions.updateCustomDeck(
        targetFolder,
        folderItemORM,
        existingCustomDeck.model,
        payloadGroupsToCustomDeckGroup(selectedGroups, existingCustomDeck),
        title,
      ))

      // collaboration mode
      folderItemORM &&
      targetFolder &&
      title &&
      isSharedFolders &&
      dispatch(customDeckActions.updateCustomDeck({
        customDeck: existingCustomDeck.model,
        title: title,
        groups: payloadGroupsToCustomDeckGroup(selectedGroups, existingCustomDeck),
        folder: targetFolder,
        folderItem: folderItemORM,
        currentUser: currentUser.userProfile!,
      },
      ))
    } else {
      targetFolder &&
      title &&
      dispatch(folderActions.createCustomDeck(
        targetFolder,
        payloadGroupsToCustomDeckGroup(selectedGroups, existingCustomDeck),
        title,
      ),
      )
    }

    closePresentationBuilder()
  }

  const cancelPresentation = () => {
    const hasChanges = !isEqual(initialSelectedGroupRef.current, selectedGroups) || initialTitleRef.current !== title
    if (hasChanges) {
      openCloseConfirmation()
      return
    }

    customDeckId &&
      !isCustomDeckLockedByOthers && dispatch(customDeckActions.lockCustomDeck({
      customDeckId: customDeckId,
      lock: false,
      timestarted: '',
      currentUser: currentUser.userProfile!,
    }))
    closePresentationBuilder()
  }

  const closePresentationBuilder = () => {
    dispatch(presentationBuilderActions.closePresentationBuilder())
  }

  const openRequiredHiddenSlidesConfirmation = () => {
    dispatch(
      DNAModalActions.setModal(
        {
          isVisible: true,
          allowBackdropCancel: true,
          component: (props) => (
            <RequiredSlidesHiddenConfirmation
              onSave={createOrUpdatePresentation}
              {...props}
            />
          ),
        },
      ),
    )
  }

  const onApplyFindAndReplace = (payloadGroups: PayloadGroup[], newTitle: string) => {
    setTitle(newTitle);
    setBuilderMode('customization')
    const { groups, numRequiredSlidesAdded } = validatePayloadGroups(payloadGroups)

    const allAssociatedParents: {[key: string]: string[]} = {}
    groups.forEach(group => {
      group.documentVersionORM?.meta.allPages.forEach(page => {
        page.linkedSlides?.forEach(slideId => {
          if (allAssociatedParents[slideId] && !allAssociatedParents[slideId].includes(page.pageId)) {
            allAssociatedParents[slideId].push(page.pageId)
          }
          else allAssociatedParents[slideId] = [page.pageId]
        })
      })
    })
    const modifiedPayloadGroups:ExtendedPagePayloadGroup[] = groups.map(group => {
      const extendedPages:ExtendedPage[] = group.pages.map(page => {
        const extenedPage:ExtendedPage = {
          ...page,
          parentIds: allAssociatedParents[page.pageId],
        }
        return extenedPage
      })
      const modifiedPayloadGroup:ExtendedPagePayloadGroup = { ...group, pages: extendedPages }
      return modifiedPayloadGroup
    })
    setSelectedGroups(modifiedPayloadGroups)
    setNumOfRequiredSlides(numRequiredSlidesAdded)
  };

  const validatePayloadGroups = (
    existingPayloadGroups: PayloadGroup[],
  ): {
    groups: PayloadGroup[],
    numRequiredSlidesAdded: number
  } => {
    const docVersionMap = new Map<string, DocumentVersionORM>();
    const addedPages = new Set<string>();
    const addedPageGroups = new Set<string>();
    let numAddedSlides = 0;

    // We only add required slides if the deck is in a valid state otherwise we let the user keep on editing
    if (!existingPayloadGroups.every((group) => group.groupStatus === GroupStatus.ACTIVE)) {
      // IF THERE ARE ASSOCIATED SLIDES, THE GROUPS NEED TO BE ADDED TO A QUEUE FOR LATER REVIEW
      existingPayloadGroups.forEach((group) => {
        group.pages.forEach((page) => {
          if (page.linkedSlides?.length) {
            pendingGroupsRevision.current = true;
          }
        });
      });

      return {
        groups: existingPayloadGroups,
        numRequiredSlidesAdded: 0,
      }
    } else {
      pendingGroupsRevision.current = false;
    }

    existingPayloadGroups.forEach(group => {
      if (group.documentVersionORM) {
        docVersionMap.set(group.documentVersionORM!.model.id, group.documentVersionORM!)
        group.pages.forEach(page => addedPages.add(page.pageId))
        group.groupSrcId && addedPageGroups.add(group.groupSrcId)
      }
    })

    const newPayloadGroups: PayloadGroup[] = []

    // We iterate through all documents making sure required group/slides are already added
    // Since now the required slide are added through selection stage, this can be the reassurance
    docVersionMap.forEach((docVer) => {
      const {
        relations: {
          documentORM, pages,
        },
        model:{
          id,
        },
      } = docVer

      pages.forEach(page => {
        const { model:{ isRequired }, relations:{ pageGroupORM } } = page
        /** Identify required pages... */
        if (isRequired) {
          /** ... with related page groups that are not currently in the addedPageGroups set and add them to
           * the newPayloadGroups array. Additionally, add the source doc group id to the addedPageGroups set */
          if (pageGroupORM && !addedPageGroups.has(pageGroupORM.model.id)) {
            // const srcDocGroup = pageGroupORM
            /**
             * Map the page models in the group's related PageORM array to a Page array
             * Additionally add each page id to the addedPages set
             * */
            const pages = pageGroupORM.relations.pages.map(({ model }) => {
              addedPages.add(model.pageId)
              return model;
            })
            newPayloadGroups.push({
              id: uuid(),
              groupSrcId: pageGroupORM.model.id,
              documentVersionORM: docVer,
              pages,
              visible: true,
              groupStatus: GroupStatus.ACTIVE,
              docAccessLevel: pageGroupORM.relations.documentVersionORM.relations.documentORM.model.accessLevel,
              locked: pageGroupORM.model.locked,
            })
            numAddedSlides = numAddedSlides + pages.length
            addedPageGroups.add(pageGroupORM.model.id)

          /** ... which are not in the addedPages set and add them to the newPayloadGroups array */
          } else if (!addedPages.has(page.model.pageId)) {
            addedPages.add(page.model.pageId)
            newPayloadGroups.push({
              id: uuid(),
              groupSrcId: id,
              documentVersionORM: docVer,
              pages: [page.model],
              visible: true,
              groupStatus: GroupStatus.ACTIVE,
              docAccessLevel: documentORM.model.accessLevel,
            })
            numAddedSlides++
          }
        }
      })
    })

    // Now we iterate through all slides and add associated slides
    const associatedPayloadGroups:PayloadGroup[] = []
    const associatedSlideIds = new Set<string>();
    const allPayloadGroups = [...existingPayloadGroups, ...newPayloadGroups]

    // Iterate over all payload groups and add the associated slides to associatedSlides set
    allPayloadGroups.forEach(({ documentVersionORM, pages, docAccessLevel }) => {
      pages.forEach(page => {
        page.linkedSlides?.forEach(slideId => {
          // If linkedSlide is not already contained in payloadGroups and we have not add it yet
          const existsInPayloadGroups = !existingPayloadGroups.find(group =>
            group.pages.some(page => page.pageId === slideId))
          if (!associatedSlideIds.has(slideId) && existsInPayloadGroups) {
            const associatedSlide = documentVersionORM?.meta.allPages.find(page => page.pageId === slideId)
            if (associatedSlide) {
              // then we will push the association slide in associated array
              associatedSlideIds.add(slideId)
              const newAssociatedSlide: PayloadGroup = {
                id: uuid(),
                groupSrcId: slideId,
                documentVersionORM: documentVersionORM,
                pages: [associatedSlide],
                visible: true,
                groupStatus: GroupStatus.ACTIVE,
                docAccessLevel: docAccessLevel,
              }
              associatedPayloadGroups.push(newAssociatedSlide)
              numAddedSlides++
            }
          }
        })
      })
    })

    // Sort all required slides in slide number order
    const sortedByPageNum = [...newPayloadGroups, ...associatedPayloadGroups]
      .sort((a, b) => (a.pages[0].number < b.pages[0].number) ? -1 : 1)
    return {
      groups: [...existingPayloadGroups, ...sortedByPageNum],
      numRequiredSlidesAdded: numAddedSlides,
    }
  }

  const onCloseHandler = () => {
    batch(() => {
      customDeckId && dispatch(customDeckActions.lockCustomDeck({
        customDeckId: customDeckId,
        lock: false,
        timestarted: '',
        currentUser: currentUser.userProfile!,
      }))
      dispatch(presentationBuilderActions.closePresentationBuilder())
    })
  }

  const openCloseConfirmation = () => {
    dispatch(
      DNAModalActions.setModal(
        {
          isVisible: true,
          allowBackdropCancel: true,
          component: (props) => <CloseConfirmation {...props} onClose={onCloseHandler} />,
        },
      ),
    )
  }

  const contextValue: PresentationBuilderStateType = {
    onApplyFindAndReplace,
    clearSlides,
    closePresentationBuilder,
    savePresentation,
    builderMode,
    setBuilderMode,
    title,
    setTitle,
    saveAttemptedWithoutTitle,
    setSaveAttemptedWithoutTitle,
    numOfRequiredSlides,
    setNumOfRequiredSlides,
    selectedGroups,
    setSelectedGroups,
    associatedParentsMap,
    setAssociatedParentsMap,
    hiddenSlidesVisible,
    setHiddenSlidesVisible,
    displayPageSourceFile,
    setDisplayPageSourceFile,
    handleModeChange,

    onPreview,
    editorThumbnailSize,
    setEditorThumbnailSize,
    cycleEditorThumbnailSize,
    editorThumbnailDimensions,

    selectorThumbnailSize,
    setSelectorThumbnailSize,
    cycleSelectorThumbnailSize,
    selectorThumbnailDimensions,

    cancelPresentation,
    activeReplacementGroup,
    setActiveReplacementGroup,
    customDeck: existingCustomDeck,
    editorType,
    variant,
    isLocked,
    isCustomDeckLockedByOthers,

    selectedTargetItems,
    setSelectedTargetItems,
  }

  return (
    <PresentationBuilderStateContext.Provider value={contextValue}>
      {props.children}
    </PresentationBuilderStateContext.Provider>
  )
}

PresentationBuilderStateProvider.displayName = 'PresentationBuilderStateProvider'

/** Hook for utilizing presentation builder state values/methods. Also
 * handles guarding against using context outside of the provider */
export function usePresentationBuilderState() {
  const context = useContext(PresentationBuilderStateContext)
  if (!context) {
    throw new Error('usePresentationBuilderState must be used within the PresentationBuilderStateProvider')
  }
  return context;
}

export default PresentationBuilderStateProvider
