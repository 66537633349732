import React from 'react';
import { DNABox, Iffy } from '@alucio/lux-ui';
import { ComposableVariant } from '../CustomFields/ComposableForm';
import { ComposableField } from '../CustomFields/ComposableField';
import {
  CustomFieldDefinition, FieldPosition,
} from '@alucio/aws-beacon-amplify/src/models';

import { multiTypeSort } from 'src/state/redux/selector/common';
import { useFieldPositions } from '../CustomFields/useCustomFields';

const ONE_COLUMN_CONTROL_TYPES = [
  'OBJECT',
  'LABEL',
]

type ColumnFieldProps = {
  variant?: ComposableVariant;
  groupCustomFields?: ColumnFieldGroup;
}

type ColumnFieldWrapperProps = {
  customFields: CustomFieldDefinition[];
  variant?: ComposableVariant;
  groupCustomFields?: ColumnFieldGroup;
}

type ColumnFieldGroup = {
  fields : CustomFieldDefinition[];
  groupType: 'COLUMN' | 'SPAN';
}

/// This function is used to group the fields in the form in columns or spans depending on the field position
/// The fields are span if they are of type OBJECT or LABEL or if they are in te same position
/// on its previous and next field (LEFT,LEFT,LEFT), otherwise they are in a column with the next field (just two columns are supported LEFT,RIGHT)
const getFieldGroups = (customFields: CustomFieldDefinition[]) => {
  const columnFieldGroups: ColumnFieldGroup[] = customFields.reduce<ColumnFieldGroup[]>(
    (groups, field, i, arr) => {
      const previousGroup = groups[groups.length - 1];
      const fieldPosition = field.settings?.fieldPosition || FieldPosition.LEFT;
      const previousFieldPosition = arr[i - 1] ? arr[i - 1].settings?.fieldPosition || FieldPosition.LEFT
        : fieldPosition;
      const nextFieldPosition = arr[i + 1] ? arr[i + 1].settings?.fieldPosition || FieldPosition.LEFT : fieldPosition;
      if ((ONE_COLUMN_CONTROL_TYPES.includes(field.controlType) ||
        (fieldPosition === previousFieldPosition && fieldPosition === nextFieldPosition))
      ) {
        if (previousGroup && previousGroup.groupType === 'SPAN') {
          previousGroup.fields.push(field);
        } else {
          groups.push({ fields: [field], groupType: 'SPAN' });
        }
      }
      else {
        if (previousGroup) {
          if (previousGroup.groupType === 'COLUMN' && previousGroup.fields.length < 2) {
            previousGroup.fields.push(field);
            return groups;
          }
        }
        groups.push({ fields: [field], groupType: 'COLUMN' });
      }

      return groups;
    },
    [],
  );
  return columnFieldGroups;
};

export const ColumnFieldsWrapper = (props: ColumnFieldWrapperProps) => {
  const { customFields, variant = ComposableVariant.DEFAULT } = props
  const fieldPostions = useFieldPositions(customFields)
  const isTwoColumnsForm = fieldPostions.length > 1

  const sortCustomFields = customFields.filter(p => !p.isChildField)
    .sort((a, b) => multiTypeSort(a.order, b.order, { dir: 'asc' }))
  // Create an array of arrays of ColumnFieldGroup , where each ColumnFieldGroup is a group of fields
  // that should be displayed in the same row
  // object fields create a new item in the array so they are displayed in a new whole row

  const groups = getFieldGroups(sortCustomFields);

  return (
    <DNABox appearance="col" fill>
      <Iffy is={!isTwoColumnsForm}>
        <DNABox fill appearance="col" spacing="md" keepSpacingLastItem>
          {
          customFields.filter(field => !field.isChildField)
            .map(field => (
              <ComposableField
                variant={variant}
                key={field.fieldName}
                field={field}
              />
            ))
        }
        </DNABox>
      </Iffy>
      <Iffy is={isTwoColumnsForm}>
        {groups.map((group, index) => (
          <ColumnFields
            key={`groupFields-${index}`}
            groupCustomFields={group}
            variant={variant}
          />
        ))}
      </Iffy>
    </DNABox>
  )
}

const ColumnFields = (props: ColumnFieldProps) => {
  const { variant, groupCustomFields } = props
  if (!groupCustomFields) return null
  return (
    <DNABox appearance="col">
      <Iffy is={groupCustomFields.groupType === 'COLUMN'}>
        <DNABox>
          {groupCustomFields.fields.map((field, i) => (
            <DNABox
              key={field.id}
              appearance="col"
              fill
              style={{
                marginRight: i === 0 && groupCustomFields.fields.length > 1 ? 16 : 0,
                marginLeft: i > 0 ? 16 : 0,
                marginVertical: 8,
              }}
            >
              <ComposableField
                field={field}
                variant={variant}
              />
            </DNABox>
          ))}

        </DNABox>
      </Iffy>
      <Iffy is={groupCustomFields.groupType === 'SPAN'}>
        <DNABox fill appearance="col" spacing="md" keepSpacingLastItem>
          {groupCustomFields.fields.map((field) => (
            <ComposableField
              key={field.id}
              field={field}
              variant={variant}
            />
          ))}
        </DNABox>
      </Iffy>
    </DNABox>
  )
}
