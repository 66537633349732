import { CRMIntegration, Tenant } from '@alucio/aws-beacon-amplify/src/models'
import { observeSlice } from 'src/state/redux'
import { SliceState } from 'src/state/redux/slice/common'

import { Observable } from 'rxjs';
import { islastSyncLessThan24Hours } from './crmMachine';
import { REFRESH_DATA } from './crmMachineTypes';
import { CRM_LAST_SYNC_AT } from './util';

export type CRMAutoSync = Observable<REFRESH_DATA>

export const observableTenant = () => {
  return observeSlice<SliceState<Tenant>>(
    state => state.tenant,
    undefined,
  )
    .filter(tenants => {
      return tenants.records.length > 0 && !!tenants.records[0].config.crmIntegration
    })
    .map<{ type: 'SET_CONFIG' | 'RESET_CONFIG', payload: CRMIntegration | undefined }>(tenants => {
      const crmIntegration = tenants.records[0]?.config.crmIntegration
      return {
        type: crmIntegration ? 'SET_CONFIG' : 'RESET_CONFIG',
        payload: crmIntegration,
      }
    })
}

export const observableAutoSync = (): CRMAutoSync => {
  return new Observable(observer => {
    setInterval(() => {
      const lastSyncAt = localStorage.getItem(CRM_LAST_SYNC_AT)
      const isUserInAMeeting = window.location.pathname.startsWith('/meeting-presentation')
      if (lastSyncAt && !isUserInAMeeting) {
        const shouldAutoSync = !islastSyncLessThan24Hours(new Date(lastSyncAt))
        if (shouldAutoSync) {
          observer.next({
            type: 'REFRESH_DATA',
            payload: { trigger: 'SYSTEM' },
          })
        }
      }
    }, 60 * 60 * 1000)
  })
}

export default {}
