import React from 'react';
import { DNABox, Iffy, Stack } from '@alucio/lux-ui';
import InPersonActionBar from './InPersonActionBar';
import SlideRollWrapper from '../SharedComponents/SlideRollWrapper';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import PlayerWrapper from 'src/components/Presentation/PlayerWrapper';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';
import { ImageBackground } from 'react-native';
import TextSearchPanel from 'src/components/TextSearchPanel/TextSearchPanel';
import Notes from '../SharedComponents/Notes';

const InPersonContent:React.FC = () => {
  const { activePresentation } = useContent()
  const {
    meetingORM,
    slideRollVisible,
    setSlideRollVisible,
    textSearchVisible,
    setTextSearchVisible,
    openNotes,
    toggleMyNotes,
    toggleSpeakerNotes,
  } = useMeetingsState()

  if (!meetingORM) return null

  return (
    <DNABox testID="presentation-content" fill>
      <Stack
        anchor="bottom"
        style={{
          backgroundColor: 'black',
          flex: 1,
          width: '100%',
          height: '100%',
        }}
      >

        {/* CONTENT */}
        <Stack.Layer
          style={{
            flex: 1,
            width: '100%',
            height: '100%',
          }}
        >
          <DNABox fill style={{ position: 'absolute', width: '100%', height: '100%' }}>
            <Iffy is={!activePresentation}>
              <ImageBackground
                source={require('../../../../assets/images/beacon-presentation-bg.jpg')}
                style={{ flex: 1 }}
              />
            </Iffy>
            <Iffy is={activePresentation}>
              <PlayerWrapper.Highlighter
                isFullWindow
                meetingId={meetingORM.model.id}
                mode="INTERACTIVE"
                lockAspectRatio={false}
                isTabletMeetingMode
                isFromMeetings={true}
                highlighterVisible
              />
            </Iffy>
            {/* MY NOTES */}
            <Iffy is={openNotes.length}>
              <Notes
                openNotes={openNotes}
                toggleMyNotes={toggleMyNotes}
                toggleSpeakerNotes={toggleSpeakerNotes}
              />
            </Iffy>
          </DNABox>
        </Stack.Layer>

        {/* SLIDE ROLL */}
        <Stack.Layer anchor="topLeft" style={{ height: '100%' }}>
          <Iffy is={activePresentation}>
            <DNABox style={{ display: slideRollVisible ? 'flex' : 'none' }} fill>
              <SlideRollWrapper closeButtonOnPress={() => setSlideRollVisible(false)}/>
            </DNABox>
          </Iffy>
        </Stack.Layer>

        {/* TEXT SEARCH */}
        <Stack.Layer anchor="topLeft" style={{ height: '100%' }}>
          <DNABox fill>
            {textSearchVisible &&
              <TextSearchPanel
                onClose={() => { setTextSearchVisible(false) }}
                contentHookVariant="proxied"
              />
            }
          </DNABox>
        </Stack.Layer>

        {/* CONTROLS */}
        <Stack.Layer anchor="bottomRight">
          <InPersonActionBar />
        </Stack.Layer>
      </Stack>
    </DNABox>
  );
};

export default InPersonContent
