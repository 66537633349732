/* eslint-disable no-bitwise */
/** MODULES */
import { createMachine, matchesState, spawn, StateValue } from 'xstate'
import { assign } from '@xstate/immer'
import { current } from 'immer'

/** TYPES */
import * as Types from './crmMachineTypes'
import {
  CRM_AUTH_INFORMATION,
  CRM_STORAGE_UPDATE,
  INVALID_SESSION_ID,
  CRMUtil,
  CRM_LAST_SYNC_AT,
  errors,
} from './util'
import { Singleton as IndexDbCrm } from 'src/classes/CRM/CRMIndexedDB'
import { CRMAccount, Config, TableEnum } from 'src/classes/CRM/CRMIndexedDBTypes'
import configSyncMachine from './configSyncMachine'
import { observableAutoSync, observableTenant } from './observables'

export const ERR_TOKEN_EXPIRED = 'Your session has expired. Please log in again.'

const crmUtil = new CRMUtil();

const CRMDB = IndexDbCrm;

const crmMachine = () => createMachine<
  Types.crmContext,
  Types.crmEvents,
  Types.crmState
>(
  {
    predictableActionArguments: false,
    id: 'crmMachine',
    strict: false,
    context: {
      crmSession: undefined,
      accounts: [],
      configItem: undefined,
      crmConfig: undefined,
      crmSyncStatus: Types.CRMSyncStatus.IDLE,
      isAuthenticating: false,
      crmFormConfigActor: undefined,
      connectionStatus: Types.CRMConnectionStatus.DISCONNECTED,
      crmAvailabilityStatus: Types.CRMAvailabilityStatus.DISABLED,
      autoSyncActor: undefined,
      lastSyncedAt: undefined,
    },
    initial: 'idle',
    entry: assign((context) => {
      if (!context.crmConfig) {
        spawn(observableTenant(), 'tenant')
      }
      if (!context.autoSyncActor) {
        context.autoSyncActor = spawn(observableAutoSync(), 'crmAutoSync')
      }
    }),
    states: {
      idle: {
        description: 'The initial state of oAuthTokenHandler',
        entry: ['loadAuthFromLocalStorage', 'loadLastSyncAt', 'loadActors'],
        initial: 'idle',
        on: {
          REFRESH_DATA: {
            target: 'refreshingData.prepareIDB',
          },
          LOGIN_CRM: {
            target: 'login',
            actions: 'setLoginConfig',
          },
          LOGOUT_CRM: {
            target: 'logout',
          },
          SET_CONFIG: {
            target: 'setConfig',
          },
        },
        states: {
          idle: {},
        },
      },
      login: {
        entry: [
          'clearErrors',
          assign((context) => {
            context.isAuthenticating = true
            context.crmSyncStatus = Types.CRMSyncStatus.SYNCING
          }),
        ],
        exit: assign((context) => {
          context.isAuthenticating = false
        }),
        invoke: {
          id: 'doAuth',
          src: 'doAuth',
          onError: { actions: ['clearSession', 'logError'], target: '#crmMachine.showError' },
        },
        on: {
          LOAD_SESSION_DATA: {
            actions: ['readSessionData', 'syncFormConfig'],
            // a soon the user is logged in, we can refresh the data
            target: '#crmMachine.refreshingData.prepareIDB',
          },
          BACK_TO_IDLE: {
            target: '#crmMachine.idle',
            actions: ['clearSession'],
          },
        },
      },
      refreshingData: {
        description: 'Refresh the data from the crm',
        tags: ['REFRESH_DATA'],
        states: {
          prepareIDB: {
            invoke: {
              id: 'prepareIDB',
              src: 'prepareIDB',
              onDone: { target: '#crmMachine.refreshingData.syncData' },
              onError: {
                target: '#crmMachine.showError',
                actions: 'logError',
              },
            },
          },
          syncData: {
            invoke: {
              id: 'syncCrmData',
              src: 'syncCrmData',
              tags: ['REFRESH_DATA'],
              onDone: { target: '#crmMachine.idle', actions: ['syncData', 'setSyncingDone', 'setLatsSyncedAt'] },
              onError: [
                {
                  cond: 'isInvalidSession',
                  target: '#crmMachine.refreshToken',
                },
                {
                  target: '#crmMachine.showError',
                  actions: 'logError',
                },
              ],
            },
          },
        },
        on: {
          LOGOUT_CRM: {
            target: '#crmMachine.logout',
          },
        },
      },
      refreshToken: {
        entry: ['clearErrors'],
        tags: ['REFRESH_TOKEN'],
        invoke: {
          id: 'refreshToken',
          src: 'refreshToken',
          onDone: { target: '#crmMachine.refreshingData.prepareIDB' },
          onError: {
            target: '#crmMachine.showError',
            actions: ['logError', 'analitycsRefreshTokenError'],
          },
        },
      },
      showError: {
        entry: ['logError'],
        on: {
          LOGIN_CRM: {
            target: '#crmMachine.login',
          },
        },
      },
      setConfig: {
        entry: ['setConfig', 'loadLastSyncAt', 'loadActors'],
        after: {
          0: [{
            target: '#crmMachine.refreshingData.prepareIDB',
            cond: 'canAutoSync',
          }, {
            target: '#crmMachine.idle',
            actions: ['checkSyncStatus'],
          }],
        },
      },
      setLoginConfig: {
        entry: ['setConfig', 'loadLastSyncAt', 'loadActors', 'syncFormConfig'],
        after: {
          0: [{
            target: '#crmMachine.refreshingData.prepareIDB',
          }, {
            target: '#crmMachine.idle',
          }],
        },
      },
      logout: {
        entry: ['clearErrors', 'disconnecting'],
        invoke: {
          id: 'logOut',
          src: 'logOut',
          onDone: { target: '#crmMachine.idle', actions: ['clearSession', 'disconnected'] },
          onError: { target: '#crmMachine.showError' },
        },
        on: {
          LOAD_SESSION_DATA: {
            actions: 'readSessionData',
          },
        },
      },
    },
    on: {
      SET_FORM_SYNC_STATUS: {
        actions: 'setFormSyncStatus',
      },
      SET_FORM_SYNC_ERROR_STATUS: {
        actions: 'setFormSyncErrorStatus',
      },
    },
  },
  {
    // [Side Effects]
    actions: {
      analitycsRefreshTokenError: assign((context) => {
        analytics.track('CRM_TOKEN_INVALID', {
          category: 'CRM',
          integration: context.crmConfig?.crmIntegrationType,
          date: new Date().toISOString(),
        })
      }),
      clearSession: assign((context) => {
        context.crmSession = undefined
        context.connectionStatus = Types.CRMConnectionStatus.DISCONNECTED
        context.crmSyncStatus = Types.CRMSyncStatus.IDLE
      }),
      syncData: assign((context, event) => {
        const evt = event as Types.SYNC_DATA_EVENT
        context.accounts = evt.data.accounts
        context.configItem = evt.data.configItem
      }),
      clearErrors: assign((context) => {
        context.error = undefined
      }),
      loadAuthFromLocalStorage: assign((context) => {
        context.crmSession = crmUtil.loadAuthInformation()

        context.connectionStatus = context.crmSession?.accessToken
          ? Types.CRMConnectionStatus.CONNECTED
          : Types.CRMConnectionStatus.DISCONNECTED
      }),
      loadActors: assign((context) => {
        if (!context.crmConfig?.meetingSetting ||
          !context.crmConfig?.instanceUrl ||
          context.crmFormConfigActor ||
          context.connectionStatus !== Types.CRMConnectionStatus.CONNECTED
        ) {
          return;
        }
        const config = current(context.crmConfig);
        context.crmFormConfigActor = spawn(
          configSyncMachine(config),
          'crmFormConfigActor',
        )

        if (!islastSyncLessThan24Hours(context.lastSyncedAt)) {
          context.crmFormConfigActor.send('START')
        }
      }),
      loadLastSyncAt: assign((context) => {
        const lastSyncAt = localStorage.getItem(CRM_LAST_SYNC_AT)

        if (lastSyncAt === null) {
          return
        }

        context.lastSyncedAt = new Date(lastSyncAt)
      }),
      readSessionData: assign((context, event) => {
        const evt = event as Types.LOAD_SESSION_DATA
        context.crmSession = evt.payload.crmSession
      }),
      logError: assign((context, event) => {
        if (typeof event === 'object') {
          const evt = event as Types.CRM_ERROR

          if (evt.type === 'error.platform.refreshToken') {
            context.error = ERR_TOKEN_EXPIRED
            context.connectionStatus = Types.CRMConnectionStatus.DISCONNECTED
            context.crmSession = undefined
            return
          }

          context.error = evt.data.message || evt.data.errorCode || evt.data.errors?.[0]?.message
        }
      }),
      setConfig: assign((context, event) => {
        const evt = event as Types.SET_CONFIG
        context.crmConfig = evt.payload

        // TODO: Implement retry functionality for when the authentication token becomes invalid.
        context.crmAvailabilityStatus = context.crmConfig?.meetingSetting
          ? Types.CRMAvailabilityStatus.ENABLED
          : Types.CRMAvailabilityStatus.DISABLED
      }),
      setFormSyncStatus: assign((context, event) => {
        const evt = event as Types.SET_FORM_SYNC_STATUS
        const { status } = evt.data || {}

        if (status) {
          context.crmSyncStatus = status
        }
      }),
      setLatsSyncedAt: assign((context) => {
        localStorage.setItem(CRM_LAST_SYNC_AT, new Date().toISOString())
        context.lastSyncedAt = new Date()

        analytics.track('CRM_SYNC', {
          category: 'CRM',
          integration: context.crmConfig?.crmIntegrationType,
          lastSyncedAt: context.lastSyncedAt,
        })
      }),
      setFormSyncErrorStatus: assign((context, event) => {
        const evt = event as Types.SET_FORM_SYNC_ERROR_STATUS
        context.crmSyncStatus = evt.payload?.status || Types.CRMSyncStatus.ERROR
        context.error = evt.payload?.error || 'Error while syncing the form'
      }),
      disconnecting: assign((context) => {
        context.connectionStatus = Types.CRMConnectionStatus.DISCONNECTING
      }),
      disconnected: assign((context) => {
        context.connectionStatus = Types.CRMConnectionStatus.DISCONNECTED
      }),
      setSyncingDone: assign((context) => {
        context.crmSyncStatus = Types.CRMSyncStatus.SYNCED
      }),
      checkSyncStatus: assign((context) => {
        const lastSyncAt = localStorage.getItem(CRM_LAST_SYNC_AT)
        if (context.connectionStatus === Types.CRMConnectionStatus.CONNECTED && lastSyncAt) {
          context.crmSyncStatus = Types.CRMSyncStatus.SYNCED
        }
      }),
      syncFormConfig: assign((context) => {
        const config = current(context.crmConfig);
        if (!context.crmFormConfigActor && config) {
          context.crmFormConfigActor = spawn(
            configSyncMachine(config),
            'crmFormConfigActor',
          )

          context.crmFormConfigActor.send('START')
        }
      }),
    },
    services: {
      doAuth: (ctx) => async (callback) => {
        try {
          // trigger the popup
          await crmUtil.getCRMAuthUrl(true)
        } catch (e: any) {
          if (e.message === errors.not_allowed_pop_up) {
            throw new Error(errors.not_allowed_pop_up)
          }

          // should navigate go to error state
          throw new Error(JSON.stringify(e))
        }

        // we need to listen to the storage event
        // in order to know if the auth information has been updated
        const handleStorageChange = (e) => {
          if (e.key === CRM_AUTH_INFORMATION || e.type === CRM_STORAGE_UPDATE) {
            const event: Types.LOAD_SESSION_DATA = {
              type: 'LOAD_SESSION_DATA',
              payload: {
                crmSession: crmUtil.loadAuthInformation(),
              },
            }
            if (event.payload.crmSession.accessToken) {
              callback(event)

              analytics.track('CRM_CONNECT', {
                category: 'CRM',
                integration: ctx.crmConfig?.crmIntegrationType,
              })
            }
            else {
              const event: Types.EVT_BACK_TO_IDLE = {
                type: 'BACK_TO_IDLE',
              }
              callback(event)
            }
          }
        }

        // TODO BEAC-3746: try to handle this using the broad cast channel
        // listen for the auth information is persisted in the local storage
        window.addEventListener('storage', handleStorageChange);
        window.addEventListener(CRM_STORAGE_UPDATE, handleStorageChange);
        return () => {
          window.removeEventListener('storage', handleStorageChange);
          // clean the events
          window.removeEventListener(CRM_STORAGE_UPDATE, handleStorageChange);
        }
      },
      logOut: async (context) => {
        if (!context.crmSession) {
          // should navigate to the error state
          throw new Error('No session to log out')
        }

        await crmUtil.logOutCRM(context.crmSession?.accessToken)
        const event: Types.LOAD_SESSION_DATA = {
          type: 'LOAD_SESSION_DATA',
          payload: {
            crmSession: crmUtil.loadAuthInformation(),
          },
        }

        // clear index db
        await CRMDB.clearDB()
        return event
      },
      prepareIDB: async (context) => {
        if (!context.crmSession) {
          // should navigate to the error state
          throw new Error('No session to refresh data')
        }

        if (!context.crmSession.authInformation.userInfo.id) {
          throw new Error('No user id to refresh data')
        }

        if (!context.crmConfig) {
          throw new Error('No config present please reach your administrator')
        }

        const userId = context.crmSession.authInformation.userInfo.id.toString()
        const configItem = await CRMDB.getById<Config>(TableEnum.CONFIG_OBJECT, userId)

        const configObj: Config = {
          id: userId,
          name: context.crmSession.authInformation.userInfo.displayName,
          configBase64: base64Encode(JSON.stringify(context.crmConfig)),
        }

        // if the config object is not present, we need to create it
        // and we can assume it is the first time the user is logged in
        if (!configItem) {
          return await CRMDB.upsert(TableEnum.CONFIG_OBJECT, configObj)
        }

        // if the config is present, we need to check if the user has changed
        // if the hash is different, we need to clear the database and update the config
        if (
          configItem.id !== configObj.id ||
          configItem.configBase64 !== configObj.configBase64
        ) {
          analytics.track('CRM_CONFIG_CHANGE', {
            category: 'CRM',
            integration: context.crmConfig?.crmIntegrationType,
          })

          await CRMDB.clearDB()
          return await CRMDB.upsert(TableEnum.CONFIG_OBJECT, configObj)
        }
        else {
          return { type: 'DONE' }
        }
      },
      syncCrmData: async (context) => {
        if (!context.crmSession) {
          // should navigate to the error state
          throw new Error('No session to refresh data')
        }

        if (!context.crmConfig) {
          throw new Error('No config present please reach your administrator')
        }

        const userId = context.crmSession.authInformation.userInfo.id.toString()
        const configItem = await CRMDB.getById<Config>(TableEnum.CONFIG_OBJECT, userId)

        // https://trailhead.salesforce.com/trailblazer-community/feed/0D54S00000A8KBjSAN
        // On regards og how to check if a token is still valid
        // get accounts from the crm
        const accountObj = await crmUtil.getAccountData(context.crmConfig, configItem.lastSyncTime)

        if (!accountObj) {
          throw new Error('No accounts found')
        }

        const updatedConfigObject = { ...configItem, lastSyncTime: accountObj.lastSyncTime }

        if (context.crmConfig.meetingSetting) {
          context.crmFormConfigActor?.send('START')
        }

        if (accountObj.records.length === 0) {
          console.warn('No accounts to sync')

          await CRMDB.upsert(TableEnum.CONFIG_OBJECT, updatedConfigObject)

          return {
            accounts: await CRMDB.getAll<CRMAccount>(TableEnum.ACCOUNT),
            configItem: updatedConfigObject,
          }
        }

        await CRMDB.upsert(TableEnum.ACCOUNT, accountObj.records)
        await CRMDB.upsert(TableEnum.CONFIG_OBJECT, updatedConfigObject)

        return {
          accounts: await CRMDB.getAll<CRMAccount>(TableEnum.ACCOUNT),
          configItem: updatedConfigObject,
        }
      },
      refreshToken: async (context) => {
        if (!context.crmSession) {
          // should navigate to the error state
          throw new Error('No session to refresh data')
        }

        return await crmUtil.refreshTokenCRM(context.crmSession.accessToken, context.crmSession.refreshToken)
      },
    },
    // [Conditional Checks]
    guards: {
      isInvalidSession: (_, event) => {
        const evt = event as Types.CRM_ERROR
        return evt.data.errorCode === INVALID_SESSION_ID || evt.data.errorCode === INVALID_SESSION_ID
      },
      isCRMEnabled: (context) => {
        return context.connectionStatus === Types.CRMConnectionStatus.CONNECTED
      },
      canAutoSync: (context) => {
        const isConnected = context.connectionStatus === Types.CRMConnectionStatus.CONNECTED

        const lastSyncTimeWithin24Hours = islastSyncLessThan24Hours(context.lastSyncedAt)

        return isConnected && !lastSyncTimeWithin24Hours
      },
    },
  },
)

export const islastSyncLessThan24Hours = (lastSyncTime) => {
  if (!lastSyncTime || lastSyncTime === null) {
    return true
  }

  const now = new Date()
  const diff = now.getTime() - lastSyncTime.getTime()
  const hours = Math.floor(diff / (1000 * 60 * 60))

  return hours < 24
}

// base 64 encode
const base64Encode = (data: string) => {
  const buff = Buffer.from(data, 'utf-8')
  return buff.toString('base64')
}

export type CRMStatus = Pick<Types.CRMStateValue, 'value'> & {
  matches: (value: StateValue) => ReturnType<typeof matchesState>
}

export type crmMachineType = ReturnType<typeof crmMachine>

export default crmMachine
