import React, { useMemo } from 'react'
import { batch, useDispatch } from 'react-redux'
import im from 'immer'

import { Dispatch } from 'redux'
import { FolderItemORM, CustomDeckORM } from 'src/types/types'

import { GenericToast, ToastOrientations, useToast } from '@alucio/lux-ui'
import { ToastActions } from '@alucio/lux-ui/lib/components/Toast/useToast'
import { folderActions } from 'src/state/redux/slice/folder'
import DNADocumentAddToFolder from '../Modal/DNADocumentAddToFolder'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import {
  EDITOR_TYPE,
  presentationBuilderActions,
} from 'src/state/redux/slice/PresentationBuilder/PresentationBuilder'
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal'
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings'

import { API, graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import {
  downloadCustomDeckAsPdfFile,
} from '@alucio/aws-beacon-amplify/src/graphql/mutations'
import { download } from 'src/screens/Documents/Export'
import { getCustomDeckLambda } from '@alucio/aws-beacon-amplify/src/graphql/queries';
import { GetCustomDeckLambdaQuery } from '@alucio/aws-beacon-amplify/src/API';
import { customDeckActions } from 'src/state/redux/slice/customDeck';
import { getRootFolderORM } from 'src/state/redux/selector/folder';
import DNACustomDeckDeleteModal from '../Modal/DNACustomDeckDelete';

export type CustomDeckContextOptions = {
  [key in keyof typeof CustomDeckContextActions]: {
    icon: string,
    title: CustomDeckContextActions,
    onPress: (
      d: CustomDeckORM,
      dispatch: Dispatch,
      toast: ToastActions,
      folderItemORM?: FolderItemORM,
      isTablet?: boolean,
      context?: string,
      isOnline?: boolean,
      currentUser?: string,
    ) => () => void
  }
}

export type BindCustomDeckContextActions = {
  [key in keyof typeof CustomDeckContextActions]: (d: CustomDeckORM, f?: FolderItemORM, c?: string) => () => void
}

export enum CustomDeckContextActions {
  addToFolder = 'Add to folder',
  downloadAsPDF = 'Download as PDF',
  duplicate = 'Duplicate',
  edit = 'Edit',
  delete = 'Delete',
  present = 'View'
}

export type CustomDeckActions = (keyof typeof CustomDeckContextActions)

export const useDNACustomDeckActions = (): BindCustomDeckContextActions => {
  const dispatch = useDispatch()
  const toast = useToast()
  const { deviceMode, isOnline } = useAppSettings()
  const bound = useMemo(() => im(customDeckContextOptions, draft => {
    for (const action in customDeckContextOptions) {
      const actionTyped = action as keyof typeof CustomDeckContextActions
      draft[action] = (
        customDeckORM: CustomDeckORM,
        folderItemORM: FolderItemORM,
        context?: string, // indicates from where this action was done for tracking purposes
      ) => customDeckContextOptions[actionTyped].onPress(
        customDeckORM,
        dispatch,
        toast,
        folderItemORM,
        deviceMode === DeviceMode.tablet,
        context,
        isOnline,
      )
    }
  }) as unknown as BindCustomDeckContextActions,
  [dispatch, toast],
  )

  return bound
}

export const customDeckContextOptions: CustomDeckContextOptions = {
  addToFolder: {
    icon: 'folder-plus',
    title: CustomDeckContextActions.addToFolder,
    onPress: (_, dispatch, toast, folderItemORM) => () => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (props) => (
          <DNADocumentAddToFolder
            {...props}
            itemORM={folderItemORM!}
            toast={toast}
          />
        ),
      }),
      )
    },
  },
  edit: {
    icon: 'pencil',
    title: CustomDeckContextActions.edit,
    onPress: (currentCustomDeck, dispatch, _, folderItemORM, __, context, isOnline) => async () => {
      let customDeck;

      // The Custom deck culd be updated from the lasted time that we get all the shared folders
      // If that is the case we need to refresh the store
      // Only applies for the collaborators due the owner is able to get the changes inmediately
      // because it is subscribed to DATASTORE
      if (isOnline && currentCustomDeck.meta.permissions?.isCollaborator) {
        const rootFolder = folderItemORM?.relations.parentORM
          ? getRootFolderORM(folderItemORM?.relations.parentORM)
          : folderItemORM?.relations.parentORM
        const { data } = await API.graphql(graphqlOperation(getCustomDeckLambda, {
          customDeckId: currentCustomDeck.model.id,
          folderId: folderItemORM?.relations.parentORM?.model.id,
          rootFolderId: rootFolder?.model.id,
        })) as GraphQLResult<GetCustomDeckLambdaQuery>;

        if (!data || !data.getCustomDeckLambda) {
          throw new Error('Could not find custom deck')
        }

        customDeck = data?.getCustomDeckLambda;
      }

      batch(() => {
        customDeck && dispatch(customDeckActions.upsert(customDeck))
        dispatch(presentationBuilderActions.openPresentationBuilderEdit({
          customDeckId: currentCustomDeck.model.id,
          folderItemORM,
          targetFolder: folderItemORM?.relations.parentORM!,
          context,
          editorType: currentCustomDeck.meta.permissions?.isCollaborator
            ? EDITOR_TYPE.COLLABORATOR
            : EDITOR_TYPE.OWNER,

        }));
      })
    },
  },
  delete: {
    icon: 'trash-can-outline',
    title: CustomDeckContextActions.delete,
    onPress: (_, dispatch, toast, folderItemORM) => () => {
      const targetFolderId = folderItemORM?.relations.parentORM?.model.id

      if (!targetFolderId) {
        console.error('Could not determine the current folder path')
        return;
      }

      // NOTE: currently we are not removing the custom deck from the DB
      // we are only removing the reference in the folderItem
      if (folderItemORM) {
        analytics?.track('CUSTOM_DELETE', {
          action: 'DELETE',
          category: 'CUSTOM',
          customDeckId: folderItemORM.model.id,
        })
        dispatch(DNAModalActions.setModal(
          {
            isVisible: true,
            allowBackdropCancel: false,
            component: (props) => (
              <DNACustomDeckDeleteModal
                {...props}
                folderItemORM={folderItemORM}
                toast={toast}
              />
            ),
          },
        ))
      } else {
        console.error('Could not determine the current personalized file to delete')
      }
    },
  },
  duplicate: {
    icon: 'content-copy',
    title: CustomDeckContextActions.duplicate,
    onPress: (_, dispatch, __, folderItemORM) => () => {
      const targetFolder = folderItemORM?.relations.parentORM

      if (!targetFolder) {
        console.error('Could not determine the current folder path')
        return;
      }

      if (!folderItemORM) {
        console.error('Could not determine the current custom deck')
        return;
      }

      dispatch(
        // @ts-ignore
        folderActions.duplicateItem({
          targetFolderORM: targetFolder,
          itemToDupe: folderItemORM,
        },
        ),
      )
    },
  },
  present: {
    icon: 'eye-outline',
    title: CustomDeckContextActions.present,
    onPress: (customDeckORM, dispatch, __, folderItemORM) => () => {
      for (const group of customDeckORM.meta.customDeckGroups) {
        if (group.model.visible && group.pages[0]) {
          dispatch(
            contentPreviewModalActions.setModalVisibility({
              documentVersionId: group.pages[0].documentVersionORM.model.id,
              folderItemORM: folderItemORM,
              content: folderItemORM,
              isFullWindow: true,
              fromEllipsis: true,
              isOpen: true,
            },
            ))
          break;
        }
      }
    },
  },
  downloadAsPDF: {
    icon: 'file-pdf-box',
    title: CustomDeckContextActions.downloadAsPDF,
    onPress: (customDeck, _, toast, folderItemORM) => async () => {
      toast.add(
        <GenericToast
          title="Generating PDF download..."
          status="information"
        />,
        ToastOrientations.TOP_RIGHT,
      )
      try {
        const { data } = await API.graphql(
          graphqlOperation(downloadCustomDeckAsPdfFile, {
            customDeckId: customDeck.model.id,
          }),
        ) as { data: { downloadCustomDeckAsPdfFile: string } };

        const title = folderItemORM?.meta.title
        download(data.downloadCustomDeckAsPdfFile, `${title?.replace('.pptx', '')}.pdf`)
      } catch (e) {
        console.warn(`Error downloading the pdf file for this document id ${customDeck.model.id}`, e)
      }
    },
  },
}
