import React from 'react';
import { DNACard, DNAText } from '@alucio/lux-ui';
import { FlatList, StyleSheet } from 'react-native';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import DocumentOpenedPresentationRow from './DocumentOpenedPresentationRow';
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import { LoadedPresentation } from 'src/state/context/ContentProvider/ContentProvider';

enum OpenedPresentationVariantOptions {
  default,
  inPersonActionBar
}
export type OpenedPresentationsVariantOption = keyof typeof OpenedPresentationVariantOptions
type OpenedPresentationsVariants = Record<OpenedPresentationsVariantOption, React.ElementType>
type OpenedPresentationsVariantStylesheets = Record<
  OpenedPresentationsVariantOption,
  StyleSheet.NamedStyles<{[keys:string]:StyleSheet}>
  >

interface OpenedPresentationProps {
  variant?: OpenedPresentationsVariantOption
}
interface RenderItem {
  item:LoadedPresentation,
  variant?:OpenedPresentationsVariantOption
}

const openedPresentationsVariantStylesheets:OpenedPresentationsVariantStylesheets = {
  default: StyleSheet.create({
    panel: {
      backgroundColor: colors['color-gray-900'],
      padding: 24,
    },
  }),
  inPersonActionBar: StyleSheet.create({
    panel: {
      backgroundColor: colors['color-gray-900'],
    },
  }),
}

const RenderItem:React.FC<RenderItem> = (
  {
    item,
    variant = 'default',
  },
) => {
  const {
    removePresentation,
    activePresentation,
    changeActivePresentation,
  } = useContent()
  const {
    meetingORM,
    setCurrentTab,
    setPresentationControlsVisible,
  } = useMeetingsState()

  const handleShowPresentation = (itemId: string) => {
    changeActivePresentation(itemId)
    meetingORM?.model.type === 'VIRTUAL'
      ? setCurrentTab('PRESENTING')
      : setPresentationControlsVisible(false)
  }

  return (<DocumentOpenedPresentationRow
    removePresentation={removePresentation}
    variant={variant}
    activePresentation={activePresentation}
    changeActivePresentation={handleShowPresentation}
    item={item}
  />)
}

/** SECTION: VARIANTS */
const DefaultVariant: React.FC = () => {
  const { presentations } = useContent()

  return (
    <>
      <DNAText h5 style={{ color: colors['color-text-white'], marginBottom: 20 }}>
        You can open multiple files and quickly switch between them.
      </DNAText>

      { presentations.length
        ? <FlatList
            keyExtractor={(item) => item.presentable.id}
            data={presentations}
            initialNumToRender={10}
            renderItem={({ item }) => <RenderItem item={item}/>}
            contentContainerStyle={{ borderRadius: 8 }}
        />
        : <DNAEmpty
            emptyVariant={EmptyVariant.MeetingsOpenedPresentationsEmpty}
            variant="default"
        />
      }
    </>
  )
}

const InPersonActionBarVariant: React.FC = () => {
  const { presentations } = useContent()

  return (
    presentations.length
      ? <FlatList
          keyExtractor={(item) => item.presentable.id}
          data={presentations}
          initialNumToRender={10}
          renderItem={({ item }) => <RenderItem item={item} variant="inPersonActionBar" />}
          contentContainerStyle={{ borderRadius: 8 }}
      />
      : null
  )
}
/** !SECTION: VARIANTS */

const openedPresentationsVariants:OpenedPresentationsVariants = {
  default: DefaultVariant,
  inPersonActionBar: InPersonActionBarVariant,
}

const OpenedPresentations: React.FC<OpenedPresentationProps> = (
  { variant = 'default' },
) => {
  const CurrentVariant = openedPresentationsVariants[variant]
  const { panel } = openedPresentationsVariantStylesheets[variant]
  return (
    <DNACard style= {panel}>
      <CurrentVariant />
    </DNACard>
  )
}

export default OpenedPresentations
