import { TextWithHighlights } from '@alucio/aws-beacon-amplify/src/API';
import { DNAText } from '@alucio/lux-ui';
import { DNATextProps } from '@alucio/lux-ui/src/components/type/DNAText/DNAText';
import React from 'react';

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const highlightText = (
  text : string,
  shouldBeInBold: string,
  status?: DNATextProps['status'],
  numberOfLines?: number,
) => {
  const parts = text.split(new RegExp(`(${escapeRegExp(shouldBeInBold)})`, 'gi'));
  return (
    <DNAText numberOfLines={numberOfLines}>
      {parts.map((item, index) => (
        <DNAText key={index} bold={item.toLowerCase() === shouldBeInBold.toLowerCase()} status={status}>{item}</DNAText>
      ),
      )}
    </DNAText>
  )
}

function getHighlightedWords(text: string, highlights: TextWithHighlights['highlights']) {
  const res = new Set<string>();
  const escapedRes = new Set<string>();

  if (!highlights) {
    return [[], []];
  }

  for (let i = 0; i < highlights.length; i++) {
    res.add((text.substring(highlights[i].beginOffset, highlights[i].endOffset)));
    escapedRes.add(escapeRegExp(text.substring(highlights[i].beginOffset, highlights[i].endOffset)));
  }

  return [[...res], [...escapedRes]];
}

const renderTextWithHighlight = (
  textWithHighlight: TextWithHighlights, props: DNATextProps) => {
  if (!textWithHighlight.text) {
    return null;
  }

  const { numberOfLines, testID, ...rest } = props;
  // trims line breaks, spaces, tabs, etc so that the snippet would be in one line like in the mocks.
  const trimmedSnippet = textWithHighlight.text.replace(/(?:\r\n|\r|\n)/g, ' ');

  const [highlightedWords, highlightedWordsEscaped] =
    getHighlightedWords(textWithHighlight.text, textWithHighlight.highlights);

  if (!highlightedWords.length) {
    return <DNAText testID={testID} numberOfLines={numberOfLines} {...rest}>{trimmedSnippet}</DNAText>;
  }

  const regex = new RegExp(`(${highlightedWordsEscaped.sort((a, b) => b.length - a.length ).join('|')})`, 'gi');

  const tokens = trimmedSnippet.split(regex);

  return (
    <DNAText numberOfLines={numberOfLines} testID={testID}>
      {tokens.map((token, index) => {
        return (
          <DNAText key={index} bold={highlightedWords.includes(token)} {...rest}>{token}</DNAText>
        )
      })}
    </DNAText>
  )
}

export {
  highlightText,
  renderTextWithHighlight,
}
