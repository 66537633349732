import { Dispatch } from 'redux';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Logger } from '@aws-amplify/core';
import { CRMSubmitMeetingPayload, CRMSubmitResponseReferences, SYNC_STATUS } from 'src/classes/CRM/CRMIndexedDBTypes';
import { MeetingORM } from 'src/types/orms';
import { meetingActions } from 'src/state/redux/slice/meeting';
import {
  Attendee,
  AttendeeType,
  ContentPresented,
  CRMMeetingRecordInfo,
  CrmSyncStatus,
  CustomValues,
  Meeting, MeetingAttendeeStatus,
  MeetingStatus,
  ObjectRecordStatus,
} from '@alucio/aws-beacon-amplify/src/models';
import { formToModel } from 'src/components/CustomFields/ComposableFormUtilities';
import { MEETING_SUBMIT_TYPE } from 'src/components/Meeting/AddMeetingProvider';
import { useGetFormConfigFromIndexDB } from 'src/state/redux/selector/crm';
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import { useUserTenant } from 'src/state/redux/selector/user';
import { CRMHandler } from 'src/classes/CRM/CRMHandler';
import { FormValuesType } from 'src/components/CustomFields/ComposableForm';
import { getPreprocessedMeetingForm, SeparatedFields } from './saveMeetingUtilities';
import { useCustomDeckORMMap } from 'src/state/redux/selector/folder';

const logger = new Logger('Save meeting handler', 'DEBUG')

export interface SubmitMeetingProps {
  contentPresented?: ContentPresented[];
  formValues: FormValuesType;
  meetingORM: MeetingORM;
  submitType?: MEETING_SUBMIT_TYPE;
}

// GIVEN THE AMOUNT OF CONDITIONS, THIS OBJECT WILL BE PASSED ACROSS FUNCTIONS
interface SubmitMeetingPayload extends SubmitMeetingProps {
  formValuesAttendees: FormValuesType[];
  crmSubmitResponse?: CRMSubmitResponseReferences;
  dispatch: Dispatch;
  saveToCRM: boolean;
  canUseMeetingCRMFields: boolean;
  hasCRMConfig: boolean;
  separatedCustomFields: SeparatedFields;
}

export interface AttendeeForm extends Attendee {
  crmValues?: FormValuesType;
}

// GIVEN THE AMOUNT OF INFORMATION THAT'S REQUIRED TO SAVE A MEETING AND AVOID
// HAVING THE PARENT COMPONENT HANDLING THEM ALL, THIS HOOK WILL RETURN THE
// SUBMITMEETING FUNCTION AND ALSO USE THE REQUIRED HOOKS TO GET SOME INF
export const useSaveMeeting = () => {
  const rawCRMFormConfig = useGetFormConfigFromIndexDB();
  const tenant = useUserTenant();
  const hasCRMConfig = !!tenant?.config.crmIntegration?.meetingSetting;
  const indexedCustomDecks = useCustomDeckORMMap();
  const { canUseMeetingCRMFields, crmIntegrationType } = useCRMStatus();
  const memoizedSubmit = useCallback((payload: SubmitMeetingProps) =>
    submitMeeting(payload), [rawCRMFormConfig, canUseMeetingCRMFields]);
  const dispatch = useDispatch();

  async function submitMeeting(submitMeetingPayload: SubmitMeetingProps): Promise<void> {
    logger.debug('Submitting meeting.', submitMeetingPayload);
    const { meetingORM, submitType = MEETING_SUBMIT_TYPE.DEFAULT } = submitMeetingPayload;
    const saveToCRM = [MEETING_SUBMIT_TYPE.SUBMIT_LOCK_TO_CRM, MEETING_SUBMIT_TYPE.SAVE_TO_CRM].includes(submitType);
    const {
      startTime,
      endTime,
      formValuesAttendees,
      separatedCustomFields,
    } = getPreprocessedMeetingForm({
      ...submitMeetingPayload,
      crmIntegrationType,
      hasCRMConfig,
    });

    let crmSubmitResponse: CRMSubmitResponseReferences | undefined;
    let errorOnSubmit = false;

    // ** CRM SUBMIT ** //
    if (saveToCRM && hasCRMConfig && canUseMeetingCRMFields && rawCRMFormConfig) {
      const crmCallId = meetingORM.model.crmRecord?.crmCallId;
      try {
        crmSubmitResponse = await performCRMSubmit({
          mainCrmValues: separatedCustomFields.crmValues,
          startTime,
          endTime,
          mainCrmRecordId: crmCallId,
          formValuesAttendees: formValuesAttendees as unknown as AttendeeForm[],
          formSettings: rawCRMFormConfig,
          submitType,
          tenant,
          indexedCustomDecks,
          contentPresented: submitMeetingPayload.contentPresented,
          meetingORM,
        });
      } catch (e) {
        errorOnSubmit = true;
        logger.debug('An error occurred while submitting to CRM', e);
      }
      logger.debug('CRM Submit Response', crmSubmitResponse);

      analytics.track(submitType === MEETING_SUBMIT_TYPE.SUBMIT_LOCK_TO_CRM ? 'MEETING_SUBMITTED' : 'MEETING_SYNC', {
        category: 'MEETING',
        meetingID: meetingORM.model.id,
        meetingStatus: meetingORM.model.status,
        mainCrmRecordId: crmCallId,
      });
    }

    // ** BEACON SAVE ** //
    const saveMeetingPayload: SubmitMeetingPayload = {
      dispatch,
      separatedCustomFields,
      saveToCRM,
      hasCRMConfig,
      crmSubmitResponse,
      formValuesAttendees,
      canUseMeetingCRMFields,
      ...submitMeetingPayload,
    };
    await saveBeaconMeeting(saveMeetingPayload);

    // CHECK IF THERE'S AN ERROR
    const hasErrors = Object.values(crmSubmitResponse || {}).some(({ syncStatus }) => syncStatus === SYNC_STATUS.ERROR);
    if (hasErrors || errorOnSubmit) {
      throw Error('An error occurred while submitting to CRM');
    }
  }

  return memoizedSubmit;
};

function saveBeaconMeeting(saveMeetingPayload: SubmitMeetingPayload): void {
  const {
    dispatch,
    meetingORM,
    separatedCustomFields,
    submitType = MEETING_SUBMIT_TYPE.DEFAULT,
    hasCRMConfig,
    contentPresented,
  } = saveMeetingPayload;

  const startTime = separatedCustomFields.beaconInternalValues.startTime as string;
  const endTime = separatedCustomFields.beaconInternalValues.endTime as string | undefined;
  const title = separatedCustomFields.beaconInternalValues.title as string;

  // CREATES / UPDATES THE MAIN CRM RECORD AND PER ATTENDEE
  const { crmRecord, attendees } = getFormattedCRMRecords(saveMeetingPayload);
  const customValues = hasCRMConfig ? meetingORM.model.customValues
    : formToModel(separatedCustomFields.customFieldValues)
  const mergeCustomValues = hasCRMConfig
    ? customValues
    : mergeRemovedObjectRecordValues(meetingORM.model.customValues || [], customValues || [])

  const payload: Meeting = {
    ...meetingORM.model,
    status: submitType === MEETING_SUBMIT_TYPE.SUBMIT_LOCK_TO_CRM ? MeetingStatus.LOCKED : meetingORM.model.status,
    startTime: new Date(startTime || meetingORM.model.startTime).toISOString(),
    // IN CASE THE SAVE COMES FROM AN ONGOING MEETING (WHICH DOESN'T HAVE AN ENDTIME YET)
    endTime: endTime ? new Date(endTime).toISOString() : undefined,
    title,
    contentPresented: contentPresented || [...meetingORM.model.contentPresented],
    crmRecord,
    customValues: mergeCustomValues,
    attendees,
  };

  dispatch(meetingActions.updateMeeting(meetingORM.model, payload));
}

interface FormattedCRMRecordsResponse {
  attendees: Attendee[],
  crmRecord?: CRMMeetingRecordInfo
}

// ** RETURNS THE ATTENDEES WITH THEIR CRM RECORDS AND THE MAIN CRM MEETING RECORD ** //
function getFormattedCRMRecords(saveMeetingPayload: SubmitMeetingPayload): FormattedCRMRecordsResponse {
  const {
    hasCRMConfig,
    meetingORM,
    formValuesAttendees,
    crmSubmitResponse,
    saveToCRM,
    separatedCustomFields,
    canUseMeetingCRMFields,
  } = saveMeetingPayload;

  if (!hasCRMConfig || !canUseMeetingCRMFields) {
    return {
      crmRecord: meetingORM.model.crmRecord,
      attendees: getFormattedAttendees(formValuesAttendees as unknown as Attendee[], meetingORM) || [],
    };
  }

  const formattedCustomValues = formToModel(separatedCustomFields.crmValues);
  const mainCrmRecord = getCRMRecord(
    formattedCustomValues,
    'main-record',
    saveToCRM,
    crmSubmitResponse,
    meetingORM.model.crmRecord);
  const attendeesWithCrmRecords = formValuesAttendees.map((formValueAttendee) => {
    const { crmValues = {}, ...rest } = formValueAttendee;
    const attendee = rest as unknown as Attendee;
    const isMainAttendee = attendee.attendeeType === AttendeeType.PRIMARY;
    const formattedCrmValues = formToModel(crmValues as unknown as FormValuesType);

    return {
      ...attendee,
      crmRecord: getCRMRecord(
        formattedCrmValues || [],
        isMainAttendee ? 'main-record' : attendee.id,
        saveToCRM,
        crmSubmitResponse,
        attendee.crmRecord),
    };
  });

  return {
    crmRecord: mainCrmRecord,
    attendees: getFormattedAttendees(attendeesWithCrmRecords, meetingORM, crmSubmitResponse) || [],
  };
}

// ** RETURNS THE CRM RECORD OBJECT FORMATTED (INCLUDING THE CRM ID) ** //
function getCRMRecord(
  crmValues: CustomValues[],
  referenceId: string,
  submittedToCRM: boolean,
  crmSubmitResponse?: CRMSubmitResponseReferences,
  crmRecord?: CRMMeetingRecordInfo): CRMMeetingRecordInfo | undefined {
  let crmId: string | undefined = crmRecord?.crmCallId;

  if (!crmId) {
    // GETS THE ID OF THE CRM RECORD'S SUBMIT RESPONSE
    crmId = crmSubmitResponse?.[referenceId]?.externalId;
  }

  // IF THERE'S A CHILD RECORD, THE ID FROM THE SUBMIT RESPONSE MUST BE SET TO THE BEACON RECORD
  const crmCustomValues: CustomValues[] = crmValues.map((crmValue) => {
    // IF IT'S A REGULAR FIELD, NO CHECK NEEDS TO BE DONE
    if (!crmValue.objectRecords?.length) {
      return crmValue;
    }

    // IF IT'S AN OBJECT RECORD (CHILD RECORD FROM CRM), THE ID OF THAT RECORD NEEDS TO BE SET
    return {
      ...crmValue,
      objectRecords: crmValue.objectRecords.map((objectRecord) => {
        const objectResponse = crmSubmitResponse?.[objectRecord.id];
        if (!objectResponse?.externalId) {
          return objectRecord;
        } else if (objectResponse.syncStatus === SYNC_STATUS.ERROR) {
          logger.debug('Object with error', objectResponse);
          return objectRecord;
        }

        return {
          ...objectRecord,
          externalId: objectRecord.externalId || objectResponse.externalId,
          syncStatus: CrmSyncStatus.SYNCED,
        };
      }),
    };
  });

  return {
    lastSyncedAt: submittedToCRM ? new Date().toISOString() : crmRecord?.lastSyncedAt,
    crmSyncStatus: submittedToCRM ? CrmSyncStatus.SYNCED : crmRecord?.crmSyncStatus,
    crmCallId: crmId,
    crmCustomValues,
  };
}

function mergeRemovedObjectRecordValues(modelCustomValues: CustomValues[],
  customValues: CustomValues[]): CustomValues[] {
  if (modelCustomValues.length === 0) return customValues;
  const result = [...customValues]
  modelCustomValues.filter((customValue) => customValue.objectRecords).forEach((customValue) => {
    const payloadCustomValue = result.find((payloadCustomValue) =>
      payloadCustomValue.fieldId === customValue.fieldId);
    if (!payloadCustomValue) {
      result?.push({
        ...customValue,
        objectRecords: customValue.objectRecords?.map((objectRecord) => {
          return { ...objectRecord, status: ObjectRecordStatus.REMOVED };
        }),
      });
    }
    else {
      customValue.objectRecords?.forEach((record) => {
        const objectRecord = payloadCustomValue.objectRecords?.find((objectRecord) =>
          objectRecord.id === record.id);
        if (!objectRecord) {
          payloadCustomValue?.objectRecords?.push({
            ...record,
            status: ObjectRecordStatus.REMOVED,
          });
        }
      });
    }
  });
  return result;
}

function getFormattedAttendees(
  attendees: Attendee[],
  meeting: MeetingORM,
  crmSubmitResponse?: CRMSubmitResponseReferences): Attendee[] {
  const removedAttendees = meeting?.model.attendees?.reduce<Attendee[]>((acc, attendee) => {
    // REMOVED IN THE PAST ATTENDEE
    if (attendee.status === MeetingAttendeeStatus.REMOVED) {
      acc.push(attendee);
      return acc;
    }

    const isRemoved = attendees.find(({ id }) => id === attendee.id) === undefined;

    // RECENTLY REMOVED ATTENDEE
    if (isRemoved) {
      let crmRecord = attendee.crmRecord;

      if (crmSubmitResponse?.[attendee.id] && crmRecord &&
        crmSubmitResponse[attendee.id].syncStatus === SYNC_STATUS.DELETED) {
        crmRecord = {
          ...crmRecord,
          crmSyncStatus: CrmSyncStatus.DELETED,
        }
      }

      acc.push({
        ...attendee,
        crmRecord,
        status: MeetingAttendeeStatus.REMOVED,
        updatedAt: new Date().toISOString(),
      });
    }

    return acc;
  }, []) || [];

  return [...attendees, ...removedAttendees];
}

// ** IN CHARGE OF CALLING THE APPROPRIATE ADAPTER TO SUBMIT TO CRM ** //
async function performCRMSubmit(payload: CRMSubmitMeetingPayload): Promise<CRMSubmitResponseReferences> {
  if (!payload.tenant.config.crmIntegration) {
    throw Error('No CRMConfig is defined.');
  }
  const crmHandlerInstance = CRMHandler(payload.tenant.config.crmIntegration);
  return crmHandlerInstance.Syncer().submitToCRM(payload);
}
