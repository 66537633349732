import React from 'react'
import { StyleSheet } from 'react-native'
import { DNABox } from '@alucio/lux-ui'

import { Action, usePresentationBuilderSharedResources } from '../PresentationEditor'
import { usePresentationBuilderState } from '../../state/PresentationBuilderStateProvider'
import { useAppSettings } from 'src/state/context/AppSettings'
import { PRESENTATION_BUILDER_VARIANT } from 'src/state/redux/slice/PresentationBuilder/PresentationBuilder'

const S = StyleSheet.create({
  buttonStyle: {
    paddingHorizontal:12,
    paddingVertical:4,
  },
  containerStyle: {
    borderBottomColor: '#F1F1F1',
    borderBottomWidth: 1,
    backgroundColor: 'white',
    height: 56,
  },
})

export const PrimaryActionButtons:React.FC = () => {
  const { getActionEls } = usePresentationBuilderSharedResources()
  const {
    selectedGroups,
    savePresentation,
    cancelPresentation,
    handleModeChange,
    builderMode,
    variant,
    isLocked,
  } = usePresentationBuilderState()

  const { isOnline } = useAppSettings()
  const allContentCached = selectedGroups.every(group => group.documentVersionORM?.meta.assets.isContentCached)

  const isRead = variant === PRESENTATION_BUILDER_VARIANT.READ

  const actions:Action[] = [
    {
      hidden: isRead,
      fill:true,
      label:builderMode === 'customization' ? 'Add Slides' : 'Expand',
      appearance:'filled',
      status:'tertiary',
      style: [S.buttonStyle, { marginHorizontal: 12 }],
      onPress: () => handleModeChange(),
      disabled: isLocked,
      testID: 'expanded-collapse-custom-deck-button',
    },
    {
      hidden: !isRead,
      iconLeft: builderMode === 'customization' ? 'arrow-collapse' : 'arrow-expand',
      fill:true,
      onPress: () => handleModeChange(),
      disabled: isLocked,
    },
    {
      label:'Cancel',
      appearance:'filled',
      status:'tertiary',
      style: [S.buttonStyle, { marginRight: 8 }],
      onPress: () => cancelPresentation(),
      testID: 'presentation-editor-cancel-button',
    },
    {
      label:'Save',
      appearance:'filled',
      status: 'primary',
      disabled: (
        isLocked ||
        !selectedGroups.length ||
        !selectedGroups.some(({ visible }) => visible) ||
        (!isOnline && !allContentCached) // or there is at least one visible group
      ),
      style:[S.buttonStyle, { marginRight: 12 }],
      onPress: () => savePresentation(),
      testID: 'presentation-editor-save-button',
    },
  ]

  const actionEls = getActionEls(actions)

  return (
    <DNABox
      style={S.containerStyle}
      alignY="center"
    >
      {actionEls}
    </DNABox>
  )
}
