import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNADivider, DNASpinner, DNAText } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import { drawerActions } from 'src/state/redux/slice/drawer';
import { useDispatch } from 'react-redux';

const style = StyleSheet.create({
  content: {
    backgroundColor: colors['color-gray-50'],
    padding: 24,
  },
  titleContainer: {
    height: 56,
    paddingLeft: 24,
    paddingRight: 24,
  },
  title: {
    color: colors['gray-600'],
  },
  footer: {
    backgroundColor: colors['color-text-white'],
    borderTopColor: colors['color-gray-50'],
    borderTopWidth: 1,
    height: 56,
  },
  loadingBox: {
    backgroundColor: colors['color-text-white'],
    borderRadius: 6,
  },
});

const AddMeetingHeaderDesktop = () => {
  const dispatch = useDispatch();
  const onCancel = () => {
    dispatch(drawerActions.toggle({}));
  }

  return (
    <DNABox appearance="col">
      <DNABox alignY="center" appearance="row" spacing="between" style={style.titleContainer}>
        <DNAText bold h5 style={style.title}>Meeting Event</DNAText>
        <DNABox>
          <DNAButton
            appearance="ghost"
            status="tertiary"
            size="md"
            padding="sm"
            iconLeft="close"
            onPress={onCancel}
          />
        </DNABox>
      </DNABox>
      <DNADivider />
    </DNABox>
  )
}

const AddMeetingHeaderTablet = () => {
  const dispatch = useDispatch();
  const onCancel = () => {
    dispatch(drawerActions.toggle({}))
  }

  return (
    <DNABox appearance="col">
      <DNABox alignY="center" appearance="row" spacing="md" childFill={2} style={style.titleContainer}>

        <DNAButton
          status="tertiary"
          appearance="ghost"
          iconLeft="close"
          onPress={onCancel}
        />

        <DNAText bold h5 style={style.title}>Meeting Event</DNAText>
        <DNABox alignX="end" />

      </DNABox>
      <DNADivider />
    </DNABox>
  )
}

type DeviceModeVariant = Record<DeviceMode, React.ElementType>
const deviceModeVariant:DeviceModeVariant = {
  desktop: AddMeetingHeaderDesktop,
  tablet: AddMeetingHeaderTablet,
}

const AddLoadingMeetingHeader = () => {
  const { deviceMode } = useAppSettings()
  const CurrentDeviceModeVariant = deviceModeVariant[deviceMode]

  return <CurrentDeviceModeVariant/>
}

interface LoadingMeetingProps {
  loadingText?: string;
}

export const LoadingMeeting = (props: LoadingMeetingProps) => {
  return (
    <DNABox appearance="col" fill spacing="between" childFill={1} childStyle={{ display: 'flex' }}>
      <AddLoadingMeetingHeader />
      <DNABox style={style.content} appearance="col" fill>
        <DNABox
          style={style.loadingBox}
          alignX="center"
          alignY="center"
          spacing="lg"
          appearance="col"
          fill
        >
          <DNASpinner size="medium" />
          <DNAText bold status="flatAlt">{props.loadingText || 'Loading...'}</DNAText>
        </DNABox>
      </DNABox>
      <DNABox style={style.footer} />
    </DNABox>
  );
};
