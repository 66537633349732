import { createMachine, sendParent } from 'xstate'
import { assign } from '@xstate/immer'
import * as Types from './configSyncMachineTypes'
import { SyncEntry, SYNC_ENTRY_STATUS } from 'src/classes/CRM/ISyncManifest'
import { CRMIntegration } from '@alucio/aws-beacon-amplify/src/models'
import { TableEnum } from 'src/classes/CRM/CRMIndexedDBTypes'
import { Singleton as IndexDbCrm } from 'src/classes/CRM/CRMIndexedDB'
import { CRMHandler } from 'src/classes/CRM/CRMHandler';
import { CRMSyncStatus, SET_CONTEXT_VARS } from './crmMachineTypes'

const CRMDB = IndexDbCrm;

// create a machine with the following configuration:
const configSyncMachine = (config: CRMIntegration) => createMachine<
  Types.crmConfigFormContext,
  Types.crmConfigFormsEvents,
  Types.crmConfigFormsState
>({
  id: 'configSync',
  initial: 'idle',
  predictableActionArguments: true,
  context: {
    hasBeenInitialized: false,
    // consider using a factory function to create the crmSync object
    crmSync: CRMHandler(config).Syncer(),
    pendingsEntries: [] as SyncEntry[],
    isFirstTimeSync: false,
  },
  states: {
    idle: {
      description: 'The machine is idle',
      on: {
        'START': [
          { target: 'setContextVars' },
        ],
      },
    },
    checkForPendingEntry: {
      description: 'check for pending entry',
      invoke: {
        id: 'checkForPendingEntry',
        src: 'checkForPendingEntry',
        onDone: [
          { target: 'processEntry', actions: 'setPendingEntries', cond: 'checkForPendingEntry' },
          { target: 'callConfigSyncComplete' },
        ],
        onError: { target: 'error', actions: 'logError' },
      },
    },
    initialize: {
      description: 'load manifest entries for tables from config',
      invoke: {
        src: 'initialize',
        onDone: { target: 'checkForPendingEntry', actions: 'setPendingEntries' },
        onError: { target: 'error', actions: 'logError' },
      },
    },
    setContextVars: {
      description: 'set context variables',
      invoke: {
        src: 'setContextVars',
        onDone: [
          { target: 'initialize', cond: 'isNotInitialized', actions: 'setContextVars' },
          { target: 'checkForPendingEntry', actions: 'setContextVars' },
        ],
        onError: { target: 'error', actions: 'logError' },
      },
    },
    processEntry: {
      description: 'process pending sync entry',
      onEntry: 'setParentSyncingStatus',
      invoke: {
        src: 'processEntry',
        onDone: [
          { target: 'checkForPendingEntry', cond: 'checkForPendingEntry' },
          { target: 'callConfigSyncComplete' },
        ],
        onError: { target: 'error', actions: 'logError' },
      },
    },
    callConfigSyncComplete: {
      description: 'call configSyncComplete',
      onEntry: 'setParentSyncingStatus',
      invoke: {
        id: 'configSyncComplete',
        src: 'configSyncComplete',
        onDone: { target: 'idle', actions: ['cleanUp', 'setParentFinishedSyncedStatus'] },
        onError: { target: 'error', actions: 'logError' },
      },
    },
    error: {
      onEntry: sendParent((context) => ({
        type: 'SET_FORM_SYNC_ERROR_STATUS',
        payload: { error: context?.error || 'error', status: CRMSyncStatus.ERROR },
      })),
      description: 'error',
      type: 'final',
    },
  },
}, {
  guards: {
    isNotInitialized: (context) => !context.hasBeenInitialized,
    checkForPendingEntry: (context) => context.pendingsEntries.length > 0,
  },
  services: {
    configSyncComplete: async (context) => {
      const syncEntries = await CRMDB.getAll<SyncEntry>('FORM_MANIFEST')
      const config = await context.crmSync.configSyncComplete(syncEntries)
      await CRMDB.upsert(TableEnum.FORM_SETTINGS, config)
    },
    initialize: async (context) => {
      const pendingsEntries = context.crmSync.initialize()
      return await Promise.all(pendingsEntries.map((entry) => CRMDB.addSyncEntry(entry)))
    },
    checkForPendingEntry: async () => {
      return await CRMDB.getEntryByStatus(SYNC_ENTRY_STATUS.PENDING)
    },
    processEntry: async (context) => {
      const entry = context.pendingsEntries?.[0]
      if (entry) {
        const entries = await context.crmSync.processManifestEntry(entry)
        await Promise.all(entries.map((entry) => CRMDB.addSyncEntry(entry)))
      }
    },
    setContextVars: async () => {
      const formSettings = await CRMDB.getAll<TableEnum.FORM_SETTINGS>('FORM_SETTINGS')
      return {
        isFirstTimeSync: formSettings.length === 0,
      }
    },
  },
  actions: {
    logError: assign((context, event) => {
      context.error = event
      console.error('error', event)
    }),
    cleanUp: assign((context) => {
      context.error = undefined
      context.hasBeenInitialized = false
    }),
    setPendingEntries: assign((context, event) => {
      const { data } = event as unknown as { data: SyncEntry[] | undefined}
      context.pendingsEntries = data || []
    }),
    setContextVars: assign((context, event) => {
      const { data } = event as unknown as SET_CONTEXT_VARS
      context.isFirstTimeSync = data?.isFirstTimeSync || false
      context.hasBeenInitialized = true
    }),
    setParentSyncingStatus: sendParent((context) => {
      // if there are no pending entries, then we are done syncing
      // this case woul be handled by the setParentFinishedSyncedStatus action
      if (context.pendingsEntries.length === 0) {
        return {
          type: '',
          data: {},
        }
      }

      return {
        type: 'SET_FORM_SYNC_STATUS',
        data: {
          status:
            context.isFirstTimeSync
              ? CRMSyncStatus.SYNCING
              : CRMSyncStatus.RE_SYNCING,
        },
      }
    }),
    setParentFinishedSyncedStatus: sendParent(() => {
      return {
        type: 'SET_FORM_SYNC_STATUS',
        data: {
          status: CRMSyncStatus.SYNCED,
        },
      }
    }),
  },
})

export default configSyncMachine
