import React, { useEffect, useMemo } from 'react'
import { Modal } from '@ui-kitten/components'
import { StyleSheet, View, ViewStyle, Image } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/state/redux';
import { DNABox, Stack, Iffy, DNASlider, InformationMessage } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { ORMTypes } from 'src/types/types';
import { isDocumentVersionORM, isFolderItemORM } from 'src/types/typeguards';
import { FileType } from '@alucio/aws-beacon-amplify/src/models'
import ContentProvider, { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import ContentPreviewModalStateProvider,
{ useContentPreviewModalState } from './State/ContentPreviewModalStateProvider';
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
import PlayerWrapper from 'src/components/Presentation/PlayerWrapper'
import ActionBar from './ActionBar';
import Header from './Header/Header';
import PreviewSlideRollConnector from './PreviewSlideRoll';
import Sidebar from './SideBar/SideBar';
import TextSearchPanel from '../TextSearchPanel/TextSearchPanel';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import useKeyPressedEventHandler, {
  KeyEventSettings,
} from 'src/hooks/useKeyPressedEventHandler/useKeyPressedEventHandler';
import MeetingPresentedMetaProvider, { useMeetingPresentedMeta } from './State/MeetingPresentedMetaProvider';
import EmptyStateScreen from './EmptyStateScreen';
import { AUTO_UPDATE_DEFAULT_DATE } from 'src/state/redux/slice/customDeck';

export const styles = StyleSheet.create({
  container: {},
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  mainWrapper: {
    backgroundColor: colors['color-text-white'],
    flex: 1,
    width: '100%',
    height: '100%',
  },
  modalContainer: {
    backgroundColor: colors['color-text-white'],
    width: '100vw',
    height: '100vh',
  },
  viewContainer: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
  },
  slideRoll: {
    display: 'none',
    maxWidth: 250,
    marginRight: 40,
  },
  playerWrapperContainer: {
    padding: 15,
  },
  webDocActionbar: {
    padding: 14,
    borderBottomWidth: 1,
    borderBottomColor: colors['color-gray-80'],
    backgroundColor: colors['color-text-white'],
  },
  loaderImage: {
    width: 200,
    height: 200,
  },
  InformationMessageContainer: {
    backgroundColor: colors['color-text-white'],
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: colors['color-gray-80'],
  },
});

type DeviceModeVariants = Record<DeviceMode, React.ElementType>
const deviceModeVariants: DeviceModeVariants = {
  desktop: Modal,
  tablet: View,
}

const WithContentProvider: React.FC = () => {
  const { addPresentation, activePresentation, nextStepPage, prevStepPage } = useContent()
  const { isPresentedSlideEmpty } = useMeetingPresentedMeta()

  const keyEventSettings: KeyEventSettings[] = useMemo(() => [{
    keyEvent: 'keydown',
    keys: ['ArrowLeft'],
    onAction: prevStepPage,
  }, {
    keyEvent: 'keydown',
    keys: ['ArrowRight', 'Space'],
    onAction: nextStepPage,
  }], []);

  useKeyPressedEventHandler(keyEventSettings);

  const {
    textSearchVisible, setTextSearchVisible,
    slideRollVisible, setSlideRollVisible,
    acknowledgeUpdate, isAcknowledgeClicked,
  } = useContentPreviewModalState()
  const modalState = useSelector((state: RootState) => state.contentPreviewModal)
  let orm;
  let isFolderItem;
  if (isDocumentVersionORM(activePresentation?.presentable.orm)) {
    orm = activePresentation?.presentable.orm
  } else if (isFolderItemORM(activePresentation?.presentable.orm)) {
    isFolderItem = true
    orm = activePresentation?.presentable.orm.relations.itemORM
  }
  const isWebDoc = orm?.model.type === FileType.WEB
  const isVideoDoc = orm?.model.type === FileType.MP4
  const isHTMLDoc = orm?.model.type === FileType.HTML
  const isCustomDeck = isFolderItemORM(activePresentation?.presentable.orm)
    ? activePresentation?.presentable.orm.model.type === ORMTypes.CUSTOM_DECK : false

  // ONLY SHOW ACTION BAR WHEN PRESENTED SLIDE IS NOT EMPTY AND
  // IF IT IS MEETING HISTORY, OR IT IS A CUSTOMDECK, OR IT IS NOT WEB, VIDEO, HTML DOCS
  const showActionBar = !isPresentedSlideEmpty &&
    (modalState.meetingId || isCustomDeck || !(isWebDoc || isVideoDoc || isHTMLDoc))
  const hideSearch = isWebDoc || isVideoDoc || isHTMLDoc

  useEffect(() => {
    // IF THE FILE IS FROM A MEETING, THE MEETINGPRESENTEDMETA
    // PROVIDER WILL HANDLE INITIALIZATION
    if (modalState.content && !modalState.meetingId) {
      addPresentation(modalState.content)
    }
  }, [modalState.content],
  )

  const handleAcknowledge = () => acknowledgeUpdate()
  const isSharedWithTheUser = isFolderItem && orm?.relations?.parentORM?.meta?.isSharedWithTheUser
  // This is needed for when the acknowledge is clicked in that case the ORM is not being updated
  const isCustomAcknowledged = isCustomDeck
    ? (orm?.model.autoUpdateAcknowledgedAt ?? AUTO_UPDATE_DEFAULT_DATE) !== AUTO_UPDATE_DEFAULT_DATE : false
  const isFolderItemAcknowledged = isFolderItem && !!orm?.model.updateAcknowledgedAt
  const hideAutoUpdateMessage = isCustomAcknowledged || isFolderItemAcknowledged || isSharedWithTheUser
  const showAutoUpdateBanner =
    !hideAutoUpdateMessage && isFolderItemORM(activePresentation?.presentable.orm) &&
    activePresentation?.presentable.orm?.meta?.hasAutoUpdatedItem

  return (
    <DNABox fill appearance="col">
      <DNABox>
        <Header />
      </DNABox>
      <Iffy is={showAutoUpdateBanner && !isAcknowledgeClicked}>
        <DNABox style={styles.InformationMessageContainer}>
          <InformationMessage
            text="This file was auto-updated to a new version"
            variance="warning"
            actionButtonFunction={handleAcknowledge}
            actionButtonText="Okay"
            style={{ margin: 0 }}
          />
        </DNABox>
      </Iffy>
      <Stack
        anchor="bottom"
        style={styles.mainWrapper}
      >
        <Stack.Layer
          style={styles.mainWrapper}
        >
          <DNABox fill appearance="row">
            {textSearchVisible &&
              <TextSearchPanel
                onClose={() => { setTextSearchVisible(false) }}
              />
            }
            <DNABox
              style={[styles.slideRoll, slideRollVisible && { display: 'flex' }]}
              fill
            >
              <PreviewSlideRollConnector closeButtonOnPress={() => setSlideRollVisible(false)} />
            </DNABox>
            <DNABox fill style={{ padding: 15 }}>
              <Iffy is={isPresentedSlideEmpty}>
                <EmptyStateScreen />
              </Iffy>
              <Iffy is={!isPresentedSlideEmpty}>
                {(modalState.isLoading || !activePresentation)
                  ? <DNABox
                      alignY="center"
                      alignX="center"
                      fill
                      style={{ backgroundColor: 'black' }}
                  >
                    <Image
                      testID="dna-loader"
                      style={styles.loaderImage}
                      source={require('../../../assets/images/LoaderDNA_dark.gif')}
                    />
                  </DNABox>
                  : <PlayerWrapper
                      isDarkMode={true}
                      isFullWindow={false}
                      lockAspectRatio={false}
                      meetingId={modalState.presentationId!}
                      mode="INTERACTIVE"
                      isCustomDeck={isCustomDeck}
                  />
                  }
              </Iffy>

            </DNABox>
            <Sidebar/>
          </DNABox>
        </Stack.Layer>
        <Stack.Layer anchor="bottom" style={{ marginBottom: 15 }}>
          <Iffy is={showActionBar}>
            <ActionBar hideSearch={hideSearch}/>
          </Iffy>
        </Stack.Layer>
      </Stack >
    </DNABox >
  )
}

interface DesktopDeviceModeProps {
  style?: ViewStyle,
  visible: boolean,
  backdropStyle: ViewStyle,
  onBackdropPress: () => void,
}
interface TabletDeviceModeProps {
  style?: ViewStyle,
}

type DeviceModeVariantProps = Record<DeviceMode, DesktopDeviceModeProps | TabletDeviceModeProps>

const ContentPreviewModalV2: React.FC = () => {
  const modalState = useSelector((state: RootState) => state.contentPreviewModal)
  const dispatch = useDispatch()
  const { deviceMode } = useAppSettings();

  const handleBackdropPress = () => {
    dispatch(contentPreviewModalActions.setModalVisibility({ isOpen: false, content: undefined }))
  }

  const deviceModeVariantProps: DeviceModeVariantProps = {
    desktop: {
      style: styles.modalContainer,
      visible: !!modalState.isOpen,
      backdropStyle: styles.backdrop,
      onBackdropPress: handleBackdropPress,
    },
    tablet: {
      style: styles.viewContainer,
    },
  }

  if (!modalState.isOpen) {
    return null;
  }

  const CurrentDeviceModeVariant = deviceModeVariants[deviceMode]
  const currentDeviceModeVariantProps = deviceModeVariantProps[deviceMode]
  const ModalComponent = (
    <CurrentDeviceModeVariant {...currentDeviceModeVariantProps}>
      <ContentProvider meetingId={modalState.presentationId}>
        <MeetingPresentedMetaProvider>
          <ContentPreviewModalStateProvider>
            <WithContentProvider />
          </ContentPreviewModalStateProvider>
        </MeetingPresentedMetaProvider>
      </ContentProvider>
    </CurrentDeviceModeVariant>
  );

  if (deviceMode === DeviceMode.tablet) {
    return (
      <DNASlider visible setVisible={() => {}}>
        {ModalComponent}
      </DNASlider>
    );
  }

  return ModalComponent;
}

export default ContentPreviewModalV2
