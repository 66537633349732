import { DocumentVersionORM, CustomDeckORM, DocumentORM } from 'src/types/orms';
import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useMSLDocumentSearch, useCustomDeckSearch } from 'src/state/redux/selector/documentSearch/documentSearch';
import { filters, merge } from 'src/state/redux/document/query';
import { DNABox, DNAButton, MenuItem, DNAText, DNAChip, Icon } from '@alucio/lux-ui';
import DNADocumentThumbnail from '../DNA/Document/DNADocumentThumbnail';
import DNAThumbnail from '../DNA/Thumbnail/DNAThumbnail';
import { OverflowMenu, Input } from '@ui-kitten/components';
import CustomFieldBadgeList from '../CustomFields/CustomFieldBadgeList';
import { useTenantCustomFields } from 'src/state/redux/selector/tenant';
import { CustomFieldUsage, FileType } from '@alucio/aws-beacon-amplify/src/models';
import { isDocumentVersionORM } from 'src/types/types';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { useAddMeeting } from '../Meeting/AddMeetingProvider';

const styles = StyleSheet.create({
  resultsWrapper: {
    maxHeight: 300,
  },
  searchIcon: {
    width: 17.5,
    height: 17.5,
  },
  cancelBtn: {
    height: 40,
    marginLeft: 8,
    marginBottom: 4,
  },
})
export interface ContentSearchResult {
  id: string
  title: string,
  orm: DocumentVersionORM | CustomDeckORM,
}

enum VariantOptions {
  meetingHistory = 'meetingHistory',
  hubs = 'hubs',
}

type VariantOption = keyof typeof VariantOptions

interface ContentSearchBarProps {
  onSelectItem: (item: ContentSearchResult) => void,
  selectedIds?: string[],
  variant: VariantOption,
  isVisible: boolean,
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
}

const DocumentVersionResult: React.FC<{ orm: DocumentVersionORM }> = (props) => {
  const { orm } = props;
  const tenantCustomFields = useTenantCustomFields({
    defaultSearchFilter: true,
    usages: { internalUsages: [CustomFieldUsage.DOCUMENT] },
  });
  const tenantValues = tenantCustomFields.reduce((acc, customField) => {
    if (isDocumentVersionORM(orm)) {
      const value = orm.meta.customValues?.configsMap?.[customField.id]?.displayValues
      if (value && value.length > 0) {
        acc += acc ? `, ${value.join(', ')}` : ` ⋅ ${value.join(', ')}`;
      }
    }
    return acc;
  }, '');

  const type = orm.model.type === FileType.MP4 ? 'VIDEO' : orm.model.type

  return (
    <DNABox spacing="sm">
      <DNADocumentThumbnail
        width={72}
        height={40}
        documentVersionORM={orm}
        showProcessing
      />
      <DNABox appearance="col">
        <DNAText>{orm.model.title}</DNAText>
        <DNAText numberOfLines={1}>{type} {tenantValues}</DNAText>
        <CustomFieldBadgeList documentVersionORM={orm} />
      </DNABox>
    </DNABox>
  )
}

const CustomDeckResult: React.FC<{ orm: CustomDeckORM }> = (props) => {
  const { orm } = props;

  return (
    <DNABox spacing="sm">
      <DNAThumbnail
        s3URL={orm.meta.assets.thumbnailKey}
        useLoadingIndicator
        width={72}
        height={40}
      />
      <DNABox appearance="col">
        <DNAText>{orm.model.title}</DNAText>
        <DNABox>
          <DNAChip appearance="tag">
            MODIFIED
          </DNAChip>
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

const ContentSearchBar: React.FC<ContentSearchBarProps> = ({
  onSelectItem,
  selectedIds,
  variant,
  isVisible,
  setIsVisible,
}) => {
  const [resultsVisibility, setResultsVisibility] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  const searchFilter = useMemo<Parameters<typeof useMSLDocumentSearch>>(
    () => [
      { text: searchText },
      merge(filters.hasLatestPublishedVersion, filters.presentable),
    ],
    [searchText],
  )

  const documentResults: DocumentORM[] = useMSLDocumentSearch(...searchFilter)
  // Add a presentable filter here when BEAC-3779 custom deck filtering is implemented
  const customDeckResults: CustomDeckORM[] = useCustomDeckSearch(searchText);
  const inputRef = useRef<Input>(null)
  const combinedResults = useMemo(() => {
    const documentVersions: ContentSearchResult[] = documentResults
      .map((result) => ({
        id: result.relations.version.latestPublishedDocumentVersionORM!.model.id,
        title: result.relations.version.latestPublishedDocumentVersionORM!.model.title!,
        orm: result.relations.version.latestPublishedDocumentVersionORM!,
      }));
    if (variant === VariantOptions.hubs) {
      return [...documentVersions].filter((item) => !selectedIds?.includes(item.id));
    }
    const customDecks: ContentSearchResult[] = customDeckResults.map((result) => ({
      id: result.model.id,
      title: result.model.title,
      orm: result,
    }))

    return [...documentVersions, ...customDecks].filter((item) => !selectedIds?.includes(item.id));
  }, [documentResults, customDeckResults]);

  const meeting = useAddMeeting();

  const renderSearchIcon = () => {
    return (
      <TouchableOpacity>
        <Icon style={styles.searchIcon} name="magnify" />
      </TouchableOpacity>
    )
  }

  const renderSearchInput = () => {
    return (
      <Input
        ref={inputRef}
        placeholder="Search for files"
        value={searchText}
        onChangeText={setSearchText}
        autoFocus={true}
        style={{ flex: 1 }}
        accessoryLeft={renderSearchIcon}
        testID="text-input"
      />
    )
  }

  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  }
  const onBackdropPressHandler = () => {
    setResultsVisibility(false);
  }

  const handleItemPress = (content: ContentSearchResult) => {
    if (variant === VariantOptions.meetingHistory) {
      analytics?.track('MEETING_MANUAL_ADD_DOCUMENT', {
        action: 'MANUAL_ADD_DOCUMENT',
        category: 'MEETING',
        meetingId: meeting.meetingORM?.model.id,
        meetingType: meeting.meetingORM?.model.type,
        contentId: content.id,
        contentType: content.orm.type,
      });
    }
    onSelectItem(content);
    setSearchText('');
    if (variant === VariantOptions.meetingHistory) setIsVisible(false);
  }

  useEffect(() => {
    setResultsVisibility(searchText.length >= 3 && combinedResults.length > 0);
  }, [searchText])

  if (!isVisible) return null
  return (
    <DNABox alignY="center">
      <OverflowMenu
        style={styles.resultsWrapper}
        anchor={renderSearchInput}
        visible={resultsVisibility}
        placement="bottom start"
        fullWidth={true}
        onBackdropPress={onBackdropPressHandler}
      >
        {combinedResults.map((content) => {
          return (
            <MenuItem
              key={content.id}
              testID="dropdown-results"
              onPress={() => handleItemPress(content)}
              title={() =>
                isDocumentVersionORM(content.orm)
                  ? <DocumentVersionResult orm={content.orm} />
                  : <CustomDeckResult orm={content.orm} />
              }
            />
          )
        })}
      </OverflowMenu>
      <DNAButton
        appearance="outline"
        status="tertiary"
        size="sm"
        onPress={toggleVisibility}
        style={styles.cancelBtn}
      >
        Cancel
      </DNAButton>
    </DNABox>
  )
}

export default ContentSearchBar
