import React, { useEffect, useState } from 'react'
import {
  DNAButton,
  DNAText,
  Iffy,
  Page,
  responsive,
  util,
} from '@alucio/lux-ui'
import { WebLinking as Linking } from '@alucio/core'
import { View, Image } from 'react-native'

const S = {
  Page: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  PageMobile: {
    justifyContent: 'center',
  },
  LoaderImage: {
    width: 200,
    height: 200,
  },
}

const Loading = () => {
  const [showRefreshMessage, setShowRefreshMessage] = useState<boolean>();
  useEffect(() => {
    const handle = setTimeout(() => {
      analytics?.track('APP_LONG_HYDRATE', {
        action: 'LONG_HYDRATE',
        category: 'APP',
      });
      setShowRefreshMessage(true)
    }, 15000)
    return () => {
      clearTimeout(handle)
    }
  }, [])
  const handleLogout = () => {
    Linking.openURL('/logout', '_self')
  }
  return (
    <Page
      style={util.mergeStyles(
        undefined,
        S.Page,
        [S.PageMobile, !responsive.device.isWeb],
      )}
    >
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <Image
          testID="dna-loader"
          style={S.LoaderImage}
          source={require('../../assets/images/LoaderDNA.gif')}
        />
        <Iffy is={showRefreshMessage}>
          <DNAText>
            This is taking longer than expected. If this message persists, please
            <DNAButton onPress={handleLogout} appearance="ghostLink" padding="none"> log out </DNAButton>
            and try again.
          </DNAText>
        </Iffy>
      </View>
    </Page>
  );
}

export default Loading;
