import { DNABox, DNAButton, DNAChip, DNAIcon, DNAText, Iffy, Rotate } from '@alucio/lux-ui';
import { Image, StyleSheet } from 'react-native';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSettings } from 'src/state/context/AppSettings';
import { useTenant } from 'src/state/redux/selector/tenant';
import ActiveUser from 'src/state/global/ActiveUser';
import SvgCrmIcon from 'src/assets/icons/CrmIcon';
// TODO: BEAC-3746: Find the correct way to handle this
import { useActor } from '@xstate/react'
import * as Types from 'src/state/machines/CRM/crmMachineTypes';
import { useUserTenant } from 'src/state/redux/selector/user';
import { CRMAccount, CRMAddress } from 'src/classes/CRM/CRMIndexedDBTypes';
import { Singleton as IndexDbCrm } from 'src/classes/CRM/CRMIndexedDB';
import debounce from 'lodash/debounce';
import { CRMAccountORM, ORMTypes } from 'src/types/orms';
import { get } from 'lodash';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import { CrmIntegrationType } from '@alucio/aws-beacon-amplify/src/models';
import { errors } from 'src/state/machines/CRM/util';

const styles = StyleSheet.create({
  containerStatus: {
    padding: 24,
  },
  sectionWrapper: {
    borderColor: colors['color-gray-80'],
    borderRadius: 4,
    borderWidth: 1,
    padding: 24,
  },
  containerError: {
    margin : 24,
    padding: 24,
    borderWidth: 1,
    borderColor: colors['color-gray-80'],
  },
  container: {
    margin : 32,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colors['color-gray-80'],
    padding: 24,
    width: 300,
  },
  connecWrapper: {
    width: '100%',
  },
  connectContainer: {
    flex: 1,
  },
  avatar: {
    borderRadius: 200,
    width: 64,
    height: 64,
  },
});

type CRMContext = {
  cond: {
    canRefreshData: boolean;
    isConnected: boolean;
    canLogin: boolean;
    canLogout: boolean;
    isErrored: boolean;
    isSyncing: boolean;
    disableSync: boolean;
    isLoggingOut: boolean;
};
  imageUrl: string | undefined;
  displayName: string | undefined;
  state: Types.CRMState,
  send: Types.CRMService['send']
}

const getFormatedName = (model: unknown, formattedName: string ): string => {
  return formattedName.replace(/\${(\w+)}/g, (_, v) => get(model, v, ''))
}

// create context
const CRMContext = React.createContext<CRMContext>(null!);

export const getAccountById = async (id: string, syncStatus: Types.CRMSyncStatus) => {
  if (!IndexDbCrm.config) {
    await IndexDbCrm.getConfig()
  }

  const account = await IndexDbCrm.getById<CRMAccount>('ACCOUNT', id)
  const accountsORM = toORMAccount([account], syncStatus)
  return accountsORM[0]
}

const toORMAccount = (accounts: CRMAccount[], syncStatus: Types.CRMSyncStatus) : CRMAccountORM[] => {
  if (syncStatus !== Types.CRMSyncStatus.SYNCED) return []

  const config = IndexDbCrm.config;

  if (!config) {
    console.error('CRM config is not defined');
    return [];
  }

  return accounts.map((account) => (
    {
      model: account,
      type: 'CRM_ACCOUNT' as ORMTypes.CRM_ACCOUNT,
      meta: {
        formattedName: getFormatedName(account, config.displaySettings.shortened),
        // TODO: Format long name is pending to be implemented
      },
      relations: {
        addresses: (account?.addresses as CRMAddress[])?.map((address) => (
          {
            model: address,
            type: 'CRM_ADDRESS' as ORMTypes.CRM_ADDRESS,
            meta: {
              formattedName: getFormatedName(address, config.addressSettings.displaySettings.shortened),
              // TODO: Format long name is pending to be implemented
            },
          }
        )),
      },
    }
  ))
}

export const useCRMAccountSearch = () => {
  const [accounts, setAccounts] = useState<CRMAccountORM[]>([])
  const { syncStatus } = useCRMStatus();

  type FilterFunction<T> = (value: T, index: number, array: T[]) => boolean;

  const search = useCallback(async (search: string, filterFn?: FilterFunction<CRMAccount>) => {
    const result = IndexDbCrm.index?.search(`*${search}*`)
    const ids = result?.map((item) => item.ref)

    if (!ids) return setAccounts([])

    let accounts = await IndexDbCrm.filterById('ACCOUNT', ids)

    if (filterFn) accounts = accounts.filter(filterFn)

    const accountsORM = toORMAccount(accounts, syncStatus)
    setAccounts(accountsORM)
  }, [])

  return {
    accounts,
    search : debounce(search, 500),
    getAccountById: (id: string) => getAccountById(id, syncStatus),
  }
}

export const CRMIntegrationProvider = ({ children }) => {
  const { crmMachine } = useAppSettings()
  const [state, send] = useActor(crmMachine);
  const imageUrl = state.context.crmSession?.authInformation?.userInfo?.thumbnail
  const displayName = state.context.crmSession?.authInformation?.userInfo?.displayName
  const isRefreshingDataTag = state.hasTag('REFRESH_DATA')
  const isRefreshingTokenTag = state.hasTag('REFRESH_TOKEN')
  const isSyncing  = state.context.crmSyncStatus === Types.CRMSyncStatus.SYNCING ||
    state.context.crmSyncStatus === Types.CRMSyncStatus.RE_SYNCING

  const cond = {
    canRefreshData:  state.can({ type: 'REFRESH_DATA', payload: { trigger: 'USER' } }),
    isConnected : !!state.context.crmSession?.accessToken,
    canLogin: state.can('LOGIN_CRM') && !isRefreshingTokenTag && !isSyncing && !isRefreshingDataTag,
    canLogout: state.can('LOGOUT_CRM') && !isRefreshingTokenTag && !isSyncing && !isRefreshingDataTag,
    isErrored: state.matches('showError'),
    isSyncing: isSyncing || isRefreshingTokenTag || isRefreshingDataTag,
    isLoggingOut: state.context.connectionStatus === Types.CRMConnectionStatus.DISCONNECTING,
    // TODO: BEAC-3746: Consider to move to a tag
    disableSync: state.context.isAuthenticating || isRefreshingTokenTag,
  }

  const value = {
    cond,
    imageUrl,
    displayName,
    state,
    send,
  }

  return (
    <CRMContext.Provider value={value}>
      {children}
    </CRMContext.Provider>
  )
}

export const useCrmStateMachine = () => {
  const context = React.useContext(CRMContext);

  if (context === undefined) {
    throw new Error('useCrmStateMachine must be used within a CRMIntegrationProvider')
  }

  return context;
}

const CRMAvatar = () => {
  const { imageUrl } = useCrmStateMachine();
  const [image, setImage] = React.useState(imageUrl);
  return (
    <DNABox alignX="center">
      {image
        ? <Image
            source={{ uri: imageUrl }}
            style={styles.avatar}
            onError={() => setImage(undefined)}
        />
      // TODO BEAC-3746: try to us a DNAIcon/DNAButton instead
        : < SvgCrmIcon />
      }
    </DNABox>
  )
}

const CRMConnect = () => {
  const currentTenant = useUserTenant();
  const { isOnline } = useAppSettings();
  const { cond, send, state } = useCrmStateMachine()
  const { isConnected, canLogin, canLogout, isLoggingOut } = cond
  const { isErrored } = cond;
  const isPopUpError = state.context?.error === errors.not_allowed_pop_up
  const ConnectionText = isErrored && !isPopUpError ? 'Re-Authenticate' : isConnected ? 'Log out' : 'Connect';

  const handleDisconnect = () => {
    analytics.track('CRM_DISCONNECT', {
      category: 'CRM',
      integration: state.context.crmConfig?.crmIntegrationType,
    })

    send({
      type: 'LOGOUT_CRM',
    })
  }

  const handleConnect = async () => {
    send({
      type: 'LOGIN_CRM',
      payload: currentTenant?.config.crmIntegration,
    })
  }

  if (!isOnline) return null

  return (
    <DNAButton
      disabled={isLoggingOut || !canLogin || cond.disableSync || (!canLogout && ConnectionText === 'Log out')}
      style={styles.connectContainer}
      size="md"
      status="tertiary"
      appearance="outline"
      onPress={isErrored
        ? handleConnect
        : isConnected
          ? handleDisconnect
          : handleConnect
        }
    >
      <DNABox fill>
        <Iffy is={cond.disableSync || cond.isLoggingOut}>
          <Rotate>
            <DNAIcon.Styled
              disabled
              appearance="outline"
              status="tertiary"
              size="md"
              name="refresh"
            />
          </Rotate>
        </Iffy>
        <DNAText
          status={
          !(canLogin || canLogout) ||
          cond.disableSync ||
          cond.isLoggingOut
            ? 'subtle'
            : undefined
          }
        >
          {ConnectionText}
        </DNAText>
      </DNABox>
    </DNAButton>
  )
}

const CRMIntegration = () => {
  const { cond, displayName, state } = useCrmStateMachine()
  const { isConnected } = cond
  const tenant = useTenant(ActiveUser.user!.tenantId);

  const ChipText = isConnected ? 'CONNECTED' : '';

  if (!tenant) {
    return null;
  }

  // This case shoudn't happen, but just in case due the tab is not visible for tenants that don't have CRM Integration
  if (!tenant.tenant.config.crmIntegration) {
    return <DNAText status="warning">CRM Integration is not enabled for this tenant</DNAText>
  }

  return (
    <DNABox
      appearance="col"
      alignY="center"
      alignX="center"
      spacing="md"
      style={styles.container}
      childStyle={[3, styles.connecWrapper]}
    >
      <DNABox spacing="between" alignX="center" alignY="center">
        <DNABox alignY="center" spacing="sm">
          <Iffy is={cond.isSyncing && !cond.isLoggingOut && !state.context.isAuthenticating}>
            <DNAPopover >
              <DNAPopover.Anchor>
                <Rotate>
                  <DNAIcon.Styled
                    size="xl"
                    name="sync-circle"
                    appearance="ghost"
                  />
                </Rotate>
              </DNAPopover.Anchor>
              <DNAPopover.Content>
                <DNAText status="basic">Syncing...</DNAText>
              </DNAPopover.Content>
            </DNAPopover>
          </Iffy>
          <DNAText>{tenant.tenant.config.crmIntegration.name || ''}</DNAText>
        </DNABox>
      </DNABox>
      <Iffy is={isConnected}>
        <DNAChip size="sm" status="success">{ChipText}</DNAChip>
      </Iffy>
      {isConnected &&
        <DNABox appearance="col" spacing="md">
          <CRMAvatar />
          <DNAText status="flatAlt">
            {displayName}
          </DNAText>
        </DNABox>
        }
      { /* Connect Container */}
      <CRMConnect />
    </DNABox>
  )
}

export const CRMStatus = () => {
  const { isOnline } = useAppSettings();
  const { cond, displayName, send, state } = useCrmStateMachine()
  const { isSyncing, isConnected, isErrored } = cond
  const tenant = useTenant(ActiveUser.user!.tenantId);
  const config = tenant?.tenant.config.crmIntegration

  const ChipText = isConnected ? 'CONNECTED' : '';
  const lastSyncTime = state.context.lastSyncedAt

  if (!isConnected) {
    return null
  }

  return (
    <DNABox style={{ width: 400, backgroundColor: colors['color-text-white'] }} appearance="col">
      <DNABox style={styles.containerStatus} fill appearance="col" spacing="md">
        <DNABox style={styles.sectionWrapper} appearance="col" spacing="lg">
          { /* Connecting Status */ }
          <DNABox spacing="between" alignX="center" alignY="center">
            <DNABox alignY="center" spacing="sm">
              <Iffy is={isSyncing}>
                <Rotate>
                  <DNAIcon.Styled
                    size="xl"
                    name="sync-circle"
                    appearance="ghost"
                  />
                </Rotate>
              </Iffy>
              <DNAText bold h5>
                {tenant?.tenant.config.crmIntegration?.name || ''}
              </DNAText>
            </DNABox>
            <Iffy is={isConnected && !isErrored}>
              <DNAChip size="sm" status="success">{ChipText}</DNAChip>
            </Iffy>
          </DNABox>

          { /* Avatar */ }
          <DNABox alignY="center" spacing="md">
            <CRMAvatar />
            <DNAText>
              {displayName}
            </DNAText>
          </DNABox>

          { /* Last Sync */ }
          <DNABox alignY="center">
            {lastSyncTime &&
              <DNAText status="flatAlt">Last Synced on {
                   lastSyncTime.toLocaleDateString(undefined,
                     { day: 'numeric', month: 'short', year: 'numeric' }) + ' at ' +
                   lastSyncTime.toLocaleTimeString(undefined,
                     { hour: '2-digit', minute: '2-digit' })
                }</DNAText>}
          </DNABox>

          { /* Sync Button */ }
          <Iffy is={!isErrored && isOnline}>
            <DNAButton
              disabled={!isOnline || cond.disableSync || isSyncing || !config || cond.isLoggingOut}
              size="md"
              style={{ width: '100%' }}
              status="tertiary"
              iconLeft={isSyncing ? undefined : 'sync' }
              appearance="outline"
              onPress={() => send({ type: 'REFRESH_DATA', payload: { trigger: 'USER' } })}
            >
              <DNABox fill>
                <Iffy is={isSyncing}>
                  <Rotate>
                    <DNAIcon.Styled
                      disabled
                      appearance="outline"
                      status="tertiary"
                      size="md"
                      name="sync"
                    />
                  </Rotate>
                </Iffy>
                <DNAText status={isSyncing ? 'subtle' : undefined}>
                  Sync latest from CRM
                </DNAText>
              </DNABox>
            </DNAButton>
          </Iffy>
          <Iffy is={isErrored}>
            <CRMConnect />
          </Iffy>
        </DNABox>
      </DNABox>

      { /* Error */ }
      {isErrored && <DNABox style={{ marginVertical : 12 }} fill alignX="center">
        <CRMError />
      </DNABox>}
    </DNABox>)
}

export const CRMError = () => {
  const { cond, state } = useCrmStateMachine()
  const { isErrored } = cond

  useEffect(() => {
    if (state.context.error) {
      console.error('CRM Error', state.context.error)
    }
  }, [state.context.error])

  if (!isErrored) return null

  const error = Object.values(errors).find(e => e === state.context.error) ||
    'Sorry, we are unable to connect. Please contact support.'

  return (
    <DNAText status="danger">
      {error}
    </DNAText>
  )
}

export const CRMReauth = () => {
  const { cond } = useCrmStateMachine()
  const { isErrored } = cond

  return (
    <DNABox style={{ width: 300, backgroundColor: 'white', paddingTop: 24 }} appearance="col" >
      <CRMConnect />
      <Iffy is={isErrored}>
        <DNABox alignX="center" alignY="center" fill>
          <DNABox style={{ marginTop: 4, marginBottom: 12 }}>
            <CRMError />
          </DNABox>
        </DNABox>
      </Iffy>
    </DNABox>
  )
}

interface CRM_STATUS {
  canUseMeetingCRMFields: boolean,
  isCRMSyncing: boolean,
  syncStatus: Types.CRMSyncStatus,
  connectionStatus: Types.CRMConnectionStatus,
  availabilityStatus: Types.CRMAvailabilityStatus,
  crmIntegrationType: CrmIntegrationType | 'VEEVA' | 'SALESFORCE' | undefined
}

export const useCRMStatus = () => {
  const { crmMachine } = useAppSettings()
  const [state] = useActor(crmMachine);
  const tenant = useTenant(ActiveUser.user!.tenantId);

  const CRMType = tenant?.tenant.config.crmIntegration?.crmIntegrationType

  const cond = useMemo(() => ({
    isEnabled : state.context.crmAvailabilityStatus === Types.CRMAvailabilityStatus.ENABLED,
    syncStatus : state.context.crmSyncStatus,
    isCRMSyncing : [Types.CRMSyncStatus.RE_SYNCING, Types.CRMSyncStatus.SYNCING].includes(state.context.crmSyncStatus),
    canUseMeetingCRMFields : state.context.crmSyncStatus === Types.CRMSyncStatus.SYNCED &&
      state.context.crmAvailabilityStatus === Types.CRMAvailabilityStatus.ENABLED,
    connectionStatus : state.context.connectionStatus,
    availabilityStatus : state.context.crmAvailabilityStatus,
  }), [state.context.crmSyncStatus, state.context.connectionStatus, state.context.crmAvailabilityStatus])

  return useMemo<CRM_STATUS>(() => ({
    syncStatus: cond.syncStatus,
    isCRMSyncing: cond.isCRMSyncing,
    canUseMeetingCRMFields: cond.canUseMeetingCRMFields,
    connectionStatus: cond.connectionStatus,
    availabilityStatus: cond.availabilityStatus,
    crmIntegrationType: CRMType,
  }), [cond])
}

export default () => {
  return (
    <CRMIntegrationProvider>
      <CRMIntegration />
      <DNABox style={{ marginVertical : 4 }}>
        <CRMError />
      </DNABox>
    </CRMIntegrationProvider>
  );
}
