import {
  CustomFieldDefinition,
  CustomValues,
} from '@alucio/aws-beacon-amplify/src/models'
import { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast'
import { AssociatedFileORM, EmailTemplateORM, HubORM } from 'src/types/orms'
import { ContentShareProps, ExistingShareLink, ShareFileOption } from 'src/utils/shareLink/shareLink.web'

export type AssociatedFileMapping = {
  [documentVersionId: string]: AssociatedFileORM[]
}

export type ShareFileOptionMapping = {
  [documentVersionId: string]: ShareFileOption[]
}

export type ShareContext = {
  availableStates: STEP_STATE[],
  customFields: CustomFieldDefinition[] | undefined,
  customFieldsValues: CustomValues[],
  associatedFiles: AssociatedFileMapping | undefined,
  emailTemplates: EmailTemplateORM[],
  contentToShare: ShareFileOptionMapping | HubORM
  // should this be an array of ExistingShareLink?
  existingShareLinks: ExistingShareLink | undefined
  generateLinks?: {
    type: SHARE_METHODS,
    emailTemplateId: string,
  }
}

export enum STEP_STATE {
  'CUSTOM_FIELDS' = 'customField',
  'ASSOCIATED_FILES' = 'associatedFiles',
  'EMAIL_TEMPLATES' = 'emailTemplates',
}

export enum SHARE_METHODS {
  OPEN_EMAIL_CLIENT = 'OPEN_EMAIL_CLIENT',
  COPY_EMAIL = 'COPY_EMAIL',
  COPY_LINK = 'COPY_LINK',
  // [NOTE] - An internal method without a payload only used for guard checking
  GUARD_CHECK = 'GUARD_CHECK',
}

export enum COPY_STATE {
  NONE = 'NONE',
  COPYING = 'COPYING',
  READY_FOR_COPY = 'READY_FOR_COPY',
  COPIED = 'COPIED',
}

export type EVT_PREV_STEP = {
  type: 'PREV_STEP',
  payload?: {
    toast?: ToastActions,
    customFieldsValues?: CustomValues[],
  }
}

export type EVT_NEXT_STEP = {
  type: 'NEXT_STEP',
  payload?: {
    toast?: ToastActions,
    customFieldsValues?: CustomValues[],
  }
}

export type EVT_GENERATE_SHARE = {
  type: 'GENERATE_SHARE',
  // [TODO] - Make this type (and implementations around this) more clear
  payload:
  | {
      type: SHARE_METHODS.COPY_EMAIL | SHARE_METHODS.OPEN_EMAIL_CLIENT,
      emailTemplateORM?: EmailTemplateORM,
      attachmentsInfo?: ContentShareProps[], // [TODO] - Can we pass this into the machine definition?
      customFieldsValues?: CustomValues[],
    }
  | {
      type: SHARE_METHODS.COPY_LINK,
      customFieldsValues?: CustomValues[],
    }
  | { type: SHARE_METHODS.GUARD_CHECK }
}

export type EVT_SET_DOCS_TO_SHARE = {
  type: 'SET_DOCS_TO_SHARE',
  payload: {
    contentToShare: ShareFileOptionMapping,
  }
}

export type EVT_SET_HUB_TO_SHARE = {
  type: 'SET_HUB_TO_SHARE',
  payload: {
    hubToShare: HubORM
  }
}

export type EVT_GO_TO_FINAL = {
  type: 'GO_TO_FINAL'
}

export type ShareEvents =
  | EVT_PREV_STEP
  | EVT_NEXT_STEP
  | EVT_GENERATE_SHARE
  | EVT_SET_DOCS_TO_SHARE
  | EVT_SET_HUB_TO_SHARE
  | EVT_GO_TO_FINAL

export type ShareState = {
  value: {},
  context: ShareContext,
}

/** Typeguards */
export const isPrevStep =
  (item: unknown): item is EVT_PREV_STEP =>
    item
      ? (item as EVT_PREV_STEP).type === 'PREV_STEP'
      : false

export const isNextStep =
  (item: unknown): item is EVT_NEXT_STEP =>
    item
      ? (item as EVT_NEXT_STEP).type === 'NEXT_STEP'
      : false
