import React, { useEffect, useCallback, createContext, useContext, PropsWithChildren } from 'react'
import { FlatList } from 'react-native'
import {
  DNABox,
  DNAButton,
  DNAText,
  DNACard,
  DNADivider,
  DNACheckbox,
  DNAChip,
  DNAContextMenu,
  Iffy,
  util,
  luxColors,
} from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import {
  AssociatedFileORM,
  DocumentORM,
  DocumentVersionORM,
  EntityWithFiles,
  ORMTypes,
} from 'src/types/types';
import {
  Document,
  AttachedFile,
  DocumentStatus,
  AttachedFileEntityType,
  AssociatedFile,
  FileType,
} from '@alucio/aws-beacon-amplify/src/models'
import FileUpload, { UploadStatus, ManagedFile, useFileUpload } from 'src/components/DNA/FileUpload/FileUpload'
import { API, graphqlOperation } from '@aws-amplify/api'
import { createAttachedFileFromS3Upload } from '@alucio/aws-beacon-amplify/src/graphql/mutations'
import { bytesToSize } from 'src/utils/documentHelpers'
import { downloadContentFromCloudfront } from 'src/utils/loadCloudfrontAsset/loadCloudfrontAsset';
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import { useAllDocumentsInstance } from 'src/state/redux/selector/document'
import { FeatureFlags } from 'src/types/featureFlags'
import { useFeatureFlag } from 'src/hooks/useFeatureFlag/useFeatureFlag'
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList';

// [TODO-1550] - There are a set of related TS guards coming in soon from, should replace it later
//             - https://github.com/alucioinc/eeb/pull/943
export const isDocumentVersion = (entity: any): entity is DocumentVersionORM =>
  (entity as DocumentVersionORM).type === ORMTypes.DOCUMENT_VERSION;

type Checked = Record<string, boolean>

type AssociatedFilesContextValues = {
  mode: Mode | keyof typeof Mode
  enableCheckbox?: boolean,
  setCheckedValues?: React.Dispatch<React.SetStateAction<Checked>>,
  checkedValues?: Checked,
  entityORM?: EntityWithFiles
  onUpdateAssociatedFile?: (associatedFile: AssociatedFile) => void,
}

export enum Mode {
  READ_ONLY = 'READ_ONLY',
  READ_DOWNLOADABLE = 'READ_DOWNLOADABLE',
  EDIT_DISABLED = 'EDIT_DISABLED',
  EDITABLE = 'EDITABLE',
  EMAIL_ATTACHMENT = 'EMAIL_ATTACHMENT'
}

export const AssociatedFilesContext = createContext<AssociatedFilesContextValues>({
  mode: Mode.READ_ONLY,
})

const useAssociatedFiles = () => useContext(AssociatedFilesContext)

/** UI CONTAINERS */
export const FileRowContainer: React.FC<PropsWithChildren<{
  item?: AttachedFile | DocumentORM
}>> = (props) => {
  const { item } = props
  const { enableCheckbox, setCheckedValues, checkedValues } = useAssociatedFiles()

  const itemId = item
    ? item?.type === 'DOCUMENT'
      ? item.model.id
      : item.id
    : undefined

  return (
    <DNABox
      fill
      spacing="md"
      alignY="center"
      childFill={1}
      style={{ padding: 12 }}
    >
      {/* Optional checkbox */}
      <Iffy is={enableCheckbox && itemId}>
        <DNACheckbox
          checked={checkedValues?.[itemId!]}
          onChange={() => setCheckedValues?.(p => ({ ...p, [itemId!]: !p[itemId!] }))}
        />
      </Iffy>

      <DNABox fill appearance="col" spacing="sm">
        {props.children}
      </DNABox>
    </DNABox>
  )
}

export const FileRowTitle: React.FC<{ children: string }> = (props) => {
  // [TODO] - Max line count/ellipsis?
  return (
    <DNABox>
      <DNAText>{props.children}</DNAText>
    </DNABox>
  )
}

export const FileRowContent: React.FC<PropsWithChildren> = (props) => {
  return (
    <DNABox fill spacing="between" alignY="center">
      {props.children}
    </DNABox>
  )
}

export const FileRowMeta: React.FC<{
  tag: string,
  item?: DocumentORM | AttachedFile,
  fileSize?: number
}> = (props) => {
  const { tag, item, fileSize } = props;

  const isDocument = item?.type === 'DOCUMENT';
  const isDocumentWithUnpublishedVersion = isDocument &&
    (item as DocumentORM).meta.hasUnpublishedVersion &&
    (item as DocumentORM).model.status === DocumentStatus.PUBLISHED;

  return (
    <DNABox spacing="sm" alignY="center">
      <DNAChip appearance="tag">{tag.toLocaleUpperCase()}</DNAChip>
      {isDocument
        ? <DNABox spacing="sm" alignY="center">
          <CustomFieldBadgeList
            documentVersionORM={(item as DocumentORM).relations.version.latestUsableDocumentVersionORM}
          />
          <DNABox
            style={util.mergeStyles(undefined,
              [{ borderWidth: 2, borderColor: luxColors.warning.quaternary }, isDocumentWithUnpublishedVersion])}
          >
            <DNADocumentChip
              item={item as DocumentORM}
              variant="status"
            />
          </DNABox>
        </DNABox>
        : null}
      <Iffy is={fileSize}>
        <DNAText>{bytesToSize(fileSize!)}</DNAText>
      </Iffy>
    </DNABox>
  )
}

interface FileRowActionsProps<T> {
  entityORM: T,
  item?: (AttachedFile | DocumentORM),
  onDelete?: (entityORM: T, item: DocumentORM | AttachedFile) => void
}
export const FileRowActions = <T extends EntityWithFiles>(props: FileRowActionsProps<T>) => {
  const { entityORM, item, onDelete } = props
  const { enableCheckbox, mode } = useAssociatedFiles()

  const isEditable = mode === Mode.EDITABLE
  const isDisabled = mode === Mode.EDIT_DISABLED
  const isEmailAttachment = mode === Mode.EMAIL_ATTACHMENT
  const isWebDoc =  (item as DocumentORM).model?.type === FileType.WEB
  const canDownload = mode !== Mode.READ_ONLY && !isEmailAttachment && !isWebDoc
  if (enableCheckbox) return null;

  return (
    <DNABox spacing="sm" alignY="center">
      {/* Download */}
      <Iffy is={canDownload}>
        <DNAButton
          onPress={() => {
            if (!item) return;

            if (item?.type !== 'DOCUMENT') {
              downloadContentFromCloudfront(item.srcFile.key, item.srcFileName, item.type)
            }
            else if (item?.type === 'DOCUMENT') {
              const latestDocVer = item.relations.version.latestPublishedDocumentVersionORM?.model
              if (!latestDocVer) return;

              downloadContentFromCloudfront(latestDocVer.srcFile?.key!, latestDocVer.srcFilename!, latestDocVer.type)
            }
          }}
          iconLeft="download"
          status="secondary"
          appearance="ghost"
        />
      </Iffy>

      {/* Context Actions */}
      <Iffy is={isEditable || isDisabled || isEmailAttachment}>
        <DNAContextMenu>
          <DNAContextMenu.Anchor>
            <DNAButton
              onPress={() => { }}
              iconLeft="dots-vertical"
              status="secondary"
              appearance="ghost"
              disabled={isDisabled}
            />
          </DNAContextMenu.Anchor>
          <DNAContextMenu.Items>
            <DNAContextMenu.Item
              collapseOnPress
              delay={100} // Workaround because sometimes the item deletes too fast before the ContextMenu can hide itself
              title="Delete"
              onPress={() => {
                if (!item) return;
                onDelete?.(entityORM, item)

                // [TODO] - Move this to the actual removal method, not the onSelect
                //        - There are times where removals aren't committed immediately
                analytics?.track('DOCUMENT_AF_REMOVED', {
                  action: 'AF_REMOVED',
                  category: 'DOCUMENT',
                  documentId: entityORM.model?.documentId,
                  documentVersionId: entityORM.model.id,
                  type: item.type === 'DOCUMENT' ? 'LINK' : 'UPLOAD',
                  attachedContentId: 'model' in item ? item.model.id : item.id,
                });
              }}
            />
          </DNAContextMenu.Items>
        </DNAContextMenu>
      </Iffy>
    </DNABox>
  )
}

interface FileRowDistributableProps<T> {
  entityORM: T,
  associatedFileORM: AssociatedFileORM
}

// many references to distributable here, not really sure where this component is being used
export const FileRowDistributable = <T extends EntityWithFiles>(props: FileRowDistributableProps<T>) => {
  const { entityORM, associatedFileORM } = props
  const { mode, onUpdateAssociatedFile } = useAssociatedFiles()
  const enableEmailTemplates = useFeatureFlag(FeatureFlags.BEAC_2516_Enable_Document_Sharing_Templates)

  // Some type inference workarounds
  const document = associatedFileORM.model.type === 'DOCUMENT'
    ? associatedFileORM.file as Document
    : undefined
  const attachedFile = associatedFileORM.model.type === 'ATTACHED_FILE'
    ? associatedFileORM.file as AttachedFile
    : undefined
  const isDistributableDoc = associatedFileORM.meta.canBeSharedByMSL;
  const isRequired = associatedFileORM.model.isDefault

  const isEditable = mode === Mode.EDITABLE
  const isDisabled = mode === Mode.EDIT_DISABLED
  const isEmailAttachment = mode === Mode.EMAIL_ATTACHMENT
  const showEditableOptions = (isEditable || isDisabled) && !isEmailAttachment
  const showReadOptions = !isEditable && !isDisabled && !isEmailAttachment

  const displayDistributableEditOption = attachedFile

  function toggleField(field: 'DEFAULT' | 'DISTRIBUTABLE'): void {
    let { isDefault } = associatedFileORM.model;
    let isDistributable = associatedFileORM.meta.canBeSharedByMSL;

    if (field === 'DEFAULT') {
      isDefault = !isDefault;
    } else {
      isDistributable = !isDistributable;
      isDefault = isDistributable && isDefault;
    }

    onUpdateAssociatedFile?.({
      ...associatedFileORM.model,
      isDefault,
      isDistributable,
    });
  }

  return (
    <DNABox spacing="sm" alignY="center">
      {/* EDITABLE OPTIONS */}
      <Iffy is={showEditableOptions}>

        <Iffy is={isDistributableDoc}>
          <DNAPopover placement="top">
            <DNAPopover.Anchor>
              <DNACheckbox
                checked={isRequired}
                status="primary"
                appearance="outline"
                disabled={isDisabled}
                onChange={() => {
                  if (isDocumentVersion(entityORM)) {
                    toggleField('DEFAULT');
                  }
                }}
              >
                Required
              </DNACheckbox>
            </DNAPopover.Anchor>
            <DNAPopover.Content>
              <DNAText status="basic">
                Determines whether this file is required when sharing externally
              </DNAText>
            </DNAPopover.Content>
          </DNAPopover>
        </Iffy>

        {/* LABEL DETERMINED BY DOCUMENT */}
        <Iffy is={document}>
          <Iffy is={enableEmailTemplates}>
            <DNAChip status="primary">
              {isDistributableDoc ? 'Distributable' : 'Non-distributable'}
            </DNAChip>
          </Iffy>
        </Iffy>

        {/* EDITABLE CHECKBOX WITH LABEL FOR ATTACHED FILE */}
        <Iffy is={enableEmailTemplates}>
          <Iffy is={displayDistributableEditOption}>
            <DNACheckbox
              checked={associatedFileORM.meta.canBeSharedByMSL}
              status="primary"
              appearance="outline"
              disabled={isDisabled}
              onChange={() => {
                if (isDocumentVersion(entityORM)) {
                  toggleField('DISTRIBUTABLE');
                }
              }}
            >
              Distributable
            </DNACheckbox>
          </Iffy>
        </Iffy>
      </Iffy>

      {/* READ MODES */}
      <Iffy is={showReadOptions}>
        <Iffy is={isRequired && isDistributableDoc}>
          <DNAChip status="primary">Required</DNAChip>
        </Iffy>
        <Iffy is={enableEmailTemplates}>
          <DNAChip status="primary">
            {(isDistributableDoc) ? 'Distributable' : 'Non-distributable'}
          </DNAChip>
        </Iffy>
      </Iffy>
    </DNABox>
  )
}

export const FileRow = {
  Container: FileRowContainer,
  Title: FileRowTitle,
  Content: FileRowContent,
  Meta: FileRowMeta,
  Actions: FileRowActions,
  Distributable: FileRowDistributable,
}

/** ROW VARIANTS */
const AttachedFileUploadProgressRow: React.FC<{
  file: ManagedFile,
  onFinishedUpload?: (attachedFile: AttachedFile) => void
}> = (props) => {
  const { file, onFinishedUpload } = props
  const { entityORM } = useAssociatedFiles()

  const STATUS = FileUpload.Status

  const hasError = file.status === STATUS.ERROR
  const isInProgress = file.status === STATUS.IN_PROGRESS
  const isCancelling = file.status === STATUS.CANCELLING
  const isCancelled = file.status === STATUS.CANCELLED
  const isUploaded = file.status === STATUS.UPLOADED
  const isFinished = file.status === STATUS.FINISHED

  const onFinished = useCallback(async () => {
    if (!entityORM) return;
    let attachedFileId = ''
    const res = await API.graphql(
      graphqlOperation(createAttachedFileFromS3Upload, {
        inputAttached: {
          entityId: entityORM.model.id,
          entityType: isDocumentVersion(entityORM)
            ? AttachedFileEntityType.DOCUMENT_VERSION
            : AttachedFileEntityType.EMAIL_TEMPLATE,
          srcFilename: file.file.name,
          fileS3Key: file.key,
        },
      }),
    ) as { data: { createAttachedFileFromS3Upload: AttachedFile } }

    onFinishedUpload && onFinishedUpload(res.data.createAttachedFileFromS3Upload)
    attachedFileId = res.data.createAttachedFileFromS3Upload.id

    return attachedFileId
  }, [file, entityORM])

  return (
    <FileRow.Container>
      <FileRow.Title>{file.file.name}</FileRow.Title>
      <FileRow.Content>
        <FileRow.Meta tag={file.fileExt} fileSize={file.file.size} />
        {/* Progress Indicator */}
        <FileUpload.RowProgress
          file={file}
          onFinished={onFinished}
        >
          {
            ({ progressPercent, cancel }) => (
              <DNABox spacing="sm" alignY="center">
                {/* Uploading State */}
                <Iffy is={isInProgress || isUploaded || isFinished}>
                  <DNABox style={{ width: 100 }}>
                    <FileUpload.ProgressBar
                      progress={progressPercent}
                      finalValue={100}
                    />
                  </DNABox>
                  <DNABox style={{ width: 50 }}>
                    <Iffy is={!isFinished}>
                      <DNAButton
                        appearance="ghost"
                        status="danger"
                        iconLeft="close-circle-outline"
                        onPress={cancel}
                      />
                    </Iffy>
                  </DNABox>
                </Iffy>

                {/* Abort States */}
                <Iffy is={hasError || isCancelling || isCancelled}>
                  {/* Error */}
                  <Iffy is={hasError}>
                    <DNAText status="danger">
                      {
                        file.error?.message === FileUpload.ERR_CODES.MAX_FILE_SIZE_EXCEEDED
                          ? FileUpload.ERR_CODES.MAX_FILE_SIZE_EXCEEDED
                          : 'An error has occured'
                      }
                    </DNAText>
                  </Iffy>

                  {/* Cancelling */}
                  <Iffy is={isCancelling}>
                    <DNAText status="danger">Cancelling ...</DNAText>
                  </Iffy>

                  {/* Cancelled */}
                  <Iffy is={isCancelled}>
                    <DNAText status="danger">Cancelled</DNAText>
                  </Iffy>

                  {/* Removel Button */}
                  <Iffy is={hasError || isCancelled}>
                    <DNAButton
                      appearance="ghost"
                      status="danger"
                      iconLeft="close-circle-outline"
                      onPress={file.remove}
                    />
                  </Iffy>
                </Iffy>
              </DNABox>
            )
          }
        </FileUpload.RowProgress>
      </FileRow.Content>
    </FileRow.Container>
  )
}

interface AttachedFileRowProps<T> {
  entityORM: T,
  associatedFileORM: AssociatedFileORM,
  item?: AttachedFile,
  onDelete?: (documentVersionORM: T, item: DocumentORM | AttachedFile) => void
}
const AttachedFileRow = <T extends EntityWithFiles>(props: AttachedFileRowProps<T>) => {
  const { item, associatedFileORM, entityORM, onDelete } = props

  if (!item) return null

  return (
    <FileRow.Container item={item}>
      <FileRow.Title>{item.title}</FileRow.Title>
      <FileRow.Content>
        <FileRow.Meta tag={item.type} item={item} fileSize={item.srcSize} />
        <DNABox alignY="center" spacing="sm">
          <FileRow.Distributable
            entityORM={entityORM}
            associatedFileORM={associatedFileORM}
          />
          <FileRow.Actions
            entityORM={entityORM}
            item={item}
            onDelete={onDelete}
          />
        </DNABox>
      </FileRow.Content>
    </FileRow.Container>
  )
}

interface AssociatedFileRowProp<T extends EntityWithFiles> {
  entityORM: T,
  associatedFileORM: AssociatedFileORM,
  item: Document,
  onDelete?: (entityORM: T, item: DocumentORM | AttachedFile) => void
}
const AssociatedFileRow = <T extends EntityWithFiles>(props: AssociatedFileRowProp<T>) => {
  const { item, associatedFileORM, entityORM, onDelete } = props
  // [TODO] - Workaround since we don't get the ORM version of Document atm
  const [documentORM] = useAllDocumentsInstance(
    item
      ? { filter: { model: { id: item.id } } }
      : undefined,
  )

  if (!item) return null
  // Since this control is only used within Publisher we show latest version
  const docVer = documentORM?.relations.version.latestDocumentVersionORM
  return (
    <FileRow.Container item={documentORM}>
      <FileRow.Title>{docVer.model.title ?? ''}</FileRow.Title>
      <FileRow.Content>
        <FileRow.Meta
          tag={item.type}
          item={documentORM}
          fileSize={docVer.model.srcSize ?? 0}
        />
        <DNABox alignY="center" spacing="sm">
          <FileRow.Distributable
            entityORM={entityORM}
            associatedFileORM={associatedFileORM}
          />
          <FileRow.Actions
            item={documentORM}
            entityORM={entityORM}
            onDelete={onDelete}
          />
        </DNABox>
      </FileRow.Content>
    </FileRow.Container>
  )
}

interface AssociatedFilesListProps<T> {
  entityORM: T,
  items: (AssociatedFileORM | ManagedFile)[],
  mode: Mode | keyof typeof Mode,
  emptyMessage?: string,
  enableCheckbox?: boolean,
  // [TODO] - Find a better pattern to have bi-directional state flow here (that fits the current component tree)
  checkedValues?: Checked,
  setCheckedValues?: React.Dispatch<React.SetStateAction<Checked>>,
  onStatusChange?: (status: UploadStatus) => void,
  onDelete?: (entityORM: T, item: DocumentORM | AttachedFile) => void,
  onFinishedUpload?: (attachedFile: AttachedFile) => void,
  onUpdateAssociatedFile?: (associatedFile: AssociatedFile) => void,
}
const AssociatedFilesList = <T extends EntityWithFiles>(props: AssociatedFilesListProps<T>) => {
  const fileUpload = useFileUpload()
  const {
    entityORM,
    items,
    mode,
    enableCheckbox,
    checkedValues,
    setCheckedValues,
    onStatusChange,
    emptyMessage,
    onDelete,
    onFinishedUpload,
    onUpdateAssociatedFile,
  } = props

  // For any file row that has finished uploading, we keep rendering said (finished) progress row
  //  until its subscription is received. Once a record is matched, remove the file progress
  useEffect(() => {
    const associatedFilesIds = entityORM
      .relations
      .associatedFiles
      .reduce<Record<string, boolean>>(
        (acc, file) => {
          acc[file.model.attachmentId] = true
          return acc
        },
        {},
      )

    items.forEach(item => {
      if (item.type === 'MANAGED_FILE') {
        const uploadedFileMatchesExistingRecord = (
          item.refId &&
          associatedFilesIds[item.refId]
        )

        if (uploadedFileMatchesExistingRecord) {
          item.remove()
        }
      }
    })
  }, [entityORM, items])

  useEffect(() => {
    onStatusChange?.(fileUpload.status)
  }, [fileUpload.status])

  const value = {
    mode,
    enableCheckbox,
    checkedValues,
    setCheckedValues,
    onUpdateAssociatedFile,
    entityORM,
  }

  return (
    <AssociatedFilesContext.Provider value={value}>
      <DNACard {...(items.length ? { appearance: 'outline' } : { })}>
        <FlatList<AssociatedFileORM | ManagedFile>
          data={items}
          initialNumToRender={25}
          ItemSeparatorComponent={DNADivider}
          keyExtractor={(item) => item.type === 'MANAGED_FILE' ? item.key : item.model.id}
          renderItem={
            (row) => {
              if (row.item.type === 'MANAGED_FILE') {
                return (
                  <AttachedFileUploadProgressRow
                    file={row.item}
                    onFinishedUpload={onFinishedUpload}
                  />
                )
              }
              else if (row.item.type === 'ASSOCIATED_FILE') {
                if (row.item.model.type === 'DOCUMENT') {
                  // [TODO] - Consider just passing in the AssociatedFileORM instead of the attachment itself
                  return (
                    <AssociatedFileRow
                      item={row.item.file as Document}
                      entityORM={entityORM}
                      associatedFileORM={row.item}
                      onDelete={onDelete}
                    />
                  )
                }
                else if (row.item.model.type === 'ATTACHED_FILE') {
                  return (
                    <AttachedFileRow
                      item={row.item.file as AttachedFile | undefined}
                      entityORM={entityORM}
                      associatedFileORM={row.item}
                      onDelete={onDelete}
                    />
                  )
                }
              }

              return null
            }
          }
        />
      </DNACard>
      <Iffy is={!items.length && emptyMessage}>
        <DNAText style={{ marginVertical: 8 }} status="flatAlt">
          {emptyMessage!}
        </DNAText>
      </Iffy>
    </AssociatedFilesContext.Provider>
  )
}

AssociatedFilesList.Mode = Mode

export default AssociatedFilesList
