import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Iffy,
  DNAIcon,
  Select,
  IndexPath,
  util,
  DNASelect,
} from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'

import { styles } from './Styles';
import { StyleProp, ViewStyle } from 'react-native';
import { FieldConfig } from '@alucio/aws-beacon-amplify/src/models';

const SELECT_VALUE_PROMPT = 'Select...';

interface SelectInputProps {
  allowDeselect?: boolean;
  availableValues: string[];
  disableValues?: string[];
  descriptionText?: string;
  disabled?: boolean;
  multiSelect?: boolean;
  hideLabel?: boolean;
  testID?: string;
  onChangeSelection: (
    value: string[] | string,
    field?: FieldConfig
    ) => void;
  required?: boolean;
  status?: string;
  titleColor?: string;
  style?: StyleProp<ViewStyle>;
  removeMarginPadding?: boolean;
  title: string;
  value: string[] | string;
  field?: FieldConfig
}

export default function SelectInputComponent(props: SelectInputProps) {
  const {
    allowDeselect = false,
    availableValues,
    disableValues,
    descriptionText,
    multiSelect = false,
    hideLabel = false,
    onChangeSelection,
    required = true,
    status = 'flat',
    title,
    titleColor,
    removeMarginPadding,
    disabled = false,
    value,
    field,
    ...rest
  } = props;

  const [selectedIndices, setSelectedIndices] = useState<IndexPath | IndexPath[]>();
  const [itemValues, setItemValues] = useState<string[]>([]);

  const kebabCaseTestId = rest.testID?.toLowerCase().replaceAll(' ', '-')

  useEffect(() => {
    const currentItemValues = (!multiSelect && allowDeselect)
      ? [SELECT_VALUE_PROMPT, ...availableValues]
      : availableValues
    setItemValues(currentItemValues);
    if (value && (value.length > 0)) {
      if (multiSelect && Array.isArray(value)) {
        const selectedIndices = value.map((val) => {
          const itemIndex = currentItemValues.indexOf(val);
          return new IndexPath(itemIndex);
        });
        setSelectedIndices(selectedIndices);
      } else if (!multiSelect) {
        const val = Array.isArray(value) ? value[0] : value;
        const itemIndex = currentItemValues.indexOf(val);
        setSelectedIndices(new IndexPath(itemIndex))
      }
    } else {
      if (!multiSelect && allowDeselect) {
        setSelectedIndices(new IndexPath(0));
      } else {
        setSelectedIndices(undefined);
      }
    }
  }, [multiSelect, allowDeselect, availableValues, value]);

  const selectValue = (index: IndexPath | IndexPath[]) => {
    if (!Array.isArray(index)) {
      const selectedValue = allowDeselect && index.row === 0 ? '' : itemValues[index.row];
      const isDisabled = disableValues && disableValues.includes(selectedValue);
      !isDisabled && onChangeSelection(selectedValue ? [selectedValue] : [], field);
      !isDisabled && setSelectedIndices(index);
    } else {
      const selectedValues = index.map((idx) => {
        const selectedValue = itemValues[idx.row];
        return selectedValue;
      })
      setSelectedIndices(index);
      onChangeSelection(selectedValues, field);
    }
  };
  const formatFieldValue = (textProps) => {
    let value = SELECT_VALUE_PROMPT;
    if (selectedIndices) {
      if (Array.isArray(selectedIndices)) {
        value = selectedIndices.map((idx) => itemValues[idx.row]).join(', ');
      } else {
        value = itemValues[selectedIndices.row];
      }
    }
    const { propStyles, ...rest } = textProps;

    return (
      <Text
        {...rest}
        style={
          util.mergeStyles(
            propStyles,
            value === SELECT_VALUE_PROMPT
              ? styles.SelectPrompt
              : null,
            disabled
              ? styles.DisabledPrompt
              : null,
          )
        }
      >{value}
      </Text>
    );
  }
  const formatItemTitle = (textProps, label) => {
    const { propStyles, ...rest } = textProps;
    const isDisabled = disableValues && disableValues.includes(label);
    return (
      <Text
        {...rest}
        style={[
          util.mergeStyles(
            undefined,
            label === SELECT_VALUE_PROMPT
              ? styles.SelectPrompt
              : null, { width: '100%' },
          ),
          isDisabled && { opacity: 0.4 },
        ]}
      >{label}
      </Text>
    )
  }
  const renderOption = (field: string, index: number) => (
    <Select.Item
      testID={`select-item-${field.toLowerCase().replaceAll(' ', '-')}`}
      key={index}
      title={(textProps) => formatItemTitle(textProps, field)}
    />
  )

  return (
    <Box
      testID={`${kebabCaseTestId}-container`}
      style={util.mergeStyles(undefined, styles.Container, [styles.NoMarginPadding, removeMarginPadding])}
    >
      {!hideLabel && <Box style={styles.TitleContainer}>
        <Box>
          <Text style={styles.required}>{required ? '*' : ''}</Text>
        </Box>
        <Box>
          <Text
            style={
            util.mergeStyles(
              undefined,
              styles.Title,
              [{ color: titleColor }, titleColor],
            )
          }
          >{title}</Text>
        </Box>
        <Iffy is={descriptionText}>
          <Box>
            <DNAPopover >
              <DNAPopover.Anchor>
                <DNAIcon
                  testID={`icon-help-circle-outline-${kebabCaseTestId}`}
                  style={styles.HelpToolTipIconStyle}
                  name="help-circle-outline"
                />
              </DNAPopover.Anchor>
              <DNAPopover.Content>
                <Text
                  testID={`${kebabCaseTestId}-tooltip-text`}
                  style={{ color: 'white' }}
                >{descriptionText}</Text>
              </DNAPopover.Content>
            </DNAPopover>
          </Box>
        </Iffy>
      </Box>}
      {/* [TODO] - Should add "-select" suffix and use kebabCase version* */}
      <Box testID={rest.testID}>
        <DNASelect
          size="large"
          disabled={disabled}
          status={status}
          selectedIndex={selectedIndices}
          onSelect={selectValue}
          multiSelect={multiSelect}
          value={formatFieldValue}
          {...rest}
        >
          {itemValues.map(renderOption)}
        </DNASelect>
      </Box>
    </Box>
  );
}
