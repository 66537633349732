import { CacheDBSchema } from '@alucio/core'
import { IDBPDatabase, openDB, deleteDB } from 'idb'
import PWALogger from 'src/worker/util/logger'

// [TODO-PWA] - Enums don't work in worker files for some reason
// [TODO-PWA] - Test cases for upgrading IDB
const setupCacheDB = async () => {
  const createContentManifest = (db: IDBPDatabase<CacheDBSchema>) => {
    const store = db.createObjectStore(
      'CACHE_MANIFEST',
      { keyPath: 'id' },
    );
    store.createIndex('by-status', 'status');
  }
  const createCachePayload = (db: IDBPDatabase<CacheDBSchema>) => {
    const store = db.createObjectStore(
      'CACHE_PAYLOAD',
      { keyPath: 'path' },
    );
    store.createIndex('by-path', 'path');
  }
  const createDB = async () => {
    return await openDB<CacheDBSchema>(
      'CacheDB',
      2,
      {
        upgrade(db, oldVersion, newVersion) {
          if (oldVersion !== 0 && oldVersion !== newVersion) {
            // check if exists in the object store

            const idxObjects : ['CACHE_MANIFEST', 'CACHE_PAYLOAD']  = ['CACHE_MANIFEST', 'CACHE_PAYLOAD'];

            idxObjects.forEach((idx) => {
              if (db.objectStoreNames.contains(idx)) {
                db.deleteObjectStore(idx);
              }
            });
            PWALogger.info('[PWA] - Upgrading CacheDB from version ' + oldVersion + ' to ' + newVersion);
          }

          PWALogger.info('Upgrading CacheDB')
          createContentManifest(db);
          createCachePayload(db);
        },
      });
  }
  const db = await createDB()
  db.close();
  const existingTables = db.objectStoreNames
  if (!existingTables.contains('CACHE_PAYLOAD') || !existingTables.contains('CACHE_MANIFEST')) {
    PWALogger.warn('Found missing table')
    await deleteDB('CacheDB', {
      blocked() {
        console.warn('DB Delete attempt was blocked by another connection')
      },
    })
    PWALogger.info('Recreating DB')
    await createDB()
    PWALogger.info('DB Created')
  }
}

export default setupCacheDB
