import React, { NamedExoticComponent, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useDispatch } from 'src/state/redux'
import { Image, ImageProps, Pressable, ScrollView, StyleSheet, TextStyle, View, ViewStyle } from 'react-native'
import {
  DNABox,
  DNAButton,
  DNACheckbox,
  DNAChip,
  DNAIcon,
  DNAText,
  Iffy,
  responsive,
  Stack,
  useSafePromise,
  util,
} from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors'

import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import { AssociatedSlidePreviewModal } from './AssociatedSlidePreviewModal'
import { getImageObjectURLFromCloudfront, retryPromise } from 'src/utils/loadCloudfrontAsset/common'
import { ThumbnailSize, thumbnailSizeDimensions } from 'src/hooks/useThumbnailSize/useThumbnailSize'
import { Page, Sentiment, PresentedMeta } from '@alucio/aws-beacon-amplify/src/models'
import { DocumentVersionORM } from 'src/types/orms'
import useFeatureFlag from 'src/hooks/useFeatureFlag/useFeatureFlag';
import { FeatureFlags } from 'src/types/featureFlags';
import { isElement } from 'src/types/typeguards';
import debounce from 'lodash/debounce'

enum DNAThumbnailModes {
  READ_ONLY,
  SELECTABLE,
  REMOVABLE,
}

enum DNAThumbnailVariants {
  DEFAULT,
  ASSOCIATED,
  INFO,
}

type ComposableComponentStyles =
  | 'thumbnailContainer'
  | 'loadedThumbnail'
  | 'pageNumber'
  | 'selected'

type ComposableStyle = Record<ComposableComponentStyles, ViewStyle | TextStyle>

const defaultVariantStyle: ComposableStyle = {
  thumbnailContainer: { },
  loadedThumbnail: { },
  selected: { },
  pageNumber: {
    color: colors['color-text-basic'],
  },
}

export const variantStyles: Record<DNAThumbnailVariants, ComposableStyle> = {
  [DNAThumbnailVariants.DEFAULT]: {
    ...defaultVariantStyle,
  },
  [DNAThumbnailVariants.INFO]: {
    ...defaultVariantStyle,
    thumbnailContainer: {
      borderColor: colors['color-gray-100'],
      borderRadius: 6,
      borderWidth: 3,
    },
    loadedThumbnail: {
      backgroundColor: colors['color-gray-100'],
      borderRadius: 6,
      borderWidth: 3,
      borderColor: colors['color-text-basic'],
    },
    selected: {
      borderColor: colors['color-brand2-500'],
    },
  },
  [DNAThumbnailVariants.ASSOCIATED]: {
    ...defaultVariantStyle,
    thumbnailContainer: {
      borderColor: colors['color-gray-100'],
      borderRadius: 6,
      borderWidth: 2,
    },
  },
}

const cornerItemsStyles = StyleSheet.create<Record<ThumbnailSize, any>>({
  xxs: {
    margin: 6,
  },
  xs: {
    margin: 6,
  },
  sm: {
    margin: 8,
  },
  md: {
    margin: 10,
  },
  lg: {
    margin: 12,
  },
  xl: {
    margin: 16,
  },
  xxl: {
    margin: 18,
  },
})

const S = StyleSheet.create({
  fullW: { width: '100%' },
  opaque: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  overlayText: {
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  pageNumber: {
    backgroundColor: colors['color-gray-300'],
    color: colors['color-text-basic'],
    paddingVertical: 2,
    paddingHorizontal: 4,
    borderRadius: 2,
  },
  disabledPopover: {
    color: colors['color-text-white'],
    marginHorizontal: 12,
    width: 400,
  },
  presentedMetaWrapper: {
    borderRadius: 4,
    backgroundColor: colors['color-black'],
    padding: 4,
  },
})

export interface DNAThumbnailProps extends Omit<ImageProps, 'source'> {
  useLoadingIndicator?: boolean,
  s3URL?: string,
  children?: never,
  overlayText?: string,
  size?: ThumbnailSize,
  presentedMeta?: PresentedMeta,
  height?: number,
  width?: number,

  pageNumber?: number,
  isCover?: boolean,
  isRequired?: boolean,

  mode?: DNAThumbnailModes,
  variant?: DNAThumbnailVariants,

  checked?: boolean,
  onCheck?: () => void,
  onCheckIn?: () => void,
  onLongPress?: () => void,
  delayLongPress?: number,

  disabled?: boolean,
  disabledMessage?: string,
  preview?: { pages: Page[], docVerORM: DocumentVersionORM, getSlideTitle?: (number: number) => string },
  onEditPageData?: (pageNumber?: number, s3URL?: string) => void,
  showEditButton?: boolean,
  showSpeakerNotesButton?: boolean,
  thumbnailTitle?: string,
  parentRef?: ScrollView | null
}

export type imageURI = {
  uri: string,
  width: number,
  height: number,
  ratio: number,
}

export enum FetchStatus {
  PENDING,
  RESOLVED,
  REJECTED,
}

const useConditionals = () => {
  const areMeetingNotesEnabled = useFeatureFlag(FeatureFlags.BEAC_4227_meeting_slide_notes);
  const areReactionsEnabled = useFeatureFlag(FeatureFlags.BEAC_4227_meeting_slide_reactions);
  const isFollowUpEnabled = useFeatureFlag(FeatureFlags.BEAC_4227_meeting_slide_follow_up);

  return {
    areMeetingNotesEnabled,
    areReactionsEnabled,
    isFollowUpEnabled,
  };
}

const PresentedMetaComponent = (props: { presentedMeta: PresentedMeta }) => {
  const { presentedMeta: { note, followUp, sentiment } } = props;
  const conditionals = useConditionals();

  // IN CASE THE PRESENTEDMETA EXISTS WITH EMPTY NOTES
  if ((!note || !conditionals.areMeetingNotesEnabled) &&
     (!followUp || !conditionals.isFollowUpEnabled) &&
     (!sentiment || !conditionals.areReactionsEnabled)) {
    return <></>;
  }

  return (
    <DNABox style={S.presentedMetaWrapper} spacing="xs" alignY="center">
      <Iffy is={conditionals.areReactionsEnabled && sentiment}>
        <DNAIcon.Styled
          appearance="ghost"
          status={sentiment === Sentiment.NEGATIVE ? 'warning' : 'success'}
          name={sentiment === Sentiment.NEGATIVE ? 'chevron-down' : 'chevron-up'}
        />
      </Iffy>
      <Iffy is={conditionals.areMeetingNotesEnabled && note}>
        <DNAIcon.Styled name="playlist-edit" size="md" />
      </Iffy>
      <Iffy is={conditionals.isFollowUpEnabled && followUp}>
        <DNAIcon.Styled name="flag" size="md" />
      </Iffy>
    </DNABox>
  );
};

export type DNAThumbnail = NamedExoticComponent<DNAThumbnailProps> & {
  Modes: typeof DNAThumbnailModes,
  Variants: typeof DNAThumbnailVariants,
}

type useImageLoader = {
  imageURI: imageURI | undefined;
  fetchStatus: FetchStatus | undefined;
  showLoadingIndicator: boolean;
  fetchImage(): Promise<void>
}

type useImageLoaderState = {
  imageURI: imageURI | undefined;
  fetchStatus: FetchStatus | undefined;
  currentS3URL: string | undefined;
}

type useImageLoaderActions = (
  {
    type: 'SET_IMAGE_URI',
    payload: {
      imageURI: imageURI | undefined,
    },
  } |
  {
    type: 'SET_FETCH_STATUS',
    payload: {
      fetchStatus: FetchStatus | undefined,
    },
  } |
  {
    type: 'SET_CURRENT_S3_URL',
    payload: {
      currentS3URL: string | undefined,
    },
  } |
  {
    type: 'SET_IMAGE_URI_AND_FETCH_STATUS_AND_CURRENT_S3_URL',
    payload: {
      imageURI: imageURI | undefined,
      fetchStatus: FetchStatus | undefined,
      currentS3URL: string | undefined,
    },
  } |
  {
    type: 'SET_IMAGE_URI_AND_FETCH_STATUS',
    payload: {
      imageURI: imageURI | undefined,
      fetchStatus: FetchStatus | undefined,
    },
  }
)

const useImageLoader = (props: DNAThumbnailProps) : useImageLoader => {
  const [state, dispatch] = useReducer(
    (state: useImageLoaderState, actions: useImageLoaderActions) : useImageLoaderState => {
      switch (actions.type) {
        case 'SET_IMAGE_URI':
          return {
            ...state,
            imageURI: actions.payload.imageURI,
          }
        case 'SET_FETCH_STATUS':
          return {
            ...state,
            fetchStatus: actions.payload.fetchStatus,
          }
        case 'SET_CURRENT_S3_URL':
          return {
            ...state,
            currentS3URL: actions.payload.currentS3URL,
          }
        case 'SET_IMAGE_URI_AND_FETCH_STATUS':
          return {
            ...state,
            imageURI: actions.payload.imageURI,
            fetchStatus: actions.payload.fetchStatus,
          }
        case 'SET_IMAGE_URI_AND_FETCH_STATUS_AND_CURRENT_S3_URL':
          return {
            ...state,
            imageURI: actions.payload.imageURI,
            fetchStatus: actions.payload.fetchStatus,
            currentS3URL: actions.payload.currentS3URL,
          }
        default:
          return state
      }
    },
    {
      imageURI: undefined,
      fetchStatus: undefined,
      currentS3URL: undefined,
    },
  )

  const safePromise = useSafePromise()

  const {
    s3URL,
    useLoadingIndicator = true,
  } = props

  useEffect(
    () => {
      // Explicitly release the image's reference on unmount
      //  to garbage collect (hopefully)
      return () => {
        if (state.imageURI?.uri)
        { URL.revokeObjectURL(state.imageURI.uri) }
      }
    },
    [state.imageURI],
  )

  async function fetchImage() {
    if (!s3URL) {
      dispatch({
        type: 'SET_FETCH_STATUS',
        payload: {
          fetchStatus: FetchStatus.REJECTED,
        },
      })
      return;
    }

    if (state.currentS3URL && s3URL === state.currentS3URL) {
      return;
    }

    try {
      dispatch({
        type: 'SET_FETCH_STATUS',
        payload: {
          fetchStatus: FetchStatus.PENDING,
        },
      })

      // [TODO] - Make the `makeSafe` promise infer the return type from the param instead of `as` assertion
      const objURL = await retryPromise(
        safePromise.makeSafe(
          getImageObjectURLFromCloudfront(s3URL),
        ),
      ) as string

      const fetchDimensions = () =>
        new Promise<{ width: number, height: number }>(
          (resolve, reject) => (
            Image.getSize(
              objURL,
              (width, height) => resolve({ width, height }),
              reject,
            )
          ),
        )

      const dimensions = await retryPromise(safePromise.makeSafe(fetchDimensions()))

      dispatch({
        type: 'SET_IMAGE_URI_AND_FETCH_STATUS_AND_CURRENT_S3_URL',
        payload: {
          imageURI: {
            uri: objURL,
            ratio: responsive.dimensions.screenWidth / dimensions.width,
            ...dimensions,
          },
          fetchStatus: FetchStatus.RESOLVED,
          currentS3URL: s3URL,
        },
      })

      /** TODO: Probably a better way to structure this */
    } catch (err: any) {
      if (err?.isCanceled) return;
      dispatch({
        type: 'SET_IMAGE_URI_AND_FETCH_STATUS',
        payload: {
          imageURI: undefined,
          fetchStatus: FetchStatus.REJECTED,
        },
      })
    }
  }

  const showLoadingIndicator = (
    (state.fetchStatus === FetchStatus.PENDING) &&
    useLoadingIndicator
  )

  return {
    imageURI: state.imageURI,
    fetchStatus: state.fetchStatus,
    showLoadingIndicator,
    fetchImage,
  }
}

const Thumbnail : React.FC<DNAThumbnailProps & useImageLoader> = (props) => {
  const {
    s3URL,
    height: propHeight,
    width: propWidth,
    overlayText,
    size,
    pageNumber,
    isCover,
    isRequired,

    mode = DNAThumbnailModes.READ_ONLY,
    variant = DNAThumbnailVariants.DEFAULT,
    presentedMeta,
    onCheck,
    onCheckIn,
    onLongPress,
    delayLongPress,
    checked,
    disabled,
    resizeMode = 'center',
    disabledMessage,
    preview,
    onEditPageData,
    showEditButton,
    thumbnailTitle,
    showSpeakerNotesButton,
    ...rest
  } = props
  const dispatch = useDispatch()

  const { imageURI, showLoadingIndicator, fetchStatus } = rest

  const dimensions = size ? thumbnailSizeDimensions[size] : undefined

  const width = dimensions ? dimensions.width : propWidth
  const height = dimensions ? dimensions.height : propHeight
  const minSize = useMemo(
    () => ({
      minHeight: height,
      minWidth: width,
    }),
    [width, height],
  )

  const variantStyle = variantStyles[variant]
  const badgesWidth = isCover && isRequired ? 180 : isCover || isRequired ? 105 : 50

  const InteractiveWrapper = mode === DNAThumbnailModes.SELECTABLE
    ? Pressable
    : DNABox

  const interactiveProps = mode === DNAThumbnailModes.SELECTABLE
    ? {
      onPress: !disabled ? onCheck : undefined,
      onPressIn: !disabled ? onCheckIn : undefined,
      onLongPress: !disabled ? onLongPress : undefined,
      delayLongPress,
    }
    : { }

  const togglePreviewModal = () => {
    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: (props) => (
        <AssociatedSlidePreviewModal
          pageNumber={pageNumber}
          payload={preview}
          {...props}
        />
      ),
    }))
  }

  return (
    <InteractiveWrapper style={minSize} {...interactiveProps}>
      <DNABox
        testID="document-thumbnail"
        appearance="col"
        spacing={pageNumber ? 'xs' : undefined}
      >
        <Stack anchor="bottom">

          {/* THUMBNAIL IMAGE */}
          <Stack.Layer>
            <Iffy is={preview}>
              <Pressable onPress={togglePreviewModal}>
                <DNABox
                  style={util.mergeStyles(
                    undefined,
                    variantStyle.thumbnailContainer,
                    [variantStyle.selected, checked],
                  )}
                >
                  {/* LOADING INDICATOR */}
                  <Iffy is={showLoadingIndicator}>
                    <DNABox style={minSize} />
                  </Iffy>

                  {/* LOADED */}
                  <Iffy is={fetchStatus === FetchStatus.RESOLVED}>
                    <Image
                      {...rest}
                      style={util.mergeStyles(
                        props,
                        variantStyle.loadedThumbnail,
                        { height, width },
                      )}
                      source={{ uri: imageURI?.uri }}
                      resizeMode={resizeMode}
                    />
                  </Iffy>

                  {/* FAILED IMAGE */}
                  <Iffy is={fetchStatus === FetchStatus.REJECTED}>
                    <DNABox style={minSize} />
                  </Iffy>
                </DNABox>
              </Pressable>
            </Iffy>
            <Iffy is={!preview}>
              <DNABox
                style={util.mergeStyles(
                  undefined,
                  variantStyle.thumbnailContainer,
                  [variantStyle.selected, checked],
                )}
              >
                {/* LOADING INDICATOR */}
                <Iffy is={showLoadingIndicator}>
                  <DNABox style={minSize} />
                </Iffy>

                {/* LOADED */}
                <Iffy is={fetchStatus === FetchStatus.RESOLVED}>
                  <Image
                    {...rest}
                    style={util.mergeStyles(
                      props,
                      variantStyle.loadedThumbnail,
                      { height, width },
                    )}
                    source={{ uri: imageURI?.uri }}
                    resizeMode={resizeMode}
                  />
                </Iffy>

                {/* FAILED IMAGE */}
                <Iffy is={fetchStatus === FetchStatus.REJECTED}>
                  <DNABox style={minSize} />
                </Iffy>
              </DNABox>
            </Iffy>
          </Stack.Layer>

          {/* CHECKBOX */}
          <Stack.Layer anchor="topLeft">
            <Iffy is={mode === DNAThumbnailModes.SELECTABLE}>
              <DNABox style={cornerItemsStyles[size ?? 'md']}>
                {/* DISABLED DNAPOPOVER */}
                <Iffy is={disabled}>
                  <DNAPopover >
                    <DNAPopover.Anchor>
                      <DNACheckbox
                        context="altBg"
                        checked={!!checked}
                        disabled={disabled}
                        onChange={onCheck}
                      />
                    </DNAPopover.Anchor>
                    <DNAPopover.Content>
                      <DNAText
                        style={S.disabledPopover}
                      >
                        {disabledMessage}
                      </DNAText>
                    </DNAPopover.Content>
                  </DNAPopover>
                </Iffy>

                {/* REGULAR CHECKBOX */}
                <Iffy is={!disabled}>
                  <DNACheckbox
                    context="altBg"
                    checked={!!checked}
                    disabled={disabled}
                    onChange={onCheck}
                  />
                </Iffy>
              </DNABox>
            </Iffy>
          </Stack.Layer>

          {/* PRESENTEDMETA */}
          <Stack.Layer anchor="bottomRight" style={{ padding: 3 }}>
            <Iffy is={presentedMeta}>
              <PresentedMetaComponent presentedMeta={presentedMeta!} />
            </Iffy>
          </Stack.Layer>

          {/* OVERLAY TEXT */}
          <Stack.Layer style={S.fullW}>
            <Iffy is={overlayText && overlayText !== ''}>
              <DNABox
                style={[S.overlayText, S.opaque]}
                fill
              >
                <DNAText status="basic" numberOfLines={2} c1>
                  {overlayText}
                </DNAText>
              </DNABox>
            </Iffy>
          </Stack.Layer>

          {/* REMOVEABLE TRASH ICON */}
          <Stack.Layer anchor="topRight">
            <Iffy is={mode === DNAThumbnailModes.REMOVABLE}>
              <DNAButton
                appearance="outline"
                status="dark"
                padding="xs"
                style={[S.opaque, cornerItemsStyles[size ?? 'md']]}
                iconLeft="close"
                onPress={DNAThumbnail.Modes.REMOVABLE ? onCheck : undefined}
              />
            </Iffy>
          </Stack.Layer>

          {/* ASSOCIATED SLIDE */}
          <Stack.Layer anchor="bottomRight" style={cornerItemsStyles[size ?? 'md']}>
            <Iffy is={variant === DNAThumbnailVariants.ASSOCIATED}>
              <DNAText
                appearance="outline"
                style={[S.pageNumber]}
              >
                { pageNumber }
              </DNAText>
            </Iffy>
          </Stack.Layer>
        </Stack>

        {/* SLIDE NUMBER & BADGE */}
        <Iffy is={(pageNumber !== undefined || isCover || isRequired)}>
          <DNABox testID="slide-title-container" alignY="start">
            <Iffy is={variant !== DNAThumbnailVariants.ASSOCIATED}>
              {/* PAGE NUMBER */}
              <DNAText
                testID="slide-number"
                numberOfLines={1}
                bold
                style={{ maxWidth: (width ?? 100) - badgesWidth, color: colors['color-gray-400'] }}
                status="flatAlt"
              >
                {`${pageNumber}. ${thumbnailTitle ?? `Slide ${pageNumber}`}`}
              </DNAText>
            </Iffy>
            <DNABox fill alignX="end" spacing="sm">
              <Iffy is={showEditButton}>
                <DNABox fill alignX="end" spacing="sm">
                  <Iffy is={showSpeakerNotesButton}>
                    <DNAButton
                      appearance="ghostLink"
                      status="tertiary"
                      size="sm"
                      onPress={() => onEditPageData?.(pageNumber, s3URL)}
                      iconRight="text-box-outline"
                      padding="none"
                      rounded="none"
                    />
                  </Iffy>
                  <DNAButton
                    appearance="ghostLink"
                    status="tertiary"
                    size="sm"
                    onPress={() => onEditPageData?.(pageNumber, s3URL)}
                    iconRight="pencil"
                    padding="none"
                    rounded="none"
                  />
                </DNABox>
              </Iffy>
              {/* COVER TAG */}
              <Iffy is={isCover}>
                <DNAChip appearance="tag">COVER</DNAChip>
              </Iffy>
              {/* REQUIRED TAG */}
              <Iffy is={isRequired}>
                <DNAChip appearance="tag" status="danger">REQUIRED</DNAChip>
              </Iffy>
            </DNABox>
          </DNABox>
        </Iffy>
      </DNABox>
    </InteractiveWrapper>
  );
}

// [NOTE] - https://stackoverflow.com/questions/59764130/adding-non-nullable-static-type-to-memoized-components-throws-lint-error-see-ex
export const DNAThumbnail: DNAThumbnail = Object.assign(
  {},
  React.memo<DNAThumbnailProps>((props) => {
    const ref = useRef(null)
    const imageLoader = useImageLoader(props)
    const isInView = useInView(ref, props.parentRef)

    useEffect(() => {
      if (isInView) {
        imageLoader.fetchImage()
      }
    }, [isInView, props.s3URL])

    const size =  props.width
      ? { height: props.height, width: props.width }
      : thumbnailSizeDimensions[props.size ?? 'sm']

    return (<View ref={ref}>
      {isInView && props.s3URL && (
        <DNABox>
          <Thumbnail {...props} {...imageLoader} />
        </DNABox>
      )}
      {!isInView && (
        // width + 100 temp fix for some blinking for the intercection observer for the medium size
        <DNABox style={{ height: size.height, width: size.width }} />
      )}
    </View>);
  }),
  {
    displayName: 'DNAThumbnail',
    Modes: DNAThumbnailModes,
    Variants: DNAThumbnailVariants,
  },
)

const useInView = (ref: React.RefObject<Element>, root?: ScrollView | null) => {
  const [isInView, setIsInView] = useState(false)

  const isVisibleInScrollContainer = useCallback((element, container) => {
    if (!element || !container) return false
    if (container === null) return false

    const elementRect = element.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    const elementTopRelativeToContainer = elementRect.top - containerRect.top;
    const elementBottomRelativeToContainer = elementRect.bottom - containerRect.top;
    const containerHeight = container.clientHeight;

    const isVerticalVisible =
      elementTopRelativeToContainer < containerHeight &&
      elementBottomRelativeToContainer > 0;

    return isVerticalVisible;
  }, [])

  const setVisibleDebounced = useCallback(debounce((isVisible) => {
    setIsInView(isVisible)
  }, 100), [])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // this is required due the dnd doesn't work properly with the intersection observer
        const isVisibleInParent = isVisibleInScrollContainer(entry.target, root)

        setVisibleDebounced(entry.isIntersecting || isVisibleInParent)
      },
      {
        rootMargin: '0px',
        threshold: 0,
        root: isElement(root) ? root : null,
      },
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [ref])

  return isInView
}

export default DNAThumbnail
