import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  DNABox,
  DNAButton,
  DNACollapsibleCard,
  DNADivider,
  DNAText,
  useWindowSize,
} from '@alucio/lux-ui';
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip';
import {
  GroupStatus,
  PayloadGroup, usePresentationBuilderState,
} from '../../state/PresentationBuilderStateProvider';
import { useDocumentVersionORM } from 'src/state/redux/selector/document';
import { v4 as uuid } from 'uuid';
import useThumbnailSelector from 'src/components/SlideSelector/useThumbnailSelector';
import PageGroupList, { GroupDraft } from 'src/components/SlideSelector/PageGroupList';
import { SlideMode } from 'src/components/SlideSelector/SlideSelector';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList';
import { usePresentationSelector } from '../../PresentationSelector/PresentationSelectorStateProvider';

const styles = StyleSheet.create({
  contentWrapper: {
    overflowY: 'auto',
    minWidth: '40vw',
  },
});

interface Props {
  documentVersionId: string;
  onReplacementSelected: (payloadGroup: PayloadGroup) => void;
}

const SlideSelector = (props: Props) => {
  const { width } = useWindowSize()
  const {
    onReplacementSelected,
    documentVersionId,
  } = props

  const {
    selectorThumbnailSize,
    cycleSelectorThumbnailSize,
  } = usePresentationBuilderState()
  const {
    initializeThumbnails,
  } = useThumbnailSelector({ mode: SlideMode.DocumentVersion })

  const {
    getAllGroups,
    getAllGroupedIndividualSlides,
    selectedDocumentVersionORM,
    setSelectedDocumentVersionORM,
    selectedGroups,
  } = usePresentationSelector()

  const allGroups = [...getAllGroups(), ...getAllGroupedIndividualSlides()]

  const documentVersionORM = useDocumentVersionORM(documentVersionId);

  useEffect(() => {
    /** TODO: This is the previous implementation and should be cleaned up
     * https://alucioinc.atlassian.net/browse/BEAC-3797
     */
    documentVersionORM && initializeThumbnails(documentVersionORM, false)

    /** TODO: This should be reworked in the cleanup of the old implementation.
     * https://alucioinc.atlassian.net/browse/BEAC-3797
     */
    setSelectedDocumentVersionORM(documentVersionORM)
  }, [documentVersionORM])

  const handleGroupSelect = (selectedGroup:GroupDraft) => {
    const newPayloadGroup:PayloadGroup = {
      id: uuid(),
      documentVersionORM:documentVersionORM,
      pages: selectedGroup.pages,
      groupSrcId: selectedGroup.id,
      groupStatus: GroupStatus.ACTIVE,
      visible: true,
      docAccessLevel: documentVersionORM?.relations.documentORM?.model.accessLevel!,
      name: selectedGroup.name,
      locked: selectedGroup.locked,
    }
    onReplacementSelected(newPayloadGroup)
  }

  if (!documentVersionORM) {
    return null;
  }

  return (
    <DNABox fill alignY="start" style={styles.contentWrapper}>
      <DNABox appearance="col" fill>
        <DNAText
          testID="select-file-update-text"
          status="warning"
          style={{ marginVertical : 16, marginHorizontal: 24 }}
        >
          {/* eslint-disable-next-line max-len */}
          Select from the updated file below to replace the corresponding items indicated on the right. If the updated file has additional required slides, those will also be automatically added.
        </DNAText>
        <DNADivider />
        {/* breadcrumb */}
        <DNABox
          alignY="center"
          style={{
            marginVertical: 10,
            marginHorizontal: 24,
            height: 80,
          }}
        >
          <DNABox appearance="col" spacing="sm">
            <DNAText b1>
              {documentVersionORM?.model.title}
            </DNAText>
            <DNABox appearance="row" spacing="md">
              <CustomFieldBadgeList documentVersionORM={documentVersionORM} />
              <DNADocumentChip
                item={documentVersionORM.relations.documentORM}
                variant="docType"
              />
            </DNABox>
          </DNABox>
          <DNABox fill alignX="end">
            <DNAButton
              appearance="ghost"
              status="tertiary"
              padding="sm"
              iconLeft={selectorThumbnailSize === 'sm' ? 'view-grid' : 'view-comfy'}
              onPress={cycleSelectorThumbnailSize}
            />
          </DNABox>
        </DNABox>
        <View style={{ marginHorizontal: 24 }}>
          <DNACollapsibleCard headerTitle="Release Notes" collapsedDefault>
            <DNAText style={{ lineHeight: 20 }}>{documentVersionORM.model.releaseNotes}</DNAText>
          </DNACollapsibleCard>
        </View>
        <DNABox fill style={{ marginTop: 37, paddingHorizontal: 24 }}>

          {/* TODO: This previously used the `PageGroupList` component.
          This should be refactored in https://alucioinc.atlassian.net/browse/BEAC-3797 */}
          <PageGroupList
            handleGroupSelect={handleGroupSelect}
            selectorThumbnailSize={width && width < 1050 ? 'md' : selectorThumbnailSize}
            allGroups={allGroups}
            documentVersionORM={selectedDocumentVersionORM}
            selectedGroups={selectedGroups}
          />
        </DNABox>
      </DNABox>
    </DNABox>
  )
};

SlideSelector.displayName = 'SlideSelector';

export default SlideSelector;
