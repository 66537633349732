import { DNABox, DNAButton, DNACheckbox, DNADivider, DNAText, Iffy, luxColors } from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'src/state/redux'
import { uniqueId } from 'xstate/lib/utils'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { StyleSheet } from 'react-native';

import { GroupStatus, usePresentationBuilderState } from '../state/PresentationBuilderStateProvider'
import { PrimaryActionButtons } from './PrimaryActionButtons/PrimaryActionButtons'
import SecondaryActionButtons from './SecondaryActionButtons/SecondaryActionButtons'
import FindAndReplace from './FindAndReplace/FindAndReplace'
import EditableText from '../../EditableText/EditableText';
import { EditMutex } from '@alucio/aws-beacon-amplify/src/models'
import { useUserById } from 'src/state/redux/selector/user'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import ReleaseNotesModal from 'src/components/DNA/Modal/ReleaseNotesModal'
import { DocumentVersionORM } from 'src/types/orms'
import SlidesGroupTarget from 'src/components/DnD/Clone/PresentationBuilder/SlidesGroupTarget'
import
RequiredSlidesHiddenConfirmation from 'src/components/DNA/Modal/DNAPresentationBuilder/RequiredSlidesHiddenConfirmation'

const styles = StyleSheet.create({
  headerMainWrapper: {
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
  },
  headerRow: {
    minHeight: 47,
    paddingHorizontal: 15,
    backgroundColor: 'white',
  },
  headerInformationRowBorder: {
    borderTopColor: colors['color-gray-80'],
    borderTopWidth: 1,
  },
  lastSeen: {
    marginLeft: 16,
    marginBottom: 12,
    marginTop: 12,
  },
  warningBanner: {
    backgroundColor: colors['color-warning-500'],
    height: 56,
  },
  bulkAction: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingVertical: 8,
  },
});

export interface Action extends DNAButtonProps {
  label?: string
  fill?: boolean
  hidden?: boolean
  tooltip?: string
}

export const usePresentationBuilderSharedResources = () => {
  const containerMargin = 15
  const getActionEls: (actions: Action[]) => JSX.Element[] = (actions) => {
    return actions.filter(action => !action.hidden).map(action => {
      const {
        disabled = false,
        iconLeft,
        onPress,
        label,
        appearance = 'ghostLink',
        status = 'tertiary',
        fill,
        style,
        size = 'md',
        tooltip,
        padding,
        testID,
      } = action

      const actionButton =
        (<DNAButton
          disabled={disabled}
          iconLeft={iconLeft}
          onPress={onPress}
          appearance={appearance}
          status={status}
          style={style}
          size={size}
          padding={padding}
          testID={testID}
        >
          {label}
        </DNAButton>)

      return (
        <DNABox
          key={uniqueId()}
          fill={fill}
        >
          {tooltip
            ? <DNAPopover placement="top" disablePopover={['tabletPWA']}>
              <DNAPopover.Anchor>
                {actionButton}
              </DNAPopover.Anchor>
              <DNAPopover.Content>
                <DNAText
                  style={{ color: colors['color-text-white'] }}
                >{tooltip}</DNAText>
              </DNAPopover.Content>

            </DNAPopover>
            : actionButton}
        </DNABox>
      )
    })
  }

  return {
    containerMargin,
    getActionEls,
  }
}

export const LastUpdated: React.FC = () => {
  const {
    customDeck,
  } = usePresentationBuilderState();

  const updatedAt = customDeck?.model.updatedAt
  const updatedBy = customDeck?.model.updatedBy
  const userORM = useUserById(updatedBy || '');
  const lastUpdatedDate = updatedAt
    ? new Date(updatedAt).toLocaleDateString()
    : null;

  const lastUpdatedTime = updatedAt
    ? new Date(updatedAt).toLocaleTimeString()
    : null;

  if (!updatedAt) return null;

  return (
    <DNAText testID="last-update" numberOfLines={1} status="flat">
      {`Last updated ${lastUpdatedDate} ${lastUpdatedTime} by ${userORM?.meta.formattedName}`}
    </DNAText>
  )
}

const HeaderMutexOverlay: React.FC<{
  editMutex: EditMutex
}> = (props) => {
  const { editMutex } = props
  const userORM = useUserById(editMutex?.userId || '');
  const { cancelPresentation } = usePresentationBuilderState()
  return (
    <DNABox
      appearance="row"
      alignY="center"
      style={styles.warningBanner}
      spacing="between"
    >
      <DNABox appearance="row" style={{ marginLeft: 12 }}>
        <DNAText status="basic" bold testID="current-user-editing-name">{`${userORM?.meta.formattedName} `}</DNAText>
        <DNAText status="basic">
          is currently editing. Only one person can edit a file at a time. Please come back later.
        </DNAText>
      </DNABox>
      <DNABox style={{ marginRight: 4 }}>
        <DNAButton
          size="xs"
          status="tertiary"
          onPress={cancelPresentation}
        >Close
        </DNAButton>
      </DNABox>
    </DNABox>
  )
}

const useBulkValidations = () => {
  const {
    selectedTargetItems,
    selectedGroups,
    associatedParentsMap,
  } = usePresentationBuilderState();

  const allSelectedVisible = useMemo(() => selectedTargetItems.every(item => {
    const group = selectedGroups.find(group => group.id === item)
    return group && group.visible
  }), [selectedTargetItems, selectedGroups])

  const allSelectedHidden = useMemo(() => selectedTargetItems.every(item => {
    const group = selectedGroups.find(group => group.id === item)
    return group && !group.visible
  }), [selectedTargetItems, selectedGroups])

  const someRequiredSlidesSelected = useMemo(() => {
    return selectedTargetItems.some(item => {
      const group = selectedGroups.find(group => group.id === item)
      return group &&
        group.pages.some(page => {
          const hasAssociatedParent = associatedParentsMap.get(page.pageId) && !page.isRequired
          return page.isRequired || hasAssociatedParent
        },
        )
    })
  }, [selectedTargetItems, selectedGroups])

  const toggleVisibilityText = allSelectedVisible
    ? 'Hide'
    : allSelectedHidden ? 'Show' : ''

  return {
    allSelectedVisible,
    someRequiredSlidesSelected,
    toggleVisibilityText,
  }
}

const Header: React.FC = () => {
  const {
    selectedGroups,
    title,
    setTitle,
    numOfRequiredSlides,
    saveAttemptedWithoutTitle,
    customDeck,
    selectedTargetItems,
    isLocked,
    isCustomDeckLockedByOthers,
    setSelectedGroups,
    setSelectedTargetItems,
    builderMode,
  } = usePresentationBuilderState();

  const dispatch = useDispatch();
  const [disablePreview, setDisablePreivew] = useState<boolean>(false);
  const [showUnavailableMessage, setShowUnavailableMessage] = useState<boolean>(false);
  const groupsIds = selectedGroups.map(({ id }) => id);
  const {
    allSelectedVisible,
    someRequiredSlidesSelected,
    toggleVisibilityText,
  } = useBulkValidations()
  const storeDispatch = useDispatch();

  const unresolvedArr = useMemo(() => {
    const resMap = new Map<string, { documentVersionORM: DocumentVersionORM, unresolvedCount: number }>();
    let disablePrev = false;
    let unavailable = false;
    const values = [GroupStatus.DELETED, GroupStatus.REVOKED, GroupStatus.ARCHIVED];
    unavailable = selectedGroups.some(({ groupStatus }) => {
      return values.includes(groupStatus);
    });
    selectedGroups.forEach(({ documentVersionORM, groupStatus }) => {
      if (documentVersionORM && groupStatus === GroupStatus.MAJOR_UPDATE) {
        const id = documentVersionORM.model.id;
        const unresolvedCount = (resMap.get(id)?.unresolvedCount ?? 0) + 1;
        resMap.set(id, {
          documentVersionORM,
          unresolvedCount,
        });
        disablePrev = true;
      }
    });
    disablePrev = unavailable || disablePrev;
    setShowUnavailableMessage(unavailable);
    setDisablePreivew(disablePrev);
    return Array.from(resMap.values());
  }, [selectedGroups])

  const toggleVisibility = useCallback(() => {
    setSelectedGroups((prev) =>
      prev.map((group) => {
        const isSelected = selectedTargetItems.includes(group.id);
        return {
          ...group,
          visible: isSelected
            ? toggleVisibilityText === 'Show'
            : group.visible,
        }
      }),
    )
    setSelectedTargetItems([]);
  }, [someRequiredSlidesSelected, selectedTargetItems, allSelectedVisible]);

  const handleVisibilityChange = useCallback(() => {
    if (someRequiredSlidesSelected && allSelectedVisible) {
      storeDispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: false,
        component: (props) =>
          (<RequiredSlidesHiddenConfirmation
            { ...props }
            onSave={toggleVisibility}
          />),
      }));
    } else {
      toggleVisibility();
    }
  }, [someRequiredSlidesSelected, selectedTargetItems, allSelectedVisible]);

  const remove = useCallback(() => {
    setSelectedGroups((prev) => (
      prev.filter((group) => !selectedTargetItems.includes(group.id))
    ))
    setSelectedTargetItems([]);
  }, [selectedTargetItems]);

  return (
    <DNABox appearance="col" style={styles.headerMainWrapper}>
      <Iffy is={isCustomDeckLockedByOthers}>
        <HeaderMutexOverlay editMutex={customDeck?.model.editMutex!} />
      </Iffy>

      <PrimaryActionButtons />

      <DNABox appearance="col">
        <EditableText
          wrapperStyle={styles.headerRow}
          value={title}
          disabled={isLocked}
          removeMarginPadding={true}
          onChangeText={setTitle}
          placeHolder="File Name"
          placeholderTextColor={luxColors.contentText.quaternary}
          required
          error={saveAttemptedWithoutTitle ? 'This field is required' : undefined}
          toggleShowInputIcon="pencil"
        />
        <DNABox appearance="col">
          {/* Las updated and secondary buttons  preview, sizes, settings */}
          <DNABox alignY="center" style={[styles.headerRow, { display: 'flex', justifyContent: 'space-between' }]}>
            <DNAText numberOfLines={1} bold status="flatAlt">
              <Iffy is={customDeck?.model.editMutex}>
                <LastUpdated />
              </Iffy>
            </DNAText>
            <Iffy is={selectedGroups.length}>
              <SecondaryActionButtons disablePreview={disablePreview} />
            </Iffy>
          </DNABox>
          <DNADivider />
          {/* Select all, bulk hide and delete */}
          <DNABox alignY="center" style={[styles.headerRow, styles.bulkAction]}>
            <DNABox spacing="md" alignY="center">
              {!!groupsIds.length && <DNABox>
                <DNACheckbox
                  disabled={!groupsIds.length}
                  checked={!!groupsIds.length && groupsIds.length === selectedTargetItems.length}
                  onChange={(isChecked) => {
                    setSelectedTargetItems(isChecked ? groupsIds : []);
                  }}
                >Select all</DNACheckbox>
              </DNABox>}

              {!!groupsIds.length && <DNAText status="flat" style={{ color: colors['color-gray-100'] }}>|</DNAText>}

              <DNABox>
                <DNAText status="flatAlt" bold>{selectedTargetItems.length} of {groupsIds.length} selected</DNAText>
              </DNABox>

              {!!selectedTargetItems.length && <DNABox alignY="center" spacing="sm">
                {/* hide button */}
                <DNAPopover disablePopover={['tabletPWA']}>
                  <DNAPopover.Anchor>
                    <DNAButton
                      disabled={!selectedTargetItems.length || toggleVisibilityText === ''}
                      iconLeft={toggleVisibilityText === 'Show' ? 'eye-outline' : 'eye-off-outline'}
                      padding="md"
                      appearance="outline"
                      status="tertiary"
                      onPress={handleVisibilityChange}
                    >
                      {builderMode === 'selection' ? '' : 'Hide'}
                    </DNAButton>
                  </DNAPopover.Anchor>
                  <DNAPopover.Content>
                    <DNAText status="basic">{toggleVisibilityText === '' ? 'Hide' : toggleVisibilityText}</DNAText>
                  </DNAPopover.Content>
                </DNAPopover>

                {/* remove button */}
                <DNAPopover disablePopover={['tabletPWA']}>
                  <DNAPopover.Anchor>
                    <DNAButton
                      disabled={
                      (!selectedTargetItems.length || someRequiredSlidesSelected) &&
                      // if all the items are selected this will work as clean all
                      !(selectedTargetItems.length === selectedGroups.length)
                  }
                      padding="md"
                      iconLeft="trash-can-outline"
                      appearance="outline"
                      status="tertiary"
                      onPress={remove}
                    >{builderMode !== 'selection' && 'Remove'}</DNAButton>
                  </DNAPopover.Anchor>
                  <DNAPopover.Content>
                    <DNAText status="basic">Remove</DNAText>
                  </DNAPopover.Content>
                </DNAPopover>
              </DNABox>}
            </DNABox>
          </DNABox>
        </DNABox>
        <DNADivider />
        <Iffy is={showUnavailableMessage}>
          <DNAText
            style={{
              paddingHorizontal: 16,
              paddingVertical: 10,
            }}
            status="warning"
          >
            If a slide is unavailable, it may have been deleted or you may not have access.
          </DNAText>
          <DNADivider />
        </Iffy>
        {Object.values(unresolvedArr).map(({
          documentVersionORM:{
            model:{ title },
            relations: { documentORM: { relations: { version: { latestPublishedDocumentVersionORM } } } },
          },
          unresolvedCount,
        }) => {
          return (
            <DNABox appearance="col" style={{ zIndex: -1 }} key={title}>
              <DNABox
                // spacing="between"
                alignY="center"
                style={{
                  paddingHorizontal: 16,
                  paddingVertical: 10,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <DNAText
                  status="warning"
                  numberOfLines={1}
                >
                  <DNAText status="warning" bold>{`${title} `}</DNAText>
                  was recently modified - {unresolvedCount} item(s) need to be resolved
                </DNAText>
                <DNAButton
                  appearance="outline"
                  status="tertiary"
                  size="xs"
                  padding="xs"
                  onPress={() => {
                    if (latestPublishedDocumentVersionORM) {
                      dispatch(DNAModalActions.setModal({
                        isVisible: true,
                        allowBackdropCancel: true,
                        component: (props) => (
                          <ReleaseNotesModal
                            {...props}
                            documentVersionORM={latestPublishedDocumentVersionORM}
                          />
                        ),
                      }))
                    }
                  }}
                >
                  View release notes
                </DNAButton>
              </DNABox>
              <DNADivider />
            </DNABox>
          )
        })}
        <Iffy is={numOfRequiredSlides > 0}>
          <DNABox alignY="center" style={[styles.headerRow, styles.headerInformationRowBorder]}>
            <DNAText numberOfLines={1} bold status="danger">
              {numOfRequiredSlides} additional required slide(s) added.
            </DNAText>
          </DNABox>
        </Iffy>
      </DNABox>
    </DNABox>
  )
}

const PresentationEditor: React.FC = () => {
  const { builderMode, editorThumbnailDimensions } = usePresentationBuilderState()
  const isCustomizationMode = builderMode === 'customization'
  const isReplaceMode = builderMode === 'replace'

  return (
    <>
      {isReplaceMode &&
        <FindAndReplace />
      }
      <DNABox
        fill
        appearance="col"
        style={{
          maxWidth: isCustomizationMode ? undefined : editorThumbnailDimensions.width + 106,
          borderLeftColor: colors['color-gray-80'],
          borderLeftWidth: isCustomizationMode ? undefined : 2,
          display: !isReplaceMode ? undefined : 'none',
        }}
      >
        <Header />
        <SlidesGroupTarget />
      </DNABox>
    </>
  )
}
export default PresentationEditor
