import React, { useEffect, useRef, useState } from 'react';
import { ToDo, TodoStatus } from '@alucio/aws-beacon-amplify/src/models';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNACheckbox, DNAContextMenu, DNAText, Iffy } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { Controller } from 'react-hook-form';
import InputComponent from 'src/components/Publishers/InputComponent';
import { useTodoState } from './ToDoListProvider';
import { Input } from '@ui-kitten/components';

export const styles = StyleSheet.create({
  label: {
    color: colors['color-gray-800'],
    fontWeight: 'bold',
    marginBottom: 4,
    marginTop: 4,
    textTransform: 'uppercase',
    fontSize: 12,
  },
  completed: {
    textDecorationLine: 'line-through',
  },
});

const ViewMode: React.FC<Props> = (props) => {
  const { item } = props;
  const { setEditingId, changeToDoStatus, handleOnCompleted } = useTodoState()
  const [isCompleted, setIsCompleted] = useState(item.status === TodoStatus.COMPLETED);

  const onChangeCompleted = (value: boolean) => {
    setIsCompleted(value);
    handleOnCompleted(item.todoId || '', value)
  };

  return (
    <DNABox fill alignY="start">
      <DNABox spacing="sm" fill >
        <DNACheckbox
          status="gray"
          checked={isCompleted}
          onChange={onChangeCompleted}
        />
        <DNABox appearance="col" spacing="sm" style={{ maxWidth: 'calc(100vw - 900px)' }}>
          <DNAText style={isCompleted ? styles.completed : {}} bold status="flatAlt">{item.title}</DNAText>
          <DNAText style={isCompleted ? styles.completed : {}} status="flatAlt">{item.resolution}</DNAText>
        </DNABox>
      </DNABox>
      <DNABox spacing="sm">
        <Iffy is={item.status !== TodoStatus.COMPLETED}>
          <DNAButton
            iconRight="pencil"
            appearance="ghostLink"
            status="gray"
            size="md"
            padding="none"
            onPress={() => setEditingId(item.todoId)}
          />
        </Iffy>
        <DNAContextMenu>
          <DNAContextMenu.Anchor>
            <DNAButton
              appearance="ghost"
              size="md"
              status="gray"
              padding="none"
              iconLeft="dots-vertical"
            />
          </DNAContextMenu.Anchor>
          <DNAContextMenu.Items>
            <DNAContextMenu.Item
              onPress={() => changeToDoStatus(item.todoId || '', TodoStatus.DELETED)}
              icon="trash-can-outline"
              status="danger"
              iconStatus="danger"
              title="Delete"
            />
          </DNAContextMenu.Items>
        </DNAContextMenu>
      </DNABox>
    </DNABox>
  );
};

const EditMode: React.FC<Props> = (props: Props) => {
  const { item } = props;
  const { rhform, onCancelEdit, onSaveTodo, toDos, isNewItem } = useTodoState()
  const [title] = useState(item.title);
  const [resolution] = useState(item.resolution);
  const index = toDos.findIndex((todo) => todo.todoId === item.todoId)
  const { control } = rhform
  const error = rhform.errors?.todoListWidget?.toDos?.[index]
  const titleRef = useRef<Input>(null);
  const resolutionRef = useRef<Input>(null);

  const onCancel = () => {
    if (!isNewItem) {
      rhform.setValue(`todoListWidget.toDos[${index}]`, { ...item, title, resolution })
    }
    onCancelEdit()
  }

  useEffect(() => {
    titleRef.current && isNewItem ? titleRef.current.focus() : resolutionRef.current?.focus();
  }, []);

  return (
    <DNABox appearance="col" alignY="center" spacing="sm">
      <DNABox spacing="xs" fill appearance="col">
        <Controller
          name={`todoListWidget.toDos[${index}].title`}
          title="Task Name"
          required={true}
          characterLimit={150}
          showCharacterCounter={false}
          control={control}
          removeMarginPadding
          defaultValue={item.title}
          render={({ onChange, value }) => (
            <InputComponent
              onChangeText={onChange}
              value={value}
              title="Task Name"
              characterLimit={150}
              removeMarginPadding
              onKeyPress={(e) => {
                if (e.nativeEvent.key === 'Enter') {
                  e.preventDefault();
                  resolutionRef.current?.focus();
                }
              }}
              ref={titleRef}
            />
          )}
        />
        {error?.title &&
          <DNAText style={{ color: colors['color-danger-500'], marginTop: 4 }}>
            <>
              {Array.isArray(error.title)
                ? error.title.map((e, idx) => <DNABox key={`title_${idx}_error_message`}>{e}</DNABox>)
                : error.title?.message}
            </>
          </DNAText>
        }
      </DNABox>

      <Controller
        name={`todoListWidget.toDos[${index}].resolution`}
        required={false}
        characterLimit={500}
        showCharacterCounter={false}
        control={control}
        multiline
        removeMarginPadding
        defaultValue={item.resolution}
        render={({ onChange, value }) => (
          <InputComponent
            onChangeText={onChange}
            value={value}
            title="Resolution / Description"
            characterLimit={500}
            removeMarginPadding
            multiline
            showCharacterCounter={false}
            required={false}
            numOfLines={5}
            onKeyPress={({ nativeEvent }) => {
              if (nativeEvent.key === 'Enter' && rhform.getValues(`todoListWidget.toDos[${index}].title`)) {
                onSaveTodo()
              }
            }}
            ref={resolutionRef}
          />
        )}
      />
      <DNABox spacing="between">
        <DNABox />
        <DNABox spacing="sm">
          <DNAButton
            appearance="outline"
            status="tertiary"
            padding="sm"
            children="Cancel"
            onPress={() => onCancel()}
          />
          <DNAButton
            padding="sm"
            children="Done"
            onPress={() => onSaveTodo()}
          />
        </DNABox>
      </DNABox>
    </DNABox >
  );
};

type Props = {
  item: Partial<ToDo>
}

const TodoRow: React.FC<Props> = (props) => {
  const { editingId } = useTodoState()
  const { item } = props;
  const mode = item.todoId === editingId ? 'edit' : 'view';
  return (
    <DNABox
      alignY="center"
      appearance="col"
      spacing="md"
      fill
      style={{ paddingVertical: 15 }}
    >
      <Iffy is={mode === 'view'}>
        <ViewMode {...props} />
      </Iffy>
      <Iffy is={mode !== 'view'}>
        <EditMode {...props} />
      </Iffy>
    </DNABox>
  );
};

export default TodoRow;
