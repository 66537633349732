import React from 'react'
import { useDispatch } from 'src/state/redux'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import {
  DNAModal,
  DNABox,
  useDisableSwipe,
  DNAButton,
  DNAText,
} from '@alucio/lux-ui'
import { StyleSheet } from 'react-native';
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal'
import { ContentPageData } from 'src/hooks/useContentPageData/useContentPageData'
import { useForm, Controller } from 'react-hook-form'
import DNAThumbnail from '../Thumbnail/DNAThumbnail';
import InputComponent from 'src/components/Publishers/InputComponent'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

const styles = StyleSheet.create({
  input: {
    minWidth:600,
  },
})

interface DNAUpdateSlideDataProps {
  pageData: ContentPageData,
  pageNumber: number,
  thumbURL?: string,
  send: (event: any) => void,
}

const DNAUpdateSlideData: DNAModalVariant<DNAUpdateSlideDataProps> = ({
  toggleModal,
  pageData,
  pageNumber,
  thumbURL,
  send,
}) => {
  useDisableSwipe()
  const dispatch = useDispatch()

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      title: pageData?.title,
      speakerNotes: pageData?.speakerNotes,
    },
  })

  const {
    control,
    handleSubmit,
    formState: {
      isDirty,
    },
  } = form
  const onAction = (data: { title?: string; speakerNotes?: string; }) => {
    pageData.title = data.title
    pageData.speakerNotes = data.speakerNotes
    send({ type: 'SET_IS_DIRTY', payload: { type: 'slidesData', isDirty: true } })
    dispatch(DNAModalActions.toggleModal())
  }

  return (
    <DNAModal>
      <DNAModal.Header onClose={toggleModal}>
        Edit slide details
      </DNAModal.Header>
      <DNAModal.Body>
        <DNABox spacing="md" alignY="center">
          <DNABox appearance="col" spacing="xs">
            <DNABox
              appearance="row"
              style={{ borderWidth: 1, borderColor: colors['color-gray-80'], padding: 20 }}
            >
              <DNAThumbnail
                variant={DNAThumbnail.Variants.DEFAULT}
                key={thumbURL}
                s3URL={thumbURL}
                size="xl"
              />
            </DNABox>
            <DNAText status="flat" bold>{`${pageNumber}.`}</DNAText>
          </DNABox>

          <DNABox appearance="col" spacing="md">
            <Controller
              as={InputComponent}
              name="title"
              inputStyle={styles.input}
              title="Title"
              required={false}
              characterLimit={100}
              showCharacterCounter={true}
              control={control}
              removeMarginPadding
            />
            <Controller
              as={InputComponent}
              name="speakerNotes"
              title="Speaker Notes"
              required={false}
              multiline
              numOfLines={9}
              control={control}
              removeMarginPadding
              characterLimit={1000}
              showCharacterCounter={true}
            />
          </DNABox>
        </DNABox>
      </DNAModal.Body>
      <DNAModal.Confirmation>
        <DNABox alignY="center" spacing="sm">
          <DNAButton size="sm" onPress={toggleModal} status="tertiary">
            Cancel
          </DNAButton>
          <DNAButton
            size="sm"
            disabled={!isDirty}
            testID="confirmation-button"
            onPress={handleSubmit(onAction)}
            status="primary"
          >
            Done
          </DNAButton>
        </DNABox>
      </DNAModal.Confirmation>
    </DNAModal>
  )
}

export default DNAUpdateSlideData
