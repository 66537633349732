import React, { useMemo, useState } from 'react';
import { WidgetContentCardProps, WidgetContextMenu, widgetContentCardStyles } from './WidgetContentCard';
import { DNABox, DNAButton, DNAText, Iffy } from '@alucio/lux-ui';
import { HubSectionType, TodoStatus } from '@alucio/aws-beacon-amplify/src/models';
import ToDoRow from './ToDoComponents/ToDoRow';
import { arrayMove } from '@dnd-kit/sortable';
import DNARearranger from 'src/components/DnD/DNARearranger';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { StyleSheet } from 'react-native';
import { TodoProvider, useTodoState } from './ToDoComponents/ToDoListProvider';

export const styles = StyleSheet.create({
  draggableWidgetCard: {
    paddingLeft: 28,
    paddingVertical: 15,
  },
  widgetCardOverlay: {
    borderTopColor: colors['color-gray-80'],
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
  },
  completedHeader: {
    backgroundColor: colors['color-gray-20'],
    borderRadius: 6,
    marginTop: 20,
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  todoContainer: {
    borderBottomWidth: 1, 
    borderBottomColor: colors['color-gray-80'],
  }
});

const TodoList: React.FC = () => {
  const [activeItemId, setActiveItemId] = useState()
  const { toDos, onSaveTodo, addTodo, editingId, setShowAll, showAll, showCompleted } = useTodoState()

  const activeToDos = toDos.filter(p => p.status === TodoStatus.ACTIVE)
  const activeTodo = useMemo(() => toDos.find(todo => todo.todoId === activeItemId), [activeItemId])
  const items = activeToDos.map((toDo) => {
    return {
      id: toDo.todoId || '',
      itemId: toDo.todoId,
    }
  })

  const handleDragStart = (event) => {
    const { active } = event
    if (active.id) {
      setActiveItemId(active.id)
    }
  }

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id && activeToDos.length > 1) {
      const oldIndex = toDos.findIndex((item) => item.todoId === active.id);
      const newIndex = toDos.findIndex((item) => item.todoId === over.id);
      const arr = arrayMove(toDos, oldIndex, newIndex);
      const newOrder = arr.map((item, index) => {
        return { ...item, order: index }
      })
      onSaveTodo(newOrder);
    }
    setActiveItemId(undefined);
  }

  const completedToDos = toDos.filter(p => p.status === TodoStatus.COMPLETED)

  return (
    <>
      <DNABox fill style={widgetContentCardStyles.cardHeader}>
        <DNABox
          fill
          alignY="center"
          spacing="between"
          childFill={0}
        >
          <DNABox fill childFill={0} alignY="center" spacing="sm">
            <DNAText h4 bold numberOfLines={1}>To-Do List</DNAText>
          </DNABox>
          <DNABox alignY="center" spacing="sm">
            <DNAButton
              iconLeft="plus"
              appearance="outline"
              status="tertiary"
              onPress={() => addTodo()}
            >
              Add Item
            </DNAButton>
            <WidgetContextMenu widgetType={HubSectionType.TODO_LIST} />
          </DNABox>
        </DNABox>
      </DNABox>
      <DNABox fill appearance="col" spacing="md" alignY="center" style={widgetContentCardStyles.cardBody}>
        <Iffy is={activeToDos.length === 0}>
          <DNAText status="flatAlt" >Add to-do items to see them appear here</DNAText>
        </Iffy>
        <Iffy is={toDos.length > 0}>
          <DNARearranger
            collisionDetection={'closestCenter'}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            modifiers={['restrictToVerticalAxis']}
            items={items}
            strategy={'verticalListSortingStrategy'}
            overlay={activeTodo && (
              <DNARearranger.SortableItem
                id={activeTodo.todoId || ''}
                itemId={activeTodo.todoId || ''}
                containerId="todoContainer"
                leftIcon="drag-vertical"
                handle
                boxContainerStyle={styles.widgetCardOverlay}
              >
                <DNABox fill style={styles.draggableWidgetCard} >
                  <DNABox appearance="col" spacing="sm" style={{ maxWidth: 'calc(100vw - 900px)' }}>
                    <DNAText bold status="flatAlt">{activeTodo.title}</DNAText>
                    <DNAText status="flatAlt">{activeTodo.resolution}</DNAText>
                  </DNABox>
                </DNABox>
              </DNARearranger.SortableItem>
            )}
          >
            {
              activeToDos.map((item, index) => {
                if ((index > 4 && !showAll)) return null;
                return (
                  <React.Fragment key={item.todoId}>
                    <DNARearranger.SortableItem
                      id={item.todoId || ''}
                      itemId={item.todoId || ''}
                      containerId="base"
                      disabled={editingId === item.todoId}
                      leftIcon="drag-vertical"
                      handle
                      boxContainerStyle={styles.todoContainer}
                    >
                      <ToDoRow item={item} />
                    </DNARearranger.SortableItem>
                  </React.Fragment>
                )
              },
              )
            }
          </DNARearranger>
          <Iffy is={activeToDos.length > 5}>
            <DNAButton
              appearance="outline"
              status="tertiary"
              onPress={() => setShowAll(!showAll)}
            >
              {showAll ? 'Show Less' : 'Show All'}
            </DNAButton>
          </Iffy>
        </Iffy>
        <Iffy is={showCompleted}>
          <DNABox fill appearance="col" spacing="md" alignY="center">
            <DNABox style={styles.completedHeader}>
              <DNAText bold>Completed </DNAText>
              <DNAText bold>{`(${completedToDos.length})`}</DNAText>
            </DNABox>
            <Iffy is={completedToDos.length === 0}>
              <DNAText status="flatAlt" >No completed items</DNAText>
            </Iffy>
            <DNABox fill appearance="col" spacing="md" alignY="center" style={{...styles.todoContainer ,paddingLeft: 56 }}>
              {
                completedToDos.map((item) => {
                  return (
                    <ToDoRow key={item.todoId} item={item} />
                  )
                },
                )
              }
            </DNABox>
          </DNABox>
        </Iffy>
      </DNABox>
    </>
  );
};

const TodoListWrapper: React.FC<WidgetContentCardProps> = (props) => {
  return (
    <TodoProvider {...props}>
      <TodoList />
    </TodoProvider>
  );
}

export default TodoListWrapper;
