import React from 'react';
import { DNABox, DNASlider } from '@alucio/lux-ui/src';
import Header from 'src/screens/Hubs/EditHub/Header';
import LeftSideBar from 'src/screens/Hubs/EditHub/LeftSideBar';
import Content from 'src/screens/Hubs/EditHub/Content';
import RightSideBar from 'src/screens/Hubs/EditHub/RightSideBar';
import { HubORM } from 'src/types/orms';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider';
import { HubsStateProvider } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import HubRHFormStateProvider from './useHubRHFormStateProvider';

interface EditHubProps {
  hubORM: HubORM
}

const EditHub: React.FC<EditHubProps> = ({ hubORM }) => {
  const hubId = hubORM.model.id

  return (
    <HubRHFormStateProvider hubId={hubId}>
      <DNABox fill appearance="col">
        <Header hubORM={hubORM}/>
        <DNABox fill>
          <LeftSideBar/>
          <Content/>
          <RightSideBar hubORM={hubORM}/>
        </DNABox>
      </DNABox>
    </HubRHFormStateProvider>
  )
}

const EditHubWrapper: React.FC = () => {
  const hubState = useHubsState()
  const { hubORM, onInvisibleCallback, editHubSliderVisible, setEditHubSliderVisible } = hubState

  if (!hubORM) return null
  return (
    <DNASlider
      visible={editHubSliderVisible}
      setVisible={setEditHubSliderVisible}
      orientation="horizontal"
      onInvisible={() => {
        if (typeof onInvisibleCallback.current === 'function') {
          onInvisibleCallback.current?.()
        }
      }}
    >
      <HubsStateProvider value={hubState}>
        <EditHub hubORM={hubORM} />
      </HubsStateProvider>
    </DNASlider>
  )
}

export default EditHubWrapper
