import React,
{
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  PropsWithChildren,
} from 'react'
import { FolderORM, FolderItemORM, ORMTypes } from 'src/types/types'

import {
  getRootFolderORM,
  useAllFolders,
  useAllFoldersInstance,
  useIsSharedFoldersHydrated,
  useIsSharedFoldersStatus,
  useSharedFolders,
} from 'src/state/redux/selector/folder'
import { sortCollection, FilterAndSortOptions } from 'src/state/redux/selector/common'
import folderQuery from 'src/state/redux/folder/query'
import folderItemQuery from 'src/state/redux/folderItem/query'
import { FolderItemType } from '@alucio/aws-beacon-amplify/src/models'
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'src/state/redux'
import { sharedFolderActions } from 'src/state/redux/slice/sharedFolder'
import { SliceStatus } from 'src/state/redux/slice/common'

const NESTED_DEPTH = 2
const DEFAULT_FILTER = folderQuery.sorts.pinnedAsc

/** Context */
type DNAFolderNavContextType = {
  folderStack: FolderORM[],
  allFolders: FolderORM[],
  currentFolders: FolderORM[],
  currentItems: FolderItemORM[]
  pushFolder: (f: FolderORM) => void,
  popFolder: () => void,
  popToFolder: (f?: FolderORM) => void,
  isMaxDepth: boolean,
  isCurrentlyNested: boolean,
  isFolderListLoaded: boolean,
  displaySharedFolders: boolean,
  setDisplaySharedFolders: (display: boolean) => void,
}

type SetFolderStack = React.Dispatch<React.SetStateAction<FolderORM[]>>

const DNAFolderNavContext = createContext<DNAFolderNavContextType>({
  folderStack: [], // [TOOD]: Consider support Document/Version on the stack?
  allFolders: [],
  currentFolders: [],
  currentItems: [],
  pushFolder: () => { },
  popFolder: () => { },
  popToFolder: () => { },
  isMaxDepth: false,
  isFolderListLoaded: false,
  isCurrentlyNested: false,
  displaySharedFolders: false,
  setDisplaySharedFolders: () => { },
})
DNAFolderNavContext.displayName = 'DNAFolderNav'

/** Factories */
const usePushFolder = (setState: SetFolderStack) => useCallback(
  (folderORM: FolderORM) => {
    setState(p => {
      if (p.length === NESTED_DEPTH) {
        console.error('Cannot exceed nested folder depth')
        return p
      }

      return [...p, folderORM]
    })
  }, [],
)

const usePopFolder = (setState: SetFolderStack) => useCallback(
  () => { setState(p => p.slice(0, -1)) },
  [],
)

const usePopToFolder = (setState: SetFolderStack) => useCallback(
  (folderORM?: FolderORM) => {
    if (!folderORM) {
      setState([])
      return;
    }

    setState(p => {
      const targetIdx = p.findIndex(f => f.model.id === folderORM.model.id)

      if (targetIdx === -1) {
        console.error('Did not find folder to pop to')
        return p
      }

      return p.slice(0, targetIdx + 1)
    })
  },
  [],
)

type DNAFolderNavProps = {
  query?: FilterAndSortOptions<FolderORM>
}

const DNAFolderNav: React.FC<PropsWithChildren<DNAFolderNavProps>> = (props) => {
  const { query = DEFAULT_FILTER, children } = props

  const location = useLocation();
  const [folderStack, setFolderStack] = useState<FolderORM[]>([])
  const [displaySharedFolders, setDisplaySharedFolders] = useState<boolean>(false);
  const sharedFolders =  useSharedFolders();
  const isShareFolderStatusPending = useIsSharedFoldersStatus(SliceStatus.PENDING)
  const ownFolders = useAllFolders();
  const isSharedFoldersHydrated = useIsSharedFoldersHydrated();
  const allFolders = displaySharedFolders ? sharedFolders : ownFolders;
  const isFolderListLoaded = displaySharedFolders
    ? isSharedFoldersHydrated && !isShareFolderStatusPending
    : true;
  const history = useHistory();
  const dispatch = useDispatch()
  const isMeetingRoute = location.pathname.includes('meeting-presentation')
  const isFolderRoute = location.pathname.includes('folder')

  /**
   * TODO: Investigate necessity of this when using in meeting panel shared folder tab (probably should just disable in that case).
   * Currently breaking the meetings panel if we have it always enabled
   */
  useEffect(() => {
    if (displaySharedFolders && isSharedFoldersHydrated && !isMeetingRoute) {
      const pathTokens = location.pathname.split('/').slice(1)
      for (let i = 1; i < pathTokens.length; i++) {
        const folderORMFromPath = allFolders
          .find(folderORM => folderORM.model.id === pathTokens[i])
        if (!folderORMFromPath) {
          history.replace(`/${pathTokens[0]}`)
          return;
        }
        pushFolder(folderORMFromPath)
      }
    }
  }, [displaySharedFolders, isSharedFoldersHydrated])

  // we need to detect if the current folder has an external dependency on it
  // when the user do a f5 or refresh the page
  useEffect(() => {
    const init = () => {
    // extract from the path the uuid of the folder
      const pathTokens = location.pathname.split('/').slice(1)
      if (pathTokens.length === 0) {
        return;
      }

      const folderId = pathTokens.reverse()[0];
      const folderORMFromPath = allFolders
        .find(folderORM => folderORM.model.id === folderId)
      if (folderORMFromPath) {
        const rootFolder = getRootFolderORM(
          folderORMFromPath.relations.parentFolderORM ||
          folderORMFromPath,
        )

        rootFolder?.meta.hasExternalDependencies &&
      dispatch(sharedFolderActions.getFolderExternalDependencies({
        folderId: folderORMFromPath.model.id,
        parentFolderId: rootFolder.model.id,
      }))
      }
    }

    allFolders && allFolders.length > 0 && isFolderRoute && init()
  }, [isFolderRoute, allFolders.length])

  // Folder stored in folderStack can become stale
  //  Be sure to update them if allFolders change otherwise a stale ORM can mess up datastore
  //  We do sometimes fetch the latest ORM at the dispatch level instead,
  //  but still a good idea to update stack references
  useEffect(
    () => {
      setFolderStack(p => {
        let modifiedFlag = false

        const updatedRef = p.reduce(
          (acc, fol) => {
            const sourceFolder = allFolders.find(srcFol => srcFol.model.id === fol.model.id)

            if (!sourceFolder) {
              console.error('Something went wrong -- folder stack not in sync with all folders')
              return acc
            }

            if (fol !== sourceFolder) {
              modifiedFlag = true
              return [...acc, sourceFolder]
            }

            return [...acc, fol]
          },
          [] as FolderORM[],
        )

        return modifiedFlag ? updatedRef : p
      })
    },
    [allFolders, folderStack],
  )

  // ** Util Fns **
  const pushFolder = usePushFolder(setFolderStack)
  const popFolder = usePopFolder(setFolderStack)
  const popToFolder = usePopToFolder(setFolderStack)

  // ** Util **
  const isCurrentlyNested = !!folderStack.length
  const isMaxDepth = folderStack.length === NESTED_DEPTH

  // ** Get current folder **
  const stackIds = folderStack.map(folderORM => folderORM.model.id)
  const idFilter: FilterAndSortOptions<FolderORM> = isCurrentlyNested
    ? { filter: { model: { id: stackIds } } }
    : {}
  const currentFilter = folderQuery.merge(query, idFilter)
  const currentFolders = useAllFoldersInstance(currentFilter, displaySharedFolders)

  const currentActiveFilter = folderQuery.merge(query, idFilter, folderQuery.filters.active)
  const currentActiveFolders = useAllFoldersInstance(currentActiveFilter, displaySharedFolders)

  // ** Sort current folder items **
  // [TODO-3445] - Why are we stubbing an ORM here?

  const currentItemsUnsorted = isCurrentlyNested
    ? folderStack.slice(-1)[0]?.relations.items ?? []
    : currentActiveFolders
      .filter(folder => folder.relations.parentFolderORM === null)
      .map((folder: FolderORM): FolderItemORM => {
        return {
          model: {
            id: folder.model.id,
            itemId: folder.model.id,
            addedAt: folder.model.createdAt,
            itemLastUpdatedAt: folder.model.updatedAt,
            type: FolderItemType.FOLDER,
          },
          type: ORMTypes.FOLDER_ITEM,
          relations: {
            itemORM: folder,
          },
          meta: {
            title: folder.model.name,
            assets: { },
            hasAutoUpdatedItem: folder.meta.version.containsAutoUpdatedItem,
            hasOutdatedItem: folder.meta.version.containsOutdatedDocVer,
          },
        }
      })
  //  Nor does it seem support receiving a programmatic folder stack update (concurrent delete)
  const currentItems = sortCollection(
    currentItemsUnsorted,
    folderItemQuery.sorts.folderDisplayAsc,
  )

  return (
    <DNAFolderNavContext.Provider
      value={{
        folderStack,
        allFolders,
        currentFolders,
        currentItems,
        pushFolder,
        popFolder,
        popToFolder,
        isMaxDepth,
        isCurrentlyNested,
        isFolderListLoaded,
        displaySharedFolders,
        setDisplaySharedFolders,
      }}
    >
      { children}
    </DNAFolderNavContext.Provider>
  )
}

export const useDNAFolderNav = () => useContext(DNAFolderNavContext)

export const withDNAFolderNav = (Component, query?: DNAFolderNavProps['query']) => (props) => (
  <DNAFolderNav {...query ? { query } : {}}>
    <Component {...props} />
  </DNAFolderNav>
)
export default DNAFolderNav
