import { Auth } from '@aws-amplify/auth'
import CacheDB from 'src/worker/db/cacheDB';
import ActiveUser from 'src/state/global/ActiveUser'
import { DataStore } from '@aws-amplify/datastore';
import workerChannel from 'src/worker/channels/workerChannel'
import store, { storeReset } from '../../../state/redux/store';
import { Hub, Logger } from '@aws-amplify/core'
import { deleteDB } from 'idb';
import { AlucioChannel } from '@alucio/lux-ui';
import { SessionStatus } from '../../IdleComponent/IdleComponent';
import { WebLinking as Linking } from '@alucio/core';
import { isIOS } from 'react-device-detect'

// Analitycs DB
import { clear as clearAnalyticsQueue } from 'src/utils/analytics/queueIdb';
import { withStore as withclearAnalyticsStore } from 'src/utils/analytics/index';
import { CRMUtil } from 'src/state/machines/CRM/util';
import setupCacheDB from 'src/worker/db/setupCacheDB';

interface IAuthUtil {
  DBS: string[];
  cacheDB: CacheDB;
  crmDB: CRMUtil;
  prevAuthUserKey: string;
  idleChannelName: string;
  cleanLocalStorageKeys: string[];
  disableWorkerOnLogOut: boolean;
  logger: Logger;
  isLoggingOut: boolean;
  purgeCacheDB: () => Promise<void>;
  clearUserDetailLocalStorage: () => void;
  cleanCRMData: () => Promise<void>;
  resetRedux: () => void;
  /**
   * when the user is logged out we show a message to the user
   * and we send a message to the alucio channel to notify the user is logged out
   */
  sendLogOutMessageToChannel: (reason: SessionStatus) => void;
  pauseSync: () => void;
  /**
   *  method in charge to clean the data store indexDB when is required
   */
  clearDataStore: () => Promise<void>;
  hubDispatch(): void;
  setPrevAuthUser(): Promise<void>;
  getPrevAuthUser(): string | null;
  clearOfflineAnalyticsDB(): Promise<void>;
  cleanGlobalState(): void;
}

class AuthUtil implements IAuthUtil {
    DBS = ['amplify-datastore']
    cacheDB : CacheDB
    crmDB : CRMUtil;
    prevAuthUserKey = 'prevAuthUser'
    idleChannelName = 'beacon-idle'
    cleanLocalStorageKeys = [
      'amplify-latest-user-attributes',
    ]

    disableWorkerOnLogOut = false
    logger: Logger
    isLoggingOut = false
    isStarted = false

    constructor() {
      this.cacheDB = new CacheDB()
      this.crmDB = new CRMUtil()
      this.logger = new Logger('Authenticator', 'INFO');
    }

    /*
      it is in charge to clean the index db used for offline mode to cache all the documents
    */
      public purgeCacheDB = async () => {
        try {
          const cacheDB = this.cacheDB

          if (!cacheDB) {
            console.error('cacheDB is not defined')
            return
          }

          if (!cacheDB) throw new Error('CacheDB is not initialized')

          await cacheDB.open()
          await cacheDB.purge()
          await cacheDB.close()

          // for the restriction of safary of clearing the index db only after the pwa is closed
          // we delete the db and we create the db again, this seems to work at least on the simulator
          if (isIOS) {
            await deleteDB('CacheDB')
            await setupCacheDB()
          }
        } catch (e) {
          console.warn('Error occured purging cacheDB', e)
        }
      }

      /*
        Clear user details in local storage (used for offline)
      */
      public clearUserDetailLocalStorage = () => {
        this.cleanLocalStorageKeys.forEach(key => localStorage.removeItem(key))
      }

      /*
          Clean the database used to store the CRM data
      */
      public cleanCRMData = async () => {
        await this.crmDB.cleanLogOutCRMStorage()
      }

    /*
        clean the redux store
    */
    public resetRedux = () => {
      store.dispatch(storeReset())
    }

    /*
      In charge of distpach the amplify event for logout
    */
    public hubDispatch() {
      Hub.dispatch('logout', { event: 'logout' });
    }

    /*
      @param {string} username - identifier that will be used to check if the user was logged previously
      used to store the previous user logged email
      this will check next time that the user is logged in
      and if the user is the same we will not purge clean some index DBS
    */
    public async setPrevAuthUser() {
      const userEmail = await this.getCurrentUserEmail()
      userEmail && localStorage.setItem(this.prevAuthUserKey, userEmail.toLocaleLowerCase())
    }

    /*
        @return {string} - the previous user logged email
    */
    public getPrevAuthUser() {
      return localStorage.getItem(this.prevAuthUserKey)
    }

    /**
     * when the user is logged out we show a message to the user
     * and we send a message to the alucio channel to notify the user is logged out
     */
     public sendLogOutMessageToChannel = (
       reason : SessionStatus,
     ) => {
       AlucioChannel.get(this.idleChannelName)?.postMessage(reason);
     }

     public pauseSync = () => {
       workerChannel.postMessageExtended({ type: 'PAUSE_SYNC' })
     }

    /**
     *  method in charge to clean the data store indexDB when is required
     */
    public clearDataStore = async () => {
      await DataStore.clear()
      await DataStore.stop()
      return Promise.resolve()
    }

    /**
     * start data store
     * after a multiple checks we found that the data store is not starting
     * after the user is logged out
     * this method is in charge to start the data store
     * we have a wait of 1 second to make sure that the data store is started
     * this method is a good candidate to a patch to do a better implementation
     */
    public async dataStoreInit() {
      this.startDataStore()
    }

    /**
     * Sometimes data store failes to start could be multiple reason for instance the db is not ready
     * we want to make sure that the data store is started
     */
    private startDataStore = async () => {
      if (this.isStarted) return
      await DataStore.start()
      this.enforceDataStoreInit()
    }

    /**
     * Data store is not starting after the user is logged in due multiple reasons, some of those are still unknown
     * this method is in charge to make sure that the data store is started to avoid the infinite loading spinner
     */
    private enforceDataStoreInit = async () => {
      const interval = setInterval(() => {
        // eslint-disable-next-line dot-notation
        if (!DataStore['initialized']) {
          DataStore.start()
          clearInterval(interval)
        }
      }, 1000)
    }

    /*
      clean analytics DB
      this db is connected with segment is in charge to save the events that occurs during the offline mode
      and sync them with segment when the user is online
    */
    public async clearOfflineAnalyticsDB() {
      try {
        await clearAnalyticsQueue(withclearAnalyticsStore);
      }
      catch (e) {
        console.warn('Error clearing analytics queue', e)
      }
    }

    /*
          we used a global var to store the active user this should clean all the data related to the user in the global state
      */
    public cleanGlobalState() {
      ActiveUser.clear()
    }

    public clearPrevAuthUser = () => {
      localStorage.removeItem(this.prevAuthUserKey)
    }

    /*
      get the current user email if the user is logged if not an undefined value will be returned
    */
    public async getCurrentUserEmail() : Promise<string | undefined> {
      const validUser = await this.isAValidUser()
      if (!validUser) return

      const userInfo = await Auth.currentUserInfo()

      return userInfo?.attributes?.email
    }

    /**
     * remove all indexedDBs declared on the internal var DBS
     */
    public async deleteDBs() {
      const dbsToDelete = this.DBS
      for (const idbName of dbsToDelete) {
        try {
          await deleteDB(idbName)
        } catch (e) {
          console.warn(`Error deleting indexDB ${idbName}`, e)
        }
      }
    }

    /*
      clear the storage keys after logout
    */
    private clearLocalStorage() {
      this.cleanLocalStorageKeys.forEach(key => localStorage.removeItem(key))
    }

    /*
       used to check if the user session is still valid
    */
    private async isAValidUser () : Promise<boolean> {
      try {
        await Auth.currentUserInfo()
        return true
      }
      catch (e) {
        return false
      }
    }

  /*
      method in charge to remove the webworker
    */
  public static disableServiceWorker = async () => {
    // We subscribe to watch for a purge success message from the SW before terminating
    // the service worker
    workerChannel
      .observable
      .filter(msg => msg.type === 'CACHE_DB' && msg.value === 'PURGE_COMPLETE')
      .subscribe(async () => {
        const registration = await navigator.serviceWorker.getRegistration('/')
        registration?.unregister().then(() => {
          Linking.openURL('/profile/offline', '_self');
        })
      })

    workerChannel.postMessageExtended({
      type: 'CACHE_DB',
      value: 'PURGE',
    })
  }
}

export default new AuthUtil();
