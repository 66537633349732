import React, { ReactElement, useState } from 'react';
import { DNABox, DNADivider, DNAText, Hoverable, Iffy, luxColors, util } from '@alucio/lux-ui';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { MenuItem } from '@ui-kitten/components';
import { DocumentORM, EntityWithFiles } from 'src/types/types'
import {
  useCanPerformSearch,
  useDocumentSearch,
} from 'src/state/redux/selector/documentSearch/documentSearch';
import { useTenantCustomFields } from 'src/state/redux/selector/tenant'
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip'
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail';
import {
  DocumentStatus,
  CustomFieldDefinition,
  CustomFieldUsage,
  FileType,
} from '@alucio/aws-beacon-amplify/src/models';
import { isDocumentVersion } from '../DNA/AssociatedFiles/AssociatedFilesList';
import CustomFieldBadgeList from '../CustomFields/CustomFieldBadgeList';

const resultsWrapperStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
};

const styles = StyleSheet.create({
  boldedText: {
    color: luxColors.contentText.tertiary,
    fontSize: 12,
  },
  customFieldsText: {
    color: luxColors.subtitle.quinary,
    fontSize: 12,
    lineHeight: 20,
  },
  hoveredBackgroundColor: {
    backgroundColor: luxColors.hoveredBackground.primary,
  },
  noResults: {
    marginLeft: 4,
    marginBottom: 6,
    marginTop: 6,
  },
  recordRow: {
    flex: 1,
    flexDirection: 'row',
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
  },
  titleText: {
    color: luxColors.contentText.tertiary,
    fontSize: 14,
    lineHeight: 16,
  },
  viewAllWrapper: {
    borderTopColor: luxColors.disabled.quaternary,
    borderTopWidth: 1,
    justifyContent: 'center',
  },
});

interface Props<T> {
  searchText: string;
  parentDoc: T;
  onSelect: (parentDoc: T, linkedDoc: DocumentORM) => void
}

interface RecordRowProps {
  tenantCustomFields: CustomFieldDefinition[];
  documentORM: DocumentORM;
  isFirst: boolean;
  onSelectItem: (selectedDocument: DocumentORM) => void;
  tokenizedSearchText: string[];
}

const useResultsBox = <T extends EntityWithFiles>(props: Props<T>) => {
  const { searchText = '', parentDoc, onSelect } = props;
  const searchedText = searchText.toLowerCase().trim();
  const canPerformSearch = useCanPerformSearch();
  const tokenizedSearchText = getTokenizedText(searchText)
  const resultSearch: DocumentORM[] = useDocumentSearch(
    { text: searchedText, status: [DocumentStatus.PUBLISHED, DocumentStatus.NOT_PUBLISHED], isPublisher: true },
  );
  const tenantCustomFields = useTenantCustomFields({
    defaultSearchFilter: true,
    usages: { internalUsages: [CustomFieldUsage.DOCUMENT] },
  });

  function onSelectItem(linkedDoc: DocumentORM): void {
    onSelect(parentDoc, linkedDoc)
  }

  const { associatedFiles } = parentDoc.relations
  const filteredResultSearch = resultSearch
    .filter(result => {
      // Avoid recursively attached file
      const doesNotMatchTargetDoc = isDocumentVersion(parentDoc)
        ? result.model.id !== parentDoc?.relations.documentORM?.model.id
        : true

      // Avoid already linked file
      const doesNotMatchExistingLink = !associatedFiles.find(file => file.model.attachmentId === result.model.id)

      return (doesNotMatchTargetDoc && doesNotMatchExistingLink)
    })

  const displayEmptyMessage = canPerformSearch(searchedText) && !filteredResultSearch.length;

  return {
    onSelectItem,
    displayEmptyMessage,
    filteredResultSearch,
    tokenizedSearchText,
    tenantCustomFields,
  }
}

const AssociatedFileResultBox = <T extends EntityWithFiles>(props: Props<T>) => {
  const {
    onSelectItem,
    displayEmptyMessage,
    filteredResultSearch,
    tokenizedSearchText,
    tenantCustomFields,
  } = useResultsBox(props)

  return (
    <React.Fragment>
      <Iffy is={!displayEmptyMessage}>
        <MenuItem
          style={resultsWrapperStyle}
          title={() =>
            (<DNABox appearance="col" fill>
              {filteredResultSearch.map((documentORM, idx) =>
                (<RecordRow
                  isFirst={!idx}
                  key={documentORM.model.id}
                  tokenizedSearchText={tokenizedSearchText}
                  documentORM={documentORM}
                  tenantCustomFields={tenantCustomFields}
                  onSelectItem={onSelectItem}
                />))
            }
            </DNABox>)
        }
        />
      </Iffy>
      <Iffy is={displayEmptyMessage}>
        <MenuItem
          disabled
          title={ () =>
            (<DNAText b2 style={styles.noResults} numberOfLines={1}>
              No items match your search. Please try a different search.
            </DNAText>)
          }
        />
      </Iffy>
    </React.Fragment>
  );
}

function RecordRow(props: RecordRowProps): ReactElement {
  const {
    tenantCustomFields,
    documentORM,
    isFirst,
    tokenizedSearchText,
    onSelectItem,
  } = props;
  const [isHovered, setIsHovered] = useState<boolean>(false);
  // Since this control is only used within Publisher we show latest version
  const { latestUsableDocumentVersionORM } = documentORM.relations.version
  const integrationSource = documentORM.meta.integration.source
    ? `, \u2022 ${documentORM.meta.integration.source}`
    : ''
  const tenantValues = tenantCustomFields.reduce((acc, customField) => {
    const value = documentORM.meta.customValues?.configsMap?.[customField.id]?.displayValues
    if (value && value.length > 0) {
      acc += acc ? `, ${value.join(', ')}` : ` ⋅ ${value.join(', ')}`;
    }
    return acc;
  }, '');

  function toggleHover(): void {
    setIsHovered(!isHovered);
  }

  function onSelect(): void {
    onSelectItem(documentORM);
  }

  const documentType = documentORM.model.type === FileType.MP4 ? 'VIDEO' : documentORM.model.type

  return (
    <Hoverable onHoverIn={toggleHover} onHoverOut={toggleHover}>
      <>
        <Iffy is={!isFirst}>
          <DNADivider />
        </Iffy>
        <TouchableOpacity onPress={onSelect} style={[styles.recordRow, isHovered && styles.hoveredBackgroundColor]}>
          <DNABox fill alignY="center">
            <DNABox style={{ maxHeight: 40, marginRight: 16 }}>
              <DNADocumentThumbnail
                width={72}
                height={40}
                documentVersionORM={latestUsableDocumentVersionORM}
              />
            </DNABox>
            <DNABox appearance="col">
              <DNAText testID="associated-files-results-text" status="basic" numberOfLines={5} style={styles.titleText}>
                {getTextComponents(tokenizedSearchText, latestUsableDocumentVersionORM.model.title || '', true)}
              </DNAText>
              <DNAText style={styles.customFieldsText} numberOfLines={5}>
                {
                  getTextComponents(
                    tokenizedSearchText,
                    `${documentType} ${tenantValues} ${integrationSource}`,
                  )
                }
              </DNAText>
              <DNABox spacing="sm" alignY="center">
                <CustomFieldBadgeList documentVersionORM={latestUsableDocumentVersionORM} />
                <DNABox
                  style={util.mergeStyles(
                    undefined,
                    [
                      { borderWidth: 2, borderColor: luxColors.warning.quaternary },
                      (
                        documentORM.meta.hasUnpublishedVersion &&
                        documentORM.model.status === DocumentStatus.PUBLISHED
                      ),
                    ],
                  )}
                >
                  <DNADocumentChip
                    item={documentORM}
                    variant="status"
                  />
                </DNABox>
              </DNABox>
            </DNABox>
          </DNABox>
        </TouchableOpacity>
      </>
    </Hoverable>
  );
}

function getTextComponents(
  tokenizedSearchText: string[],
  textFieldValue: string,
  isTitle = false,
): (string | ReactElement)[] {
  const formattedText: (string | ReactElement)[] = [];
  const tokenizedField = getTokenizedText(textFieldValue);
  const boldTextStyle = { bold: true, style: isTitle ? styles.titleText : styles.boldedText };

  // ITERATE OVER THE TOKENS TO FIND IF THERE'S A MATCH
  tokenizedField.forEach((token) => {
    // CHECK IF ONE OF THE SEARCH ITEMS MATCHES ANY TOKEN OF THE FIELD
    const matchedToken = tokenizedSearchText.find((searchToken) => token.toLowerCase().includes(searchToken));

    // IF THERE'S A MATCH, BOLD IT. OTHERWISE, RETURN IT AS IT IS.
    if (matchedToken) {
      const index = token.toLowerCase().indexOf(matchedToken);

      if (index > 0) {
        formattedText.push(token.substring(0, index));
        formattedText.push(
          React.createElement(DNAText, boldTextStyle, [token.substring(index, index + matchedToken.length)]),
        )
        formattedText.push(token.substring(index + matchedToken.length, token.length));
      } else {
        formattedText.push(React.createElement(DNAText, boldTextStyle, [token.substring(0, matchedToken.length)]));
        formattedText.push(token.substring(matchedToken.length, token.length));
      }
    } else {
      formattedText.push(token);
    }
  });

  return formattedText;
}

// RETURNS THE TEXT FIELD AS AN ARRAY OF STRING SEPARATED BY SPACES AND PUNCTUATION MARKS
function getTokenizedText(text: string): string[] {
  const tokens: string[] = [];
  let lastBreakPoint = 0;

  for (let i = 0; i < text.length; i++) {
    if (text[i].match(/[^\w\s]|_/g) || text[i] === ' ') {
      if (lastBreakPoint !== i) {
        tokens.push(text.substring(lastBreakPoint, i));
      }
      tokens.push(text.substring(i, i + 1));
      lastBreakPoint = i + 1;
    } else if (i === text.length - 1) {
      tokens.push(text.substring(lastBreakPoint, text.length));
    }
  }

  return tokens;
}

export default AssociatedFileResultBox
