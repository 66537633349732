import React, { createContext, PropsWithChildren, useContext, useState } from 'react'
import { Pressable, TouchableOpacity } from 'react-native'
import { OverflowMenu, MenuItem } from '@ui-kitten/components'
import { DNABox, DNAText, DNAIcon, DNADivider, DNAChip, DNAButton, DNASlider, Iffy } from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import ProfilePicture from 'src/components/DNA/Header/ProfilePicture'

import useScreenNav from 'src/components/DNA/hooks/useScreenNav'
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage'
import { useAppSettings } from 'src/state/context/AppSettings'
import { useCurrentUserORM } from 'src/state/redux/selector/user'

import { UserRole } from '@alucio/aws-beacon-amplify/src/models'
import { ROUTES } from 'src/router/routeDef'
import ProfileScreen from 'src/screens/Profile/Profile'
import { useSearchTextContextProvider } from 'src/components/Header/SearchInput/SearchInput'
import useLogOut, { SOURCE } from 'src/components/Authenticator/LogOut'
import { SignOutStateProvider } from 'src/components/Authenticator/LogOut.proxy'

const USER_ROLES = {
  [UserRole.ALUCIO_ADMIN]: 'Alucio Admin',
  [UserRole.TENANT_ADMIN]: 'Admin',
  [UserRole.TENANT_PUBLISHER]: 'Publisher',
  [UserRole.TENANT_VIEWER]: 'Viewer',
};

const ROLE_SWITCH = {
  [ROUTES.PUBLISHER_DASHBOARD.UID]: {
    role: USER_ROLES[UserRole.TENANT_VIEWER],
    screen: ROUTES.HOME,
  },
  [ROUTES.EMAIL_TEMPLATES.UID]: {
    role: USER_ROLES[UserRole.TENANT_VIEWER],
    screen: ROUTES.HOME,
  },
  [ROUTES.SEARCH_RESULTS_PUBLISHER.UID]: {
    role: USER_ROLES[UserRole.TENANT_VIEWER],
    screen: ROUTES.HOME,
  },
  [ROUTES.PUBLISHER_FOLDERS.UID]: {
    role: USER_ROLES[UserRole.TENANT_VIEWER],
    screen: ROUTES.HOME,
  },
  [ROUTES.PUBLISHER_SHARED_FOLDERS.UID]: {
    role: USER_ROLES[UserRole.TENANT_VIEWER],
    screen: ROUTES.HOME,
  },
  [ROUTES.PUBLISHER_ARCHIVED_FOLDERS.UID]: {
    role: USER_ROLES[UserRole.TENANT_VIEWER],
    screen: ROUTES.HOME,
  },
  [ROUTES.PUBLISHER_FOLDERS_UPDATES.UID]: {
    role: USER_ROLES[UserRole.TENANT_VIEWER],
    screen: ROUTES.HOME,
  },
  [ROUTES.REPORTS.UID]: {
    role: USER_ROLES[UserRole.TENANT_VIEWER],
    screen: ROUTES.HOME,
  },
  [ROUTES.ADMIN_SETTINGS.UID]: {
    role: USER_ROLES[UserRole.TENANT_VIEWER],
    screen: ROUTES.HOME,
  },
  default: {
    role: USER_ROLES[UserRole.TENANT_PUBLISHER],
    screen: ROUTES.PUBLISHER_DASHBOARD,
  },
}

const analyticsEvents: { [K: string]: [string, Object] } = {
  roleSwitchToMSL: [
    'USER_CHANGE_TO_MSL',
    { action: 'CHANGE_TO_MSL', category: 'USER' },
  ],
  roleSwitchToPublisher: [
    'USER_CHANGE_TO_PUBLISHER',
    { action: 'CHANGE_TO_PUBLISHER', category: 'USER' },
  ],
  logout: [
    'LOGIN_LOGOUT',
    { action: 'LOGOUT', category: 'LOGIN' },
  ],
}

type ProfileMenuContextType = {
  appSettings?: ReturnType<typeof useAppSettings>,
  menu?: {
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
  },
  user?: {
    user: ReturnType<typeof useCurrentUserORM>,
    fullName: string,
    labelValues: string[],
    roleSwitch: typeof ROLE_SWITCH['default'],
  },
  nav?: {
    currentPage: ReturnType<typeof useCurrentPage>,
    go: () => void,
    goToProfilePassword: () => void,
    goToLogout: () => void,
  },
  enableDeviceSwitch?: boolean,
  profileSlider?: {
    profileTabletVisible: boolean,
    setProfileTabletVisible: React.Dispatch<React.SetStateAction<boolean>>,
  }
}

// Being lazy by not filling out default values and making the types possibly undefined instead
const ProfileMenuContext = createContext<ProfileMenuContextType>({})
const ProfileMenuProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  const [visible, setVisible] = useState<boolean>(false)
  const [profileTabletVisible, setProfileTabletVisible] = useState<boolean>(false)
  const currentUserORM = useCurrentUserORM();
  const { go, goTo } = useScreenNav()
  const { signOut }  = useLogOut()
  const currentPage = useCurrentPage({ exact: false })
  const appSettings = useAppSettings()

  const fullName = currentUserORM?.model?.givenName + ' ' + currentUserORM?.model?.familyName

  /** Default labels from account */
  const filters = currentUserORM?.meta.customFilterValues.defaultFilters ?? []
  const filterDisplayValues = Object.values(filters).map(filters => filters.displayValues).flat()

  const labelValues = Array
    .from(new Set(filterDisplayValues))
    .sort()

  const dismiss = (fn: Function) => (...args) => {
    setVisible(false)
    fn(...args)
  }

  const value = {
    appSettings,
    menu: {
      visible,
      setVisible,
    },
    user: {
      user: currentUserORM,
      fullName,
      labelValues,
      roleSwitch: ROLE_SWITCH[currentPage?.UID ?? ''] ?? ROLE_SWITCH.default,
    },
    nav: {
      currentPage,
      go: dismiss(go),
      goToProfilePassword: dismiss(goTo.PROFILE_PASSWORD),
      goToLogout: dismiss(() => {
        signOut(SOURCE.USER)
        goTo.HOME(undefined, ...analyticsEvents.logout)
      }),
    },
    enableDeviceSwitch: appSettings.isDeviceSwitchEnabled,
    profileSlider: {
      profileTabletVisible,
      setProfileTabletVisible,
    },
  }

  return (
    <ProfileMenuContext.Provider value={value}>
      {children}
    </ProfileMenuContext.Provider>
  )
}

export function useProfileMenu() {
  const context = useContext(ProfileMenuContext)
  if (!context) {
    throw new Error('useProfileMenu must be used within the ProfileMenuContextProvider')
  }
  return context;
}

// Menu's Avatar component
const ProfilePictureMenu: React.FC<PropsWithChildren> = (props) => {
  const common = useProfileMenu()

  return (
    <OverflowMenu
      visible={common?.menu?.visible}
      onBackdropPress={() => common?.menu?.setVisible(false)}
      anchor={() => (
        <TouchableOpacity
          testID="profile-menu"
          activeOpacity={1}
          onPress={() => common?.menu?.setVisible(p => !p)}
        >
          <ProfilePicture />
        </TouchableOpacity>
      )}
    >
      <MenuItem
        appearance="grouped"
        title={() => props.children as React.ReactElement}
      />
    </OverflowMenu>
  )
}

const ProfileMenuDesktop: React.FC = () => {
  const common = useProfileMenu()
  const { isOnline } = useAppSettings()
  const { setCurrentSearchString } = useSearchTextContextProvider();

  const roleSwitchHandler = () => {
    // @ts-ignore
    common.nav.go(common.user.roleSwitch.screen)
    setCurrentSearchString('');
  }

  if (!common?.user?.user) {
    return (
      <DNAText>
        User profile could not be loaded. Please contact an admin
      </DNAText>
    )
  }

  return (
    <ProfilePictureMenu>
      {/* MAIN INFO */}
      <DNABox fill appearance="col">
        <DNABox
          appearance="col"
          style={{
            width: 340,
            paddingVertical: 24,
            paddingHorizontal: 36,
            backgroundColor: 'white',
          }}
          alignX="center"
          spacing="md"
          fill
        >
          <DNAPopover fill delayPressIn={10} interactive={true} >
            <DNAPopover.Anchor>
              <ProfilePicture size="medium" />
            </DNAPopover.Anchor>
            <DNAPopover.Content >
              <DNABox >
                <DNABox
                  appearance="col"
                  alignX="center"
                  fill
                  spacing="sm"
                  style={{
                  // @ts-ignore
                    cursor:'text',
                  }}
                >
                  <DNAText
                    selectable
                    status="basic"
                  > User ID: {common.user.user.model.id}</DNAText>
                  <Iffy is={common.user.user.model.tenantId}>
                    <DNAText
                      selectable
                      status="basic"
                    >Tenant ID: {common.user.user.model.tenantId}</DNAText>
                  </Iffy>
                </DNABox>
              </DNABox>
            </DNAPopover.Content>
          </DNAPopover>
          <DNABox appearance="col" alignX="center" spacing="sm">
            <DNAText h5 style={{ textAlign: 'center' }}>{common.user.fullName}</DNAText>
            <DNAText b1 style={{ textAlign: 'center' }}>{common.user.user.model.email}</DNAText>
            <DNAText status="subtle">{USER_ROLES[common.user.user.model.role]}</DNAText>
          </DNABox>
          <DNAButton
            testID="account-profile-button"
            appearance="ghostLink"
            onPress={() => common?.nav?.goToProfilePassword()}
            disabled={!isOnline}
          >
            Account Profile
          </DNAButton>
          <DNADivider height={1} width={80} />
          <DNABox appearance="col" alignX="center" fill spacing="sm">
            <DNAText status="subtle">My profile</DNAText>
            <DNABox
              fill
              alignX="center"
              wrap="start"
              spacing="sm"
              childStyle={{ paddingBottom: 4 }}
            >
              {
                common.user.labelValues.map((label, idx) => (
                  <DNAChip key={`${label}-${idx}`} status="primary">
                    {label}
                  </DNAChip>
                ))
              }
            </DNABox>
          </DNABox>
        </DNABox>

        <DNABox appearance="col">
          {/* ROLE SWITCH */}
          <Iffy is={common?.user.user.model.role === UserRole.TENANT_PUBLISHER}>
            <DNADivider />
            <DNABox appearance="col" style={{ padding: 12 }}>
              <DNABox style={{ paddingBottom: 8 }} fill appearance="col">
                <DNAText c2 status="secondary">USE BEACON AS ...</DNAText>
              </DNABox>
              <DNABox
                testID={`${common.user.roleSwitch.role.toLocaleLowerCase()}-menu-option`}
                alignY="center"
                spacing="sm"
                as={TouchableOpacity}
                // @ts-ignore
                onPress={roleSwitchHandler}
                activeOpacity={1}
              >
                <DNAIcon.Styled
                  appearance="ghost"
                  status="tertiary"
                  name="account-edit"
                  size="md"
                />
                <DNAText>{common.user.roleSwitch.role}</DNAText>
              </DNABox>
            </DNABox>
          </Iffy>

          {/* LOGOUT */}
          <DNADivider />
          <DNABox
            style={{ padding: 12 }}
            alignY="center"
            spacing="sm"
            as={TouchableOpacity}
            // @ts-ignore
            onPress={() => common.nav.goToLogout()}
            activeOpacity={1}
            testID="account-logout-button"
          >
            <DNAIcon.Styled
              appearance="ghost"
              status="tertiary"
              name="logout"
              size="md"
            />
            <DNAText>Log out</DNAText>
          </DNABox>
          {/* DEVICE SWITCH */}
          <Iffy is={common.enableDeviceSwitch}>
            <DNADivider />
            <DNABox
              as={TouchableOpacity}
              style={{ padding: 12 }}
              alignY="center"
              spacing="sm"
              // @ts-ignore
              onPress={() => common.appSettings.dispatch({ type: 'toggleDeviceMode' })}
              activeOpacity={1}
            >
              <DNAIcon.Styled
                appearance="ghost"
                status="tertiary"
                name="monitor-cellphone"
                size="md"
              />
              <DNAText>Switch device modes</DNAText>
            </DNABox>
          </Iffy>
        </DNABox>
      </DNABox>
    </ProfilePictureMenu>
  )
}

const ProfileMenuTablet: React.FC = () => {
  const common = useProfileMenu()
  const uselogOutState = useLogOut()

  // Check if user is redirected from account profile route, if so open the account profile slider,
  // and remove the local storage
  const isFromTabletProfile = localStorage.getItem( 'isFromTabletProfile' )
  if (isFromTabletProfile) {
    common?.profileSlider?.setProfileTabletVisible(true)
    localStorage.removeItem( 'isFromTabletProfile')
  }

  if (!common?.profileSlider?.setProfileTabletVisible) return null

  return (
    <>
      <Pressable
        testID="profile-menu"
        onPress={() => common?.profileSlider?.setProfileTabletVisible(true)}
      >
        <ProfilePicture />
      </Pressable>

      <DNASlider
        visible={common?.profileSlider?.profileTabletVisible}
        setVisible={common?.profileSlider?.setProfileTabletVisible}
      >
        <SignOutStateProvider value={uselogOutState}>
          <ProfileScreen />
        </SignOutStateProvider>
      </DNASlider>
    </>
  )
}

const ProfileMenu: React.FC = (props) => {
  const { deviceMode } = useAppSettings()

  return (
    <ProfileMenuProvider>
      {
        deviceMode === 'desktop'
          ? <ProfileMenuDesktop {...props} />
          : <ProfileMenuTablet {...props} />
      }
    </ProfileMenuProvider>
  )
}

export default ProfileMenu
