import React, { PropsWithChildren, ReactElement, useState } from 'react';
import { Image, ImageBackground, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import {
  Button,
  Disclaimer,
  DISCLAIMER_MODE,
  Icon,
  Iffy,
  luxColors,
  Modal,
  responsive,
  util,
  InformationMessage,
} from '@alucio/lux-ui';
import { IAuthPieceProps } from 'aws-amplify-react/lib-esm/Auth/AuthPiece';
import { isFirefox, isSafari, isMobile } from 'react-device-detect';
import { DisclaimerType } from '../Profile/Profile';
const BackgroundImage = require('../../../assets/images/LoginBackground.jpg');
const Logo = require('../../../assets/images/LoginLogo.png');

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: luxColors.modalBackdrop.primary,
  },
  background: {
    flex: 1,
    alignItems: 'flex-end',
  },
  beaconText: {
    color: luxColors.alucioPurple.primary,
    fontSize: 24,
    fontWeight: 'bold',
    paddingBottom: 20,
    paddingTop: 20,
  },
  boldText: {
    fontWeight: 'bold',
  },
  bottomOption: {
    color: luxColors.headerTextColor.primary,
    fontSize: 13,
  },
  closeButton: {
    backgroundColor: luxColors.thumbnailBorder.primary,
    borderColor: luxColors.thumbnailBorder.primary,
    borderRadius: 50,
    marginBottom: 24,
    marginTop: 24,
  },
  footer: {
    alignSelf: 'center',
    flexDirection: 'row',
    paddingBottom: 16,
  },
  formContent: {
    alignItems: 'center',
    width: '100%',
  },
  formWrapper: {
    backgroundColor: luxColors.info.primary,
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    overflowY: 'scroll',
    paddingLeft: 64,
    paddingRight: 64,
    paddingTop: 56,
    width: 504,
  },
  horizontalOffset: {
    marginLeft: -50,
    marginRight: -50,
  },
  icon: {
    color: luxColors.closeIcon.primary,
    height: 24,
    width: 24,
  },
  logoImage: {
    height: 115,
    width: 120,
  },
  needHelp: {
    color: luxColors.thumbnailBorder.primary,
    fontSize: 13,
  },
  mainWrapper: {
    flex: 1,
    flexDirection: 'row',
    height: responsive.dimensions.trueHundredHeight,
  },
  modalContentWrapper: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingBottom: 65,
    paddingTop: 16,
  },
  modalContentText: {
    color: luxColors.headerTextColor.primary,
    fontSize: 14,
    lineHeight: 20,
  },
  modalFooter: {
    borderBottomWidth: 0,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 16,
  },
  modalHeaderText: {
    color: luxColors.blueText.tertiary,
    fontSize: 18,
    fontWeight: 'bold',
  },
  modalSection: {
    borderBottomColor: luxColors.divider.tertiary,
    borderBottomWidth: 1,
    paddingLeft: 16,
    paddingRight: 16,
  },
  modalWrapper: {
    backgroundColor: luxColors.info.primary,
    borderRadius: 4,
    flexDirection: 'column',
    paddingTop: 16,
    width: 500,
  },
  row: {
    flexDirection: 'row',
  },
  verticalDivider: {
    borderRightColor: luxColors.headerTextColor.primary,
    borderRightWidth: 1,
    height: 16,
    marginLeft: 16,
    marginRight: 16,
  },
});

interface HelpModalProps {
  toggleHelpModal: () => void,
}

interface Props extends IAuthPieceProps {
  title?: string;
  isLogin?: boolean;
  showReturnToLogin?: boolean;
  changePasswordEnabled?: boolean;
}

const FormWrapper: React.FC<PropsWithChildren<Props>> = (props) => {
  const [isHelpModalShown, setIsHelpModalShown] = useState<boolean>(false);
  const [disclaimer, setDisclaimer] =
    useState<DisclaimerType>({ isVisble: false, mode: DISCLAIMER_MODE.Privacy });

  function toggleDisplayModal(): void {
    setIsHelpModalShown(!isHelpModalShown);
  }

  function forgotPassword(): void {
    props.onStateChange?.('forgotPassword');
  }

  function returnToLoginState(): void {
    props.onStateChange?.('signIn');
  }

  function onCloseDisclaimer() {
    setDisclaimer({ ...disclaimer, isVisble: false });
  }

  function showPrivacy() {
    setDisclaimer({
      isVisble: true,
      mode: DISCLAIMER_MODE.Privacy,
    })
  }

  function showToU() {
    setDisclaimer({
      isVisble: true,
      mode: DISCLAIMER_MODE.ToU,
    })
  }

  return (
    <>
      {disclaimer.isVisble && <Disclaimer mode={disclaimer.mode} onClose={onCloseDisclaimer} />}
      <View style={styles.mainWrapper}>
        <Iffy is={isHelpModalShown}>
          <HelpModal toggleHelpModal={toggleDisplayModal} />
        </Iffy>
        <ImageBackground
          source={BackgroundImage}
          style={styles.background}
          resizeMode="cover"
        >
          <View
            style={util.mergeStyles(
              undefined,
              styles.formWrapper,
              [{ paddingTop: 20 }, (isFirefox)],
            )}
          >
            <View style={styles.formContent}>
              <Iffy is={isFirefox || (!isMobile && isSafari)}>
                <InformationMessage
                  variance="warning"
                  style={styles.horizontalOffset}
                  text="For the best user experience please use the latest version of the Chrome web browser."
                />
              </Iffy>
              <Image source={Logo} style={styles.logoImage} />
              <Iffy is={props.title}>
                <Text style={styles.beaconText}>{props.title}</Text>
              </Iffy>
              {props.children}
              <View style={styles.row}>
                <Iffy is={props.isLogin && props.changePasswordEnabled}>
                  <TouchableOpacity testID="forgot-password" onPress={forgotPassword}>
                    <Text style={styles.needHelp}>Forgot password?</Text>
                  </TouchableOpacity>
                </Iffy>
                <Iffy is={props.showReturnToLogin}>
                  <TouchableOpacity onPress={returnToLoginState}>
                    <Text style={styles.needHelp}>Log in</Text>
                  </TouchableOpacity>
                </Iffy>
                <TouchableOpacity onPress={toggleDisplayModal}>
                  <Text style={[styles.needHelp, { marginLeft: 20 }]}>Need help?</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.footer}>
              <TouchableOpacity onPress={showToU}>
                <Text style={styles.bottomOption}>Terms of Use</Text>
              </TouchableOpacity>
              <View style={styles.verticalDivider} />
              <TouchableOpacity onPress={showPrivacy}>
                <Text style={styles.bottomOption}>Privacy Policy</Text>
              </TouchableOpacity>
            </View>
          </View>
        </ImageBackground>
      </View>
    </>
  );
};

const HelpModal = (props: HelpModalProps): ReactElement => {
  const { toggleHelpModal } = props;

  return (
    <Modal visible={true} onBackdropPress={toggleHelpModal} backdropStyle={styles.backdrop}>
      <View style={styles.modalWrapper}>
        <View style={[styles.modalSection, styles.modalHeader]}>
          <Text style={styles.modalHeaderText}>Need help?</Text>
          <TouchableOpacity onPress={toggleHelpModal}>
            <Icon style={styles.icon} name="close" />
          </TouchableOpacity>
        </View>
        <View style={[styles.modalSection, styles.modalContentWrapper]}>
          <Text style={styles.modalContentText}>
            <Text>Please contact Alucio Support</Text>
            <Text style={styles.boldText}> (support@alucio.io)</Text>
            <Text> for assistance with your account.</Text>
          </Text>
        </View>
        <View style={[styles.modalSection, styles.modalFooter]}>
          <Button.Kitten style={styles.closeButton} onPress={toggleHelpModal}>
            Close
          </Button.Kitten>
        </View>
      </View>
    </Modal>
  );
};

export default FormWrapper;
