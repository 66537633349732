import { LDFlagValue } from 'launchdarkly-js-sdk-common'

/** NOTE: Naming convention using snake_case allows for cleaner
 * implementation here vs string enums using kebab-case */
enum FeatureFlagKeys {
  BEAC_2516_Enable_Document_Sharing_Templates,
  // [TODO:] 2718 remove this when we have a new feature flag for collaboration
  BEAC_2712_custom_decks_shared_folders,
  BEAC_2818_custom_deck_collaboration,
  BEAC_1538_Content_Preview_Modal_V2,
  BEAC_2519_zendesk_knowledgebase,
  BEAC_3240_zendesk_chat,
  BEAC_2388_msl_reports,
  BEAC_2829_enable_add_meeting,
  BEAC_3336_web_video_doc_types,
  BEAC_2820_highlighter,
  BEAC_3865_publisher_share_sent_download_report,
  BEAC_1954_allow_add_file_to_folder,
  BEAC_3900_analytics_demo_mode,
  BEAC_2777_immediate_publish_emails,
  BEAC_3849_html_document_type,
  BEAC_1832_bulk_add_to_folder,
  BEAC_2131_bulk_share_email,
  BEAC_4227_meeting_slide_follow_up,
  BEAC_4227_meeting_slide_notes,
  BEAC_4227_meeting_slide_reactions,
  BEAC_3448_full_text_search,
  BEAC_4227_meeting_slide_eyeball,
  BEAC_3569_presentation_vanity_url,
  BEAC_3259_beacon_hubs,

  /** TODO: the items were implemented prior to the typing and are still using kebab-case on launch darkly.
   * These items would need to be renamed/rebuilt on that service to take advantage of this typing implementation.
   * Adding them here proactively for when the old kebab-case references in the code have been refactored
   * https://alucioinc.atlassian.net/browse/BEAC-4479 */
  // beac_1849_enable_publisher_reports,
  // beac_2327_disable_publisher_folder,
  // enable_shared_folders,
  // enable_viewer_file_upload
  // beac_1535_msl_email_alerts,
  // allow_ms_edge,
}
type FeatureFlagKey = keyof typeof FeatureFlagKeys

export interface FeatureFlag {
  key: FeatureFlagKey,
  defaultValue?: LDFlagValue
}

export const FeatureFlags: Record<FeatureFlagKey, FeatureFlag> = {
  BEAC_2516_Enable_Document_Sharing_Templates: {
    key: 'BEAC_2516_Enable_Document_Sharing_Templates',
    defaultValue: true,
  },
  // [TODO:] 2718 remove this when we have a new feature flag for collaboration
  BEAC_2712_custom_decks_shared_folders: {
    key: 'BEAC_2712_custom_decks_shared_folders',
    defaultValue: true,
  },
  BEAC_2829_enable_add_meeting: {
    key: 'BEAC_2829_enable_add_meeting',
    defaultValue: false,
  },
  BEAC_3865_publisher_share_sent_download_report: {
    key: 'BEAC_3865_publisher_share_sent_download_report',
    defaultValue: true,
  },
  BEAC_2818_custom_deck_collaboration: {
    key: 'BEAC_2818_custom_deck_collaboration',
    defaultValue: false,
  },
  BEAC_1538_Content_Preview_Modal_V2: {
    key: 'BEAC_1538_Content_Preview_Modal_V2',
    defaultValue: true,
  },
  BEAC_2388_msl_reports: {
    key: 'BEAC_2388_msl_reports',
    defaultValue: false,
  },
  BEAC_2519_zendesk_knowledgebase: {
    key: 'BEAC_2519_zendesk_knowledgebase',
    defaultValue: false,
  },
  BEAC_3240_zendesk_chat: {
    key: 'BEAC_3240_zendesk_chat',
    defaultValue: false,
  },
  BEAC_3336_web_video_doc_types: {
    key: 'BEAC_3336_web_video_doc_types',
    defaultValue: true,
  },
  BEAC_2820_highlighter: {
    key: 'BEAC_2820_highlighter',
    defaultValue: true,
  },
  BEAC_1954_allow_add_file_to_folder: {
    key: 'BEAC_1954_allow_add_file_to_folder',
    defaultValue: true,
  },
  BEAC_3900_analytics_demo_mode: {
    key: 'BEAC_3900_analytics_demo_mode',
    defaultValue: false,
  },
  BEAC_2777_immediate_publish_emails: {
    key: 'BEAC_2777_immediate_publish_emails',
    defaultValue: false,
  },
  BEAC_3849_html_document_type: {
    key: 'BEAC_3849_html_document_type',
    defaultValue: true,
  },
  BEAC_1832_bulk_add_to_folder: {
    key: 'BEAC_1832_bulk_add_to_folder',
    defaultValue: true,
  },
  BEAC_2131_bulk_share_email: {
    key: 'BEAC_2131_bulk_share_email',
    defaultValue: true,
  },
  BEAC_4227_meeting_slide_follow_up: {
    key: 'BEAC_4227_meeting_slide_follow_up',
    defaultValue: true,
  },
  BEAC_4227_meeting_slide_notes: {
    key: 'BEAC_4227_meeting_slide_notes',
    defaultValue: false,
  },
  BEAC_4227_meeting_slide_reactions: {
    key: 'BEAC_4227_meeting_slide_reactions',
    defaultValue: false,
  },
  BEAC_3448_full_text_search: {
    key: 'BEAC_3448_full_text_search',
    defaultValue: false,
  },
  BEAC_4227_meeting_slide_eyeball: {
    key: 'BEAC_4227_meeting_slide_eyeball',
    defaultValue: true,
  },
  BEAC_3569_presentation_vanity_url: {
    key: 'BEAC_3569_presentation_vanity_url',
    defaultValue: true,
  },
  BEAC_3259_beacon_hubs: {
    key: 'BEAC_3259_beacon_hubs',
    defaultValue: false,
  },

  /** TODO: the items were implemented prior to the typing and are still using kebab-case on launch darkly.
    * These items would need to be renamed/rebuilt on that service to take advantage of this typing implementation.
    * Adding them here proactively for when the old kebab-case references in the code have been refactored.
    * https://alucioinc.atlassian.net/browse/BEAC-4479 */
  // beac_1849_enable_publisher_reports: {
  //   key: 'beac_1849_enable_publisher_reports',
  //   defaultValue: false,
  // },
  // beac_2327_disable_publisher_folder: {
  //   key: 'beac_2327_disable_publisher_folder',
  //   defaultValue: true,
  // },
  // enable_shared_folders: {
  //   key: 'enable_shared_folders',
  //   defaultValue: false,
  // },
  // enable_viewer_file_upload: {
  //   key: 'enable_viewer_file_upload',
  //   defaultValue: false,
  // },
  // beac_1535_msl_email_alerts: {
  //   key: 'beac_1535_msl_email_alerts',
  //   defaultValue: false,
  // },
  // allow_ms_edge: {
  //   key: 'allow_ms_edge',
  //   defaultValue: true,
  // },
}
