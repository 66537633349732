import React from 'react';
import Modal from 'react-native-modal'
import { StyleSheet } from 'react-native'

import {
  DNABox,
  DNAButton,
  DNAText,
  luxColors,
  DNADivider,
} from '@alucio/lux-ui';
import { DNAModalProps } from 'src/state/redux/slice/DNAModal/DNAModal';

interface ConfirmationModalProps extends Partial<DNAModalProps> {
  onConfirmAction?: () => void,
}

const styles = StyleSheet.create({
  container: {
    width: 560,
    minHeight: 248,
    backgroundColor: luxColors.confirmationBackground.primary,
    alignSelf: 'center',
    borderRadius: 4,
  },
  header: {
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  body: {
    paddingBottom: 24,
    paddingTop: 24,
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  footer: {
    paddingVertical: 16,
    paddingHorizontal: 16,

  },
  continueBtn: {
    borderColor: luxColors.headerTopColor.primary, // TODO: Fix in BEAC-2833, the style was added to match the mocks, in 2833 fix this
  },
})
const ReopenPopoutModal = (props: ConfirmationModalProps) => {
  const { isVisible, closeModal, onConfirmAction } = props

  function onAction(): void {
    onConfirmAction?.()
    closeModal?.()
  }

  return (
    <Modal
      isVisible={isVisible}
      hasBackdrop={false}
    >
      <DNABox spacing="between" appearance="col" style={styles.container}>
        <DNABox fill appearance="row" spacing="between" style={styles.header} childFill={2}>
          <DNAText h5>Re-open content window</DNAText>
          <DNAButton
            appearance="ghost"
            status="tertiary"
            iconLeft="close"
            onPress={closeModal}
          />
        </DNABox>
        <DNADivider />
        <DNABox style={styles.body} appearance="col" spacing="md">
          <DNAText style={{ color: luxColors.flat.primary }}>
            We noticed you closed your content window.
          </DNAText>
          <DNAText style={{ color: luxColors.flat.primary }}>
            To continue sharing content, re-open your content window and share via your video conferencing tool.
          </DNAText>
        </DNABox>
        <DNADivider />
        <DNABox appearance="row" spacing="sm" alignX="end" style={styles.footer}>
          <DNAButton onPress={onAction} status="primary" size="sm">
            Re-open content window
          </DNAButton>
        </DNABox>
      </DNABox>
    </Modal>
  )
};

export default ReopenPopoutModal;
