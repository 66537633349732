import React, { useEffect, useRef } from 'react'
import {
  DNABox,
  DNAButton,
  DNAModal,
  DNAText,
  Iffy,
  useInputConfig,
} from '@alucio/lux-ui'
import { Dimensions } from 'react-native'
import {
  DocumentVersionORM,
  EmailTemplateORM,
  HubORM,
} from 'src/types/types'
import { CustomFieldUsage } from '@alucio/aws-beacon-amplify/src/models'
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal'
import { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast'
import { CurrentUser } from 'src/state/redux/selector/user'
import { SHARE_METHODS, STEP_STATE } from 'src/state/machines/sharing/shareTypes'
import { useTenantCustomFields } from 'src/state/redux/selector/tenant'
import { ComposableForm } from 'src/components/CustomFields/ComposableForm'
import DNAFileShareModalStateProvider, {
  SHARE_METHOD,
  useDNAFileShareModalState,
} from './state/context/DNAFileShareModalStateProvider'

import { SceneMap, TabView } from 'react-native-tab-view'
import { getCurrentState } from 'src/state/machines/sharing/shareUtils'
import getInputConfig from './DNAFileShareModal.inputConfig'
import { SelectAssociatedFilesBody } from './TabViewScenes/SelectAssociatedFiles'
import { SelectEmailTemplatesBody } from './TabViewScenes/SelectEmailTemplates'
import { SelectCustomFieldsBody } from './TabViewScenes/SelectCustomFields'
import { formToModel } from 'src/components/CustomFields/ComposableFormUtilities'
import { ExistingShareLink } from 'src/utils/shareLink/shareLink.web'

export enum ShareVariantOptions {
  DOC_VER_SHARE = 'DOC_VER_SHARE',
  HUB_SHARE = 'HUB_SHARE'
}

export type ShareVariantType = keyof typeof ShareVariantOptions

const stepHeaders: Record<STEP_STATE, { header: string, component: React.FC }> = {
  [STEP_STATE.CUSTOM_FIELDS]: {
    header: 'Enter details',
    component: SelectCustomFieldsBody,
  },
  [STEP_STATE.ASSOCIATED_FILES]: {
    header: 'Select files to share or copy links',
    component: SelectAssociatedFilesBody,
  },
  [STEP_STATE.EMAIL_TEMPLATES]: {
    header: 'Select email template',
    component: SelectEmailTemplatesBody,
  },
}

const FileShareFooter: React.FC = () => {
  const {
    shareMachineState,
    shareMachineSend,
    shareMethod,
    cond,
    rhForm,
    onNextStep,
    validateAndExecute,
    variant,
  } = useDNAFileShareModalState()

  const hasPrevStep = shareMachineState.can('PREV_STEP')
  const hasNextStep = shareMachineState.can('NEXT_STEP')
  const isFinalStep = !hasNextStep

  const currentState = getCurrentState(shareMachineState)
  const isCustomFields = currentState === STEP_STATE.CUSTOM_FIELDS
  const isAssociatedFiles = currentState === STEP_STATE.ASSOCIATED_FILES
  const isEmailTemplate = currentState === STEP_STATE.EMAIL_TEMPLATES

  const availableStates = shareMachineState.context.availableStates
  const hasExistingShareLink = shareMachineState.context.existingShareLinks

  const isGeneratingEmailTemplate = !!shareMachineState.context.generateLinks
  const canGenerateShare = shareMachineState.can({
    type: 'GENERATE_SHARE',
    payload: { type: SHARE_METHODS.GUARD_CHECK },
  })
  const isCustomFieldsValid = (isCustomFields && rhForm.formState.isValid && canGenerateShare)
  const isFinalActionDisabled = (
    shareMachineState.tags.has('DISABLE_SHARE') ||
    !canGenerateShare ||
    (isCustomFields ? !isCustomFieldsValid : false)
  )

  const isStepBeforeLast = currentState
    ? availableStates.indexOf(currentState) === (availableStates.length - 2)
    : false

  const advanceDisabled = (isCustomFields && !rhForm.formState.isValid) ||
  (
    isAssociatedFiles &&
    !shareMachineState.can({ type: 'GENERATE_SHARE', payload: { type: SHARE_METHODS.GUARD_CHECK } })
  ) ||
  shareMachineState.tags.has('DISABLE_SHARE')

  const onPrevStep = () => {
    shareMachineSend({ type: 'PREV_STEP' })
  }

  const handleCopy = () => {
    shareMachineSend({
      type: 'GENERATE_SHARE',
      payload: {
        type:SHARE_METHODS.COPY_LINK,
        customFieldsValues: isCustomFields
          ? formToModel(rhForm.getValues())
          : undefined,
      },
    })
  }

  const handleEmail = () => {
    shareMachineSend({
      type: 'GENERATE_SHARE',
      payload: {
        type:SHARE_METHODS.OPEN_EMAIL_CLIENT,
        customFieldsValues: isCustomFields
          ? formToModel(rhForm.getValues())
          : undefined,
      },
    })
  }

  useInputConfig(
    getInputConfig(
      validateAndExecute(() => {
        !advanceDisabled && onNextStep()
      }),
    ),
  )

  const {
    formState:{ errors },
  } = rhForm

  const handleFormError = () => {
    /** TODO: add form focus for invalid fields once
     * react-hook-form has been updated. See the following ticket:
     * https://alucioinc.atlassian.net/browse/BEAC-4011
     */
    // console.log('The errors', errors)
  }
  useEffect(handleFormError, [errors])

  const showCopyButton = (shareMethod === SHARE_METHOD.COPY && isFinalStep) ||
    (shareMethod === SHARE_METHOD.EMAIL && isAssociatedFiles)

  return (
    <DNABox fill spacing="between">
      {
        hasPrevStep
          ? <DNAButton
              testID="back-btn"
              status="tertiary"
              appearance="filled"
              iconLeft="chevron-left"
              onPress={onPrevStep}
              disabled={shareMachineState.tags.has('DISABLE_SHARE')}
          >
            Back
          </DNAButton>
          : <DNABox/>
      }
      {/*
        we want the copy hub link button to show if we are copying an existing link in the final step
        or the first step when generating the link first, this currently only applys to hub share
      */}
      <DNABox spacing="md">
        <Iffy is={(variant === ShareVariantOptions.HUB_SHARE && !isFinalStep) || hasExistingShareLink}>
          <DNAButton
            appearance="outline"
            onPress={handleCopy}
            status={cond.generatedLink ? 'success' : 'primary'}
            disabled={isFinalActionDisabled}
            testID="continue-btn"
          >
            {
              cond.isGeneratingLink
                ? 'Copying'
                : cond.generatedLink
                  ? 'Copied'
                  : 'Copy hub link'
            }
          </DNAButton>
        </Iffy>
        <Iffy is={showCopyButton}>
          <DNAButton
            appearance="outline"
            onPress={handleCopy}
            status={cond.generatedLink ? 'success' : 'primary'}
            disabled={isFinalActionDisabled}
            testID="continue-btn"
          >
            {
              cond.isGeneratingLink
                ? 'Copying'
                : cond.generatedLink
                  ? 'Copied'
                  : 'Copy file name(s) and links'
            }
          </DNAButton>
        </Iffy>

        {/* [TODO] - Should we allow them to use the default email template even if they have templates? */}
        <Iffy is={shareMethod === SHARE_METHOD.EMAIL && isFinalStep && !isEmailTemplate}>
          <DNAButton
            appearance="outline"
            onPress={handleEmail}
            status={(!isGeneratingEmailTemplate && cond.generatedLink) ? 'success' : 'primary'}
            disabled={isFinalActionDisabled}
            testID="continue-btn"
          >
            {
              !isGeneratingEmailTemplate && cond.isGeneratingLink
                ? 'Opening email client'
                : !isGeneratingEmailTemplate && cond.generatedLink
                  ? 'Opened email client'
                  : 'Email file name(s) and links'
            }
          </DNAButton>
        </Iffy>

        <Iffy is={hasNextStep}>
          <DNAButton
            onPress={onNextStep}
              // [TODO] - Probably a better way to express this through the machine
              //        - We should conditionally render based on no more steps to go to
              //          and we should disable on other guards (i.e. no file selected)
              //        - Maybe we can have an abstraction called like `isStepValid` that is generic enough any of the states
              // [KENNY] - Move into `cond`
            disabled={advanceDisabled}
            testID="continue-btn"
          >
            {
              isStepBeforeLast
                ? 'Continue to share'
                : 'Continue'
            }
          </DNAButton>
        </Iffy>
      </DNABox>
    </DNABox>
  )
}

const DNAFileShareModalContent: React.FC = () => {
  const {
    shareMachineState,
    closeModal,
  } = useDNAFileShareModalState()

  const routes = shareMachineState.context.availableStates.map((availableState) => {
    return {
      key: availableState,
      title: availableState,
    }
  })

  const currentScreenContent = getCurrentState(shareMachineState)

  const scenes = useRef(
    shareMachineState
      .context
      .availableStates
      .reduce(
        (sceneMap, availableState) => {
          return {
            ...sceneMap,
            [availableState]: stepHeaders[availableState].component,
          }
        },
        {},
      ),
  )

  const isHeadlessMode = shareMachineState.matches('form.headless')
  const isDisabled = shareMachineState.tags.has('DISABLE_SHARE')

  if (isHeadlessMode) {
    return null
  }

  // [MOVE TO COND]
  const indexOfCurrentStep = shareMachineState
    .context
    .availableStates
    .indexOf(getCurrentState(shareMachineState)!)

  return (
    <DNAModal>
      <DNAModal.Header
        disabled={isDisabled}
        onClose={closeModal}
      >
        <DNABox>
          <DNAText testID="file-share-modal-text-header" h4>
            { currentScreenContent && stepHeaders[currentScreenContent].header }
          </DNAText>
        </DNABox>
      </DNAModal.Header>

      {/* BODY */}
      <DNAModal.Body style={{ padding: 0, maxHeight: 480 }}>
        <DNABox
          fill
          appearance="col"
          style={{ width: 800, minHeight: 378, paddingVertical: 16 }}
          testID="share-modal-details"
        >
          <TabView
            navigationState={{ index: indexOfCurrentStep, routes }}
            renderScene={SceneMap(scenes.current)}
            initialLayout={{ width: Dimensions.get('window').width }}
            onIndexChange={() => null}
            style={{ flex: 1 }}
            renderTabBar={() => null}
            swipeEnabled={false}
          />
        </DNABox>
      </DNAModal.Body>

      {/* FOOTER */}
      <DNAModal.Footer>
        <FileShareFooter />
      </DNAModal.Footer>
    </DNAModal>
  )
}

const DNAFileShareModal: DNAModalVariant<{
  shareContent: DocumentVersionORM[] | HubORM
  currentUser: CurrentUser
  toast: ToastActions,
  emailTemplates: EmailTemplateORM[],
  shareMethod: SHARE_METHOD,
  variant: ShareVariantType,
  meetingId?: string,
  existingShareLinks?: ExistingShareLink,
}> = props => {
  const {
    closeModal,
    shareContent,
    toast,
    currentUser,
    emailTemplates,
    shareMethod,
    isVisible,
    setModal,
    toggleModal,
    pushModal,
    variant,
    meetingId,
    existingShareLinks,
  } = props

  const variantConfig = {
    [ShareVariantOptions.DOC_VER_SHARE]: {
      customFieldUsage: CustomFieldUsage.CONTENT_SHARE,
    },
    [ShareVariantOptions.HUB_SHARE]: {
      customFieldUsage: CustomFieldUsage.HUB,
    },
  }

  const customFields = useTenantCustomFields({ usages: { internalUsages: [variantConfig[variant].customFieldUsage] } })

  return (
    <ComposableForm customFields={customFields}>
      <DNAFileShareModalStateProvider
        shareContent={shareContent}
        currentUser={currentUser}
        toast={toast}
        emailTemplates={emailTemplates}
        shareMethod={shareMethod}
        closeModal={closeModal}
        isVisible = {isVisible}
        setModal={setModal}
        toggleModal={toggleModal}
        pushModal={pushModal}
        meetingId={meetingId}
        variant={variant}
        existingShareLinks={existingShareLinks}
      >
        <DNAFileShareModalContent/>
      </DNAFileShareModalStateProvider>
    </ComposableForm>
  )
}
export default DNAFileShareModal
