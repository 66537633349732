import React, { useEffect, useState } from 'react';
import { DNABox, DNAButton, DNAText, Iffy, util } from '@alucio/lux-ui';
import InputComponent, { TextAreaProps } from '../Publishers/InputComponent';
import { StyleProp, ViewStyle } from 'react-native';
import { LuxSizeEnum } from '@alucio/lux-ui/src/typings';

interface EditableTextProps extends TextAreaProps {
  bold?: boolean;
  toggleShowInputIcon?: string;
  triggerInputShown?: (isShown: boolean) => void;
  error?: string;
  wrapperStyle?: StyleProp<ViewStyle>,
  /** NOTE: This prop allows the component to trigger the input change detection
   * immediately after any changes to the input rather than on the blur event */
  immediateUpdate?: boolean,
}

// THIS IS TO HAVE THE COMPONENT WITH THE SAME HEIGHT
// UPON CHANGING FROM INPUT TO TEXT
const COMPONENT_HEIGHT = {
  sm: 36,
  md: 44,
  lg: 52,
};

const EditableText:React.FC<EditableTextProps> = ({
  value,
  size = LuxSizeEnum.lg,
  bold,
  onChangeText,
  toggleShowInputIcon = 'pencil',
  title,
  triggerInputShown,
  removeMarginPadding,
  immediateUpdate,
  wrapperStyle,
  disabled,
  placeholderTextColor,
  error,
  required,
  placeHolder,
  ...rest
}) => {
  const [showInput, setShowInput] = useState<boolean>(false);
  const [localText, setLocalText] = useState<string>(value || '');

  function toggleShowInput(): void {
    triggerInputShown?.(!showInput);

    // UPDATE THE PARENT WITH THE NEW TEXT WHEN BLUR TO AVOID UNNECESSARY RE RENDERS OF THE PARENT
    if (showInput && !immediateUpdate) {
      onChangeText?.(localText);
    }

    setShowInput((isShown) => !isShown);
  }
  const textStyles = util.mergeStyles(
    undefined,
    { marginLeft: 8, flex: 1 },
    [{ color: placeholderTextColor }, placeholderTextColor && !value],
  )

  useEffect(() => {
    if (error && !showInput) {
      toggleShowInput();
    }
  }, [error])

  return (
    <DNABox testID="file-name-input" appearance="col" fill style={[wrapperStyle, { paddingTop: 4 }]}>
      <DNABox alignY="center" style={{ minHeight: COMPONENT_HEIGHT[size] }} childFill fill>
        <Iffy is={required}>
          <DNAText status="danger">*</DNAText>
        </Iffy>
        <Iffy is={!showInput}>
          <DNABox
            alignY="center"
            style={{
              marginLeft: removeMarginPadding ? 0 : 8,
              flex: 1,
              // THIS IS TO KEEP THE SAME ALIGNMENT WITH THE TEXTINPUT SINCE THE UI KITTEN TEXTINPUT ADDS AN EMPTY DIV
              // WITH A MARGINTOP OF 4, MAKING A BIT NOTICEABLE THE ALIGNMENT WHEN SWITCHING FROM THIS TEXT TO THE INPUT
              marginBottom: 4,
            }}
          >
            <Iffy is={toggleShowInputIcon}>
              <DNAButton
                disabled={disabled}
                status="primary"
                appearance="ghostLink"
                size="md"
                padding="none"
                style={{ marginLeft: 10 }}
                iconLeft={toggleShowInputIcon}
                onPress={toggleShowInput}
              />
            </Iffy>
            <DNAText
              testID="email-template-name-input-text-box"
              bold={bold}
              numberOfLines={2}
              style={textStyles}
              onPress={toggleShowInput}
            >
              {value || placeHolder}
            </DNAText>
          </DNABox>
        </Iffy>
        <Iffy is={showInput}>
          <DNABox alignY="center" fill style={{ marginLeft: removeMarginPadding ? 0 : 8 }}>
            <InputComponent
              size={size}
              removeMarginPadding={true}
              hideLabel={true}
              autoFocus={true}
              placeHolder={placeHolder}
              placeholderTextColor={placeholderTextColor}
              value={immediateUpdate ? value : localText}
              onBlur={toggleShowInput}
              status={error ? 'danger' : undefined}
              onChangeText={immediateUpdate ? onChangeText : setLocalText}
              required={required}
              {...rest}
            />
          </DNABox>
        </Iffy>
      </DNABox>
      <Iffy is={error}>
        <DNAText c1 status="danger" style={ { marginTop: 4 }}>{error}</DNAText>
      </Iffy>
    </DNABox>
  );
};

export default EditableText;
