import { SalesforceSyncer } from './SalesforceSyncer';
import {
  CRMSubmitMeetingPayload,
  CRMSubmitResponseReferences,
  RecordToDelete,
  SYNC_STATUS,
} from '../CRMIndexedDBTypes';
import { Logger } from '@aws-amplify/core';
import { MeetingORM } from 'src/types/orms';
import { getVeevaFirstSubmitPayload, getVeevaPayloadToUpdateRecords } from '../Translators/VeevaFormTranslator';
const logger = new Logger('VeevaSyncer', 'DEBUG');

export class VeevaSyncer extends SalesforceSyncer {
  async submitToCRM(crmSubmitPayload: CRMSubmitMeetingPayload): Promise<CRMSubmitResponseReferences> {
    logger.debug('Veeva Submit to CRM is called.', crmSubmitPayload);
    // IF IT'S THE FIRST TIME A MEETING IS BEING SUBMITTED, WE CAN USE THE COMPOSITE TREE SF'S
    // ENDPOINT TO CREATE ALL THE RECORDS WITH ONE CALL
    if (!crmSubmitPayload.mainCrmRecordId) {
      return this.handleVeevaFirstSubmit(crmSubmitPayload);
    }

    return this.handleVeevaUpdates(crmSubmitPayload);
  }

  private async handleVeevaFirstSubmit(crmSubmitPayload: CRMSubmitMeetingPayload):
    Promise<CRMSubmitResponseReferences> {
    logger.debug('Handling first Veeva submit to CRM');
    const payload = await getVeevaFirstSubmitPayload(crmSubmitPayload);
    const response = await super.postFirstSubmit(payload, crmSubmitPayload.tenant);

    if (response.hasError) {
      logger.debug('An error occurred ', response.results);
      throw Error(response.results.toString());
    }

    const submitResponse = response.results.reduce<CRMSubmitResponseReferences>((acc, result) => {
      const errors = result.errors?.map((error) => error.message) || [];

      return {
        ...acc,
        [result.referenceId]: {
          externalId: result.id,
          syncStatus: errors.length ? SYNC_STATUS.ERROR : SYNC_STATUS.SYNCED,
          errorMessages: errors,
        },
      }
    }, {});

    logger.debug('Requests responses', submitResponse);
    return submitResponse;
  }

  public async handleDeleteMeeting(meeting: MeetingORM): Promise<CRMSubmitResponseReferences> {
    const { crmRecord } = meeting.model;
    const recordsToDelete: RecordToDelete[] = [];
    if (!crmRecord?.crmCallId) {
      return {};
    }

    recordsToDelete.push({
      beaconId: 'main-record',
      crmId: crmRecord.crmCallId,
    })

    meeting.model.attendees.forEach((attendee) => {
      if (attendee.crmRecord?.crmCallId) {
        recordsToDelete.push({
          beaconId: attendee.id,
          crmId: attendee.crmRecord?.crmCallId,
        })
      }
    });

    analytics.track('MEETING_DELETE', {
      category: 'MEETING',
      type: 'DELETE',
      integration: 'VEEVA',
      crmRecordId: crmRecord.crmCallId,
    });

    return this.deleteRecords(recordsToDelete);
  }

  private async handleVeevaUpdates(crmSubmitPayload: CRMSubmitMeetingPayload): Promise<CRMSubmitResponseReferences> {
    logger.debug('Handling Veeva update submit to CRM', crmSubmitPayload);
    // GETS AN ARRAY OF RECORDS TO BE UPDATED/CREATED/DELETED
    const separatedRecords = await getVeevaPayloadToUpdateRecords(crmSubmitPayload);
    logger.debug('Veeva upserts payload', separatedRecords);

    const responses = await Promise.all([
      this.deleteRecords(separatedRecords.recordsToDelete),
      this.upsertRecords(
        crmSubmitPayload.tenant,
        separatedRecords.recordsToUpsert,
        separatedRecords.recordsToInsert),
    ]);

    return responses.reduce((acc, resp) => ({ ...acc, ...resp }), {});
  }
}
