import { createMachine, spawn, InvokeCallback } from 'xstate'
import { assign } from '@xstate/immer'
import {
  Dimensions,
  PDFChannelMessage,
  PDFMessageTypes,
  PlayerMode,
  PPZTransform,
  PresentationContentState,
  SetPresentationMeta,
  PlayerEventSource,
  VideoAnalyticsPayload,
} from '@alucio/core'
import { FileType } from '@alucio/aws-beacon-amplify/src/models';
import { RATIOS } from '@alucio/lux-ui/src/components/layout/DNAAspectRatio/DNAAspectRatio';
import { v4 } from 'uuid'
import { Logger } from '@aws-amplify/core';
import { BroadcastChannel } from 'broadcast-channel'
import * as PW from './playerWrapperTypes'
import { AlucioChannel } from '@alucio/lux-ui';
import equal from 'fast-deep-equal';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep'

export * as PW from './playerWrapperTypes'
const logger = new Logger('PlayerWrapperSM', 'INFO');

type ChannelObserverCallback<T extends PW.PlayerWrapperEvents> = InvokeCallback<T, PW.PlayerWrapperEvents>

// [TODO] - Consider bumping XState for some new features
//          - Arbitrary arguments in guards
//          - Better TS experience

// [TODO] - Consider creating a generic observer interface (zen-observable -- set workerChannel)
const presentationStateObserver = (channel: PW.PlayerWrapperContext['presentationStateChannel']):
  ChannelObserverCallback<PW.EVT_PRESENTATION_STATE_SYNC> =>
  (send) => {
    const id = v4()
    const handler = (msg: PW.PresentationChannelMessage) => {
      // We only pay attention to PRESENTATION_STATE_SYNC events which are broadcast
      // by the PresentationBroadCastProvider
      logger.debug(`Got Presentation Channel Message [${id}]`, msg)
      if (msg.type === 'PRESENTATION_STATE_SYNC') {
        send({
          type: 'PRESENTATION_STATE_SYNC',
          meetingId: msg.meetingId,
          payload: msg.payload,
          messageNumber: msg.messageNumber,
        })
      }
      if (msg.type === 'PRESENTATION_STATE_IDLE') {
        send({
          type: 'PRESENTATION_STATE_IDLE',
          meetingId: msg.meetingId,
        })
      }
    }

    logger.debug(`attaching listener to presentation channel ${id}`, channel)
    channel.addEventListener(
      'message',
      handler,
    )
    return () => {
      logger.debug('Cleanup Called for presentation channel')
      // We close the channel which also releases the handler
      channel.close()
    }
  }

const playerStateObserver = (
  channel: PW.PlayerWrapperContext['playerStateChannel'],
  meetingId: string,
  playerContext: PlayerEventSource,
):
  ChannelObserverCallback<PW.EVT_PLAYER_ACTION> =>
  (send) => {
    const handler = (msg: PDFChannelMessage) => {
      if (msg.type === PDFMessageTypes.ANALYTICS) {
        const values = msg.value as VideoAnalyticsPayload
        analytics?.track('VIDEO_PRESENTED', {
          documentId: values.documentId,
          documentVersionId: values.documentVersionId,
          meetingId,
          context: playerContext,
          videoStartTime: values.videoStartTime,
          videoEndTime: values.videoEndTime,
        })
        return;
      }

      send({
        type: 'PLAYER_ACTION',
        payload: msg,
      })
    }
    logger.debug('attaching listener to channel', channel)
    channel.addEventListener(
      'message',
      handler,
    )
    return async () => {
      logger.debug('Cleanup Called for player channel')
      // We close the channel which also releases the handler
      await AlucioChannel.remove(AlucioChannel.commonChannels.PDF_CHANNEL)
    }
  }

const PlayerWrapper = (
  meetingId: string = 'aaaaaa',
  playerMode: PlayerMode,
  frameId: string = v4(),
  lockAspectRatio: boolean,
  playerContext: PlayerEventSource,
) => createMachine<
  PW.PlayerWrapperContext,
  PW.PlayerWrapperEvents,
  PW.PlayerWrapperState
>(
  {
    predictableActionArguments: false,
    id: 'PlayerWrapper',
    context: {
      aspectRatio: RATIOS['16_9'],
      frameId,
      meetingId,
      playerMode,
      // We need to create a new channel rather than use AlucioChannel.get otherwise the context and wrapper
      // are using the same channel object you we won't receive any messages
      presentationStateChannel: new BroadcastChannel<PW.PresentationChannelMessage>('PRESENTATION_CHANNEL'),
      playerStateChannel: AlucioChannel.get(AlucioChannel.commonChannels.PDF_CHANNEL),
      messageNumber: 0,
      shouldBroadcastPlayerMessages: playerMode === 'INTERACTIVE',
      lockAspectRatio,
    },
    initial: 'initialize',
    states: {
      initialize: {
        tags: [PW.Tags.IS_IDLE],
        always: {
          actions: assign(ctx => {
            logger.debug(`In Entry ${!!ctx.presentationStateObserver} ${ctx.frameId}`)
            if (!ctx.presentationStateObserver) {
              // [TODO] - Try to get types working (returns an unsub function)
              const actor = spawn(
                presentationStateObserver(ctx.presentationStateChannel),
                'presentationStateObserverActor',
              )

              ctx.presentationStateObserver = actor
            }

            if (!ctx.playerStateObserver) {
              const actor = spawn(
                playerStateObserver(ctx.playerStateChannel, meetingId, playerContext),
                'playerStateObserverActor',
              )

              ctx.playerStateObserver = actor
            }
          }),
          target: '#PlayerWrapper.idle',
        },
      },
      idle: {
        tags: [PW.Tags.IS_IDLE],
        on: {
          PRESENTATION_STATE_SYNC: {
            target: 'presenting.loading.initializing',
            actions: [
              'logEvent',
              'setPresentableState',
              'clearPlayerState',
            ],
          },
        },
      },
      presenting: {
        states: {
          loading: {
            initial: 'initializing',
            states: {
              initializing: {
                entry: assign((ctx) => { ctx.frameId = v4() }),
                on: {
                  PLAYER_ACTION: {
                    cond: 'playerInitialized',
                    actions: [
                      'logEvent',
                      'setPlayerMode',
                    ],
                    target: 'initialized',
                  },
                },
              },
              initialized: {
                always: {
                  cond: (ctx) => !!ctx.presentableState && ctx.presentableState.JWT !== 'PENDING',
                  actions: [
                    'loadDocument',
                  ],
                  target: 'loading',
                },
                on: {
                  PRESENTATION_STATE_SYNC: {
                    actions: [
                      'logEvent',
                      'setPresentableState',
                    ],
                  },
                },
              },
              loading: {
                on: {
                  PLAYER_ACTION: {
                    cond: 'playerDocumentLoaded',
                    target: '#PlayerWrapper.presenting.ready',
                    actions: assign((ctx, evt) => {
                      logger.debug(evt.type, { ctx, evt })
                      ctx.playerState = cloneDeep(ctx.presentableState)
                      // We always clear out the PPZ state and let the player update
                      // to make sure we're kept in sync 
                      ctx.playerState && (ctx.playerState.ppzCoords = undefined)
                    }),
                  },
                },
              },
            },
            // [TODO] - Check if this is valid
            on: {
              PRESENTATION_STATE_SYNC: [
                {
                  cond: 'loadNewDocument',
                  target: '#PlayerWrapper.presenting.loading.initializing',
                  actions: [
                    'logEvent',
                    'setPresentableState',
                    'clearPlayerState',
                  ],
                },
              ],
            },
          },
          ready: {
            tags: [PW.Tags.IS_CONTENT_LOADED],
            on: {
              PLAYER_ACTION: {
                cond: 'isMyIFrameEvt',
                actions: [
                  'logEvent',
                  'resolvePlayerEvent',
                ],
              },
              RELOAD_WEB_DOC: {
                cond: (ctx) => ctx.presentableState?.contentType === FileType.WEB ||
                  ctx.presentableState?.contentType === FileType.HTML,
                target: '#PlayerWrapper.presenting.loading.initializing',
                actions: [
                  'logEvent',
                  'setPresentableState',
                  'clearPlayerState',
                ],
              },
              // Presentation Provider (state) -> State Machine
              PRESENTATION_STATE_SYNC: [
                {
                  cond: 'loadNewDocument',
                  target: '#PlayerWrapper.presenting.loading.initializing',
                  actions: [
                    'logEvent',
                    'setPresentableState',
                    'clearPlayerState',
                  ],
                },
                {
                  cond: 'presentationStateChanged',
                  actions: [
                    'logEvent',
                    'setPresentableState',
                    'applyPresentableState',
                  ],
                },
              ],
              TOGGLE_HIGHLIGHTER: {
                actions: ['toggleHighlighter'],
              },
            },
          },
        },
      },
    },
    on: {
      PRESENTATION_STATE_IDLE:
      {
        cond: 'isMyMeetingEvt',
        target: 'idle',
        actions: [
          'logEvent',
          'setPresentableState',
          'clearPlayerState',
        ],
      },
    },
  },
  {
    guards: {
      isMyIFrameEvt: (ctx, event) => {
        const evt = event as PW.EVT_PLAYER_ACTION
        return ctx.frameId === evt.payload.frameId
      },
      isMyMeetingEvt: (ctx, event) => {
        const evt = event as (PW.EVT_PRESENTATION_STATE_IDLE | PW.EVT_PRESENTATION_STATE_SYNC)
        return ctx.meetingId === evt.meetingId
      },
      loadNewDocument: (ctx, event) => {
        const evt = event as PW.EVT_PRESENTATION_STATE_SYNC
        const isSameMeeting = ctx.meetingId === evt.meetingId
        const hasDocumentVersionId = !!evt.payload.documentVersionId && !!ctx?.playerState?.documentVersionId
        const isPresentableIdDifferent = ctx?.presentableState?.documentVersionId !== evt.payload.documentVersionId
        const isPlayerStateIdDifferent = ctx?.playerState?.documentVersionId !== evt.payload.documentVersionId
        return (
          isSameMeeting &&
          hasDocumentVersionId &&
          (isPresentableIdDifferent || isPlayerStateIdDifferent)
        )
      },
      presentationStateChanged: (ctx, event) => {
        const evt = event as PW.EVT_PRESENTATION_STATE_SYNC
        logger.debug('checking presentation state changed', { existing: ctx.presentableState, new: evt.payload })
        return evt.meetingId === ctx.meetingId && !equal(ctx.presentableState, evt.payload)
      },
      playerInitialized: (ctx, event) => {
        const evt = event as PW.EVT_PLAYER_ACTION
        return evt.payload.frameId === ctx.frameId && evt.payload.type === 'IFRAME_LOADED'
      },
      playerDocumentLoaded: (ctx, event) => {
        const evt = event as PW.EVT_PLAYER_ACTION
        return evt.payload.frameId === ctx.frameId && evt.payload.type === 'DOCUMENT_LOADED'
      },
    },
    actions: {
      logEvent: (ctx, evt, meta) => {
        logger.debug(evt.type, { ctx, evt, meta })
      },
      loadDocument: (ctx, _, __) => {
        logger.debug('actions.loadDocument')
        const contentState = ctx.presentableState
        if (contentState) {
          const msg = {
            type: PDFMessageTypes.LOAD_FILE,
            frameId: ctx.frameId,
            value: {
              JWT: contentState.JWT,
              bucket: contentState.bucket,
              docPath: contentState.docPath,
              documentVersionId: contentState.documentVersionId,
              documentId: contentState.documentId,
              groupId: contentState.groupId,
              contentType: contentState.contentType,
              visiblePages: contentState.visiblePages,
              contentURL: contentState.contentURL,
              state: {
                page: contentState.state.page,
                step: contentState.state.step,
                viewport: contentState.ppzCoords,
              },
            },
          }
          logger.debug('sending LOAD_DOCUMENT message')
          ctx.playerStateChannel.postMessage(msg)
        }
      },
      clearPlayerState: assign((ctx) => {
        ctx.playerState = undefined
      }),
      // Beacon Provider (Updated State) -> Sync to machine -> Resolves what to do
      applyPresentableState: assign((ctx, event, __) => {
        logger.debug('actions.applyPresentableState')
        if (ctx.presentableState && ctx.playerState) {
          const presentableState = ctx.presentableState
          const playerState = ctx.playerState
          // If groups change, update meta which controls show/hide specific slides to make groups function
          if (presentableState.groupId !== playerState.groupId) {
            logger.debug('Detected GroupID Change from Presentable')
            const payload: SetPresentationMeta = {
              groupId: presentableState.groupId,
              slide: presentableState.state.page,
              step: presentableState.state.step,
              visiblePages: [...presentableState.visiblePages],
            }
            ctx.playerStateChannel.postMessage({
              type: PDFMessageTypes.SET_PRESENTATION_META,
              frameId: ctx.frameId,
              value: payload,
            })
            playerState.groupId = presentableState.groupId
            playerState.state = presentableState.state
            playerState.visiblePages = presentableState.visiblePages
          }
          // If beacon master state is different from player state
          // Force a sync to the player (beacon master overrides player state)
          // Note: we don't want to send a SET_STATE msg to the player upon an
          // update of the totalSteps
          else if (!equal(omit(presentableState.state, ['totalSteps']), omit(playerState.state, ['totalSteps']))) {
            logger.debug('Detected Presentation State (Progress) Change from Presentable')
            if (ctx.messageNumber === event.messageNumber || ctx.playerMode !== 'INTERACTIVE') {
              const payload: PresentationContentState = {
                documentVersionId: presentableState.documentVersionId,
                groupId: presentableState.groupId,
                page: presentableState.state.page,
                step: presentableState.state.step,
                totalSteps: presentableState.state.totalSteps,
                viewport: {
                  positionX: presentableState.ppzCoords?.positionX ?? 0,
                  positionY: presentableState.ppzCoords?.positionY ?? 0,
                  scale: presentableState.ppzCoords?.scale ?? 1,
                },
              }
              ctx.playerStateChannel.postMessage({
                type: PDFMessageTypes.SET_PRESENTATION_STATE,
                frameId: ctx.frameId,
                value: payload,
              })
            }
            playerState.state.page = presentableState.state.page
            playerState.state.step = presentableState.state.step
          }
          // If zoom coordinates are different, force player state to take beacon's last known zoom state
          else if (!equal(presentableState.ppzCoords, playerState.ppzCoords) &&
            presentableState.ppzCoords && ctx.playerMode !== 'INTERACTIVE') {
            logger.debug('Detected PPZ Change from Presentable')
            const payload: PPZTransform = {
              positionX: presentableState.ppzCoords.positionX,
              positionY: presentableState.ppzCoords.positionY,
              scale: presentableState.ppzCoords.scale,
            }
            ctx.playerStateChannel.postMessage({
              type: PDFMessageTypes.PPZ_TRANSFORM,
              frameId: ctx.frameId,
              value: payload,
            })
          } else if (presentableState.JWT !== playerState.JWT) {
            logger.debug('Detected ContentAccessToken Change from Presentable');
            ctx.playerStateChannel.postMessage({
              type: PDFMessageTypes.SET_CONTENT_ACCESS_TOKEN,
              frameId: ctx.frameId,
              value: presentableState.JWT,
            });
            playerState.JWT = presentableState.JWT;
          }
        } else {
          logger.debug('No changes detected between Presentable and Player State')
        }
      }),
      resolvePlayerEvent: assign((ctx, event) => {
        logger.debug('actions.resolvePlayerEvent')
        const evt = event as PW.EVT_PLAYER_ACTION
        const shouldBroadcast = ctx.shouldBroadcastPlayerMessages
        switch (evt.payload.type) {
          case 'PDF_PAGE_LOADED': {
            // Temporary fix for BEAC-3025 by removing this we lock to 16:9
            // and avoid timing issues of switching between windows
            if (!ctx.lockAspectRatio) {
              const pageBounds = evt.payload.value as Dimensions
              const pageRatio = parseFloat(
                (pageBounds.height / pageBounds.width).toFixed(2),
              )

              if (ctx.presentableState?.contentType === FileType.WEB) {
                ctx.aspectRatio = RATIOS.FULL
              } else {
                ctx.aspectRatio = pageRatio === RATIOS['4_3']
                  ? RATIOS['4_3']
                  : RATIOS['16_9']
              }

              break
            }
            break
          }
          case 'NAVIGATE_PAST_FIRST': {
            logger.debug('Broadcasting NAVIGATE_PAST_FIRST')
            shouldBroadcast && ctx.presentationStateChannel.postMessage({
              type: 'NAVIGATE_PAST_FIRST',
              meetingId: ctx.meetingId,
            })
            break
          }
          case 'NAVIGATE_PAST_LAST': {
            logger.debug('Broadcasting NAVIGATE_PAST_LAST')
            shouldBroadcast && ctx.presentationStateChannel.postMessage({
              type: 'NAVIGATE_PAST_LAST',
              meetingId: ctx.meetingId,
            })
            break
          }
          case 'SHOW_SEARCH': {
            logger.debug('Broadcasting SHOW_SEARCH')
            shouldBroadcast && ctx.presentationStateChannel.postMessage({
              type: 'SHOW_SEARCH',
              meetingId: ctx.meetingId,
            })
            break
          }
          case 'SHOW_MY_CONTENT': {
            logger.debug('Broadcasting SHOW_MY_CONTENT')
            shouldBroadcast && ctx.presentationStateChannel.postMessage({
              type: 'SHOW_MY_CONTENT',
              meetingId: ctx.meetingId,
            })
            break
          }
          case 'PPZ_TRANSFORM': {
            logger.debug('Handling PPZ Transform')
            const newPPZ = evt.payload.value as PPZTransform
            if (ctx.playerState) {
              ctx.playerState.ppzCoords = newPPZ
            }
            if (!equal(ctx.presentableState?.ppzCoords, newPPZ)) {
              logger.debug('Broadcasting PPZ Change', newPPZ)
              shouldBroadcast && ctx.presentationStateChannel.postMessage({
                type: 'PPZ_TRANSFORM',
                meetingId: ctx.meetingId,
                presentableGroupId: ctx.playerState?.groupId ?? '',
                payload: newPPZ,
              })
            }
            break
          }
          case 'PAGE_CHANGE': {
            const newState = evt.payload.value as PresentationContentState
            if (ctx.playerState) {
              ctx.playerState.state.page = newState.page ?? ctx.playerState.state.page
              ctx.playerState.state.step = newState.step ?? ctx.playerState.state.step
            }
            if (newState.page !== ctx.presentableState?.state.page ||
              newState.step !== ctx.presentableState?.state.step ||
              newState.totalSteps !== ctx.presentableState?.state.totalSteps) {
              const payload = {
                page: newState.page ?? 0,
                step: newState.step ?? 0,
                groupId: newState.groupId ?? '',
                totalSteps: newState.totalSteps ?? 0,
              }
              logger.debug('Broadcasting Presentation Progress Change', payload)
              ctx.messageNumber = ctx.messageNumber ? ctx.messageNumber + 1 : 1;
              shouldBroadcast && ctx.presentationStateChannel.postMessage({
                type: 'PRESENTATION_PROGRESS',
                meetingId: ctx.meetingId,
                payload: payload,
                messageNumber: ctx.messageNumber,
              })
            }
            break
          }
          default: {
            logger.warn(`Unrecognized Player Event ${evt.payload.type} encountered`)
          }
        }
      }),
      setPlayerMode: (ctx) => {
        logger.debug('actions.setPlayerMode')
        ctx.playerStateChannel.postMessage({
          type: PDFMessageTypes.PLAYER_MODE,
          frameId: ctx.frameId,
          value: {
            mode: ctx.playerMode,
          },
        })
      },
      setPresentableState: assign((ctx, event) => {
        logger.debug('actions.setPresentableState')
        const evt = event as PW.EVT_PRESENTATION_STATE_SYNC
        ctx.presentableState = evt.payload
      }),
      toggleHighlighter: assign((ctx, event) => {
        logger.debug('actions.toggleHighlighter')
        const contentState = ctx.presentableState
        if (contentState) {
          // TODO: Remove as in a followiing ticket
          const evt = event as PW.TOGGLE_HIGHLIGHTER
          const isEnabled = evt.highlighterVisible
          const msg = {
            type: PDFMessageTypes.TOGGLE_HIGHLIGHTER,
            frameId: ctx.frameId,
            value: isEnabled,
          }
          ctx.playerStateChannel.postMessage(msg)
        }
      }),
    },
  },
)

export default PlayerWrapper
