import { DocumentVersion, CustomFieldUsage } from '@alucio/aws-beacon-amplify/src/models'
import { DocumentORM, DocumentVersionORM, DOCUMENT_ACTIONS_ENUM } from 'src/types/types'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'

import { store } from 'src/state/redux';
import { canPerformAction, allDocumentsSortedAndFilteredFactory } from 'src/state/redux/selector/document'
import { tenantList } from 'src/state/redux/selector/tenant'
import { getMappedCustomValues } from 'src/state/redux/selector/common'
import { generateAllPagesForVersion } from 'src/utils/documentHelpers'

interface VersionChanges {
  isMajorVersionRequired: boolean,
  isMajorVersionProposed: boolean,
}

/**
 * We cannot pass DocumentORMs around via the context or through events
 * due to it not being serializable (circular structure)
 *
 * Instead, we use Redux is a plain JS way with selectors to grab the latest ORM
 * at any given time
 */

export const getDocumentORMFactory = function (id: string): () => DocumentORM {
  const documentSelector = allDocumentsSortedAndFilteredFactory()
  const filter = { filter: { model: { id } } }

  return (): DocumentORM => {
    const [targetDoc] = documentSelector(
      store.getState(),
      undefined,
      filter,
    ) as DocumentORM[]

    if (!targetDoc) throw new Error('Target Document not found')
    return targetDoc
  }
}

export const omitInternalFields = (docVer: Partial<DocumentVersion>) => {
  return omit(docVer, 'versionNumber', '_version', '_lastChangedAt', '_deleted', 'contentSource')
}

/**
 * Compares a working version draft against a previously published version
 * to determine what type of SemVer change should be applied
 */
export const determineSemVerChange = (
  latestDocumentVersion: Partial<DocumentVersion>,
  latestPublishedVersion?: DocumentVersionORM,
): VersionChanges => {
  const minorChange = {
    isMajorVersionRequired: false,
    isMajorVersionProposed: false,
  };

  const majorSuggestedChange = {
    isMajorVersionRequired: false,
    isMajorVersionProposed: true,
  }

  const majorRequiredChange = {
    isMajorVersionRequired: true,
    isMajorVersionProposed: true,
  }

  const draftPages = generateAllPagesForVersion(
    latestDocumentVersion.id ?? '', latestDocumentVersion.numPages ?? 0, latestDocumentVersion.pageSettings ?? [])
  const draftPageGroups = latestDocumentVersion?.pageGroups;
  const latestPublishedDocVersion = latestPublishedVersion?.model
  const latestPublishedPages = latestPublishedDocVersion ? generateAllPagesForVersion(
    latestPublishedDocVersion.id, latestPublishedDocVersion.numPages ?? 0, latestPublishedDocVersion.pageSettings) : []
  const latestPublishedPageGroups = latestPublishedVersion?.model.pageGroups

  // [NOTE] - This really shouldn't happen as pages should always be populated after file processing
  if (!latestPublishedPages || !draftPages) {
    return minorChange
  }

  // 1. A new document (first version to be published)
  //    We don't show this in the UI anyways, but might be nice to have later
  const isFirstDraft = latestDocumentVersion && !latestPublishedVersion
  if (isFirstDraft) {
    return majorRequiredChange
  }

  // 2. Draft version has fewer slides
  const draftVersionHasFewerSlides = draftPages.length < latestPublishedPages.length
  if (draftVersionHasFewerSlides) {
    return majorRequiredChange
  }

  // 3. Draft version grouping has changed due to one of
  //    a. amount of groups not matching (including not having any groups)
  //    b. slide indexes not matching
  const pageGroupLengthChanged = Number(draftPageGroups?.length) !== Number(latestPublishedPageGroups?.length)
  const draftPageGroupSlideIndexes = draftPageGroups
    ?.map(pageGroup => pageGroup.pageIds?.map(pageId => pageId.split('_')[2]))
  const publishedPageGroupSlideIndexes = latestPublishedPageGroups
    ?.map(pageGroup => pageGroup.pageIds?.map(pageId => pageId.split('_')[2]))
  const pageGroupIndexesChanged = !isEqual(draftPageGroupSlideIndexes, publishedPageGroupSlideIndexes)

  if (pageGroupLengthChanged || pageGroupIndexesChanged) {
    return majorRequiredChange
  }

  // 4. Required slides changed
  const draftRequiredSlides = draftPages?.map((page, idx) => ({ [idx]: !!page.isRequired }))
  const publishedRequiredSlides = latestPublishedPages?.map((page, idx) => ({ [idx]: !!page.isRequired }))
  // [NOTE] - We first compare the original range of slides for required changes
  const originalSlidesRequiredCheck = !isEqual(
    draftRequiredSlides.slice(0, publishedRequiredSlides.length),
    publishedRequiredSlides,
  )
  // [NOTE] - If any new additional slides have required, then flag for major change
  const additionalSlidesRequiredCheck = draftRequiredSlides
    .slice(publishedRequiredSlides.length, draftRequiredSlides.length)
    .some(slide => Object.values(slide).some(required => required))

  if (originalSlidesRequiredCheck || additionalSlidesRequiredCheck) {
    return majorRequiredChange
  }

  const {
    MSLSelectSlides: previousModifyPermission,
  } = latestPublishedVersion?.meta.permissions ?? {}

  const [currentUserTenant] = tenantList(store.getState())
  const draftConfigsMap = getMappedCustomValues(
    { internalUsages: [CustomFieldUsage.DOCUMENT] },
    latestDocumentVersion.customValues,
    currentUserTenant.config?.customFields,
  )

  // 5. Document is no longer modifiable
  const draftModifiablePermission = canPerformAction(
    DOCUMENT_ACTIONS_ENUM.modify,
    draftConfigsMap,
    currentUserTenant,
  )
  const noLongerModifiable = (!!previousModifyPermission === true && !draftModifiablePermission)

  if (noLongerModifiable) { return majorRequiredChange }

  // 6. Additional pages without other required/grouping changes
  if (draftPages.length > latestPublishedPages.length) {
    return majorSuggestedChange
  }

  // 7. Check if the linked slides have changed
  const linkedSlidesChange = draftPages.some((page, idx) => {
    const publishedPage = latestPublishedPages[idx]

    // 7b. To be backwards compatible with older records pre-linked slides schema changes, equate an undefined linkedSlides to the draft's empty array
    //     - The better fix is to make the schema non-nullable and run a migration to force all the older records to
    //       have an empty array so we can skip this edge case check
    if (!publishedPage.linkedSlides && !page.linkedSlides?.length) {
      return false
    }

    // check if two arrays are equal (strictly)
    return !isEqual(
      // the id of the pages should be removed
      page.linkedSlides?.map(e => e.split('_')[2]),
      publishedPage.linkedSlides?.map(e => e.split('_')[2]),
    )
  })

  if (linkedSlidesChange) {
    return majorRequiredChange
  }

  // 8. Otherwise anything else is a minor change
  return minorChange;

  // 8. [TODO] - Preserve user's last selection if minor change?
}

export default {
  getDocumentORMFactory,
  omitInternalFields,
  determineSemVerChange,
}
