import { useSelector } from 'react-redux';
import { createSelector, Selector } from '@reduxjs/toolkit';
import {
  ContentShare,
} from '@alucio/aws-beacon-amplify/src/models'
import { RootState } from '../store'
import { ORMTypes, ContentShareORM } from 'src/types/orms';
import { ExistingShareLink } from 'src/utils/shareLink/shareLink.web';
import config from '../../../config/app.json';

export const selContentShares = (state: RootState): ContentShare[] => state.contentShare.records

// not sure if we need a ContentShareORM?
const toContentShareORM = (
  contentShare: ContentShare,
): ContentShareORM => {
  return {
    model: contentShare,
    type: ORMTypes.CONTENT_SHARE,
  }
}

const toExistingLinkFormat = (contentShare: ContentShare, title: string): ExistingShareLink => {
  return {
    expiresAt: contentShare.expiresAt,
    id: contentShare.id,
    link: `${config.contentURL}/${contentShare.type}/${contentShare.token}`,
    title,
  }
}

const contentShareList: Selector<RootState, ContentShareORM[]> = createSelector(
  selContentShares,
  (contentShares): ContentShareORM[] => contentShares.map(contentShare => toContentShareORM(contentShare)),
)

const contentShareListByContentId: Selector<RootState, ContentShareORM[]> = createSelector(
  contentShareList,
  (_, contentId: string) => contentId,
  (contentShareListORM, contentId): ContentShareORM[] =>
    contentShareListORM.filter((contentShare: ContentShareORM) => contentShare.model.contentId === contentId),
)

const existingContentShareLink: Selector<RootState, ExistingShareLink> = createSelector(
  contentShareList,
  (_, contentShareId: string) => contentShareId,
  (contentShareListORM, contentShareId): ExistingShareLink => {
    const existingContentShareORM = contentShareListORM
      .find((contentShare: ContentShareORM) => contentShare.model.id === contentShareId)
    // typescript not sure why I need to cast as string when it is already string
    return toExistingLinkFormat(existingContentShareORM.model, '')
  },
)

export const useContentShareList = (): ReturnType<typeof contentShareList> =>
  useSelector((state: RootState) => contentShareList(state))

export const useContentShareListByContentId = (contentId: string): ReturnType<typeof contentShareListByContentId> =>
  useSelector((state: RootState) => contentShareListByContentId(state, contentId))

export const useExistingContentShareLink =
  (contentShareId: string, title: string): ReturnType<typeof existingContentShareLink> =>
    useSelector((state: RootState) => existingContentShareLink(state, contentShareId, title))
