import React, { useEffect, useRef, useState } from 'react'
import { Image, ImageBackground, StyleSheet, Text, ViewProps } from 'react-native'
import type { StateFrom } from 'xstate'
import { useMachine } from '@xstate/react'

import {
  DNABox,
  DNAText,
  DNAButton,
  DNAAspectRatio,
  Iffy,
  Stack,
} from '@alucio/lux-ui'
import {
  PlayerMode,
  PlayerEventSource,
  nonSelectableElementHook,
} from '@alucio/core'
import playerWrapperMachine, { PW } from 'src/state/machines/presentation/playerWrapper'
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings'
import { PresentationBroadcastContent } from '@alucio/core/lib/state/context/PresentationPlayer/types'
import { v4 } from 'uuid'
import { FileType, MeetingType } from '@alucio/aws-beacon-amplify/src/models'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy'
import useCurrentPage from '../DNA/hooks/useCurrentPage'
import { DNARoute } from 'src/router/routeDef'
import { useHighlighter } from '../Highlighter/HighlighterProvider'
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy'
import Highlighter from '../Highlighter/Highlighter'

type PlayerWrapperMachine = ReturnType<typeof playerWrapperMachine>
type PlayerWrapperState = StateFrom<PlayerWrapperMachine>

const styles = StyleSheet.create({
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
  },
  loaderImage: {
    height: 200,
    width: 200,
  },
  watermarkContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    position: 'absolute',
    zIndex: 3,
    marginTop: 16,
    marginLeft: 16,
    borderRadius: 4,
    paddingHorizontal: 16,
    paddingVertical: 5,
  },
  webDocActionbar: {
    borderBottomWidth: 1,
    borderBottomColor: colors['color-gray-80'],
    backgroundColor: colors['color-text-white'],
  },
  initialTextContainer: {
    backgroundColor: colors['color-black-transparent-40'],
  },
  initialTextCopy: { maxWidth:558, textAlign:'center', lineHeight: 24 },
});

const useFormatted = (state: PlayerWrapperState) => {
  const currentState = JSON.stringify({ state: state.value, frameId: state.context.frameId })

  return {
    currentState,
  }
}

const useCond = (state: PlayerWrapperState) => {
  const isDocumentLoaded = state.hasTag(PW.Tags.IS_CONTENT_LOADED)
  const isIdle = state.hasTag(PW.Tags.IS_IDLE)

  return {
    isDocumentLoaded,
    isIdle,
  }
}

interface IFrameWrapperProps {
  isFullWindow: boolean,
  meetingId: string,
  showDebug?: boolean,
  mode?: PlayerMode,
  /** TODO: Remove this once the content preview modal no longer supports full window mode on tablet */
  isTabletMeetingMode?: boolean,
  isDarkMode?: boolean,
  lockAspectRatio?: boolean,
  isFromMeetings?: boolean,
  wrapperStyle?: ViewProps['style'],
  isCustomDeck?: boolean,
  highlighterVisible?: boolean,
  returnToHome?: () => void,
}

const IFrameWrapper: React.FC<IFrameWrapperProps> & {Highlighter: typeof PlayerWrapperHighlighter} = ({
  isFullWindow,
  meetingId,
  showDebug = false,
  mode = 'INTERACTIVE',
  isTabletMeetingMode,
  isDarkMode = true,
  lockAspectRatio = true,
  isFromMeetings = false,
  wrapperStyle,
  isCustomDeck,
  highlighterVisible,
  returnToHome,
}) => {
  const { deviceMode } = useAppSettings();
  const [hasPresented, setHasPresented] = useState<boolean>(false)
  const iFrameRef = useRef<HTMLIFrameElement>(null)
  const currentPage = useCurrentPage();
  const getPlayerContext = (currentPage: DNARoute | undefined): PlayerEventSource => {
    if (currentPage && currentPage.LABEL === 'Meetings') return PlayerEventSource.MEETING
    else if (currentPage && currentPage.PATH === '/publisher') return PlayerEventSource.PUBLISHER
    else return PlayerEventSource.APP
  }
  const playerContext = getPlayerContext(currentPage)
  const machine = useRef(playerWrapperMachine(meetingId, mode, v4(), lockAspectRatio, playerContext)).current
  const [state, send] = useMachine(machine)
  useEffect(() => {
    if (!state.tags.has(PW.Tags.IS_IDLE)) {
      setHasPresented(true)
    }
  }, [state])
  useEffect(() => {
    send('TOGGLE_HIGHLIGHTER', { highlighterVisible: highlighterVisible })
  }, [highlighterVisible])
  const formatted = useFormatted(state)
  const cond = useCond(state)
  const formatContentInfo = (data: PresentationBroadcastContent) => {
    const { ppzCoords, state, groupId, visiblePages, documentVersionId: docVerId } = data
    return JSON.stringify({
      docVersionId: `${docVerId.substring(0, 5)}...${docVerId.substring(docVerId.length - 5)}`,
      ppzCoords,
      state,
      groupId,
      visiblePages: visiblePages.join(','),
    }, null, '  ')
  }

  const isWebDoc = state.context.presentableState?.contentType === FileType.WEB
  const isVideoDoc = state.context.presentableState?.contentType === FileType.MP4
  const isHTMLDoc = state.context.presentableState?.contentType === FileType.HTML
  const isInPersonlMeeting = useMeetingsState()?.meetingORM?.model.type === MeetingType.IN_PERSON
  // eslint-disable-next-line max-len
  const iframeSrc = `/pdf/index.html?frameId=${state.context.frameId}&isFullWindow=${isFullWindow}&isTabletMode=${deviceMode === DeviceMode.tablet}&isTabletMeetingMode=${isTabletMeetingMode}`
  const addtionalPadding = (isCustomDeck || isInPersonlMeeting) && (isWebDoc || isVideoDoc || isHTMLDoc)
  const IframeWrapperRef = useRef<HTMLDivElement>(null)
  nonSelectableElementHook(IframeWrapperRef)

  return (
    <DNABox ref={IframeWrapperRef} testID="content-preview-modal-iframe" fill appearance="col" style={wrapperStyle}>
      <Iffy is={state.can('RELOAD_WEB_DOC')}>
        <DNABox
          style={[styles.webDocActionbar, isFromMeetings ? { padding: 14 } : { paddingBottom: 14, marginBottom: 14 }]}
        >
          <DNAButton
            appearance="outline"
            status="tertiary"
            iconLeft="home"
            onPress={() => {
              returnToHome && returnToHome()
              send('RELOAD_WEB_DOC')
            }}
            testID="return-home-button"
          >
            Return home
          </DNAButton>
        </DNABox>
      </Iffy>
      <Iffy is={showDebug}>
        <DNAText h1>Current State</DNAText>
        <DNAText>{formatted.currentState}</DNAText>
        <DNABox appearance="row" fill>
          <DNABox appearance="col">
            <Text>
              Presentable State
              {state.context.presentableState ? formatContentInfo(state.context.presentableState) : ''}
            </Text>
          </DNABox>
          <DNABox appearance="col">
            <Text>
              Player State
              {state.context.playerState ? formatContentInfo(state.context.playerState) : ''}
            </Text>
          </DNABox>
        </DNABox>
      </Iffy>
      <Iffy is={state.value === 'idle' && isDarkMode && isFromMeetings}>
        <ImageBackground
          source={require('../../../assets/images/beacon-presentation-bg.jpg')}
          style={{ flex: 1 }}
        >
          <Iffy is={mode === 'NON_INTERACTIVE' && !hasPresented}>
            <DNABox
              appearance="col"
              alignX="center"
              alignY="center"
              fill
              spacing="xl"
              style={styles.initialTextContainer}
            >
              <DNABox
                appearance="col"
                alignX="center"
              >
                <DNAText h1 bold status="basic">Share this window with your attendees.</DNAText>
                <DNAText h1 bold status="basic">Ensure it remains visible.</DNAText>
              </DNABox>
              <DNABox
                spacing="md"
                alignX="center"
                appearance="col"
              >

                <DNAText
                  status="basic"
                  style={styles.initialTextCopy}
                >
                  {/* eslint-disable-next-line max-len */}
                  Most screen sharing applications only focus on windows that can be seen. If your window gets covered, viewers will no longer be able to see what you’re sharing.
                </DNAText>
              </DNABox>

            </DNABox>
          </Iffy>
        </ImageBackground>
      </Iffy>
      <Iffy is={state.value !== 'idle'}>
        <DNABox
          fill
          appearance="col"
          style={{
            backgroundColor: isDarkMode ? 'black' : '',
            paddingBottom: addtionalPadding ? 40 : '',
          }}
        >
          <DNAAspectRatio ratio={state.context.aspectRatio} isFullWidth={isWebDoc || isHTMLDoc}>
            <Iffy is={!cond.isIdle}>
              {/* WATERMARK */}
              <Iffy is={state.context.presentableState?.watermarkText}>
                <DNABox style={styles.watermarkContainer}>
                  <DNAText bold h2 status="basic" >{state.context.presentableState?.watermarkText}</DNAText>
                </DNABox>
              </Iffy>
              {/* IFRAME */}
              <Stack >
                {/* by default the first stack layer is posicionated ad a relative for that reason we have this empty stack */}
                <Stack.Layer />
                <Stack.Layer fill style={{ top: 0, left: 0, right: 0, bottom: 0 }}>
                  <DNABox fill>
                    <iframe
                      id="player-iframe"
                      object-fit="cover"
                      frameBorder={0}
                      ref={iFrameRef}
                      scrolling={isWebDoc || isHTMLDoc ? 'auto' : 'no'}
                      src={iframeSrc}
                      style={{
                        flex: 1,
                        overflow: 'hidden',
                      }}
                      key={iframeSrc}
                    />
                  </DNABox>
                </Stack.Layer>
                {highlighterVisible && <Stack.Layer fill style={{ top: 0, left: 0, right: 0, bottom: 0 }}>
                  <Highlighter iFrameRef={iFrameRef} mode={mode} />
                </Stack.Layer>}
              </Stack>

              {/* DOCUMENT LOADING SPINNER */}
              <Iffy is={!cond.isDocumentLoaded}>
                <DNABox
                  alignX="center"
                  alignY="center"
                  style={[styles.loaderContainer, !isDarkMode && { backgroundColor: 'white' }]}
                >
                  <Image
                    testID="dna-loader"
                    style={styles.loaderImage}
                    source={!isDarkMode
                      ? require('../../../assets/images/LoaderDNA.gif')
                      : require('../../../assets/images/LoaderDNA_dark.gif')}
                  />

                </DNABox>
              </Iffy>
            </Iffy>
          </DNAAspectRatio>
        </DNABox>
      </Iffy>
    </DNABox>
  )
}

const PlayerWrapperHighlighter: React.FC<IFrameWrapperProps> = (props) => {
  const {
    highlighterVisible,
    resetHighlighter,
  } = useHighlighter();

  const {
    activePresentation,
  } = useContent()

  useEffect(() => {
    const documentId = activePresentation?.currentPresentablePage.documentVersionORM?.model.id
    if (documentId) {
      resetHighlighter()
    }
  }, [activePresentation?.currentPresentablePage.documentVersionORM?.model.id])

  return (
    <IFrameWrapper {...props} highlighterVisible={highlighterVisible} returnToHome={resetHighlighter} />
  )
}

IFrameWrapper.Highlighter = PlayerWrapperHighlighter;

export default IFrameWrapper;
