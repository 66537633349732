import React, { useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Controller } from 'react-hook-form';
import {
  DNABox,
  DNAButton,
  DNAContextMenu,
  DNAText,
  Iffy,
  TextField,
} from '@alucio/lux-ui/src';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import TenantLogo from 'src/components/DNA/Header/TenantLogo';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import { useHubRHFromState } from 'src/screens/Hubs/EditHub/useHubRHFormStateProvider';
import { HubORM } from 'src/types/orms';

type HeaderProps = {
  hubORM: HubORM
}

const styles = StyleSheet.create({
  breadcrumbHeader: {
    height: 48,
    backgroundColor: colors['color-text-white'],
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
    paddingHorizontal: 24,
  },
  chevronIcon: {
    height: 18,
    color: colors['color-text-tertiary'],
    marginHorizontal: 15,
    top: 5,
  },
  hubTitleHeader: {
    height: 64,
    backgroundColor: colors['color-text-white'],
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
    paddingHorizontal: 24,
  },
  hubTitle: {
    marginHorizontal: 30,
  },
  editHubTitleInputBox: {
    width: 500,
  },
  contextMenuItems: {
    minWidth: 160,
    right: 66,
  },
});

const Header: React.FC<HeaderProps> = ({ hubORM }) => {
  const { handleCancelEdit, handleSaveHub, handleSaveNPreview, openPreviewWindow, handleDeletHub } = useHubsState()
  const { rhform, isSaving, setIsSaving } = useHubRHFromState()
  const [editNameMode, setEditNameMode] = useState<boolean>(false)
  const [hubName, setHubName] = useState<string>(hubORM.model.name || '')
  const [hubNameInput, setHubNameInput] = useState<string>(hubORM.model.name || '')
  const [previousHubName, setPreviousHubName] = useState<string>(hubORM.model.name || '')

  const {
    handleSubmit,
    setValue,
    control,
    formState: { isDirty, dirtyFields },
  } = rhform

  const cancelHubNameChange = () => {
    setEditNameMode(false)
    setHubNameInput(previousHubName)
  }

  const onSubmitHubName = () => {
    if (!hubNameInput) {
      cancelHubNameChange()
    } else {
      setEditNameMode(false)
      setPreviousHubName(hubNameInput)
      setHubName(hubNameInput)
      setValue('name', hubNameInput, { shouldDirty: true })
    }
  }

  const cancelEditHub = useMemo(() => () => {
    handleCancelEdit(!!Object.keys(dirtyFields).length && isDirty)
  }, [rhform, isDirty, dirtyFields])

  const onSaveNPreview = (data: any) => {
    setIsSaving(true)
    setTimeout(() => {
      handleSaveNPreview(data)
      setIsSaving(false)
    }, 1000)
  }

  const onPreview = () => openPreviewWindow(hubORM.model.id)

  const handleSaveWithDelay = (data: any) => {
    setIsSaving(true)
    setTimeout(() => {
      handleSaveHub(data)
      setIsSaving(false)
    }, 500)
  };

  return (
    <DNABox childFill={1} style={ styles.hubTitleHeader }>
      {/* Tenant LOGO */}
      <TenantLogo disableNav={true} />
      {/* Hub Title */}
      <DNABox childFill={1} fill alignY="center" alignX="center" spacing="sm" style={ styles.hubTitle }>
        {/* Edit Hub Name Mode - Input & Buttons */}
        <DNABox
          fill
          alignY="center"
          alignX="center"
          spacing="sm"
          style={ !editNameMode && { display: 'none' }}
        >
          <Controller
            name="name"
            title="NAME"
            control={control}
            hideLabel
            required
            characterLimit={150}
            render={() => (
              <TextField.Kitten
                style={ styles.editHubTitleInputBox }
                value={hubNameInput}
                maxLength={150}
                onChangeText={setHubNameInput}
                onKeyPress={e => {
                  if (e.nativeEvent.key === 'Enter') onSubmitHubName()
                }}
              />
            )}
          />
          <DNAButton
            appearance="outline"
            status="tertiary"
            padding="sm"
            onPress={() => cancelHubNameChange()}
            children="Cancel"
          />
          <DNAButton
            padding="sm"
            onPress={() => onSubmitHubName()}
            children="Done"
          />
        </DNABox>
        {/* Hub Name Read Only Mode - Text & Edit Button */}
        <Iffy is={!editNameMode}>
          <DNABox alignY="center" alignX="center">
            <DNAText
              h4
              numberOfLines={1}
            >
              {hubName}
            </DNAText>
            <DNAButton
              appearance="ghostLink"
              status="gray"
              iconLeft="pencil"
              padding="sm"
              size="md"
              onPress={() => setEditNameMode(true)}
            />
          </DNABox>
        </Iffy>
      </DNABox>
      {/* Action Buttons */}
      <Iffy is={!editNameMode}>
        <DNABox alignX="end" alignY="center" spacing="lg">
          <DNABox alignX="end" alignY="center" spacing="sm">
            <DNAContextMenu style={ styles.contextMenuItems }>
              <DNAContextMenu.Anchor>
                <DNAButton
                  iconRight="dots-vertical"
                  appearance="outline"
                  status="tertiary"
                  padding="sm"
                />
              </DNAContextMenu.Anchor>
              <DNAContextMenu.Items>
                <DNAContextMenu.Item
                  collapseOnPress
                  delay={100} // Workaround because sometimes the item deletes too fast before the ContextMenu can hide itself
                  title="Delete"
                  status="danger"
                  iconStatus="danger"
                  icon="trash-can-outline"
                  onPress={() => handleDeletHub(hubORM.model, true)}
                />
              </DNAContextMenu.Items>
            </DNAContextMenu>
            <DNAButton
              appearance="outline"
              status="tertiary"
              padding="sm"
              onPress={handleSubmit(onSaveNPreview, onPreview)}
              disabled={isSaving}
            >
              Preview
            </DNAButton>
          </DNABox>
          <DNABox alignX="end" alignY="center" spacing="sm">
            <DNAButton
              appearance="outline"
              status="tertiary"
              padding="sm"
              onPress={cancelEditHub}
              disabled={isSaving}
            >
              Cancel
            </DNAButton>
            <DNAButton
              padding="sm"
              onPress={handleSubmit(handleSaveWithDelay)}
              disabled={isSaving}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </DNAButton>
          </DNABox>
        </DNABox>
      </Iffy>
    </DNABox>
  )
}

export default Header
