import {
  AttendeeType,
  ContentPresented,
  ControlType,
  CrmSyncStatus,
  CustomFieldDefinition,
  CustomFieldUsage,
  CustomFieldValueDefinition,
  CustomValues,
  FieldDataType,
  FieldStatus,
} from '@alucio/aws-beacon-amplify/src/models';
import { CustomFieldValuesMap, MeetingORM } from 'src/types/orms';
import map from 'lodash/map';
import { FormTranslatorResponse, ICRMFormTranslator } from '../CRMHandler';
import {
  CRMSubmitMeetingPayload,
  FormSettings,
  LayoutComponent,
  LayoutItem,
  LayoutSection,
  RecordToDelete,
  SALESFORCE_FIELD_DATA_TYPE,
  SalesforceFirstSubmitPayload,
  SalesforceFirstSubmitRecord,
  SalesforceFormSettings,
  SalesforceRecordToUpsert,
  SalesforceUpdateSubmit,
  SFDefaultField,
  SFDefaultRecord,
} from '../CRMIndexedDBTypes';
import { isArrayOfObjects, ObjectWithId } from 'src/components/CustomFields/ComposableFormUtilities';
import { Logger } from '@aws-amplify/core';
import { FormValuesType } from 'src/components/CustomFields/ComposableForm';
import { AttendeeForm } from 'src/state/context/Meetings/saveMeetingHelper';
import { MEETING_CONTENT_TYPE } from '@alucio/aws-beacon-amplify/lib/API';
import { CustomDeckORMMap } from 'src/state/redux/selector/folder';
const logger = new Logger('SalesforceTranslator', 'DEBUG');

export class SalesforceFormTranslator implements ICRMFormTranslator {
  protected formSettings: SalesforceFormSettings[];
  protected mainTable: string;

  private DATA_TYPE_FIELDS = {
    [SALESFORCE_FIELD_DATA_TYPE.String]: {
      fieldType: FieldDataType.STRING,
      controlType: ControlType.INPUT,
    },
    [SALESFORCE_FIELD_DATA_TYPE.TextArea]: {
      fieldType: FieldDataType.STRING,
      controlType: ControlType.TEXTAREA,
    },
    [SALESFORCE_FIELD_DATA_TYPE.Boolean]: {
      fieldType: FieldDataType.CATEGORICAL,
      controlType: ControlType.CHECKBOX,
      fieldValueDefinitions: [
        {
          ...this.getPartialCustomFieldValueDefinitions(),
          id: 'true',
          value: 'YES',
        },
        {
          ...this.getPartialCustomFieldValueDefinitions(),
          id: 'false',
          value: 'NO',
        },
      ],
    },
    [SALESFORCE_FIELD_DATA_TYPE.DateTime]: {
      fieldType: FieldDataType.DATE,
      controlType: ControlType.DATEPICKER,
    },
    [SALESFORCE_FIELD_DATA_TYPE.Reference]: {
      fieldType: FieldDataType.CATEGORICAL,
      controlType: ControlType.SELECT,
    },
    [SALESFORCE_FIELD_DATA_TYPE.Picklist]: {
      fieldType: FieldDataType.CATEGORICAL,
      controlType: ControlType.SELECT,
    },
    [SALESFORCE_FIELD_DATA_TYPE.MultiPicklist]: {
      fieldType: FieldDataType.MULTICATEGORICAL,
      controlType: ControlType.SELECT,
    },
    CUSTOM_FIELD_OBJECT: {
      fieldType: FieldDataType.OBJECT,
      controlType: ControlType.OBJECT,
      status: FieldStatus.ENABLED,
      usage: CustomFieldUsage.MEETING,
      fieldValueDefinitions: [],
      createdAt: new Date().toISOString(),
    },
  };

  constructor(formSettings: SalesforceFormSettings[], mainTable: string) {
    if (!mainTable) {
      throw Error('Invalid mainTable configuration.');
    }
    this.formSettings = formSettings;
    this.mainTable = mainTable;
  }

  //* * SALESFORCE CONFIG TO BEACON **//
  getFormCustomFieldConfig(): FormTranslatorResponse {
    const customFields: CustomFieldDefinition[] = [];
    const formSettingMap = this.formSettings.reduce((acc, formSetting) => {
      acc[formSetting.objectInfos.apiName] = formSetting;
      return acc;
    }, {} as Record<string, SalesforceFormSettings>);

    const formSetting = formSettingMap[this.mainTable];

    // when the user logout from the crm integration an enter again this value can be empty
    // TODO: validate why this is called when the user logout from the crm integration and then open the form
    if (!formSetting) return { crmCustomFields: [], crmDefaultValues: {} };

    const { layout } = formSetting;
    if (!layout) throw Error('Invalid layout configuration.');

    layout.sections.forEach((section) => {
      customFields.push(...this.processSection(section, formSetting));
    });

    const childrenCustomFields = this.processChildren(this.mainTable);

    // push children custom fields avoiding duplicates
    childrenCustomFields.forEach((childCustomField) => {
      const found = customFields.find(
        (customField) => customField.id === childCustomField.id,
      );
      if (!found) customFields.push(childCustomField);
    });

    const crmDefaultValues = this.getDefaultValues(formSetting, customFields);

    return {
      crmCustomFields: customFields,
      crmDefaultValues,
    };
  }

  protected processSection(
    section: LayoutSection,
    formSettings: SalesforceFormSettings,
  ): CustomFieldDefinition[] {
    const customFields: CustomFieldDefinition[] = [];
    section.layoutRows.forEach((row) => {
      row.layoutItems.forEach((item) => {
        customFields.push(...this.processItem(item, formSettings));
      });
    });

    return customFields;
  }

  protected processItem(
    item: LayoutItem,
    formSettings: SalesforceFormSettings,
  ): CustomFieldDefinition[] {
    if (!item.editableForNew || !item.editableForUpdate) return [];

    const { layoutComponents } = item;
    const { objectInfos: objectInfo } = formSettings;

    const customFields: CustomFieldDefinition[] = [];

    for (let i = 0; i < layoutComponents.length; i++) {
      const component = layoutComponents[i];
      if (component.componentType !== 'Field') continue;

      const info = objectInfo.fields[component.apiName];

      // AVOID RENDERING THE TEXT FIELD WHERE THE FILE NAMES WILL BE STORED
      if (!info || info.apiName === formSettings.presentationsFieldName) continue;

      const { dataType } = info;

      // lookup
      if (
        item.lookupIdApiName &&
        info.reference &&
        dataType === SALESFORCE_FIELD_DATA_TYPE.Reference
      ) {
        const customField = this.handleLookupItem(component, formSettings);
        if (customField) customFields.push(customField);
        continue;
      }

      if (
        dataType === SALESFORCE_FIELD_DATA_TYPE.Picklist ||
        dataType === SALESFORCE_FIELD_DATA_TYPE.MultiPicklist
      ) {
        const customField = this.handlePicklistItem(component, formSettings);
        if (customField) customFields.push(customField);
        continue;
      }

      const customFieldMapValue = this.DATA_TYPE_FIELDS[dataType];

      if (!customFieldMapValue) continue;
      customFields.push({
        ...this.getPartialCustomField(component),
        ...this.DATA_TYPE_FIELDS[dataType],
        required: info.required || item.required,
        description: info.inlineHelpText,
        maxLength: info.length,
      });
    }

    return customFields;
  }

  protected handlePicklistItem(
    item: LayoutComponent,
    formSettings: SalesforceFormSettings,
  ): CustomFieldDefinition | void {
    const { apiName } = item;
    const { objectInfos: objectInfo } = formSettings;

    const info = objectInfo.fields[apiName];

    const { dataType } = info;

    const picklist = formSettings.picklists[info.apiName];

    if (!picklist) return undefined;

    const { values, controllerValues } = picklist;

    const controllerValuesMap = Object.entries(controllerValues).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [value]: key,
      }),
      {},
    );

    const CustomFieldValueDefinitions: CustomFieldValueDefinition[] =
      values.map((value) => {
        const dependentValueIds = value.validFor?.map(
          (id) => controllerValuesMap[id],
        );
        return {
          ...this.getPartialCustomFieldValueDefinitions(),
          id: value.value,
          value: value.value,
          label: value.label,
          dependentValueIds,
        };
      });

    let dependentFieldId: string | undefined;

    if (info.controllerName) {
      // CHILD FIELDS ARE PREFIXED WITH ITS PARENT'S NAME TO AVOID DUPLICATED FIELD NAMES
      dependentFieldId = formSettings.isMainTable
        ? info.controllerName : `${formSettings.objectInfos.apiName}_${info.controllerName}`
    }

    return {
      dependentFieldId,
      ...this.getPartialCustomField(item),
      ...this.DATA_TYPE_FIELDS[dataType],
      required: info.required,
      fieldValueDefinitions: CustomFieldValueDefinitions,
      description: info.inlineHelpText,
    };
  }

  protected handleLookupItem(
    item: LayoutComponent,
    formSettings: SalesforceFormSettings,
  ): CustomFieldDefinition | void {
    const { apiName } = item;
    const { objectInfos: objectInfo } = formSettings;

    const info = objectInfo.fields[apiName];

    const { dataType } = info;

    const altLookupName =
      objectInfo.fields[info.apiName]?.referenceToInfos?.[0]?.apiName;

    const lookup =
      formSettings.lookups[info.apiName] || formSettings.lookups[altLookupName];

    if (!lookup) return undefined;
    const { records } = lookup;

    const CustomFieldValueDefinitions = records.map((record) => {
      const { Id, Name } = record.fields;
      return {
        ...this.getPartialCustomFieldValueDefinitions(),
        id: Id,
        value: Id,
        label: Name,
      };
    });

    const customFieldDefinition: CustomFieldDefinition = {
      ...this.getPartialCustomField(item),
      ...this.DATA_TYPE_FIELDS[dataType],
      required: info.required,
      fieldValueDefinitions: CustomFieldValueDefinitions,
      description: info.inlineHelpText,
    };

    return customFieldDefinition;
  }

  private getDefaultValues(
    formSetting: SalesforceFormSettings,
    customFields: CustomFieldDefinition[],
  ): CustomFieldValuesMap {
    const defaultValues: CustomFieldValuesMap = {};
    const defaultRecordFields: SFDefaultRecord['fields'] =
      formSetting.defaultRecord.fields;
    customFields.forEach((customField) => {
      if (
        defaultRecordFields[customField.id] &&
        defaultRecordFields[customField.id]?.value !== null
      ) {
        const value = this.getValueFromDefaultRecord(
          defaultRecordFields[customField.id],
        );
        if (value !== undefined) {
          defaultValues[customField.id] = {
            field: customField,
            displayValues: [
              defaultRecordFields[customField.id].displayValue || value,
            ],
            values: [value],
          };
        }
      }
    });
    return defaultValues;
  }

  private getValueFromDefaultRecord(
    defaultRecord: SFDefaultField,
  ): string | undefined {
    if (typeof defaultRecord.value !== 'object') {
      // IN BEACON'S FORM, WE HANDLE THE BOOLEANS AS STRING VALUES (FOR CHECKBOXES WITH DIFFERENT VALUES)
      const isBoolean = typeof defaultRecord.value === 'boolean';
      return isBoolean ? defaultRecord.value.toString() : defaultRecord.value;
    } else if (defaultRecord.value === undefined) {
      return undefined;
    }
    return this.getValueFromDefaultRecord(defaultRecord.value.fields.Id);
  }

  private getPartialCustomField(
    component: LayoutComponent,
  ): Omit<CustomFieldDefinition, 'fieldType' | 'controlType'> {
    return {
      id: component.apiName,
      fieldLabel: component.label,
      createdAt: new Date().toDateString(),
      fieldName: component.apiName,
      fieldValueDefinitions: [],
      status: FieldStatus.ENABLED,
      usage: CustomFieldUsage.MEETING,
    };
  }

  private getPartialCustomFieldValueDefinitions(): Omit<
    CustomFieldValueDefinition,
    'id' | 'value'
    > {
    return {
      createdAt: new Date().toDateString(),
      dependentValueIds: [],
      label: '',
    };
  }

  private processChildren(mainTable: string): CustomFieldDefinition[] {
    const customFields: CustomFieldDefinition[] = [];
    this.formSettings
      .filter((formSetting) => formSetting.objectInfos.apiName !== mainTable)
      .forEach((formSetting) => {
        const customFieldObjects: CustomFieldDefinition[] = this.processChild(formSetting);

        customFields.push(...customFieldObjects);
      });

    return customFields;
  }

  protected processChild(formSetting: SalesforceFormSettings) {
    const { layout, objectInfos } = formSetting;
    const customFields: CustomFieldDefinition[] = [];
    if (!layout)
    { throw Error('Invalid layout configuration.'); }

    const childrenFieldIds = layout.sections
      .map((section) => {
        const childCustomField = this.processSection(
          section,
          formSetting,
        ).map((customField) => ({
          ...customField,
          // NOTE: THERE MIGHT BE SCENARIOS WHERE A CHILD FIELD COULD HAVE THE SAME API NAME (ID)
          // THAN A REGULAR FIELD. TO AVOID CONFUSIONS, CHILDREN FIELDS' IDS WILL BE THE
          // CONCATENATION OF THE "PARENTID" + "_" + "CHILDFIELDID"
          id: `${formSetting.objectInfos.apiName}_${customField.id}`,
          isChildField: true,
        }));
        customFields.push(...childCustomField);

        return childCustomField.map((customField) => customField.id);
      })
      .flat();

    const customFieldObject: CustomFieldDefinition = {
      ...this.DATA_TYPE_FIELDS.CUSTOM_FIELD_OBJECT,
      id: formSetting.objectInfos.apiName,
      fieldLabel: formSetting.objectInfos.label,
      objectSetting: {
        childrenFieldIds,
        restriction: {
          syncDelete: !objectInfos.deletable,
          syncUpdate: !objectInfos.updateable,
        },
      },
      createdAt: new Date().toISOString(),
      fieldName: formSetting.objectInfos.apiName,
    };

    customFields.push(customFieldObject);

    return customFields;
  }
}

// ** FIRST SUBMIT FUNCTIONS ** //
// THE RETURN OF THIS FUNCTION IS BASED ON THE FIRST SUBMIT OF A MEETING WHERE
// THE COMPOSITE TREE ENDPOINT IS GOING TO BE CALLED. THIS ENDPOINT RECEIVES ALL
// THE RECORDS THE CREATE THEM ALL IN ONE SINGLE CALL
export function getSalesforceFirstSubmitPayload(
  crmSubmitPayload: CRMSubmitMeetingPayload): SalesforceFirstSubmitPayload {
  logger.debug('Getting payload for first Salesforce submit.', crmSubmitPayload);
  const { formSettings, mainCrmValues } = crmSubmitPayload;
  const mainRecordSetting = formSettings.find((record) => record.isMainTable);

  if (!mainRecordSetting) {
    throw Error('Settings for main table not found.');
  }

  const payload = getFirstSubmitRecordPayload(mainRecordSetting.id, 'main-record',
    mainCrmValues, mainRecordSetting, crmSubmitPayload);

  return {
    records: [payload],
  }
}

// BASED ON THE PRESENTED CONTENT, RETURNS A STRING WITH THE FILE NAMES
function getConcatenatedPresentationsNames(indexedCustomDecks: CustomDeckORMMap,
  contentPresented?: ContentPresented[]): string {
  return [...(contentPresented?.reduce((acc, content) => {
    if (content.contentType === MEETING_CONTENT_TYPE.CUSTOM_DECK) {
      const customDeck = indexedCustomDecks[content.contentId];
      if (customDeck) {
        customDeck.meta.customDeckGroups.forEach((group) => {
          group.pages.forEach((page) => {
            page.documentVersionORM.model.title && acc.add(page.documentVersionORM.model.title);
          });
        });
      }
    } else {
      acc.add(content.title);
    }

    return acc;
  }, new Set<string>())) || []].sort().join('\r\n');
}

// GIVEN A CRMRECORD (FROM THE FORM VALUES), TRANSLATE IT INTO SUBMIT TO CRM PAYLOAD
export function getFirstSubmitRecordPayload(
  apiName: string,
  referenceId: string,
  crmValues: FormValuesType = {},
  mainRecordSetting: FormSettings,
  crmSubmitPayload?: CRMSubmitMeetingPayload): SalesforceFirstSubmitRecord {
  const isChildObject = apiName !== mainRecordSetting.id;
  const record: SalesforceFirstSubmitRecord = {
    attributes: {
      type: apiName,
      referenceId,
    },
  };

  // ADDS A KEY/VALUE OF THE PRESENTED FILES IF INDICATED ON THE CONFIG
  if (!isChildObject && crmSubmitPayload && mainRecordSetting.presentationsFieldName) {
    record[mainRecordSetting.presentationsFieldName] =
      getConcatenatedPresentationsNames(crmSubmitPayload.indexedCustomDecks, crmSubmitPayload.contentPresented);
  }

  // TO THE PAYLOAD RECORD OBJECT, ADDS THE VALUES FROM THE FORM
  Object.entries(crmValues).forEach(([crmKey, value]) => {
    // IF TRUE, IT'S A CHILD RECORD
    if (Array.isArray(value) && isArrayOfObjects(value) && value.length) {
      const relationship = mainRecordSetting.objectInfos.childRelationships
        .find((relationship) => relationship.childObjectApiName === crmKey);

      if (!relationship) {
        throw Error(`Relationship not found for ${crmKey}`);
      }

      const records: SalesforceFirstSubmitRecord[] = [];

      // EACH OBJECT (CHILD RECORD) WILL BE TRANSLATED INTO A SUBMITPAYLOAD
      value.forEach((val) => {
        if (typeof val === 'object') {
          const { id, status, externalId, ...rest } = val;
          records.push(getFirstSubmitRecordPayload(
            crmKey,
            id,
            rest,
            mainRecordSetting,
            crmSubmitPayload,
          ));
        }
      });

      record[relationship.relationshipName] = { records };
    } else {
      let fieldKey = crmKey;
      // IF IT'S A FIELD FROM A CHILD OBJECT, ON BEACON, WE STORE A CONCATENATION OF `${childTableName}_${fieldName}`
      // (THIS IS TO AVOID ISSUES ON THE FORM WITH DUPLICATED FIELD NAMES)
      if (isChildObject) {
        fieldKey = crmKey.replace(`${apiName}_`, '');
      }

      record[fieldKey] = Array.isArray(value) ? value.join(';') : value;
    }
  });

  return record;
}

// ** UPDATE SUBMIT FUNCTIONS ** //
// BASED ON THE CRM RECORDS, PREPARES THEM TO BE UPDATED/CREATED
export function getSalesforceUpdateSubmitPayload(crmSubmitPayload: CRMSubmitMeetingPayload): SalesforceUpdateSubmit {
  logger.debug('Getting payload for update Salesforce submit.', crmSubmitPayload);
  const { mainCrmValues, formSettings, mainCrmRecordId } = crmSubmitPayload;
  const mainRecordSetting = formSettings.find((record) => record.isMainTable);

  if (!mainRecordSetting) {
    throw Error('Settings for main table not found.');
  }

  const payloads: SalesforceUpdateSubmit = {
    recordsToUpsert: [],
    recordsToInsert: {
      records: [],
    },
    recordsToDelete: getRecordIdsToDelete(
      crmSubmitPayload.meetingORM,
      [mainCrmValues],
    ),
  };

  const updates = getUpsertRecords(
    mainCrmValues,
    formSettings,
    mainCrmRecordId!,
    mainRecordSetting,
    crmSubmitPayload,
  );

  payloads.recordsToUpsert.push(...updates);
  return payloads;
}

// RECEIVES FORM VALUES AND DETERMINES WHICH NEEDS TO BE
// CREATED AND WHICH NEEDS TO BE UPDATED
export function getUpsertRecords(
  crmValues: FormValuesType,
  formSettings: FormSettings[],
  mainCrmRecordId: string,
  mainRecordSetting: FormSettings,
  crmSubmitPayload?: CRMSubmitMeetingPayload): SalesforceRecordToUpsert[] {
  const upsertRecords: SalesforceRecordToUpsert[] = [];

  const mainRecord: SalesforceRecordToUpsert = {
    apiName: mainRecordSetting.id,
    beaconId: 'main-record',
    salesforceId: mainCrmRecordId,
    fields: {},
  };

  // ADDS A KEY/VALUE OF THE PRESENTED FILES IF INDICATED ON THE CONFIG
  if (crmSubmitPayload && mainRecordSetting.presentationsFieldName) {
    mainRecord.fields[mainRecordSetting.presentationsFieldName] =
      getConcatenatedPresentationsNames(crmSubmitPayload.indexedCustomDecks, crmSubmitPayload.contentPresented);
  }

  // ITERATES OVER THE MAIN OBJECT'S FIELDS
  Object.entries(crmValues).forEach(([crmFieldKey, value]) => {
    if (Array.isArray(value) && isArrayOfObjects(value) && value.length) {
      const formSetting = formSettings.find((setting) => setting.id === crmFieldKey);
      if (!formSetting) {
        throw new Error(`Form Setting not found for ${crmFieldKey}`);
      } else if (!formSetting.relationshipWithParentApiName) {
        throw new Error(`Relationship name with parent not found for ${crmFieldKey}`);
      }
      logger.debug(`Translating objects for ${crmFieldKey}`);

      // PER CHILD OBJECT FIELD, FORMAT IT AND VERIFY IT NEEDS TO BE CREATED/UPDATED
      value.forEach((childObject) => {
        const formattedObject = getChildObjectUpsert(
          childObject,
          formSetting,
          formSetting.relationshipWithParentApiName || '',
          mainCrmRecordId || '');

        upsertRecords.push(formattedObject);
      });
    } else {
      mainRecord.fields[crmFieldKey] = processUpsertFieldValue(value, mainRecordSetting, crmFieldKey);
    }
  });

  upsertRecords.push(mainRecord);
  return upsertRecords;
}

// CHECKS THE OBJECTS OF THE FORM VALUES, COMPARES THEM WITH THE
// OLD OBJECTS FROM THE MEETING, AND GETS THE ID OF THE REMOVED ONES
export function getRecordIdsToDelete(meetingORM: MeetingORM, crmObjects?: FormValuesType[],
  remainingAttendees?: AttendeeForm[]): RecordToDelete[] {
  const recordsToDelete: RecordToDelete[] = [];
  // GETS THE EXTERNAL IDS OF ALL THE CURRENT CHILD OBJECTS
  const validExternalIds: string[] | undefined = crmObjects?.reduce<string[]>((acc, crmObject) => {
    Object.values(crmObject).forEach((value) => {
      if (isArrayOfObjects(value)) {
        acc = [...acc, ...map(value as Object[], 'externalId')];
      }
    });

    return acc;
  }, []);

  function addDeletedIds(crmCustomValues?: CustomValues[]): void {
    crmCustomValues?.forEach((crmCustomValue) => {
      crmCustomValue.objectRecords?.forEach((objectRecord) => {
        if (objectRecord.externalId && !validExternalIds?.includes(objectRecord.externalId) &&
          objectRecord.syncStatus === CrmSyncStatus.SYNCED) {
          recordsToDelete.push({
            crmId: objectRecord.externalId,
            beaconId: objectRecord.id,
          });
        }
      });
    });
  }

  // ITERATES OVER THE OLD CHILD OBJECTS TO SEE IF ONE WAS REMOVED
  addDeletedIds(meetingORM.model.crmRecord?.crmCustomValues);
  meetingORM.model.attendees.forEach((attendee) => {
    addDeletedIds(attendee.crmRecord?.crmCustomValues);

    // CHECKS IF THE ATTENDEE WAS DELETED. IF SO, GET THEIR CRM RECORD ID
    if (attendee.attendeeType === AttendeeType.SECONDARY) {
      const isValidAttendee = remainingAttendees?.some((remainingAttendee) => remainingAttendee.id === attendee.id);
      if (!isValidAttendee && attendee.crmRecord?.crmCallId &&
        attendee.crmRecord.crmSyncStatus === CrmSyncStatus.SYNCED) {
        recordsToDelete.push({
          crmId: attendee.crmRecord.crmCallId,
          beaconId: attendee.id,
        });
      }
    }
  });

  return recordsToDelete;
}

function processUpsertFieldValue(
  value: string | string[] | ObjectWithId[],
  formSetting: FormSettings,
  fieldKey: string): string | boolean {
  if (Array.isArray(value)) {
    return value.join(';');
  }
  const isBoolean = formSetting.objectInfos.fields[fieldKey]
    ?.dataType === SALESFORCE_FIELD_DATA_TYPE.Boolean;
  return isBoolean ? value === 'true' : value;
}

// RECEIVES AN OBJECTWITHID TO TRANSLATE IT TO BE CREATED/UPDATES ON SF
export function getChildObjectUpsert(
  crmValues: ObjectWithId,
  formSetting: FormSettings,
  relationshipWithParentName: string,
  parentId: string): SalesforceRecordToUpsert {
  const { id, status, externalId, ...crmFieldValues } = crmValues;
  logger.debug('Translating object', crmValues);

  const record: SalesforceRecordToUpsert = {
    apiName: formSetting.id,
    beaconId: crmValues.id,
    salesforceId: crmValues.externalId || '',
    fields: {},
  };

  // ADDS THE RELATIONSHIP WITH ITS PARENT (CAN ONLY BE SENT ON CREATE)
  if (!crmValues.externalId) {
    record.fields[relationshipWithParentName] = parentId;
  }

  Object.entries(crmFieldValues).forEach(([crmFieldKey, value]) => {
    if (typeof value === 'object' && !Array.isArray(value)) {
      // AN OBJECT SHOULDN'T HAVE ANOTHER CHILD OBJECT
      logger.debug(`${formSetting.id} has a child object: ${crmFieldKey}`, value);
    } else {
      const fieldKey = crmFieldKey.replace(`${formSetting.id}_`, '');
      record.fields[fieldKey] = value ? processUpsertFieldValue(value, formSetting, fieldKey) : '';
    }
  });

  return record;
}
