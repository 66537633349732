import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNADivider, DNAText, Iffy } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import EditableText from 'src/components/EditableText/EditableText';
import { GroupDraftStatus, ReplaceGroupDraft } from './FindAndReplace';
import { Page } from '@alucio/aws-beacon-amplify/src/models';
import { getImageObjectURLFromCloudfront } from 'src/utils/loadCloudfrontAsset/common';
import GroupRow from './GroupRow';
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector';
import { LastUpdated } from '../PresentationEditor';

const styles = StyleSheet.create({
  content: {
    backgroundColor: colors['color-gray-10'],
    paddingVertical: 30,
    overflowY: 'scroll',
  },
  header: {
    backgroundColor: colors['color-text-white'],
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
    paddingVertical: 10,
  },
  mainContainer: {
    borderLeftColor: colors['color-gray-80'],
    borderLeftWidth: 2,
    minWidth: '60vw',
  },
});

interface Props {
  activeGroup?: string;
  customTitle: string;
  setCustomTitle: (title: string) => void;
  onAcceptDeletedGroup: (groupId: string) => void;
  onRemoveGroup: (groupId: string) => void;
  onUndoRemoval: (groupId: string) => void;
  onSelectGroup: (groupId: string) => void;
  replaceGroups: ReplaceGroupDraft[];
  groupRefs: {};
}

interface HeaderProps {
  customTitle: string;
  groups: ReplaceGroupDraft[];
  setCustomTitle: (title: string) => void;
}

const Header: React.FC<HeaderProps> = (props) => {
  let unresolvedItems = 0;

  props.groups.forEach(({ status }) => {
    if (![GroupDraftStatus.ACTIVE, GroupDraftStatus.REPLACED, GroupDraftStatus.GROUP_REMOVED].includes(status)) {
      unresolvedItems++;
    }
  });

  return (
    <DNABox style={styles.header} appearance="col" spacing="sm">
      <DNABox style={{ paddingHorizontal: 16 }}>
        <EditableText
          value={props.customTitle}
          removeMarginPadding={true}
          onChangeText={props.setCustomTitle}
        />
      </DNABox>
      <DNABox spacing="md" style={{ paddingHorizontal: 16, paddingBottom: 10 }}>
        <DNAText bold status="flatAlt">{props.groups.length} item(s)</DNAText>
        <DNAText status="flat">|</DNAText>
        <LastUpdated />
      </DNABox>
      <DNADivider />
      <DNABox style={{ paddingHorizontal: 16, paddingVertical: 10 }}>
        <Iffy is={unresolvedItems > 0}>
          <DNAText
            testID="need-resolve-text"
            bold
            status="warning"
          >
            {unresolvedItems} item(s) need to be resolved
          </DNAText>
        </Iffy>
        <Iffy is={unresolvedItems === 0}>
          <DNAText bold status="success">All items have been resolved</DNAText>
        </Iffy>
      </DNABox>
    </DNABox>
  );
};

const GroupsReplacements = (props: Props) => {
  const {
    activeGroup,
    onAcceptDeletedGroup,
    onRemoveGroup,
    onUndoRemoval,
    onSelectGroup,
    replaceGroups,
    groupRefs,
  } = props;

  const [slidesThumbnails, setSlideThumbnails] = useState<{ [key: string]: string }>({});
  const pagesThumbnails = useRef<{ [key: string]: string }>({});

  // WE FETCH THE THUMBNAILS AND SAVE THEM IN A REF TO AVOID REFETCHING THEM
  const fetchThumbs = async (): Promise<void> => {
    const thumbnails: { [key: string]: string } = {};
    await Promise.all(
      replaceGroups.map(async (group) => {
        await Promise.all(
          group.pages.concat(group.groupReplacement?.pages || []).map(async (page: Page) => {
            if (!page) {
              return;
            }

            const isReplacement = group.status === GroupDraftStatus.REPLACED && group.groupReplacement;
            const docVer = isReplacement
              ? group.groupReplacement?.documentVersionORM?.model
              : group.documentVersionORM?.model;

            if (pagesThumbnails.current[page.pageId]) {
              thumbnails[page.pageId] = pagesThumbnails.current[page.pageId];
            } else {
              thumbnails[page.pageId] = await getImageObjectURLFromCloudfront(
                detectArchivedFileKeyPath(docVer, page),
              );
              pagesThumbnails.current[page.pageId] = thumbnails[page.pageId];
            }
          }));
      }),
    );
    setSlideThumbnails(thumbnails);
  };

  useEffect(() => {
    fetchThumbs();
  }, [replaceGroups]);

  let currentIndexCount = 1;

  return (
    <DNABox fill appearance="col" style={styles.mainContainer}>
      {/* HEADER */}
      <Header
        setCustomTitle={props.setCustomTitle}
        customTitle={props.customTitle}
        groups={replaceGroups}
      />
      {/* CONTENT */}
      <DNABox style={styles.content} fill appearance="col" spacing="md">
        {replaceGroups.map((group) => {
          const groupComponent = (
            <GroupRow
              isActiveGroup={activeGroup === group.groupId}
              currentIndexCount={currentIndexCount}
              slidesThumbnails={slidesThumbnails}
              key={group.groupId}
              group={group}
              onRemoveGroup={onRemoveGroup}
              onAcceptDeletedGroup={onAcceptDeletedGroup}
              onSelectGroup={onSelectGroup}
              onUndoRemoval={onUndoRemoval}
              ref={groupRefs[group.groupId]}
            />
          );
          currentIndexCount += group.visible ? group.pages.length : 0;
          return groupComponent;
        })}
      </DNABox>
    </DNABox>
  );
};

GroupsReplacements.displayName = 'GroupsReplacements';

export default GroupsReplacements;
