// this state machine is intended to validate if the same user logged in aagain and if that is the case we should clean the data
import { createMachine, InterpreterFrom } from 'xstate';
import { Hub, Logger } from '@aws-amplify/core'
import { DataStore } from '@aws-amplify/datastore';
import type { HubCapsule } from '@aws-amplify/core'
import AuthUtil from '../../../components/Authenticator/services/authUtil';
import Observable from 'zen-observable';
import { assign } from '@xstate/immer';

const logger = new Logger('Authenticator', 'INFO');

export type ValidateUserMachine = typeof validateUserMachine
export type ValidateUserService = InterpreterFrom<ValidateUserMachine>

const observable = new Observable<{
    type: 'SIGN_IN',
    payload: {
       data: HubCapsule
    }
}>(subscriber => {
  const listener = async (data: HubCapsule) => {
    subscriber.next({ type: 'SIGN_IN', payload: { data } });
  }
  Hub.listen('auth', listener);
  return () => {
    Hub.remove('auth', listener);
  };
});

type Context = {
    error?: string;
}

type SIGN_IN = { type: 'SIGN_IN', payload: {data : HubCapsule} }

type Event = SIGN_IN

export const validateUserMachine = createMachine<Context, Event, any>({
  id: 'validateUserMachine',
  initial: 'idle',
  context: {
    error: undefined,
  },
  states: {
    idle: {
      invoke : {
        id: 'authListener',
        src: () => observable,
        onDone: {
          target: 'validateUser',
        },
      },
    },
    validateUser: {
      tags: ['validatingUser'],
      invoke: {
        id: 'validateUser',
        src: 'validateUser',
        onDone: {
          target: '#validateUserMachine.idle',
        },
        onError: {
          target: '#validateUserMachine.error',
        },
      },
    },
    done: {
      type: 'final',
    },
    error: {
      entry: ['onError'],
    },
  },
  on: {
    SIGN_IN: {
      target: 'validateUser',
    },
  },
}, {
  actions: {
    onError: assign((context, event) => {
      context.error = JSON.stringify(event.payload);
    }),
  },
  services: {
    validateUser: async (context, event) => {
      const { data } = event.payload;
      const authEvent = data.payload.event;

      if (authEvent === 'signIn') {
        // we cannot use the payload here, because for sso the email is not present
        const email = await AuthUtil.getCurrentUserEmail()
        await DataStore.stop()
        // open the database

        if (email !== AuthUtil.getPrevAuthUser()) {
          logger.info('new user logged in, cleaning prev user data info')
          try {
            await AuthUtil.clearDataStore();
            await AuthUtil.purgeCacheDB();
            await AuthUtil.cleanCRMData();
            await AuthUtil.clearOfflineAnalyticsDB();
            await AuthUtil.deleteDBs();
          }
          catch (e) {
            logger.error('error cleaning prev user data info', e)
          }
          AuthUtil.clearUserDetailLocalStorage()
          AuthUtil.clearPrevAuthUser()
          logger.info('new user logged in, cleaning prev user data info done')
        }
      }
    },
  },
});
