
import { useEffect, useState } from 'react';
import { useAppSettings } from 'src/state/context/AppSettings';
import { Logger } from '@aws-amplify/core'
const logger = new Logger('useRetryOnSSOError', 'INFO');

const navigation = window.location;

const retrySSOErrors = [
  'Invalid SAML response received: Already found an entry for username',
  'Error in SAML response processing: Already found an entry for username',
]

const useRetryOnErrosSSO = () => {
  const [error, setError] = useState<undefined | string>();
  const { initialUrlSearchParams } = useAppSettings()
  useEffect(() => {
    const url = localStorage.getItem('loginUrl')
    const ssoError = initialUrlSearchParams.get('error_description') ?? ''
    const errorOnFirstSign = retrySSOErrors.filter((error) => ssoError.startsWith(error))?.length > 0
    const persistedSSO = localStorage.getItem('ssoError');
    logger.debug(`entered useRetryOnErrorsSSO ${url} ${errorOnFirstSign} ${ssoError} ${persistedSSO}`)
    if (errorOnFirstSign && url) {
      logger.info('Detected first time SSO sign in error. Retrying sign-in.')
      navigation.href = url;
    }
    else if (ssoError) {
      logger.info(`Detected other SSO Error ${ssoError}`)
      localStorage.setItem('ssoError', JSON.stringify({
        ssoError: ssoError,
        // eslint-disable-next-line max-len
        error: 'We were unable to log you in using Single Sign-On. Please try again or contact your organization’s system administrator to resolve this issue.',
      }));
      navigation.href = navigation.origin
    }
    else if (persistedSSO) {
      const ssoError = { ...(JSON.parse(persistedSSO) as unknown as { ssoError: string, error: string }) };
      logger.info(`Detected persisted SSO Error: ${ssoError.ssoError}`);
      setError(ssoError.error);
      setTimeout(() => localStorage.removeItem('ssoError'), 1000); // if we dont do this for an unknown reason the messages never is shown
    }
    else setError(undefined)
  }, [navigation.search])

  const saveUrl = (url) => {
    localStorage.setItem('loginUrl', url);
  }

  const cleanSSoError = () => setError(undefined)

  return {
    saveUrl,
    cleanSSoError,
    error,
  }
}

export default useRetryOnErrosSSO
