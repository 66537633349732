// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ShareType = {
  "DOCUMENT_VERSION": "DOCUMENT_VERSION",
  "RECORDING": "RECORDING",
  "ATTACHED_FILE": "ATTACHED_FILE",
  "HUB": "HUB"
};

const PageGroupSource = {
  "USER": "USER",
  "DOCUMENT": "DOCUMENT"
};

const FolderStatus = {
  "ACTIVE": "ACTIVE",
  "ARCHIVED": "ARCHIVED",
  "REMOVED": "REMOVED"
};

const FolderItemType = {
  "FOLDER": "FOLDER",
  "DOCUMENT_VERSION": "DOCUMENT_VERSION",
  "CUSTOM_DECK": "CUSTOM_DECK"
};

const FolderItemStatus = {
  "ACTIVE": "ACTIVE",
  "REMOVED": "REMOVED"
};

const PermissionType = {
  "VIEW": "VIEW",
  "EDIT": "EDIT"
};

const ShareTargetType = {
  "USER": "USER",
  "FILTER": "FILTER",
  "ALL": "ALL"
};

const CrmSyncStatus = {
  "SYNCED": "SYNCED",
  "NOT_SYNCED": "NOT_SYNCED",
  "DELETED": "DELETED"
};

const ObjectRecordStatus = {
  "ACTIVE": "ACTIVE",
  "REMOVED": "REMOVED"
};

const ShareStatus = {
  "IS_SHARED": "IS_SHARED",
  "NOT_SHARED": "NOT_SHARED"
};

const DocumentAccessLevel = {
  "TENANT": "TENANT",
  "USER": "USER"
};

const DocumentStatus = {
  "ARCHIVED": "ARCHIVED",
  "REVOKED": "REVOKED",
  "NOT_PUBLISHED": "NOT_PUBLISHED",
  "PUBLISHED": "PUBLISHED",
  "DELETED": "DELETED",
  "ARCHIVED_WITH_VERSIONS": "ARCHIVED_WITH_VERSIONS",
  "REVOKED_WITH_VERSIONS": "REVOKED_WITH_VERSIONS",
  "DELETED_WITH_VERSIONS": "DELETED_WITH_VERSIONS"
};

const IntegrationType = {
  "VEEVA": "VEEVA",
  "BEACON": "BEACON",
  "NONE": "NONE"
};

const FileType = {
  "JPG": "JPG",
  "JPEG": "JPEG",
  "XLS": "XLS",
  "XLSX": "XLSX",
  "XLSM": "XLSM",
  "DOC": "DOC",
  "DOCX": "DOCX",
  "PDF": "PDF",
  "PPTX": "PPTX",
  "PNG": "PNG",
  "MP4": "MP4",
  "WEB": "WEB",
  "HTML": "HTML",
  "ZIP": "ZIP"
};

const ConversionStatus = {
  "PENDING": "PENDING",
  "PROCESSING": "PROCESSING",
  "PROCESSED": "PROCESSED",
  "ERROR": "ERROR"
};

const DocumentVersionChangeType = {
  "MAJOR": "MAJOR",
  "MINOR": "MINOR"
};

const NotificationScope = {
  "NONE": "NONE",
  "EMAIL": "EMAIL",
  "EMAIL_IMMEDIATE": "EMAIL_IMMEDIATE"
};

const AssociatedFileType = {
  "DOCUMENT": "DOCUMENT",
  "ATTACHED_FILE": "ATTACHED_FILE"
};

const AssociatedFileStatus = {
  "ACTIVE": "ACTIVE",
  "DELETED": "DELETED"
};

const ZendeskFeature = {
  "CHAT": "CHAT",
  "SSO": "SSO"
};

const FieldDataType = {
  "CATEGORICAL": "CATEGORICAL",
  "MULTICATEGORICAL": "MULTICATEGORICAL",
  "STRING": "STRING",
  "DATE": "DATE",
  "USER_LIST": "USER_LIST",
  "OBJECT": "OBJECT"
};

const TenantStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const FieldStatus = {
  "DISABLED": "DISABLED",
  "ENABLED": "ENABLED"
};

const FormatValidation = {
  "EMAIL": "EMAIL"
};

const ControlType = {
  "SELECT": "SELECT",
  "RADIOLIST": "RADIOLIST",
  "CHECKBOX": "CHECKBOX",
  "CHECKBOXLIST": "CHECKBOXLIST",
  "INPUT": "INPUT",
  "TEXTAREA": "TEXTAREA",
  "DATEPICKER": "DATEPICKER",
  "USERLIST": "USERLIST",
  "CUSTOM": "CUSTOM",
  "OBJECT": "OBJECT",
  "LABEL": "LABEL"
};

const FieldPosition = {
  "LEFT": "LEFT",
  "RIGHT": "RIGHT"
};

const CustomFieldUsage = {
  "USER_FILTER": "USER_FILTER",
  "MEETING": "MEETING",
  "DOCUMENT": "DOCUMENT",
  "EMAIL_TEMPLATE_FILTER": "EMAIL_TEMPLATE_FILTER",
  "CONTENT_SHARE": "CONTENT_SHARE",
  "HUB": "HUB",
  "TENANT_FORM": "TENANT_FORM"
};

const PermissionEffect = {
  "ALLOW": "ALLOW",
  "BLOCK": "BLOCK"
};

const TenantFormTargetType = {
  "API": "API"
};

const TenantFormStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const CrmIntegrationType = {
  "SALESFORCE": "SALESFORCE",
  "VEEVA": "VEEVA"
};

const AttachedFileEntityType = {
  "DOCUMENT_VERSION": "DOCUMENT_VERSION",
  "EMAIL_TEMPLATE": "EMAIL_TEMPLATE"
};

const UserStatus = {
  "ACTIVE": "ACTIVE",
  "DEACTIVATED": "DEACTIVATED"
};

const UserRole = {
  "ALUCIO_ADMIN": "AlucioAdmin",
  "TENANT_ADMIN": "TenantAdmin",
  "TENANT_PUBLISHER": "TenantPublisher",
  "TENANT_VIEWER": "TenantViewer",
  "TEAM_REPORT_VIEWER": "TeamReportViewer"
};

const InputEngineType = {
  "MULTICATEGORICAL": "MULTICATEGORICAL",
  "SINGLECATEGORICAL": "SINGLECATEGORICAL"
};

const EmailTemplateStatus = {
  "DRAFT": "DRAFT",
  "ACTIVE": "ACTIVE",
  "DEACTIVATED": "DEACTIVATED",
  "REMOVED": "REMOVED"
};

const PurposeType = {
  "INTERNAL_USE_ONLY": "Internal Use Only",
  "EXTERNAL_PROACTIVE": "External - Proactive",
  "EXTERNAL_REACTIVE": "External - Reactive",
  "NOT_DEFINED": "Not Defined"
};

const LogLevel = {
  "WARNING": "WARNING",
  "ERROR": "ERROR",
  "SUCCESS": "SUCCESS",
  "INFO": "INFO"
};

const IntegrationRunStatus = {
  "RUNNING": "RUNNING",
  "COMPLETED": "COMPLETED",
  "WARNING": "WARNING",
  "ERROR": "ERROR"
};

const ContentPresentedStatus = {
  "ACTIVE": "ACTIVE",
  "DELETED": "DELETED"
};

const MeetingContentType = {
  "CUSTOM_DECK": "CUSTOM_DECK",
  "DOCUMENT_VERSION": "DOCUMENT_VERSION",
  "DOCUMENT_VERSION_GROUP": "DOCUMENT_VERSION_GROUP"
};

const Sentiment = {
  "POSITIVE": "POSITIVE",
  "NEGATIVE": "NEGATIVE"
};

const MeetingType = {
  "IN_PERSON": "IN_PERSON",
  "VIRTUAL": "VIRTUAL",
  "MANUAL": "MANUAL"
};

const MeetingStatus = {
  "IN_PROGRESS": "IN_PROGRESS",
  "ENDED_EXIT": "ENDED_EXIT",
  "ENDED_TIMEOUT": "ENDED_TIMEOUT",
  "DELETED": "DELETED",
  "LOCKED": "LOCKED"
};

const MeetingAttendeeStatus = {
  "ACTIVE": "ACTIVE",
  "REMOVED": "REMOVED"
};

const AttendeeType = {
  "PRIMARY": "PRIMARY",
  "SECONDARY": "SECONDARY"
};

const HubStatus = {
  "ACTIVE": "ACTIVE",
  "DELETED": "DELETED"
};

const HubSectionType = {
  "TEXTAREA": "TEXTAREA",
  "SHARED_FILES": "SHARED_FILES",
  "TODO_LIST": "TODO_LIST"
};

const HubSharedFileContentType = {
  "DOCUMENT_VERSION": "DOCUMENT_VERSION"
};

const SharedFileStatus = {
  "ACTIVE": "ACTIVE",
  "DELETED": "DELETED"
};

const TodoStatus = {
  "ACTIVE": "ACTIVE",
  "COMPLETED": "COMPLETED",
  "DELETED": "DELETED"
};

const { Folder, CustomDeck, Document, DocumentVersion, Tenant, AttachedFile, ContentShare, User, EmailTemplate, IntegrationLog, IntegrationSettings, Meeting, Hub, ContentTokenOutput, PresentableContentMetadata, PageGroup, HubAndContentDetails, HubSection, TextareaHubSettings, SharedFileHubSetting, DocumentVersionSettings, HubSharedAssociatedFile, ToDo, ReportDashboard, ReportItem, ReportGroup, Sheet, GetSharedFoldersOutput, FolderItem, SharePermission, CustomValues, ObjectRecord, ObjectRecordValue, LabelValues, FolderPermission, GetDependenciesOutput, CustomDeckGroup, CustomDeckPage, EditMutex, DocumentIntegration, S3Object, PageSetting, LabelValue, SemVer, AssociatedFile, DocumentVersionIntegration, AccessTokenData, ZendeskTokenPayload, AuthInformationPayload, UserInfoPayload, RefreshTokenCRMPayload, KendraSearchOutput, KendraSearchResultItem, TextWithHighlights, Highlights, FieldConfig, ListConfiguration, ListConfigurationField, ListConfigurationSort, TenantConfig, TenantForm, CustomFieldDefinition, CommonFieldSettings, DocumentFieldSettings, CustomFieldValueDefinition, DocumentFieldValueSettings, DocumentPermission, ObjectSetting, ObjectRestriction, TenantFormTargetParameters, MeetingFieldDefinition, MeetingFieldValueDefinition, ListConfig, ListSort, FieldListConfig, CRMIntegration, CRMAccountSettings, CRMDisplaySettings, CRMAddressSettings, CRMTableSetting, CRMChildTableSetting, VeevaSetting, QuickFilter, BookmarkedDoc, SubmitIntegrationResult, Page, IntegrationClientConfigurationTuple, IntegrationValueMapping, IntegrationFieldMapping, IntegrationLogEntry, ContentPresented, PagePresentedEvent, PresentedMeta, FieldValue, Attendee, CRMMeetingRecordInfo } = initSchema(schema);

export {
  Folder,
  CustomDeck,
  Document,
  DocumentVersion,
  Tenant,
  AttachedFile,
  ContentShare,
  User,
  EmailTemplate,
  IntegrationLog,
  IntegrationSettings,
  Meeting,
  Hub,
  AssociatedFileType,
  PageGroupSource,
  FolderStatus,
  FolderItemType,
  FolderItemStatus,
  PermissionType,
  ShareTargetType,
  CrmSyncStatus,
  ObjectRecordStatus,
  ShareStatus,
  DocumentAccessLevel,
  DocumentStatus,
  IntegrationType,
  FileType,
  ConversionStatus,
  DocumentVersionChangeType,
  NotificationScope,
  AssociatedFileStatus,
  ZendeskFeature,
  FieldDataType,
  TenantStatus,
  FieldStatus,
  FormatValidation,
  ControlType,
  FieldPosition,
  CustomFieldUsage,
  PermissionEffect,
  TenantFormTargetType,
  TenantFormStatus,
  CrmIntegrationType,
  AttachedFileEntityType,
  ShareType,
  UserStatus,
  UserRole,
  InputEngineType,
  EmailTemplateStatus,
  PurposeType,
  LogLevel,
  IntegrationRunStatus,
  ContentPresentedStatus,
  MeetingContentType,
  Sentiment,
  MeetingType,
  MeetingStatus,
  MeetingAttendeeStatus,
  AttendeeType,
  HubStatus,
  HubSectionType,
  HubSharedFileContentType,
  SharedFileStatus,
  TodoStatus,
  ContentTokenOutput,
  PresentableContentMetadata,
  PageGroup,
  ReportDashboard,
  ReportItem,
  ReportGroup,
  Sheet,
  GetSharedFoldersOutput,
  FolderItem,
  SharePermission,
  CustomValues,
  ObjectRecord,
  ObjectRecordValue,
  LabelValues,
  FolderPermission,
  GetDependenciesOutput,
  CustomDeckGroup,
  CustomDeckPage,
  EditMutex,
  DocumentIntegration,
  S3Object,
  PageSetting,
  LabelValue,
  SemVer,
  AssociatedFile,
  DocumentVersionIntegration,
  AccessTokenData,
  ZendeskTokenPayload,
  AuthInformationPayload,
  UserInfoPayload,
  RefreshTokenCRMPayload,
  KendraSearchOutput,
  KendraSearchResultItem,
  TextWithHighlights,
  Highlights,
  FieldConfig,
  ListConfiguration,
  ListConfigurationField,
  ListConfigurationSort,
  TenantConfig,
  TenantForm,
  CustomFieldDefinition,
  CommonFieldSettings,
  DocumentFieldSettings,
  CustomFieldValueDefinition,
  DocumentFieldValueSettings,
  DocumentPermission,
  ObjectSetting,
  ObjectRestriction,
  TenantFormTargetParameters,
  MeetingFieldDefinition,
  MeetingFieldValueDefinition,
  ListConfig,
  ListSort,
  FieldListConfig,
  CRMIntegration,
  CRMAccountSettings,
  CRMDisplaySettings,
  CRMAddressSettings,
  CRMTableSetting,
  CRMChildTableSetting,
  VeevaSetting,
  QuickFilter,
  BookmarkedDoc,
  SubmitIntegrationResult,
  Page,
  IntegrationClientConfigurationTuple,
  IntegrationValueMapping,
  IntegrationFieldMapping,
  IntegrationLogEntry,
  ContentPresented,
  PagePresentedEvent,
  PresentedMeta,
  FieldValue,
  Attendee,
  CRMMeetingRecordInfo,
  HubSection,
  TextareaHubSettings,
  SharedFileHubSetting,
  DocumentVersionSettings,
  HubSharedAssociatedFile,
  HubAndContentDetails,
  ToDo,
};