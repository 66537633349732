import React from 'react'
import { useDispatch } from 'src/state/redux'
import { DNABox, DNAButton, DNAModal, DNAText, Iffy, luxColors, LuxStatus } from '@alucio/lux-ui'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'

interface ButtonProps {
  status: LuxStatus
  iconLeft?: string
  appearance?: 'outline' | 'ghost' | 'filled'

}

interface DNACommonConfirmation {
  descriptionText?: string
  onConfirmAction?: () => void
  cancelText?: string
  confirmActionText?: string
  status?: LuxStatus
  title?: string
  cancelButtonProps?: ButtonProps
  onCancelCallback?: () => void
}

const DNACommonConfirmation:React.FC<DNACommonConfirmation> = ({
  onConfirmAction, title, status = 'primary', descriptionText, cancelText, confirmActionText,
  onCancelCallback,
  cancelButtonProps = { status: 'tertiary' },
}) => {
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(DNAModalActions.closeModal())
    onCancelCallback?.()
  }

  const onAction = () => {
    onConfirmAction && onConfirmAction()
    dispatch(DNAModalActions.closeModal())
  }

  return (
    <DNAModal>
      <DNAModal.Header onClose={closeModal}>
        <DNABox style={{ padding: 8, minWidth: 384 }}>
          <DNAText h5>{title}</DNAText>
        </DNABox>
      </DNAModal.Header>
      { descriptionText &&
        <DNAModal.Body>
          <DNABox style={{ padding: 8, minWidth: 384 }}>
            <DNAText style={{ color: luxColors.flat.primary }}>{descriptionText}</DNAText>
          </DNABox>
        </DNAModal.Body>
      }
      <DNAModal.Confirmation bodyless={ !descriptionText }>
        <DNAButton testID="cancel-button" {...cancelButtonProps} onPress={closeModal} size="sm">
          <DNAText
            status={cancelButtonProps.status === 'primary' ? 'primary' : 'flat'}
          >{cancelText || 'Cancel'}</DNAText>
        </DNAButton>
        <Iffy is={onConfirmAction}>
          <DNAButton
            testID="confirmation-button"
            onPress={onAction}
            status={status}
            size="sm"
          >{confirmActionText}</DNAButton>
        </Iffy>
      </DNAModal.Confirmation>
    </DNAModal>
  )
}

export default DNACommonConfirmation
