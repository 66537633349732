import { CustomFieldUsage } from '@alucio/aws-beacon-amplify/src/models'
import { DNABox } from '@alucio/lux-ui'
import React from 'react'
import { ScrollView } from 'react-native'
import { ComposableField } from 'src/components/CustomFields/ComposableField'
import { STEP_STATE } from 'src/state/machines/sharing/shareTypes'
import { useTenantCustomFields } from 'src/state/redux/selector/tenant'
import { useDNAFileShareModalState } from '../state/context/DNAFileShareModalStateProvider'
import { ShareVariantOptions } from '../DNAFileShareModal'

export const SelectCustomFieldsBody: React.FC = () => {
  const { validateAndExecute, onNextStep, currentState, variant } = useDNAFileShareModalState()
  const variantConfig = {
    [ShareVariantOptions.DOC_VER_SHARE]: {
      customFieldUsage: CustomFieldUsage.CONTENT_SHARE,
    },
    [ShareVariantOptions.HUB_SHARE]: {
      customFieldUsage: CustomFieldUsage.HUB,
    },
  }

  const customFields = useTenantCustomFields({ usages: { internalUsages: [variantConfig[variant].customFieldUsage] } })
  const currentlyVisible: boolean = currentState === STEP_STATE.CUSTOM_FIELDS
  return (
    <ScrollView contentContainerStyle={{ flex: 1, margin: 16, display: currentlyVisible ? 'flex' : 'none' }}>
      <DNABox fill appearance="col" spacing="xs" testID="custom-field">
        {
            customFields.map(
              customField =>
                (<ComposableField
                  key={customField.id}
                  field={customField}
                  onSubmit={validateAndExecute(onNextStep)}
                />),
            )
          }
      </DNABox>
    </ScrollView >
  )
}
