import React from 'react';
import { DNABox, DNAButton, DNAText, Iffy } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import { StyleSheet } from 'react-native';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import { Tab, tabs } from '../PresentationControls';
import { MeetingType } from '@alucio/aws-beacon-amplify/src/models';

const styles = StyleSheet.create({
  closeIcon: {
    color: colors['color-text-white'],
  },
  container: {
    maxHeight: 56,
    backgroundColor: colors['color-dark-500'],
    paddingHorizontal: 24,
  },
  presentationNumber: {
    backgroundColor: colors['color-text-white'],
    borderRadius: 3,
    paddingHorizontal: 6,
    paddingVertical: 3,
  },
})

const Header = () => {
  const { presentations } = useContent();
  const {
    setCurrentTab,
    currentTab,
    togglePresentationControlsVisibility,
    meetingORM,
    endMeeting,
    checkFormDiscard,
    canEndMeeting,
  } = useMeetingsState();
  const isInPersonMeeting = meetingORM?.model.type === MeetingType.IN_PERSON;

  function onSelectTab(newTab: Tab): void {
    newTab !== currentTab && checkFormDiscard(() => { setCurrentTab(newTab) })
  }

  const handleTogglePanelVisibility = () => {
    checkFormDiscard(togglePresentationControlsVisibility)
  }

  return (
    <DNABox style={styles.container} alignY="center" fill spacing="between">
      {/* TABS */}
      <DNABox fill>
        {
          (Object.keys(tabs) as Tab[]).map((tabKey, idx) => {
            if (isInPersonMeeting && tabKey === 'PRESENTING') {
              return undefined;
            }
            const isSelected = currentTab === tabKey;
            const isPresenting = presentations.length > 0;
            return (
              <DNABox key={tabs[tabKey].testID + idx}>
                <DNAButton
                  onPress={() => onSelectTab(tabKey as Tab)}
                  appearance={isSelected ? 'ghost' : 'ghostLink'}
                  status="dark"
                  style={ isSelected && { backgroundColor: colors['color-gray-600'] }}
                  size="md"
                  padding="sm"
                  rounded="md"
                  testID={`${tabs[tabKey].testID}-button-tab`}
                >
                  <DNABox spacing="sm" alignY="center">
                    <DNAText status="basic">
                      {tabs[tabKey].title}
                    </DNAText>
                    <Iffy is={tabKey === 'OPENED_PRESENTATIONS'}>
                      <DNABox
                        style={[styles.presentationNumber,
                          isPresenting && { backgroundColor: colors['color-success-500'] }]}
                        alignX="center"
                        alignY="center"
                        testID="presentations-length-container"
                      >
                        <DNAText status={isPresenting ? 'basic' : 'dark'} testID="presentations-length">
                          {presentations.length}
                        </DNAText>
                      </DNABox>
                    </Iffy>
                  </DNABox>
                </DNAButton>
              </DNABox>
            );
          })
        }
      </DNABox>
      {/* CLOSE PANEL BUTTON */}
      <Iffy is={isInPersonMeeting}>
        <DNAButton
          style={{ paddingHorizontal: 0 }}
          // @ts-ignore
          iconStyle={styles.closeIcon}
          onPress={handleTogglePanelVisibility}
          iconLeft="close"
          appearance="ghostLink"
          status="dark"
          size="md"
        />
      </Iffy>
      {/* END MEETING BUTTON */}
      <Iffy is={!isInPersonMeeting}>
        <DNAButton
          size="xs"
          status="danger"
          onPress={endMeeting}
          disabled={!canEndMeeting}
        >
          <DNAText status="basic">End</DNAText>
        </DNAButton>
      </Iffy>
    </DNABox>
  );
};

Header.displayName = 'Header';

export default Header;
