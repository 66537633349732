import { saveAs } from 'file-saver';

import { FileType } from '@alucio/aws-beacon-amplify/src/models'
import { getImageFromCloudfront, getContentFromCloudfront, downloadRecordingFromCloudfront } from './common'

async function downloadContentFromCloudfront(
  key: string,
  fileName: string,
  fileType: FileType | keyof typeof FileType,
): Promise<void> {
  const content = await getContentFromCloudfront(key, { 'Alucio-Action': 'download' });
  let mimeType = 'application/octet-stream'
  if (fileType === FileType.PPTX) {
    mimeType = 'application/vnd.openxmlformats-officedocument.presentationml'
  } else if (fileType === FileType.PDF) {
    mimeType = 'application/pdf'
  } else if (fileType === FileType.PNG) {
    mimeType = 'image/png'
  } else if (fileType === FileType.MP4) {
    mimeType = 'video/mp4'
  }
  const fileBlob = new Blob([new Uint8Array(content)], { type: mimeType })
  saveAs(fileBlob, fileName);
}

export {
  getImageFromCloudfront,
  downloadContentFromCloudfront,
  downloadRecordingFromCloudfront,
}
