import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNADivider, DNAText } from '@alucio/lux-ui';
import format from 'date-fns/format'
import { DocumentStatus } from '@alucio/aws-beacon-amplify/src/models';
import { useContentPreviewModalState } from '../../State/ContentPreviewModalStateProvider';

const S = StyleSheet.create({
  notes: { maxWidth: 280 },
})

const VersionHistory: React.FC = () => {
  const { activeDocumentVersionORM } = useContentPreviewModalState()
  if (!activeDocumentVersionORM) return null
  const activeDoc = activeDocumentVersionORM.relations.documentORM
  const docVersions = activeDoc.relations.documentVersions

  const publishedVersionsEntries = docVersions
    .filter((ver) => ver.model.status === DocumentStatus.PUBLISHED)
    .map(docVer => {
      const header = `Version ${docVer.meta.version.semVerLabel}`
      const publishDate = `Published ${format(new Date(docVer.model.updatedAt || 0), 'MMMM dd, yyyy')}`
      const { releaseNotes, id } = docVer.model

      return (
        <DNABox
          key={id}
          appearance="col"
          spacing="md"
        >
          <DNAText bold>{header}</DNAText>
          <DNAText status="subtle">{publishDate}</DNAText>
          <DNAText style={S.notes}>{releaseNotes}</DNAText>
          <DNADivider />
        </DNABox>
      )
    })

  return (
    <DNABox appearance="col" spacing="md">
      {publishedVersionsEntries}
    </DNABox>
  )
}

export default VersionHistory;
