import React from 'react'
import {
  DNAButton,
} from '@alucio/lux-ui'
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings'
import { useDispatch } from 'src/state/redux';
import { DRAWER_ENTITIES, drawerActions } from 'src/state/redux/slice/drawer';
import { useTenantForms } from 'src/state/redux/selector/tenant';
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';

type DeviceModeButtonProps = Record<DeviceMode, DNAButtonProps>

const deviceModeButtonProps:DeviceModeButtonProps = {
  desktop: {
    appearance: 'outline',
    status: 'tertiary',
    size: 'lg',
    padding: 'sm',
  },
  tablet: {
    padding:'none',
    rounded:'full',
    appearance:'ghostLink',
    status:'dark',
    size:'xl',
  },
}

export const FormButton = () => {
  const dispatch = useDispatch();
  const tenantForms = useTenantForms();

  const onOpenForm = (): void => {
    analytics?.track('TENANT_FORM_ERROR', {
      category: 'TENANT_FORM',
      context: 'TENANT_FORM',
      formID: tenantForms[0].id,
    })
    dispatch(drawerActions.toggle({
      entity: DRAWER_ENTITIES.TENANT_FORM,
      entityId: tenantForms[0].id,
    }));
  };
  const { deviceMode } = useAppSettings()
  const currentDeviceModeButtonProps = deviceModeButtonProps[deviceMode]

  if (!tenantForms.length) return null;

  return (
    <DNAButton
      onPress={onOpenForm}
      testID="notification-bell"
      {...currentDeviceModeButtonProps}
    >
      Forms
    </DNAButton>
  )
}

export default FormButton
