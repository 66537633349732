import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { commonReducers, initialState, SliceStatus } from './common'
import { IntegrationSettings } from '@alucio/aws-beacon-amplify/src/models'
import { API, graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import { ListIntegrationSettingsQuery } from '@alucio/aws-beacon-amplify/lib/API';
import { listIntegrationSettings } from '@alucio/awshelpers/lib/queries'

const sliceName = 'integrationSetting'
const { reducers, extraReducers, asyncActions } = commonReducers<IntegrationSettings>(sliceName)

const getIntegrationsAction = createAsyncThunk(
  'integrationSettings/getIntegrationSettings',
  async (tenantId: string) => {
    const { data } = await API.graphql(graphqlOperation(listIntegrationSettings, {
      filter: { tenantId: { eq: tenantId } },
    })) as GraphQLResult<ListIntegrationSettingsQuery>;
    return data?.listIntegrationSettings || [];
  },
)

const integrationSettingsSlice = createSlice({
  name: sliceName,
  initialState: initialState<IntegrationSettings>(),
  reducers: {
    ...reducers,
    disable: {
      prepare: (integrationSettings: IntegrationSettings) => {
        return {
          payload: {
            model: IntegrationSettings,
            entity: integrationSettings,
            updates: { enabled: false },
          },
        }
      },
      reducer: reducers.save,
    },
  },
  extraReducers: {
    [getIntegrationsAction.fulfilled.toString()]: (state, resp) => {
      state.status = SliceStatus.OK;
      state.hydrated = true;
      state.records = resp.payload.items;
    },
    [getIntegrationsAction.rejected.toString()]: (state) => {
      state.status = SliceStatus.ERROR;
    },
    ...extraReducers,
  },
})

export default integrationSettingsSlice
export const integrationSettingsActions = {
  ...integrationSettingsSlice.actions,
  ...asyncActions,
  getIntegrationsAction,
}
