import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'
import {
  DNAText,
  DNABox,
  DNAChip,
  DNAButton,
  Iffy,
  createPortal,
} from '@alucio/lux-ui'
import { DNABoxProps } from '@alucio/lux-ui/lib/components/layout/DNABox/DNABox'
import { DragOverlay } from '@dnd-kit/core'
import { snapCenterToCursor } from '@dnd-kit/modifiers'
import { POOL_CONTAINER_ID } from 'src/components/DnD/Clone/CloneBuilder'
import { usePresentationSelector }
  from 'src/components/PresentationBuilder/PresentationSelector/PresentationSelectorStateProvider'
import { useSlideThumbnails } from './SlidesGroupTarget'
import { usePresentationBuilderState } from 'src/components/PresentationBuilder/state/PresentationBuilderStateProvider'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import GroupedSlides from './TargetComponents/GroupedSlides'
import Slide from './TargetComponents/Slide'
import CollapsibleCard, { CollapsibleActionMenu } from 'src/components/CollapsibleCard/CollapsibleCard'
import { thumbnailSizeDimensions } from 'src/hooks/useThumbnailSize/useThumbnailSize'
import { S as slideStyle } from 'src/components/DnD/Clone/PresentationBuilder/TargetComponents/Slide'
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail'
import { ThumbnailPage } from 'src/components/SlideSelector/SlideSelector'
import { GroupDraft } from 'src/components/SlideSelector/PageGroupList'
import { getThumbURL } from 'src/screens/Publishers/Versioning/SlideSettings/SlideSettings'
import { getSlideContentPageDataTitle }
  from 'src/hooks/useContentPageData/useContentPageData'

export const S = StyleSheet.create({
  rect1: {
    minHeight: 6,
    backgroundColor:
    colors['color-brand2-80'],
    marginHorizontal: 20,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  rect2: {
    minHeight: 6,
    backgroundColor:
    colors['color-brand2-10'],
    marginHorizontal: 30,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  groupedSlidesFill: {
    flexBasis: '100%',
  },
})

export const SingleSlide: React.FC<{page: ThumbnailPage}> = ({ page }) => {
  const { selectedDocumentVersionORM, contentPageData } = usePresentationSelector()
  const { editorThumbnailSize } = usePresentationBuilderState()
  const s3URL = useMemo(() => {
    if (!selectedDocumentVersionORM || !page) return ''
    else return getThumbURL(selectedDocumentVersionORM, page.number)
  }, [selectedDocumentVersionORM])
  const title = useMemo(() => {
    return getSlideContentPageDataTitle(page.number - 1, contentPageData || [])
  }, [contentPageData])

  return (
    <DNAThumbnail
      overlayText={selectedDocumentVersionORM?.model.title}
      style={slideStyle.thumbnailBackdrop}
      s3URL={s3URL}
      size={editorThumbnailSize}
      pageNumber={page.number}
      thumbnailTitle={title}
      checked={ true}
      variant={DNAThumbnail.Variants.INFO}
      mode={DNAThumbnail.Modes.SELECTABLE}
    />
  )
}

export const GroupCard: React.FC<{activeItem: GroupDraft}> = ({ activeItem }) => {
  const { selectedDocumentVersionORM } = usePresentationSelector()
  const { editorThumbnailSize } = usePresentationBuilderState()
  const cardContainerWidth = useMemo(() =>
    thumbnailSizeDimensions[editorThumbnailSize].width + 14, [editorThumbnailSize])
  const groupHeaderTitle = (
    <DNABox fill appearance="col" alignY="center" style={{ width: 100 }}>
      <DNAText h5 numberOfLines={1}>{activeItem.name}</DNAText>
      <DNAText c1 status="flat" numberOfLines={1}>{selectedDocumentVersionORM?.model.title}</DNAText>
    </DNABox>
  )
  return (
    <DNABox style={{ height: 67, width: cardContainerWidth }}>
      <CollapsibleCard
        headerTitle={groupHeaderTitle}
        isCollapsed={true}
      >
        <CollapsibleActionMenu>
          <DNABox
            spacing="sm"
            alignY="center"
          >
            <DNAChip
              appearance="subtle"
              status="basic"
              size="lg"
              style={{ borderRadius: 2 }}
            >
              {`${activeItem.pages.length}`}
            </DNAChip>
            {/* These buttons are just placeholder, they are not functional */}
            <DNAButton
              appearance="ghostLink"
              disabled
              size="md"
              iconStyle={{ color: colors['color-gray-200'] }}
              padding="sm"
              rounded="md"
              status="gray"
              iconLeft={activeItem.locked ? 'lock' : 'lock-open-variant-outline'}
            />
            <DNAButton
              iconLeft="dots-vertical"
              size="md"
              padding="sm"
              appearance="ghost"
              status="gray"
            />
          </DNABox>
        </CollapsibleActionMenu>
      </CollapsibleCard>
    </DNABox>
  )
}

const PoolItemOverlay: React.FC = () => {
  const { selectedItemIds, getGroupDrafts } = usePresentationSelector()
  const { active } = usePresentationSelector()
  const activeItemId = active?.data.current?.itemId
  const activeItem = useMemo(() =>
    getGroupDrafts().find(groupDraft => groupDraft.id === activeItemId), [getGroupDrafts, activeItemId])
  if (!activeItem) return null
  const isGroup = activeItem.pages.length > 1

  return (
    <>
      <Iffy is={selectedItemIds.length >= 3}>
        <DNABox style={S.rect2} />
      </Iffy>
      <Iffy is={selectedItemIds.length >= 2}>
        <DNABox style={S.rect1} />
      </Iffy>
      <Iffy is={isGroup}>
        <GroupCard activeItem={activeItem} />
      </Iffy>
      <Iffy is={!isGroup}>
        <SingleSlide page={activeItem.pages[0]} />
      </Iffy>
    </>
  )
}

const TargetItemOverlay: React.FC = () => {
  const { groupMap, groupStartingIdxMap } = useSlideThumbnails()
  const { builderMode, isLocked, selectedTargetItems } = usePresentationBuilderState()
  const { active } = usePresentationSelector()
  const activeItemId: string = active?.data.current?.itemId ?? ''
  const activeItem = useMemo(() => groupMap.get(activeItemId), [activeItemId])
  if (!activeItem) return null
  const isGroup = activeItem.pages.length > 1
  const isCustomizationMode = builderMode === 'customization';
  const orientation: DNABoxProps['appearance'] = isCustomizationMode ? 'row' : 'col'

  const groupedProps = {
    group: activeItem,
    startingIdx: groupStartingIdxMap[activeItem.id],
    style: S.groupedSlidesFill,
    orientation,
    islocked: isLocked,
    isDraggingMode: !!activeItem,
  }
  const slideProps = {
    group: activeItem,
    page: activeItem.pages[0],
    displayIdx: groupStartingIdxMap[activeItem.id],
    enableOverlay: !activeItem,
    isDraggingMode: !!activeItem,
  }
  return (
    <>
      <Iffy is={selectedTargetItems.length >= 3}>
        <DNABox style={S.rect2} />
      </Iffy>
      <Iffy is={selectedTargetItems.length >= 2}>
        <DNABox style={S.rect1} />
      </Iffy>
      <Iffy is={isGroup}>
        <GroupedSlides {...groupedProps} />
      </Iffy>
      <Iffy is={!isGroup}>
        <Slide {...slideProps} />
      </Iffy>
    </>)
}

const SlidesGroupOverlay: React.FC = () => {
  const { active } = usePresentationSelector()
  const { editorThumbnailDimensions } = usePresentationBuilderState()
  const activeItemId = active?.data.current?.itemId
  const isFromPool = active?.data.current?.containerId === POOL_CONTAINER_ID
  const isFromTarget = active?.data.current?.containerId === 'default'
  if ( !activeItemId || (!isFromPool && !isFromTarget)) return null

  return (
    createPortal(
      <DragOverlay modifiers={[snapCenterToCursor]}>
        <DNABox appearance="col" style={{ width: editorThumbnailDimensions.width + 6 }}>
          {/* <DNAText>{ active?.id }</DNAText>
          <DNAText>{ activeItemId }</DNAText> */}
          <Iffy is={isFromPool}>
            <PoolItemOverlay />
          </Iffy>
          <Iffy is={isFromTarget}>
            <TargetItemOverlay />
          </Iffy>
        </DNABox>
      </DragOverlay>,
      window.document.body,
    )
  )
}

export default SlidesGroupOverlay
