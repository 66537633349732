import React, { useEffect, useState } from 'react'
import { StyleSheet, TouchableWithoutFeedback, ViewStyle } from 'react-native';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings'
import { DNAButton, DNAText, DNABox, Icon } from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import { RootState } from 'src/state/redux'
import { useSelector } from 'react-redux'
import { getZendeskTokenPayload } from 'src/utils/zendeskHelpers'
import { ZENDESK_FEATURE } from '@alucio/aws-beacon-amplify/src/API'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';

const sharedStyles = StyleSheet.create({
  notificationIcon: {
    width: 8,
    height: 8,
    color: colors['color-danger-500'],
    marginLeft: 8,
    fontSize: 16,
  },
})

interface Styles {
  wrapper:ViewStyle,
}

type DeviceModeStyles = Record<DeviceMode, StyleSheet.NamedStyles<Styles>>

const deviceModeStyles:DeviceModeStyles = {
  desktop: StyleSheet.create({
    wrapper: {
      position: 'absolute',
      right: 1,
      top: -6,
      cursor: 'pointer',
    },
  }),
  tablet: StyleSheet.create({
    wrapper: {
      position: 'absolute',
      right: 8,
      top: 3,
      cursor: 'pointer',
    },
  }),
}

export const useChatButtonSharedResources = () => {
  const { isOnline } = useAppSettings();
  const { isOpen:CPMisOpen } = useSelector((state: RootState) => state.contentPreviewModal)
  const [unreadMessages, setUnreadMessages] = useState<boolean>()
  const openChat = () => {
    // @ts-ignore
    zE('messenger', 'open');
  }

  /** TODO: Consider moving some of this stuff to a more central/general
 * zendesk related management facility such as a context or hook */
  useEffect(() => {
    const updatedIndex = CPMisOpen ? -1 : 999999
    // @ts-ignore
    isOnline && zE('messenger:set', 'zIndex', updatedIndex);
  }, [CPMisOpen, isOnline])

  /** Component init */
  useEffect(() => {
    /** Login User */
    // @ts-ignore
    isOnline && zE('messenger', 'loginUser', async (cb) => {
      const payload = await getZendeskTokenPayload(ZENDESK_FEATURE.CHAT)
      cb(payload.token)
    });

    /** Setup unread message event handler */
    /** TODO: Identify approach for cleaning up this handler. It is currently causing a memory leak when logging out and back in again. */
    // @ts-ignore
    isOnline && zE('messenger:on', 'unreadMessages', (count) => {
      setUnreadMessages(count > 0)
    })

    /** On component destroy, logout the user */
    return () => {
      // @ts-ignore
      isOnline && zE('messenger', 'logoutUser')
    }
  }, [isOnline])

  return {
    openChat,
    unreadMessages,
  }
}

type DeviceModeButtonProps = Record<DeviceMode, DNAButtonProps>
const deviceModeButtonProps:DeviceModeButtonProps = {
  desktop: {
    appearance:'outline',
    status:'tertiary',
    size:'lg',
    padding:'sm',
    iconLeft:'chat-processing-outline',
  },
  tablet: {
    appearance:'ghostLink',
    padding:'none',
    rounded:'full',
    status:'dark',
    size:'xl',
    iconLeft:'chat-outline',
  },
}

const ChatButton:React.FC = () => {
  const { openChat, unreadMessages } = useChatButtonSharedResources()
  const { deviceMode } = useAppSettings()
  const { notificationIcon } = sharedStyles

  const { wrapper } = deviceModeStyles[deviceMode]
  const currentDeviceModeButtonProps = deviceModeButtonProps[deviceMode]

  return (
    <DNAPopover disablePopover={['tabletPWA']}>
      <DNAPopover.Anchor>
        <DNAButton
          testID="icon-chat-processing-outline"
          onPress={openChat}
          {...currentDeviceModeButtonProps}
        />
        {unreadMessages &&
          <TouchableWithoutFeedback onPress={openChat}>
            <DNABox
              style={wrapper}
            >
              <Icon name={'record'} style={notificationIcon}/>
            </DNABox>
          </TouchableWithoutFeedback>
        }
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">Chat Support</DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  )
}

export default ChatButton
