import React, { useState, createContext, useEffect, useContext, PropsWithChildren } from 'react'
import { useSafePromise } from '@alucio/lux-ui';
import { GetReportListQuery, GetEmbeddedUrlQuery } from '@alucio/aws-beacon-amplify/src/API'
import { getEmbeddedUrl, getReportList } from '@alucio/aws-beacon-amplify/src/graphql/queries'
import { API, graphqlOperation } from '@aws-amplify/api'
import { useUserTenant } from 'src/state/redux/selector/user';
import {
  CustomFieldUsage,
  FieldStatus, PermissionEffect, ReportDashboard, ReportItem, Sheet,
} from '@alucio/aws-beacon-amplify/src/models';
import useFeatureFlag from 'src/hooks/useFeatureFlag/useFeatureFlag';
import { FeatureFlags } from 'src/types/featureFlags';
import { EmbeddableObject } from './EmbedTypes';
import { useTenantCustomFields } from 'src/state/redux/selector/tenant';

const CUSTOM_HIDE_DOCUMENT_REPORTS = [
  {
    name: 'Share Activity',
    hiddenByBlockSharing: true,
    hiddedByBlockDownloading: false,
    hiddenPublisherByFutureFlag: false,
  },
  {
    name:'Top 10 Shared Documents',
    hiddenByBlockSharing: true,
    hiddedByBlockDownloading: false,
    hiddenPublisherByFutureFlag: false,
  },
  {
    name:'Document Links Sent And Downloaded',
    hiddenByBlockSharing: true,
    hiddedByBlockDownloading: false,
    hiddenPublisherByFutureFlag: true,
  },
  {
    name:'All Content Downloads by Viewer',
    hiddenByBlockSharing: false,
    hiddedByBlockDownloading: true,
    hiddenPublisherByFutureFlag: false,
  },
]

type SelectedSheet = {
  selectedSheet: Sheet | undefined;
}

type ReportProvider = {
  dashboard?: ReportDashboard,
  onReportSelected: (report: ReportItem) => void,
  reportUrl: string | undefined
  isLoadedReportsList: boolean,
  isLoadingReport: boolean,
  setIsLoadingReport: (isLoading: boolean) => void,
  setReportUrl: (url: string | undefined) => void,
  selectedReport: ReportItem | undefined,
  setEmbeddableObject: (embeddableObject: EmbeddableObject) => void,
  onSelectedSheetChange: (sheet : SelectedSheet) => void,
  folderName: string,
  isSharingBlocked: boolean,
  isDownloadingBlocked: boolean,
  onDashboardChange: (isTeamLeader : Boolean) => void,
  isTeamViewer: boolean,
  setIsTeamViewer: (isTeamViewer: boolean) => void,
}

const getEmbeddedUrlAsync = async (dashboardId: string, folderName: String) => {
  const gqlParams = {
    ...graphqlOperation(getEmbeddedUrl,
      {
        dashboardId: dashboardId,
        folderName,
      }),
  }
  const { data } = await API.graphql(gqlParams) as {
    data: GetEmbeddedUrlQuery;
  };

  if (!data.getEmbeddedUrl) {
    throw new Error('An error occurred getting the embedded URL report')
  }

  return data;
}

const getReportListAsync = async (folderName: String, isSharingBlocked: Boolean,
  hideReportsByFeatureFlag: boolean, isDownloadingBlocked : Boolean) => {
  const gqlParams = {
    ...graphqlOperation(getReportList, { folderName }),
  }

  const { data } = await API.graphql(gqlParams) as {
    data: GetReportListQuery;
  };

  if (!data.getReportList) {
    throw new Error('No report list get from the service')
  }
  const filteredReportList = data.getReportList.reports.filter(report => {
    const reportName = report?.name.trim().toLocaleLowerCase();
    const hiddenReport = CUSTOM_HIDE_DOCUMENT_REPORTS.find(report =>
      report.name.trim().toLocaleLowerCase() === reportName);
    if (isSharingBlocked && hiddenReport && hiddenReport.hiddenByBlockSharing) {
      return false;
    }
    if (hiddenReport && hiddenReport.hiddenPublisherByFutureFlag &&
        folderName.toLowerCase().includes('publisher') && hideReportsByFeatureFlag) {
      return false;
    }
    if (isDownloadingBlocked && hiddenReport && hiddenReport.hiddedByBlockDownloading) {
      return false;
    }
    return true;
  }).sort((a, b) => a.name.localeCompare(b.name))

  return { ...data.getReportList, reports: filteredReportList };
}

const ReportContext = createContext<ReportProvider>(null!)

interface InitialValues {
  folderName: string
}

export const ReportProvider: React.FC<PropsWithChildren<InitialValues>> = (props) => {
  const { folderName } = props
  const [dashboard, setDashboard] = useState<ReportDashboard>()
  const [reportUrl, setReportUrl] = useState<string>();
  const safePromise = useSafePromise()
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [embeddableObject, setEmbeddableObject] = useState<EmbeddableObject>();
  const tenant = useUserTenant();
  const isSharingBlocked = tenant?.config?.defaultDocumentPermissions.share === PermissionEffect.BLOCK;
  const hideReportsByFeatureFlag = !useFeatureFlag(FeatureFlags.BEAC_3865_publisher_share_sent_download_report)
  const [selectedReport, setSelectedReport] = useState<ReportItem>()
  const documentCustomFields = useTenantCustomFields({ usages: { internalUsages: [CustomFieldUsage.DOCUMENT] } })
  const [isTeamViewer, setIsTeamViewer] = useState(false)

  useEffect(() => {
    const listReports = async () => {
      try {
        const dashboardQuery = await safePromise.makeSafe(getReportListAsync(folderName, isSharingBlocked,
          hideReportsByFeatureFlag, isDownloadingBlocked))
        // [TODO-1918] - Could come back null, handle non-happy path
        // Consider inserting "Failed to Load" as the drop down selection
        setDashboard(dashboardQuery)
        if (dashboardQuery.dashboardUrl) {
          setReportUrl(dashboardQuery.dashboardUrl)
          setSelectedReport(dashboardQuery.reports.find(report => report.name.toLowerCase().includes('dashboard')))
        }
      }
      catch (e) {
        console.warn(e)
      }
    }

    listReports()
  }, [])

  const onDashboardChange = async (isTeamLeader : Boolean) => {
    setIsLoadingReport(true)
    setDashboard(undefined)
    setReportUrl(undefined)
    const folder = !isTeamLeader ? folderName : 'Beacon_Embedded_Publisher'
    const dashboardQuery = await safePromise.makeSafe(getReportListAsync(folder, isSharingBlocked,
      hideReportsByFeatureFlag, isDownloadingBlocked))
    // [TODO-1918] - Could come back null, handle non-happy path
    // Consider inserting "Failed to Load" as the drop down selection
    setDashboard(dashboardQuery)
    if (dashboardQuery.dashboardUrl) {
      setReportUrl(dashboardQuery.dashboardUrl)
      setSelectedReport(dashboardQuery.reports.find(report => report.name.toLowerCase().includes('dashboard')))
    }
  }

  const checkIfDownloadDocumentsIsBlocked = () => {
    const IsAnyDownloadValueActive = documentCustomFields
      .filter(q => q.status === FieldStatus.ENABLED).map(customField => customField.fieldValueDefinitions
        .filter(valueDefinition => valueDefinition.documentSettings?.permission?.download === PermissionEffect.ALLOW &&
          valueDefinition.disabled !== true)
        .map(valueDefinition => valueDefinition.id))
      .flat().length > 0;
    const isDownloadBlocked = tenant?.config?.defaultDocumentPermissions.download === PermissionEffect.BLOCK;
    return isDownloadBlocked || !IsAnyDownloadValueActive;
  }
  const isDownloadingBlocked = checkIfDownloadDocumentsIsBlocked();

  const setDefaultParameters = () => {
    const endDate = new Date()
    endDate.setMonth(11)
    endDate.setDate(31)
    const startDate = new Date()
    startDate.setMonth(0)
    startDate.setDate(1)
    embeddableObject?.setParameters(
      {
        startDate: startDate.toISOString(),
        enddate: endDate.toISOString(),
        documentLabel: '[ALL]',
        documentLabelValue: '[ALL]',
        documentLabelKeyNotAll: '-- Select --',
        userRole: 'Viewer',
        meetingType: '[ALL]',
        userLabelKey: '[ALL]',
        userLabelValue: '[ALL]',
        lastUsableVersion: '[ALL]',
        semVersion: '[ALL]',
        documentVersionTitle: '[ALL]',
        userEmail: '[ALL]',
      })
  }

  const onSelectedSheetChange = async ({ selectedSheet }) => {
    if (selectedSheet) {
      const report = dashboard?.reports.find(report => report.name === selectedSheet.name.split('-')[1])
      setSelectedReport(report)
    }
  }

  const onReportSelected = async (report: ReportItem) => {
    // check if the report is one of the tabs in the dashboard
    const folder = !isTeamViewer ? folderName : 'Beacon_Embedded_Publisher'
    const sheet = dashboard?.dashboardSheets.find(sheet => sheet.name.split('-')[1].toLowerCase() ===
    report.name.toLocaleLowerCase())
    if (sheet && reportUrl === dashboard?.dashboardUrl) {
      // if the report is one of the tabs in the dashboard, set the selected sheet
      embeddableObject?.navigateToSheet(sheet.sheetId)
      setDefaultParameters()
    }
    else if (sheet && reportUrl !== dashboard?.dashboardUrl && dashboard) {
      // if the report is one of the tabs in the dashboard, but the dashboard is not loaded, load the dashboard
      setReportUrl(undefined)
      setIsLoadingReport(true)
      setSelectedReport(report)
      const embeddedUrl = await safePromise.makeSafe(getEmbeddedUrlAsync(dashboard?.dashboardId, folder))
      setDashboard({ ...dashboard, dashboardUrl: embeddedUrl.getEmbeddedUrl || '' })
      setReportUrl(embeddedUrl.getEmbeddedUrl || undefined)
    }
    else {
      // if the report is not one of the tabs in the dashboard, load the report
      setReportUrl(undefined)
      setSelectedReport(report)
      setIsLoadingReport(true)
      const data = await getEmbeddedUrlAsync(report.dashboardId, folder)
      setReportUrl(data.getEmbeddedUrl || undefined)
    }
  }

  const contextValues = {
    dashboard,
    onReportSelected,
    reportUrl,
    isLoadedReportsList: !!dashboard,
    isLoadingReport,
    setIsLoadingReport,
    setReportUrl,
    selectedReport,
    setEmbeddableObject,
    onSelectedSheetChange,
    folderName,
    isSharingBlocked,
    isDownloadingBlocked,
    onDashboardChange,
    isTeamViewer,
    setIsTeamViewer,
  }

  return (
    <ReportContext.Provider value={contextValues}>
      {props.children}
    </ReportContext.Provider>
  )
}

export const useReport = () => useContext(ReportContext)
