import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAIcon, DNAModal, DNAText, Iffy } from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import { CustomFieldDefinition, CustomFieldUsage, FieldDataType } from '@alucio/aws-beacon-amplify/src/models';
import { useTenantCustomFields } from 'src/state/redux/selector/tenant';
import { DNAModalVariant } from '../DNAConnectedModal';
import { ShareFolderMode } from './DNAShareFolderModal';
import { ComposableForm, useComposableForm } from 'src/components/CustomFields/ComposableForm';
import { ComposableField } from 'src/components/CustomFields/ComposableField';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

const styles = StyleSheet.create({
  container: {
    minWidth: 600,
    maxWidth: 600,
  },
  folderNameText: {
    maxWidth: 450,
  },
  tabletChevronBtn: {
    marginRight: 10,
  },
  createAccessFilterTextContainer: {
    marginTop: 24,
    marginLeft: 24,
  },
  createAccessFilterHelpCircle: {
    marginLeft: 5,
  },
  createAccessFilterTooltipText: {
    color: colors['color-text-white'],
    width: 400,
  },
  composableFieldContainer: {
    height: 308,
    marginTop: 18,
    paddingHorizontal: 24,
  },
});

interface CreateAccessFilterProps {
  isDesktop: boolean;
  onCreateFilter: (value: any) => void;
  display: boolean;
  folderName: string;
  setMode: (mode: ShareFolderMode) => void;
}

interface CreateFilterProps extends CreateAccessFilterProps {
  customFields: CustomFieldDefinition[]
}

const tooltipText = 'Creating an access filter will determine which users will have access ' +
'to this folder based on the filters that they have been assigned. ' +
'However, they may not have access to all the content within this folder ' +
'if their assigned filters restrict their access to specific content.'

const CreateAccessFilter: DNAModalVariant<CreateFilterProps> = (props) => {
  const { toggleModal, customFields, onCreateFilter, display, setMode, isDesktop, folderName } = props;
  const { rhForm } = useComposableForm()
  const { formState } = rhForm;
  const { dirtyFields } = formState;
  const isDirty = Object.keys(dirtyFields).length;

  const handleSubmit =
      rhForm.handleSubmit((values) => {
        onCreateFilter(Object.keys(values).reduce((acc, key) => {
          rhForm.setValue(key, undefined);
          const formattedValues = Array.isArray(values[key]) ? values[key] : [values[key]];
          if (values[key] && formattedValues.length) {
            acc[key] = { key, values: formattedValues };
          }

          return acc;
        }, {}));
        rhForm.reset();
      });

  return (
    <DNABox testID="create-access-filter-modal" style={{ display : display ? undefined : 'none' }}>
      <DNAModal>
        <DNAModal.Header>
          {/* DESKTOP HEADER */}
          <Iffy is={isDesktop}>
            <DNABox appearance="row" spacing="between" alignY="center" style={styles.container}>
              <DNAButton
                appearance="ghost"
                status="tertiary"
                size="md"
                padding="sm"
                iconLeft="chevron-left"
                onPress={() => setMode(ShareFolderMode.SHARE_FOLDER)}
              >
                Back </DNAButton>
              <DNAButton
                iconLeft="close"
                onPress={toggleModal}
                appearance="ghost"
                status="gray"
                size="md"
                padding="sm"
              />
            </DNABox>
          </Iffy>
          {/* TABLET HEADER */}
          <Iffy is={!isDesktop}>
            <DNABox appearance="row" spacing="between" alignY="center" style={styles.container}>
              <DNABox alignY="center" spacing="xs">
                <DNAButton
                  appearance="ghost"
                  status="tertiary"
                  size="md"
                  padding="sm"
                  iconLeft="chevron-left"
                  onPress={() => setMode(ShareFolderMode.SHARE_FOLDER)}
                  style={styles.tabletChevronBtn}
                />
                <DNAText status="dark" h4 numberOfLines={2} style={styles.folderNameText}>{folderName}</DNAText>
              </DNABox>
              <DNAButton
                testID="share-folder-modal-confirmation-button"
                size="md"
                appearance="ghost"
                onPress={handleSubmit}
                disabled={!isDirty}
                status="primary"
              >
                Create
              </DNAButton>
            </DNABox>
          </Iffy>
        </DNAModal.Header>
        <DNAModal.Body>
          <DNABox as={ScrollView} appearance="col" fill style={styles.container}>
            <DNABox appearance="col" style={styles.createAccessFilterTextContainer}>
              <DNABox testID="create-access-filter-container" appearance="row">
                <DNAText label>Create Access Filter</DNAText>
                <DNAPopover >
                  <DNAPopover.Anchor>
                    <DNABox>
                      <DNAIcon.Styled
                        testID="create-access-filter"
                        style={styles.createAccessFilterHelpCircle}
                        appearance="ghost"
                        status="tertiary"
                        name="help-circle-outline"
                      />
                    </DNABox>
                  </DNAPopover.Anchor>
                  <DNAPopover.Content>
                    <DNAText
                      testID="create-access-filter-tooltip-text"
                      style={styles.createAccessFilterTooltipText}
                    >
                      {tooltipText}
                    </DNAText>
                  </DNAPopover.Content>
                </DNAPopover>
              </DNABox>
            </DNABox>
            <DNABox style={styles.composableFieldContainer}>
              <DNABox appearance="col" spacing="md" fill>
                {
                  customFields.map((field) =>
                    (<ComposableField
                      key={field.id}
                      field={field}
                    />),
                  )
                }
              </DNABox>
            </DNABox>
          </DNABox>
        </DNAModal.Body>
        <Iffy is={isDesktop}>
          <DNAModal.Confirmation>
            <DNAButton
              testID="share-folder-modal-confirmation-button"
              size="sm"
              onPress={handleSubmit}
              disabled={!isDirty}
              status="primary"
            >
              Create
            </DNAButton>
          </DNAModal.Confirmation>
        </Iffy>
      </DNAModal>
    </DNABox>
  )
}

const CreateAccessFilterWrapper: DNAModalVariant<CreateAccessFilterProps> = (props) => {
  const customFields = useTenantCustomFields({ usages: { internalUsages: [CustomFieldUsage.USER_FILTER] } })
    .filter(({ showByDefault, fieldType }) => showByDefault && fieldType === FieldDataType.MULTICATEGORICAL)
    .map((customFieldDefinition) => ({ ...customFieldDefinition, required: false }));

  return (
    <ComposableForm customFields={customFields}>
      <CreateAccessFilter {...props} customFields={customFields} />
    </ComposableForm>
  );
};

export default CreateAccessFilterWrapper;
