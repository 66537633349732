import { DNABox, DNAButton, DNAText, useOrientationChange } from '@alucio/lux-ui';
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';
import React from 'react';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';

interface PresentationNavProps {
  disablePageCount?: boolean
}

type ButtonDefaultsVariants = Record<DeviceMode, DNAButtonProps>

const PresentationNav:React.FC<PresentationNavProps> = ({
  disablePageCount,
}) => {
  const { activePresentation, nextStepPage, prevStepPage } = useContent()
  const { deviceMode } = useAppSettings()
  const orientation = useOrientationChange()

  const buttonDefaultsVariants:ButtonDefaultsVariants = {
    desktop: {
      appearance:'filled',
      size: 'sm',
      padding: 'sm',
      status: 'dark',
    },
    tablet: {
      appearance:'filled',
      size: orientation === 'LANDSCAPE' ? 'lg' : 'sm',
      padding: orientation === 'LANDSCAPE' ? 'lg' : 'sm',
      status: 'dark',
    },
  }

  const currentButtonDefaultsVariant = buttonDefaultsVariants[deviceMode]
  const presentable = activePresentation?.presentable;
  const currentPageNumber = activePresentation?.currentPresentablePage.presentationPageNumber

  return (
    <DNABox spacing="sm" alignY="center">
      <DNAButton
        {...currentButtonDefaultsVariant}
        onPress={prevStepPage}
        testID="previous-slide-button"
        iconLeft="chevron-left"
      />
      {!disablePageCount &&
        <DNAText status="basic" testID="slide-of-number">
          {`${currentPageNumber} of ${presentable?.numberOfPages}`}
        </DNAText>
      }
      <DNAButton
        {...currentButtonDefaultsVariant}
        onPress={nextStepPage}
        testID="next-slide-button"
        iconRight="chevron-right"
      />
    </DNABox>
  )
}

export default PresentationNav;
