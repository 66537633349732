import { GetProviderByUserEmailQuery } from '@alucio/aws-beacon-amplify/src/API';
import awsmobile from '@alucio/aws-beacon-amplify/src/aws-exports';
import { getProviderByUserEmail } from '@alucio/aws-beacon-amplify/src/graphql/queries';
import { API, graphqlOperation, GraphQLQuery } from '@aws-amplify/api';
import debounce from 'lodash/debounce';
import config from 'src/config/app.json';
import { useState, useCallback } from 'react';
import useRetryOnErrosSSO from './useRetryOnSSoError';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag/useFeatureFlag';
import { FeatureFlags } from 'src/types/types';
import { useAppSettings } from 'src/state/context/AppSettings';

const navigation = window.location;

const useSSO = () => {
  const [isValidating, doSSOValidation] = useState<boolean>(false);
  const [isSSoUser, setIsSSoUser] = useState<boolean>(false);
  const [domain, setDomain] = useState<string>('');
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const { saveUrl, error: ssoError, cleanSSoError } = useRetryOnErrosSSO();
  const isKnowledgebaseEnabled = useFeatureFlag(FeatureFlags.BEAC_2519_zendesk_knowledgebase);
  const { initialUrlSearchParams } = useAppSettings()
  const validateEmail = (email) => {
    // eslint-disable-next-line max-len
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const validateUser: any = async (email, redirect = true) => {
    // Match an email
    if (!validateEmail(email)) {
      setIsSSoUser(false);
      return {
        url: null,
        isSSO: false,
      };
    }

    doSSOValidation(true);

    const domain : string = email.split('@').pop().toLowerCase();

    // Since we aren't logged in we use IAM un-authenticated credentials to call appsync
    const gqlParams = {
      ...graphqlOperation(getProviderByUserEmail, { idpIdentifier: domain }),
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    }

    const providerData = await API.graphql<GraphQLQuery<GetProviderByUserEmailQuery>>(gqlParams);
    const result = providerData.data?.getProviderByUserEmail;
    doSSOValidation(false);
    setDomain(domain);

    if (result) {
      // The react router is not available at this momment in the app
      // eslint-disable-next-line max-len
      let url = `https://${config.authUrl}.auth.us-west-2.amazoncognito.com/oauth2/authorize?client_id=${awsmobile.aws_user_pools_web_client_id}&response_type=code&scope=email+openid+profile+aws.cognito.signin.user.admin&redirect_uri=${window.location.origin}/&idp_identifier=${domain}`;
      const returnTo = initialUrlSearchParams.get('return_to');

      if (returnTo && isKnowledgebaseEnabled) {
        url += `&state=${encodeURIComponent(returnTo)}`
      }
      saveUrl(url);

      if (redirect) navigation.href = url
      else setRedirectUrl(url)
      setIsSSoUser(true);
      return {
        url,
        isSSO: true,
      };
    }
    setIsSSoUser(false);
    return {
      url: null,
      isSSO: false,
    };
  };
  const SSOUserValidation = useCallback(validateUser, [isKnowledgebaseEnabled])

  const DebouncedSSOUserValidation = useCallback(debounce(validateUser, 1000), [])

  return {
    SSOUserValidation,
    DebouncedSSOUserValidation,
    isValidating,
    isSSoUser,
    redirectUrl,
    domain,
    ssoError,
    cleanSSoError,
  }
}

export default useSSO;
