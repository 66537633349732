import React, { PropsWithChildren, useMemo, useState } from 'react';
import beaconConfig, { getDefaultValues } from 'src/hooks/useMeetingFormConfig/AddMeetingConfig';
import { AddMeetingProvider, CRM_SUBMIT_STATE, useAddMeeting } from './AddMeetingProvider';
import { DNABox, DNACard, DNAText, Iffy, InformationMessage } from '@alucio/lux-ui';
import { ComposableForm, ComposableVariant } from '../CustomFields/ComposableForm';
import AddMeetingFooter from './AddMeetingFooter';
import { ScrollView, StyleSheet } from 'react-native';
import { ComposableDateTimePicker, ComposableField } from '../CustomFields/ComposableField';
import AttendeeInput from '../CustomFields/AttendeeInput';
import { MeetingORM } from 'src/types/orms';
import ContentPresentedList from './ContentPresentedList';
import { ContentPresentedStatus, CustomFieldDefinition, MeetingStatus } from '@alucio/aws-beacon-amplify/src/models';
import { useAppSettings } from 'src/state/context/AppSettings';
import AddMeetingHeader from './AddMeetingHeader';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import ContentSearchBar, { ContentSearchResult } from '../ContentSearchBar/ContentSearchBar';
import { isCustomDeckORM } from 'src/types/typeguards';
import { useFolderItemORMMap } from 'src/state/redux/selector/folder';
import useMeetingFormConfig, { MeetingFormConfig } from 'src/hooks/useMeetingFormConfig/useMeetingFormConfig';
import { LoadingMeeting } from './LoadingMeeting';
import MeetingCRMAuth from './MeetingCRMAuth';
import { ColumnFieldsWrapper } from '../CustomFields/ColumnCustomFieldsWrapper';
import { DNAButton } from '@alucio/lux-ui/src';

interface AddMeetingProps {
  meetingId?: string,
  toggleDrawer: () => void,
}

const styles = StyleSheet.create({
  cardTitle: {
    marginBottom: 26,
  },
  contentCard: {
    borderRadius: 6,
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  contentContainer: {
    backgroundColor: colors['color-gray-50'],
  },
  informationMessageContainer: {
    paddingHorizontal: 24,
    paddingTop: 24,
  },
  informationMessage: {
    marginBottom: 0,
  },
  rowsWrapper: {
    borderRadius: 3,
    borderWidth: 1,
    borderColor: colors['color-gray-80'],
    padding: 8,
  },
});

interface ContentPresentedFieldProps {
  isEditForm: boolean
  meetingId?: string
  onDeleteContent: (contentPresentedId: string) => void,
}

const ContentPresentedField = (props: ContentPresentedFieldProps) => {
  const { onDeleteContent, isEditForm, meetingId } = props;
  const [isSearchBarVisible, setIsSearchBarVisible] = useState<boolean>(false);
  const folderItemORMMap = useFolderItemORMMap();
  const { contentPresented, addContentPresented, isReadOnly } = useAddMeeting()
  const selectedIds = contentPresented?.reduce((acc: string[], current) => {
    if (current.status !== 'DELETED') {
      acc.push(current.contentId)
    }
    return acc;
  }, [])

  const handleSelectItem = (item: ContentSearchResult) => {
    let folderItemId;

    if (isCustomDeckORM(item.orm)) {
      folderItemId = folderItemORMMap.get(item.id)?.model.id;
    }

    addContentPresented({
      contentId: item.id,
      contentType: item.orm.type,
      status: ContentPresentedStatus.ACTIVE,
      title: item.orm.model.title ?? '',
      folderItemId,
      presentedMeta: [],
    });
  }

  const handleShowSearchBar = () => {
    analytics?.track('MEETING_ADD_CONTENT', {
      action: 'ADD_CONTENT',
      category: 'MEETING',
    })
    setIsSearchBarVisible(true);
  }

  return (
    <DNABox appearance="col" spacing="sm">
      <DNABox>
        <Iffy is={selectedIds?.length === 0}>
          <DNAText status="flat">
            No content was presented
          </DNAText>
        </Iffy>
      </DNABox>
      <Iffy is={!isReadOnly}>
        <DNABox appearance="col" spacing="sm">
          <DNAButton
            testID="meeting-add-file-button"
            size="sm"
            appearance="outline"
            status="tertiary"
            onPress={handleShowSearchBar}
          >Add file</DNAButton>
          <ContentSearchBar
            onSelectItem={handleSelectItem}
            selectedIds={selectedIds}
            variant="meetingHistory"
            isVisible={isSearchBarVisible}
            setIsVisible={setIsSearchBarVisible}
          />
        </DNABox>
      </Iffy>

      <Iffy is={selectedIds?.length}>
        <DNABox appearance="col" style={isReadOnly ? styles.rowsWrapper : undefined} fill>
          <ContentPresentedList
            onDelete={onDeleteContent}
            isEditForm={isEditForm}
            meetingId={meetingId}
            contentPresented={contentPresented || []}
            showDivider={isReadOnly}
          />
        </DNABox>
      </Iffy>
    </DNABox>
  )
}

interface CustomFormProps {
  customFields: CustomFieldDefinition[],
  displaySuccessfulSave?: boolean,
  meetingORM?: MeetingORM,
}

const CustomForm: React.FC<CustomFormProps> = (props) => {
  const { meetingORM, customFields, displaySuccessfulSave } = props;
  const defaultValues = getDefaultValues(meetingORM)
  const { crmSubmitState, onDeleteContent, isValid, errorMessage, isReadOnly } = useAddMeeting()
  const { deviceMode } = useAppSettings()
  const staticFields = { ...beaconConfig, customValues: defaultValues }
  const errorSubmitCRM = useMemo(() => crmSubmitState === CRM_SUBMIT_STATE.ERROR, [crmSubmitState]);

  return (
    <DNABox appearance="col" fill>
      <AddMeetingHeader />
      <DNABox fill appearance="col" style={styles.contentContainer}>
        {/* SUCCESSFUL SAVE */}
        <Iffy is={displaySuccessfulSave}>
          <DNABox appearance="col" spacing="lg" style={styles.informationMessageContainer}>
            <InformationMessage
              text={meetingORM?.model.status === MeetingStatus.LOCKED
                ? 'Successfully submitted to CRM' : 'Draft successfully sent to CRM' }
              variance="success"
            />
          </DNABox>
        </Iffy>
        {/* FORM ERRORS */}
        <Iffy is={(!isValid && errorMessage) || errorSubmitCRM}>
          <DNABox appearance="col" spacing="lg" style={styles.informationMessageContainer}>
            <InformationMessage
              text={errorMessage || 'An error occurred while submitting to CRM'}
              variance="danger"
              style={styles.informationMessage}
            />
          </DNABox>
        </Iffy>
        <ScrollView contentContainerStyle={{ paddingBottom: 80 }}>
          <DNABox appearance="col" spacing="lg" fill style={{ padding: 24 }}>

            {/* MEETING INFORMATION */}
            <ContentCard cardTitle="Meeting Information">
              <DNABox appearance="col" spacing="md">
                {/* MEETING TITLE */}
                <ComposableField variant={ComposableVariant.DEFAULT} field={staticFields.fields[0]} />
                {/* MEETING TIMES */}
                <DNABox appearance="col" spacing="xs">
                  <DNABox appearance="row" fill>
                    <DNABox appearance="col" style={{ marginRight: 10 }} fill>
                      <ComposableField
                        hideError
                        variant={ComposableVariant.DEFAULT}
                        field={staticFields.fields[1]}
                        CustomFormComponent={ComposableDateTimePicker}
                      />
                    </DNABox>
                    <DNABox appearance="col" style={{ marginRight: 10 }} fill>
                      <ComposableField
                        variant={ComposableVariant.DEFAULT}
                        field={staticFields.fields[2]}
                        CustomFormComponent={ComposableDateTimePicker}
                      />
                    </DNABox>
                  </DNABox>
                </DNABox>
              </DNABox>
            </ContentCard>

            {/* PRIMARY ATTENDEE */}
            <ContentCard cardTitle="Primary Attendee">
              <ComposableField
                hideRequiredLabel
                variant={ComposableVariant.DEFAULT}
                field={staticFields.fields[3]}
                CustomFormComponent={(props) =>
                  (<AttendeeInput
                    variant={ComposableVariant.DEFAULT}
                    isReadOnly={isReadOnly}
                    meetingORM={meetingORM}
                    {...props}
                  />)
                }
              />
            </ContentCard>

            {/* ADDITIONAL ATTENDEES */}
            <ContentCard cardTitle="Additional Attendees">
              <ComposableField
                variant={ComposableVariant.DEFAULT}
                field={staticFields.fields[4]}
                CustomFormComponent={(props) =>
                  (<AttendeeInput
                    variant={ComposableVariant.DEFAULT}
                    isReadOnly={isReadOnly}
                    {...props}
                  />)
                }
              />
            </ContentCard>

            {/* FILES */}
            <ContentCard cardTitle="Files">
              <ContentPresentedField
                onDeleteContent={onDeleteContent}
                isEditForm={!isReadOnly}
                meetingId={meetingORM?.model.id}
              />
            </ContentCard>

            {/* CRM AUTH */}
            <MeetingCRMAuth />

            {/* CUSTOM FIELDS */}
            <Iffy is={customFields.length}>
              <ContentCard cardTitle="Additional Meeting Information">
                <DNABox>
                  <ColumnFieldsWrapper customFields={customFields} />
                </DNABox>
              </ContentCard>
            </Iffy>
          </DNABox>
        </ScrollView>
        {deviceMode === 'desktop' && <AddMeetingFooter />}
      </DNABox>
    </DNABox>
  )
}

interface ContentCardProps {
  cardTitle: string
  variant?: ComposableVariant
}

export const ContentCard: React.FC<PropsWithChildren<ContentCardProps>> = (props) => {
  const { cardTitle, children, variant = ComposableVariant.DEFAULT } = props;
  const isVirtual = variant === ComposableVariant.VIRTUAL;

  return (
    <DNACard
      appearance="flat"
      style={[styles.contentCard, {
        backgroundColor: isVirtual ? colors['color-gray-700'] : colors['color-text-basic'],
      }]}
    >
      <DNAText
        bold
        status={isVirtual ? 'basic' : 'dark'}
        style={styles.cardTitle}
      >
        {cardTitle}
      </DNAText>
      {children}
    </DNACard>
  );
};

const AddMeeting: React.FC<AddMeetingProps> = (props) => {
  const { meetingId } = props
  const formConfig = useMeetingFormConfig(meetingId);

  if (formConfig.isLoading) {
    return <LoadingMeeting />;
  }

  return (
    <ComposableForm {...formConfig}>
      <AddMeetingProvider {...props}>
        <ContentWrapper meetingId={meetingId} formConfig={formConfig} />
      </AddMeetingProvider>
    </ComposableForm>
  )
}

interface WrapperProps {
  meetingId?: string;
  formConfig: MeetingFormConfig;
}

const ContentWrapper: React.FC<WrapperProps> = (props) => {
  const { meetingId, formConfig } = props;
  const { crmSubmitState } = useAddMeeting();
  const [displaySuccessfulSave, isSubmittingToCRM] = useMemo(() => [
    crmSubmitState === CRM_SUBMIT_STATE.SUCCESS,
    crmSubmitState === CRM_SUBMIT_STATE.SUBMITTING,
  ], [crmSubmitState]);
  const loadingMessage =
    !meetingId ? 'Creating meeting...'
      : isSubmittingToCRM ? 'Saving to CRM...' : undefined;

  return (
    <>
      <Iffy is={meetingId && !isSubmittingToCRM}>
        <CustomForm
          displaySuccessfulSave={displaySuccessfulSave}
          meetingORM={formConfig.meetingORM}
          customFields={formConfig.customFields}
        />
      </Iffy>
      <Iffy is={!meetingId || isSubmittingToCRM}>
        <LoadingMeeting loadingText={loadingMessage} />
      </Iffy>
    </>
  );
};

export default AddMeeting
