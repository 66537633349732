import React from 'react'
import { CustomDeckORM, FeatureFlags, FolderItemORM } from 'src/types/types'
import {
  useDNACustomDeckActions,
  customDeckContextOptions,
  CustomDeckActions,
} from './DNACustomDeck.actions'
import { DNAContextMenu } from '@alucio/lux-ui'
import { useAppSettings } from 'src/state/context/AppSettings'
import useCurrentPage from '../hooks/useCurrentPage'
import { useFeatureFlag } from 'src/hooks/useFeatureFlag/useFeatureFlag'

export interface DNACustomDeckContextMenuProps {
  folderItemORM: FolderItemORM,
  children: React.ReactElement,
  actions?: CustomDeckActions[],
  unavailableContent?: boolean,
}

export const DNACustomDeckContextMenu: React.FC<DNACustomDeckContextMenuProps> = (props) => {
  const {
    folderItemORM,
    actions,
    children,
  } = props

  const route = useCurrentPage({ exact: false })
  const isSharedFolders = route?.PATH.includes('shared_folders');
  const enableCollaborationEdit = useFeatureFlag(FeatureFlags.BEAC_2818_custom_deck_collaboration)
  const { deviceMode, isOnline } = useAppSettings()
  const DNACustomDeckActions = useDNACustomDeckActions()
  /** TODO: Refactor improper use of as assertion */
  const customDeckORM = folderItemORM.relations.itemORM as CustomDeckORM
  const actionsToUse: CustomDeckActions[] = []
  const isDesktop = deviceMode === 'desktop'
  const isOfllineAndSharedFolders = !isOnline && isSharedFolders

  const isReviewRequired: boolean = customDeckORM.meta.version.requiresReview;

  const isOfflineSynced = (!isOnline && customDeckORM.meta.assets.isContentCached)

  const rootFolderORM =
    folderItemORM.relations.parentORM?.relations.parentFolderORM ?? folderItemORM.relations.parentORM;
  const hasEditPermission = rootFolderORM?.meta.permission === 'EDIT';

  // Button permissions
  const canEdit = !isSharedFolders
    ? !isReviewRequired && (isOnline || isOfflineSynced)
    : !isOfllineAndSharedFolders && enableCollaborationEdit && hasEditPermission

  const canDelete = !isSharedFolders
  const canAddToFolder = !isSharedFolders ? !isReviewRequired : false

  const canDuplicate = !isSharedFolders
    ? !isReviewRequired
    : false

  const canPresent = !isSharedFolders
    ? !isReviewRequired && (isOnline || isOfflineSynced)
    // [TODO] - Add check for external dependencies
    : !isReviewRequired && (isOnline || isOfflineSynced)

  const canDownloadAsPdf = isOnline && !isReviewRequired

  // display the text in the dropdown
  canPresent && actionsToUse.push('present')
  canDuplicate && actionsToUse.push('duplicate')
  canEdit && actionsToUse.push('edit')
  canDelete && actionsToUse.push('delete')
  canAddToFolder && actionsToUse.push('addToFolder')
  canDownloadAsPdf && actionsToUse.push('downloadAsPDF')

  const MenuItems = actions?.length || actionsToUse.length
    ? (actions ?? actionsToUse)
      .map(action => {
        const { onPress, ...rest } = customDeckContextOptions[action]
        return (
          <DNAContextMenu.Item
            disabled={ actions ? !actions.length : !actionsToUse.length}
            key={action}
            status={isDesktop ? 'neutral' : 'dark'}
            onPress={DNACustomDeckActions[action](customDeckORM, folderItemORM)}
            {...rest}
          />
        )
      })
    : (<DNAContextMenu.Item
        title="(No actions available)"
        onPress={() => {}}
    />)

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        { children }
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        { MenuItems }
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

export default DNACustomDeckContextMenu
