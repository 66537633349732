import React, { useState, useEffect } from 'react'
import { Pressable, StyleSheet } from 'react-native';
import {
  Collapsible,
  DNABox,
  DNAText,
  luxColors,
  DNAButton,
  DNAIcon,
  Iffy,
} from '@alucio/lux-ui'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'

import { Controller, useFieldArray, useFormContext, Control } from 'react-hook-form'

import { FieldDataType, IntegrationSettings, IntegrationFieldMapping } from '@alucio/aws-beacon-amplify/src/models';

import SelectInputComponent from 'src/components/Publishers/SelectInputComponent'
import InputComponent from 'src/components/Publishers/InputComponent'

import { useIntegrationContext } from './Provider'
import { BeaconAttribute, BeaconMappingValue, valueMap } from './index'
import VeevaIntegration from './Forms/VeevaIntegration';
import BeaconIntegration from './Forms/BeaconIntegration';
import camelCase from 'lodash/camelCase';

const styles = StyleSheet.create({
  integrationTypeContainer: {
    marginTop: 32,
  },
  fieldMappingContainer: {
    backgroundColor: luxColors.backgroundColor.secondary,
  },
  fieldMappingHeader: {
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: colors['color-gray-80'],
  },
  fieldMappingHeaderInner: {
    marginLeft: 48,
  },
  fieldMappingTitle :{
    marginBottom: 10,
  },
  fieldMappingRowContainer: {
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
    padding: 8,
  },
  fieldMappingDropdown: {
    marginHorizontal: 5,
    paddingLeft: 16,
    paddingRight: 5,
  },
  fieldMappingInput: {
    marginHorizontal: 5,
    paddingRight: 10,
  },
  fieldMappingAddBtn: {
    marginLeft: 40,
    marginBottom: 10,
    marginTop: 5,
  },
})

type FieldMappingType = {
  [key: string]: {
    valueMappings: valueMap[]
  }
}

interface FieldMapRowProps {
  beaconAttribute: BeaconAttribute,
  control: Control<Record<string, any>>,
  name: string,
  reset: any,
  selectedFieldMap?: FieldMappingType,
}

interface IntegrationFormType {
  beaconMappingValues: BeaconMappingValue,
  selectedFieldMap?: FieldMappingType,
  selectedIntegration?: IntegrationSettings,
}

const FieldMapRow = (props: FieldMapRowProps) => {
  const { control, beaconAttribute, name } = props

  const { fields, append, remove } = useFieldArray({
    control,
    name: beaconAttribute.fieldValues
      ? `fieldMapping.${beaconAttribute.name}.valueMappings`
      : beaconAttribute.name,
  })

  const lowerCaseKey = beaconAttribute.name

  const [collapsed, setCollapsed] = useState(true);
  const toggleCollapsed = () => setCollapsed(!collapsed);
  return (
    <DNABox appearance="col" style={styles.fieldMappingRowContainer}>
      <DNABox appearance="row" style={{ marginBottom: 12 }}>
        <DNABox fill alignY="center" style={{ marginRight: 32 }}>
          {[FieldDataType.CATEGORICAL.toString(), FieldDataType.MULTICATEGORICAL.toString()]
            .includes(beaconAttribute.dataType) &&
              <Pressable onPress={toggleCollapsed} style={{ padding: 10 }}>
                <DNAIcon.Styled
                  appearance="ghost"
                  status="gray"
                  name={collapsed ? 'chevron-right' : 'chevron-down'}
                  size="md"
                />
              </Pressable>
          }
          <DNABox
            fill
            appearance="col"
            spacing="md"
            style={[FieldDataType.CATEGORICAL.toString(), FieldDataType.MULTICATEGORICAL.toString()]
              .includes(beaconAttribute.dataType) ? {} : { paddingLeft: 40 }}
          >
            <Controller
              control={control}
              name={`fieldMapping.${name}.srcFieldname`}
              as={InputComponent}
              removeMarginPadding
              required={false}
            />
          </DNABox>
        </DNABox>
        <DNABox fill alignY="center">
          <DNAText>{beaconAttribute.label} {beaconAttribute.isRequired ? '*' : '' }</DNAText>
        </DNABox>
      </DNABox>
      <Collapsible collapsed={collapsed}>
        {fields.map(({ id }, index) => {
          return (
            <DNABox key={id} alignY="center" style={{ paddingLeft: 40 }}>
              <Controller
                as={InputComponent}
                control={control}
                name={`fieldMapping.${lowerCaseKey}.valueMappings[${index}].srcValue`}
                hideLabel
                placeHolder=""
                placeholderTextColor={luxColors.contentText.quaternary}
                inputStyle={styles.fieldMappingInput}
                titleColor={luxColors.contentText.tertiary}
                removeMarginPadding
                required
              />
              <Controller
                control={control}
                name={`fieldMapping.${lowerCaseKey}.valueMappings[${index}].targetValue`}
                render={props => (
                  <SelectInputComponent
                    title=""
                    hideLabel
                    multiSelect={false}
                    availableValues={beaconAttribute.fieldValues}
                    descriptionText=""
                    style={styles.fieldMappingDropdown}
                    removeMarginPadding
                    onChangeSelection={(value: string | string[], field?: any) => {
                      const singleValue =  Array.isArray(value) ? value[0] : value
                      props.onChange(singleValue, field)
                    }}
                    value={props.value}
                  />
                )}
                hideLabel
                multiSelect={false}
                availableValues={beaconAttribute.fieldValues}
                descriptionText=""
                removeMarginPadding
              />
              <Pressable onPress={() => remove(index)}>
                <DNAIcon.Styled
                  appearance="ghost"
                  status="dark"
                  name="trash-can-outline"
                  size="md"
                />
              </Pressable>
            </DNABox>
          )
        })}
        <DNAButton
          status="secondary"
          appearance="outline"
          onPress={() => append({})}
          style={styles.fieldMappingAddBtn}
        >
          Add
        </DNAButton>
      </Collapsible>
    </DNABox>
  )
}

const INTEGRATION_TYPES = ['VEEVA', 'BEACON'];

interface clientConfigDataType {
  key: string
  value: string
}

export const convertClientConfigToFormValues = (clientConfig: clientConfigDataType[]) => {
  return clientConfig?.reduce((prevVal, currentVal) => {
    prevVal[currentVal.key] = currentVal.value
    return prevVal
  }, {})
}

export const convertMappingsToFormValues = (mapping: IntegrationFieldMapping[]) => {
  return mapping?.reduce((prevVal, currentVal) => {
    if (!currentVal) return currentVal;
    prevVal[camelCase(currentVal.targetFieldName)] = {
      srcFieldname: currentVal.srcFieldname,
      valueMappings: currentVal.valueMappings || [],
    }
    return prevVal
  }, {})
}

const IntegrationForm = (props: IntegrationFormType) => {
  const { beaconMappingValues, selectedIntegration } = props
  const { control, formState: { errors }, reset } = useFormContext()
  const { handleSetIntegrationType, integrationType } = useIntegrationContext()

  const convertMappingToFormFieldMappingFormat = (fieldMappingData) => {
    return fieldMappingData.reduce((prevVal, currentVal) => {
      if (currentVal.valueMappings) {
        prevVal[currentVal.targetFieldName] = {
          srcFieldname: currentVal.srcFieldname,
          valueMappings: currentVal.valueMappings,
        }
      } else {
        prevVal[currentVal.targetFieldName] = {
          srcFieldname: currentVal.srcFieldname,
        }
      }
      return prevVal
    }, {})
  }

  useEffect(() => {
    if (selectedIntegration) {
      handleSetIntegrationType(selectedIntegration.integrationType!)

      const { clientConfigurationFields, mapping } = selectedIntegration

      // @ts-ignore TODO investigate the correct way to address the clientConfigurationTuple
      const convertedConfigValues = convertClientConfigToFormValues(clientConfigurationFields)

      // @ts-ignore // for some reason checking if mapping is null does not fix the typescript error
      const convertedMappings = convertMappingsToFormValues(mapping)

      reset({
        name: selectedIntegration.name,
        integrationType: selectedIntegration.integrationType,
        notificationEmail: selectedIntegration.notificationEmail,
        errorSyncEmail: selectedIntegration.errorSyncEmail,
        shouldSync: selectedIntegration.enabled,
        clientConfigurationFields: convertedConfigValues,
        fieldMapping: convertedMappings,
      })
    }
  }, [reset])

  const formattedMapping =
    selectedIntegration?.mapping
      ? convertMappingToFormFieldMappingFormat(selectedIntegration.mapping)
      // Check if we need this for create, maybe we can remove it
      : {}

  return (
    <DNABox appearance="col" fill spacing="lg">
      <DNABox appearance="col" fill spacing="md" style={styles.integrationTypeContainer}>
        <DNAText h4>Integration Type</DNAText>
        <DNABox spacing="md" appearance="col">
          <DNABox>
            <Controller
              name="integrationType"
              control={control}
              rules={{ required: 'This field is required' }}
              render={props => (
                <SelectInputComponent
                  title=""
                  required={false}
                  removeMarginPadding
                  style={errors.integrationType && { borderColor: luxColors.error.primary }}
                  availableValues={INTEGRATION_TYPES}
                  onChangeSelection={value => {
                    const singleValue =  Array.isArray(value) ? value[0] : value
                    props.onChange(value)
                    handleSetIntegrationType(singleValue.toString())
                  }}
                  disabled={!!selectedIntegration}
                  value={props.value}
                />
              )}
            />
            {
            errors.integrationType &&
              <DNAText style={{ color: luxColors.error.primary, paddingLeft: 25 }}>
                {errors.integrationType.message}
              </DNAText>
          }
          </DNABox>
          {selectedIntegration && <DNAText status="subtle" h5>Integration ID: { selectedIntegration?.id }</DNAText>}
        </DNABox>
      </DNABox>
      <DNABox appearance="col" fill spacing="md">
        <DNAText h4>Settings</DNAText>
        <Iffy is={!integrationType}>
          <DNABox>
            <DNAText>Please select an integration type</DNAText>
          </DNABox>
        </Iffy>
        <Iffy is={integrationType === 'VEEVA'}>
          <VeevaIntegration />
        </Iffy>
        <Iffy is={integrationType === 'BEACON'}>
          <BeaconIntegration />
        </Iffy>
      </DNABox>
      <DNABox appearance="col" spacing="sm" fill>
        <DNAText h4>Notifications</DNAText>
        <DNAText status="subtle">
          Get email alerts when your content syncs. You can enter multiple emails separated by commas.
        </DNAText>
        <DNABox>
          <Controller
            as={InputComponent}
            name="notificationEmail"
            placeHolder="Email, comma separated"
            title="Email (All content sync)"
            control={control}
            removeMarginPadding
            required={false}
          />
        </DNABox>
        <DNABox>
          <Controller
            as={InputComponent}
            name="errorSyncEmail"
            title="Email (Sync errors)"
            placeHolder="Email, comma separated"
            control={control}
            removeMarginPadding
            required={false}
          />
        </DNABox>
      </DNABox>
      <Iffy is={integrationType}>
        <DNABox appearance="col">
          <DNABox spacing="sm" appearance="col" style={styles.fieldMappingTitle}>
            <DNAText h4>Field Mapping</DNAText>
            <DNAText status="subtle">
              * Indicates the attribute is a required field when publishing a file within Beacon
            </DNAText>
          </DNABox>
          <DNABox appearance="col" style={styles.fieldMappingContainer} >
            <DNABox fill spacing="around" style={styles.fieldMappingHeader}>
              <DNABox style={styles.fieldMappingHeaderInner} childFill>
                <DNAText status="flatAlt" c1>Import Attribute</DNAText>
              </DNABox>
              <DNABox style={styles.fieldMappingHeaderInner} childFill>
                <DNAText status="flatAlt" c1>Beacon Attribute</DNAText>
              </DNABox>
            </DNABox>
            {formattedMapping && Object.keys(beaconMappingValues)
              .sort((a, b) => a.localeCompare(b))
              .map(beaconAttributeKey => {
                return (
                  <FieldMapRow
                    selectedFieldMap={formattedMapping[beaconAttributeKey]}
                    key={beaconAttributeKey}
                    name={beaconAttributeKey}
                    control={control}
                    reset={reset}
                    beaconAttribute={beaconMappingValues[beaconAttributeKey]}
                  />
                )
              })}
          </DNABox>
        </DNABox>
      </Iffy>
    </DNABox>
  )
}

export default IntegrationForm
