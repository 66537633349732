import React, { useMemo, useState, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAText, Iffy } from '@alucio/lux-ui/src';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { v4 as uuid } from 'uuid';
import {
  AssociatedFileType,
  HubSectionType,
  HubSharedFileContentType,
  SharedFileHubSetting,
  SharedFileStatus,
  HubSharedAssociatedFile,
  DocumentStatus,
} from '@alucio/aws-beacon-amplify/src/models';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import { AssociatedFileORM, isDocumentVersionORM } from 'src/types/types'
import { WidgetContentCardProps, widgetContentCardStyles, WidgetContextMenu } from 'src/screens/Hubs/EditHub/Widgets/WidgetContentCard';
import ContentSearchBar, { ContentSearchResult } from 'src/components/ContentSearchBar/ContentSearchBar';
import SharedFilesDocumentGroup from 'src/screens/Hubs/EditHub/Widgets/SharedFilesComponents/SharedFilesDocumentGroup';
import { HubFormWidgetKeys } from '../useHubRHFormStateProvider';

export interface RowProps {
  isLastItem: boolean
  handleDeleteItem: (contentId: string, associatedFileDetails?: AssociatedFileDetails) => void
}

export interface AssociatedFileDetails extends HubSharedAssociatedFile {
  isRequired?: boolean
  parentId: string
  associatedFileORM: AssociatedFileORM
}

export const styles = StyleSheet.create({
  emptyStateTextBox: {
    marginTop: 24,
  },
  documentGroupsContainer: {
    borderColor: colors['color-gray-80'],
    borderWidth: 1,
  },
  associatedFilesGroupContainer: {
    paddingLeft: 24,
    paddingBottom: 24,
  },
  associatedFilesGroupBorderBox: {
    borderColor: colors['color-gray-80'],
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
  },
  bottomBorder: {
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
  },
  associatedFilesGroupHeader: {
    paddingHorizontal: 16,
    paddingVertical: 4,
    backgroundColor: colors['color-gray-10'],
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
  },
  row: {
    height: 113,
    padding: 24,
  },
  rowHovered: {
    backgroundColor: colors['color-gray-10'],
  },
  documentThumbnailBorder: {
    borderColor: colors['color-gray-80'],
    borderWidth: 1,
    marginRight: 16,
  },
  attachedFileIcon: {
    height: 30,
    color: colors['color-gray-300']
  },
  attachedFileThumbnailBox: {
    minWidth: 120,
    minHeight: 65,
    backgroundColor: colors['color-gray-10'],
  },
});

const SharedFiles: React.FC<WidgetContentCardProps> = ({ widget, rhform }) => {
  const { hubORM } = useHubsState()
  const [isSearchBarVisible, setIsSearchBarVisible] = useState<boolean>(false);
  const [sharedFiles, setSharedFiles] = useState<SharedFileHubSetting[]>(widget.sharedFiles || [])

  useEffect(() => {
    /* 
    upon widgets list getting updated (added or deleted new widgets),
    we need to set the form value to the current unsave values within the widget.
    or else the value in widgets will reset to the previous saved value when user add or delete other widgets.
    we also compairs the current value with the previous value to determine if the form is dirty.
    */
    if (!hubORM) return 
    const lastSavedSharedFilesWidget = hubORM.model.hubSections?.find(section => section.type === HubSectionType.SHARED_FILES)
    let shouldDirty
    const basicFieldKeys = ['order', 'type', 'visible']
    if (lastSavedSharedFilesWidget) {
      Object.keys(lastSavedSharedFilesWidget).forEach(key => {
        if (key === HubFormWidgetKeys.sharedFiles) {
          lastSavedSharedFilesWidget.sharedFiles?.forEach((sharedFile, index) => {
            const sharedFilesArray = widget.sharedFiles || []
            if (sharedFilesArray[index]?.id !== sharedFile.id) shouldDirty = true
          })
        } else if (basicFieldKeys.includes(key) && lastSavedSharedFilesWidget[key] !== widget[key]) shouldDirty = true
      })
    } else shouldDirty = true
    const { order, sharedFiles, type, visible } = widget
     // typescript work around - convert all value into undefined 
    const modifiedSharedFiles = sharedFiles?.map(sharedFile => {
      const associatedFiles = sharedFile.documentVersionSettings?.associatedFiles?.map(associatedFile => {
        return {...associatedFile, versionId: associatedFile.versionId === null ? undefined : associatedFile.versionId}
      })
      return {...sharedFile, documentVersionSettings: associatedFiles ? {associatedFiles} : undefined}
    })
    const payload = {
      order,
      sharedFiles: sharedFiles ? modifiedSharedFiles : undefined,
      type,
      visible,
    }
    rhform.setValue('sharedFilesWidget', payload, { shouldDirty })
    setSharedFiles(payload.sharedFiles || [])
  }, [hubORM, widget])

  const selectedIds = useMemo(() => {
    return sharedFiles
      .filter(sharedFile => sharedFile.status === SharedFileStatus.ACTIVE)
      .map(sharedFile => sharedFile.contentId)
  }, [sharedFiles])

  const allUserSelectedSharedFiles = useMemo(() => sharedFiles
    .filter(sharedFile => sharedFile.status === SharedFileStatus.ACTIVE)
    .sort((a, b) => a.title.localeCompare(b.title, 'en', { numeric: true })
  ), [sharedFiles])

  const handleShowSearchBar = () => {
    setIsSearchBarVisible(true);
  }

  const handleSelectItem = useMemo(() => (item: ContentSearchResult) => {
    const existedDoc = sharedFiles.find(sharedFile => sharedFile.contentId === item.id)
    const docVerORM = item.orm
    if (isDocumentVersionORM(docVerORM) && !existedDoc) {
      const associatedFiles = docVerORM.relations.associatedFiles
        .filter(associatedFile => {
          return (
              associatedFile.model.type === AssociatedFileType.ATTACHED_FILE &&
              associatedFile.meta.canBeSharedByMSL
            ) ||
            (
              associatedFile.model.type === AssociatedFileType.DOCUMENT &&
              associatedFile.meta.canBeSharedByMSL &&
              associatedFile.file?.['status'] === DocumentStatus.PUBLISHED
            )
        })
        .map(associatedFile => {
          let versionId: string | undefined
          if (associatedFile.model.type === AssociatedFileType.DOCUMENT) {
            versionId = associatedFile.relations.latestUsableDocumentVersion?.id ?? undefined
          }
          return {
            associatedFileId: associatedFile.model.id,
            versionId,
          }
        })

      const newItem: SharedFileHubSetting = {
        id: uuid(),
        contentId: item.id,
        title: item.title,
        contentType: HubSharedFileContentType.DOCUMENT_VERSION,
        documentVersionSettings: associatedFiles.length ? { associatedFiles } : undefined,
        status: SharedFileStatus.ACTIVE,
      }

      setSharedFiles(pre => {
        const newSharedFiles = [...pre, newItem]
        rhform.setValue('sharedFilesWidget',
          { ...widget, sharedFiles: newSharedFiles },
          { shouldDirty: true })
        return newSharedFiles
      })

    }
  }, [sharedFiles, setSharedFiles])

  const handleDeleteItem = useMemo(() => (contentId: string, associatedContent?: AssociatedFileDetails) => {
    setSharedFiles(pre => {
      const sharedFilesCopy = [...pre]
  
      if (associatedContent) {
        const parentIndex = sharedFilesCopy.findIndex(sharedFile => sharedFile.id === associatedContent.parentId)
        const partent = sharedFilesCopy[parentIndex]
        if (parentIndex !== -1) {
          const previousAssociatedFiles = partent.documentVersionSettings?.associatedFiles?.slice() || []
          const targetIndex = previousAssociatedFiles.findIndex(hubSharedAssociatedFile => {
            return hubSharedAssociatedFile.associatedFileId === associatedContent.associatedFileId
          })
          if (targetIndex !== -1) {
            const newAssociatedFiles = [...previousAssociatedFiles]
            newAssociatedFiles.splice(targetIndex, 1)
            sharedFilesCopy.splice(parentIndex, 1, {
              ...sharedFilesCopy[parentIndex],
              documentVersionSettings: {
                associatedFiles: newAssociatedFiles,
              },
            })
            rhform.setValue('sharedFilesWidget',
              { ...widget, sharedFiles: sharedFilesCopy },
              { shouldDirty: true })
            return sharedFilesCopy
          } else return pre
        } else return pre
      } else {
        const targetIndex = sharedFilesCopy.findIndex(sharedFile => sharedFile.contentId === contentId)
        if (targetIndex !== -1) {
          sharedFilesCopy.splice(targetIndex, 1)
          rhform.setValue('sharedFilesWidget',
            { ...widget, sharedFiles: sharedFilesCopy },
            { shouldDirty: true })
          return sharedFilesCopy
        } else return pre
      }
    }) 
  }, [sharedFiles])

  if (widget.type !== HubSectionType.SHARED_FILES) return null
  return (       
    <>
      {/* Card Header */}
      <DNABox fill style={ widgetContentCardStyles.cardHeader }>
        <DNABox
          fill
          alignY="center"
          spacing="between"
          childFill={0}
        >
          <DNABox fill childFill={0} alignY="center" spacing="sm">
            <DNAText h4 bold numberOfLines={1}>Shared Files</DNAText>
          </DNABox>
          <DNABox alignY="center" spacing="sm">
            <DNAButton
              iconLeft="plus"
              appearance="outline"
              status="tertiary"
              onPress={handleShowSearchBar}
            >
              Add file
            </DNAButton>
            <WidgetContextMenu widgetType={HubSectionType.SHARED_FILES} />
          </DNABox>
        </DNABox>
      </DNABox>
      {/* Card Content */}
      <DNABox fill appearance="col" alignY="center" style={ widgetContentCardStyles.cardBody }>
        <ContentSearchBar
          onSelectItem={handleSelectItem}
          selectedIds={selectedIds}
          variant="hubs"
          isVisible={isSearchBarVisible}
          setIsVisible={setIsSearchBarVisible}
        />
        <DNABox fill appearance="col" alignY="center" style={isSearchBarVisible ? styles.emptyStateTextBox : undefined}>
          <Iffy is={!selectedIds.length}>
            <DNAText status="flatAlt">Add files to see them appear here</DNAText>
          </Iffy>
          <Iffy is={allUserSelectedSharedFiles.length}>
            <DNABox fill appearance="col" style={styles.documentGroupsContainer}>
              {allUserSelectedSharedFiles.map((sharedFile, index) => (
                <SharedFilesDocumentGroup
                  key={sharedFile.id}
                  sharedFile={sharedFile}
                  isLastItem={index === allUserSelectedSharedFiles.length - 1}
                  handleDeleteItem={handleDeleteItem}
                />
              ))}
            </DNABox>
          </Iffy>
        </DNABox>
      </DNABox>
    </>
  )
}

export default SharedFiles
