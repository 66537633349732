import { FilterAndSortOptions, multiTypeSort } from 'src/state/redux/selector/common';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { API, graphqlOperation } from '@aws-amplify/api'
import { KendraSearchQuery, TextWithHighlights } from '@alucio/aws-beacon-amplify/src/API'
import { kendraSearch, feedback } from '@alucio/aws-beacon-amplify/src/graphql/queries'
import { DocumentORM } from 'src/types/orms'
import {
  DocumentSearchOptions,
  useCanPerformSearch, useDocumentSearch,
} from 'src/state/redux/selector/documentSearch/documentSearch';
import { useAppSettings } from 'src/state/context/AppSettings'
import useFeatureFlag from './useFeatureFlag/useFeatureFlag';
import { FeatureFlags } from 'src/types/featureFlags';
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage';
import { merge } from 'src/state/redux/document/query'
import debounce from 'lodash/debounce'
import { useAllDocumentsInstance } from 'src/state/redux/selector/document';

type SearchStatus = 'IDLE' | 'PENDING' | 'RESULTS' | 'EMPTY_RESULTS' | 'FALLBACK';
type Mode =
  'HEADER' | 'VIEWER_RESULTS' | 'PUBLISHER_RESULTS' | 'FOLDER' | 'CUSTOM' | 'MEETING'

export type SearchContextValue = {
  query: FilterAndSortOptions<DocumentORM> | null | undefined
  documentIdSnippetMap: Map<string, documentSnippetType>
  fullTextSearch: ReturnType<typeof debounce<(searchText: string) => Promise<void>>>;
  searchText: string;
  status: SearchStatus
  resetSearch: () => void;
  mode?: Mode
  submitFeedback: (docId: string, tenantIndexId?: string) => void;
  isLoadingSearch: boolean;
}

const SearchContext = createContext<SearchContextValue>({
  query: null,
  documentIdSnippetMap: new Map(),
  fullTextSearch: debounce(async (_: string) => undefined, 500),
  searchText: '',
  status: 'IDLE',
  resetSearch: () => undefined,
  submitFeedback: () => undefined,
  isLoadingSearch: false,
})

interface ProviderProps {
  mode: Mode
  searchText?: string,
  children: React.ReactElement
}

export type documentSnippetType = {
  title: TextWithHighlights,
  documentExcerpt: TextWithHighlights
  resultId: string
  queryId: string
  index: number
}

const constructQuery = (idMap: Map<string, documentSnippetType>) => {
  const newFilterAndSortOptions: FilterAndSortOptions<DocumentORM> = {
    filter: {
      model: {
        id: (id) => idMap.has(id),
      },
    },
    sort: [
      {
        model: {
          id: (a, b) => {
            return multiTypeSort(idMap.get(a)?.index, idMap.get(b)?.index, { dir: 'asc' })
          },
        },
      },
    ],
  }
  return newFilterAndSortOptions
}

const currentPageQuery = { exact: false }
// todo: flexible filtering
const statusFilters = {
  PUBLISHER: ['PUBLISHED', 'NOT_PUBLISHED', 'ARCHIVED', 'REVOKED'],
  VIEWER: ['PUBLISHED'],
}

const recordSearchEvent = (mode: Mode) => {
  // adding this check since we don't want to record events as they type in the search dropdown
  // only when they hit enter
  if (mode !== 'HEADER') {
    analytics?.track('SEARCH_DOCUMENT_SEARCH', {
      category: 'SEARCH',
      action: 'DOCUMENT_SEARCH',
      context: mode,
    });
  }
}

const DocumentSearchProvider: React.FC<ProviderProps> = ({ mode, children }) => {
  const [query, setQuery] = useState<FilterAndSortOptions<DocumentORM> | null>(null);
  const [lunrText, setLunrText] = useState<string>('');
  const [documentIdSnippetMap, setDocumentIdSnippetMap] =
    useState<Map<string, documentSnippetType >>(new Map());
  const [localText, setLocalText] = useState('');
  const { isOnline } = useAppSettings();
  const canPerformSearch = useCanPerformSearch()
  const isKendraEnabled = useFeatureFlag(FeatureFlags.BEAC_3448_full_text_search);
  const route = useCurrentPage(currentPageQuery)
  const [status, setStatus] = useState<SearchStatus>('IDLE')

  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')

  const memoSearchOpts: DocumentSearchOptions = useMemo(() => {
    return {
      text: lunrText,
      isPublisher: isPublisherRoute,
    }
  }, [lunrText, isPublisherRoute])

  const lunrResults = useDocumentSearch(memoSearchOpts);

  const fullTextSearch = useCallback(
    async (searchText: string) => {
      setLocalText(searchText);
      if (isKendraEnabled && isOnline && canPerformSearch(searchText)) {
        setStatus('PENDING')
        recordSearchEvent(mode);

        try {
          const { data } = await API.graphql(graphqlOperation(kendraSearch, {
            queryText: searchText,
            filter: {
              statuses: statusFilters[isPublisherRoute ? 'PUBLISHER' : 'VIEWER'],
            },
          })) as { data: KendraSearchQuery }

          const documentResultMap: Map<string, documentSnippetType> = new Map();
          data.kendraSearch?.items?.forEach((curr, index) => {
            if (curr?.documentId) {
              const documentId = curr.documentId
              documentResultMap.set(documentId, {
                title: curr.title,
                documentExcerpt: curr.documentExcerpt,
                resultId: curr.resultId,
                queryId: curr.queryId,
                index: index,
              } );
            }
          });

          // fallback to lunr
          if (documentResultMap.size === 0) {
            setLunrText(searchText);
            setStatus('FALLBACK');
          }
          else {
            setDocumentIdSnippetMap(documentResultMap);
            setQuery(constructQuery(documentResultMap));
            setStatus('RESULTS');
          }
        }
        catch (err) {
          // fallback to lunr
          setLunrText(searchText);
          setStatus('FALLBACK');
        }
      }
      else if (canPerformSearch(searchText)) {
        recordSearchEvent(mode);
        setLunrText(searchText);
        setStatus('FALLBACK');
      }
    },
    [isKendraEnabled, isOnline, isPublisherRoute],
  );

  const debouncedSearch = useCallback(
    debounce(fullTextSearch, 500),
    [fullTextSearch],
  );

  useEffect(
    () => {
      if (status === 'FALLBACK') {
        const map = lunrResults.reduce((acc, curr) => {
          acc.set(curr.model.id, undefined);
          return acc;
        }, new Map());
        setDocumentIdSnippetMap(map);
        setQuery(constructQuery(map));

        if (map.size === 0) {
          analytics?.track('NO_RESULT', {
            category: 'SEARCH',
            action: 'NO_RESULT',
          });
          setStatus('EMPTY_RESULTS');
        }
        else {
          setStatus('RESULTS');
        }
      }
    },
    [lunrResults, status],
  )

  const resetSearch = () => {
    setQuery(null);
    setDocumentIdSnippetMap(new Map());
    setLunrText('');
    setLocalText('');
    setStatus('IDLE');
  }

  const submitFeedback = (docId: string, tenantIndexId?: string) => {
    analytics?.track('SEARCH_DOCUMENT_SELECT', {
      category: 'SEARCH',
      action: 'DOCUMENT_SEARCH_SELECT',
      context: mode,
    })
    if (documentIdSnippetMap.get(docId) && tenantIndexId) {
      const currentDoc = documentIdSnippetMap.get(docId)
      try {
        const feedbackResult = API.graphql(graphqlOperation(feedback, {
          indexId: tenantIndexId,
          resultId: currentDoc?.resultId,
          queryId: currentDoc?.queryId,
          relevancy: 'RELEVANT',
        }))
        return feedbackResult
      } catch (err) {
        // only logging the error here becuase if this api call breaks we dont want to interrupt the user flow
        console.error('error while submitting search feedback feedback', err)
      }
    }
  }

  return (
    <SearchContext.Provider
      value={{
        query,
        documentIdSnippetMap,
        fullTextSearch: debouncedSearch,
        searchText: localText,
        status,
        mode,
        resetSearch,
        submitFeedback,
        isLoadingSearch: !(status === 'EMPTY_RESULTS' || status === 'RESULTS'),
      }}
    >
      { children }
    </SearchContext.Provider>
  )
}

export const useDocumentSearchV2Context = () => useContext(SearchContext)

export const documentSearchV2Factory = (contextIn: typeof SearchContext) => (
  searchText?: string,
  ...queriesIn: FilterAndSortOptions<DocumentORM>[]
) => {
  const context = useContext(contextIn);

  useEffect(
    () => {
      if (searchText) {
        context.fullTextSearch(searchText)
      }
    },
    [searchText],
  )

  const searchQuery: FilterAndSortOptions<DocumentORM> | undefined = useMemo(
    () => merge(context.query, ...queriesIn),
    [context.query, ...queriesIn],
  )

  // [TODO-4140] - Possibly make this optional somehow or return an already memoized value if the consumer doesn't care about these results
  const docORMResults = useAllDocumentsInstance(searchQuery)

  const value = useMemo(
    () => ({
      ...context,
      searchQuery,
      documentORMSearchResults: docORMResults,
    }),
    [context, searchQuery, docORMResults],
  )

  return value
}

export const useDocumentSearchV2 = documentSearchV2Factory(SearchContext)

export default DocumentSearchProvider;
