import { CustomFieldDefinition } from '@alucio/aws-beacon-amplify/src/models';
import { SalesforceFormSettings } from '../CRMIndexedDBTypes';

export enum SUPPORTED_ZVODS {
    MEDICAL_DISCUSSION = 'zvod_Medical_Discussion_vod__c',
}

const zvodHandlers = {
  [SUPPORTED_ZVODS.MEDICAL_DISCUSSION]: handleMedicalDiscussionZvod,
};

export function handleZvod(
  zvodName: SUPPORTED_ZVODS,
  formSetting: SalesforceFormSettings[],
  processChild: (formSetting: SalesforceFormSettings) => CustomFieldDefinition[],
): CustomFieldDefinition[] {
  if (!Object.values(SUPPORTED_ZVODS).includes(zvodName)) {
    return [];
  }

  return zvodHandlers[zvodName](zvodName, formSetting, processChild);
}

function handleMedicalDiscussionZvod(
  zvodName: SUPPORTED_ZVODS,
  formSettings: SalesforceFormSettings[],
  processChild: (formSetting: SalesforceFormSettings) => CustomFieldDefinition[],
) {
  const formSetting = formSettings.find((setting) => setting.id === 'zvod_Medical_Discussion_vod__c');

  if (!formSetting) {
    return [];
  }

  return processChild(formSetting);
}
