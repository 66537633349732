import { useForm, UseFormMethods } from 'react-hook-form'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useHub } from 'src/state/redux/selector/hub';
import {
  HubSection,
  HubSectionType,
  SharedFileHubSetting,
  SharedFileStatus,
  HubSharedFileContentType,
  TodoStatus,
  ToDo,
} from '@alucio/aws-beacon-amplify/src/models';


// [TODO-DEBT] - Consider using https://github.com/fabien0102/ts-to-zod
//             - May automatically do the conversion from TS to Zod Schema for us
//               Since Zod only does Zod -> TS

const widgetTypeFormSchema = z.union([
  z.nativeEnum(HubSectionType),
  z.literal('TEXTAREA'),
  z.literal('SHARED_FILES'),
  z.literal('TODO_LIST')
])

const formSchema = z.object({
  name: z
    .string()
    .nonempty({ message: 'This field is required' }),
  textareaWidget: z
    .object({
      type: widgetTypeFormSchema,
      visible: z.boolean(),
      order: z.number().nonnegative(),
      textarea: z.object({
        header: z.string().optional(),
        content: z.string().optional(),
      }),
    })
    .optional(),
  sharedFilesWidget: z
    .object({
      type:widgetTypeFormSchema,
      visible: z.boolean(),
      order: z.number().nonnegative(),
      sharedFiles: z.array(z.object({
        id: z.string(),
        contentId: z.string(),
        title: z.string(),
        contentType: z.union([
          z.nativeEnum(HubSharedFileContentType),
          z.literal('DOCUMENT_VERSION'),
        ]),
        documentVersionSettings: z.object({
          associatedFiles: z.array(z.object({
            associatedFileId: z.string(),
            versionId: z.string().optional()
          })).optional()
        }).optional(),
        status: z.union([
          z.nativeEnum(SharedFileStatus),
          z.literal('ACTIVE'),
          z.literal('DELETED'),
        ])
      })).optional()
    })
    .optional(),
  todoListWidget: z
    .object({
      type: widgetTypeFormSchema,
      visible: z.boolean(),
      order: z.number().nonnegative(),
      toDos: z.array(z.object({
        todoId: z.string(),
        title: z.string().min(1, { message: 'This field is required ' }),
        resolution: z.string().optional(),
        status: z.union([
          z.nativeEnum(TodoStatus),
          z.literal('ACTIVE'),
          z.literal('COMPLETED'),
          z.literal('DELETED'),
        ]),
        order: z.number().nonnegative(),

      })).optional()
    })
    .optional(),
})

interface TextareaHubSection extends HubSection {
  textarea?: {
    header?: string,
    content?: string,
  },
}

type SharedFilesHubSection = HubSection & {
  sharedFiles?: SharedFileHubSetting[]
} | undefined

export type ToDoHubSection = HubSection & {
  toDos?: ToDo[]
} | undefined

interface useHubFormStateType {
  hubId: string
}

export type EhitHubRhform = UseFormMethods<{
  name: string,
  textareaWidget?: TextareaHubSection,
  sharedFilesWidget?: SharedFilesHubSection,
  todoListWidget?: ToDoHubSection,
}>

const useHubFormState = (props: useHubFormStateType): EhitHubRhform => {
  const { hubId } = props
  const hubORM = useHub(hubId)

  const textareaWidget = hubORM?.model.hubSections?.find(widget => widget.type === HubSectionType.TEXTAREA)
  const sharedFilesWidget = hubORM?.model.hubSections?.find(widget => widget.type === HubSectionType.SHARED_FILES)
  const todoListWidget = hubORM?.model.hubSections?.find(widget => widget.type === HubSectionType.TODO_LIST)
  const sharedFiles = sharedFilesWidget?.sharedFiles === null ? undefined : sharedFilesWidget?.sharedFiles
  // typescript work around - convert all value into undefined 
  const modifiedSharedFiles = sharedFiles?.map(sharedFile => {
    const associatedFiles = sharedFile.documentVersionSettings?.associatedFiles?.map(associatedFile => {
      return { ...associatedFile, versionId: associatedFile.versionId === null ? undefined : associatedFile.versionId }
    })
    return {...sharedFile, documentVersionSettings: associatedFiles ? {associatedFiles} : undefined}
  })

  const rhForm = useForm({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      name: hubORM?.model.name,
      textareaWidget,
      sharedFilesWidget: sharedFilesWidget ? { ...sharedFilesWidget, sharedFiles: modifiedSharedFiles } : undefined,
      todoListWidget
    },
  })

  return rhForm
}

export default useHubFormState
