import type { Draft } from 'immer'
import {
  Slice,
  CaseReducer,
  CreateSliceOptions,
  SliceCaseReducers,
  createAction,
  PrepareAction,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";

import { executeReducerBuilderCallback } from '@reduxjs/toolkit/src/mapBuilders'
import {
  ReducerWithInitialState,
  NotFunction,
  ReadonlyActionMatcherDescriptionCollection,
  CaseReducers
} from '@reduxjs/toolkit/src/createReducer'

/**
  ------------------------------------------------------------------
  The following is a Immer-less reimplementation of `createSlice`.
  Minimal modifications were made only to two areas
    - Freezing the initial state creator
    - Removing wrapping reducers in immer producer (draft) wrappers

  Types were not touched to maintain compatibility, but generally makes little to no-difference
  ------------------------------------------------------------------
*/

function getType(slice: string, actionKey: string): string {
  return `${slice}/${actionKey}`
}

function isStateFunction<S>(x: unknown): x is () => S {
  return typeof x === 'function'
}

export function createReducer<S extends NotFunction<any>>(
  initialState: S | (() => S),
  mapOrBuilderCallback:
    | CaseReducers<S, any>
    | ((builder: ActionReducerMapBuilder<S>) => void),
  actionMatchers: ReadonlyActionMatcherDescriptionCollection<S> = [],
  defaultCaseReducer?: CaseReducer<S>
): ReducerWithInitialState<S> {
  if (process.env.NODE_ENV !== 'production') {
    if (typeof mapOrBuilderCallback === 'object') {
      if (!hasWarnedAboutObjectNotation) {
        hasWarnedAboutObjectNotation = true
        console.warn(
          /* eslint-disable max-len */
          "The object notation for `createReducer` is deprecated, and will be removed in RTK 2.0. Please use the 'builder callback' notation instead: https://redux-toolkit.js.org/api/createReducer"
        )
      }
    }
  }

  const [actionsMap, finalActionMatchers, finalDefaultCaseReducer] =
    typeof mapOrBuilderCallback === 'function'
      ? executeReducerBuilderCallback(mapOrBuilderCallback)
      : [mapOrBuilderCallback, actionMatchers, defaultCaseReducer]

  // Ensure the initial state gets frozen either way (if draftable)
  let getInitialState: () => S
  
  if (isStateFunction(initialState)) {
    getInitialState = () => Object.freeze(initialState())
  } else {
    const frozenInitialState = Object.freeze(initialState)
    getInitialState = () => frozenInitialState
  }

  function reducer(state = getInitialState(), action: any): S {
    let caseReducers = [
      actionsMap[action.type],
      ...finalActionMatchers
        .filter(({ matcher }) => matcher(action))
        .map(({ reducer }) => reducer),
    ]
    if (caseReducers.filter((cr) => !!cr).length === 0) {
      caseReducers = [finalDefaultCaseReducer]
    }

    return caseReducers.reduce((previousState, caseReducer): S => {
      if (caseReducer) {
        if (previousState) {
          // If it's already a draft, we must already be inside a `createNextState` call,
          // likely because this is being wrapped in `createReducer`, `createSlice`, or nested
          // inside an existing draft. It's safe to just pass the draft to the mutator.
          const state = previousState as Draft<S> // We can assume this is already a draft
          const result = caseReducer(state, action)

          if (result === undefined) {
            return previousState
          }

          return result as S
        } else if (!previousState) {
          // If state is not draftable (ex: a primitive, such as 0), we want to directly
          // return the caseReducer func and not wrap it with produce.
          const result = caseReducer(previousState as any, action)

          if (result === undefined) {
            if (previousState === null) {
              return previousState
            }
            throw Error(
              'A case reducer on a non-draftable value must not return undefined'
            )
          }

          return result as S
        } else {
          // @ts-ignore createNextState() produces an Immutable<Draft<S>> rather
          // than an Immutable<S>, and TypeScript cannot find out how to reconcile
          // these two types.
          return caseReducer(state, action)
        }
      }

      return previousState
    }, state)
  }

  reducer.getInitialState = getInitialState

  return reducer as ReducerWithInitialState<S>
}

let hasWarnedAboutObjectNotation = false

/**
 * This is an Immerless version of @reduxjs/toolkit's `createSlice`
 * If using this, please remember to NOT MUTATE STATE IN REDUCERS
 */
function createSlice<
  State,
  CaseReducers extends SliceCaseReducers<State>,
  Name extends string = string
>(
  options: CreateSliceOptions<State, CaseReducers, Name>
): Slice<State, CaseReducers, Name> {
  const { name } = options
  if (!name) {
    throw new Error('`name` is a required option for createSlice')
  }

  if (
    typeof process !== 'undefined' &&
    process.env.NODE_ENV === 'development'
  ) {
    if (options.initialState === undefined) {
      console.error(
        'You must provide an `initialState` value that is not `undefined`. You may have misspelled `initialState`'
      )
    }
  }

  // Freeze the initial state (original uses an `immer` variant)
  const initialState = typeof options.initialState == 'function'
    ? options.initialState
    : Object.freeze(options.initialState)

  const reducers = options.reducers || {}

  const reducerNames = Object.keys(reducers)

  const sliceCaseReducersByName: Record<string, CaseReducer> = {}
  const sliceCaseReducersByType: Record<string, CaseReducer> = {}
  const actionCreators: Record<string, Function> = {}

  reducerNames.forEach((reducerName) => {
    const maybeReducerWithPrepare = reducers[reducerName]
    const type = getType(name, reducerName)

    let caseReducer: CaseReducer<State, any>
    let prepareCallback: PrepareAction<any> | undefined

    if ('reducer' in maybeReducerWithPrepare) {
      caseReducer = maybeReducerWithPrepare.reducer
      prepareCallback = maybeReducerWithPrepare.prepare
    } else {
      caseReducer = maybeReducerWithPrepare
    }

    sliceCaseReducersByName[reducerName] = caseReducer
    sliceCaseReducersByType[type] = caseReducer
    actionCreators[reducerName] = prepareCallback
      ? createAction(type, prepareCallback)
      : createAction(type)
  })

  function buildReducer() {
    if (process.env.NODE_ENV !== 'production') {
      if (typeof options.extraReducers === 'object') {
        if (!hasWarnedAboutObjectNotation) {
          hasWarnedAboutObjectNotation = true
          console.warn(
            /* eslint-disable max-len */
            "The object notation for `createSlice.extraReducers` is deprecated, and will be removed in RTK 2.0. Please use the 'builder callback' notation instead: https://redux-toolkit.js.org/api/createSlice"
          )
        }
      }
    }

    const [
      extraReducers = {},
      actionMatchers = [],
      defaultCaseReducer = undefined,
    ] =
      typeof options.extraReducers === 'function'
        ? executeReducerBuilderCallback(options.extraReducers)
        : [options.extraReducers]

    const finalCaseReducers = { ...extraReducers, ...sliceCaseReducersByType }

    // [TODO] - Reimplement this
    return createReducer(initialState, (builder) => {
      for (const key in finalCaseReducers) {
        builder.addCase(key, finalCaseReducers[key] as CaseReducer<any>)
      }
      for (const m of actionMatchers) {
        builder.addMatcher(m.matcher, m.reducer)
      }
      if (defaultCaseReducer) {
        builder.addDefaultCase(defaultCaseReducer)
      }
    })
  }

  let _reducer: ReducerWithInitialState<State>

  return {
    name,
    reducer(state, action) {
      if (!_reducer) _reducer = buildReducer()

      return _reducer(state, action)
    },
    actions: actionCreators as any,
    caseReducers: sliceCaseReducersByName as any,
    getInitialState() {
      if (!_reducer) _reducer = buildReducer()

      return _reducer.getInitialState()
    },
  }
}

export default createSlice
