import { DNABox, DNAButton, DNAChip, DNADivider, DNAText, Iffy, Hoverable } from '@alucio/lux-ui'
import { ImageBackground, Pressable, StyleSheet } from 'react-native';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import React, { useState } from 'react'
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import { isDocumentVersionORM, isFolderItemORM, isPageGroupORM } from 'src/types/typeguards';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import { useMediaQuery } from 'react-responsive';
import { thumbnailSizeDimensions } from 'src/hooks/useThumbnailSize/useThumbnailSize';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList';

/** ANIMATION IMPORTS */
import LottieAnimation from 'react-lottie-player'
const PopupHiddenAnimation = require('src/assets/lottie/shared-window-visibility-animation.json')

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors['color-black'],
    maxWidth: thumbnailSizeDimensions.xl.width,
    minWidth: thumbnailSizeDimensions.lg.width,
    width: '30%',
    borderLeftWidth: 1,
    borderColor: '#383838',
  },
  header: {
    paddingHorizontal: 24,
    paddingVertical: 12,
    maxHeight: 56,
    minHeight: 56,
    justifyContent: 'space-between',
    backgroundColor: colors['color-success-500'],
  },
  // TODO remove style in button
  expandButton: {
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
  },
  overlay: {
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.48)',
  },
});

const CurrentlyPresentingPanel = () => {
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1665px)',
  })

  const {
    width: placeholderImageWidth,
    height: placeholderImageHeight,
  } = isLargeScreen
    ? thumbnailSizeDimensions.xl
    : thumbnailSizeDimensions.lg

  const { setCurrentTab, checkFormDiscard, popoutContentHidden } = useMeetingsState()
  const { activePresentation } = useContent();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const presentable = activePresentation?.presentable;
  const currentPageNumber = activePresentation?.currentPresentablePage.presentationPageNumber;

  const s3URL = activePresentation
    ? detectArchivedFileKeyPath(
      activePresentation?.currentPresentablePage.documentVersionORM?.model,
      activePresentation.currentPresentablePage.page,
    )
    : ''

  const isDocumentVersion =
    !isPageGroupORM(activePresentation?.presentable.orm) &&
    (isDocumentVersionORM(activePresentation?.presentable.orm) ||
    isDocumentVersionORM(activePresentation?.presentable.orm.relations.itemORM));

  const isModifiedDocumentVersion = isFolderItemORM(activePresentation?.presentable.orm) && isDocumentVersion &&
    activePresentation?.presentable.orm.model.visiblePages?.length;

  function toggleHover(): void {
    setIsHovered(!isHovered);
  }

  function handleExpand(): void {
    // BEFORE CLOSING, CHECKS THE MEETING'S FORM DOESN'T HAVE UNSAVED DATA
    checkFormDiscard(() => {
      setIsHovered(false);
      setCurrentTab('PRESENTING')
    });
  }

  return (

    <DNABox style={styles.container} appearance="col">
      <DNABox appearance="row" alignY="center" style={styles.header} fill>
        <DNAText h5 bold status="basic">Currently Presenting</DNAText>
        <DNAButton
          appearance="filled"
          size="sm"
          iconLeft="arrow-expand"
          style={styles.expandButton}
          iconStyle={{ paddingHorizontal: 12 }}
          onPress={handleExpand}
        >
          Expand
        </DNAButton>
      </DNABox>
      <Iffy is={!activePresentation}>
        <DNABox
          fill
          alignX="center"
          style={{ maxHeight: placeholderImageHeight }}
        >
          <DNABox
            style={{
              width:placeholderImageWidth,
              height:placeholderImageHeight,
            }}
          >
            <ImageBackground
              source={require('../../../../../../../applications/beacon/assets/images/beacon-presentation-bg.jpg')}
              style={{ flex:1 }}
            />
          </DNABox>
        </DNABox>
      </Iffy>
      <Iffy is={activePresentation}>

        {/* NOTE: can't use spacing prop here because it breaks support for long filenames */}
        <DNABox
          fill
          appearance="row"
          alignY="center"
          style={{ paddingHorizontal: 16, paddingVertical: 12, maxHeight:56, minHeight: 56 }}
        >
          <DNABox spacing="sm" style={{ marginRight:12 }}>
            <CustomFieldBadgeList documentVersionORM={activePresentation?.currentPresentablePage.documentVersionORM} />
            <Iffy is={isModifiedDocumentVersion || !isDocumentVersion}>
              <DNABox>
                <DNAChip
                  appearance="tag"
                  style={{ backgroundColor: colors['color-gray-transparent-40'] }}
                >
                  MODIFIED
                </DNAChip>
              </DNABox>
            </Iffy>
          </DNABox>

          <DNABox fill>
            <DNAText numberOfLines={2} status="basic">
              {activePresentation?.presentable.title}
            </DNAText>
          </DNABox>

        </DNABox>

        <Hoverable onHoverIn={toggleHover} onHoverOut={toggleHover}>
          <DNABox alignX="center">
            <Pressable onPress={handleExpand}>
              <DNAThumbnail
                s3URL={s3URL}
                useLoadingIndicator={true}
                size={isLargeScreen ? 'xl' : 'lg'}
              />

              {/* Expand Overlay */}
              <Iffy is={isHovered}>
                <DNABox style={[styles.overlay]}>
                  <DNAButton
                    appearance="ghost"
                    size="sm"
                    iconLeft="arrow-expand"
                    iconStyle={{ color: 'white' }}
                    onPress={handleExpand}
                  >
                    <DNAText bold status="basic">Expand</DNAText>
                  </DNAButton>
                </DNABox>
              </Iffy>

              {/* Content Hidden Overlay */}

              {popoutContentHidden &&
                <DNABox
                  style={styles.overlay}
                  alignX="center"
                  alignY="center"
                  appearance="col"
                >
                  <DNAText
                    h5
                    bold
                    status="basic"
                  >Adjust windows so shared window is visible</DNAText>
                  <LottieAnimation
                    style={{ width:190 }}
                    play
                    animationData={PopupHiddenAnimation}
                  />
                </DNABox>
              }

            </Pressable>
          </DNABox>
        </Hoverable>

        <DNABox alignX="center" spacing="sm" style={{ paddingVertical: 16 }} alignY="center">
          <DNAText style={{ fontSize: 14 }} bold status="basic">
            {`Slide ${currentPageNumber} of ${presentable?.numberOfPages}`}
          </DNAText>
        </DNABox>
        <DNADivider variant="virtual" />
        <DNABox style={{ padding: 16 }}>
          <DNAText style={{ fontSize: 15 }} status="basic">
            {activePresentation?.currentPresentablePage.page.speakerNotes || 'No slide notes'}
          </DNAText>
        </DNABox>
      </Iffy>
    </DNABox >
  )
}

export default CurrentlyPresentingPanel
