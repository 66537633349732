import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { meetingActions } from 'src/state/redux/slice/meeting';
import { DNABox, DNAButton, DNAText, Icon } from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import { MeetingType, FileType, FolderItemType } from '@alucio/aws-beacon-amplify/src/models';
import { useSyncState } from 'src/state/redux/selector/cache'
import workerChannel from 'src/worker/channels/workerChannel'
import { format } from 'date-fns';
import { useAppSettings } from 'src/state/context/AppSettings';
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
import { LoadedPresentation } from 'src/state/context/ContentProvider/ContentProvider';
import { Option } from './Option';
import { colors } from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { isCustomDeckORM, isDocumentVersionORM, isFolderItemORM } from 'src/types/typeguards';
import { DefaultVariant } from './Variants/Default';
import { ContentPreviewModalVariant } from './Variants/ContentPreviewModal';
const InPersonMeetingAnimation = require('src/assets/lottie/in-person-meeting-animated.json')
const VirtualMeetingAnimation = require('src/assets/lottie/virtual-meeting-animated.json')

enum ContextVariantOptions {
  default,
  contentPreviewModal
}
enum AnchorVariantOptions {
  desktop,
  desktopCPM,
  tablet,
  tabletCPM,
}

export interface MeetingButtonProps {
  context?: ContextVariantOption
}

type AnchorVariantOption = keyof typeof AnchorVariantOptions
type ContextVariantOption = keyof typeof ContextVariantOptions
type ContextVariants = Record<ContextVariantOption, React.ElementType>

type ValidModelTypes = (
  FileType |
  keyof typeof FileType |
  FolderItemType | keyof typeof FolderItemType |
  undefined)[]

export const styles = StyleSheet.create({
  content: {
    backgroundColor: colors['text-white'],
    borderColor: colors['gray-100'],
    borderRadius: 4,
    borderWidth: 1,
    minWidth: 210,
    shadowColor: colors['gray-900'],
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.16,
    shadowRadius: 11.22,
  },
  chevronIcon: {
    color: colors['gray-900'],
    height: 16,
  },
});

/** This hook allows us to instantiate meeting button variances using shared components
 * based on whether we have access to the useContent hook. The dropdown options are the same but
 * the button anchor can be visually divergant for the various contexts this component is used in.
 * */
export const useMeetingButtonSharedComponents = (loadedPresentation?: LoadedPresentation) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const syncState = useSyncState()
  const [optionsVisible, setOptionsVisible] = useState<boolean>(false);
  const { deviceMode, isTabletPlatform } = useAppSettings()

  const toggleOptionsVisibility = () => {
    setOptionsVisible((optionsVisible) => !optionsVisible);
  }

  const disabledVirtualContent = (() => {
    const { orm } = { ...loadedPresentation?.presentable }
    const validModelTypes: ValidModelTypes = [FileType.WEB, FileType.HTML]

    if (isFolderItemORM(orm)) {
      const { itemORM } = orm.relations
      if (isDocumentVersionORM(itemORM)) {
        return validModelTypes.includes(itemORM.model.type)
      }
      if (isCustomDeckORM(itemORM)) {
        const { containsWebDocs, containsHTMLDocs } = itemORM.meta
        return containsWebDocs || containsHTMLDocs
      }
    }
    else if (isDocumentVersionORM(orm)) {
      return validModelTypes.includes(orm.model.type)
    }
    /** Assume we are not working with a video or web doc if none of the checks above are triggered */
    return false
  })();

  const pauseOfflineSyncing = async() => {
    // check if service worker is registered
    const registration = await navigator.serviceWorker.getRegistration('/')
    if (registration && syncState?.matches('online.sync')) {
      // pause offline syncing
      workerChannel.postMessageExtended({ type: 'PAUSE_SYNC' })
    }
  }

  const startMeeting = (
    inPerson?: boolean,
  ) => () => {
    pauseOfflineSyncing()
    const onMeetingCreated = (meetingId: string) => history.push(`/meeting-presentation/${meetingId}`)

    if (loadedPresentation) {
      dispatch(contentPreviewModalActions.setModalVisibility({ isOpen: false }));
      dispatch(contentPreviewModalActions.setInitialContent({
        presentableModelORM: loadedPresentation.presentable.orm,
        page: loadedPresentation.currentPresentablePage.presentationPageNumber,
      }));
    }

    // WE CREATE THE MEETING
    dispatch(meetingActions.startMeeting({
      type: inPerson ? MeetingType.IN_PERSON : MeetingType.VIRTUAL,
      title: `Meeting Started at ${format(new Date(), 'MM/dd/yyyy h:mm aa')}`,
      callback: onMeetingCreated,
      context: loadedPresentation ? 'PREVIEW_MODAL' : undefined,
    }));
  }

  /** 4 Anchor variants here in 4 possible cases:
   * (currently the only way to determine if we are in
   * CPM is by checking the loaded presentation prop)
   *  - Desktop - Dropdown with "Start meeting" label
   *  - Tablet - Circle play icon
   *  - Desktop Content Preview Modal - Dropdown with "Present" label and play button icon
   *  - Tablet Content Preview Modal - "Present" label with play button icon
  */
  const currentAnchorVariant: AnchorVariantOption =
    deviceMode === 'desktop'
      ? loadedPresentation ? 'desktopCPM' : 'desktop'
      : loadedPresentation ? 'tabletCPM' : 'tablet'

  const meetingButtonAnchor = (<DNAPopover.Anchor>
    {currentAnchorVariant === 'tablet'
      ? <DNAButton
          padding="sm"
          appearance="ghostLink"
          status="dark"
          iconLeft="play-circle"
          size="xl"
          onPress={isTabletPlatform ? startMeeting(true) : toggleOptionsVisibility}
          testID="header-start-meeting-button"
      />
      : <DNAButton
          testID={loadedPresentation ? 'header-present-button' : 'header-start-meeting-button'}
          onPress={isTabletPlatform ? startMeeting(true) : toggleOptionsVisibility}
          appearance="outline"
          status="tertiary"
          size="md"
          padding="sm"
      >
        <DNABox spacing="sm" alignY="center" style={{ height: 20 }}>
          {currentAnchorVariant !== 'desktop' && <Icon style={styles.chevronIcon} name="play" />}
          <DNAText bold>
            {loadedPresentation ? 'Present' : 'Start Meeting'}
          </DNAText>
          {isTabletPlatform || <Icon style={styles.chevronIcon} name={`chevron-${optionsVisible ? 'up' : 'down'}`} />}
        </DNABox>
      </DNAButton>
    }
  </DNAPopover.Anchor>)

  const meetingButtonOptions = (<DNAPopover.Content>
    <DNABox spacing="sm" appearance="col" style={styles.content}>

      {/* In-Person Meeting Option */}
      <Option
        icon="account-multiple"
        animationData={InPersonMeetingAnimation}
        title="In-Person Meeting"
        subTitle="Present content directly from your active window."
        style={{
          borderBottomWidth: 1,
          borderBottomColor: colors['gray-80'],
        }}
        onSelect={startMeeting(true)}
      />

      {/* Virtual Meeting Option */}
      <DNAPopover
        disablePopover={!disabledVirtualContent}
        placement="top"
      >

        <DNAPopover.Anchor>
          <Option
            icon="account-group"
            animationData={VirtualMeetingAnimation}
            title="Virtual Meeting"
            // eslint-disable-next-line max-len
            subTitle="Present content while using a video conferencing tool or in a group setting using an external TV monitor. Can be used offline."
            onSelect={startMeeting(false)}
            disabled={disabledVirtualContent}
          />
        </DNAPopover.Anchor>
        <DNAPopover.Content>
          <DNAText
            testID="disabled-virtual-meeting-tool-tip-text"
            style={{ color: colors['text-white'] }}
            numberOfLines={1}
          >
            Not available for this type of document
          </DNAText>
        </DNAPopover.Content>
      </DNAPopover>

    </DNABox>
  </DNAPopover.Content>)

  const meetingButtonComponents = (<DNAPopover
    lazyMount
    placement="bottom-end"
    interactive={true}
    visible={optionsVisible}
    onBackdropPress={toggleOptionsVisibility}
    type="menu"

  >
    {/* Toggle Button */}
    {meetingButtonAnchor}

    {/* Dropdown options */}
    {meetingButtonOptions}
  </DNAPopover>)

  return { startMeeting, optionsVisible, toggleOptionsVisibility, meetingButtonComponents }
}

const contextVariants: ContextVariants = {
  default: DefaultVariant,
  contentPreviewModal: ContentPreviewModalVariant,
}

const MeetingButton: React.FC<MeetingButtonProps> = ({ context = 'default' }) => {
  /** Currently the goal of this implementation is to allow for the conditional usage of the useContent hook.
   * However, this approach additionally supports completely divergent appearances as well (i.e. the launch
   * meeting button could look/interact completely differnet in the various contexts (default vs cpm) */
  const CurrentContextVariant = contextVariants[context]

  return <CurrentContextVariant />
}

export default MeetingButton;
