import React from 'react'
import { useDispatch } from 'src/state/redux'
import { folderActions } from 'src/state/redux/slice/folder'

import { DNAModal, DNAText, DNABox, GenericToast, ToastOrientations } from '@alucio/lux-ui'
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal'
import { FolderItemORM } from 'src/types/types'
import { ToastActions } from '@alucio/lux-ui/src/components/Toast/useToast'

export type DNACustomDeckDeleteModalProps = {
  folderItemORM: FolderItemORM
  toast?: ToastActions
}

export const DNACustomDeckDeleteModal: DNAModalVariant<DNACustomDeckDeleteModalProps> = (props) => {
  const { folderItemORM, toggleModal, toast } = props
  const dispatch = useDispatch()

  const onAction = () => {
    dispatch(folderActions.removeItem(folderItemORM))
    toast?.add(
      <GenericToast
        title="File deleted."
        status="success"
      />,
      ToastOrientations.TOP_RIGHT,
    )
    toggleModal()
  }

  return (
    <DNAModal>
      <DNAModal.Header>Delete item?</DNAModal.Header>
      <DNAModal.Body>
        <DNABox style={{ width: 528 }}>
          <DNAText status="subtle" numberOfLines={2}>{folderItemORM.meta.title}</DNAText>
        </DNABox>
      </DNAModal.Body>
      <DNAModal.Confirmation
        onCancel={toggleModal}
        onAction={onAction}
        actionLabel="Delete"
        actionStatus="danger"
      />
    </DNAModal>
  )
}

export default DNACustomDeckDeleteModal
