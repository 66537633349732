import { useCallback, useEffect, useRef, useState } from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import {
  PPZTransform,
} from '@alucio/core';
import { Logger } from '@aws-amplify/core';
import { FileType } from '@alucio/aws-beacon-amplify/src/models';
import AWSConfig from '@alucio/aws-beacon-amplify/src/aws-exports';
import { PresentablePage } from 'src/types/types';
import { PresentationBroadcastContent } from '@alucio/core/lib/state/context/PresentationPlayer/types';
import { LoadedPresentation } from './ContentProvider';
import { getDocPath } from 'src/components/ContentPreviewModal/ContentPreview/IFrame/IFrame.web';
import { PW } from 'src/state/machines/presentation/playerWrapper';
import { AlucioChannel, useSafePromise } from '@alucio/lux-ui';
import {
  PresentationChannelMessage,
  PresentationStateIdleEvent,
  PresentationStateSyncEvent,
} from 'src/state/machines/presentation/playerWrapperTypes';
import equal from 'fast-deep-equal';
import useInterval from '@alucio/core/lib/hooks/useInterval/useInterval';
import { useAppSettings } from '../AppSettings';
import useTokenHandler from 'src/hooks/useTokenHandler/useTokenHandler';
const logger = new Logger('useContentInfoBroadcastChannel', 'INFO');

const useContentInfoBroadcastChannel = (
  setActiveSlideByPresentationPageNumber: (presentationPageNumber: number, step?: number, totalSteps?: number) => void,
  setCurrentPPZCoords: (coords?: PPZTransform) => void,
  activePresentation?: LoadedPresentation,
  // TODO when we integrate we'll want to pull the meeting ID from the hook
  meetingId:string = 'aaaaaa',
) => {
  const { isOnline } = useAppSettings()
  const safePromise = useSafePromise()
  const messageNumberRef = useRef<number>(0);
  const [getTokenInterval, setGetTokenInterval] = useState<number | null>(null);
  const [contentInfo, setContentInfo] = useState<PresentationBroadcastContent>();
  const currentActivePresentationRef = useRef<LoadedPresentation | undefined>(activePresentation);
  const channel: BroadcastChannel<PW.PresentationChannelMessage> = useRef(
    AlucioChannel.get(AlucioChannel.commonChannels.PRESENTATION_CHANNEL)).current
  const { getAccessToken } = useTokenHandler();

  useEffect(handleNewActivePage, [activePresentation,
    activePresentation?.currentPresentablePage,
    activePresentation?.currentStep,
    activePresentation?.currentPPZCoords?.positionX,
    activePresentation?.currentPPZCoords?.positionY,
    activePresentation?.currentPPZCoords?.scale]);

  // ** CHANNEL LISTENER/SENDER ** //
  // SENDS A MESSAGE WITH THE NEW UPDATED CONTENT STATE
  const broadcastStateSync = useCallback((msg: PresentationChannelMessage) => {
    async function postMsg() {
      logger.debug('Sending to Presentation Channel', { channel, msg })
      channel.postMessage(msg)
    }
    safePromise.makeSafe(postMsg())
      .catch(err => !err.isCanceled
        ? console.error(err)
        : undefined,
      )
  }, []);
  useEffect(() => {
    logger.debug('Calling broadcast from useEffect', contentInfo)
    broadcastStateSync(getBroadcastMessage(contentInfo));
  }, [contentInfo]);

  const getBroadcastMessage = (contentInfo?: PresentationBroadcastContent) => {
    const msg:PresentationStateSyncEvent|PresentationStateIdleEvent = contentInfo
      ? {
        type: 'PRESENTATION_STATE_SYNC',
        meetingId,
        payload: contentInfo,
        messageNumber: messageNumberRef.current,
      }
      : {
        type: 'PRESENTATION_STATE_IDLE',
        meetingId,
      }
    return msg
  }

  // WAITS FOR MESSAGES TO UPDATE THE STATE ACCORDINGLY
  useEffect(() => {
    channel.onmessage = (msg: PW.PresentationChannelMessage) => {
      if (msg.meetingId === meetingId && msg.type !== 'PRESENTATION_STATE_SYNC') {
        switch (msg.type) {
          case 'NAVIGATE_PAST_FIRST':
            logger.debug('Got NAVIGATE_PAST_FIRST')
            navigatePastFirst()
            break
          case 'NAVIGATE_PAST_LAST':
            logger.debug('Got NAVIGATE_PAST_LAST')
            navigatePastLast();
            break
          case 'PRESENTATION_PROGRESS': {
            logger.debug('Got PRESENTATION_PROGRESS', msg.payload);
            messageNumberRef.current = msg.messageNumber;
            const { page, groupId, step, totalSteps } = msg.payload
            if (page && groupId) {
              pageChange(page, groupId, step, totalSteps);
            }
            break;
          }
          case 'PPZ_TRANSFORM': {
            logger.debug('Got PPZ_TRANSFORM', msg.payload)
            const currGroupId = currentActivePresentationRef.current?.currentPresentablePage.presentableGroup.id
            // Check the groupId to make sure we are not getting a PPZ event for a previous doc/group
            if (msg.presentableGroupId === currGroupId) {
              setCurrentPPZCoords(msg.payload);
            } else {
              logger.warn('Got PPZ message for non-current group ignorning')
            }
            break
          }
          default:
            logger.warn(`Received unhandled message from wrapper: ${msg.type}`, msg)
        }
      }
    };
  }, []);

  // HEARTBEAT
  useInterval(() =>
    broadcastStateSync(getBroadcastMessage(contentInfo)), 2000);

  useInterval(() => {
    async function refreshContentToken() {
      if (activePresentation) {
        logger.debug('getting access JWT')
        const documentVersionORM = activePresentation?.currentPresentablePage.documentVersionORM;
        const { accessToken: jwt, expirationTimeInSeconds } = await getAccessToken(documentVersionORM);

        setContentInfo((contentInfo) => {
          if (contentInfo) {
            return {
              ...contentInfo,
              JWT: jwt,
            }
          }

          return undefined;
        });
        setGetTokenInterval((expirationTimeInSeconds - 60) * 1000);
      }
    }

    refreshContentToken();
  }, getTokenInterval);

  // ** HANDLERS ** //
  function navigatePastLast(): void {
    navigatePast(true);
  }

  function navigatePastFirst(): void {
    navigatePast();
  }

  // ACCORDING TO OUR LAST PAGE (currentPresentablePageRef) AND THE NEW ACTIVE ONE,
  // IT DETERMINES THE NEW STATE FOR THE PRESENTATION TO HAVE
  function handleNewActivePage(): void {
    logger.debug('entered handleNewActivePage')
    if (!activePresentation) {
      currentActivePresentationRef.current = undefined;
      setContentInfo(undefined)
      return;
    }

    const isNewDocument = activePresentation.currentPresentablePage.documentVersionORM.model.id !==
      currentActivePresentationRef.current?.currentPresentablePage.documentVersionORM.model.id;

    if (isNewDocument) {
      // UPDATES THE CONTENT INFO STATE TO REFLECT THE NEW DOCUMENT INFO
      handleChangeOfDocument(activePresentation);
      currentActivePresentationRef.current = { ...activePresentation };
      return;
    }

    if (!contentInfo) {
      return;
    }

    const isNewGroup = activePresentation.currentPresentablePage.presentableGroup.id !==
      currentActivePresentationRef.current?.currentPresentablePage.presentableGroup.id;

    if (isNewGroup) {
      const group = activePresentation.currentPresentablePage.presentableGroup;

      // REMAINS THE SAME DOCUMENT INFO BUT UPDATES THE GROUP/PAGE/STEP
      setContentInfo({
        ...contentInfo,
        groupId: group.id,
        state: {
          page: activePresentation.currentPresentablePage.page.number,
          step: activePresentation.currentStep || 0,
          totalSteps: activePresentation.totalSteps || 0,
        },
        visiblePages: group.pages.reduce<number[]>((acc, page) => {
          acc.push(page.page.number);
          return acc;
        }, []),
      });
      currentActivePresentationRef.current = { ...activePresentation };
      return;
    }

    const isNewPage = activePresentation.currentPresentablePage.id !==
      currentActivePresentationRef.current?.currentPresentablePage.id;

    if (isNewPage) {
      // REMAINS THE SAME DOCUMENT INFO BUT UPDATES THE PAGE/STEP
      setContentInfo({
        ...contentInfo,
        state: {
          page: activePresentation.currentPresentablePage.page.number,
          step: activePresentation.currentStep || 0,
          totalSteps: activePresentation.totalSteps || 0,
        },
      });
      currentActivePresentationRef.current = { ...activePresentation };
      return;
    }

    const isNewStep = activePresentation.currentStep !==
      currentActivePresentationRef.current?.currentStep;

    if (isNewStep) {
      // REMAINS THE SAME DOCUMENT INFO BUT UPDATES THE STEP
      setContentInfo({
        ...contentInfo,
        state: {
          page: contentInfo.state.page,
          step: activePresentation.currentStep || 0,
          totalSteps: activePresentation.totalSteps || 0,
        },
      });
      currentActivePresentationRef.current = { ...activePresentation };
      return;
    }

    const isNewPPZCords = !equal(
      activePresentation.currentPPZCoords, currentActivePresentationRef.current?.currentPPZCoords);

    if (isNewPPZCords) {
      // REMAINS THE SAME DOCUMENT INFO BUT UPDATES THE COORDS
      setContentInfo({
        ...contentInfo,
        ppzCoords: activePresentation.currentPPZCoords,
      });
      currentActivePresentationRef.current = { ...activePresentation };
    }
  }

  async function handleChangeOfDocument(activePresentation: LoadedPresentation): Promise<void> {
    const { documentVersionORM } = activePresentation.currentPresentablePage
    const docPath = getDocPath(documentVersionORM, isOnline);
    const bucket = AWSConfig.aws_user_files_s3_bucket;
    const group = activePresentation.currentPresentablePage.presentableGroup;
    const { watermarkText } = documentVersionORM.meta
    const { isContentCached } = documentVersionORM.meta.assets
    const updatedInfo = {
      // The JWT is loaded via a promise to allow us to go ahead and init
      // the player while we're fetching the JWT
      JWT: (isOnline && !isContentCached) ? 'PENDING' : '',
      bucket,
      docPath: `/content/${docPath}`,
      documentVersionId: documentVersionORM.model.id,
      documentId: documentVersionORM.relations.documentORM.model.id,
      groupId: group.id,
      contentType: FileType[documentVersionORM.model.type],
      contentURL: documentVersionORM.model.contentURL,
      state: {
        page: activePresentation.currentPresentablePage.page.number,
        step: activePresentation.currentStep || 0,
        totalSteps: activePresentation.totalSteps || 0,
      },
      ppzCoords: activePresentation?.currentPPZCoords,
      visiblePages: group.pages.reduce<number[]>((acc, page) => {
        acc.push(page.page.number);
        return acc;
      }, []),
      watermarkText,
    }

    if (isOnline && !isContentCached) {
      getAccessToken(documentVersionORM).then((response) => {
        const { accessToken: jwt, expirationTimeInSeconds } = response;

        setContentInfo({
          ...updatedInfo,
          JWT: jwt,
        })
        // WE'LL FETCH A NEW TOKEN EVERY (TIMEOUT - 1) MINUTES
        setGetTokenInterval((expirationTimeInSeconds - 60) * 1000);
      });
      return;
    }
    setContentInfo(updatedInfo);
  }

  // DETERMINES THE NEXT PAGE/GROUP TO GO, DEPENDING ON THE CURRENT ONE
  function navigatePast(next?: boolean): void {
    let newPage: PresentablePage | undefined;

    for (const group of currentActivePresentationRef.current?.presentable.presentableGroups || []) {
      for (const page of group.pages) {
        if (currentActivePresentationRef.current?.currentPresentablePage.presentationPageNumber ===
          (page.presentationPageNumber + (next ? -1 : 1))) {
          newPage = page;
          break;
        }
      }
    }

    if (newPage) {
      setActiveSlideByPresentationPageNumber(newPage.presentationPageNumber, next ? 0 : -1);
    } else {
      console.warn(`New Page to NAVIGATE_PAST_${next ? 'FIRST' : 'LAST'} not found`);
    }
  }

  // NOTE: PAGENUMBER IS THE ACTUAL NUMBER OF THE PAGE WITHIN A DOCUMENT
  function pageChange(pageNumber: number, groupId: string, step?: number, totalSteps?: number): void {
    let newPage: PresentablePage | undefined;

    for (const group of currentActivePresentationRef.current?.presentable.presentableGroups || []) {
      for (const page of group.pages) {
        if (page.page.number === pageNumber && group.id === groupId) {
          newPage = page;
          break;
        }
      }
    }

    if (newPage) {
      setActiveSlideByPresentationPageNumber(newPage.presentationPageNumber, step, totalSteps);
    } else {
      console.warn('New Page to CHANGE not found');
    }
  }
};

export default useContentInfoBroadcastChannel;
