import React from 'react'
import { ShareType } from '@alucio/aws-beacon-amplify/src/models'
import { DNABox, DNAButton, DNACard, DNAChip, DNAText, util } from '@alucio/lux-ui'
import { SHARE_METHODS, STEP_STATE } from 'src/state/machines/sharing/shareTypes'
import { useAllDocumentsInstance } from 'src/state/redux/selector/document'
import { AssociatedFileORM, DocumentORM, EmailTemplateORM, getAssociatedFileTitle } from 'src/types/orms'
import { useDNAFileShareModalState } from '../state/context/DNAFileShareModalStateProvider'
import { ScrollView, StyleSheet } from 'react-native'
import { colors } from '@alucio/lux-ui/src/theming/themes/alucio/colors'

interface ShareEmailTemplateRowProps {
  emailTemplateORM: EmailTemplateORM,
  isLastRow: boolean,
}

const styles = StyleSheet.create({
  documentChip: {
    minWidth: 45,
  },
  emailTemplatesCard: {
    borderColor: colors['color-gray-100'],
    margin: 16,
  },
  emailTemplatesRow: {
    padding: 12,
    paddingRight: 20,
  },
  emailTemplatesRowBorder: {
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#DBDBDB', // not sure why colors['color-gray-100'] add border color black instead of gray
  },
});

// [BEAC-1583] For email templates we will allow to link unpublished doc files but
// we will only share the published ones, the same that happens in Doc Version
const getAttachmentsToShare = (
  associatedFiles: AssociatedFileORM[],
  allDocumentORM: DocumentORM[],
) => {
  return associatedFiles
    .filter(af => af.meta.canBeSharedByMSL)
    .map(af => {
      let contentId = af.file?.id!
      if (af.model.type === 'DOCUMENT') {
        const [currentAssociatedDoc] = allDocumentORM
          .filter(currentDoc => af.file?.id! === currentDoc.model.id)
        contentId = currentAssociatedDoc != null
          ? currentAssociatedDoc.relations.version.latestPublishedDocumentVersionORM?.model.id!
          : contentId
      }
      return {
        title: getAssociatedFileTitle(af),
        contentId,
        sharedContentId: af.model.id,
        type: af.model.type === 'ATTACHED_FILE' ? ShareType.ATTACHED_FILE : ShareType.DOCUMENT_VERSION,
      }
    })
}

export const ShareEmailTemplateRow: React.FC<ShareEmailTemplateRowProps> = ({
  emailTemplateORM,
  isLastRow,
}) => {
  const allDocumentORM = useAllDocumentsInstance()
  const { shareMachineState, shareMachineSend, cond } = useDNAFileShareModalState()

  const {
    emailTemplateId: generatingEmailTemplateId,
    type: generatingEmailType,
  } = shareMachineState.context.generateLinks ?? { }

  const isThisTemplateGenerating = emailTemplateORM.model.id === generatingEmailTemplateId

  const isEmailClientOpening = (
    isThisTemplateGenerating &&
    generatingEmailType === SHARE_METHODS.OPEN_EMAIL_CLIENT &&
    cond.isGeneratingLink
  )

  const hasEmailClientOpened = (
    isThisTemplateGenerating &&
    generatingEmailType === SHARE_METHODS.OPEN_EMAIL_CLIENT &&
    cond.generatedLink
  )

  const isEmailCopying = (
    isThisTemplateGenerating &&
    generatingEmailType === SHARE_METHODS.COPY_EMAIL &&
    cond.isGeneratingLink
  )

  const hasEmailCopied = (
    isThisTemplateGenerating &&
    generatingEmailType === SHARE_METHODS.COPY_EMAIL &&
    cond.generatedLink
  )

  const handleShare = (type: SHARE_METHODS.OPEN_EMAIL_CLIENT | SHARE_METHODS.COPY_EMAIL) => () => {
    shareMachineSend({
      type: 'GENERATE_SHARE',
      payload: {
        type,
        emailTemplateORM,
        attachmentsInfo: getAttachmentsToShare(
          emailTemplateORM.relations.associatedFiles,
          allDocumentORM,
        ),
      },
    })
  }

  return (
    <DNABox
      testID="email-template-share-row"
      key={emailTemplateORM.model.id}
      style={util.mergeStyles(undefined, styles.emailTemplatesRow, [styles.emailTemplatesRowBorder, !isLastRow])}
    >
      <DNABox
        appearance="row"
        spacing="between"
        alignY="center"
        fill
        childFill={0}
      >
        <DNABox appearance="col" fill>
          {/* TITLE */}
          <DNAText numberOfLines={2}>
            {emailTemplateORM.model.title}
          </DNAText>

          {/* CUSTOM FILTER CHIPS */}
          <DNABox spacing="xs" wrap="start">
            {
              Object
                .values(emailTemplateORM.meta.customFilterValues)
                .flat()
                ?.map(({ displayValues }) => (
                  displayValues.map((value, idx) => (
                    <DNAChip key={value + idx} style={{ marginTop: 4 }}>
                      {value}
                    </DNAChip>
                  ))
                ))
            }
          </DNABox>
        </DNABox>

        {/* INTERACTIVE BUTTONS */}
        <DNABox spacing="sm">
          {/* EMAIL */}
          <DNAButton
            testID="email-template-mailto-client-button"
            status={hasEmailClientOpened ? 'success' : 'primary'}
            appearance="ghost"
            iconLeft={hasEmailClientOpened ? 'check-bold' : 'email-send-outline'}
            onPress={handleShare(SHARE_METHODS.OPEN_EMAIL_CLIENT)}
            disabled={shareMachineState.tags.has('DISABLE_SHARE')}
          >
            {
              isEmailClientOpening
                ? 'Opening email client'
                : hasEmailClientOpened
                  ? 'Opened email client'
                  : 'Email'
            }
          </DNAButton>

          {/* COPY EMAIL */}
          <DNAButton
            testID={
              cond.generatedLink
                ? 'email-template-link-copied-button'
                : 'email-template-copy-link-button'
            }
            status={hasEmailCopied ? 'success' : 'primary'}
            appearance="ghost"
            iconLeft={hasEmailCopied ? 'check-bold' : 'content-copy'}
            onPress={handleShare(SHARE_METHODS.COPY_EMAIL)}
            disabled={shareMachineState.tags.has('DISABLE_SHARE')}
          >
            {
              isEmailCopying
                ? 'Copying'
                : hasEmailCopied
                  ? 'Copied'
                  : 'Copy'
            }
          </DNAButton>
        </DNABox>
      </DNABox>
    </DNABox>
  )
}
export const SelectEmailTemplatesBody: React.FC = () => {
  const { emailTemplates, currentState } = useDNAFileShareModalState()
  const currentlyVisible: boolean = currentState === STEP_STATE.EMAIL_TEMPLATES

  return (
    <ScrollView style={{ display: currentlyVisible ? 'flex' : 'none' }}>
      <DNACard appearance="outline" style={[styles.emailTemplatesCard]} testID="email-template-share-modal">
        {emailTemplates.map((emailTemplateORM, idx) =>
          (
            <React.Fragment key={emailTemplateORM.model.id}>
              <ShareEmailTemplateRow
                emailTemplateORM={emailTemplateORM}
                isLastRow={idx === emailTemplates.length - 1}
              />
            </React.Fragment>),
        )}
      </DNACard>
    </ScrollView>
  )
}
