import deepEqual from 'fast-deep-equal'

/**
 * A way to do both deep and shallow comparisons
 * Currently does not support shallowKey checking for nested keys
 * @param a any
 * @param b any
 * @param opts { shallowCheck: Record<string, boolean> }
 * @returns boolean
 */
export const shallowDeepEqual = (
  a: any,
  b: any,
  opts: { shallowCheck: Record<string, boolean> } = { shallowCheck: { } }
) => {
  if (typeof a === 'object' && typeof b === 'object' && Object.keys(opts.shallowCheck).length) {    
    const aKeys = Object.keys(a)
    const bKeys = Object.keys(b)
    
    const isKeyLengthEqual = aKeys.length === bKeys.length 

    if (!isKeyLengthEqual) return false

    return aKeys.every(key => {
      return opts.shallowCheck[key]
        ? a[key] === b[key]
        : deepEqual(a[key], b[key])
    })
  }

  return deepEqual(a, b)
}

export default shallowDeepEqual
