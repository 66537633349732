import { isIOS } from 'react-device-detect'
import { CanvasEvent, getClientOffset, getClientPosition } from './shared'

interface SquareObject {
    x: number,
    y: number,
    width: number,
    height: number,
    rgb: RGBType,
}

type RGBType = {
    r: number,
    g: number,
    b: number,
    opacity: number,
  }

const getRGB = ( rgb : RGBType) => {
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.opacity})`
}

const decreaseOpacity = (rgb: RGBType) => {
  const opacity = ((rgb.opacity * 100) - 10) / 100 // 10% decrease
  return { ...rgb, opacity }
}

export function square(
  canvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
  isDrawStart: boolean,
) {
  let startPosition = { x: 0, y: 0 };
  let endPosition = { x: 0, y: 0 };

  let canvasMapElements : Record<string, SquareObject> = {}

  const getContext = () => {
    if (!canvasRef.current)
    { return; }

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    return context;
  };
    //
  const prepareCanvas = () => {
    if (!canvasRef.current)
    { return; }

    const context = getContext();

    if (!context)
    { return; }
    context.scale(2, 2);
  };

  const drawRectangle = (x: number, y: number, width: number, height: number) => {
    const squareObj = {
      x,
      y,
      width,
      height,
      rgb: { r: 255, g: 255, b: 0, opacity: 1 },
    };

    const key = Buffer.from(JSON.stringify(squareObj)).toString('base64');
    canvasMapElements[key] = squareObj;
    onCanvasMounted();
  };

  const draw = (squareObj: SquareObject) => {
    const context = getContext();

    if (!context)
    { return; }

    const rgb = getRGB(squareObj.rgb);

    context.beginPath();
    context.strokeStyle = rgb;
    context.fillStyle = rgb;
    context.rect(squareObj.x, squareObj.y, squareObj.width, squareObj.height);
    context.fill();
  };

  const onCanvasMounted = () => {
    if (!Object.keys(canvasMapElements).length)
    { return; }

    cleanCanvas();
    canvasMapElements = Object.keys(canvasMapElements).reduce((acc, key) => {
      if (key === 'Background') {
        acc[key] = canvasMapElements[key];
        draw(canvasMapElements[key]);
        return acc;
      }

      const squareObj = canvasMapElements[key];
      const rgb = decreaseOpacity(squareObj.rgb);
      const newSquareObj = { ...squareObj, rgb };

      if (rgb.opacity > 0) {
        acc[key] = newSquareObj;
        draw(newSquareObj);
      }

      return acc;
    }, {});
  };

  const cleanCanvas = () => {
    const canvas = canvasRef.current;
    const context = getContext();

    if (!context)
    { return; }
    if (!canvas)
    { return; }
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  const clearCanvas = () => {
    canvasMapElements = {};
    cleanCanvas();
  };

  const mouseDownListener = (event: CanvasEvent) => {
    if (isIOS) {
      touchStart(event)
      return
    }

    if (!canvasRef.current)
    { return; }

    const canvas = canvasRef.current;

    const offset = getClientOffset(canvas);

    if (!offset)
    { return; }

    const { x, y } = getClientPosition(event);

    clearCanvas();

    startPosition = { x: x - offset.x, y: y - offset.y };
    isDrawStart = true;
  };

  const touchStart = (event: CanvasEvent) => {
    event.preventDefault();

    if (!canvasRef.current)
    { return; }

    const canvas = canvasRef.current;
    const offset = getClientOffset(canvas)
    const touches = event.changedTouches;

    if (!offset)
    { return; }

    if (isDrawStart) {
      return;
    }

    if (!touches || !touches.length)
    { return; }

    const { x, y } = getClientPosition(event, true, 0);

    startPosition = { x: x - offset.x, y: y - offset.y };
    isDrawStart = true;
  }

  const mouseMoveListener = (event: CanvasEvent) => {
    if (isIOS) {
      touchmove(event)
      return
    }

    if (!isDrawStart)
    { return; }

    if (!canvasRef.current)
    { return; }

    const canvas = canvasRef.current;
    const offset = getClientOffset(canvas);

    if (!offset)
    { return; }

    const { x, y } = getClientPosition(event);

    endPosition = { x: x - offset.x, y: y - offset.y };
    const width = endPosition.x - startPosition.x;
    const height = endPosition.y - startPosition.y;

    if (Math.abs(width) <= 0 || Math.abs(height) <= 0) {
      return;
    }

    cleanCanvas();
    draw({ x: startPosition.x, y: startPosition.y, width, height, rgb: { r: 255, g: 255, b: 0, opacity: 1 } })

    return {};
  };

  const touchmove = (event: CanvasEvent) => {
    event.preventDefault();
    const touches = event.changedTouches;

    if (!canvasRef.current)
    { return; }

    const canvas = canvasRef.current;
    const offset = getClientOffset(canvas);

    if (!offset)
    { return; }

    if (!touches || !touches.length)
    { return; }

    const { x, y } = getClientPosition(event, true, touches.length - 1);

    endPosition = { x: x - offset.x, y: y - offset.y };
    const width = endPosition.x - startPosition.x;
    const height = endPosition.y - startPosition.y;

    clearCanvas();

    draw({
      x: startPosition.x,
      y: startPosition.y,
      width,
      height,
      rgb: { r: 255, g: 255, b: 0, opacity: 1 },
    })
  };

  const mouseUpListener = (event: CanvasEvent) => {
    if (isIOS) {
      touchend(event)
      return
    }

    if (!isDrawStart)
    { return; }

    if (!canvasRef.current)
    { return; }

    const canvas = canvasRef.current;
    const offset = getClientOffset(canvas);

    if (!offset)
    { return; }

    const { x, y } = getClientPosition(event);

    endPosition = { x: x - offset.x, y: y - offset.y };
    const width = endPosition.x - startPosition.x;
    const height = endPosition.y - startPosition.y;
    cleanCanvas();
    drawRectangle(startPosition.x, startPosition.y, width, height);
    isDrawStart = false;
  };

  const touchend = (event) => {
    event.preventDefault();
    const touches = event.changedTouches;

    if (!canvasRef.current)
    { return; }

    const canvas = canvasRef.current;
    const offset = getClientOffset(canvas);

    if (!offset)
    { return; }

    if (!touches || !touches.length)
    { return; }

    const { x, y } = getClientPosition(event, true, touches.length - 1);

    endPosition = { x: x - offset.x, y: y - offset.y };
    const width = endPosition.x - startPosition.x;
    const height = endPosition.y - startPosition.y;

    cleanCanvas();
    drawRectangle(startPosition.x, startPosition.y, width, height);
    isDrawStart = false;
  };

  const initCanvas = () => {
    if (!canvasRef.current)
    { return; }

    const canvas = canvasRef.current;

    const squareObj = {
      x: 0,
      y: 0,
      width: canvas.width,
      height: canvas.height,
      rgb: { r: 255, g: 255, b: 0, opacity: 0 },
    };

    const key = 'Background';
    canvasMapElements[key] = squareObj;
    onCanvasMounted();
  };
  return {
    clearCanvas,
    initCanvas,
    isDrawStart,
    mouseDownListener,
    mouseMoveListener,
    mouseUpListener,
    onCanvasMounted,
    prepareCanvas,
  };
}
