import { InfoMessage } from 'src/state/machines/versioning/versioningTypes'
import { MAX_OFFLINE_FILE_SIZE_BYTES } from 'src/worker/machines/sync/syncEntry'

export const INFO_MESSAGES: Record<
  'UPLOADING' | 'PROCESSING_ERROR' | 'QUEUED' | 'OPTIMIZING' | 'UPLOADED' | 'UPLOAD_CANCELLING' | 'FILE_TOO_BIG',
  InfoMessage
> = {
  UPLOADING: {
    message: 'New version is uploading. You can make edits after it is optimized.',
    status: 'primary',
  },
  UPLOAD_CANCELLING: {
    message: 'Please wait for upload to cancel ...',
    status: 'primary',
  },
  PROCESSING_ERROR: {
    message: 'Invalid file. Please delete draft and upload new file.',
    status: 'danger',
  },
  UPLOADED: {
    message: 'New version has been optimized. You can now make edits.',
    status: 'success',
  },
  QUEUED: {
    message: 'New version is queued for optimizing. You can make edits after it is optimized.',
    status: 'primary',
  },
  OPTIMIZING: {
    message: 'New version is currently optimizing. You can make edits after it is optimized.',
    status: 'primary',
  },
  FILE_TOO_BIG: {
    message: `This file exceeds the offline file size limit (${MAX_OFFLINE_FILE_SIZE_BYTES / 1048576} MB). ` +
      'Viewers will not be able to use this file offline.',
    status: 'warning',
  },

}
