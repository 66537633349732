import {
  ControlType, CustomFieldDefinition, CustomValues, FieldDataType,
  FieldStatus,
  FormatValidation,
} from '@alucio/aws-beacon-amplify/src/models';
import { CustomFieldValue, CustomFieldValuesMap } from 'src/types/orms';
import * as z from 'zod'
import { ZodNonEmptyArray, ZodString, ZodEffects, ZodOptional, ZodArray, ZodObject, ZodNullable } from 'zod';
import { v4 as uuid } from 'uuid';
import { OBJECT_RECORD_STATUS } from '@alucio/aws-beacon-amplify/src/API';
import { internalObjectFields } from './InternalObjectFields';
import { FormValuesType } from './ComposableForm';

export type ObjectWithId = {
  id: string,
  externalId?: string,
  status: OBJECT_RECORD_STATUS,
  [key: string]: string | string[] | undefined,
}

export type FieldControlConfig = {
    clampLength: boolean,
    showCharCount?: boolean,
  }

export type composableZodType = ZodNonEmptyArray<ZodString> |
  ZodEffects<ZodString, string, string> |
  ZodOptional<ZodArray<ZodString>> |
  ZodString |
  ZodOptional<ZodNullable<ZodString>> |
  ZodArray<ZodObject<any>> |
  ZodOptional<ZodString> |
  ZodObject<any> |
  z.ZodUnion<[ZodString, z.ZodLiteral<''>]>

export const isArrayOfObjects = (value: unknown) : boolean => {
  return Array.isArray(value) && value.every((val) => typeof val === 'object');
}

// [TODO]: REFACTOR AND OPTIMIZE THIS LOGIC
//        - We shoud have separate ones for categorical / multicategorical so it's more clear (instead of overloading logic)
const categoricalSchema = (field: CustomFieldDefinition) => {
  const acceptsMultiValues = field.fieldType === FieldDataType.MULTICATEGORICAL;

  if (field.required) {
    const validActiveFieldValueIds = field
      .fieldValueDefinitions
      .filter(fieldValDef => !fieldValDef.disabled)
      .reduce<string[]>(
        (acc, fieldValue) => ([...acc, `(${fieldValue.id})`]),
        [],
      );

    const requiredFieldIdsRegex = new RegExp(validActiveFieldValueIds.join('|'));

    if (field.controlType !== ControlType.CUSTOM) {
      return acceptsMultiValues
        ? z.string().min(1).regex(requiredFieldIdsRegex).array().nonempty()
        : z.string().min(1).regex(requiredFieldIdsRegex);
    }

    // [TODO-3077] - Double check custom control interface
    return acceptsMultiValues
      ? z.string().min(1).array().nonempty()
      : z.string().min(1);
  }
  else {
    return acceptsMultiValues
      ? z.string().min(1, { message: 'This field is required' }).array().optional()
      : z.string().optional();
  }
}

export const validFieldControlTypes: Record<
  FieldDataType,
  Partial<Record<ControlType, FieldControlConfig>>
> = {
  [FieldDataType.CATEGORICAL]: {
    [ControlType.SELECT]: {
      clampLength: false,
    },
    [ControlType.RADIOLIST]: {
      clampLength: false,
    },
    [ControlType.CHECKBOX]: {
      clampLength: false,
    },
  },
  [FieldDataType.MULTICATEGORICAL]: {
    [ControlType.SELECT]: {
      clampLength: true,
    },
    [ControlType.CHECKBOXLIST]: {
      clampLength: true,
    },
    [ControlType.CUSTOM]: {
      clampLength: true,
    },
  },
  [FieldDataType.DATE]: {
    [ControlType.DATEPICKER]: {
      clampLength: false,
    },
  },
  [FieldDataType.STRING]: {
    [ControlType.INPUT]: {
      clampLength: true,
      showCharCount: false,
    },
    [ControlType.TEXTAREA]: {
      clampLength: true,
      showCharCount: true,
    },
    [ControlType.LABEL]: {
      clampLength: false,
    },
  },
  [FieldDataType.USER_LIST]: {
    [ControlType.USERLIST]: {
      clampLength: false,
    },
  },
  [FieldDataType.OBJECT]: {
    [ControlType.OBJECT]: {
      clampLength: false,
    },
  },
}

export const isTextInputControl = {
  [ControlType.INPUT]: true,
  [ControlType.TEXTAREA]: true,
  // [NOTE] Not sure if we should create an isDateInputControl
  [ControlType.DATEPICKER]: true,
}

// Error Handling in Zod Documentation: https://github.com/colinhacks/zod/blob/master/ERROR_HANDLING.md
const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type || issue.code === z.ZodIssueCode.too_small) {
    return { message: 'This field is required.' };
  }
  return { message: ctx.defaultError };
};

z.setErrorMap(customErrorMap);

// [TODO-2780] - Consider adding the override options in here
// [TODO-2780] - Consider creating smaller utility functions to pipe
export const fieldToSchema = (fields: CustomFieldDefinition[]) => {
  const mappedFields = fields.filter(field => field.status === FieldStatus.ENABLED && !field.isChildField)
    .map(field => {
      if (field.fieldType !== FieldDataType.OBJECT) {
        return getFieldSchema(field)
      }
      else {
        const childFields = fields.filter(childField => childField.isChildField &&
          field.objectSetting?.childrenFieldIds?.includes(childField.id))
        let objSchema
        childFields.forEach(childField => {
          const childSchema = getFieldSchema(childField)
          objSchema = objSchema ? objSchema.extend({ [childField.id] : childSchema.fieldSchema })
            : z.object({ [childField.id]: childSchema.fieldSchema })
        })
        if (objSchema) { objSchema = objSchema.extend({
          id: z.string().optional(),
          externalId: z.string().optional().nullable(),
        }) }
        return {
          fieldName: field.id,
          fieldSchema: field.required
            ? z.array(objSchema,
              { required_error: `Please add at least one ${field.fieldLabel.toLowerCase()}` }).nonempty()
            : z.array(objSchema).optional(),
        }
      }
    },

    )

  // eslint-disable-next-line comma-spacing
  const fieldSchemas = mappedFields.reduce<Record<string,(composableZodType)>>(
    (acc, field) => ({
      ...acc,
      [field.fieldName]: field.fieldSchema,
    }),
  {},
  )

  return z.object(fieldSchemas)
}

const getFieldSchema = (field: CustomFieldDefinition) =>
{
  const fieldConfig = validFieldControlTypes[field.fieldType][field.controlType]

  // Check if field has valid type/control combinations
  if (!fieldConfig) {
    // eslint-disable-next-line max-len
    throw new Error(`${field.fieldType} + ${field.controlType} is not a valid field combination. Please check the custom fields config`)
  }

  // String fields will have the schema shape of
  // z.string()
  //  .min(1) || .optional()
  //  ?.max()
  let schemaField: composableZodType

  const shouldLimitLength = field.maxLength &&
        isTextInputControl[field.controlType] &&
        fieldConfig?.clampLength

  if (field.id === 'contentURL') {
    schemaField = z.string()
      .min(1)

    /** NOTE: After .refine processes the schema field we no longer have access to the .max method
         * on the string. This allows us to set it based on the relevant conditions. Bit of a workaround
         * for the .max method not supporting conditional activation */
    schemaField = (shouldLimitLength
      ? schemaField
        .max(field.maxLength)
      : schemaField)
      .refine(isValidUrl, { message: 'Invalid URL format' });
  }
  else if (field.formatValidation === FormatValidation.EMAIL) {
    schemaField = z.string().email({ message: 'Invalid Email' })
    if (field.required) {
      schemaField = schemaField.min(1, { message: 'This field is required ' })
    }
    else {
      schemaField = schemaField.or(z.literal(''))
    }
  }
  else if (field.required && field.controlType !== ControlType.LABEL) {
    schemaField = z.string().min(1, { message: 'This field is required ' })
    shouldLimitLength && schemaField.max(field.maxLength)
  }
  else {
    // [TODO-2780] - Find a way to avoid the unknown here
    schemaField = z.string().nullable().optional()
  }

  // For categorical/multicategorical types, we have to now whitelist string values
  if ([FieldDataType.MULTICATEGORICAL, FieldDataType.CATEGORICAL].includes(field.fieldType as FieldDataType)) {
    schemaField = categoricalSchema(field)
  }

  return {
    fieldName: field.id,
    fieldSchema: schemaField,
  }
}

const isValidUrl = (str: string) => {
  try {
    const url = new URL(str)
    return (str.startsWith('https://') || str.startsWith('http://')) && url.hostname
  }
  catch (e) {
    return false
  }
}

export const formToModel = (values: FormValuesType): CustomValues[] => {
  return Object
    .entries(values)
    .reduce((acc, [fieldId, values]) => {
      if (!values) {
        return acc
      }
      const isObjArr = isArrayOfObjects(values)
      // if the field is an object, we need to map the values to the correct format
      if (isObjArr) {
        const objectRecords = (<ObjectWithId[]>values).map(obj => {
          return {
            id: obj.id || uuid(),
            externalId: obj.externalId,
            status: obj.status || OBJECT_RECORD_STATUS.ACTIVE,
            values : [...Object.entries(obj).filter(([key]) => !internalObjectFields.map(f => f.id).includes(key))
              .map(([key, value = []]) => ({
                fieldId: key,
                values: Array.isArray(value) ? value : [value],
              }))],
          }
        })

        acc = [
          ...acc,
          {
            fieldId: fieldId,
            values: [],
            objectRecords,
          },
        ];

        return acc
      }

      acc = [
        ...acc,
        {
          fieldId: fieldId,
          values: Array.isArray(values) ? <string[]>values : [values],
        },
      ];

      return acc
    }, [] as CustomValues[])
}

// Model -> Form Object
export const getDefaultValues = (customValuesMap: CustomFieldValuesMap)
: FormValuesType => {
  return Object
    .entries(customValuesMap)
    .reduce(
      (acc, [_fieldId, value]) => {
        if (!value.field) { return acc; }
        if (value.field.fieldType === FieldDataType.OBJECT) {
          acc[value.field.id] = []
          if (!value.objectValues || !Array.isArray(value.objectValues)) return acc
          value.objectValues?.forEach((objectValue) => {
            const val = { id: objectValue.id, externalId: objectValue.externalId, status: objectValue.status }
            const child =  Object.entries(objectValue.customFieldValues).reduce((val, [key, childValue]) => {
              val[key] = getDefaulValueFlatField(childValue)
              return val
            }, val)
            acc[value.field.id].push(child)
          })
        }
        else {
          acc[value.field?.id] = getDefaulValueFlatField(value)
        }
        return acc;
      },
      {},
    );
}

const getDefaulValueFlatField = (value : CustomFieldValue) => {
  if (value.field.fieldType === FieldDataType.MULTICATEGORICAL) {
    return value.values || [];
  }
  else {
    return value.values[0] || '';
  }
}
