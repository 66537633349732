import React from 'react';
import { StyleSheet } from 'react-native';
import { Controller } from 'react-hook-form';
import { DNACard, DNAContextMenu, DNAButton } from '@alucio/lux-ui/src';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { HubSectionType } from '@alucio/aws-beacon-amplify/src/models';
import { ModifiedHubSection } from 'src/state/context/Hubs/HubsStateProvider';
import { EhitHubRhform } from 'src/screens/Hubs/EditHub/useHubForm';
import Textarea from 'src/screens/Hubs/EditHub/Widgets/Textarea';
import SharedFiles from 'src/screens/Hubs/EditHub/Widgets/SharedFiles';
import TodoList from './ToDoList';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import { rhformFieldName, useHubRHFromState } from 'src/screens/Hubs/EditHub/useHubRHFormStateProvider';

export const widgetContentCardStyles = StyleSheet.create({
  card: {
    borderColor: colors['color-gray-80'],
    borderWidth: 1,
    borderRadius: 4,
  },
  cardHeader: {
    minHeight: 64,
    maxHeight: 64,
    paddingHorizontal: 24,
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
  },
  cardBody: {
    minHeight: 72,
    padding: 24,
  },
  contextMenuItems: {
    minWidth: 160,
    right: 66,
  },
  contextMenuAnchorBtn: {
    width: 32,
    height: 32
  },
});

export interface WidgetContentCardProps {
  widget: ModifiedHubSection,
  rhform: EhitHubRhform,
}

const WidgetContentCardVariants: Record<HubSectionType, React.FC<WidgetContentCardProps>> = {
  [HubSectionType.TEXTAREA]: Textarea,
  [HubSectionType.SHARED_FILES]: SharedFiles,
  [HubSectionType.TODO_LIST]: TodoList,
}

const WidgetContentCard: React.FC<WidgetContentCardProps> = ({ widget, rhform }) => {
  const WidgetVariant = WidgetContentCardVariants[widget.type]

  return (
    <DNACard
      style={ widgetContentCardStyles.card }
      appearance="flat"
    >
      <Controller
        name={rhformFieldName[widget.type]}
        title=""
        control={rhform.control}
        hideLabel
        render={() => (
          <WidgetVariant widget={widget} rhform={rhform}/>
        )}
      />
    </DNACard>
  )
}

export default WidgetContentCard


// Below are reusable components for widgets

type WidgetContextMenuProp = {
  widgetType: HubSectionType
}

export const WidgetContextMenu: React.FC<WidgetContextMenuProp> = ({ widgetType }) => {
  const { handleDeleteWidget, widgetOptions } = useHubsState()
  const { rhform } = useHubRHFromState()
  return (
    <DNAContextMenu style={ widgetContentCardStyles.contextMenuItems }>
      <DNAContextMenu.Anchor>
        <DNAButton
          iconRight="dots-vertical"
          appearance="outline"
          status="tertiary"
          padding="xs"
          size="md"
          style={ widgetContentCardStyles.contextMenuAnchorBtn }
        />
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        <DNAContextMenu.Item
          collapseOnPress
          delay={100} // Workaround because sometimes the item deletes too fast before the ContextMenu can hide itself
          title="Delete"
          status="danger"
          iconStatus="danger"
          icon="trash-can-outline"
          onPress={() => handleDeleteWidget(widgetType, rhform)}
        />
        { widgetOptions[widgetType]?.map((option, index) => (
          <DNAContextMenu.Item
            key={`${widgetType}-option-${index}`}
            collapseOnPress
            title={option.title}
            icon={option.icon}
            onPress={option.onPress}
          />
        ))}
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}
