import React, { useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAChip, DNAText, Hoverable, Iffy, luxColors } from '@alucio/lux-ui';
import { AssociatedFileORM, DocumentORM, getAssociatedFileTitle } from 'src/types/types';
import { downloadContentFromCloudfront } from 'src/utils/loadCloudfrontAsset/loadCloudfrontAsset';
import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { isAssociatedFileAttachment, isPublishedDocumentAttachment } from 'src/types/typeguards';
import mergeStyles from '@alucio/lux-ui/src/components/util/mergeStyles';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { useContentPreviewModalState } from '../../State/ContentPreviewModalStateProvider';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList';
import { useAllDocumentsInstance } from 'src/state/redux/selector/document';
import { FileType } from '@alucio/aws-beacon-amplify/src/models';

const styles = StyleSheet.create({
  fileRow: {
    paddingBottom: 16,
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
    maxWidth: 280,
  },
  hoveredRow: {
    backgroundColor: luxColors.mainContainer.primary,
  },
})

interface FileRowProps {
  file: AssociatedFileORM,
}

const AssociatedDocumentRow: React.FC<FileRowProps> = ({ file }) => {
  const { addPresentation } = useContent()
  const { setIsAssociatedFile } = useContentPreviewModalState()
  const [attachedDocumentORM]:(DocumentORM)[] = useAllDocumentsInstance({
    filter: {
      model: { id: file.file?.id },
    },
  })
  const attachedDocumentVersionORM = attachedDocumentORM.relations.version.latestUsableDocumentVersionORM
  const documentActions = useDNADocumentActions()

  const [isHovered, setIsHovered] = useState<boolean>(false);

  const toggleHover = () => {
    setIsHovered(currVal => !currVal);
  }

  const onFileSelected = () => {
    setIsAssociatedFile(true)
    addPresentation(attachedDocumentVersionORM)
  }
  const isWebDoc = attachedDocumentORM.model.type === FileType.WEB
  return (
    <Pressable onPress={onFileSelected}>
      <Hoverable onHoverIn={toggleHover} onHoverOut={toggleHover}>
        <DNABox
          childFill
          appearance="col"
          spacing="md"
          style={mergeStyles(undefined, styles.fileRow, [styles.hoveredRow, isHovered])}
        >
          <DNAText testID="cpm-associated-file-text" style={{ maxWidth: 280 }}>{getAssociatedFileTitle(file)}</DNAText>
          <DNABox
            fill
            spacing="between"
            alignY="end"
          >
            <DNABox spacing="xs">
              <CustomFieldBadgeList
                documentVersionORM={attachedDocumentVersionORM}
              />
              <DNAChip appearance="tag">{attachedDocumentORM.model.type}</DNAChip>
            </DNABox>
            <Iffy is={attachedDocumentORM.meta.permissions.MSLDownload && !isWebDoc}>
              <DNABox alignX="end" alignY="center">
                <DNAButton
                  appearance="ghostLink"
                  status="gray"
                  iconLeft="download"
                  size="sm"
                  onPress={documentActions.download(attachedDocumentORM)}
                />
              </DNABox>
            </Iffy>
          </DNABox>
        </DNABox>
      </Hoverable>
    </Pressable>
  )
}

const AttachedFileRow: React.FC<FileRowProps> = (props) => {
  const { file } = props
  const attachedFile = file.file
  const [isHovered, setIsHovered] = useState<boolean>(false);
  if (!attachedFile || isPublishedDocumentAttachment(attachedFile)) return null

  // TODO CHECK ID FOR ATTACHED DOCUMENTS
  const onFileSelected = () => {
    downloadContentFromCloudfront(attachedFile.srcFile.key, attachedFile.srcFileName, attachedFile.type)
    analytics?.track('DOCUMENT_AF_DOWNLOAD', {
      action: 'AF_DOWNLOAD',
      attachedContentId: file.model.attachmentId,
      category: 'DOCUMENT',
      documentId: file.relations.latestUsableDocumentVersion?.documentId,
      type: file.model.type,
    });
  }
  return (
    <Pressable onPress={onFileSelected}>
      <Hoverable
        onHoverIn={() => setIsHovered(currVal => !currVal)}
        onHoverOut={() => setIsHovered(currVal => !currVal)}
      >
        <DNABox appearance="col" style={mergeStyles(undefined, styles.fileRow, [styles.hoveredRow, isHovered])}>
          <DNAText style={{ maxWidth: 280 }}>{attachedFile.title}</DNAText>
          <DNABox
            fill
            spacing="between"
            alignY="end"
          >
            <DNAChip
              appearance="tag"
              style={{ alignSelf: 'flex-start' }}
            >
              {attachedFile.type}
            </DNAChip>
            <DNABox alignX="end" alignY="center">
              <DNAButton
                appearance="ghostLink"
                status="gray"
                iconLeft="download"
                size="sm"
                onPress={onFileSelected}
              />
            </DNABox>
          </DNABox>
        </DNABox>
      </Hoverable>
    </Pressable>
  )
}

const AssociatedFiles: React.FC = () => {
  const { activeDocumentVersionORM } = useContentPreviewModalState()
  if (!activeDocumentVersionORM) return null

  const versionElements = activeDocumentVersionORM.relations.associatedFiles
    .filter(({ model, file }) => {
      if (isAssociatedFileAttachment(model)) return true
      return isPublishedDocumentAttachment(file)
    })
    .map(assocFile => {
      const { file, model: { id } } = assocFile
      if (isPublishedDocumentAttachment(file)) {
        return (<AssociatedDocumentRow
          file={assocFile}
          key={id}
        />)
      }
      return (<AttachedFileRow
        file={assocFile}
        key={id}
      />)
    })

  return (
    <DNABox fill appearance="col" spacing="md">
      {versionElements}
    </DNABox>
  )
}

export default AssociatedFiles;
