import { DNABox } from '@alucio/lux-ui'
import PlayerWrapper from 'src/components/Presentation/PlayerWrapper'
import React, { useEffect, useRef, useState } from 'react'
import { HighlighterProvider } from 'src/components/Highlighter/HighlighterProvider'
import { PopoutContentBCPayloads } from 'src/state/context/Meetings/Hooks/useMeetingsPopoutContentState'
import { BroadcastChannel } from 'broadcast-channel'
import { MeetingORM } from 'src/types/orms'
import { Logger } from '@aws-amplify/core'

interface ContentProps {
  meetingId: string
}

const logger = new Logger('Popout Content Window', 'INFO');

const Content: React.FC<ContentProps> = ({ meetingId }) => {
  return (
    <DNABox fill style={{ backgroundColor: 'black' }}>
      <HighlighterProvider>
        <PlayerWrapper
          isFullWindow={true}
          meetingId={meetingId}
          mode="NON_INTERACTIVE"
          isFromMeetings={true}
          highlighterVisible
        />
      </HighlighterProvider>
    </DNABox>
  )
}

const PopoutContent: React.FC = () => {
  const broadcastChannel = useRef<BroadcastChannel<PopoutContentBCPayloads>>()
  const [meetingORM, setMeetingORM] = useState<MeetingORM>()

  /** NOTE: Init hook */
  const handleInit = () => {
    broadcastChannel.current = new BroadcastChannel<PopoutContentBCPayloads>('meeting-content')
    broadcastChannel.current.postMessage({ type:'popoutWindowLoaded' })
    broadcastChannel.current.onmessage = (payload) => {
      logger.debug('Broadcast channel message received', payload)
      switch (payload.type) {
        case 'meetingLoaded':
          setMeetingORM(payload.meetingORM)
          break
      }
    }
    return () => {
      broadcastChannel.current?.close()
    }
  }

  useEffect(handleInit, [])

  return meetingORM ? <Content meetingId={meetingORM.model.id} /> : null
}

export default PopoutContent
