import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid';
import im from 'immer'
import cloneDeep from 'lodash/cloneDeep'
import { commonReducers, initialState, SliceState } from './common'
import { store } from 'src/state/redux';
import {
  DocumentStatus,
  DocumentVersion,
  AssociatedFileType,
  AssociatedFileStatus,
  AssociatedFile,
  AttachedFile,
} from '@alucio/aws-beacon-amplify/src/models'
import activeUser from 'src/state/global/ActiveUser'
import { DocumentORM, DocumentVersionORM, AssociatedFileORM } from 'src/types/types'

export const sliceName = 'documentVersion'
const { reducers, extraReducers } = commonReducers<DocumentVersion>(sliceName)

export const createAssociatedFile = (
  payload: Omit<
    AssociatedFile,
    'id' | 'createdAt' | 'createdBy'
  >,
): AssociatedFile => {
  if (!activeUser.user?.id) {
    throw new Error('Could not find current logged in User')
  }

  return {
    id: uuid(),
    isDefault: false,
    createdAt: new Date().toISOString(),
    createdBy: activeUser.user.id,
    ...payload,
  }
}

const documentVersionSlice = createSlice({
  name: sliceName,
  initialState: initialState<DocumentVersion>(),
  reducers: {
    ...reducers,
    prePublish: {
      prepare: (documentVersionORM: DocumentVersionORM, updates: Partial<DocumentVersion>) => {
        const documentORM = documentVersionORM.relations.documentORM
        const isFirstVersion = !documentORM.relations.version.latestPublishedDocumentVersionORM
        const isMajorChange = updates.changeType === 'MAJOR'
        const latestVersion = documentORM.relations.version.latestUsableDocumentVersionORM.model

        // versionForm semVer can be null for a new version from upload (lambda fn doesnt fill the field)
        // for those cases we try to fallback to semVer value from event payload
        const currentSemVer = documentVersionORM.model.semVer ?? latestVersion.semVer!

        return {
          payload: {
            model: DocumentVersion,
            entity: documentVersionORM.model,
            updates: {
              ...updates,
              publishedAt: new Date().toISOString(),
              integrationType: documentVersionORM.meta.integration.integrationType,
              integration: documentVersionORM.meta.integration.integration,
              semVer: isFirstVersion
                ? { major: 1, minor: 0 }
                : {
                  major: currentSemVer.major + (isMajorChange ? 1 : 0),
                  minor: isMajorChange ? 0 : currentSemVer.minor + 1,
                },
            },
          },
        }
      },
      reducer: reducers.save,
    },
    delete: {
      prepare: (documentVersionORM: DocumentVersionORM) => {
        if (documentVersionORM.model.status === 'PUBLISHED') {
          throw new Error('You cannot delete a Published Version')
        }

        return {
          payload: {
            model: DocumentVersion,
            entity: documentVersionORM.model,
            updates: { status: DocumentStatus.DELETED },
          },
        }
      },
      reducer: reducers.save,
    },
    /** This reducer will update the document version with the provided title. If no
     * title is provided or the title is an empty string, it will reset the title to
     * the original source file name */
    rename: {
      prepare: (documentVersionORM: DocumentVersionORM, updatedTitle) => {
        const newTitle = updatedTitle || documentVersionORM.model.srcFilename
        return {
          payload: {
            model: DocumentVersion,
            entity: documentVersionORM.model,
            updates: { title: newTitle },
          },
        }
      },
      reducer: reducers.save,
    },
    linkFile: {
      prepare: (targetDocVerORM: DocumentVersionORM, linkedDoc: DocumentORM) => {
        const newAssociatedFile = createAssociatedFile({
          attachmentId: linkedDoc.model.id,
          type: AssociatedFileType.DOCUMENT,
          status: AssociatedFileStatus.ACTIVE,
        })

        analytics?.track('DOCUMENT_AF_ATTACHED', {
          action: 'AF_ATTACHED',
          category: 'DOCUMENT',
          documentId: targetDocVerORM.model.documentId,
          documentVersionId: targetDocVerORM.model.id,
          type: 'LINK',
          attachedContentId: linkedDoc.model.id,
        });
        return {
          payload: {
            model: DocumentVersion,
            entity: targetDocVerORM.model,
            updates: {
              associatedFiles: [
                ...targetDocVerORM.model.associatedFiles ?? [],
                newAssociatedFile,
              ],
            },
          },
        }
      },
      reducer: reducers.batchSave,
    },
    removeAssociatedFile: {
      prepare: (parentDoc: DocumentVersionORM, linkedDoc: DocumentVersionORM) => {
        const associatedFilesCopy = cloneDeep(parentDoc.model.associatedFiles)
        const linkedFileIdx = associatedFilesCopy?.findIndex(associatedFile => associatedFile.id === linkedDoc.model.id)
        const temp = Object.assign(
          { status: AssociatedFileStatus.DELETED },
          associatedFilesCopy![linkedFileIdx!],
        )

        associatedFilesCopy?.splice(linkedFileIdx!, 1, temp)

        return {
          payload: {
            model: DocumentVersion,
            entity: parentDoc.model,
            updates: {
              associatedFiles: associatedFilesCopy,
            },
          },
        }
      },
      reducer: reducers.save,
    }, // [TODO]: DELETE THIS FUNCTION WHEN THE OLD VERSIONING IS REPLACED
    toggleRequired: {
      prepare: (
        docVerORM: DocumentVersionORM,
        associatedFileORM: AssociatedFileORM,
        value?: AssociatedFile['isDefault'],
      ) => {
        const updatedAssociatedFiles = im(
          docVerORM.model.associatedFiles,
          draft => {
            if (!draft) return []
            const target = draft.find(associatedFile => associatedFile.id === associatedFileORM.model.id)
            if (!target) return;
            target.isDefault = value ?? !target.isDefault
          },
        )

        return {
          payload: {
            model: DocumentVersion,
            entity: docVerORM.model,
            updates: {
              associatedFiles: updatedAssociatedFiles,
            },
          },
        }
      },
      reducer: reducers.batchSave,
    }, // [TODO]: DELETE THIS FUNCTION WHEN THE OLD VERSIONING IS REPLACED
    toggleAttachmentIsDistributable: {
      prepare: (
        docVerORM: DocumentVersionORM,
        associatedFileORM: AssociatedFileORM,
        value?: AssociatedFile['isDistributable'],
      ) => {
        const updatedAssociatedFiles = im(
          docVerORM.model.associatedFiles,
          draft => {
            if (!draft) return []
            const target = draft.find(associatedFile => associatedFile.id === associatedFileORM.model.id)
            if (!target) return;
            target.isDistributable = value ?? !target.isDistributable
            // Disable default (required) if ! distributable
            target.isDefault = target.isDistributable && target.isDefault
          },
        )

        return {
          payload: {
            model: DocumentVersion,
            entity: docVerORM.model,
            updates: {
              associatedFiles: updatedAssociatedFiles,
            },
          },
        }
      },
      reducer: reducers.batchSave,
    },
    createAttachedFileLink: {
      prepare: (
        docVerId: DocumentVersion['id'],
        attachedFile: AttachedFile,
      ) => {
        const docVerSlice = store.getState().documentVersion as any as SliceState<DocumentVersion>
        const docVer = docVerSlice.records.find(record => record.id === docVerId)

        if (!docVer) {
          throw new Error(`Could not find Document Version with id: ${docVerId}`)
        }

        const newAssociatedFile = createAssociatedFile({
          attachmentId: attachedFile.id,
          type: AssociatedFileType.ATTACHED_FILE,
          status: 'ACTIVE',
        })

        const updatedAssociatedFiles = [
          ...docVer.associatedFiles ?? [],
          newAssociatedFile,
        ]
        analytics?.track('DOCUMENT_AF_ATTACHED', {
          action: 'AF_ATTACHED',
          category: 'DOCUMENT',
          documentId: docVer.documentId,
          documentVersionId: docVer.id,
          type: 'UPLOAD',
          attachedContentId: attachedFile.id,
        });

        return {
          payload: {
            model: DocumentVersion,
            entity: docVer,
            updates: {
              associatedFiles: updatedAssociatedFiles,
            },
          },
        }
      },
      reducer: reducers.batchSave,
    },
  },
  extraReducers,
})

export default documentVersionSlice
export const documentVersionActions = documentVersionSlice.actions
