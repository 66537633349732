import React from 'react';
import { DNABox, DNAButton, DNAText, Iffy } from '@alucio/lux-ui';
import { styles } from './PresentationSelector';
import { useAppSettings } from 'src/state/context/AppSettings';
import { usePresentationSelector } from './PresentationSelectorStateProvider';
import DNADocumentChip from 'src/components/DNA/Document/DNADocumentChip';
import { DocumentAccessLevel } from '@alucio/aws-beacon-amplify/src/models';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList';
import { usePresentationBuilderState } from '../state/PresentationBuilderStateProvider';

const SubHeader: React.FC = () => {
  const {
    selectedDocumentVersionORM,
    searchResultsText,
    setSelectedDocumentVersionORM,
  } = usePresentationSelector()

  const {
    selectorThumbnailSize,
    cycleSelectorThumbnailSize,
  } = usePresentationBuilderState()

  const { deviceMode } = useAppSettings()
  const isTablet = deviceMode === 'tablet'
  const isPersonalFile =
    selectedDocumentVersionORM?.relations.documentORM.model.accessLevel === DocumentAccessLevel.USER;

  return (
    <DNABox alignY="center" style={{ marginHorizontal: 64 }}>
      <Iffy is={!selectedDocumentVersionORM}>
        <DNABox style={{ marginTop: 16 }}>
          <DNAText
            h5
            status="secondary"
            style={styles.searchResultsText}
          >
            {searchResultsText}
          </DNAText>
        </DNABox>
      </Iffy>
      <Iffy is={selectedDocumentVersionORM}>
        <DNABox
          fill
          alignY="center"
          style={{ height: 80 }}
        >
          <DNABox style={{ marginRight: 16 }}>
            <DNAButton
              status="tertiary"
              appearance="outline"
              onPress={() => setSelectedDocumentVersionORM(undefined)}
              iconLeft="chevron-left"
              padding="sm"
            />
          </DNABox>
          <DNABox appearance="col" fill spacing="sm">
            <DNAText numberOfLines={1} b1>{selectedDocumentVersionORM?.model.title}</DNAText>
            <DNABox appearance="row" spacing="md">
              <Iffy is={!isPersonalFile}>
                <CustomFieldBadgeList documentVersionORM={selectedDocumentVersionORM}/>
              </Iffy>
              { selectedDocumentVersionORM &&
                <DNADocumentChip
                  item={selectedDocumentVersionORM.relations.documentORM}
                  variant="docType"
                />}
            </DNABox>
          </DNABox>
          { !isTablet && <DNABox fill alignX="end">
            <DNAButton
              appearance="outline"
              status="tertiary"
              size="md"
              padding="sm"
              iconLeft={selectorThumbnailSize === 'lg' ? 'view-comfy' : 'view-grid'}
              onPress={cycleSelectorThumbnailSize}
            />
          </DNABox> }
        </DNABox>
      </Iffy>
    </DNABox>

  )
}

export default SubHeader
