import React from 'react'
import { DocumentORM } from 'src/types/types'
import { DNACardComponent } from 'src/components/DNA/Grid/Cards/types'

import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions'

import {
  DNAButton,
  DNABox,
  DNAText,
  DNACard,
  DNACheckbox,
  DNADivider,
  Iffy,
  DNAChip,
  luxColors,
  Stack,
} from '@alucio/lux-ui'
import DNADocumentContextMenu from 'src/components/DNA/Document/DNADocumentContextMenu/DNADocumentContextMenu'
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail'
import { CUSTOM_DECK_CARD_MAX_HEIGHT } from './constants'
import useCurrentPage from 'src/components/DNA/hooks/useCurrentPage'
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList'
import { FileType } from '@alucio/aws-beacon-amplify/src/models'

export const DNADocumentDefaultCard: DNACardComponent<DocumentORM> = (props) => {
  const { isDesktop, ORM: documentORM, isOnline, onPress, selectEnabled, checked, onSelect } = props
  const documentActions = useDNADocumentActions()
  const isBookmarked = documentORM.meta.bookmark.isBookmarked;
  // [TODO-2126] - We should have a property that combines isOnline and isContentCached
  //               on the DocumentVersionORM rather than checking both at the component level
  const unavailableContent =
    !isOnline &&
    (!documentORM.relations.version.cachedDocumentVersionORM?.meta.assets.isContentCached ||
      documentORM.model.type === FileType.WEB);
  const canPresent = documentORM.meta.permissions.MSLPresent
  const safeOnPress = onPress
    ? () => { onPress?.(documentORM) }
    : undefined

  const thumbnailOnPress = canPresent
    ? documentActions.present(documentORM)
    : documentActions.preview(documentORM)
  const cardOnPress = documentActions.preview(documentORM)

  const route = useCurrentPage()
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const title = documentORM.relations.version.latestUsableDocumentVersionORM.model.title

  return (
    <DNABox
      testID="document-card-container"
      alignX="start"
      style={{ minWidth: 272, height: CUSTOM_DECK_CARD_MAX_HEIGHT, opacity: unavailableContent ? 0.4 : 1 }}
    >
      <DNACard
        appearance={isDesktop ? 'float' : 'outline'}
        interactive="pressable"
        onPress={ unavailableContent ? undefined : (safeOnPress || cardOnPress)}
        style={{ padding: 12 }}
      >
        <DNABox
          testID="document-card-thumbnail"
          appearance="col"
          spacing="between"
        >
          <Stack anchor={'topLeft'}>
            <Stack.Layer>
              <DNADocumentThumbnail
                documentVersionORM={
                  documentORM.relations.version.latestUsableDocumentVersionORM
                }
                width={248}
                height={142}
                unavailableContent={unavailableContent}
                /** Custom behavior here. Thumbs in Publisher routes just open the preview modal,
                * whereas MSL views will either present or preview based on whether a doc is internal */
                onPress={ unavailableContent ? undefined
                  : isPublisherRoute ? safeOnPress || thumbnailOnPress : thumbnailOnPress
                }
              />
            </Stack.Layer>
            <Stack.Layer>
              <Iffy is={selectEnabled}>
                <DNACheckbox
                  context="altBg"
                  status="primary"
                  style={{ margin: 6 }}
                  checked={checked}
                  onChange={onSelect}
                />
              </Iffy>
            </Stack.Layer>
          </Stack>
          <DNABox appearance="col" spacing="md">
            <DNAText
              testID="document-card-title"
              style={{ width: 208, overflow: 'hidden', height: 38 }}
              numberOfLines={2}
            >
              {title}
            </DNAText>
            <DNABox appearance="col" spacing="sm">
              <DNADivider />
              <DNABox alignY="center" spacing="between">
                <CustomFieldBadgeList
                  documentVersionORM={documentORM.relations.version.latestUsableDocumentVersionORM}
                />
                <Iffy is={unavailableContent}>
                  <DNAChip
                    appearance="tag"
                    style={{ backgroundColor: luxColors.basicBlack.primary }}
                    testID={`content-not-available-${title}`}
                  >
                    CONTENT NOT AVAILABLE
                  </DNAChip>
                </Iffy>
                <DNABox>
                  <DNAButton
                    appearance="ghostLink"
                    status="gray"
                    size={isDesktop ? 'md' : 'lg'}
                    padding={isDesktop ? 'sm' : 'md'}
                    rounded="md"
                    onPress={documentActions.bookmark(documentORM)}
                    iconLeft={isBookmarked ? 'bookmark' : 'bookmark-outline' }
                  />
                  <DNADocumentContextMenu documentORM={documentORM}>
                    <DNAButton
                      appearance="ghostLink"
                      status="gray"
                      size={isDesktop ? 'md' : 'lg'}
                      padding={isDesktop ? 'sm' : 'md'}
                      rounded="md"
                      iconLeft="dots-vertical"
                    />
                  </DNADocumentContextMenu>
                </DNABox>
              </DNABox>
            </DNABox>
          </DNABox>
        </DNABox>
      </DNACard>
    </DNABox>
  );
}

export default DNADocumentDefaultCard
