import {
  CustomFieldDefinition,
  CustomValues,
  Document,
  DocumentAccessLevel,
  DocumentStatus,
  DocumentVersion,
  FieldStatus,
  User,
} from '@alucio/aws-beacon-amplify/src/models'

interface IndexedCustomFieldValues {
  [x: string]: string[]
}

export function matchesLockedFilters(
  filters: CustomValues[],
  indexedCustomFields: IndexedCustomFieldValues,
): (isPublished: boolean, customValuesToCompare?: CustomValues[]) => boolean {
  return function (isPublished: boolean, customValuesToCompare?: CustomValues[]): boolean {
    if (filters.length < 1) {
      return true
    }

    for (const filter of filters) {
      let matchesFilter = false;
      let hasKeyDefinedWithValues = false;
      const fieldDefinition = indexedCustomFields[filter.fieldId];
      const validValues = filter.values.filter((valueId) => fieldDefinition?.includes(valueId));

      if (!fieldDefinition || !validValues?.length) {
        continue;
      }

      // AT LEAST ONE OF THE VALUES MUST MATCH FOR THE GIVEN FIELD
      for (const customValue of customValuesToCompare || []) {
        if (customValue.fieldId === filter.fieldId) {
          hasKeyDefinedWithValues = customValue.values.some((id) => fieldDefinition?.includes(id));
          matchesFilter = validValues.some((filterValue) => customValue.values.includes(filterValue));
        }
      }
      if (!matchesFilter && (isPublished || hasKeyDefinedWithValues)) {
        return false;
      }
    }
    return true;
  }
}

interface DocVersionRecord {
  versions: DocumentVersion[],
  document: Document
}

// [TODO-REDUX] - We should revisit this whenever we move redux to a key based object instead of array of records
export function filterDocumentsForDeletedAndLockedFilters(
  currentUser: User,
  documents: Document[],
  versions: DocumentVersion[],
  validIndexedCustomFields: IndexedCustomFieldValues): { documents: Document[], versions: DocumentVersion[] } {
  const initVersionLookup = documents.reduce<Record<string, DocVersionRecord>>((acc, doc) => {
    if (doc.status !== DocumentStatus.DELETED) {
      acc[doc.id] = {
        versions: [],
        document: doc,
      }
    }
    return acc
  }, {})
  const versionLookup = versions.reduce<Record<string, DocVersionRecord>>((acc, currVersion) => {
    if (acc[currVersion.documentId] && currVersion.status !== DocumentStatus.DELETED) {
      acc[currVersion.documentId].versions = [...acc[currVersion.documentId].versions, currVersion]
    }
    return acc
  }, initVersionLookup)

  const matchFunction = matchesLockedFilters(currentUser.lockedFiltersCustomValues || [], validIndexedCustomFields)
  return Object.keys(versionLookup).reduce<{
    documents: Document[],
    versions: DocumentVersion[]
  }>((acc, documentId) => {
    const versions = versionLookup[documentId].versions.sort((a, b) => b.versionNumber - a.versionNumber)
    // Standard logic of use latest published version or fallback to latest version
    const lastUsableVersion = versions.find((ver) => ver.status === DocumentStatus.PUBLISHED) ?? versions[0]
    const isPersonalFile = versionLookup[documentId].document.accessLevel === DocumentAccessLevel.USER &&
        versionLookup[documentId].document.createdBy === currentUser.email;
    if (isPersonalFile ||
        matchFunction(
          lastUsableVersion.status === DocumentStatus.PUBLISHED,
          lastUsableVersion?.customValues)) {
      acc.versions = [...acc.versions, ...versions]
      acc.documents = [...acc.documents, versionLookup[documentId].document]
    }
    return acc
  }, { documents: [], versions: [] })
}

// DURING THE FILTERING PROCESS, WHILE HYDRATING REDUX, FOR EMAIL_TEMPLATES AND DOCUMENTS,
// WE NEED TO PERFORM THE CHECK USING VALID FIELDS/VALUES SO WE HAVE THIS FUNCTION TO GET THEM
export function getIndexedValidCustomFields(customFields: CustomFieldDefinition[]): IndexedCustomFieldValues {
  return customFields.reduce<IndexedCustomFieldValues>((acc, customField) => {
    if (customField.status === FieldStatus.DISABLED) {
      return acc;
    }

    acc[customField.id] = customField.fieldValueDefinitions.reduce<string[]>((_acc, valueDefinition) => {
      if (!valueDefinition.disabled) {
        _acc.push(valueDefinition.id);
      }
      return _acc;
    }, []);

    return acc;
  }, {});
}
