import React, { useEffect, useMemo, useRef } from 'react'
import { StyleSheet, View } from 'react-native';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { useReport } from './ReportProvider';
import { DNABox, Iffy } from '@alucio/lux-ui';
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';
import { EmbeddableObject } from './EmbedTypes';
import useFeatureFlag from 'src/hooks/useFeatureFlag/useFeatureFlag';
import { FeatureFlags } from 'src/types/featureFlags';
import { useTenantCustomFields } from 'src/state/redux/selector/tenant';
import { CustomFieldUsage } from '@alucio/aws-beacon-amplify/src/models';

const styles = StyleSheet.create({
  loadingContainer: {
    borderWidth: 1,
    borderColor: '#EBEBEB',
    borderRadius: 4,
  },
});

const OFFSET =  new Date().getTimezoneOffset() / -60;

const options = {
  parameters: {
    country: 'United States',
  },
  scrolling: 'no',
  height: 'AutoFit',
  loadingHeight: '700px',
  width: '100%',
  footerPaddingEnabled: true,
  undoRedoDisabled: true,
  printEnable: false,
  resetDisabled: true,
  sheetTabsDisabled: true,
  iframeResizeOnSheetChange: true,
};

function capitalize(string) {
  return string[0].toUpperCase() + string.substring(1).toLowerCase()
}

export const EmbeddedReport = () => {
  const {
    reportUrl, isLoadedReportsList, isLoadingReport, setIsLoadingReport,
    setEmbeddableObject, selectedReport, dashboard, onSelectedSheetChange,
    isSharingBlocked, isDownloadingBlocked,
  } = useReport();
  const areReactionsEnabled = useFeatureFlag(FeatureFlags.BEAC_4227_meeting_slide_reactions);
  const sheet = reportUrl === dashboard?.dashboardUrl && selectedReport
    ? dashboard?.dashboardSheets.find(sheet => sheet.name === selectedReport?.name)
    : undefined
  const embedDashboard = useRef<EmbeddableObject>()
  const filter = useMemo(
    () => ({ usages: { internalUsages: [CustomFieldUsage.CONTENT_SHARE] } }),
    [],
  )
  const shareCustomFields = useTenantCustomFields(filter)
  const shareCustomFieldsReportingNames: Record<string, string> = {}
  // We limited user to have max of 5 custom fields for document share
  for (let i = 0; i < 5; i++ ) {
    // Display CharCode(127) (looks like a white space) as column title if there is no shareCustomField column
    shareCustomFieldsReportingNames[`shareCustomField${i + 1}`] = shareCustomFields[i]?.reportingName ?? '-'
    shareCustomFieldsReportingNames[`headerField${i + 1}`] = shareCustomFields[i]?.fieldLabel
      ? capitalize(shareCustomFields[i]?.fieldLabel) : String.fromCharCode(127);
  }

  useEffect(() => {
    if (reportUrl) {
      const containerDiv = document.getElementById('embedded-report-id')
      embedDashboard.current = QuickSightEmbedding.embedDashboard(
        {
          ...options,
          parameters: {
            localDatetimeOffset: OFFSET,
            hideSharedReports: isSharingBlocked ? 'YES' : 'NO',
            hideSentimentsCount : areReactionsEnabled ? 'NO' : 'YES',
            hideDownloadReport: isDownloadingBlocked ? 'YES' : 'NO',
            ...shareCustomFieldsReportingNames,
          },
          url: reportUrl,
          container: containerDiv,
          sheetId: sheet?.sheetId,
          selectedSheetChangeCallback: onSelectedSheetChange,
        });
      if (embedDashboard.current) {
        setEmbeddableObject(embedDashboard.current)
      }
      setIsLoadingReport(false);
    }
  }, [reportUrl, isLoadedReportsList])

  const emptyVariant = (!isLoadedReportsList || isLoadingReport)
    ? EmptyVariant.AnalyticsLoading : EmptyVariant.AnalyticsEmpty;

  return (
    <DNABox fill>
      <Iffy is={!isLoadedReportsList || isLoadingReport || !reportUrl}>
        <DNABox fill style={styles.loadingContainer}>
          <DNAEmpty emptyVariant={emptyVariant} />
        </DNABox>
      </Iffy>
      <Iffy is={reportUrl}>
        <View style={{ flex: 1 }} nativeID="embedded-report-id" />
      </Iffy>
    </DNABox >
  )
}
