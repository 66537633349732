import React from 'react'
import { DNAText } from '@alucio/lux-ui'
import { useContent } from 'src/state/context/ContentProvider/ContentProvider'

const SlideNotes: React.FC = () => {
  const { activePresentation, contentPageData } = useContent()
  const speakerNotes = activePresentation?.currentPresentablePage
    ? contentPageData[activePresentation?.currentPresentablePage.presentationPageNumber - 1]?.speakerNotes : undefined
  return (<DNAText style={{ maxWidth: 280 }}>
    {speakerNotes ?? 'No slide notes'}</DNAText>)
}

export default SlideNotes
