import { DNABox, DNAButton, DNACard, DNAChip, DNADivider, DNAText, GridList, Iffy, util } from '@alucio/lux-ui';
import format from 'date-fns/format';
import React, { useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { MeetingORM } from 'src/types/types';
import ContentPresentedList from './ContentPresentedList';
import MeetingContextMenu from './MeetingContextMenu';
import meetingQuery from 'src/state/redux/meeting/query';
import { sortCollection } from 'src/state/redux/selector/common';
import { Attendee, MeetingAttendeeStatus, AttendeeType } from '@alucio/aws-beacon-amplify/src/models';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import { drawerActions, DRAWER_ENTITIES } from 'src/state/redux/slice/drawer';
import { useDispatch } from 'src/state/redux';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

const S = StyleSheet.create({
  HeaderCol: {
    paddingVertical: 12,
    alignItems: 'flex-start',
  },
  PaddedContentRow: {
    paddingVertical: 12,
    paddingLeft: 30,
  },
  SortableButton: {
    minHeight: 17,
    maxHeight: 17,
    right: 8,
  },
  Row: {
    flexBasis: 'auto',
  },
  hoveredRow: {
    backgroundColor: '#F5F2F6',
    cursor: 'pointer',
  },
  MobileHeader: {
    backgroundColor: colors['color-gray-10'],
    paddingHorizontal: 16,
    minHeight: 49,
  },
});

interface MeetingHistoryListRowProps {
  meetingORM: MeetingORM
}

const AttendeesList: React.FC<{ attendees: Attendee[] }> = ({ attendees }) => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const activePrimaryAttendee = useMemo(
    () => attendees.find(({ status, attendeeType }) =>
      status !== MeetingAttendeeStatus.REMOVED && attendeeType === AttendeeType.PRIMARY),
    [attendees],
  )
  const activeSecondaryAttendees = useMemo(
    () => attendees.filter(({ status, attendeeType }) =>
      status !== MeetingAttendeeStatus.REMOVED && attendeeType === AttendeeType.SECONDARY)
      .sort((a, b) => a.name.localeCompare(b.name)),
    [attendees],
  )

  return (
    <DNABox appearance="col" spacing="sm" fill>
      <Iffy is={activePrimaryAttendee}>
        <DNAText bold numberOfLines={1}>
          {activePrimaryAttendee?.name}
        </DNAText>
      </Iffy>
      {
        activeSecondaryAttendees.map((attendee) => (
          <DNAText numberOfLines={1} key={attendee.id}>
            {attendee.name}
          </DNAText>
        )).filter((_, idx) => showAll || idx < (activePrimaryAttendee ? 4 : 5))
      }
      <Iffy is={activeSecondaryAttendees.length > (activePrimaryAttendee ? 4 : 5)}>
        <DNAButton
          status="primary"
          appearance="ghostLink"
          padding="none"
          onPress={() => setShowAll(!showAll)}
          style={{ right: 8 }}
        >
          {showAll ? 'Show less' : 'Show all'}
        </DNAButton>
      </Iffy>
    </DNABox>
  );
};

const MeetingHistoryListRow : React.FC<MeetingHistoryListRowProps> = ({ meetingORM }) => {
  const { startTime, endTime, title, contentPresented, attendees, id, status } = meetingORM.model
  const { deviceMode } = useAppSettings()
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const isTablet = deviceMode === 'tablet'
  const dispatch = useDispatch()

  const toggleDrawer = () => {
    dispatch(drawerActions.toggle({
      entity: DRAWER_ENTITIES.MEETING,
      entityId: meetingORM.model.id,
    }));
  }

  function onMouseEnter(): void {
    setIsHovered(true);
  }

  function onMouseLeave(): void {
    setIsHovered(false);
  }

  const contextMenu = (<GridList.Col style={[S.HeaderCol, { marginLeft:15 }]} span={1}>
    <MeetingContextMenu meetingORM={meetingORM} isTablet={isTablet}/>
  </GridList.Col>)

  return (
    <GridList.Row
      style={[S.Row, isHovered && S.hoveredRow]}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onPress={toggleDrawer}
      testID="meeting-list-row"
    >

      {isTablet && contextMenu}

      <GridList.Col style={S.HeaderCol} span={1}>
        <DNABox appearance="col">
          <DNAText>{format(new Date(startTime), 'LLL d, y')}</DNAText>
          <DNAText>{format(new Date(startTime), 'p')}</DNAText>
        </DNABox>
      </GridList.Col>
      <GridList.Col style={S.HeaderCol} span={1}>
        <DNABox appearance="col">
          <DNAText>{endTime ? format(new Date(endTime), 'LLL d, y') : ''}</DNAText>
          <DNAText>{endTime ? format(new Date(endTime), 'p') : ''}</DNAText>
        </DNABox>
      </GridList.Col>
      <GridList.Col style={S.HeaderCol} span={1}>
        <DNABox appearance="col" spacing="sm" fill>
          <DNAText numberOfLines={2}>{title}</DNAText>
          <Iffy is={status === 'LOCKED'}>
            <DNABox shrink>
              <DNAChip appearance="subtle">MEETING IS LOCKED</DNAChip>
            </DNABox>
          </Iffy>
        </DNABox>
      </GridList.Col>
      <GridList.Col style={S.HeaderCol} span={1}>
        <AttendeesList attendees={attendees} />
      </GridList.Col>
      <GridList.Col style={S.HeaderCol} span={1}>
        <DNABox appearance="col" fill>
          <ContentPresentedList
            isEditForm={false}
            meetingId={id}
            contentPresented={contentPresented || []}
          />
        </DNABox>
      </GridList.Col>

      {/* NOTE: Bit repetitive here. Possibly a better way to structure this to allow for a transient context menu */}
      {!isTablet && contextMenu}

    </GridList.Row>
  )
}

const MeetingTableContent:React.FC<MeetingTableProps> = ({ meetingORM }) => {
  const [isSortedAsc, setIsSortedAsc] = useState<boolean>(false);
  const sortedData = useMemo(() => {
    return isSortedAsc ? sortCollection(meetingORM, meetingQuery.sorts.startTimeAsc) : meetingORM;
  }, [isSortedAsc, meetingORM])

  const { deviceMode } = useAppSettings()
  const { headers, columnConfig } = deviceModeVariants[deviceMode]
  const isDesktop = deviceMode === 'desktop';

  return (
    <GridList
      cols={columnConfig}
      gap="12px"
      style={{ alignItems: 'flex-start', paddingHorizontal: 16 }}
    >
      <GridList.Header
        style={util.mergeStyles(undefined, [S.MobileHeader, !isDesktop])}
      >
        {
            headers.map((header) => {
              const isSortable = header === 'Start Time';
              const handleStartTimeSort = () => {
                if (header === 'Start Time') {
                  setIsSortedAsc((prev) => !prev)
                }
              }
              return (
                <GridList.Col
                  key={header}
                  style={header === 'Content Presented' ? S.PaddedContentRow : S.HeaderCol}
                  span={1}
                >
                  { isSortable
                    ? <DNAButton
                        appearance="ghostLink"
                        style={S.SortableButton}
                        padding="none"
                        status="tertiary"
                        onPress={handleStartTimeSort}
                        iconRight={isSortedAsc ? 'menu-up' : 'menu-down'}
                        testID={`header-sort-${header}`}
                    >
                      <DNAText b2 bold status="flat">{header}</DNAText>
                    </DNAButton>
                    : <DNAText b2 bold status="flat">{header}</DNAText>
                  }
                </GridList.Col>
              )
            })
          }
      </GridList.Header>
      {
        sortedData.map((meetingORM) => {
          return (
            <React.Fragment key={meetingORM.model.id}>
              <DNADivider />
              <MeetingHistoryListRow meetingORM={meetingORM}/>
            </React.Fragment>
          )
        })
      }
    </GridList>
  )
}

interface MeetingTableProps {
  meetingORM: MeetingORM[],
}

const MeetingTableTablet: React.FC<MeetingTableProps> = ({ meetingORM }) => {
  return <MeetingTableContent meetingORM={meetingORM} />
}

const MeetingTableDesktop: React.FC<MeetingTableProps> = ({ meetingORM }) => {
  return (
    <DNACard
      appearance="float"
      style={{ paddingHorizontal: 16, paddingVertical: 8, overflow: 'hidden', borderRadius: 4 }}
    >
      <MeetingTableContent meetingORM={meetingORM} />
    </DNACard>
  )
}

interface DeviceModeVariant {
  component: React.ElementType,
  headers: string[],
  columnConfig: string
}

type DeviceModeVariants = Record<DeviceMode, DeviceModeVariant>
const deviceModeVariants: DeviceModeVariants = {
  desktop: {
    component: MeetingTableDesktop,
    headers: ['Start Time', 'End Time', 'Title', 'Attendees', 'Content Presented'],
    // eslint-disable-next-line max-len
    columnConfig:'minmax(150px, 100%) minmax(150px, 100%) minmax(200px,100%) minmax(200px,100%) minmax(200px,100%) 50px',
  },
  tablet:{
    component: MeetingTableTablet,
    headers: ['', 'Start Time', 'End Time', 'Title', 'Attendees', 'Content Presented'],
    columnConfig:'72px 150px 150px minmax(200px,500px) minmax(200px,500px) minmax(200px,700px)',
  },
}

const MeetingTable:React.FC<MeetingTableProps> = (props) => {
  const { deviceMode } = useAppSettings()
  const CurrentDeviceModeVariant = deviceModeVariants[deviceMode].component
  return <CurrentDeviceModeVariant {...props}/>
}

export default MeetingTable;
