import { useMemo } from 'react'
import {
  CustomDeck,
  DocumentAccessLevel,
  DocumentStatus,
  FileType,
  Folder,
  FolderItem,
  FolderItemType,
  FolderPermission,
  PermissionType,
  ShareStatus,
  ShareTargetType,
  Tenant,
} from '@alucio/aws-beacon-amplify/src/models'
import {
  CustomDeckORM,
  DocumentVersionORM,
  FolderItemORM,
  FolderORM,
  ORMTypes,
  SharePermissionORM,
  VERSION_UPDATE_STATUS,
} from 'src/types/types'
import { RootState } from 'src/state/redux'

import { createSelector, Selector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { allDocumentVersionMap, DocumentVersionORMMap } from 'src/state/redux/selector/document'
import { loggedUser, userTenant } from 'src/state/redux/selector/user'
import {
  ADDITIONAL_FILTER,
  FilterAndSortOptions,
  filterCollection,
  getMappedCustomValues,
  sortCollection,
} from 'src/state/redux/selector/common'
import { FOLDER_ITEM_STATUS } from '@alucio/aws-beacon-amplify/src/API'
import { isCustomDeckORM, isDocumentVersionORM, isFolderORM } from 'src/types/typeguards'
import { AUTO_UPDATE_DEFAULT_DATE } from '../slice/customDeck'
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector'
import { IndexedUserORM, indexedUsersList } from './user'
import addDays from 'date-fns/addDays'
import isPast from 'date-fns/isPast'
import { SliceStatus } from '../slice/common'

export type SelectIdOpts = {
  folderId: 'string'
}

export const getRootFolderORM = (folderORM: FolderORM | null): FolderORM | null => {
  if (!folderORM) {
    return null
  }

  if (folderORM?.relations.parentFolderORM) {
    return getRootFolderORM(folderORM.relations.parentFolderORM)
  }
  return folderORM
}

export interface FolderRecursiveMetadata {
  itemSum: number,
  folderSum: number,
  containsOutdatedDocVer: boolean,
  containsPendingReviewItem: boolean,
  containsAutoUpdatedItem: boolean,
  hasExternalDependency: boolean,
}

export type FolderORMMap = { [folderId: string]: FolderORM }
export type CustomDeckORMMap = { [customDeckId: string]: CustomDeckORM }
type PermissionTypeMap = Record<string, PermissionType>

export const selectFolders = (state: RootState): Folder[] => state.folder.records
export const selectFolderOptions = (_, __, opts?: FilterAndSortOptions<FolderORM>) => opts
export const selectId = (_, __, opts: SelectIdOpts) => opts.folderId
export const selectSharedFolders = (state: RootState): Folder[] => state.sharedFolder.records
export const selectCustomDecks = (state: RootState): CustomDeck[] => state.customDeck.records
export const selectFolderPermissions = (state: RootState): FolderPermission[] => state.folderPermission.records

const folderPermissionsMap: Selector<RootState, PermissionTypeMap> = createSelector(
  selectFolderPermissions,
  (folderPermissions) => {
    return folderPermissions.reduce<PermissionTypeMap>((acc, permission) => {
      acc[permission.id] = permission.type as PermissionType;
      return acc;
    },
    {},
    )
  },
)

const deriveRecursiveMeta = (
  folder: Folder,
  folderORMMap: FolderORMMap,
  docVerORMMap: DocumentVersionORMMap,
  customDecksORMMap: CustomDeckORMMap,
  userId: string,
  itemSum: number = 0,
  folderSum: number = 0,
  containsOutdatedDocVer: boolean = false,
  containsPendingReviewItem: boolean = false,
  containsAutoUpdatedItem: boolean = false,
  hasExternalDependency: boolean = false,
) => {
  const initialState: FolderRecursiveMetadata = {
    itemSum,
    folderSum,
    containsOutdatedDocVer,
    containsPendingReviewItem,
    containsAutoUpdatedItem,
    hasExternalDependency,
  }

  const rv = folder?.items?.reduce<FolderRecursiveMetadata>(
    (acc, item) => {
      if (item.status === FOLDER_ITEM_STATUS.REMOVED) {
        return acc
      } else if (item.type === 'FOLDER') {
        // This will skip and orphaned entries
        const nextFolder = folderORMMap[item.itemId]?.model
        if (!nextFolder) return acc

        const summed = deriveRecursiveMeta(
          nextFolder,
          folderORMMap,
          docVerORMMap,
          customDecksORMMap,
          userId,
          0,
          0,
          acc.containsOutdatedDocVer,
          acc.containsPendingReviewItem,
          acc.containsAutoUpdatedItem,
          acc.hasExternalDependency,
        )

        const newFolderRecursiveMetaData: FolderRecursiveMetadata = {
          itemSum: acc.itemSum + summed.itemSum,
          folderSum: acc.folderSum + summed.folderSum + 1,
          containsOutdatedDocVer: acc.containsOutdatedDocVer || summed.containsOutdatedDocVer,
          containsPendingReviewItem: acc.containsPendingReviewItem || summed.containsPendingReviewItem,
          containsAutoUpdatedItem: acc.containsAutoUpdatedItem || summed.containsAutoUpdatedItem,
          hasExternalDependency: acc.hasExternalDependency || summed.hasExternalDependency,
        }
        return newFolderRecursiveMetaData
      } else if (item.type === 'DOCUMENT_VERSION') {
        // We do not include items if they no longer exist (deleted) or they are not in archived/published status
        if (
          !docVerORMMap[item.itemId] ||
          !['ARCHIVED', 'PUBLISHED'].includes(docVerORMMap[item.itemId].relations.documentORM.model.status)
        ) {
          return acc;
        } else {
          return {
            itemSum: acc.itemSum + 1,
            folderSum: acc.folderSum + 0,
            containsOutdatedDocVer:
              acc.containsOutdatedDocVer ||
              !docVerORMMap[item.itemId].meta.version.isLatestPublished,
            containsAutoUpdatedItem: acc.containsAutoUpdatedItem || !item.updateAcknowledgedAt,
            containsPendingReviewItem: acc.containsPendingReviewItem,
            hasExternalDependency: acc.hasExternalDependency,
          }
        }
      } else if (item.type === 'CUSTOM_DECK') {
        // item.status === 'REMOVED' is already handled
        const customDeck = customDecksORMMap[item.itemId]
        const rootFolderOrm = getRootFolderORM(folderORMMap[folder.id])
        if (!customDeck) {
          if (
            folder.shareStatus === ShareStatus.IS_SHARED ||
            // if the parent is shared we can infer that the child would b also shared, we dont support partial  sharing right now
            rootFolderOrm?.model?.shareStatus === ShareStatus.IS_SHARED
          ) {
            return {
              ...acc,
              itemSum: acc.itemSum + 1,
              hasExternalDependency: acc.hasExternalDependency ||
                folder.createdBy !== userId,
            }
          }
          else {
            return acc
          }
        }

        return {
          itemSum: acc.itemSum + 1,
          folderSum: acc.folderSum + 0, // folder count does not increase
          containsOutdatedDocVer: acc.containsOutdatedDocVer,
          containsPendingReviewItem: acc.containsPendingReviewItem ||
            customDeck.meta.version.updateStatus === VERSION_UPDATE_STATUS.NOT_PUBLISHED ||
            customDeck.meta.version.updateStatus === VERSION_UPDATE_STATUS.PENDING_MAJOR,
          containsAutoUpdatedItem: customDeck.meta.version.autoUpdateUnacknowledged || acc.containsAutoUpdatedItem,
          hasExternalDependency:
            customDeck.model.groups.some(group => group.docAccessLevel === DocumentAccessLevel.USER) ||
            userId !== customDeck.model.createdBy ||
            folder.shareStatus === ShareStatus.IS_SHARED ||
            acc.hasExternalDependency,
        }
      } else {
        throw Error(`Unknown item type ${item.type}`)
      }
    }, initialState,
  ) ?? initialState

  return rv
}

const toFolderORM = (
  folder: Folder,
  user: string,
  tenant?: Tenant,
  folderORMMap?: FolderORMMap,
  docVerORMMap?: DocumentVersionORMMap,
  customDeckMap?: CustomDeckORMMap,
  users?: IndexedUserORM,
  isSharedWithTheUser?: boolean,
): FolderORM => {
  // 1st pass to just setup some things
  if (!folderORMMap || !docVerORMMap || !customDeckMap) {
    return {
      model: folder,
      type: ORMTypes.FOLDER,
      relations: {
        parentFolderORM: null,
        items: [],
        sharePermissions: [],
      },
      meta: {
        hasExternalDependencies: false,
        isSharedFolder: false,
        isSharedWithTheUser,
        folderCount: 0,
        itemCount: 0,
        version: {
          containsOutdatedDocVer: false,
          containsAutoUpdatedItem: false,
          containsPendingReviewItem: false,
        },
      },
    }
  }

  // Ensure that only the parent folders isShared permission is modified
  if (folderORMMap[folder.id].relations.parentFolderORM === null) {
    // SHARE PERMISSION ORM
    folderORMMap[folder.id].relations.sharePermissions =
      (folderORMMap[folder.id].model.sharePermissions || []).reduce<SharePermissionORM[]>((acc, permission) => {
        if (permission.isDeleted) {
          return acc;
        }
        const customFilterValues = getMappedCustomValues(
          { additionalUsages: [ADDITIONAL_FILTER.FOLDER_FILTER] },
          permission.targetCustomValues,
          tenant?.config.customFields);

        // A PERMISSION MIGHT NOT BE DELETED BUT THE FIELDS/VALUES USED MIGHT BE DISABLED.
        // THEREFORE, THE PERMISSION, ALTHOUGH IS NOT DELETED, IS NOT VALID
        const isFilterValidPermission = Object.keys(customFilterValues).some((fieldId) =>
          customFilterValues[fieldId].displayValues.length);

        const validUserPermissions: Array<ShareTargetType | keyof typeof ShareTargetType> =
          [ShareTargetType.ALL, ShareTargetType.USER];

        const isAUsersPermission = validUserPermissions.includes(permission.targetType);

        folderORMMap[folder.id].meta.isSharedFolder =
          folderORMMap[folder.id].meta.isSharedFolder || isFilterValidPermission || isAUsersPermission;

        acc.push({
          model: permission,
          type: ORMTypes.SHARE_PERMISSION,
          meta: {
            isValidPermission: isFilterValidPermission || isAUsersPermission,
            customFilterValues,
          },
        });

        return acc;
      }, []);
  }

  if (folder?.items?.length) {
    const folderORM = folderORMMap[folder.id]
    const mappedItems: (FolderItemORM)[] = folder.items
      // Filter any dead references -- may have been filtered earlier by item status
      // STATUS = REMOVED
      ?.filter(item => {
        const itemExists =
          (
            folderORMMap[item.itemId] ||
            customDeckMap[item.itemId] ||
            (
              docVerORMMap[item.itemId] &&
              ['ARCHIVED', 'PUBLISHED', 'NOT_PUBLISHED'].includes(
                docVerORMMap[item.itemId].relations.documentORM.model.status,
              )
            )
          ) &&
          item.status !== FOLDER_ITEM_STATUS.REMOVED

        return itemExists
      })
      .map(
        folderItem => {
          const isFolder = folderItem.type === FolderItemType.FOLDER
          const isCustomDeck = folderItem.type === FolderItemType.CUSTOM_DECK

          // [TODO] - Cross function mutation, not the cleanest
          if (isFolder) {
            folderORMMap[folderItem.itemId].relations.parentFolderORM = folderORMMap[folder.id]
            folderORMMap[folderItem.itemId].meta.isSharedFolder = folderORMMap[folder.id].meta.isSharedFolder;
          } else if (isCustomDeck) {
            const deckORM = customDeckMap[folderItem.itemId];

            const customDeckItem: FolderItemORM = {
              model: folderItem,
              type: ORMTypes.FOLDER_ITEM,
              meta: {
                assets: {
                  thumbnailKey: deckORM.meta.assets.thumbnailKey,
                },
                title: deckORM.model.title,
                hasAutoUpdatedItem: deckORM.meta.version.autoUpdateUnacknowledged,
                hasOutdatedItem: deckORM.meta.version.updateStatus !== VERSION_UPDATE_STATUS.CURRENT,
              },
              relations: {
                itemORM: deckORM,
                parentORM: folderORM,
              },
            }
            return customDeckItem
          }

          const docVer = docVerORMMap[folderItem.itemId]
          const doc = docVer?.relations.documentORM
          const isModified =
            doc?.relations.version.latestPublishedDocumentVersionORM?.meta.permissions.MSLSelectSlides &&
            !!folderItem.visiblePages?.length

          const itemORM: FolderItemORM = {
            model: folderItem,
            type: ORMTypes.FOLDER_ITEM,
            meta: isFolder ? {
              assets: {
                thumbnailKey: undefined,
              },
              title: folderORMMap[folderItem.itemId].model.name,
              hasAutoUpdatedItem: folderORMMap[folderItem.itemId].meta.version.containsAutoUpdatedItem,
              hasOutdatedItem: folderORMMap[folderItem.itemId].meta.version.containsOutdatedDocVer,
            } : {
              assets: {
                thumbnailKey: docVerORMMap[folderItem.itemId].meta.assets.thumbnailKey,
              },
              title: folderItem.customTitle || docVer.model.title || '', // the empty string should never be reached because it will either have a title from docVer or a customTitle for modified files
              hasAutoUpdatedItem: !folderItem.updateAcknowledgedAt,
              hasOutdatedItem: !docVerORMMap[folderItem.itemId].meta.version.isLatestPublished,
              isModified: isModified,
            },
            relations: {
              itemORM: isFolder
                ? folderORMMap[folderItem.itemId]
                : docVerORMMap[folderItem.itemId],
              parentORM: folderORM,
            },
          }
          return itemORM
        },
      )
    folderORM.relations.items = mappedItems
    const {
      itemSum,
      folderSum,
      containsOutdatedDocVer,
      containsAutoUpdatedItem,
      containsPendingReviewItem,
      hasExternalDependency,
    } = deriveRecursiveMeta(
      folderORMMap[folder.id].model,
      folderORMMap,
      docVerORMMap,
      customDeckMap,
      user,
    )
    folderORM.meta.hasExternalDependencies = hasExternalDependency
    folderORM.meta.folderCount = folderSum
    folderORM.meta.itemCount = itemSum
    folderORM.meta.version.containsOutdatedDocVer = containsOutdatedDocVer
    folderORM.meta.version.containsAutoUpdatedItem = containsAutoUpdatedItem
    folderORM.meta.version.containsPendingReviewItem = containsPendingReviewItem
  }

  folderORMMap[folder.id].relations.ownerORM = users?.[folderORMMap[folder.id].model.createdBy];
  folderORMMap[folder.id].meta.isSharedWithTheUser = isSharedWithTheUser;
  if (!isSharedWithTheUser) {
    folderORMMap[folder.id].meta.permission = PermissionType.EDIT;
  }

  return folderORMMap[folder.id]
}

const mergeUpdateStatus = (a: VERSION_UPDATE_STATUS, b: VERSION_UPDATE_STATUS) => {
  if (a === VERSION_UPDATE_STATUS.NOT_PUBLISHED || b === VERSION_UPDATE_STATUS.NOT_PUBLISHED) {
    return VERSION_UPDATE_STATUS.NOT_PUBLISHED
  } else if (a === VERSION_UPDATE_STATUS.PENDING_MAJOR || b === VERSION_UPDATE_STATUS.PENDING_MAJOR) {
    return VERSION_UPDATE_STATUS.PENDING_MAJOR
  } else if (a === VERSION_UPDATE_STATUS.PENDING_MINOR || b === VERSION_UPDATE_STATUS.PENDING_MINOR) {
    return VERSION_UPDATE_STATUS.PENDING_MINOR
  } else {
    return VERSION_UPDATE_STATUS.CURRENT
  }
}

export const allCustomDecks: Selector<RootState, CustomDeckORM[]> = createSelector(
  selectCustomDecks,
  allDocumentVersionMap,
  loggedUser,
  (customDecks, documentVersionsORMMap, loggedUser): CustomDeckORM[] =>
    customDecks.map((customDeck) => {
      let thumbnailKey;

      const isContentCached = customDeck.groups.every(
        group => group.pages.every(
          page => {
            const documentVersionORM = documentVersionsORMMap[page.documentVersionId]
            return documentVersionORM
              ? documentVersionORM.meta.assets.isContentCached
              : true;
          },
        ),
      )

      const groups = customDeck.groups.map((group) => {
        const pages = group.pages.map((page) => {
          const documentVersionORM = documentVersionsORMMap[page.documentVersionId];
          const pageModel = documentVersionORM?.meta.allPages.find(({ pageId }) => pageId === page.pageId)

          return {
            model: page,
            documentVersionORM,
            page: pageModel ??
              // [TODO-928] - This may need to be checked in other areas, before we were falsely allowing undefined as a Page
              //  - Which would not get caught in further TypeScript causing undocumented workarounds
              //  - Instead, we do a partial of the Page (because we need the pageId as a key for DnD)
              { pageId: page.pageId, number: page.pageNumber, srcHash: '' },
          };
        });

        if (!thumbnailKey && group.visible) {
          thumbnailKey = detectArchivedFileKeyPath(
            pages[0]?.documentVersionORM?.model,
            {
              number: pages[0].page.number,
              pageId: pages[0].page.pageId,
            },
            'sm',
          );
        }

        return {
          isGroup: pages.length > 1,
          model: group,
          meta: {
            version: {
              updateStatus: pages.reduce(
                (acc, page) => page.documentVersionORM
                  ? mergeUpdateStatus(acc, page.documentVersionORM.meta.version.updateStatus)
                  : VERSION_UPDATE_STATUS.NOT_PUBLISHED, VERSION_UPDATE_STATUS.CURRENT,
              ),
            },
          },
          pages,
        }
      });
      const updateStatus = groups.reduce(
        (acc, group) => mergeUpdateStatus(acc, group.meta.version.updateStatus),
        VERSION_UPDATE_STATUS.CURRENT,
      )
      return {
        model: customDeck,
        type: ORMTypes.CUSTOM_DECK,
        meta: {
          hasExternalDependency: groups.some(group => group.model.docAccessLevel === DocumentAccessLevel.USER) &&
            customDeck.createdBy !== loggedUser.userProfile?.id!,
          assets: {
            thumbnailKey,
            isContentCached,
          },
          version: {
            updateStatus,
            requiresReview:
              updateStatus === VERSION_UPDATE_STATUS.PENDING_MAJOR ||
              updateStatus === VERSION_UPDATE_STATUS.NOT_PUBLISHED,
            // Thanks to this issue we have to use a dummy date to indicate undefined
            // https://github.com/aws-amplify/amplify-js/issues/7565
            autoUpdateUnacknowledged:
              !customDeck.autoUpdateAcknowledgedAt ||
              customDeck.autoUpdateAcknowledgedAt === AUTO_UPDATE_DEFAULT_DATE,
          },
          permissions: {
            isCollaborator: customDeck.createdBy !== loggedUser.userProfile?.id!,
            // eslint-disable-next-line max-len
            MSLPresent: !groups.some((group) => group.pages.some((page) => !page.documentVersionORM?.meta.permissions?.MSLPresent)),
          },
          containsWebDocs: groups.some((group) =>
            group.pages.some((page) => page.documentVersionORM?.model.type === FileType.WEB,
            ),
          ),
          containsVideoDoc: groups.some((group) =>
            group.pages.some((page) => page.documentVersionORM?.model.type === FileType.MP4,
            ),
          ),
          containsHTMLDocs: groups.some((group) =>
            group.pages.some((page) => page.documentVersionORM?.model.type === FileType.HTML,
            ),
          ),
          customDeckGroups: groups,
        },
      }
    }),
);

export const customDecksORMMap: Selector<RootState, CustomDeckORMMap> = createSelector(
  allCustomDecks,
  (customDecks) =>
    customDecks.reduce<CustomDeckORMMap>(
      (acc, customDeckORM) => {
        acc[customDeckORM.model.id] = customDeckORM;
        return acc;
      },
      {}),
);

const allSharedFolders: Selector<RootState, FolderORM[]> = createSelector(
  allDocumentVersionMap,
  selectSharedFolders,
  indexedUsersList,
  userTenant,
  folderPermissionsMap,
  customDecksORMMap,
  loggedUser,
  (docVerORMMap, folders, users, userTenant, folderPermissionsMap, customDecksORMMap, loggedUser): FolderORM[] => {
    const gracePeriodDays = userTenant?.folderUpdateGracePeriodDays;

    // WE NEED TO CHECK IF THE FOLDERS HAVE AN OUTDATED FILE.
    // IF SO, WE'LL REPLACE THEM WITH THE MOST RECENT ONE
    if (gracePeriodDays !== undefined && gracePeriodDays !== null) {
      folders = folders.map((folder) => {
        const items = folder.items.reduce<FolderItem[]>((acc, folderItem) => {
          if (folderItem.type !== FolderItemType.DOCUMENT_VERSION) {
            return [...acc, folderItem];
          }

          const docVer = docVerORMMap[folderItem.itemId];
          if (!docVer || docVer.relations.documentORM.model.status !== DocumentStatus.PUBLISHED) {
            return acc;
          }

          // WE CHECK IF THERE'S A GREATER DOCVER
          const { latestUsableDocumentVersionORM } = docVer.relations.documentORM.relations.version;
          if (latestUsableDocumentVersionORM.model.versionNumber > docVer.model.versionNumber) {
            // WE CHECK IF IT NEEDS TO BE AUTOUPDATED AND REPLACE IT
            if (isPast(addDays(new Date(docVer.model.updatedAt), gracePeriodDays))) {
              return [...acc, {
                ...folderItem,
                itemId: latestUsableDocumentVersionORM.model.id,
                itemLastUpdatedAt: new Date().toISOString(),
                updateAcknowledgedAt: undefined,
                customTitle: undefined,
                visiblePages: undefined,
              }]
            }
            return [...acc, folderItem];
          }
          return [...acc, folderItem];
        }, []);

        return { ...folder, items };
      }, []);
    }

    const folderORMMap = folders
      .reduce<FolderORMMap>(
        (acc, folder) => {
          acc[folder.id] = toFolderORM(folder, loggedUser.userProfile?.id!, loggedUser.relations.tenant)
          acc[folder.id].meta.isSharedWithTheUser = true;
          acc[folder.id].meta.permission = folderPermissionsMap[folder.id];
          return acc
        }, {})

    return folders.map(folder => toFolderORM(
      folder,
      loggedUser.userProfile?.id!,
      loggedUser.relations.tenant,
      folderORMMap,
      docVerORMMap,
      customDecksORMMap,
      users,
      true,
    ));
  },
)

const allFolders: Selector<RootState, FolderORM[]> = createSelector(
  allDocumentVersionMap,
  selectFolders,
  customDecksORMMap,
  indexedUsersList,
  loggedUser,
  (docVerORMMap, folders, customDeckMap, users, loggedUser): FolderORM[] => {
    // Do a first pass to create initial references for any nested FolderORM
    const validFolders = folders.filter(folder => folder.status !== 'REMOVED')

    const folderORMMap: FolderORMMap = validFolders
      .reduce(
        (acc, folder) => {
          acc[folder.id] = toFolderORM(folder, loggedUser.userProfile?.id!, loggedUser.relations.tenant)
          return acc
        },
        {},
      )

    const folderORMs = validFolders
      .map(folder => toFolderORM(folder,
        loggedUser.userProfile?.id!,
        loggedUser.relations.tenant,
        folderORMMap,
        docVerORMMap,
        customDeckMap,
        users))
    return folderORMs
  },
)

const folderItemORMById: Selector<RootState, FolderItemORM | undefined> = createSelector(
  allFolders,
  allSharedFolders,
  (_: RootState, params: GetFolderItemORMParams) => params,
  (folders, sharedFolders, { id, folderId }): FolderItemORM | undefined => {
    if (!id) {
      return undefined;
    }

    for (const folderORM of [...sharedFolders, ...folders]) {
      const folderItemORM = folderORM?.relations.items?.find((folderItemORM) =>
        folderItemORM.model.id === id && folderORM.model.id === folderId);

      if (folderItemORM) {
        return folderItemORM;
      }
    }

    return undefined;
  },
);

const folderItemORMByIdNoFolder: Selector<RootState, any> = createSelector(
  allFolders,
  allSharedFolders,
  (_: RootState, params: GetFolderItemORMParams) => params,
  (folders, sharedFolders, { id }): FolderItemORM | undefined => {
    if (!id) {
      return undefined;
    }

    for (const folderORM of [...sharedFolders, ...folders]) {
      const folderItemORM = folderORM?.relations.items?.find((folderItemORM) =>
        folderItemORM.model.id === id || folderItemORM.model.itemId === id)
      if (folderItemORM) {
        return folderItemORM;
      }
    }

    return undefined;
  },
);

const customDeckORMById: Selector<RootState, CustomDeckORM | undefined> = createSelector(
  customDecksORMMap,
  (_, id: string) => id,
  (customDeckORMMap, id): CustomDeckORM | undefined => customDeckORMMap[id],
);

const filteredFoldersFactory: () => Selector<RootState, FolderORM[]> = () => createSelector(
  allFolders,
  selectFolderOptions,
  filterCollection<FolderORM>,
)

const filteredSharedFoldersFactory: () => Selector<RootState, FolderORM[]> = () => createSelector(
  allSharedFolders,
  selectFolderOptions,
  filterCollection<FolderORM>,
)

export const allFoldersFilteredAndSortedFactory: () => Selector<RootState, FolderORM[]> = () => createSelector(
  filteredFoldersFactory(),
  selectFolderOptions,
  sortCollection<FolderORM>,
)

export const allSharedFoldersFilteredAndSortedFactory: () => Selector<RootState, FolderORM[]> = () => createSelector(
  filteredSharedFoldersFactory(),
  selectFolderOptions,
  sortCollection<FolderORM>,
)

const allFoldersFilteredAndSorted = allFoldersFilteredAndSortedFactory()

export const allFolderItems: Selector<RootState, FolderItemORM[]> = createSelector(
  allFoldersFilteredAndSorted,
  (folders) => folders.reduce<FolderItemORM[]>(
    (acc, folder) => {
      return [...acc, ...folder.relations.items]
    },
    [],
  ),
)

export const useSharedFolders = ():
  ReturnType<typeof allSharedFolders> =>
  useSelector((state: RootState) =>
    allSharedFolders(state))

export const useIsSharedFoldersHydrated = () =>
  useSelector((state: RootState) =>
    state.sharedFolder.hydrated)

export const useIsSharedFoldersStatus = (status: SliceStatus) =>
  useSelector((state: RootState) =>
    state.sharedFolder.status === status)

export const useAllFolders = (opts?: FilterAndSortOptions<FolderORM>):
  ReturnType<typeof allFoldersFilteredAndSorted> =>
  useSelector((state: RootState) =>
    allFoldersFilteredAndSorted(state, undefined, opts))

export const useAllFoldersInstance = (opts?: FilterAndSortOptions<FolderORM>, sharedFolders?: boolean):
  ReturnType<typeof allFoldersFilteredAndSorted> => {
  const selectorInstance = useMemo(
    () => allFoldersFilteredAndSortedFactory(),
    [],
  )

  const sharedSelectorInstance = useMemo(
    () => allSharedFoldersFilteredAndSortedFactory(),
    [],
  )

  const selector = sharedFolders ? sharedSelectorInstance : selectorInstance;

  return useSelector((state: RootState) => selector(state, undefined, opts));
}

export const useCustomDeckORMById = (id: string): ReturnType<typeof customDeckORMById> =>
  useSelector((state: RootState) => customDeckORMById(state, id));

type GetFolderItemORMParams = {
  id?: string,
  folderId?: string,
}

export const useFolderItemORMById = (params: GetFolderItemORMParams): ReturnType<typeof folderItemORMById> =>
  /** @ts-ignore TODO: Identify cause of TS error here */
  useSelector((state: RootState) => folderItemORMById(state, params));

export const useFolderItemORMByIdNoFolder = (params: GetFolderItemORMParams): ReturnType<typeof folderItemORMById> =>
  /** @ts-ignore TODO: Identify cause of TS error here */
  useSelector((state: RootState) => folderItemORMByIdNoFolder(state, params));

export const useCustomDeckORMMap = (): ReturnType<typeof customDecksORMMap> =>
  useSelector((state: RootState) => customDecksORMMap(state));

export enum FolderItemStatus {
  AutoUpdated = 'AutoUpdated',
  Acknowledged = 'Acknowledged',
  Outdated = 'Outdated',
  Updated = 'Updated',
  Review = 'Review'
}

export type DocumentVersionUpdateInfo = {
  path: DocumentVersionUpdateInfoPath[],
  status: FolderItemStatus,
  folderItem: FolderItemORM,
}
export type DocumentVersionUpdateInfoPath = {
  title: string,
  isModified?: boolean,
}
export type DocumentModifiedInfo = Map<DocumentVersionORM, DocumentVersionUpdateInfo[]>

const checkCustomDeck = (
  documents: Map<DocumentVersionORM, DocumentVersionUpdateInfo[]>,
  customDeck: CustomDeckORM,
  folderItemORM: FolderItemORM,
  path: DocumentVersionUpdateInfoPath[]) => {
  const processedDocuments = new Set<DocumentVersionORM>()
  customDeck.meta.customDeckGroups.forEach(groupORM => {
    if (groupORM.meta.version.updateStatus !== VERSION_UPDATE_STATUS.CURRENT ||
      (groupORM.meta.version.updateStatus === VERSION_UPDATE_STATUS.CURRENT &&
        customDeck.meta.version.autoUpdateUnacknowledged)) {
      groupORM.pages.forEach(page => {
        if (!processedDocuments.has(page.documentVersionORM)) {
          checkItem(documents, page.documentVersionORM, folderItemORM, path)
          processedDocuments.add(page.documentVersionORM)
        }
      })
    }
  })
}

const checkItem = (
  documents: Map<(DocumentVersionORM | undefined), DocumentVersionUpdateInfo[]>,
  docVersionORM: DocumentVersionORM,
  folderItemORM: FolderItemORM,
  path: DocumentVersionUpdateInfoPath[]) => {
  let status = folderItemORM.meta.hasAutoUpdatedItem
    ? FolderItemStatus.AutoUpdated
    : folderItemORM.meta.hasOutdatedItem
      ? FolderItemStatus.Outdated
      : undefined
  let docVersion: (DocumentVersionORM | undefined) = docVersionORM
  // If is a custom deck and has a pending major change we mark it for Review
  if (isCustomDeckORM(folderItemORM.relations.itemORM)) {
    const customDeckORM = folderItemORM.relations.itemORM
    status = (customDeckORM.meta.version.updateStatus === VERSION_UPDATE_STATUS.PENDING_MAJOR ||
      customDeckORM.meta.version.updateStatus === VERSION_UPDATE_STATUS.NOT_PUBLISHED)
      ? FolderItemStatus.Review
      : customDeckORM.meta.version.autoUpdateUnacknowledged
        ? FolderItemStatus.AutoUpdated
        : status
    docVersion = docVersion?.relations?.documentORM?.model?.status === 'PUBLISHED' ? docVersion : undefined
  }

  if (status) {
    const title = (folderItemORM?.model.customTitle ||
      docVersion?.relations.documentORM.relations.version.latestPublishedDocumentVersionORM?.model.title) ?? '';

    if (!title) {
      return
    }

    const info: DocumentVersionUpdateInfo = {
      path: [...path, {
        title,
        isModified: folderItemORM.meta.isModified || isCustomDeckORM(folderItemORM.relations.itemORM),
      }],
      status,
      folderItem: folderItemORM,
    }
    const currentPublishedVersion =
      docVersion?.relations.documentORM.relations.version.latestPublishedDocumentVersionORM

    if (!documents.has(currentPublishedVersion)) {
      documents.set(currentPublishedVersion, [info])
    }
    else {
      const pathAlreadyExists = Object.entries(documents.get(currentPublishedVersion) ?? []).filter(
        (docVerUpdateInfo) => folderItemORM.model.id === docVerUpdateInfo[1].folderItem.model.id);
      if (pathAlreadyExists.length === 0) {
        documents.get(currentPublishedVersion)?.push(info);
      }
    }
  }
}

const documentsModifiedFactory: () => Selector<
  RootState,
  Map<DocumentVersionORM, DocumentVersionUpdateInfo[]>
> = () => createSelector(
  allFoldersFilteredAndSorted,
  (folderORMs): Map<DocumentVersionORM, DocumentVersionUpdateInfo[]> => {
    const documents = new Map<DocumentVersionORM, DocumentVersionUpdateInfo[]>()
    folderORMs.forEach(folderORM => {
      // Only take root folders
      if (folderORM.relations.parentFolderORM) {
        return;
      }
      if (isFolderORM(folderORM)) {
        // FIRST FOLDER LEVEL
        const path = [{ title: folderORM.model.name }]
        const hasAutoUpdatedItem = folderORM.meta.version.containsAutoUpdatedItem
        const hasOutdatedItem = folderORM.meta.version.containsOutdatedDocVer ||
          folderORM.meta.version.containsPendingReviewItem
        if (hasAutoUpdatedItem || hasOutdatedItem) {
          // Iterate root folders child items
          folderORM.relations.items.forEach(subitem => {
            const child = subitem.relations.itemORM
            if (isDocumentVersionORM(child)) {
              checkItem(documents, child, subitem, path)
            }
            else if (isCustomDeckORM(child)) {
              checkCustomDeck(documents, child, subitem, path)
            }
            else if (isFolderORM(child) &&
              (child.meta.version.containsAutoUpdatedItem ||
                child.meta.version.containsOutdatedDocVer ||
                child.meta.version.containsPendingReviewItem)) {
              // SECOND FOLDER LEVEL
              const subPath = [...path, { title: child.model.name }]

              // Iterate subfolders child items
              child.relations.items.forEach(subitem => {
                const subchild = subitem.relations.itemORM
                if (isDocumentVersionORM(subchild)) {
                  checkItem(documents, subchild, subitem, subPath)
                }
                else if (isCustomDeckORM(subchild)) {
                  checkCustomDeck(documents, subchild, subitem, subPath)
                }
              })
            }
          })
        }
      }
    })

    return documents
  },
);

const documentsModified = documentsModifiedFactory()
export const useAllFoldersUpdatedDocsMap = (opts?: FilterAndSortOptions<FolderORM>):
  ReturnType<typeof documentsModified> => {
  const selectorInstance = useMemo(
    () => documentsModifiedFactory(),
    [],
  )
  return useSelector((state: RootState) => selectorInstance(state, undefined, opts))
}

export const folderItemORMMap: Selector<RootState, Map<string, FolderItemORM>> = createSelector(
  allFolderItems, (allFolderItems: FolderItemORM[]) => {
    const folderItemORMMap = new Map<string, FolderItemORM>();
    allFolderItems.forEach((item) => {
      folderItemORMMap.set(item.model.itemId, item);
    });
    return folderItemORMMap;
  },
)

export const useFolderItemORMMap = (): ReturnType<typeof folderItemORMMap> =>
  useSelector((state: RootState) => folderItemORMMap(state));
