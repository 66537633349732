import React from 'react'
import { DNABox, DNACard, DNAText, Iffy, DNAChip, DNAButton } from '@alucio/lux-ui'
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector'
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail'
import { Pressable, StyleSheet } from 'react-native';
import { LoadedPresentation } from 'src/state/context/ContentProvider/ContentProvider';
import { isDocumentVersionORM, isFolderItemORM, isPageGroupORM } from 'src/types/typeguards';
import { Icon } from '@ui-kitten/components';
import { OpenedPresentationsVariantOption } from './OpenedPresentations';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList';

const styles = StyleSheet.create({
  presentButton: {
    borderRadious: 4,
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.24)',
  },
  containerStyle: {
    paddingLeft: 8,
    paddingRight: 12,
    paddingVertical: 8,
    paddingBottom: 8,
    backgroundColor: colors['color-gray-500'],
    marginVertical: 4,
    borderRadius: 4,
  },
  inPersonControlContainerStyle: {
    paddingLeft: 8,
    paddingRight: 12,
    paddingVertical: 8,
    paddingBottom: 8,
    backgroundColor: 'transparent',
    marginVertical: 4,
    borderBottomColor: 'rgba(255, 255, 255, 0.16)',
    borderBottomWidth: 1,
  },
  activeItem: {
    backgroundColor: 'rgba(48, 173, 127, 0.16)',
    borderWidth: 1,
    borderColor: colors['color-success-500'],
    bordeRadius: 4,
    paddingLeft: 8,
    paddingRight: 12,
    paddingVertical: 8,
    paddingBottom: 8,
    marginVertical: 4,
  },
  icon: {
    color: colors['color-success-500'],
    width: 32,
    height: 32,
  },
});

export interface OpenedPresentationRowProps {
  item: LoadedPresentation,
  removePresentation: (id: string) => void,
  changeActivePresentation: (id: string) => void,
  activePresentation?: LoadedPresentation,
  variant?:OpenedPresentationsVariantOption
}

enum Actions {
  change,
  close
}
type Action = keyof typeof Actions
type ActionEffects = Record<Action, (id: string) => void>

const DocumentOpenedPresentationRow: React.FC<OpenedPresentationRowProps> = ({
  item, removePresentation, changeActivePresentation, activePresentation, variant = 'default',
}) => {
  const s3URL = detectArchivedFileKeyPath(item.currentPresentablePage.documentVersionORM?.model,
    item.currentPresentablePage.page)
  const isActive = activePresentation?.presentable.id === item.presentable.id;
  const isDocumentVersion = !isPageGroupORM(item.presentable.orm) && (isDocumentVersionORM(item.presentable.orm) ||
  isDocumentVersionORM(item.presentable.orm.relations.itemORM));
  const isModifiedDocumentVersion = isFolderItemORM(item.presentable.orm) && isDocumentVersion &&
  item.presentable.orm.model.visiblePages?.length;

  const isInPersonActionBar = variant === 'inPersonActionBar'

  const rowStyle = [
    !isInPersonActionBar && isActive && styles.activeItem,
    !isInPersonActionBar && !isActive && styles.containerStyle,
    isInPersonActionBar && styles.inPersonControlContainerStyle,
  ]

  const actionEffects:ActionEffects = {
    change: changeActivePresentation,
    close: removePresentation,
  }

  const triggerAction = (action:Action) => {
    actionEffects[action](item.presentable.id)
  }

  return (
    <DNACard
      appearance="flat"
      style={rowStyle}
    >
      <DNABox alignY="center" spacing="between" childFill={0}>
        <DNABox spacing="between" alignY="center" childFill={0}>
          <Pressable onPress={ () => triggerAction('change') }>
            <DNABox spacing="md" alignY="center" childFill={1}>

              <DNABox
                style={{
                  backgroundColor: colors['color-gray-500'],
                  borderWidth: 1,
                  borderColor: 'rgba(255, 255, 255, 0.32)',
                }}
              >
                <DNAThumbnail
                  s3URL={s3URL}
                  useLoadingIndicator={true}
                  width={80}
                  height={46}
                />
              </DNABox>
              <DNABox spacing="sm" fill childFill={0}>

                {/* Content Info */}
                <DNABox spacing="sm" appearance="col" fill>
                  <DNABox spacing="xs" appearance="col" fill>
                    <DNAText testID="document-presenation-title" numberOfLines={2} status="basic">
                      {item.presentable.title}
                    </DNAText>
                    { isPageGroupORM(item.presentable.orm) &&
                      <DNAText numberOfLines={2} status="subtle">{item.presentable.orm.model.name}</DNAText>
                    }
                  </DNABox>
                  <DNABox spacing="sm">
                    <Iffy is={isDocumentVersion}>
                      <DNABox spacing="sm">
                        <CustomFieldBadgeList documentVersionORM={item.currentPresentablePage.documentVersionORM} />
                        <DNAChip appearance="tag">
                          {item.currentPresentablePage.documentVersionORM.model?.type}
                        </DNAChip>
                      </DNABox>
                    </Iffy>
                    <Iffy is={isModifiedDocumentVersion || !isDocumentVersion}>
                      <DNAChip
                        appearance="tag"
                        style={{ backgroundColor: 'rgba(255,255,255,0.16)' }}
                      >
                        MODIFIED
                      </DNAChip>
                    </Iffy>
                  </DNABox>
                </DNABox>

                {/* Action buttons */}
                <Iffy is={!isInPersonActionBar}>
                  <DNABox spacing="sm" fill alignY="center">
                    <Iffy is={isActive}>
                      <DNAText status="success">Presenting...</DNAText>
                    </Iffy>
                    <DNAButton
                      onPress={() => triggerAction('close')}
                      appearance="ghostLink"
                      status="gray"
                      size="md"
                      colorMode="dark"
                      style={styles.presentButton}
                    >
                      <DNAText status="basic">Close</DNAText>
                    </DNAButton>
                  </DNABox>
                </Iffy>
                <Iffy is={isInPersonActionBar && isActive}>
                  <DNABox fill alignY="center">
                    <Icon name="check" style={styles.icon} />
                  </DNABox>
                </Iffy>
              </DNABox>

            </DNABox>

          </Pressable>

        </DNABox>
      </DNABox>
    </DNACard >
  )
}

export default DocumentOpenedPresentationRow
