import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { DNABox, DNACard, DNAText, Iffy } from '@alucio/lux-ui/src';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import WidgetContentCard from 'src/screens/Hubs/EditHub/Widgets/WidgetContentCard';
import { useHubRHFromState } from './useHubRHFormStateProvider';


const styles = StyleSheet.create({
  contentContainer: {
    backgroundColor: colors['color-gray-10'],
    padding: 16,
  },
  emptyStateCard: {
    borderColor: colors['color-gray-80'],
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 27,
    paddingHorizontal: 24,
  },
});

const Content: React.FC = () => {
  const { widgets } = useHubsState()
  const { rhform } = useHubRHFromState()

  return (
    <DNABox as={ScrollView} fill appearance="col" style={ styles.contentContainer }>
      <DNABox appearance="col" spacing="lg" keepSpacingLastItem>
        {/* Empty State */}
        <Iffy is={!widgets.length}>
          <DNACard
            appearance="flat"
          >
            <DNABox fill alignY="center" style={ styles.emptyStateCard }>
              <DNAText status="flatAlt">Add sections to see them appear here.</DNAText>
            </DNABox>
          </DNACard>
        </Iffy>
        {/* Widgets Mapping */}
        <Iffy is={widgets.length}>
          {widgets.map(widget => {
            return <WidgetContentCard key={widget.type} widget={widget} rhform={rhform}/>
          })}
        </Iffy>
      </DNABox>
    </DNABox>
  )
}

export default Content
