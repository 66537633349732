import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  StyleSheet,
  ScrollView,
  Image,
  FlexStyle,
  Pressable,
  ViewStyle,
  StyleProp,
} from 'react-native';
import {
  DNABox,
  DNAChip,
  DNAText,
  Iffy,
  luxColors,
  Spinner,
  Stack,
  useMouseEvent,
  util,
} from '@alucio/lux-ui';
import ItemWrapper from './Wrapper/Wrapper';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';

import './Style'
import { presentationControlActions } from 'src/state/redux/slice/PresentationControl/presentationControl';
import { PlayerActions } from '@alucio/core';
import { useAppSettings } from 'src/state/context/AppSettings';
import { useDispatch } from 'src/state/redux';
import { DocumentVersionORM, FolderItemORM, PageExtended } from 'src/types/types';
import { v4 as uuidv4 } from 'uuid'
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector';
import { getImageObjectURLFromCloudfront } from 'src/utils/loadCloudfrontAsset/common';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import SlidePreviewModal from '../SlidePreviewModal';
import useContentPageData,
{
  ContentPageData,
  getSlideContentPageDataTitle,
} from 'src/hooks/useContentPageData/useContentPageData';
import { getPresentable } from 'src/state/context/ContentProvider/helper';
import { FileType } from '@alucio/aws-beacon-amplify/src/models';

const styles = StyleSheet.create({
  slideRollModal: {
  },
  slideRollModalTablet: {
    maxWidth: 'auto',
    backgroundColor: 'white',
    marginLeft: -1,
    marginBottom: -1,
    marginRight: -1,
    paddingTop: 10,
    maxHeight: 115,
  },
  darkenedImage: {
    tintColor: luxColors.basicBlack.primary,
    opacity: 0.4,
    resizeMode: 'contain',
    width: '100%',
  },
  slideRollFS: {
    backgroundColor: luxColors.contentPanelBackground.primary,
  },
  image: {
    flex: 1,
    resizeMode: 'contain',
  },
  spinner: {
    paddingLeft: 48,
    paddingTop: 40,
  },
  stackLayer: {
    width: '100%',
  },
  thumbnailTouch: {
    flex: 1,
  },
  thumbnail: {
    flex: 1,
    borderColor: luxColors.thumbnailBorder.secondary,
    borderWidth: 2,
  },
  thumbnailNumber: {
    margin: 5,
    marginBottom: 10,
  },
  outerThumbContainerStyle: {
    borderWidth: 3,
    borderRadius: 6,
    borderColor: colors['color-gray-100'],
    marginBottom: 4,
  },
  innerThumbContainerStyle: {
    borderWidth: 3,
    borderRadius: 3,
    borderColor: colors['color-white'],
  },
})

interface SlideRollProps {
  documentVersionORM: DocumentVersionORM | undefined
  isCustomDeck?: boolean;
  folderItem?: FolderItemORM;
  isGroups?: boolean;
  activeSlide: number;
  isGridView?: boolean;
  horizontal?: boolean;
  itemHeight?: number;
  itemWidth?: number;
  numberColor?: string;
  contentUnavailable?: boolean;
  isFullWindow?: boolean
  numberPosition?: 'bottom' | 'left' | 'top' | 'right';
  selectedItemBorderColor?: 'success' | 'default';
  selectedNumberColor?: 'success' | 'default';
  setActiveSlide: (index: number) => void;
  scrollToSelected?: boolean;
  darkenUnselected?: boolean;
  visiblePages?: PageExtended[];
  variant?: 'default',
  showRightMenu?: boolean,
  showPreview?: boolean,
  keepSpacingLastItem?: boolean,
  contentPageData?: ContentPageData[],
}
interface NumberPosition {
  [key: string]: FlexStyle['flexDirection'];
}

const SELECTED_BORDER = {
  default: luxColors.thumbnailBorder.primary,
  success: luxColors.success.primary,
};

const SELECTED_NUMBER = {
  default: colors['color-gray-400'],
  success: luxColors.success.primary,
};

const NUMBER_POSITION: NumberPosition = {
  bottom: 'column',
  top: 'column-reverse',
  left: 'row-reverse',
  right: 'row',
};

interface PopulatedPage extends PageExtended {
  imgBlob: string
  key: string
  type?: DocumentVersionORM['model']['type']
}

const SlideRoll:React.FC<SlideRollProps> = ({
  isCustomDeck,
  isGroups,
  contentUnavailable,
  activeSlide,
  setActiveSlide,
  documentVersionORM,
  itemHeight = 67,
  isFullWindow,
  itemWidth = 120,
  isGridView,
  horizontal = true,
  numberColor,
  selectedItemBorderColor = 'default',
  numberPosition = 'bottom',
  selectedNumberColor,
  scrollToSelected,
  visiblePages,
  variant = 'default',
  showPreview,
  keepSpacingLastItem,
  darkenUnselected,
  folderItem,
}) => {
  /** Allow vertical wheel scroll to trigger horizontal scrolling */
  const handleMouseEvent = (e: MouseEvent | Event | TouchEvent) => {
    if (e instanceof WheelEvent) {
      const scrollEl = scrollViewRef.current?.getScrollableNode() as HTMLElement
      if (scrollEl) { scrollEl.scrollLeft += e.deltaY }
    }
  }
  useMouseEvent(handleMouseEvent)

  const { deviceMode } = useAppSettings()
  const dispatch = useDispatch()
  const isTablet = deviceMode === 'tablet'
  const [pages, setPages] = useState<PopulatedPage[]>()
  const scrollViewRef = useRef<ScrollView>(null)
  const visibleDocumentPages = visiblePages || documentVersionORM?.meta.allPages;
  const presentable = getPresentable(folderItem || documentVersionORM!);
  const { contentPageData } = useContentPageData(presentable);

  const visibleTypeBadges:DocumentVersionORM['model']['type'][] = [FileType.WEB, FileType.HTML, FileType.MP4]

  // If we're in horizontal mode memoize positions based on width, otherwise memoise based on height
  const itemsPosition =
    useMemo(
      () => {
        // Given the space of the item (width or height), this returns the position of each one.
        const getItemsPosition = (itemSize) => (pages || [])
          .map((page, index) => index === 0
            ? 0
            : index * (itemSize + 8),
          )

        return horizontal
          ? getItemsPosition(itemWidth)
          : getItemsPosition(itemHeight)
      },
      [pages, horizontal],
    ),

    // DynamicStyles
    darkenedImageStyle = [styles.image, styles.darkenedImage, { height: itemHeight }],
    thumbnailWrapperStyle: StyleProp<ViewStyle> = {
      width: itemWidth,
      flexDirection: NUMBER_POSITION[numberPosition],
      paddingTop: 12,
    }

  useEffect(() => {
    let isComponentMounted = true;
    // Get images from cloudfont, using the auth token
    async function init() {
      const populatedPages = await Promise.all(
        visibleDocumentPages?.map(async (page: PageExtended) => {
          const archivedKey = page.documentVersionORM
            ? detectArchivedFileKeyPath(page.documentVersionORM.model, page, 'sm')
            : detectArchivedFileKeyPath(documentVersionORM?.model, page, 'sm')

          const blob = await getImageObjectURLFromCloudfront(archivedKey)
          const type = page.documentVersionORM?.model.type

          const newPopulatedPage:PopulatedPage = {
            ...page,
            imgBlob: blob,
            key: uuidv4(),
            type,
          }

          return (newPopulatedPage)
        }) ?? [],
      );
      if (isComponentMounted) {
        setPages(populatedPages);
      }
    }
    if (documentVersionORM || visibleDocumentPages) {
      init()
    }
    return () => {
      isComponentMounted = false;
    }
  }, [documentVersionORM?.model?.convertedFolderKey, visibleDocumentPages]);

  // Uses the memoised positions of thumbnails to autoscroll the tray so that the active slide is
  // always in the first position
  function scrollToItem(): void {
    if (scrollToSelected) {
      const activeSlideIndex = (isCustomDeck || isGroups) ? activeSlide - 1
        : visiblePages?.findIndex(({ number }) => number === activeSlide) || (activeSlide - 1);

      // Account for horizontal/vertical orientation
      const x = horizontal ? itemsPosition[activeSlideIndex] : 0
      const y = horizontal ? 0 : itemsPosition[activeSlideIndex]

      scrollViewRef?.current?.scrollTo({ x, y, animated: true });
    }
  }
  useEffect(scrollToItem, [activeSlide, visiblePages]);

  const thumbnails = !contentUnavailable && pages?.map(({ number, imgBlob, pageId, groupId, type }, index) => {
    const isVisivleTypeBadge =
      type &&
      visibleTypeBadges.includes(type) &&
      isCustomDeck
    const isSelected = activeSlide === ((isCustomDeck || isGroups) ? index + 1 : number);
    const numberColorStyle = selectedNumberColor && isSelected
      ? { color: SELECTED_NUMBER[selectedNumberColor] }
      : numberColor ? { color: numberColor } : null
    const numberStyle = util.mergeStyles(
      undefined,
      styles.thumbnailNumber,
      numberColorStyle,
      [{ textAlign: 'right' }, numberPosition === 'right'],
    )

    function onSelected(): void {
      // NOTE: FOR CUSTOM DECKS, SINCE SAME PAGE CAN BE USED MULTIPLE TIMES, THE ONLY WAY FOR US TO KNOW
      // IN WHICH PAGE ARE WE (ACTIVE SLIDE), IS BY THE INDEX OF THE PAGE IN THE VISIBLEPAGES ARRAY
      setActiveSlide((isCustomDeck || isGroups) ? index + 1 : number);
      /**
       * TODO: Temporary workaround. The call above is duplicative but stripping out the setactiveslide
       * call above causes the presentation not to show in virtual. Will continue to investigate
       */
      // IF WE'RE IN A CUSTOM DECK, THE IFRAME WILL HANDLE THIS SINCE THE NEW PAGE MIGHT BE OF ANOTHER DOCUMENT
      if (!(isCustomDeck || isGroups)) {
        dispatch(
          presentationControlActions.triggerPresentationAction(
            { action: PlayerActions.gotoSlide, param: number },
          ),
        )
      }
    }

    const onPreview = () => {
      const pageNumber = (isCustomDeck || isGroups) ? number : index + 1;
      const page = visiblePages?.find(({ number }) => number === pageNumber)
      const archivedKey = page
        ? (detectArchivedFileKeyPath(documentVersionORM?.model, page, 'xl') ||
          detectArchivedFileKeyPath(page.documentVersionORM?.model, page, 'xl'))
        : ''

      dispatch(
        DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: true,
          component: (props) => (<SlidePreviewModal
            presentPage={onSelected}
            archivedKey={archivedKey || ''}
            title={title}
            {...props}
          />),
        }))
    }
    const pageNumber = isGroups ? number - 1 : index;
    const title = contentPageData && contentPageData.length > 0
      ? getSlideContentPageDataTitle(pageNumber, contentPageData) : '';

    if (variant === 'default') {
      return (
        <DNABox style={thumbnailWrapperStyle} key={pageId + groupId}>
          <ItemWrapper
            onPresent={onSelected}
            onPreview={onPreview}
            showPreview={showPreview}
            showPresentHover={isGridView}
            itemHeight={itemHeight}
          >
            <Pressable
              onPress={onSelected}
              style={styles.thumbnailTouch}
            >
              <DNABox
                testID={`slide-roll-image-${index + 1}`}
                style={util.mergeStyles(
                  undefined,
                  styles.thumbnail,
                  [{ borderColor: SELECTED_BORDER[selectedItemBorderColor] },
                    isSelected],
                )}
              >
                <Stack>
                  <Stack.Layer style={[styles.stackLayer, { height: itemHeight }]}>
                    <Image
                      source={{ uri: imgBlob }}
                      style={[styles.image, { height: itemHeight }]}
                    />
                  </Stack.Layer>
                  <Stack.Layer style={[styles.stackLayer, { height: itemHeight }]}>
                    <Iffy is={darkenUnselected && !isSelected}>
                      <Image
                        source={{ uri: imgBlob }}
                        style={darkenedImageStyle}
                      />
                    </Iffy>
                  </Stack.Layer>
                </Stack>
              </DNABox>
            </Pressable>
          </ItemWrapper>
          <DNABox spacing="between" style={{ marginTop: 6 }} childFill={0}>
            {/* Page Number */}
            <DNAText bold numberOfLines={1} style={numberStyle}>{`${index + 1}. ${title}`}</DNAText>

            {/* Badge */}
            {isVisivleTypeBadge &&
              <DNAChip
                appearance="tag"
                status="basic"
                size="sm"
              >{type}
              </DNAChip>
             }

          </DNABox>

        </DNABox>
      )
    }
  })

  // Spinner Thumbnails
  const spinners = visibleDocumentPages?.map((_e, index) => (
    <DNABox
      key={`thumbnails_spin_${index}`}
      style={[
        { backgroundColor: colors['color-gray-transparent-40'], height: itemHeight },
        horizontal
          ? { paddingHorizontal: 20, width: itemWidth, marginVertical: 20 }
          : { paddingVertical: 20 },
        contentUnavailable && { backgroundColor: luxColors.basicBlack.primary, height: itemHeight },
      ]}
      alignX="center"
      alignY="center"
    >
      {!contentUnavailable && <Spinner />}
    </DNABox>
  ));

  if (isGridView) {
    return (
      <ScrollView>
        <DNABox
          wrap="start"
          spacing="md"
          keepSpacingLastItem={keepSpacingLastItem}
          style={[{ marginRight: 4 }, (!thumbnails && isTablet) && { paddingBottom: 30 }]}
        >
          {thumbnails || spinners}
        </DNABox>
      </ScrollView>
    );
  }

  return (
    <DNABox
      fill={isTablet}
      style={isFullWindow
        ? styles.slideRollFS
        : [
          styles.slideRollModal,
          isTablet && styles.slideRollModalTablet,
        ]
      }
    >
      <ScrollView
        ref={scrollViewRef}
        horizontal={horizontal}
        scrollEventThrottle={200}
        decelerationRate="fast"
        testID="preview-modal-slide-roll"
      >
        <DNABox
          appearance={horizontal ? 'row' : 'col'}
          spacing="sm"
          alignX={horizontal ? 'end' : 'center'}
          style={[
            !horizontal ? { marginRight: 8 } : {},
            (!thumbnails && isTablet) && { paddingBottom: 30 },
          ]}
        >
          {thumbnails || spinners}
        </DNABox>
      </ScrollView>
    </DNABox>
  )
}

export default SlideRoll;
