import { useMemo } from 'react';
import { CustomFieldDefinition, CustomFieldUsage } from '@alucio/aws-beacon-amplify/src/models';
import { StaticFields } from 'src/components/CustomFields/ComposableForm';

import addMeetingConfig, { getDefaultValues, getSeparatedAttendees } from './AddMeetingConfig';
import onGoingMeetingConfig, { initialDefaultValues } from './MeetingDetailsConfig';
import { CustomFieldValuesMap, MeetingORM } from 'src/types/orms';
import { useMeeting } from 'src/state/redux/selector/meeting';
import { useCRMFormConfig } from 'src/state/redux/selector/crm';
import { useTenantCustomFields } from 'src/state/redux/selector/tenant';
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import { getMappedCustomValues } from 'src/state/redux/selector/common';
import merge from 'lodash/merge';
import { CRMAvailabilityStatus, CRMConnectionStatus } from 'src/state/machines/CRM/crmMachineTypes';

export interface MeetingFormConfig {
  customFields: CustomFieldDefinition[],
  meetingORM?: MeetingORM,
  staticFields: StaticFields,
  staticValues: CustomFieldValuesMap,
  values: CustomFieldValuesMap,
  isLoading: boolean,
  isReadOnly: boolean,
}

const useMeetingFormConfig = (meetingId?: string, isOngoingMeeting?: boolean):
  MeetingFormConfig => {
  const meetingORM = useMeeting(meetingId ?? 'placeholder');
  const {
    isCRMSyncing,
    canUseMeetingCRMFields,
    connectionStatus,
    availabilityStatus,
  } = useCRMStatus();
  const defaultValues = getDefaultValues(meetingORM, isOngoingMeeting);

  const { crmCustomFields, crmDefaultValues } = useCRMFormConfig();

  const meetingFormConfig = isOngoingMeeting ? onGoingMeetingConfig(meetingORM) : addMeetingConfig
  const staticFields = { ...meetingFormConfig, customValues: defaultValues };
  const customFields = useTenantCustomFields({ usages: { internalUsages: [CustomFieldUsage.MEETING] } });

  const usage = { internalUsages: [CustomFieldUsage.MEETING] }

  const separatedAttendees = useMemo(() => getSeparatedAttendees(meetingORM), [meetingORM?.model.attendees]);
  const isLoading =  availabilityStatus === CRMAvailabilityStatus.ENABLED
    ? isCRMSyncing || (connectionStatus === CRMConnectionStatus.CONNECTED &&
    (canUseMeetingCRMFields && crmCustomFields.length === 0)) : false;

  const calculatedCustomFields = availabilityStatus === CRMAvailabilityStatus.DISABLED
    ? customFields
    : canUseMeetingCRMFields && !isOngoingMeeting ? crmCustomFields : []

  const values = useMemo(() => {
    const values = isOngoingMeeting
      ? initialDefaultValues(meetingORM, customFields) : meetingORM?.meta.customValues.configsMap || {};
    const crmCustomValues = getMappedCustomValues(usage, meetingORM?.model.crmRecord?.crmCustomValues, crmCustomFields);

    // lodash merge mutate the first argument, so we need to create a new object
    return merge({ ...crmDefaultValues }, crmCustomValues, values);
  }, [meetingORM, crmCustomFields, canUseMeetingCRMFields]);

  return {
    isLoading,
    customFields: calculatedCustomFields,
    meetingORM,
    staticFields,
    staticValues: { ...defaultValues, ...separatedAttendees },
    values,
    isReadOnly: meetingORM?.model.status === 'LOCKED',
  }
}

export default useMeetingFormConfig;
