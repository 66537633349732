import { useCallback, useEffect, useState, useRef } from 'react'
import type { ScrollView } from 'react-native'
import debounce from 'lodash/debounce'
import { isElement } from 'src/types/typeguards'

const useInView = (ref: React.RefObject<HTMLElement>, root?: React.RefObject<ScrollView>) => {
  const [isInView, setIsInView] = useState(false)
  const observer = useRef<IntersectionObserver>()

  const isVisibleInScrollContainer = useCallback(
    (element: Element | null, container?: Element | null) => {
      if (!element || !container) return false

      const elementRect = element.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      const elementTopRelativeToContainer = elementRect.top - containerRect.top;
      const elementBottomRelativeToContainer = elementRect.bottom - containerRect.top;
      const containerHeight = container.clientHeight;

      const isVerticalVisible =
        elementTopRelativeToContainer < containerHeight &&
        elementBottomRelativeToContainer > 0;

      return isVerticalVisible;
    },
    [],
  )

  const setVisibleDebounced = useCallback(
    debounce(
      (isVisible) => { setIsInView(isVisible) },
      100,
    ),
    [],
  )

  // [TODO] - Need to consider if the component doesn't have a ref on mount, generally should be okay though
  useEffect(
    () => {
      if (!ref.current) {
        return;
      }

      observer.current = new IntersectionObserver(
        ([entry]) => {
          // this is required due the dnd doesn't work properly with the intersection observer
          const isVisibleInParent = isVisibleInScrollContainer(
            entry.target,
            root?.current as unknown as Element, // [NOTE] - Should generally be fine, RN types won't overlap with HTMLTypes normally
          )
          setVisibleDebounced(isVisibleInParent || entry.isIntersecting)
        },
        {
          rootMargin: '0px',
          threshold: 0,
          root: (root?.current && isElement(root?.current))
            ? root.current
            : null,
        },
      )

      observer.current.observe(ref.current)

      return () => {
        if (ref.current && observer.current) {
          observer.current.unobserve(ref.current)
        }
      }
    },
    [],
  )

  return isInView
}

export default useInView
