import React, { useState } from 'react';
import { ControlType, CustomFieldDefinition, FieldDataType } from '@alucio/aws-beacon-amplify/src/models';
import { Collapsible, DNABox, DNAButton, DNAContextMenu, DNADivider, DNAText, Iffy } from '@alucio/lux-ui';
import { Controller, FieldError, useFieldArray } from 'react-hook-form';
import { CustomObjectFieldValue } from 'src/types/orms';
import { ComposableField } from './ComposableField';
import { ObjectFieldHeader } from './ObjectFieldHeader';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { ComposableVariant, RHForm, useComposableForm } from './ComposableForm';
import { internalObjectFields } from './InternalObjectFields';
import { v4 as uuid } from 'uuid';
import { useFormatCustomFields } from './useCustomFields';
import cloneDeep from 'lodash/cloneDeep';

export type ObjectFieldWrapperProps = {
    field: CustomFieldDefinition,
    childFields: CustomFieldDefinition[],
    isReadOnly?: boolean,
    variant?: ComposableVariant;
    values?: CustomObjectFieldValue[],
    disabled?: boolean,
    control: RHForm['control'],
  }

type ObjectFieldDetailProps = {
  field: CustomFieldDefinition,
  childFields: CustomFieldDefinition[],
  variant?: ComposableVariant;
  disabled?: boolean,
  control: RHForm['control'],
  prefixChildId: string,
  value: { [key: string]: string | string[] },
  errors?: Record<string, FieldError>,
}

const formatChildFieldIds = (childField: CustomFieldDefinition, prefixChildId: string) => {
  return {
    ...childField,
    id: `${prefixChildId}.${childField.id}`,
    dependentFieldId: childField.dependentFieldId
      ? `${prefixChildId}.${childField.dependentFieldId}` : undefined,
  }
}

export const ObjectFieldWrapper: React.FC<ObjectFieldWrapperProps> = (props) => {
  const { disabled, childFields, field, control, variant, ...rest } = props
  const { rhForm } = useComposableForm()
  const { append, fields: objects } = useFieldArray(
    {
      control,
      name : field.id,
    },
  );
  const [isCollapsed, setIsCollapsed] = useState(!rest.isReadOnly)
  if (!childFields || childFields.length === 0) return null

  const addField = () => {
    const defaultValues = childFields.reduce((acc, childField) => {
      const defaultOption = childField.fieldValueDefinitions.find((option) => option.isDefault)
      if (childField.fieldType === FieldDataType.MULTICATEGORICAL) {
        acc[childField.id] = defaultOption ? [defaultOption.id] : [];
      }
      else {
        acc[childField.id] = defaultOption ? defaultOption.id : '';
      }
      return acc
    }, { })
    append({ ...defaultValues, id: uuid(), externalId: '' })
  }

  const removeObject = (index: number) => {
    const fieldValue = cloneDeep(rhForm.getValues()?.[field.id])

    if (!fieldValue) return
    if (!Array.isArray(fieldValue)) return

    // remove the item based on the index
    fieldValue.splice(index, 1)

    // reset form state
    rhForm.reset({
      ...rhForm.getValues(),
      [field.id]: fieldValue,
    }, {
      isDirty: true,
    })
  }

  return (
    <DNABox fill appearance="col">
      <ObjectFieldHeader
        title={field.fieldLabel || field.fieldName}
        isCollapsed={isCollapsed}
        variant={variant}
        setIsCollapsed={setIsCollapsed}
        isRequired={field.required}
      />
      <Collapsible
        collapsed={isCollapsed}
        renderChildrenCollapsed
      >
        <DNABox
          key={field.id}
          spacing={!objects.length ? undefined : 'md'}
          style={{
            borderColor: variant === ComposableVariant.DEFAULT ? colors['color-gray-80']
              : colors['black-transparent-56'],
            borderWidth: isCollapsed ? 0 : 1,
            paddingHorizontal: 18,
            paddingVertical: 16,
          }}
          appearance="col"
        >
          {!objects.length && (
            <Iffy is={rest.isReadOnly && !objects.length}>
              <DNABox appearance="col" spacing="sm">
                <DNAText style={{ color: colors['color-gray-300'] }} appearance="subtle">No record was added</DNAText>
              </DNABox>
            </Iffy>)}
          {objects.map((val, i) =>
            (<ObjectRow
              childFields={childFields}
              field={field}
              control={control}
              isReadOnly={rest.isReadOnly}
              value={val}
              prefixChildId={`${field.id}.${i}`}
              removeObject={removeObject}
              index={i}
              variant={variant}
              {...rest}
            />),
          )}
          <Iffy is={!rest.isReadOnly}>
            <DNAButton
              size="sm"
              iconLeft="plus"
              appearance={ variant === ComposableVariant.DEFAULT ? 'outline' : 'filled' }
              status="tertiary"
              onPress={addField}
            >Add {field.fieldLabel || field.fieldName}</DNAButton>
          </Iffy>
        </DNABox>
      </Collapsible>
    </DNABox>

  )
}

interface ObjectRowProps extends ObjectFieldDetailProps {
  index: number,
  isReadOnly?: boolean,
  removeObject: (i: number) => void,
}

interface ObjectRestriction {
  update: boolean,
  delete: boolean,
}

const getObjectsRestrictions = (
  object: ObjectFieldDetailProps['value'],
  field: CustomFieldDefinition,
  isReadOnly?: boolean): ObjectRestriction => {
  const restriction: ObjectRestriction = {
    update: !!isReadOnly,
    delete: !!isReadOnly,
  };

  if (field.objectSetting?.restriction) {
    restriction.update = !!isReadOnly ||
      !!((field.objectSetting.restriction.syncUpdate && object.externalId) ||
      (field.objectSetting.restriction.update && object.id));
    restriction.delete = !!isReadOnly ||
      !!((field.objectSetting.restriction.syncDelete && object.externalId) ||
      (field.objectSetting.restriction.delete && object.id));
  }

  return restriction;
};

const ObjectRow: React.FC<ObjectRowProps> = (props) => {
  const {
    index,
    field,
    removeObject,
    disabled,
    isReadOnly,
    value,
    ...rest
  } = props;

  const objectRestrictions = getObjectsRestrictions(value, field, isReadOnly);

  return (
    <DNABox appearance="col" spacing="sm" key={value.id.toString()}>
      <Iffy is={!objectRestrictions.delete}>
        <DNABox alignY="center" spacing="md" alignX="end" childFill={1}>
          <DNABox appearance="row">
            <DNAContextMenu>
              <DNAContextMenu.Anchor>
                <DNAButton
                  appearance="outline"
                  size="sm"
                  status="gray"
                  padding="sm"
                  iconLeft="dots-vertical"
                />
              </DNAContextMenu.Anchor>
              <DNAContextMenu.Items>
                <DNAContextMenu.Item
                  collapseOnPress
                  delay={100}
                  icon="trash-can-outline"
                  title="Delete"
                  onPress={() => removeObject(index)}
                />
              </DNAContextMenu.Items>
            </DNAContextMenu>
          </DNABox>
        </DNABox>
      </Iffy>
      <DNABox appearance="col">
        <ObjectFieldDetail
          field={field}
          disabled={objectRestrictions.update}
          value={value}
          {...rest}
        />
      </DNABox>
      <DNADivider height={3} style={{ marginTop: 10 }}/>
    </DNABox>
  );
}

const ObjectFieldDetail: React.FC<ObjectFieldDetailProps> = (props) => {
  const { disabled, childFields, prefixChildId, variant, value, control } = props
  const { leftCustomFields, rightCustomFields } =
  useFormatCustomFields(childFields.filter(f => f.controlType !== ControlType.CUSTOM))
  const isTwoColumnsField = leftCustomFields.length > 0 && rightCustomFields.length > 0
  const firstColumnFields = isTwoColumnsField ? leftCustomFields : childFields
  return (
    <DNABox>
      <HiddenFields value={value} control={control} prefix={prefixChildId}/>
      <DNABox appearance="col" style={{ marginRight: rightCustomFields.length > 0 ? 15 : 0 }} fill spacing="sm">
        {
          firstColumnFields.map((leftField, i) => {
            return (
              <ComposableField
                key={`${value.id}-left-${i}`}
                field={formatChildFieldIds(leftField, prefixChildId)}
                defaultChildValue={value[leftField.id]}
                disabled={disabled}
                variant={variant}
              />
            )
          })
        }
      </DNABox>
      <Iffy is={isTwoColumnsField}>
        <DNABox appearance="col" style={{ marginLeft: 15 }} fill spacing="sm">
          {
          rightCustomFields.map((rightField, i) => {
            return (
              <ComposableField
                key={`${value.id}-right-${i}`}
                field={formatChildFieldIds(rightField, prefixChildId)}
                defaultChildValue={value[rightField.id]}
                disabled={disabled}
                variant={variant}
              />
            )
          })
        }
        </DNABox>
      </Iffy>
    </DNABox>
  )
}

interface HiddenFieldsProps {
  value: { [key: string]: string | string[] },
  control?: RHForm['control'],
  prefix: string
}

// TODO: find a better way to keep track of internal fields
const HiddenFields: React.FC<HiddenFieldsProps> = (props) => {
  const { value, control, prefix } = props
  return (
    <>
      {internalObjectFields.map((field) => {
        return (
          <Controller
            key={field.id}
            name={`${prefix}.${field.id}`}
            control={control}
            defaultValue={value[field.id]}
            render={(renderProps) => (
              <input
                type="hidden"
                {...renderProps}
              />
            )}
          />
        )
      })}
    </>
  )
}

export const getValueFromChildField = (arr :object, fieldId: string) : string | string[] | undefined => {
  if (!fieldId.includes('.') || fieldId.split('.').length !== 3) return undefined
  const [parentFieldId, index, childFieldId] = fieldId.split('.')
  return arr[parentFieldId]?.[index]?.[childFieldId]
}

export const getErrorFromChildField = (arr :object, fieldId: string) : FieldError | undefined => {
  if (!fieldId.includes('.') || fieldId.split('.').length !== 3) return undefined
  const [parentFieldId, index, childFieldId] = fieldId.split('.')
  return arr[parentFieldId]?.[index]?.[childFieldId]
}
