import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { GenericToast, ToastOrientations, useToast } from '@alucio/lux-ui';
import { useHistory } from 'react-router';
import format from 'date-fns/format';
import { useDispatch } from 'src/state/redux';
import { v4 as uuid } from 'uuid';
import { API } from '@aws-amplify/api'
import { hubActions } from 'src/state/redux/slice/hub';
import { useHub, useHubList } from 'src/state/redux/selector/hub';
import { HubORM } from 'src/types/orms';
import { Hub, HubSection, HubStatus, HubSectionType } from '@alucio/aws-beacon-amplify/src/models';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import DNACommonConfirmation from 'src/components/DNA/Modal/DNACommonConfirmation';
import { useCurrentUser } from 'src/state/redux/selector/user';
import { useEmailTemplateListForMSL } from 'src/state/redux/selector/emailTemplate';
import DNAFileShareModal, { ShareVariantOptions } from 'src/components/DNA/Modal/DNAFileShareModal/DNAFileShareModal';
import { SHARE_METHOD } from 'src/components/DNA/Modal/DNAFileShareModal/state/context/DNAFileShareModalStateProvider';
import { EhitHubRhform } from 'src/screens/Hubs/EditHub/useHubForm';
import { rhformFieldName } from 'src/screens/Hubs/EditHub/useHubRHFormStateProvider';
import { widgetContextMenu } from 'src/screens/Hubs/EditHub/LeftSideBar';

export interface HubsStateType {
  hubORM: HubORM | undefined,
  handleEditHub: (id: string) => void,
  handleSaveNPreview: (data: any, docVerId?: string) => void,
  openPreviewWindow: (hubId: string, docVerId?: string) => void,
  handleCreateHubs: () => void,
  handlePressShare: (hubId: string) => void,
  handleDeletHub: (hub: Hub, backToHubList?: boolean) => void,
  handleSaveHub: (data: any) => void,
  hubsORM: HubORM[]
  handleCancelEdit: (isFormDirty: boolean, callback?: () => void) => void,

  onInvisibleCallback: React.MutableRefObject<(() => void) | undefined>,
  editHubSliderVisible: boolean,
  setEditHubSliderVisible: React.Dispatch<React.SetStateAction<boolean>>,
  widgets: ModifiedHubSection[],
  setWidgets: React.Dispatch<React.SetStateAction<ModifiedHubSection[]>>,
  items: string[],
  setItems: React.Dispatch<React.SetStateAction<string[]>>,
  handleAddWidget: (type: HubSectionType, rhform: EhitHubRhform) => void,
  handleDeleteWidget: (type: HubSectionType, rhform: EhitHubRhform) => void,
  widgetOptions: AdditionalWidgetOptions,
  setWidgetOptions: React.Dispatch<React.SetStateAction<AdditionalWidgetOptions>>
}

export const HubsStateContext = createContext<HubsStateType | null>(null!)
HubsStateContext.displayName = 'HubsContext'

interface InitialValues {
  hubId: string
}

export interface AdditionalWidgetOptions {
  [key: string]: WidgetOptionsContextMenu[];
}

export interface ModifiedHubSection extends HubSection {
  id: string,
  additionalWidgetOptions?: AdditionalWidgetOptions
}

export const defaultWidgetsValue: Record<HubSectionType, HubSection> = {
  [HubSectionType.TEXTAREA]: {
    type: HubSectionType.TEXTAREA,
    visible: true,
    order: 0,
    textarea: {
      header: 'Header Text',
      content: '',
    },
  },
  [HubSectionType.SHARED_FILES]: {
    type: HubSectionType.SHARED_FILES,
    visible: true,
    order: 1,
    sharedFiles: undefined,
  },
  [HubSectionType.TODO_LIST]: {
    type: HubSectionType.TODO_LIST,
    visible: true,
    toDos: [],
    order: 0,
  },
}

export interface WidgetOptionsContextMenu {
  title: string;
  status: string;
  iconStatus: string;
  icon: string;
  onPress: () => void;
}

const HubsStateProvider: React.FC<PropsWithChildren<InitialValues>> = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const hubsORM = useHubList()
  const hubORM = useHub(props.hubId)
  const toast = useToast()
  const user = useCurrentUser()
  const emailTemplates = useEmailTemplateListForMSL()

  const [editHubSliderVisible, setEditHubSliderVisible] = useState<boolean>(false)
  const [widgetOptions, setWidgetOptions] = useState<AdditionalWidgetOptions>({})
  const onInvisibleCallback = useRef<() => void>()
  const returnToHubList = () => history.push('/hubs')
  const toggleSlider = (onInvisCb?: () => void) => {
    onInvisibleCallback.current = onInvisCb
    setEditHubSliderVisible(p => !p)
  }

  useEffect(() => {
    if (hubORM) setEditHubSliderVisible(true)
  }, [props.hubId])

  const [widgets, setWidgets] = useState<ModifiedHubSection[]>([])
  // For Drag and Drop
  const sortedWidgetsIds = useMemo(() =>
    widgets.sort((a, b) => a.order - b.order).map(widget => widget.id), [widgets])
  const [items, setItems] = useState(sortedWidgetsIds)

  useEffect(() => {
    setItems(sortedWidgetsIds)
  }, [sortedWidgetsIds.length])

  useEffect(() => {
    if (hubORM) {
      const hubSections = hubORM.model.hubSections || []
      const modifiedWidgets = hubSections.map(widget => ({ ...widget, id: uuid() }))
      const updatedSortedWidgetsIds = modifiedWidgets.sort((a, b) => a.order - b.order).map(widget => widget.id)
      setWidgets(modifiedWidgets)
      setItems(updatedSortedWidgetsIds)
    }
    else {
      setWidgets([])
    }
  }, [hubORM])

  const handlePressShare = (hubId: string) => {
    const selectedHubORM = hubsORM.find(hub => hub.model.id === hubId)
    if (selectedHubORM) {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: false,
        backdropVisible: true,
        component: (modalProps) => (
          <DNAFileShareModal
            toast={toast}
            shareContent={selectedHubORM}
            currentUser={user}
            emailTemplates={emailTemplates}
            shareMethod={SHARE_METHOD.EMAIL}
            {...modalProps}
            variant={ShareVariantOptions.HUB_SHARE}
          />
        ),
      }))
    } else {
      throw Error('Unable to find Hub')
    }
  }

  const createHubsCB = (hub: Hub) => {
    history.push(`/hubs/${hub.id}`)
    toggleSlider()
  }

  const handleCreateHubs = () => {
    const defaultHubSections: HubSection[] = [
      defaultWidgetsValue[HubSectionType.TEXTAREA],
      defaultWidgetsValue[HubSectionType.SHARED_FILES],
    ]

    dispatch(hubActions.createHub({
      name: 'Hub Created ' + format(new Date(), 'LLL d, y'),
      status: HubStatus.ACTIVE,
      hubSections: defaultHubSections,
      // what if hubs creation fails?
      callback: createHubsCB,
    }))
  }

  const handleEditHub = (id: string) => {
    analytics?.track('HUB_EDIT', {
      action: 'EDIT',
      category: 'HUB',
      hubId: id,
    });
    history.push(`/hubs/${id}`)
  }

  const getAuthToken = async () => {
    const idToken = (await API.Auth.currentSession()).getIdToken().getJwtToken()
    return idToken
  }

  const openPreviewWindow = async (hubId: string, docVerId?: string) => {
    const idToken = await getAuthToken()
    const isDev = process.env.NODE_ENV === 'development'
    const base = isDev ? 'http://localhost:3002' : (window.location.origin).replace('beacon', 'content')
    const url = new URL(`/hubs/${hubId}`, base)
    url.searchParams.append('token', idToken);
    if (docVerId) {
      url.searchParams.append('docVerId', docVerId);
    }
    window.open(url.toString(), '_blank')
  }

  const handleDeletHub = (hub: Hub, backToHubList?: boolean) => {
    const deleteHub = async () => {
      await dispatch(hubActions.deleteHub(hub))
      toast?.add(
        <GenericToast title="Hub deleted" status="error" />,
        ToastOrientations.TOP_RIGHT,
      )
    }

    const deleteHubCB = () => {
      returnToHubList()
      deleteHub()
    }

    dispatch(
      DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: () => (<DNACommonConfirmation
          status="danger"
          cancelText="Cancel"
          confirmActionText="Delete"
          onConfirmAction={backToHubList ? () => toggleSlider(deleteHubCB) : deleteHub}
          title="Delete hub?"
          descriptionText="This action cannot be undone."
        />),
      }));
  }

  const SaveHubToDB = (hub: Hub, updatedContent: Hub, callback?: () => void) => {
    dispatch(hubActions.updateHub({hub, updatedContent, callback}))
    if (!callback) toggleSlider(returnToHubList)
  }

  const handleSaveHub = useMemo(() => async (data: any) => {
    if (hubORM) {
      try {
        const hubSections: HubSection[] = []
        if (data.textareaWidget) hubSections.push(data.textareaWidget)
        if (data.sharedFilesWidget) hubSections.push(data.sharedFilesWidget)
        if (data.todoListWidget) hubSections.push(data.todoListWidget)
        const payload = {
          ...hubORM.model,
          name: data.name,
          hubSections,
        }
        SaveHubToDB(hubORM.model, payload)
      } catch (e) {
        // [TODO]: add form error message banner
        console.log('data', data)
        console.log('onSubmitForm faild e', e)
      }
    }
  }, [hubORM, widgets])

  const handleSaveNPreview = useMemo(() => async (data: any, docVerId?: string) => {
    if (hubORM) {
      try {
        const hubSections: HubSection[] = []
        if (data.textareaWidget) hubSections.push(data.textareaWidget)
        if (data.sharedFilesWidget) hubSections.push(data.sharedFilesWidget)
        if (data.todoListWidget) hubSections.push(data.todoListWidget)
        const payload = {
          ...hubORM.model,
          name: data.name,
          hubSections,
        }
        SaveHubToDB(hubORM.model, payload, () => openPreviewWindow(hubORM?.model.id, docVerId))
      } catch (e) {
        // [TODO]: add form error message banner
        console.log('data', data)
        console.log('onSubmitForm faild e', e)
      }
    }
  }, [hubORM, widgets])

  const handleCancelEdit = (isFormDirty: boolean, callback?: () => void) => {
    const onConfirm = () => {
      if (callback) callback()
      toggleSlider(returnToHubList)
    }

    if (isFormDirty) {
      dispatch(
        DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: true,
          component: () => (<DNACommonConfirmation
            cancelText="Continue editing"
            confirmActionText="Discard changes"
            onConfirmAction={onConfirm}
            title="Unsaved changes"
            descriptionText="All unsaved changes will be lost."
          />),
        }));
    } else onConfirm()
  }

  const handleAddWidget = useMemo(() => (type: HubSectionType, rhform: EhitHubRhform) => {
    const currentFormValue = rhform.getValues()
    const widgetToAdd = defaultWidgetsValue[type]
    setWidgets(pre => {
      // Reset widgets to match current form
      const updatedWidgets = pre.map(widget => {
        const formFieldName: string = rhformFieldName[widget.type]
        const currentFormFieldValue = currentFormValue[formFieldName] ?? {}
        return {
          ...currentFormFieldValue,
          id: widget.id,
        }
      })
      return [...updatedWidgets, { ...widgetToAdd, id: uuid(), order: pre.length }]
    })
  }, [setWidgets])

  const handleDeleteWidget = useMemo(() => (type: HubSectionType, rhform: EhitHubRhform) => {
    dispatch(
      DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: () => (<DNACommonConfirmation
          status="danger"
          cancelText="Cancel"
          confirmActionText="Delete"
          onConfirmAction={() => setWidgets(pre => {
            // Reset widgets to match current form
            const currentFormValue = rhform.getValues()
            const filteredWidget = pre.filter(widget => widget.type !== type)
            const updatedWidgets = filteredWidget.map(widget => {
              const formFieldName: string = rhformFieldName[widget.type]
              const currentFormFieldValue = currentFormValue[formFieldName] ?? {}
              return {
                ...currentFormFieldValue,
                id: widget.id,
              }
            })
            const formName = widgetContextMenu[type].formName
            rhform.setValue(formName, undefined, { shouldValidate: true, shouldDirty: true })
            return updatedWidgets
          })}
          title="Delete section?"
        />),
      }));
  }, [setWidgets])

  const contextValue: HubsStateType = {
    hubORM,
    handleEditHub,
    handleSaveNPreview,
    openPreviewWindow,
    handleCreateHubs,
    handlePressShare,
    handleDeletHub,
    handleSaveHub,
    hubsORM,
    handleCancelEdit,

    onInvisibleCallback,
    editHubSliderVisible,
    setEditHubSliderVisible,
    widgets,
    setWidgets,
    items,
    setItems,
    handleAddWidget,
    handleDeleteWidget,
    widgetOptions,
    setWidgetOptions
  }

  return (
    <HubsStateContext.Provider value={contextValue}>
      {props.children}
    </HubsStateContext.Provider>
  )
}
HubsStateProvider.displayName = 'HubsStateProvider'
export const useHubsState = () => {
  const context = useContext(HubsStateContext)
  if (!context) {
    throw new Error('useHubsState must be used within the HubsStateProvider')
  }
  return context;
}

export default HubsStateProvider
