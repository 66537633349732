import React from 'react';
import { Auth } from '@aws-amplify/auth'
import 'src/state/machines/auth/validateUserMachine';
import { Hub, Logger } from '@aws-amplify/core'
import { Provider as ReduxProvider } from 'react-redux';
import { WebLinking as Linking } from '@alucio/core'
import { store } from 'src/state/redux';
import {
  LuxTheming,
  AlucioTheme,
  BrowserChecker,
  withToastProvider,
} from '@alucio/lux-ui';
import { deleteDB } from 'idb';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { Router } from './router';
import Routes, { RoutesListener } from './router/routes';
import DevLogger from 'src/components/DevLogger/DevLogger'
import config from 'src/config/app.json';

// COMPONENTS
import AppContainer from 'src/AppContainer';
import { ErrorBoundary } from 'react-error-boundary'
import Error from 'src/components/DNA/Error'
import Authenticator from './components/Authenticator';
import Idle from 'src/components/IdleComponent/IdleComponent';
import ContentPreviewModal from './components/ContentPreviewModal/ContentPreviewModal';
import UserInit from 'src/components/UserInit/UserInit';
import RightSideBar from 'src/components/Sidebar/RightSideBar';
import DNAConnectedModal from 'src/components/DNA/Modal/DNAConnectedModal'
import AppSettings from 'src/state/context/AppSettings'
import DNAAppContainer from 'src/components/DNA/AppContainer/DNAAppContainer'
import SlideSelector from './components/SlideSelector/SlideSelector';
import CacheDB from './worker/db/cacheDB';
import PresentationBuilder from './components/PresentationBuilder/PresentationBuilder';
import ContentPreviewModalV2 from './components/ContentPreviewModalV2/ContentPreviewModalV2';
import { useFeatureFlag } from './hooks/useFeatureFlag/useFeatureFlag';
import { FeatureFlags } from './types/featureFlags';
import { pageFunctionIntercept } from './utils/analytics';
import { CRMUtil } from './state/machines/CRM/util';
import { SignOutProvider } from './components/Authenticator/LogOut';

const logger = new Logger('ServiceWorker', 'INFO')
const { interceptCRMAuthCode }  = new CRMUtil()
const UnsupportedBrowserImage = require('../../beacon/assets/images/UnsupportedBrowser.svg');

// override the default behavior of the page event in segment
analytics.ready(() => {
  window.analytics.page = pageFunctionIntercept(window.analytics.page);
})

// [TODO] - Consider centralizing logout relating functions in one place (e.g. src/components/Authenticator)
// [TODO-PWA] - Consider moving this to the service worker layer instead
const logout = async () => {
  const isPWAStandalone = window.matchMedia('(display-mode: standalone)').matches

  window.localStorage.clear();
  analytics?.track('LOGIN_LOGOUT_FORCED', {
    action: 'LOGOUT_FORCED',
    category: 'LOGIN',
  });

  // Nuke all IndexDBs we use this function instead of datastore as a fail safe
  const dbsToDelete = ['amplify-datastore', 'analyticsOffline']
  if (!isPWAStandalone) {
    dbsToDelete.push('CacheDB')
    // De-register the service worker if it's registered
    const registration = await navigator.serviceWorker.getRegistration('/')
    if (registration) {
      logger.info('Unregistering service worker', registration)
      const isUnregistered = await registration?.unregister()
      logger.info('SW Unregistered', isUnregistered)
    }
  } else {
    try {
      const cacheDB = new CacheDB()
      await cacheDB.open()
      await cacheDB.purge()
      await cacheDB.close()
    } catch (e) {
      console.warn('Error occured purging cacheDB', e)
    }
  }
  for (const idbName of dbsToDelete) {
    try {
      await deleteDB(idbName)
    } catch (e) {
      console.warn(`Error deleting indexDB ${idbName}`, e)
    }
  }
  try {
    await Auth.signOut();
    Hub.dispatch('logout', { event: 'logout' });
  } catch (e) {
    console.warn(e)
  }
  // Use a refresh query to possibly avoid a cached app reload
  Linking.openURL(`/?refresh=${Date.now()}`, '_self')
}

const App: React.FC = () => {
  const enableNewContentPreviewModal = useFeatureFlag(FeatureFlags.BEAC_1538_Content_Preview_Modal_V2)
  return (
    <SignOutProvider>
      <UserInit>
        <RightSideBar>
          <DNAAppContainer>
            <Idle />
            <DNAConnectedModal />
            <PresentationBuilder />
            <RoutesListener />
            <Routes />
          </DNAAppContainer>
        </RightSideBar>
        <SlideSelector />
        {enableNewContentPreviewModal ? <ContentPreviewModalV2 /> : <ContentPreviewModal />}
      </UserInit >
    </SignOutProvider>
  );
};

const withProviders = ChildComponent => () => {
  interceptCRMAuthCode(true);

  const notifyError = (error) => {
    console.warn('Error encountered:', error)

    const { NODE_ENV } = process.env

    if (NODE_ENV && NODE_ENV !== 'development') {
      analytics?.track('APP_ERROR', {
        action: 'ERROR',
        category: 'APP',
        name: error.name,
        message: error.message,
      });

      const attributes = {
        segment_anonymous_id: analytics?.user().anonymousId(),
        user_id: analytics?.user().id() ?? '',
      }

      newrelic?.noticeError(error, attributes)
    }
  }

  // We keep this at the very top so that we can always get to /logout to force reload
  // the session
  if (window.location.pathname.startsWith('/logout')) {
    logout()
    return null;
  }

  return (
    <ErrorBoundary
      onError={notifyError}
      FallbackComponent={() => (
        <Error
          message={'Something has gone wrong, please reload this page!'}
        />
      )}
    >
      <ReduxProvider store={store}>
        <AppSettings>
          <Router>
            <AppContainer>
              <LuxTheming config={AlucioTheme}>
                <>
                  <BrowserChecker
                    unsupportedBrowserImage={UnsupportedBrowserImage}
                  >
                    {/* [TODO-PWA] - ALWAYS DISABLE THIS IN PRODUCTION */}
                    <DevLogger disable={true} />
                    <Authenticator>
                      <ChildComponent />
                    </Authenticator>
                  </BrowserChecker>
                </>
              </LuxTheming>
            </AppContainer>
          </Router>
        </AppSettings>
      </ReduxProvider>
    </ErrorBoundary>
  );
};

export default withLDProvider({
  clientSideID: config.LaunchDarklyKey,
  user: { key: 'AnonymousUser' },
},
)(withToastProvider(withProviders(App)));
