import {
  CustomFieldUsage,
  DocumentStatus,
  FieldStatus,
} from '@alucio/aws-beacon-amplify/src/models';
import format from 'date-fns/format'
import { CurrentUser } from 'src/state/redux/selector/user';
import { DocumentORM, getAssociatedFileTitle } from 'src/types/types'
import { TenantORM } from 'src/state/redux/selector/tenant';

export const exportData = (allDocumentsCSV: DocumentORM[], currentUser: CurrentUser, tenants: TenantORM[]) => {
  const [docVers] = allDocumentsCSV;
  const tenantDoc = tenants.find(e => e.tenant.id === docVers.model.tenantId);

  if (!tenantDoc) {
    console.warn('Tenant not found')
    return;
  }

  const tenantCustomFields = tenantDoc.tenant
    ?.config
    ?.customFields
    ?.filter(e => e.usage.includes(CustomFieldUsage.DOCUMENT) && e.status === FieldStatus.ENABLED);

  const sortedTenantCustomFields = tenantCustomFields
    ?.sort((a, b) => (a.order || 0) - (b.order || 0))

  if (!sortedTenantCustomFields) {
    console.warn('Custom fields not found')
    return;
  }

  const headers = [
    'Document ID',
    'Title',
    'Status',
    'Created At',
    'Updated At',
    'Type',
    'Uploaded File Name',
    'Latest Pages',
    'Published File Name',
    'Published Pages',
    'Associated Files',
    ...sortedTenantCustomFields.map(e => e.fieldLabel),
  ]

  const removeLineBreaks = (text: string | undefined) => {
    if (!text) return undefined;
    return text.replace(/(\r\n|\n|\r)/gm, '').trim();
  }

  const rows = allDocumentsCSV
    .filter(document => document.model.status !== DocumentStatus.DELETED && document.model.id.trim())
    .map((document) => {
      const recentPublishedVersion =
        document.relations.version.latestPublishedDocumentVersionORM ??
        document.relations.version.latestDocumentVersionORM;

      const docId = document.model.id;
      const title = removeLineBreaks(recentPublishedVersion.model.title) || '';
      const latestFileName = removeLineBreaks(
        document.relations.version.latestDocumentVersionORM.model.srcFilename,
      ) || '';
      const associatedFilesCSV = recentPublishedVersion.relations.associatedFiles
        .filter(e => e.model.status !== DocumentStatus.DELETED)
        .map((assocFile) => {
          return removeLineBreaks(getAssociatedFileTitle(assocFile))
        }).join('|')
      const publishedFileName = removeLineBreaks(recentPublishedVersion?.model.srcFilename) || '';
      const publishedPages = recentPublishedVersion?.meta.allPages.length.toString() || '0';
      const status = document.model.status;
      const type = document.model.type;
      const createdAt = document.model.createdAt;
      const updatedAt = document.model.updatedAt;
      const lastestPages = document.relations.version.latestDocumentVersionORM.meta.allPages.length || '';

      const fields = sortedTenantCustomFields.map((currField) => {
        const fieldId = currField.id
        const valuesMap = document.meta.customValues.configsMap;

        if (!valuesMap) {
          console.warn('Custom Fied Mapping not found for document', document.model.id)
          return ''
        }
        const fieldValues = valuesMap[fieldId]

        if (!fieldValues) {
          console.warn('fieldValue not found for', fieldId)
          return '';
        }

        const valueStr = fieldValues.displayValues && fieldValues.displayValues.length > 0
          ? fieldValues.displayValues.map(fieldValueDefinition => fieldValueDefinition).join('|')
          : fieldValues.displayValues[0] || ''

        return valueStr
      })

      const row = [
        docId,
        title,
        status,
        createdAt,
        updatedAt,
        type,
        latestFileName,
        lastestPages.toString(),
        publishedFileName,
        publishedPages,
        associatedFilesCSV,
        ...fields,
      ]
      return row;
    })

  const csvContent = [headers, ...rows].map(e => `"${e.map(d => d.replace(/"/g, '""')).join('","')}"`).join('\n');
  const link = document.createElement('a');
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const csvUrl = window.webkitURL.createObjectURL(blob);

  const tenant = tenants.find((tenant) => tenant.tenant.id === currentUser.userProfile?.tenantId);
  const tenantName = tenant?.tenant.name.replace(/[^a-z0-9+]+/gi, '_').substring(0, 50)

  link.setAttribute('download', `${tenantName || ''}contentReport${format(new Date(), 'MM-dd-yyyy')}.csv`);
  link.setAttribute('href', csvUrl);

  document.body.appendChild(link);

  link.click();
}

export const download = (url, filename) => {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(console.error);
}
