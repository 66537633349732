import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'src/state/redux';
import BackButtonModal from 'src/screens/Meetings/SharedComponents/Modals/BackButtonModal';
import ReopenPopoutModal from 'src/screens/Meetings/SharedComponents/Modals/ReopenPopoutModal';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { MeetingORM } from 'src/types/orms';
/** TODO: @alucio-lkeyes is currently deciding if we want this feature. Switch to or remove this and related code in
* https://alucioinc.atlassian.net/browse/BEAC-3569
*/
// import { useTenant } from 'src/state/redux/selector/tenant';
// import ActiveUser from 'src/state/global/ActiveUser';
import { BroadcastChannel } from 'broadcast-channel'
import { Logger } from '@aws-amplify/core'

interface PopoutContentBCPayload {
  type: 'meetingLoaded' | 'popoutWindowLoaded'
}
interface MeetingLoadedPayload extends PopoutContentBCPayload{
  type: 'meetingLoaded'
  meetingORM: MeetingORM
}
interface PopoutWindowLoadedPayload extends PopoutContentBCPayload{
  type: 'popoutWindowLoaded'
}

export type PopoutContentBCPayloads = MeetingLoadedPayload | PopoutWindowLoadedPayload

const logger = new Logger('Meetings Popout Content State', 'INFO');

/** NOTE: Popout window config */
const url = new URL(`${window.location.origin}/meeting-content/`)
const target = '_blank'
const leftMargin = (window.screen.width * 0.1) / 2;
const topMargin = (window.screen.height * 0.1) / 2;
const height = window.screen.height - (window.screen.height * 0.12);
const width = window.screen.width - (window.screen.width * 0.1);

// eslint-disable-next-line max-len
const features = `directories=no, resizable=0, titlebar=no, toolbar=no, location=no, status=no, menubar=no, scrollbars=no, toolbar=0, scrollbars=0, location=0, statusbar=0, menubar=0, height=${height}, width=${width},left=${leftMargin}, top=${topMargin}`

const useMeetingsPopoutContentState = (meetingORM: MeetingORM | undefined, endMeeting: () => void) => {
  const broadcastChannel = useRef<BroadcastChannel<PopoutContentBCPayloads>>()
  const [popoutWindowLoaded, setPopoutWindowLoaded] = useState<boolean>()
  /** TODO: @alucio-lkeyes is currently deciding if we want this feature. Switch to or remove this and related code in
  * https://alucioinc.atlassian.net/browse/BEAC-3569
  */
  // const tenant = useTenant(ActiveUser.user!.tenantId)

  const dispatch = useDispatch();

  const popoutReference = useRef<Window | null>()

  const handlePopoutUnloaded = (e: Event) => {
    logger.debug('Popout unloaded', { e, closed: popoutReference.current?.closed, meetingORM })

    /** NOTE: In some cases, the popout window can end up getting closed
     * before our main window gets a chance to reset the visibility state.
     * For example, when moving the popup to another virtual desktop,
     * then switching to that desktop and closing the popup.
    */
    setPopoutContentHidden(false)
    setPopoutWindowLoaded(false)

    /** TODO: Identify why the meeting status does not get updated here Even after the
     * meeting has ended the status prop on the model still registers as IN_PROGRESS */
    if (meetingORM?.model.status === 'IN_PROGRESS') {
      dispatch(
        DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: true,
          component: (props) => (<ReopenPopoutModal
            {...props}
            onConfirmAction={handleMeetingStatusUpdate}
          />),
        }))
    }
  }

  const [popoutContentHidden, setPopoutContentHidden] = useState<boolean>(false)

  const handleVisibilityUpdate = () => { setPopoutContentHidden(!!popoutReference.current?.document.hidden) }

  const handlePopoutLoaded = () => {
    /** TODO: @alucio-lkeyes is currently deciding if we want this feature. Switch to or remove this and related code in
    * https://alucioinc.atlassian.net/browse/BEAC-3569
    */
    // popoutReference.current && (popoutReference.current.document.title = `${tenant?.tenant.name} Presentation Content`)

    if (popoutReference.current) {
      popoutReference.current.document.title = 'Presentation Content'
      popoutReference.current.onunload = handlePopoutUnloaded
      popoutReference.current.document.onvisibilitychange = handleVisibilityUpdate
    }
  }

  const launchPopoutContentWindow = () => {
    /** TODO: Load normal domain related popup (switch back to this once we have purchased a vanity domain) */
    popoutReference.current = window.open(url, target, features)
  }

  /** Meeting Status Update Event Listener */
  const handleMeetingStatusUpdate = () => {
    logger.debug('Meeting updated', meetingORM?.model.status)
    if (meetingORM) {
      const { status, type } = meetingORM.model

      const shouldLaunchPopoutContentWindow =
        type === 'VIRTUAL' &&
        status === 'IN_PROGRESS' &&
        (!popoutReference.current || popoutReference.current.closed)

      /** Virtual Meeting Popup */
      shouldLaunchPopoutContentWindow && launchPopoutContentWindow()
    }
  }
  useEffect(handleMeetingStatusUpdate, [meetingORM])

  /** Back Button Event Listener */
  const onPressBackButton = () => {
    window.history.pushState(null, '', window.location.pathname);
    dispatch(
      DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (props) => (<BackButtonModal
          {...props}
          onConfirmAction={endMeeting}
        />),
      }));
  }

  /** NOTE: Kill the popout window on refresh/navigation to another URL */
  const onBeforeUnload = () => {
    popoutReference.current?.close()
  }

  /** NOTE: This useeffect ensures we are only attempting to sync the meeting once both the popout window and meetingORM have fully loaded */
  const handlePopoutContentSync = () => {
    if ( popoutWindowLoaded && meetingORM) {
      broadcastChannel.current?.postMessage({ type:'meetingLoaded', meetingORM })
    }
  }
  useEffect(handlePopoutContentSync, [popoutWindowLoaded, meetingORM])

  /** NOTE: Init hook */
  useEffect(() => {
    broadcastChannel.current = new BroadcastChannel<PopoutContentBCPayloads>('meeting-content')
    logger.debug('Init hook ran')
    broadcastChannel.current.onmessage = (payload) => {
      logger.debug('Broadcast channel message received', payload)
      switch (payload.type) {
        case 'popoutWindowLoaded':
          setPopoutWindowLoaded(true)
          setPopoutContentHidden(false)
          handlePopoutLoaded()
          handlePopoutContentSync()
          break
      }
    }
    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', onPressBackButton);
    window.addEventListener('beforeunload', onBeforeUnload)
    return () => {
      window.removeEventListener('popstate', onPressBackButton)
      window.removeEventListener('beforeunload', onBeforeUnload);
      broadcastChannel.current?.close()
    }
  }, []);

  return {
    popoutReference,
    popoutContentHidden,
  }
}

export default useMeetingsPopoutContentState
