import React, { ReactElement } from 'react';
import { StyleSheet } from 'react-native';
import { Drawer } from '@alucio/lux-ui';
import { useDispatch, useSelector } from 'react-redux';
import { DRAWER_ENTITIES, drawerActions } from 'src/state/redux/slice/drawer';
import { RootState } from 'src/state/redux';
import UserEdit from '../UserEdit/UserEdit';
import EmailTemplateDrawer from '../EmailTemplateDrawer/EmailTemplateDrawer';
import AddMeeting from '../Meeting/AddMeeting';
import FileDrawer from '../FileDrawer/FileDrawer';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import ConfirmationModal from 'src/screens/Meetings/PresentationControls/TabContent/MeetingDetails/ConfirmationModal';
import TenantForm from '../TenantForm/TenantForm';

const styles = StyleSheet.create({
  mainWrapper: {
    backgroundColor: 'transparent',
  },
});

function RightSideBar(props) {
  const drawer = useSelector((state: RootState) => state.drawer)
  const dispatch = useDispatch();

  const checkFormDiscard = (cb: () => void) => {
    dispatch(
      DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (props) => (<ConfirmationModal
          {...props}
          onConfirmAction={cb}
        />),
      }))
  }

  function toggleDrawer(ignoreChanges?:boolean): void {
    if (ignoreChanges) dispatch(drawerActions.toggle({}));
    else if (drawer.isOpen &&
      (drawer.entity === DRAWER_ENTITIES.MEETING || drawer.entity === DRAWER_ENTITIES.TENANT_FORM) &&
      drawer.hasPendingChanges) {
      checkFormDiscard(() => dispatch(drawerActions.toggle({})))
    }
    else { dispatch(drawerActions.toggle({})); }
  }

  function getDrawerContent(): ReactElement | null {
    switch (drawer.entity) {
      case DRAWER_ENTITIES.EMAIL_TEMPLATE:
        return <EmailTemplateDrawer toggleDrawer={toggleDrawer} templateId={drawer.entityId} />;
      case DRAWER_ENTITIES.MEETING:
        return <AddMeeting meetingId={drawer.entityId} toggleDrawer={toggleDrawer} />;
      case DRAWER_ENTITIES.FILES:
        return <FileDrawer toggleDrawer={toggleDrawer} />
      case DRAWER_ENTITIES.TENANT_FORM:
        return <TenantForm toggleDrawer={toggleDrawer} formId={drawer.entityId || ''} />
      case DRAWER_ENTITIES.USER:
        if (drawer.entityId || drawer.tenantId) {
          return (
            <UserEdit
              key={drawer.entityId || drawer.tenantId}
              toggleDrawer={toggleDrawer}
              userId={drawer.entityId}
              tenantId={drawer.tenantId}
            />
          );
        }
    }
    return null;
  }

  return (
    <Drawer
      open={drawer.isOpen}
      onClose={toggleDrawer}
      width={drawer.width}
      preventCloseOnBackdropPress={drawer.entity === DRAWER_ENTITIES.USER}
      animationTime={250}
      scrimStyles={{ position: 'absolute' }}
      style={styles.mainWrapper}
      orientation={drawer.orientation}
      drawerContent={getDrawerContent()}
    >
      {props.children}
    </Drawer>
  );
}

RightSideBar.displayName = 'RightSideBar';

export default RightSideBar;
