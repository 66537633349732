import React from 'react';
import { DNABox, DNAButton, DNAChip, DNADivider, DNAIcon, DNAText, Iffy } from '@alucio/lux-ui';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import { FeatureFlags, isDocumentVersionORM, isFolderItemORM, isPageGroupORM } from 'src/types/types';
import { FileType, Sentiment } from '@alucio/aws-beacon-amplify/src/models';
import DNAActionBar from 'src/components/DNA/ActionBar/DNAActionBar';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { ImageBackground, StyleSheet } from 'react-native';
import SlideRollWrapper from '../../SharedComponents/SlideRollWrapper';
import PresentationNav from '../../SharedComponents/PresentationNav';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import PlayerWrapper from 'src/components/Presentation/PlayerWrapper'
import TextSearchPanel from 'src/components/TextSearchPanel/TextSearchPanel';
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList';
import { useHighlighter } from 'src/components/Highlighter/HighlighterProvider';
import Notes from '../../SharedComponents/Notes';

/** ANIMATION IMPORTS */
import LottieAnimation from 'react-lottie-player'
import useFeatureFlag from 'src/hooks/useFeatureFlag/useFeatureFlag';
import { ActionBarState } from '../../SharedComponents/PresentationMenu';
import { CanvasVariantEnum } from 'src/components/Highlighter/UseCanvas';
const PopupHiddenAnimation = require('src/assets/lottie/shared-window-visibility-animation.json')

const styles = StyleSheet.create({
  backStyle: {
    marginLeft: 4,
  },
  iframeContainer: {
    borderColor: colors['color-success-500'],
    borderWidth: 4,
  },
  slideRollContainer: {
    borderRightWidth: 1,
    borderColor: '#383838',
  },
});

const Presenting = () => {
  const { activePresentation } = useContent()
  const {
    setCurrentTab,
    meetingORM,
    previousTab,
    slideRollVisible,
    toggleSlideRollVisibility,
    textSearchVisible,
    setTextSearchVisible,
    toggleTextSearchVisibility,
    popoutContentHidden,
    openNotes,
    toggleMyNotes,
    toggleSpeakerNotes,
    updateReaction,
    updateFollowUp,
    presentedMeta,
    actionBarState,
    setPresentationMenu,
  } = useMeetingsState();
  const areMeetingNotesEnabled = useFeatureFlag(FeatureFlags.BEAC_4227_meeting_slide_notes);
  const areReactionsEnabled = useFeatureFlag(FeatureFlags.BEAC_4227_meeting_slide_reactions);
  const isFollowUpEnabled = useFeatureFlag(FeatureFlags.BEAC_4227_meeting_slide_follow_up);

  const {
    setHighlighterMode,
    selectedType,
    setHighlighterVisible,
  } = useHighlighter();

  if (!meetingORM) return null

  let orm;
  if (isDocumentVersionORM(activePresentation?.presentable.orm)) {
    orm = activePresentation?.presentable.orm
  } else if (isFolderItemORM(activePresentation?.presentable.orm)) {
    orm = activePresentation?.presentable.orm.relations.itemORM
  }
  const isWebDoc = orm?.model.type === FileType.WEB
  const isVideoDoc = orm?.model.type === FileType.MP4
  const isHTMLDoc = orm?.model.type === FileType.HTML
  const disablePresentationNav = isWebDoc || isVideoDoc || isHTMLDoc

  const handleCollapse = () => {
    setCurrentTab(previousTab.current)
  }

  const renderActivePresentationInfo = () => {
    if (!activePresentation) return null

    const { orm, title } = activePresentation.presentable

    const isDocumentVersion =
      !isPageGroupORM(orm) &&
      (isDocumentVersionORM(orm) ||
        isDocumentVersionORM(orm.relations.itemORM));
    const isModifiedDocumentVersion =
      isFolderItemORM(orm) &&
      isDocumentVersion &&
      orm.model.visiblePages?.length;
    const showModifiedBadge =
      isModifiedDocumentVersion ||
      !isDocumentVersion;

    return (
      <DNABox fill>
        <DNABox style={{ marginRight: 12 }}>
          { /* DOCUMENT VERSION BADGE */}
          {isDocumentVersion &&
            <CustomFieldBadgeList documentVersionORM={activePresentation.currentPresentablePage.documentVersionORM} />
          }

          { /* MODIFIED BADGE */}
          {showModifiedBadge &&
            <DNAChip appearance="tag">
              MODIFIED
            </DNAChip>
          }

        </DNABox>
        <DNABox fill style={{ marginRight: 12 }}>
          <DNAText numberOfLines={1} status="basic">
            {title}
          </DNAText>
        </DNABox>
      </DNABox>
    )
  }

  const actionBarItemDefaults: DNAButtonProps = {
    appearance: 'filled',
    status: 'dark',
    size: 'xs',
  }

  return (
    <DNABox appearance="col" fill>

      {/* HEADER */}
      <DNABox fill alignY="center" alignX="end" style={{ padding: 16, maxHeight: 56 }}>
        {renderActivePresentationInfo()}
        <DNAButton
          status="dark"
          appearance="filled"
          iconLeft="arrow-collapse"
          testID="collapse-button"
          onPress={handleCollapse}
        >
          Collapse
        </DNAButton>
      </DNABox>
      <DNADivider variant="virtual" />

      {/* CONTENT */}
      <DNABox testID="presentation-content" fill>

        {textSearchVisible &&
          <TextSearchPanel
            onClose={() => { setTextSearchVisible(false) }}
            contentHookVariant="proxied"
          />
        }

        {/* SLIDE ROLL */}
        <Iffy is={activePresentation}>

          <DNABox
            style={[styles.slideRollContainer, !slideRollVisible && { width: 0, height: 0, display: 'none' }]}
          >
            <SlideRollWrapper closeButtonOnPress={toggleSlideRollVisibility} />
          </DNABox>

        </Iffy>
        <DNABox appearance="col" fill>

          {/* PRESENTATION */}

          <DNABox style={styles.iframeContainer} fill>

            {/* IFRAME */}
            {activePresentation
              ? <PlayerWrapper.Highlighter
                  isFullWindow={true}
                  meetingId={meetingORM.model.id}
                  mode="INTERACTIVE"
                  isFromMeetings={true}
                  highlighterVisible
              />
              : <ImageBackground
                  source={require('../../../../../assets/images/beacon-presentation-bg.jpg')}
                  style={{ flex: 1 }}
              />
            }

            {/* Content not visible overlay */}

            {/* NOTE: Can't use stack here because the playerwrapper esplodes 🤯 */}
            {popoutContentHidden &&
              <DNABox
                fill
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                }}
                alignX="center"
                alignY="center"
                appearance="col"
                spacing="lg"
              >
                <DNAText h1 bold status="basic">Adjust windows so shared window is visible</DNAText>
                <LottieAnimation
                  play
                  animationData={PopupHiddenAnimation}
                />
              </DNABox>}
          </DNABox>

          {/* web doc will not have the actionBar, but video for now will have the highlighter */}
          <Iffy is={activePresentation && !isWebDoc && actionBarState === ActionBarState.PresentationMenu}>

            {/* ACTION BAR */}
            <DNABox alignY="center" alignX="center" spacing="sm" style={{ padding: 12 }}>
              {/* SLIDE NAVIGATION */}
              {/* << >> prev and next pages are not able for web and video docs */}
              {!disablePresentationNav && <PresentationNav />}
              <DNAActionBar
                spacing="sm"
                itemDefaults={actionBarItemDefaults}
              >

                {/* Slideroll button */}
                <DNAActionBar.Item
                  hidden={isVideoDoc}
                  onPress={toggleSlideRollVisibility}
                  key="Slides"
                  testID="toggle-slides-button"
                >
                  <DNAText status="basic">Slides</DNAText>
                </DNAActionBar.Item>

                {/* Speakernotes button */}
                <DNAActionBar.Item
                  onPress={toggleSpeakerNotes}
                  key="Speaker Notes"
                  testID="toggle-speaker-notes-button"
                >
                  <DNAText status="basic">Speaker Notes</DNAText>
                </DNAActionBar.Item>

                {/* Highlight Button */}
                <DNAActionBar.Item
                  onPress={() => setPresentationMenu(ActionBarState.HighlighterMenu)}
                  key="Marker"
                  testID="toggle-marker-button"
                  status={actionBarItemDefaults.status}
                  _popoverProps={{
                    type: 'tooltip',
                    placement: 'top',
                  }}
                >
                  <DNAIcon.Styled
                    name="marker"
                  />
                  <DNAActionBar.Item.PopoverContent>
                    <DNABox>
                      <DNAText status="basic">Highlighter</DNAText>
                    </DNABox>
                  </DNAActionBar.Item.PopoverContent>
                </DNAActionBar.Item>
                {/* Text Search Button */}
                <DNAActionBar.Item
                  hidden={isVideoDoc}
                  onPress={toggleTextSearchVisibility}
                  key="Text Search"
                  testID="toggle-text-search"
                  _popoverProps={{
                    type: 'tooltip',
                    placement: 'top',
                  }}
                >
                  <DNAIcon.Styled
                    name="magnify"
                  />
                  <DNAActionBar.Item.PopoverContent>
                    <DNABox>
                      <DNAText status="basic">Search</DNAText>
                    </DNABox>
                  </DNAActionBar.Item.PopoverContent>
                </DNAActionBar.Item>
                {/* Positive Sentiment Button */}
                <DNAActionBar.Item
                  hidden={!areReactionsEnabled}
                  onPress={() => updateReaction(Sentiment.POSITIVE)}
                  key="Positive sentiment"
                  _popoverProps={{
                    type: 'tooltip',
                    placement: 'top',
                  }}
                >
                  <DNAIcon.Styled
                    appearance={presentedMeta?.sentiment === Sentiment.POSITIVE ? 'ghostLink' : undefined}
                    status={presentedMeta?.sentiment === Sentiment.POSITIVE ? 'success' : undefined }
                    name="chevron-up"
                  />
                  <DNAActionBar.Item.PopoverContent>
                    <DNABox>
                      <DNAText status="basic">Positive reaction</DNAText>
                    </DNABox>
                  </DNAActionBar.Item.PopoverContent>
                </DNAActionBar.Item>
                {/* Negative Sentiment Button */}
                <DNAActionBar.Item
                  hidden={!areReactionsEnabled}
                  onPress={() => updateReaction(Sentiment.NEGATIVE)}
                  key="Negative sentiment"
                  _popoverProps={{
                    type: 'tooltip',
                    placement: 'top',
                  }}
                >
                  <DNAIcon.Styled
                    appearance={presentedMeta?.sentiment === Sentiment.NEGATIVE ? 'ghostLink' : undefined}
                    status={presentedMeta?.sentiment === Sentiment.NEGATIVE ? 'warning' : undefined}
                    name="chevron-down"
                  />
                  <DNAActionBar.Item.PopoverContent>
                    <DNABox>
                      <DNAText status="basic">Negative reaction</DNAText>
                    </DNABox>
                  </DNAActionBar.Item.PopoverContent>
                </DNAActionBar.Item>
                {/* My Notes Button */}
                <DNAActionBar.Item
                  hidden={!areMeetingNotesEnabled}
                  onPress={toggleMyNotes}
                  key="My Notes"
                  testID="toggle-my-notes-button"
                  _popoverProps={{
                    type: 'tooltip',
                    placement: 'top',
                  }}
                >
                  <DNAIcon.Styled name="playlist-edit" />
                  <DNAActionBar.Item.PopoverContent>
                    <DNABox>
                      <DNAText status="basic">My Notes</DNAText>
                    </DNABox>
                  </DNAActionBar.Item.PopoverContent>
                </DNAActionBar.Item>
                {/* Follow-up Button */}
                <DNAActionBar.Item
                  hidden={!isFollowUpEnabled}
                  onPress={() => updateFollowUp()}
                  key="Flag for follow-up"
                  _popoverProps={{
                    type: 'tooltip',
                    placement: 'top',
                  }}
                >
                  <DNAIcon.Styled name={presentedMeta?.followUp ? 'flag' : 'flag-outline'} />
                  <DNAActionBar.Item.PopoverContent>
                    <DNABox>
                      <DNAText status="basic">Flag for follow-up</DNAText>
                    </DNABox>
                  </DNAActionBar.Item.PopoverContent>
                </DNAActionBar.Item>

              </DNAActionBar>
            </DNABox>
          </Iffy>

          {/* Highligther Bar */}
          <Iffy is={actionBarState === ActionBarState.HighlighterMenu}>
            <DNABox alignY="center" alignX="center" spacing="sm" style={{ padding: 12 }}>
              <DNAActionBar
                spacing="sm"
                itemDefaults={actionBarItemDefaults}
              >
                <DNAActionBar.Item
                  onPress={() => {
                    setPresentationMenu(ActionBarState.PresentationMenu)
                    setHighlighterVisible(false)
                  }}
                  testID="back-button"
                >
                  <DNAIcon.Styled
                    name="arrow-left-bold"
                  />
                  <DNABox style={styles.backStyle}>
                    <DNAText status="basic">Back</DNAText>
                  </DNABox>
                </DNAActionBar.Item>
                <DNAActionBar.Item
                  onPress={() => setHighlighterMode(CanvasVariantEnum.arrow)}
                  testID="arrow-top-right"
                  status={selectedType === CanvasVariantEnum.arrow ? 'info' : 'dark'}
                >
                  <DNAIcon.Styled
                    name="arrow-top-right"
                  />
                  <DNABox style={{ marginLeft: 4 }}>
                    <DNAText status="basic">Arrow</DNAText>
                  </DNABox>
                </DNAActionBar.Item>
                <DNAActionBar.Item
                  onPress={() => setHighlighterMode(CanvasVariantEnum.square)}
                  testID="square"
                  status={selectedType === CanvasVariantEnum.square ? 'info' : 'dark'}
                >
                  <DNAIcon.Styled
                    name="checkbox-blank"
                  />
                  <DNABox style={{ marginLeft: 4 }}>
                    <DNAText status="basic">Box highlighter</DNAText>
                  </DNABox>
                </DNAActionBar.Item>
              </DNAActionBar>
            </DNABox>
          </Iffy>
        </DNABox>

        {/* SPEAKER/MY NOTES */}
        <Iffy is={openNotes.length}>
          <Notes
            openNotes={openNotes}
            toggleMyNotes={toggleMyNotes}
            toggleSpeakerNotes={toggleSpeakerNotes}
          />
        </Iffy>
      </DNABox>
    </DNABox>
  );
};

Presenting.displayName = 'Presenting';

export default Presenting;
