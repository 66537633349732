import React, { createContext, useContext, useState, useMemo } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import { State, Sender } from 'xstate'
import { useActor } from '@xstate/react'

import { Page, DocumentStatus, ConversionStatus, FileType } from '@alucio/aws-beacon-amplify/src/models'

import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { DNABox, DNAButton, DNAText, DNAContextMenu, Icon, luxColors, Iffy } from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'

import {
  SlideSettingsContext,
  SlideSettingsEvents,
  SlideSettingsState,
} from 'src/state/machines/versioning/SlideSettings/slideSettingsTypes'

import PublisherAssociatedSlidesModal from './PublisherAssociatedSlidesModal'
import useThumbnailSize from 'src/hooks/useThumbnailSize/useThumbnailSize'
import { DocumentVersionORM } from 'src/types/orms'
import { useDispatch } from 'src/state/redux';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail'
import AssociatedSlidesThumbnails from 'src/components/DNA/Thumbnail/AssociatedSlidesThumbnails'

import CoverThumbnailModal from './CoverThumbnail'
import RequiredSlidesModal from './RequiredSlides'
import RemoveAssociatedSlidesModal from './RemovedAssociatedSlides'
import { useVersioningPanel } from '../VersioningPanelContext'
import GroupsModal from './Grouping/Groups'
import ReadOnlyGroups from './Grouping/ReadOnlyGroups'
import DNAUpdateSlideData from 'src/components/DNA/Modal/DNAUpdateSlideData'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import { getSlideContentPageDataTitle, hasSpeakerNotes } from 'src/hooks/useContentPageData/useContentPageData'
import DNAConditionalButton from 'src/components/DNA/ConditionalButton/DNAConditionalButton'
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton'
import { useForm } from 'react-hook-form'
import DNACommonConfirmation from 'src/components/DNA/Modal/DNACommonConfirmation'

export const styles = StyleSheet.create({
  actionBar: {
    paddingHorizontal: 24,
    paddingVertical: 16,
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
  },
  headerBar: {
    padding: 16,
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
  },
  helpToolTipIconStyle: {
    color: luxColors.contentText.quaternary,
    height: 20,
    paddingLeft: 5,
    width: 20,
  },
  content: {
    paddingHorizontal: 32,
    paddingVertical: 32,
  },
  rowWrapper: {
    height: 50,
    paddingHorizontal: 22,
    paddingVertical: 12,
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
  },
  verticalDivider: {
    backgroundColor: luxColors.headerTopColor.primary,
    height: 20,
    width: 1,
  },
  attachmentSlides: {
    backgroundColor: colors['color-gray-10'],
    borderRightColor: colors['color-gray-80'],
    borderRightWidth: 1,
    width: 412,
  },
  attachmentSlidesHeader: {
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
    height: 50,
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  attachmentSlidesContent: {
    padding: 35,
  },
  chevronIcon: {
    color: colors['color-gray-900'],
    height: 16,
  },
});

export type SlideSettingsStateMachineContext = {
  state: State<SlideSettingsContext, SlideSettingsEvents, any, SlideSettingsState>,
  send: Sender<SlideSettingsEvents>,
  cond: {
    associatedSlidesMode: boolean,
    isIdle: boolean,
    mode: {
      associatedSlides: {
        add: boolean,
        addStepOne: boolean,
        addStepTwo: boolean,
        remove: boolean,
      },
      coverThumbnail: boolean,
      requiredSlides: boolean,
      isVersionPublishedOrSealed: boolean,
      groupSlides: boolean,
    },
  },
  thumbnailSizes: ReturnType<typeof useThumbnailSize>,
  documentVersionORM: DocumentVersionORM,
  pageMap: Record<string, Page>
  pages: Page[],
  groupingDisabled: boolean,
  requiredSlidesDisabled: boolean,
  getSlideTitle: (pageNumber: number) => string,
}

const SlideSettingsStateMachineContext = createContext<SlideSettingsStateMachineContext>({
  state: undefined!,
  send: undefined!,
  cond: undefined!,
  thumbnailSizes: undefined!,
  documentVersionORM: undefined!,
  pageMap: undefined!,
  pages: undefined!,
  groupingDisabled: false,
  requiredSlidesDisabled: false,
  getSlideTitle: undefined!,
})

export const useSlideSettings = () => useContext(SlideSettingsStateMachineContext)

export const getThumbURL = (ORM: DocumentVersionORM, pageNumber: number) => {
  return ORM.meta
    .assets
    .thumbnailKey
    ?.split('/')
    .slice(0, -1)
    .join('/') + `/${pageNumber}_medium_thumb.png` ?? ''
}

const buttonDefaults:DNAButtonProps = {
  appearance:'outline',
  status:'tertiary',
  padding:'xs',
}

const SlideSettingsActionbar: React.FC = () => {
  const {
    send,
    thumbnailSizes,
    documentVersionORM,
    pages,
    requiredSlidesDisabled,
    groupingDisabled,
  } = useSlideSettings()

  const form = useForm({ mode:'onSubmit' })
  const { contentPageData, send: sendVersioningPanel } = useVersioningPanel()
  const dispatch = useDispatch()

  const hasAssociatedSlides = pages.some(page => page.linkedSlides?.length)
  const hasSlideTitlesToClear = !!contentPageData?.some(({ title }) => title !== '' )

  const handleClearAllSlideTitles = form.handleSubmit(() => {
    contentPageData?.forEach(page => (page.title = ''))
    sendVersioningPanel({ type: 'SET_IS_DIRTY', payload: { type: 'slidesData', isDirty: true } })
  })

  const handleLaunchClearAllSlideTitlesModal = () => contentPageData && dispatch(DNAModalActions.setModal({
    isVisible: true,
    allowBackdropCancel: false,
    component: (props) =>
      (<DNACommonConfirmation
        { ...props }
        onConfirmAction={handleClearAllSlideTitles}
        confirmActionText="Clear"
        title="Clear all slide titles?"
        cancelText="Cancel"
      />),
  }))

  return (
    <DNABox spacing="between" style={styles.actionBar}>
      {/* left side */}
      <DNABox spacing="md" alignY="center">
        <DNAText>{documentVersionORM.meta.allPages.length} slide(s)</DNAText>
        <DNAButton
          {...buttonDefaults}
          onPress={() => {
            send({ type: 'SET_COVER_THUMBNAIL' })
          }}
          style={{ backgroundColor: 'white' }}
        >
          Set cover thumbnail
        </DNAButton>
        <DNAConditionalButton
          {...buttonDefaults}
          onPress={handleLaunchClearAllSlideTitlesModal}
          // onPress={handleClearAllSlideTitles}
        >
          <DNAConditionalButton.Disable condition={!hasSlideTitlesToClear}>
            <DNAText status="basic">No slide titles to clear</DNAText>
          </DNAConditionalButton.Disable>
          Clear all slide titles
        </DNAConditionalButton>
      </DNABox>
      {/* right side */}
      <DNABox spacing="md">
        <DNAPopover >
          <DNAPopover.Anchor>
            <Icon
              style={styles.helpToolTipIconStyle}
              name="help-circle-outline"
            />
          </DNAPopover.Anchor>
          <DNAPopover.Content>
            <DNAText
              style={{ color: colors['color-text-white'], marginHorizontal: 12, width: 380 }}
            >
              These settings apply when this document is used within a custom presentation.
            </DNAText>
          </DNAPopover.Content>
        </DNAPopover>
        <DNAText>Custom Presentation Settings:</DNAText>
        <DNABox spacing="sm" alignY="center">
          <DNAContextMenu>
            <DNAContextMenu.Anchor>
              {requiredSlidesDisabled
                ? <DNAPopover>
                  <DNAPopover.Anchor>
                    <DNAButton
                      {...buttonDefaults}
                      disabled
                      iconRight="chevron-down"
                      style={{ backgroundColor: 'white' }}
                      testID="edit-required-slides-dropdown"
                    >
                      Edit required slides
                    </DNAButton>
                  </DNAPopover.Anchor>
                  <DNAPopover.Content>
                    <DNAText status="basic">
                      Please remove slide groups to proceed.
                    </DNAText>
                  </DNAPopover.Content>
                </DNAPopover>
                : <DNAButton
                    {...buttonDefaults}
                    disabled={requiredSlidesDisabled}
                    iconRight="chevron-down"
                    style={{ backgroundColor: 'white' }}
                    testID="edit-required-slides-dropdown"
                >
                  Edit required slides
                </DNAButton>}
            </DNAContextMenu.Anchor>
            <DNAContextMenu.Items>
              <DNAContextMenu.Item
                key="For-presentation"
                collapseOnPress
                delay={100}
                title="For presentation"
                onPress={() => {
                  send({ type: 'EDIT_REQUIRED_SLIDES' })
                }}
              />
              <DNAContextMenu.Item
                key="Add-to-individual-slides"
                collapseOnPress
                delay={100}
                title="Add to individual slides"
                onPress={() => {
                  send({ type: 'ADD_ASSOCIATED_SLIDES' })
                }}
              />
              { hasAssociatedSlides &&
                <DNAContextMenu.Item
                  key="Remove-from-individual-slides"
                  collapseOnPress
                  delay={100}
                  title="Remove from individual slides"
                  onPress={() => {
                    send({ type: 'REMOVE_ASSOCIATED_SLIDES' })
                  }}
                />
            }
            </DNAContextMenu.Items>
          </DNAContextMenu>
          <DNAText>or</DNAText>
          {groupingDisabled
            ? <DNAPopover>
              <DNAPopover.Anchor>
                <DNAButton
                  {...buttonDefaults}
                  disabled
                  style={{ backgroundColor: 'white' }}
                  testID="edit-slide-groups-btn"
                >
                  Edit slide groups
                </DNAButton>
              </DNAPopover.Anchor>
              <DNAPopover.Content>
                <DNAText status="basic">
                  Please remove required slides to proceed.
                </DNAText>
              </DNAPopover.Content>
            </DNAPopover>
            : <DNAButton
                {...buttonDefaults}
                onPress={() => {
                  send({ type: 'EDIT_GROUP_SLIDES' })
                }}
                style={{ backgroundColor: 'white' }}
                testID="edit-slide-groups-btn"
            >
              Edit slide groups
            </DNAButton>
          }
        </DNABox>
        <DNAButton
          {...buttonDefaults}
          style={{ height: 24, width: 24 }}
          onPress={thumbnailSizes.cycleThumbnailSize}
          iconLeft={thumbnailSizes.thumbnailSize === 'lg' ? 'view-comfy' : 'view-grid'}
        />
      </DNABox>
    </DNABox>
  )
}

const SlideSettings: React.FC = () => {
  // [TODO] - Consider adding Xstate selectors to only subscribe to actor context (instead of entire machine)
  // [NOTE] - Hardcoded usage -- extract machine/actor wrapper provider component if need more flexibility
  const {
    state: parentState, currentDocumentVersionORM, contentPageData,
    send: sendVersioningPanel,
    cond: versionPanelCond,
  } = useVersioningPanel()
  const [state, send] = useActor(parentState.context.slideSettingsActor!)
  const thumbnailSizes = useThumbnailSize(['lg', 'xl', 'xxl'], 'lg')

  const getSlideTitle = (pageNumber?: number) : string => {
    if (!pageNumber || !contentPageData || contentPageData.length === 0) return ''
    return getSlideContentPageDataTitle(pageNumber - 1,
      contentPageData, currentDocumentVersionORM.model.type === FileType.PPTX)
  }

  const pageMap = useMemo(
    () => currentDocumentVersionORM
      .meta
      .allPages
      .reduce<Record<string, Page>>(
        (acc, page) => {
          acc[page.pageId] = page
          return acc
        },
        {},
      ),
    [currentDocumentVersionORM.model],
  )
  const dispatch = useDispatch()

  const isVersionPublished = currentDocumentVersionORM
    .model
    .status === DocumentStatus.PUBLISHED

  const isDocSealed = ['REVOKED', 'ARCHIVED', 'DELETED'].includes(
    currentDocumentVersionORM
      .relations
      .documentORM
      .model
      .status,
  )
  const isVersionPublishedOrSealed = (isVersionPublished || isDocSealed)

  const hasGroupings = isVersionPublishedOrSealed
    ? !!currentDocumentVersionORM.model.pageGroups?.length
    : !!state.context.versionDraft.pageGroups?.length

  const hasRequiredSlides = isVersionPublishedOrSealed
    ? currentDocumentVersionORM.meta.allPages.some(page => page.isRequired || page.linkedSlides?.length)
    : state.context.versionDraft.pages.some(page => page.isRequired || page.linkedSlides?.length)

  const pages = isVersionPublishedOrSealed
    ? currentDocumentVersionORM.meta.allPages
    : state.context.versionDraft.pages

  const cond = {
    associatedSlidesMode: state.tags.has('SLIDES_SETTING_MODAL'),

    // Current Modes
    isIdle: state.matches('idle'),
    mode: {
      associatedSlides: {
        add: state.matches('addAssociatedSlides'),
        addStepOne: state.matches('addAssociatedSlides.stepOne'),
        addStepTwo: state.matches('addAssociatedSlides.stepTwo'),
        remove: state.matches('removeAssociatedSlides'),
      },
      coverThumbnail: state.matches('setCoverThumbnail'),
      requiredSlides: state.matches('editRequiredSlides'),
      isVersionPublishedOrSealed,
      groupSlides: state.matches('editGroupSlides'),
    },
  }

  // [SESSION] - Don't need to hold local state for this, use State Machine cond instead
  const [isAssociatedSlidesModalVisible, setIsAssociatedSlidesModalVisible] = useState<boolean>(false)

  const value = {
    state,
    send,
    cond,
    isAssociatedSlidesModalVisible,
    // toggleAssociatedSlidesModal: () => setIsAssociatedSlidesModalVisible(p => !p),
    setIsAssociatedSlidesModalVisible,
    thumbnailSizes,
    documentVersionORM: currentDocumentVersionORM,
    pageMap,
    pages,
    // per design v1 if the doc has required slides, disable the groupings
    groupingDisabled: hasRequiredSlides,
    // per design v1 if the doc has groupings, disable the required slides
    requiredSlidesDisabled: hasGroupings,
    getSlideTitle,
  }

  // [TODO-ASSOCIATED] - Consider calculating the cover thumbnail index on the meta.assets.coverThumbnailPage
  const coverThumbnailPageNumber = state.context.versionDraft.selectedThumbnail ?? 1

  const onEditPageData = (pageNumber?: number, thumbURL?: string ) => {
    if (!contentPageData || !pageNumber) return
    const pageData = contentPageData[pageNumber - 1]

    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: false,
      component: (props) =>
        (<DNAUpdateSlideData
          { ...props }
          pageData={pageData}
          pageNumber={pageNumber}
          thumbURL={thumbURL}
          send={sendVersioningPanel}
        />),
    }));
  }

  return (
    <SlideSettingsStateMachineContext.Provider
      value={value}
    >
      <DNABox
        fill
        appearance="col"
      >
        {/* actionbar */}
        <Iffy
          is={
          currentDocumentVersionORM.model.status === DocumentStatus.NOT_PUBLISHED &&
          !versionPanelCond.isNonDocumentFile
          }
        >
          <SlideSettingsActionbar />
        </Iffy>
        {/* content */}
        <ScrollView style={styles.content}>
          {/* Existing groups */}
          <ReadOnlyGroups />
          {/* IDLE MODE */}
          <DNABox
            appearance="row"
            wrap="start"
            style={{ marginTop: 35 }}
            // [TODO-ASSOCIATED] - Consider adding marginBottom in DNABox when using spacing
            childStyle={{ marginBottom: 32 }}
            alignX="center"
          >
            {
              pages?.map((page, _idx) => {
                const thumbURL = versionPanelCond.isNonDocumentFile
                  ? currentDocumentVersionORM.meta.assets.thumbnailKey?.replace('small', 'medium')
                  : getThumbURL(currentDocumentVersionORM, page.number)
                const associatedPages = page.linkedSlides?.map(linkedPageId => pageMap[linkedPageId]) ?? []
                const showEditButton = currentDocumentVersionORM.model.status === DocumentStatus.NOT_PUBLISHED &&
                currentDocumentVersionORM.model.conversionStatus === ConversionStatus.PROCESSED
                const showSpeakerNotesButton = hasSpeakerNotes(page.number - 1, contentPageData ) && showEditButton
                // [TODO-ASSOCIATED] - Make a generic context to synchronize thumbnail loading
                //                   - Add support for generic interactive/badge rendering
                //                   - Add variant supports for different styling types
                return (
                  <DNABox
                    key={page.pageId}
                    appearance="col"
                    spacing="sm"
                    childFill={1}
                    style={{ marginRight: 12, marginBottom: 8 }} // this is to replace the spacing on the parent element, because spacing does not apply on the last item
                  >
                    {/* [TODO-ASSOCIATED] - Figure out a better way to avoid shifting scrollbar */}
                    <DNABox style={{ marginRight: 12 }}>
                      <DNAThumbnail
                        variant={DNAThumbnail.Variants.INFO}
                        key={thumbURL}
                        s3URL={thumbURL}
                        useLoadingIndicator
                        thumbnailTitle={getSlideTitle(page.number)}
                        size={
                          versionPanelCond.isNonDocumentFile
                            ? 'xl'
                            : value.thumbnailSizes.thumbnailSize
                        }
                        pageNumber={page.number}
                        isCover={versionPanelCond.isNonDocumentFile ? true : page.number === coverThumbnailPageNumber}
                        isRequired={versionPanelCond.isNonDocumentFile ? true : page.isRequired}
                        onEditPageData={onEditPageData}
                        showEditButton={showEditButton}
                        showSpeakerNotesButton={showSpeakerNotesButton}
                      />
                    </DNABox>

                    <AssociatedSlidesThumbnails
                      docVerORM={currentDocumentVersionORM}
                      pages={associatedPages}
                      size={thumbnailSizes.thumbnailSize}
                      getSlideTitle={getSlideTitle}
                    />
                  </DNABox>
                )
              })
            }
          </DNABox>

          {/* MODES */}
          <PublisherAssociatedSlidesModal/>
          <CoverThumbnailModal/>
          <RequiredSlidesModal/>
          <RemoveAssociatedSlidesModal/>
          <GroupsModal />

        </ScrollView>
      </DNABox>
    </SlideSettingsStateMachineContext.Provider>
  )
}

export default SlideSettings
