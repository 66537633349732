import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { luxColors, Iffy, MenuItem, Hoverable, DNAText, DNADivider } from '@alucio/lux-ui';
import { DocumentORM } from 'src/types/types';
import { useTenantCustomFields } from 'src/state/redux/selector/tenant'
import { CustomFieldUsage } from '@alucio/aws-beacon-amplify/src/models';
import { useDispatch } from 'src/state/redux';
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { filters } from 'src/state/redux/document/query';

import { useDocumentSearchV2, useDocumentSearchV2Context } from 'src/hooks/useDocumentSearchV2.proxy'
import { SearchResultRow } from 'src/components/DNA/GridList/DNADocumentGridList';
import ListSkeleton from 'src/components/ListSkeleton/ListSkeleton';

interface Props {
  moveToResultsPage?: () => void;
  searchText: string;
  onSelectItem: (selectedDocument: DocumentORM) => void
}

const styles = StyleSheet.create({
  badgeWrapper: {
    marginLeft: 4,
    marginRight: 16,
  },
  boldedText: {
    color: luxColors.contentText.tertiary,
    fontSize: 12,
  },
  customFieldsText: {
    color: luxColors.subtitle.quinary,
    fontSize: 12,
    lineHeight: 20,
  },
  hoveredBackgroundColor: {
    backgroundColor: luxColors.hoveredBackground.primary,
  },
  mobileGridViewHeader: {
    backgroundColor: colors['color-gray-10'],
    paddingHorizontal: 16,
    paddingVertical: 5,
  },
  noResults: {
    marginLeft: 4,
    marginBottom: 6,
    marginTop: 6,
  },
  recordRow: {
    flex: 1,
    flexDirection: 'row',
    height: 52,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
  },
  resultsWrapperStyle: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  selectedFilters: {
    backgroundColor: colors['color-brand2-500'],
    borderRadius: 3,
    left: 20,
    paddingHorizontal: 4,
    paddingVertical: 2,
    position: 'absolute',
    top: 0,
  },
  titleText: {
    color: luxColors.contentText.tertiary,
    fontSize: 14,
    lineHeight: 16,
  },
  viewAllWrapper: {
    borderTopColor: luxColors.disabled.quaternary,
    borderTopWidth: 1,
    justifyContent: 'center',
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: 16,
    backgroundColor: 'white',
  },
  thumbnailSkeleton: {
    width: 120,
    height: 65,
    borderRadius: 4,
  },
  skeletonTextWrapper: {
    flexGrow: 1,
    marginLeft: 16,
  },
  skeletonMainText: {
    height: 14,
  },
  skeletonSubText: {
    height: 14,
    width: 'calc(100% - 40px)',
  },
});

// RETURNS THE TEXT FIELD AS AN ARRAY OF STRING SEPARATED BY SPACES AND PUNCTUATION MARKS
function getTokenizedText(text: string): string[] {
  const tokens: string[] = [];
  let lastBreakPoint = 0;

  for (let i = 0; i < text.length; i++) {
    if (text[i].match(/[^\w\s]|_/g) || text[i] === ' ') {
      if (lastBreakPoint !== i) {
        tokens.push(text.substring(lastBreakPoint, i));
      }
      tokens.push(text.substring(i, i + 1));
      lastBreakPoint = i + 1;
    } else if (i === text.length - 1) {
      tokens.push(text.substring(lastBreakPoint, text.length));
    }
  }

  return tokens;
}
const useResultsBox = (props: Props) => {
  const { moveToResultsPage, searchText = '' } = props;
  const [hoveredViewAll, setHoveredViewAll] = useState<boolean>(false);
  const searchedText = useMemo(() => searchText.toLowerCase().trim(), [searchText]);
  const tokenizedSearchText = getTokenizedText(searchedText);
  // [TODO] We should debounce this
  // Consider checking out https://github.com/xnimorz/use-debounce
  const {
    documentORMSearchResults: resultSearch,
  } = useDocumentSearchV2(searchedText, filters.published);

  const tenantCustomFields = useTenantCustomFields({
    defaultSearchFilter: true,
    usages: { internalUsages: [CustomFieldUsage.DOCUMENT] },
  });

  const dispatch = useDispatch();

  // [TODO-2126] - Should handle offline cache fallback?
  function onSelectItem(selectedDocument: DocumentORM): void {
    dispatch(contentPreviewModalActions.setModalVisibility({
      isOpen: true,
      documentVersionId: (
        selectedDocument.relations.version.latestUsableDocumentVersionORM?.model.id
      ),
      content: selectedDocument.relations.version.latestUsableDocumentVersionORM,
    }));
  }

  return {
    onSelectItem,
    setHoveredViewAll,
    hoveredViewAll,
    resultSearch,
    tokenizedSearchText,
    tenantCustomFields,
    moveToResultsPage,
    searchText,
  }
}

const SearchInputResultsDesktop = (props: Props) => {
  const {
    onSelectItem,
    setHoveredViewAll,
    hoveredViewAll,
    resultSearch,
    moveToResultsPage,
  } = useResultsBox(props);
  const { resetSearch, submitFeedback, isLoadingSearch, status } = useDocumentSearchV2Context();

  function toggleViewAllHover(): void {
    setHoveredViewAll(!hoveredViewAll);
  }

  function selectItemHandler(selectedDocument: DocumentORM) {
    submitFeedback(selectedDocument.model.id, selectedDocument.relations.tenant.kendraIndexId)
    onSelectItem(selectedDocument);
    props.onSelectItem(selectedDocument)
  }

  useEffect(() => {
    return () => {
      resetSearch();
    }
  }, [])
  const renderSearchItem = ({ item: documentORM } : { item: DocumentORM}) => {
    return (
      <SearchResultRow
        documentORM={documentORM}
        isDropdown
        onPress={() => selectItemHandler(documentORM)}
      />
    )
  }

  const renderList = useCallback(() => {
    return (
      <View style={{ display: 'flex', flex: 1 }}>
        <FlatList
          data={resultSearch.slice(0, 5)}
          keyExtractor={(item) => item.model.id}
          renderItem={renderSearchItem}
          ItemSeparatorComponent={DNADivider}
        />
      </View>
    )
  }, [resultSearch])

  return (
    <React.Fragment>
      <Iffy is={status === 'RESULTS'}>
        <MenuItem
          style={styles.resultsWrapperStyle}
          title={renderList}
        />
      </Iffy>
      <Iffy is={status === 'RESULTS' && resultSearch.length >= 5}>
        <Hoverable onHoverIn={toggleViewAllHover} onHoverOut={toggleViewAllHover}>
          <MenuItem
            style={[styles.viewAllWrapper, hoveredViewAll && styles.hoveredBackgroundColor]}
            onPress={moveToResultsPage}
            title={() => <DNAText b2 status="info" style={styles.noResults}>View all</DNAText>}
          />
        </Hoverable>
      </Iffy>
      <Iffy is={status === 'EMPTY_RESULTS'}>
        <MenuItem
          disabled
          title={ () =>
            (<DNAText b2 style={styles.noResults} numberOfLines={1}>
              No items match your search. Please try a different search.
            </DNAText>)
          }
        />
      </Iffy>
      <Iffy is={isLoadingSearch}>
        <ListSkeleton />
      </Iffy>
    </React.Fragment>
  );
}

SearchInputResultsDesktop.displayName = 'SearchInputResults';

export default SearchInputResultsDesktop;
