import { DNAFlatListRows } from 'src/components/DNA/FlatList/Rows/types'

import DNADocumentCustomDeckRow from './DNADocumentCustomDeckRow'
import DNADocumentFolderItemCustomDeckRow from './DNADocumentFolderItemCustomDeckRow'
import DNAFolderVirtualRow from './DNADocumentVirtualCustomDeckRow'

import { DNADivider } from '@alucio/lux-ui';
import DNAFileDrawerDefaultRow from '../fileDrawer/DNAFileDrawerDefaultRow';

export const DNADefaultRows: DNAFlatListRows = {
  DNADocumentRow: DNADocumentCustomDeckRow,
  DNADocumentFolderItemRow: DNADocumentFolderItemCustomDeckRow,
  DNADocumentFolderItemCustomDeckRow: DNADocumentFolderItemCustomDeckRow,
  DNAFolderRow: DNAFolderVirtualRow,
  DNAListDivider: DNADivider,
  DNAFileDrawerRow: DNAFileDrawerDefaultRow,
}

export default DNADefaultRows
