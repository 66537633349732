import { ToDo, TodoStatus } from '@alucio/aws-beacon-amplify/src/models';
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { ModifiedHubSection } from 'src/state/context/Hubs/HubsStateProvider';
import { v4 as uuid } from 'uuid'
import { arrayMove } from '@dnd-kit/sortable';
import { EhitHubRhform } from '../../useHubForm';
import { useHubsState } from 'src/state/context/Hubs/HubsStateProvider.proxy';
import { useDispatch } from 'src/state/redux';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import DNACommonConfirmation from 'src/components/DNA/Modal/DNACommonConfirmation';
import { useFieldArray } from 'react-hook-form';

interface TodoContextProps {
  toDos: ToDo[];
  addTodo: () => void;
  onSaveTodo: (todoList?: ToDo[], isNewItem?: boolean) => void;
  rhform: EhitHubRhform;
  editingId?: string;
  setEditingId: (id?: string) => void;
  onCancelEdit: () => void;
  isNewItem: boolean;
  showAll: boolean;
  setShowAll: (showAll: boolean) => void;
  showCompleted: boolean;
  changeToDoStatus: (todoId: string, status: TodoStatus) => void;
  handleOnCompleted: (todoId: string, isCompleted: boolean) => void;
}

export const TodoContext = createContext<TodoContextProps | undefined>(undefined);

interface TodoProviderProps {
  children: ReactNode;
  rhform: EhitHubRhform;
  widget: ModifiedHubSection;
}

export const TodoProvider: React.FC<TodoProviderProps> = ({ children, rhform, widget }) => {
  const { setValue, getValues, formState, trigger } = rhform
  const [showCompleted, setShowCompleted] = useState<boolean>(false);
  const { setWidgetOptions } = useHubsState()
  const dispatch = useDispatch()
  const initialToDos = widget.toDos?.map((toDo, index) => ({
    ...toDo,
    order: toDo.order || index,
    todoId: toDo.todoId || uuid(),
    title: toDo.title || '',
    status: toDo.status || TodoStatus.ACTIVE,
  })) || []
  const [toDos, setToDos] = useState<ToDo[]>(initialToDos.sort((a, b) => a.order - b.order))
  const [isNewItem, setIsNewItem] = useState<boolean>(false);
  const [editingId, setEditingId] = useState<string | undefined>(undefined);
  const [showAll, setShowAll] = useState<boolean>(false);
  const { remove } = useFieldArray({
    control: rhform.control,
    name: 'todoListWidget.toDos',
  })

  useEffect(() => {
    setWidgetOptions(
      {
        [widget.type]:
          [{
            title: showCompleted ? 'Hide completed' : 'Show completed',
            onPress: () => setShowCompleted(!showCompleted),
            icon: 'eye-outline',
            status: 'basic',
            iconStatus: 'basic',
          }],
      })
  }, [showCompleted])

  const addTodo = () => {
    if (!editingId) {
      const newId = uuid()
      const newTodo = {
        todoId: newId,
        title: '',
        status: TodoStatus.ACTIVE,
        resolution: '',
        order: 0,
      };

      const arr = [newTodo, ...toDos].map((toDo, index) => { return { ...toDo, order: index } })
      setValue('todoListWidget.toDos', arr,
        { shouldDirty: true },
      )
      setToDos(arr || [])
      setIsNewItem(true)
      setEditingId(newId)
    }
  }

  const onSaveTodo = (todoList?: ToDo[]) => {
    const values = todoList || getValues('todoListWidget')?.toDos
    setValue('todoListWidget.toDos', values,
      { shouldDirty: true },
    )
    setToDos(values || [])
    trigger('todoListWidget.toDos')
    if (formState.isValid) {
      setEditingId(undefined)
      setIsNewItem(false)
    }
  }

  const handleOnCompleted = (todoId: string, isCompleted: boolean) => {
    if (!isCompleted) {
      changeToDoStatus(todoId, TodoStatus.ACTIVE)
      return
    }
    const onConfirm = () => {
      setEditingId(todoId)
    }

    const onSetAsCompleted = () => {
      setTimeout(() => {
        changeToDoStatus(todoId, TodoStatus.COMPLETED)
      }, 3000)
    }

    const toDo = toDos.find((toDo) => toDo.todoId === todoId)
    if (!toDo?.resolution) {
      dispatch(
        DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: true,
          component: () => (<DNACommonConfirmation
            cancelText="Mark as complete"
            confirmActionText="Add resolution"
            onConfirmAction={onConfirm}
            title="Add resolution?"
            onCancelCallback={onSetAsCompleted}
            cancelButtonProps={{
              status: 'primary',
              iconLeft: 'checkbox-marked-outline',
              appearance: 'outline',
            }}
            descriptionText={'Adding a resolution or description can help your audience better understand\n' +
              'this task and how it was solved.'}

          />),
        }));
    }
    else {
      onSetAsCompleted()
    }
  }

  const onCancelEdit = () => {
    if (isNewItem) {
      remove(toDos.findIndex((toDo) => toDo.todoId === editingId))
      const arr = toDos.filter((toDo) => toDo.todoId !== editingId)
      setIsNewItem(false)
      setEditingId(undefined)
      onSaveTodo(arr)
    }
    else {
      setIsNewItem(false)
      setEditingId(undefined)
      onSaveTodo()
    }
  }

  const changeToDoStatus = (todoId: string, status: TodoStatus) => {
    const newIndex = status === TodoStatus.COMPLETED
      ? toDos.findIndex((item) => item.status === TodoStatus.COMPLETED) - 1
      : status === TodoStatus.DELETED ? toDos.length - 1 : 0;
    const newToDos = [...toDos];
    const index = newToDos.findIndex((item) => item.todoId === todoId);
    newToDos[index] = {
      ...newToDos[index],
      status,
      completedAt: status === TodoStatus.COMPLETED ? new Date().toISOString() : undefined,
    };
    const arr = arrayMove(newToDos, index, newIndex < 0 ? 0 : newIndex);
    const newOrder = arr.map((item, index) => {
      return { ...item, order: index }
    })
    onSaveTodo(newOrder);
  };

  return (
    <TodoContext.Provider
      value={
        {
          toDos,
          addTodo,
          onSaveTodo,
          rhform,
          editingId,
          setEditingId,
          onCancelEdit,
          isNewItem,
          showAll,
          setShowAll,
          showCompleted,
          changeToDoStatus,
          handleOnCompleted,
        }}
    >
      {children}
    </TodoContext.Provider>
  );
};

export const useTodoState = () => {
  const context = useContext(TodoContext)
  if (!context) {
    throw new Error('useTodoState must be used within the TodoProvider')
  }
  return context;
}
