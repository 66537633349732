import React, { useEffect, useState } from 'react';
import { DNABox, DNAButton, DNADivider, DNASpinner, Iffy } from '@alucio/lux-ui';
import { ScrollView, StyleSheet } from 'react-native';
import {
  ComposableForm,
  ComposableVariant,
  useComposableForm,
} from 'src/components/CustomFields/ComposableForm';
import { ComposableField } from 'src/components/CustomFields/ComposableField';
import { ContentCard } from 'src/components/Meeting/AddMeeting';
import AttendeeInput from 'src/components/CustomFields/AttendeeInput';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import { MeetingAttendeeStatus }
  from '@alucio/aws-beacon-amplify/src/models';
import useMeetingFormConfig from 'src/hooks/useMeetingFormConfig/useMeetingFormConfig';
import { useSaveMeeting } from 'src/state/context/Meetings/saveMeetingHelper';
import { ColumnFieldsWrapper } from 'src/components/CustomFields/ColumnCustomFieldsWrapper';

const styles = StyleSheet.create({
  inputStyle: {
    backgroundColor: colors['color-gray-100'],
  },
  contentContainer: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 26,
    paddingBottom: 12,
  },
})

const AddMeetingFooter = () => {
  const { rhForm } = useComposableForm()
  const submitMeeting = useSaveMeeting();

  const {
    meetingORM,
    meetingForm,
    setCanEndMeeting,
    setIsSubmitting,
  } = useMeetingsState()
  const {
    reset,
    formState: { isSubmitSuccessful, isValid, isSubmitting, dirtyFields },
  } = rhForm

  const [isSaved, setIsSaved] = useState(isSubmitSuccessful)

  // workaround for force the update of the ids for the attendees
  // [TODO-2780] - Revisit this as the form should always have the current value? Don't accept concurrent updates from other screens?
  useEffect(() =>
    rhForm.setValue('attendeesId', meetingORM?.model.attendees
      ?.filter(attendee => attendee.status === MeetingAttendeeStatus.ACTIVE)
      ?.map(attendee => attendee.id))
  , [meetingORM?.model.attendees])

  useEffect(() => { meetingForm.current = rhForm }, [rhForm])

  // THE HEADER NEEDS TO KNOW THE USER IS SUBMITTING
  useEffect(() => {
    setTimeout(() => setIsSubmitting(isSubmitting), 500);
  }, [isSubmitting]);

  useEffect(() => {
    isSubmitSuccessful && setIsSaved(true)
    setTimeout(() => {
      setCanEndMeeting(true);
      setIsSaved(false);
    }, 3000)
  }, [isSubmitSuccessful])

  const isKeyDirty = !(Object.keys(dirtyFields).length > 0)
  const isButtonDisabled = (isKeyDirty && !isSaved) || !isValid;

  const handleSubmit = rhForm.handleSubmit((values) => {
    setCanEndMeeting(false);
    if (meetingORM) {
      submitMeeting({
        meetingORM,
        formValues: values,
      });
      reset({ ...values }, { isDirty: false })
    }
    setIsSaved(true);
  })

  return (
    <DNABox
      fill
      appearance="col"
      style={{ marginHorizontal: 12, maxHeight: 48, minHeight: 48, marginVertical: 8 }}
    >
      <DNABox fill appearance="col">
        <DNADivider variant="virtual" />
        <DNABox spacing="sm" alignY="end" alignX="end" style={{ paddingVertical: 8 }}>
          {/* TODO: WORKAROUND TO BE ABLE TO SHOW THE OPACITY IN A BLACK BACKGROUND */}
          <DNABox
            fill
            style={isButtonDisabled ? { backgroundColor: colors['color-text-white'], borderRadius: 30 }
              : null}
          >
            <DNAButton
              style={{ maxHeight: 43 }}
              appearance="filled"
              status={isSaved ? 'success' : undefined}
              iconLeft={isSaved ? 'check' : undefined}
              disabled={isButtonDisabled}
              onPress={handleSubmit}
            >
              {
                isSaved ? 'Saved' : isSubmitting ? <DNASpinner size="xs" status="info" /> : 'Save'
              }
            </DNAButton>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNABox>
  );
};

const MeetingDetails = () => {
  const { meetingORM } = useMeetingsState()
  const formConfig = useMeetingFormConfig(meetingORM?.model.id, true);

  if (formConfig.isLoading) {
    return null;
  }

  return (
    <ComposableForm {...formConfig}>
      <DNABox fill appearance="col">
        <ScrollView>
          <DNABox
            appearance="col"
            spacing="md"
            fill
            style={[{ paddingHorizontal: 24 }, styles.contentContainer]}
          >
            {/* STATIC FIELDS */}
            <ContentCard cardTitle="Meeting Information" variant={ComposableVariant.VIRTUAL}>
              {/* MEETING TITLE */}
              <ComposableField variant={ComposableVariant.VIRTUAL} field={formConfig.staticFields.fields[0]} />
            </ContentCard>

            { /* PRIMARY ATTENDEE */}
            <ContentCard cardTitle="Primary Attendee" variant={ComposableVariant.VIRTUAL}>
              <ComposableField
                variant={ComposableVariant.VIRTUAL}
                field={formConfig.staticFields.fields[1]}
                CustomFormComponent={(props) => (
                  <AttendeeInput
                    variant={ComposableVariant.VIRTUAL}
                    {...props}
                  />)
                }
              />
            </ContentCard>

            { /* ADDITIONAL ATTENDEES */}
            <ContentCard cardTitle="Additional Attendees" variant={ComposableVariant.VIRTUAL}>
              <ComposableField
                variant={ComposableVariant.VIRTUAL}
                field={formConfig.staticFields.fields[2]}
                CustomFormComponent={(props) => (
                  <AttendeeInput
                    variant={ComposableVariant.VIRTUAL}
                    {...props}
                  />)
                }
              />
            </ContentCard>

            { /* CUSTOM FIELDS */}
            <Iffy is={formConfig.customFields.length}>
              <ContentCard cardTitle="Additional Meeting Information" variant={ComposableVariant.VIRTUAL}>
                <DNABox>
                  <ColumnFieldsWrapper customFields={formConfig.customFields} variant={ComposableVariant.VIRTUAL} />
                </DNABox>
              </ContentCard>
            </Iffy>
          </DNABox>
        </ScrollView>
        <AddMeetingFooter />
      </DNABox>
    </ComposableForm>
  )
}

export default MeetingDetails;
