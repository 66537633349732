import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import FormWrapper from '../../FormWrapper';
import { Button, luxColors } from '@alucio/lux-ui';
import InputComponent from 'src/components/Publishers/InputComponent';
import { Auth } from '@aws-amplify/auth';
import { IAuthPieceProps } from 'aws-amplify-react/lib-esm/Auth/AuthPiece';
import withPasswordChecker, { ValidationType } from '../PasswordChecker';
import { ConsoleLogger as Logger } from '@aws-amplify/core';
import PasswordCriterias from '../PasswordCriterias';

const styles = StyleSheet.create({
  disabledButton: {
    backgroundColor: luxColors.disabled.quaternary,
    borderColor: luxColors.disabled.quaternary,
  },
  disabledTextColor: {
    color: luxColors.disabled.quinary,
  },
  invalidMessage: {
    color: luxColors.error.primary,
    fontSize: 12,
  },
  logInButton: {
    backgroundColor: luxColors.thumbnailBorder.primary,
    borderColor: luxColors.thumbnailBorder.primary,
    borderRadius: 50,
    marginBottom: 24,
    marginTop: 24,
  },
  mainWrapper: {
    flexDirection: 'column',
    width: '100%',
  },
  marginTop: {
    marginTop: 10,
  },
  row: {
    flexDirection: 'row',
  },
  securityItem: {
    alignItems: 'center',
    marginBottom: 5,
  },
  securityItemText: {
    color: luxColors.text.secondary,
    fontSize: 13,
  },
  securityWrapper: {
    flex: 1,
    flexDirection: 'column',
  },
});

const logger = new Logger('RequireNewPassword');

const VALID_STATES = ['requireNewPassword'];

interface Props extends IAuthPieceProps {
  isPasswordValid: boolean;
  onPasswordChange: (pswd: string) => void,
  onRepeatedPasswordChange: (pswd: string) => void,
  password: string;
  passwordValidations: ValidationType;
  repeatedPassword: string;
}

const RequireNewPassword = (props: Props) => {
  const {
    isPasswordValid,
    onPasswordChange,
    onRepeatedPasswordChange,
    password,
    passwordValidations,
    repeatedPassword,
  } = props;
  const [disabled, setDisabled] = useState<boolean>(false);
  const isSubmitDisabled = !isPasswordValid || disabled;

  async function changePassword(): Promise<void> {
    setDisabled(true);
    try {
      const user = props.authData;
      await Auth.completeNewPassword(user, password || '', {});
      const email = user.challengeParam?.userAttributes?.email;
      // Identify new user
      analytics?.identify(email, {}, function () {
        analytics?.track('LOGIN_FIRST_LOGIN', {
          action: 'FIRST_LOGIN',
          category: 'LOGIN',
        });
      });

      props.onStateChange?.('signedIn', user);
    } catch (e) {
      logger.error('unexpected error', e);
      setDisabled(false);
    }
  }

  return (
    <View style={styles.mainWrapper}>
      <PasswordCriterias passwordValidations={passwordValidations} />
      <View style={styles.marginTop}>
        <InputComponent
          disabled={disabled}
          isPassword={true}
          removeMarginPadding={true}
          required={false}
          title="PASSWORD"
          testID="password-new"
          placeHolder="Password"
          titleColor={luxColors.text.primary}
          value={password}
          onChangeText={onPasswordChange}
        />
      </View>
      <View style={styles.marginTop}>
        <InputComponent
          disabled={disabled}
          isPassword={true}
          removeMarginPadding={true}
          required={false}
          title="CONFIRM PASSWORD"
          testID="password-confirm"
          placeHolder="Confirm Password"
          titleColor={luxColors.text.primary}
          value={repeatedPassword}
          onSubmitEditing={isSubmitDisabled ? () => null : changePassword}
          onChangeText={onRepeatedPasswordChange}
        />
      </View>
      <Button.Kitten
        disabled={isSubmitDisabled}
        style={[styles.logInButton, isSubmitDisabled && styles.disabledButton]}
        onPress={changePassword}
      >
        <Text testID="submit-password" style={[isSubmitDisabled && styles.disabledTextColor]}>Set password</Text>
      </Button.Kitten>
    </View>
  );
};

const RequireNewPasswordWrapper = (props) => {
  if (!VALID_STATES.includes(props.authState)) {
    return null;
  }

  return (
    <FormWrapper title="SET PASSWORD">
      <RequireNewPassword {...props} />
    </FormWrapper>
  );
};

RequireNewPasswordWrapper.displayName = 'RequireNewPassword';

export default withPasswordChecker(RequireNewPasswordWrapper);
