import { DNABox, DNAText } from '@alucio/lux-ui'
import React from 'react'
import { FolderORM } from 'src/types/orms'
import { BaseInfoPanelProps } from '../InfoPanel'
import { useInfoPanelState } from '../State/InfoPanelStateProvider'
import ActionBar, { ActionBarElement } from '../ActionBar/ActionBar'
import ContentPanels from '../ContentPanels/ContentPanels'
import { isFolderORM } from 'src/types/typeguards'
import { useLDClient } from 'launchdarkly-react-client-sdk'

export interface FolderVariantProps extends BaseInfoPanelProps {
  variant: 'Folder'
  folderORM?: FolderORM
}

const FolderVariant: React.FC<FolderVariantProps> = props => {
  const { folderORM } = props
  const { collapsed, activeActionBarOption, setCollapsed, handlePressActionBarOption } = useInfoPanelState()
  const ldlClient = useLDClient();
  const enableSharedFolders = ldlClient?.variation('enable-shared-folders', false);

  if (!isFolderORM(folderORM)) return (<></>)

  const actionBarOptions: ActionBarElement[] = [
    {
      icon: collapsed ? 'arrow-collapse-left' : 'arrow-collapse-right',
      onPress: () => setCollapsed(!collapsed),
      popoverContent: collapsed
        ? <DNAText status="basic">Expand</DNAText>
        : <DNAText status="basic">Collapse</DNAText>,
      style: { borderBottomWidth: 1, borderBottomColor: '#EBEBEB' },
      key: 'toggle-collapsed',
    },
    {
      icon: 'account-multiple',
      onPress: () => handlePressActionBarOption('Sharing'),
      active: activeActionBarOption === 'Sharing',
      popoverContent: <DNAText status="basic">Sharing</DNAText>,
      key: 'sharing',
    },
    {
      icon: 'information-outline',
      onPress: () => handlePressActionBarOption('Details'),
      active: activeActionBarOption === 'Details',
      popoverContent: <DNAText status="basic">Details</DNAText>,
      key: 'details',
    },
  ]

  // IF THE USER DOESN'T OWN THE FOLDER,
  // THE SHARING OPTION SHOULD BE HIDDEN
  if (folderORM?.meta.isSharedWithTheUser || !enableSharedFolders) {
    actionBarOptions.splice(1, 1);
  }

  return (
    <DNABox appearance="row" fill>
      <ContentPanels itemORM={folderORM}/>
      <ActionBar
        options={actionBarOptions}
        orientation="col"
        style={{ borderLeftWidth: 1, borderColor: '#EBEBEB', padding: 12 }}
      />
    </DNABox>
  )
}

export default FolderVariant
