import React, { useEffect, useRef, useState, useMemo } from 'react'
import { StyleSheet } from 'react-native'
import { DNABoxProps } from '@alucio/lux-ui/src/components/layout/DNABox/DNABox'
import { DNABox, DNAButton, DNAChip, DNAContextMenu, DNAText, Iffy, DNADivider, DNACheckbox } from '@alucio/lux-ui'
import {
  GroupStatus,
  ModifiedPayloadGroup,
  PayloadGroup,
  usePresentationBuilderState,
} from 'src/components/PresentationBuilder/state/PresentationBuilderStateProvider'
import { GroupItemOverlay, notPublishedStatus } from './Overlays'
import Slide from './Slide'
import { EDITOR_TYPE } from 'src/state/redux/slice/PresentationBuilder/PresentationBuilder'
import { CustomDeckORM } from 'src/types/orms'
import { v4 as uuid } from 'uuid'
import CollapsibleCard, { CollapsibleActionMenu } from 'src/components/CollapsibleCard/CollapsibleCard'
import { thumbnailSizeDimensions } from 'src/hooks/useThumbnailSize/useThumbnailSize'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'

interface GroupHeaderTitleProps {
    group: ModifiedPayloadGroup
}
interface GroupHeaderActionItemsProps {
    group: ModifiedPayloadGroup
    isMutexLocked?: boolean,
    onCheck?: () => void,
    isChecked?: boolean,
}

const S = StyleSheet.create({
  greyBox: {
    width: '28%',
    height: '35%',
    backgroundColor: colors['color-gray-80'],
    margin: '1.5%',
    borderRadius: 0.5,
  },
  greyBoxContainer: {
    padding: '5%',
  },
  overlayHeader: {
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  selected: {
    borderColor: colors['color-brand2-500'],
    borderRadius: 6,
    borderWidth: 3,
  },
})

const onRemoveGroup = (
  group: PayloadGroup,
  setSelectedGroups: React.Dispatch<React.SetStateAction<PayloadGroup[]>>,
  editorType?: EDITOR_TYPE,
  customDeck?: CustomDeckORM,
  setNumOfRequiredSlides?: React.Dispatch<React.SetStateAction<number>>,
) => () => {
  group.pages.forEach((page) => {
    analytics?.track('CUSTOM_SLIDE_REMOVED', {
      action: 'SLIDE_REMOVED',
      category: 'CUSTOM',
      customDeckId: customDeck?.model.id,
      groupId: group.id,
      pageId: page.pageId,
      editorType: editorType,
    });
  })
  if (setNumOfRequiredSlides) setNumOfRequiredSlides(0)
  setSelectedGroups((groups) => groups.filter((grp) => grp.id !== group.id))
}

const onSeparateGroup = (
  group: PayloadGroup,
  selectedGroups: PayloadGroup[],
  setSelectedGroups: React.Dispatch<React.SetStateAction<PayloadGroup[]>>,
  customDeck?: CustomDeckORM,
) => {
  /** Filter out current group from selected groups */
  const updatedSelectedGroups = selectedGroups.filter(({ id }) => group.id !== id)

  /** Add slides from current group as individual groups */
  const separatedSlides = group.pages.map(page => {
    const newPayloadGroup: PayloadGroup = {
      id: uuid(),
      pages: [page],
      visible:true,
      groupStatus: GroupStatus.ACTIVE,
      docAccessLevel: group.docAccessLevel,
      documentVersionORM: group.documentVersionORM,
    }
    return newPayloadGroup
  })

  updatedSelectedGroups.push(...separatedSlides)

  setSelectedGroups(updatedSelectedGroups)

  // ANALYTIC TRACKING: ungrouped named group
  analytics?.track('GROUP_UNGROUP', {
    customDeckId: customDeck?.model.id,
    srcGroupId: group.id,
  })
}

export const GroupHeaderTitle: React.FC<GroupHeaderTitleProps> = ({ group:{ name, documentVersionORM } }) => {
  return (
    <DNABox fill appearance="col" alignY="center" style={{ width: 100 }}>
      <DNAText h5 numberOfLines={1}>{name ?? 'Untitled Group'}</DNAText>
      <DNAText c1 status="flat" numberOfLines={1}>{documentVersionORM?.model.title ?? 'Untitled Document'}</DNAText>
    </DNABox>
  )
}

export const GroupHeaderActionItems:React.FC<GroupHeaderActionItemsProps> = ({
  group, isMutexLocked, onCheck, isChecked,
}) => {
  const {
    selectedGroups,
    setSelectedGroups,
    customDeck,
    editorType,
    setNumOfRequiredSlides,

  } = usePresentationBuilderState()

  const onHide = () => {
    setSelectedGroups(
      groups => {
        const rv = groups.reduce<PayloadGroup[]>(
          (acc, grp) => {
            if (grp.id === group.id) {
              const action = grp.visible ? 'HIDE' : 'UNHIDE';

              grp.pages.forEach((page) => {
                analytics?.track(`CUSTOM_SLIDE_${action}`, {
                  action: `SLIDE_${action}`,
                  category: 'CUSTOM',
                  customDeckId: customDeck?.model.id,
                  groupId: group.id,
                  pageId: page.pageId,
                  editorType: editorType,
                });
              });

              return [...acc, { ...grp, visible: !grp.visible }]
            }

            return [...acc, grp]
          },
          []
        )

        return rv
      }
    )
  }

  return (
    <DNABox
      spacing="sm"
      alignY="center"
    >
      <DNAChip
        appearance="subtle"
        status="basic"
        size="lg"
        style={{ borderRadius: 2 }}
      >
        {`${group.pages.length}`}
      </DNAChip>
      <DNAButton
        size="md"
        padding="sm"
        appearance="ghost"
        status="gray"
        iconLeft={group.locked ? 'lock' : 'vector-difference-ba'}
        disabled={group.locked}
        onPress={() => onSeparateGroup(group, selectedGroups, setSelectedGroups, customDeck)}
      />

      <Iffy is={!isMutexLocked}>
        <DNAContextMenu>
          <DNAContextMenu.Anchor>
            <DNAButton
              iconLeft="dots-vertical"
              size="md"
              padding="sm"
              appearance="ghost"
              status="gray"
            />
          </DNAContextMenu.Anchor>
          <DNAContextMenu.Items>
            <DNAContextMenu.Item
              title={ group.visible ? 'Hide' : 'Unhide'}
              icon={ group.visible ? 'eye-off-outline' : 'eye-outline'}
              onPress={onHide}
            />

            <DNAContextMenu.Item
              title="Remove"
              icon="trash-can-outline"
              onPress={onRemoveGroup(group, setSelectedGroups, editorType, customDeck, setNumOfRequiredSlides)}
            />

          </DNAContextMenu.Items>
        </DNAContextMenu>
      </Iffy>
      <DNACheckbox
        status="gray"
        onChange={onCheck}
        checked={isChecked}
      />
    </DNABox>
  )
}

const GroupedSlides: React.FC<{
    group: ModifiedPayloadGroup,
    startingIdx: number,
    orientation: DNABoxProps['appearance'],
    isDraggingMode: boolean,
    islocked?: boolean,
    isCollapsed?: boolean,
    onCheck?: () => void,
    isChecked?: boolean,
  }> = (props) => {
    const {
      group, orientation, startingIdx, isDraggingMode, islocked, onCheck, isChecked,
    } = props
    const { editorThumbnailSize } = usePresentationBuilderState()
    const thumbnailDimensions = useMemo(() => {
      return {
        height: thumbnailSizeDimensions[editorThumbnailSize].height,
        width: thumbnailSizeDimensions[editorThumbnailSize].width,
      }
    }, [editorThumbnailSize])
    const draggingOverlayStyle = useMemo(() => StyleSheet.create([
      {
        borderRadius: 4,
        borderWidth: 1,
        borderColor: colors['color-gray-100'],
        marginTop: 4,
        marginBottom: 18,
        backgroundColor: colors['color-text-basic'],
        height: thumbnailDimensions.height + 14,
        width: thumbnailDimensions.width + 14,
      },
    ]), [thumbnailDimensions])

    const [isCollapsed, setIsCollapsed] = useState(props.isCollapsed ?? false)
    const currentCollapsedState = useRef<undefined | boolean>(false)

    const isNotPublished = !!notPublishedStatus[group.groupStatus]
    const isFindAndReplace = group.groupStatus === GroupStatus.MAJOR_UPDATE
    const isOpaqueScenario = (!group.visible || isNotPublished || isFindAndReplace)

    useEffect(() => {
      // for the ui we wan to drag and drop on collapsed mode to facilitate the drag and drop interaction
      if (isDraggingMode) {
        setIsCollapsed(true)
      }
      // this means the drag and drop finished, so we need to reset the collapse state
      else if ( !isDraggingMode && currentCollapsedState.current !== undefined) {
        setIsCollapsed(currentCollapsedState.current)
      }
    }, [isDraggingMode])

    return (
      <DNABox style={isChecked && S.selected} fill>
        <Iffy is={isDraggingMode}>
          <DNABox appearance="col" style={draggingOverlayStyle}>
            {/* header */}
            <DNABox style={S.overlayHeader} spacing="between" alignY="center">
              <GroupHeaderTitle group={group} />
              <DNAChip
                appearance="subtle"
                status="basic"
                size="lg"
                style={{ borderRadius: 2 }}
              >
                {`${group.pages.length}`}
              </DNAChip>
            </DNABox>
            <DNADivider />
            {/* content */}
            <DNABox fill style={S.greyBoxContainer} wrap="start" alignX="center" alignY="center">
              <DNABox style={S.greyBox} />
              <DNABox style={S.greyBox} />
              <DNABox style={S.greyBox} />
              <DNABox style={S.greyBox} />
              <DNABox style={S.greyBox} />
              <DNABox style={S.greyBox} />
            </DNABox>
          </DNABox>
        </Iffy>
        <Iffy is={!isDraggingMode}>
          <GroupItemOverlay
            group={group}
            enabled
            isLocked={props.islocked}
            isChecked={isChecked}
            onCheck={onCheck}
          >
            <CollapsibleCard
              headerTitle={<GroupHeaderTitle group={group}/>}
              isCollapsed={isCollapsed}
              onToggleChanged={(isCollapsed) => {
                currentCollapsedState.current = isCollapsed
              }}
            >
              <CollapsibleActionMenu>
                {!isOpaqueScenario && <GroupHeaderActionItems
                  onCheck={onCheck}
                  isChecked={isChecked}
                  isMutexLocked={islocked}
                  group={group}
                />}
              </CollapsibleActionMenu>
              <DNABox
              // [NOTE] - This might be achievable without a prop by normal flexing
              //  but flexing certain containers might mess with DND
                appearance={orientation}
                spacing="lg"
                wrap="stretch"
              >
                {
                group.pages.map((page, idx) => (
                  <DNABox appearance="col" key={`${group.id}-${page.pageId}-${idx}`} style={{ marginBottom: 24 }}>
                    <Slide
                      group={group}
                      page={page}
                      displayIdx={startingIdx ? (startingIdx + idx) : -1}
                      enableOverlay={false}
                    />
                  </DNABox>
                ))
              }
              </DNABox>
            </CollapsibleCard>
          </GroupItemOverlay>
        </Iffy>
      </DNABox>
    )
  }

export default GroupedSlides
