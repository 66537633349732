/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://c7m3k7llgzgxro6mhz5sysh44e.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:b6ed8a0b-57a9-4472-b3ce-d350fdc8cb24",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_AM2nUxmak",
    "aws_user_pools_web_client_id": "7dicbn58oq10a0e9n2pjrirfub",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ObjectAudit-previewdr",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "alucio-beacon-content180602-previewdr",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
