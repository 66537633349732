import React from 'react';
import { DNABox, DNAButton, DNAIcon, DNAText, Iffy } from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { StyleSheet } from 'react-native';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';
import { useContentPreviewModalState } from './State/ContentPreviewModalStateProvider';
import { useMeetingPresentedMeta } from './State/MeetingPresentedMetaProvider';
import { Sentiment } from '@alucio/aws-beacon-amplify/src/models';
import useFeatureFlag from 'src/hooks/useFeatureFlag/useFeatureFlag';
import { FeatureFlags } from 'src/types/featureFlags';

const styles = StyleSheet.create({
  mainContainer: {
    padding: 8,
    backgroundColor: colors['color-gray-900'],
    borderRadius: 4,
  },
  tooltipText: {
    color: colors['color-text-white'],
  },
  iconBtn: {
    paddingHorizontal: 0,
  },
  textBtn: {
    paddingHorizontal: 8,
  },
  icon: {
    width: 16,
    height: 16,
  },
});

const defaultButtonProps: DNAButtonProps = {
  size: 'md',
  appearance: 'filled',
  status: 'dark',
}

const toggleButtonProps: DNAButtonProps = {
  status: 'dark',
}
interface Props {
  hideSearch?: boolean,
}

const ActionBar = (props: Props) => {
  const { hideSearch } = props
  const { activePresentation, nextStepPage, prevStepPage } = useContent();
  const { setSlideRollVisible, textSearchVisible, setTextSearchVisible } = useContentPreviewModalState()
  const { meetingORM, currentPresentedMeta, setSentiment, setFollowUp, setIsPresented } = useMeetingPresentedMeta();
  const areReactionsEnabled = useFeatureFlag(FeatureFlags.BEAC_4227_meeting_slide_reactions);
  const isFollowUpEnabled = useFeatureFlag(FeatureFlags.BEAC_4227_meeting_slide_follow_up);
  const isEyeballEnabled = useFeatureFlag(FeatureFlags.BEAC_4227_meeting_slide_eyeball);

  if (!activePresentation) return null

  const presentable = activePresentation?.presentable;
  const currentPageNumber = activePresentation?.currentPresentablePage.presentationPageNumber;
  const isLocked = meetingORM?.model.status === 'LOCKED';

  return (
    <DNABox
      testID="content-navigation-container"
      spacing="sm"
      style={styles.mainContainer}
      alignX="center"
      alignY="center"
    >
      {/* Previous slide button */}
      <DNAButton
        iconLeft="chevron-left"
        onPress={prevStepPage}
        {...defaultButtonProps}
        size="xs"
        testID="action-bar-previous-slide-btn"
      />

      <DNAText testID="slide-of-number" status="basic">
        {`${currentPageNumber} of ${presentable?.numberOfPages}`}
      </DNAText>
      {/* Next slide button */}
      <DNAButton
        {...defaultButtonProps}
        iconRight="chevron-right"
        iconStyle={{ margin: 0 }}
        onPress={nextStepPage}
        size="xs"
        testID="action-bar-next-slide-btn"
      />
      {/* slide roll button */}
      <DNAPopover disablePopover={['tabletPWA']}>
        <DNAPopover.Anchor>
          <DNAButton
            {...toggleButtonProps}
            style={styles.textBtn}
            onPress={() => {
              setSlideRollVisible(p => !p)
              setTextSearchVisible(false)
            }}
            size="xs"
            testID="action-bar-slide-roll-btn"
          >
            Slides
          </DNAButton>
        </DNAPopover.Anchor>
        <DNAPopover.Content>
          <DNAText style={styles.tooltipText} numberOfLines={1}>Toggle slides</DNAText>
        </DNAPopover.Content>
      </DNAPopover>
      {/* search  button */}
      <Iffy is={!hideSearch}>
        <DNAPopover disablePopover={['tabletPWA']}>
          <DNAPopover.Anchor>
            <DNAButton
              testID="magnify"
              size="xs"
              style={styles.iconBtn}
              {...toggleButtonProps}
              onPress={() => {
                setTextSearchVisible(p => !p)
                setSlideRollVisible(false)
              }}
            >
              <DNAIcon.Styled
                status={textSearchVisible ? undefined : 'info'}
                name="magnify"
              />
            </DNAButton>
          </DNAPopover.Anchor>
          <DNAPopover.Content>
            <DNAText
              style={styles.tooltipText}
              numberOfLines={1}
            >
              Search
            </DNAText>
          </DNAPopover.Content>
        </DNAPopover>
      </Iffy>
      <Iffy is={meetingORM}>
        <Iffy is={areReactionsEnabled && !isLocked}>
          <DNABox spacing="sm">
            {/* Positive Sentiment Button */}
            <DNAPopover disablePopover={['tabletPWA']}>
              <DNAPopover.Anchor>
                <DNAButton
                  {...toggleButtonProps}
                  size="xs"
                  style={styles.iconBtn}
                  iconLeft="chevron-up"
                  iconStyle={[styles.icon, currentPresentedMeta?.sentiment === Sentiment.POSITIVE &&
                  { color: colors['color-success-500'] }]}
                  onPress={() => setSentiment(Sentiment.POSITIVE)}
                />
              </DNAPopover.Anchor>
              <DNAPopover.Content>
                <DNAText status="basic">Positive reaction</DNAText>
              </DNAPopover.Content>
            </DNAPopover>
            {/* Negative Sentiment Button */}
            <DNAPopover disablePopover={['tabletPWA']}>
              <DNAPopover.Anchor>
                <DNAButton
                  {...toggleButtonProps}
                  size="xs"
                  style={styles.iconBtn}
                  iconLeft="chevron-down"
                  iconStyle={[styles.icon, currentPresentedMeta?.sentiment === Sentiment.NEGATIVE &&
                  { color: colors['color-warning-500'] }]}
                  onPress={() => setSentiment(Sentiment.NEGATIVE)}
                />
              </DNAPopover.Anchor>
              <DNAPopover.Content>
                <DNAText status="basic">Negative reaction</DNAText>
              </DNAPopover.Content>
            </DNAPopover>
          </DNABox>
        </Iffy>
        {/* Follow-up Button */}
        <Iffy is={isFollowUpEnabled && !isLocked}>
          <DNAPopover
            placement="top"
            type="tooltip"
            offset={30}
            disablePopover={['tabletPWA']}
          >
            <DNAPopover.Anchor>
              <DNAButton
                {...toggleButtonProps}
                size="xs"
                style={styles.iconBtn}
                iconLeft={currentPresentedMeta?.followUp ? 'flag' : 'flag-outline'}
                onPress={setFollowUp}
              />
            </DNAPopover.Anchor>
            <DNAPopover.Content>
              <DNAText status="basic">Flag for follow-up</DNAText>
            </DNAPopover.Content>
          </DNAPopover>
        </Iffy>
        {/* Presented Button */}
        <Iffy is={isEyeballEnabled && !isLocked}>
          <DNAPopover
            placement="top"
            type="tooltip"
            offset={30}
            disablePopover={['tabletPWA']}
          >
            <DNAPopover.Anchor>
              <DNAButton
                {...toggleButtonProps}
                size="xs"
                style={styles.iconBtn}
                iconLeft={currentPresentedMeta?.presented ? 'eye' : 'eye-off-outline'}
                onPress={setIsPresented}
              />
            </DNAPopover.Anchor>
            <DNAPopover.Content>
              <DNAText status="basic">
                {currentPresentedMeta?.presented ? 'Mark as unpresented slide' : 'Mark as presented slide'}
              </DNAText>
            </DNAPopover.Content>
          </DNAPopover>
        </Iffy>
      </Iffy>
    </DNABox >
  )
}

export default ActionBar;
