import React from 'react'
import { FolderStatus } from '@alucio/aws-beacon-amplify/src/models'
import { FolderORM } from 'src/types/types'
import {
  useDNAFolderActions,
  folderContextMenuActions,
  FolderContextMenuAction,
} from './DNAFolder.actions'
import { DNAContextMenu } from '@alucio/lux-ui'
import { useAppSettings } from 'src/state/context/AppSettings'
import { useLDClient } from 'launchdarkly-react-client-sdk'

export interface DNAFolderContextMenuProps {
  folderORM: FolderORM,
  actions?: FolderContextMenuAction[],
  children: React.ReactElement,
}

export const DNADocumentContextMenu: React.FC<DNAFolderContextMenuProps> = (props) => {
  const {
    folderORM,
    actions,
    children,
  } = props
  const DNAFolderActions = useDNAFolderActions()
  const { deviceMode, isOnline } = useAppSettings()
  const { isSharedWithTheUser } = folderORM.meta
  const { isPinned, status } = folderORM.model
  const { parentFolderORM } = folderORM.relations
  const isActive = status === FolderStatus.ACTIVE
  const isArchived = status === FolderStatus.ARCHIVED
  const isDesktop = deviceMode === 'desktop'
  const isNestedFolder = !!parentFolderORM
  const ldlClient = useLDClient();
  const enableSharedFolders = ldlClient?.variation('enable-shared-folders', false);

  /** Start with no actions */
  const actionsToUse: FolderContextMenuAction[] = []

  /** Identify conditions for enabling each action */
  const canArchive = !isNestedFolder && !isSharedWithTheUser
  const canCopyShared = isSharedWithTheUser
  const canDelete = !isSharedWithTheUser
  const canDuplicate = !isArchived && !isNestedFolder && !isSharedWithTheUser
  const canDuplicateNested = !isArchived && isNestedFolder && !isSharedWithTheUser
  const canPin = !isArchived && !isNestedFolder && !isSharedWithTheUser
  const canRename = !isArchived && !isSharedWithTheUser
  const canShare = isActive && !isNestedFolder && !isSharedWithTheUser && enableSharedFolders && isOnline;

  /** Populate actions array - note that this order affects the order
   * in which the elements are presented to the user in the UI */
  canRename && actionsToUse.push('rename')
  canPin && actionsToUse.push(isPinned ? 'unpin' : 'pin')
  canDuplicate && actionsToUse.push('duplicate')
  canDuplicateNested && actionsToUse.push('duplicateNested')
  canArchive && actionsToUse.push(isArchived ? 'unarchive' : 'archive')
  canShare && actionsToUse.push('share')
  canCopyShared && actionsToUse.push('copyShared')
  canDelete && actionsToUse.push('delete')

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        {children}
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        {
          (actions ?? actionsToUse).map(action => {
            const { onPress, ...rest } = folderContextMenuActions[action]
            return (
              <DNAContextMenu.Item
                key={action}
                status={isDesktop ? 'neutral' : 'dark'}
                onPress={DNAFolderActions[action](folderORM)}
                {...rest}
              />
            )
          })
        }
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

export default DNADocumentContextMenu
