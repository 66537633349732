import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { userTenant, useUserTenant } from './user';
import { Tenant } from '@alucio/aws-beacon-amplify/src/models'
import { FormSettings } from 'src/classes/CRM/CRMIndexedDBTypes';
import { Singleton as IndexDbCrm } from 'src/classes/CRM/CRMIndexedDB';
import { CRMHandler, FormTranslatorResponse } from 'src/classes/CRM/CRMHandler';
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import { CRMSyncStatus } from 'src/state/machines/CRM/crmMachineTypes';

const selRawFormConfig = (_, __, rawFormConfig: FormSettings[]) => rawFormConfig;

export const useGetFormConfigFromIndexDB = () => {
  const userTenant = useUserTenant();
  const { canUseMeetingCRMFields, syncStatus } = useCRMStatus();
  const [rawFormConfig, setRawFormConfig] = useState<FormSettings[] | undefined>();

  useEffect(() => {
    async function fetchFormConfig(): Promise<void> {
      const response = await IndexDbCrm.getFormSettingsRaw();
      setRawFormConfig(response);
    }

    if (userTenant?.config.crmIntegration?.meetingSetting && syncStatus === CRMSyncStatus.SYNCED) {
      fetchFormConfig();
    }
  }, [canUseMeetingCRMFields]);

  return rawFormConfig;
};

/** Returns an array of FormTranslatorResponse based on the CRM form response **/
const meetingFormConfig = createSelector(
  userTenant,
  selRawFormConfig,
  (tenant: Tenant | undefined, rawFormConfig: FormSettings[]): FormTranslatorResponse => {
    if (tenant?.config.crmIntegration) {
      return CRMHandler(tenant?.config.crmIntegration)
        .Translator(rawFormConfig).getFormCustomFieldConfig();
    }

    return { crmCustomFields: [], crmDefaultValues: {} };
  });

export const useCRMFormConfig = (): ReturnType<typeof meetingFormConfig> => {
  const rawFormConfig = useGetFormConfigFromIndexDB();
  const crmSyncingStatus = useCRMStatus();
  return useSelector((state: RootState) => {
    if (!rawFormConfig || !crmSyncingStatus.canUseMeetingCRMFields) {
      return { crmCustomFields: [], crmDefaultValues: {} };
    }
    return meetingFormConfig(state, undefined, rawFormConfig);
  });
};
