/*
  Check out the share state machine diagram here:
  https://www.figma.com/file/z22MXV1YnIcRBos8CQI9NZ/Library-document-share-modal?t=8Rf0stGcLOCPWFIm-0
*/
import { assign } from '@xstate/immer'
import { createMachine, actions } from 'xstate'
import {
  ShareContext,
  ShareEvents,
  ShareState,
  STEP_STATE,
  SHARE_METHODS,
  EVT_PREV_STEP,
  EVT_NEXT_STEP,
  EVT_GENERATE_SHARE,
  EVT_SET_DOCS_TO_SHARE,
  ShareFileOptionMapping,
} from './shareTypes'
import { DOCUMENT_ACTIONS_ENUM, isHubORM } from 'src/types/types'

import { DocumentVersionORM, EmailTemplateORM, HubORM } from 'src/types/orms'
import { CustomFieldDefinition, Tenant, User } from '@alucio/aws-beacon-amplify/src/models'

import {
  getPublishedAndDistributableAssociatedFiles,
  initialStep,
  stepToGuard,
  shareEmailLinks,
  shareEmailTemplate,
  copyShareEmailTemplate,
  copyLink,
  getMainFileShareOptions,
  copyHubsLink,
  copyHubsEmailTemplate,
  shareHubsLink,
  shareHubsEmailTemplate,
  getAndUpdateReduxContentShare,
} from './shareUtils'
import { SHARE_METHOD } from 'src/components/DNA/Modal/DNAFileShareModal/state/context/DNAFileShareModalStateProvider'
import { ToastActions } from '@alucio/lux-ui/lib/components/Toast/useToast'
import { canPerformAction } from 'src/state/redux/selector/document';
import { ExistingShareLink } from 'src/utils/shareLink/shareLink.web'

const share = (
  // [TODO!!!] - It's cumbersome to pass in values from selectors from components
  //             Instead, we should just query the redux store directly
  //             This also makes the machine more viable outside of component usage
  /* shareContent is the content we are sharing, at the moment it is either a docVerORM[] or HubORM */
  shareContent: DocumentVersionORM[] | HubORM,
  emailTemplates: EmailTemplateORM[],
  customFields: CustomFieldDefinition[],
  shareMethod: SHARE_METHOD,
  tenant: Tenant,
  toast: ToastActions,
  user: User,
  meetingId?: string,
  existingShareLinks?: ExistingShareLink,
) => {
  let allValidAssociatedFiles: ShareContext['associatedFiles']
  let availableStates: STEP_STATE[]
  let allMainShareDocs: ShareFileOptionMapping = {}
  if (Array.isArray(shareContent)) {
    allValidAssociatedFiles = shareContent.reduce((acc, docVerORM) => {
      acc[docVerORM.model.id] = getPublishedAndDistributableAssociatedFiles(docVerORM.relations.associatedFiles)
      return acc
    }, {})

    const allValidAssociatedFilesValues = Object.values(allValidAssociatedFiles)
    const hasAssociatedFileStep = allValidAssociatedFilesValues.flat(1).length

    // [MEI] - Only document that are distributable will be added to the list in this case
    //         We assume that the share button is disabled when none of the document is shareable
    allMainShareDocs = shareContent.reduce((acc, docVerORM) => {
      const isDistributable = canPerformAction(
        DOCUMENT_ACTIONS_ENUM.share,
        docVerORM.meta.customValues.configsMap,
        tenant,
      )
      if (isDistributable) acc[docVerORM.model.id] = [getMainFileShareOptions(docVerORM, tenant, true)]
      return acc
    }, {})

    availableStates = [
      ...(customFields.length ? [STEP_STATE.CUSTOM_FIELDS] : []),
      ...(hasAssociatedFileStep
        ? [STEP_STATE.ASSOCIATED_FILES]
        : []
      ),
      ...((emailTemplates.length && shareMethod === SHARE_METHOD.EMAIL) ? [STEP_STATE.EMAIL_TEMPLATES] : []),
    ]
  } else {
    availableStates = [
      ...(customFields.length && !existingShareLinks ? [STEP_STATE.CUSTOM_FIELDS] : []),
      ...((emailTemplates.length && shareMethod === SHARE_METHOD.EMAIL) ? [STEP_STATE.EMAIL_TEMPLATES] : []),
    ]
  }

  return createMachine<
    ShareContext,
    ShareEvents,
    ShareState
  >(
    {
      predictableActionArguments: false,
      id: 'share',
      context: {
        // [NOTE] - Cached and only calculated once!
        availableStates,
        customFields: customFields,
        customFieldsValues: [],
        associatedFiles: allValidAssociatedFiles,
        contentToShare: allMainShareDocs || shareContent,
        emailTemplates: emailTemplates,
        existingShareLinks: existingShareLinks,
        generateLinks: undefined,
      },
      type: 'parallel',
      states: {
        form: {
          initial: 'determine',
          states: {
            determine: {
              description: 'Determines which modal screen to show or generate link',
              always: [
                { target: '#share.form.customField', cond: initialStep(STEP_STATE.CUSTOM_FIELDS) },
                { target: '#share.form.associatedFiles', cond: initialStep(STEP_STATE.ASSOCIATED_FILES) },
                { target: '#share.form.emailTemplates', cond: initialStep(STEP_STATE.EMAIL_TEMPLATES) },
                { target: '#share.form.headless' },
              ],
            },
            headless: {
              description: 'The (modal-less) state, simply relies on the parallel generate state',
              type: 'final',
            },
            customField: {
              description: 'The custom field modal if the user has custom fields enabled',
            },
            associatedFiles: {
              description: 'The associated files modal if the document has associated files',
            },
            emailTemplates: {
              description: 'The email template modal if the tenant has emnail templates',
            },
          },
          on: {
            PREV_STEP: [
              {
                target: '#share.form.customField',
                cond: stepToGuard(STEP_STATE.CUSTOM_FIELDS),
                actions: [
                  'setCustomFieldValues',
                ],
              },
              {
                target: '#share.form.associatedFiles',
                cond: stepToGuard(STEP_STATE.ASSOCIATED_FILES),
                actions: [
                  'setCustomFieldValues',
                ],
              },
            ],
            NEXT_STEP: [
              {
                actions: ['setCustomFieldValues'],
                target: '#share.form.associatedFiles',
                cond: stepToGuard(STEP_STATE.ASSOCIATED_FILES),
              },
              {
                actions: ['setCustomFieldValues'],
                target: '#share.form.emailTemplates',
                cond: stepToGuard(STEP_STATE.EMAIL_TEMPLATES),
              },
            ],
            SET_DOCS_TO_SHARE: {
              actions: 'setAssociatedFileValues',
            },
            GO_TO_FINAL: {
              target: '#share.form.headless',
            },
          },
        },
        generateLinks: {
          // eslint-disable-next-line max-len
          description: 'In this scenario either Custom Fields, Associated Files, and Email Templates are disabled or only Custom fields is enabled',
          initial: 'idle',
          states: {
            idle: {
              always: [
                {
                  target: 'generating',
                  cond: (ctx) => !ctx.availableStates.length,
                },
              ],
            },
            generating: {
              tags: ['DISABLE_SHARE'],
              // @ts-ignore - This sometimes bugs out/complains -- better to just ignore for now until it's resolved
              invoke: {
                src: async (ctx, event) => {
                  const evt = event as EVT_GENERATE_SHARE
                  // TODO the HubORM copy and DocumentVersionCopy funcs are very similar,
                  // TODO consider refactor to make the funcs more generic and avoid repeating
                  // hub share
                  if (isHubORM(shareContent)) {
                    // FORM MODE
                    // COPY LINKS
                    if (evt.payload.type === SHARE_METHODS.COPY_LINK) {
                      const linkResults = await copyHubsLink(
                        shareContent,
                        toast,
                        ctx.customFieldsValues,
                        existingShareLinks && [existingShareLinks],
                      )
                      // for hubs we only have one link, the array is always one length we can just get the first index and pass it in this function
                      const [linkResult] = linkResults
                      await getAndUpdateReduxContentShare(linkResult.id)
                    }

                    // EMAIL
                    else if (evt.payload.type !== SHARE_METHODS.GUARD_CHECK) {
                      const { emailTemplateORM, attachmentsInfo } = evt.payload
                      // EMAIL CLIENT
                      if (evt.payload.type === SHARE_METHODS.OPEN_EMAIL_CLIENT) {
                        // EMAIL TEMPLATE
                        if (emailTemplateORM && attachmentsInfo) {
                          const linkResults = await shareHubsEmailTemplate(
                            shareContent,
                            attachmentsInfo,
                            emailTemplateORM.model,
                            ctx.customFieldsValues,
                            toast,
                            existingShareLinks && [existingShareLinks],
                          )
                          const [linkResult] = linkResults
                          await getAndUpdateReduxContentShare(linkResult.id)
                        }
                        // EMAIL DEFAULT
                        else {
                          const linkResults = await shareHubsLink(
                            shareContent,
                            user,
                            ctx.customFieldsValues,
                            toast,
                            existingShareLinks && [existingShareLinks],
                          )
                          // for hubs we only have one link, the array is always one length we can just get the first index and pass it in this function
                          const [linkResult] = linkResults
                          await getAndUpdateReduxContentShare(linkResult.id)
                        }
                      }
                      // COPY EMAIL TEMPLATE
                      else if (evt.payload.type === SHARE_METHODS.COPY_EMAIL) {
                        if (emailTemplateORM && attachmentsInfo) {
                          const linkResults = await copyHubsEmailTemplate(
                            shareContent,
                            toast,
                            attachmentsInfo,
                            emailTemplateORM.model,
                            ctx.customFieldsValues,
                            existingShareLinks && [existingShareLinks],
                          )
                          const [linkResult] = linkResults
                          await getAndUpdateReduxContentShare(linkResult.id)
                        }
                      }
                    }
                  // this else if is added instead of else becuase we are checking the contentToShare type for typescript
                  } else if (!isHubORM(ctx.contentToShare)) {
                    // file share
                    // HEADLESS MODE
                    if (!ctx.availableStates.length) {
                      // COPY (MAIN FILE ONLY)
                      if (shareMethod === SHARE_METHOD.COPY) {
                        await copyLink(
                          shareContent,
                          ctx.contentToShare,
                          toast,
                          ctx.customFieldsValues,
                          meetingId,
                        )
                        return;

                        // EMAIL (DEFAULT TEMPLATE)
                      } else if (shareMethod === SHARE_METHOD.EMAIL) {
                        await shareEmailLinks(
                          ctx.contentToShare,
                          user,
                          ctx.customFieldsValues,
                          toast,
                          meetingId,
                        )
                      }
                      return;
                    }

                    // FORM MODE
                    // COPY LINKS
                    if (evt.payload.type === SHARE_METHODS.COPY_LINK) {
                      await copyLink(
                        shareContent,
                        ctx.contentToShare,
                        toast,
                        ctx.customFieldsValues,
                        meetingId,
                      )
                    }

                    // EMAIL
                    else if (evt.payload.type !== SHARE_METHODS.GUARD_CHECK) {
                      const { emailTemplateORM, attachmentsInfo } = evt.payload

                      // EMAIL CLIENT
                      if (evt.payload.type === SHARE_METHODS.OPEN_EMAIL_CLIENT) {
                        // EMAIL TEMPLATE
                        if (emailTemplateORM && attachmentsInfo) {
                          await shareEmailTemplate(
                            emailTemplateORM,
                            shareContent,
                            ctx.contentToShare,
                            attachmentsInfo,
                            emailTemplateORM.model,
                            ctx.customFieldsValues,
                            toast,
                            meetingId,
                          )
                        }
                        // EMAIL DEFAULT
                        else {
                          await shareEmailLinks(
                            ctx.contentToShare,
                            user,
                            ctx.customFieldsValues,
                            toast,
                            meetingId,
                          )
                        }
                      }
                      // COPY EMAIL TEMPLATE
                      else if (evt.payload.type === SHARE_METHODS.COPY_EMAIL) {
                        if (emailTemplateORM && attachmentsInfo) {
                          await copyShareEmailTemplate(
                            emailTemplateORM,
                            shareContent,
                            toast,
                            ctx.contentToShare,
                            attachmentsInfo,
                            emailTemplateORM.model,
                            ctx.customFieldsValues,
                            meetingId,
                          )
                        }
                        // [TODO]: Seems like there's no way to copy a default share email?
                      }
                    }
                  }
                },
                onDone: [
                  {
                    actions: actions.raise({ type: 'GO_TO_FINAL', data: null }),
                    cond: 'isHubShare',
                  },
                  {
                    // description: 'The machine only runs indefinitely if not headless mode or the last step is not email templates',
                    // Since we're in parallel states, we need to raise an action to geto both parallel states rather than simply going to this node's headless state
                    actions: actions.raise({ type: 'GO_TO_FINAL', data: null }),
                    cond: (ctx) => {
                      return (
                        !ctx.availableStates.length ||
                        ctx.availableStates.at(-1) !== STEP_STATE.EMAIL_TEMPLATES ||
                        (SHARE_METHODS.COPY_LINK && (isHubORM(shareContent)))
                      )
                    },
                  },
                  { target: 'finished' },
                ],
                onError: {
                  target: 'error',
                },
              },
              on: {
                // NO-OP
                GENERATE_SHARE: undefined,
              },
            },
            finished: {
              after: {
                3000: [
                  {
                    target: 'idle',
                    actions: assign((ctx) => { ctx.generateLinks = undefined }),
                  },
                ],
              },
            },
            headless: {
              type: 'final',
            },
            // [TODO] - IMPLEMENT REFINED ERROR STEP?
            //        - Right now, if copy link fails to generate, we just use a toast
            //        - However, we could switch our error states up (maybe into the button instead of a toast?)
            error: {},
          },
          on: {
            GENERATE_SHARE: {
              target: '.generating',
              cond: (ctx) => {
                return !!Object.values(ctx.contentToShare).flat(1).length || !!ctx.contentToShare
              },
              actions: [
                'setCustomFieldValues',
                'setEmailTemplateValues',
              ],
            },
            GO_TO_FINAL: {
              target: '.headless',
            },
          },
        },
      },
    },
    {
      actions: {
        // SETTERS
        setCustomFieldValues: assign((ctx, event) => {
          const evt = event as EVT_PREV_STEP | EVT_NEXT_STEP | EVT_GENERATE_SHARE
          // GENERATE SETTERS
          if (
            evt.type === 'GENERATE_SHARE' &&
            evt.payload.type !== 'GUARD_CHECK' &&
            evt.payload.customFieldsValues
          ) {
            ctx.customFieldsValues = evt.payload.customFieldsValues
          }

          // STEP SETTERS
          if (
            (evt.type === 'PREV_STEP' || evt.type === 'NEXT_STEP') &&
            evt.payload?.customFieldsValues
          ) {
            ctx.customFieldsValues = evt.payload.customFieldsValues
          }
        }),
        setAssociatedFileValues: assign((ctx, event) => {
          const evt = event as EVT_SET_DOCS_TO_SHARE
          ctx.contentToShare = { ...ctx.contentToShare, ...evt.payload.contentToShare }
        }),
        setEmailTemplateValues: assign((ctx, event) => {
          const evt = event as EVT_GENERATE_SHARE

          if (
            (
              evt.payload.type === SHARE_METHODS.COPY_EMAIL ||
              evt.payload.type === SHARE_METHODS.OPEN_EMAIL_CLIENT
            ) && evt.payload.emailTemplateORM
          ) {
            ctx.generateLinks = {
              type: evt.payload.type,
              emailTemplateId: evt.payload.emailTemplateORM.model.id,
            }
          }
        }),
      },
      guards: { //
        isHubShare: (ctx) => isHubORM(ctx.contentToShare),
      },
    },
  )
}

export default share;
