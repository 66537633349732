import React, { useMemo } from 'react';
import {
  DNABox,
  DNAButton,
  DNAModal,
  DNAText,
  TextField,
} from '@alucio/lux-ui';
import { useForm, Controller } from 'react-hook-form'
import * as z from 'zod'
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal';
import { zodResolver } from '@hookform/resolvers/zod';

type GroupModalMode = 'add' | 'edit';

const MAX_GROUP_NAME_LENGTH = 255

const nameValidationSchema = (groupNames: string[]) => z.object({
  name: z
    .string()
    .nonempty({ message: 'This group name is required' })
    .max(MAX_GROUP_NAME_LENGTH, { message: `This group name must be ${MAX_GROUP_NAME_LENGTH} characters or less` })
    .refine(
      (value) => !value.includes('_'),
      { message: 'Group names currently do not support _ characters' },
    )
    .refine(
      (value) => groupNames.indexOf(value.trim()) === -1,
      { message: 'Group name already exists' },
    ),
})

type NameValidationSchema = z.infer<ReturnType<typeof nameValidationSchema>>

export type CreateGroupModalProps = {
    onSuccess: (name: string) => void
    mode: GroupModalMode
    groupNames: string[]
    defaultValue?: string
}

const AddGroupModal: DNAModalVariant<CreateGroupModalProps> = props => {
  const { closeModal, onSuccess, mode, defaultValue, groupNames = [] } = props;
  // [NOTE] - Could also use a ref, but no need to continously update this at the moment
  const formSchema = useMemo(
    () => nameValidationSchema(groupNames),
    [],
  )

  const form = useForm<NameValidationSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: defaultValue || '',
    },
  })

  const {
    control,
    handleSubmit,
    formState: {
      errors,
      isDirty,
      isSubmitting,
    },
  } = form

  const onSubmit = handleSubmit(values => {
    onSuccess(values.name)
    closeModal()
  })

  return (
    <DNAModal>
      <DNAModal.Header onClose={closeModal}>
        <DNAText h5>{`${mode === 'add' ? 'Create group' : 'Rename group'}`}</DNAText>
      </DNAModal.Header>
      <DNAModal.Body>
        <DNABox
          appearance="col"
          style={{ minWidth: 500 }}
        >
          <Controller
            name="name"
            control={control}
            render={({ value, onChange, onBlur }) => (
              <TextField.Kitten
                style={{ flex: 1 }}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                maxLength={MAX_GROUP_NAME_LENGTH}
                autoFocus
                onKeyPress={ (event) => {
                  if ((!isDirty || isSubmitting) && event.nativeEvent.key === 'Enter') {
                    event.preventDefault();
                    return;
                  }
                  event.nativeEvent.key === 'Enter' && onSubmit()
                }}
              />
            )}
          />

          <DNAText status="danger">
            {errors.name?.message}
          </DNAText>
        </DNABox>
      </DNAModal.Body>
      <DNAModal.Footer>
        <DNABox fill alignX="end">
          <DNAButton
            size="sm"
            appearance="outline"
            status="tertiary"
            onPress={closeModal}
          >
            Cancel
          </DNAButton>
          <DNAButton
            size="sm"
            style={{ marginLeft: 8 }}
            disabled={!isDirty || isSubmitting}
            onPress={onSubmit}
          >
            {mode === 'add' ? 'Create' : 'Update'}
          </DNAButton>
        </DNABox>
      </DNAModal.Footer>
    </DNAModal>
  )
}

export default AddGroupModal;
